/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { createRef, useEffect, useRef, useState } from "react"
import Sidebar from "../../../components/Sidebar"
import Header from "../../../components/Header"
import { Chart } from "react-google-charts"
import CampainTable from "../../../components/CampainTable"
import DropDownBox from "../../../components/DropDownBox"
import Footer from "../../../components/Footer"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import { handleAnimation } from "../../../helper/helper"
import { miniSerializeError } from "@reduxjs/toolkit"
// import Breadcrumb from "../../../components/BreadCrumbs";
// import Loader from "../../../components/Loader";
// import { getProfileDetails } from "../../profile/redux/profileApi";
// import { useDispatch } from "react-redux";

const Dashboard = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Dashboard")
  }, [setTitle])

  const [active, setActive] = useState("dashboard")
  const [action, setAction] = useState(false)
  const [ChartState, setChartState] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  // const [navbarAni, setNavbarAni] = useState(false);
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [revenueActive, setRevenueActive] = useState(0)
  const [visiterActive, setVisiterActive] = useState(0)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [dropBoxValue, setDropBoxValue] = useState()
  const [dropBoxValueTwo, setDropBoxValueTwo] = useState()
  const ChartRefOne = useRef([createRef(), createRef(), createRef()])
  const revenueData = ["Month", "Week", "Day"]
  const data = [
    ["Month", "Net Profit", "Revenue"],
    ["Feb", 44, 76],
    ["Mar", 55, 85],
    ["Apr", 57, 101],
    ["May", 56, 98],
    ["Jun", 61, 87],
    ["Jul", 58, 105],
  ]
  const options = {
    legend: "none",
    vAxis: { gridlines: { color: "none" } },
    hAxis: {},
    seriesType: "bars",
    bar: { groupWidth: "25%" },
    colors: ["#1F98D5", "#e5eaee"],
    animation: {
      startup: true,
      duration: 1000,
      easing: "out",
    },
    chartArea: {
      left: 75,
      top: 50,
      right: 75,
      bottom: 50,
    },
    tooltip: {
      isHtml: true,
      formatter: function (data) {
        const seriesLabel = data.columnLabel
        const value = data.row[1]
        const tooltipContent = `
        <div style="background-color: white; border: 1px solid #ccc; padding: 10px; display: flex">           
        <strong>${seriesLabel}</strong>${value}             
        </div>
        `

        return tooltipContent
      },
    },
  }
  const dataTwo = [
    ["Month", "Expenses", "Profit"],
    ["Feb", 44, 76],
    ["Mar", 55, 85],
    ["Apr", 57, 101],
    ["May", 56, 98],
    ["Jun", 61, 87],
    ["Jul", 58, 105],
  ]
  const options2 = {
    chart: {
      title: "Company Performance",
      subtitle: "Expenses, and Profit: 2014-2017",
    },
    vAxis: { gridlines: { color: "none" } },
    legend: "none",
    hAxis: {},
    seriesType: "bars",
    bar: { groupWidth: "25%" },
    colors: ["#1F98D5", "#e5eaee"],
    animation: {
      startup: true,
      duration: 1000,
      easing: "out",
    },
    chartArea: {
      left: 75,
      top: 50,
      right: 75,
      bottom: 50,
    },
    tooltip: {
      isHtml: true,
      formatter: function (data) {
        const seriesLabel = data.columnLabel
        const value = data.row[1]
        const tooltipContent = `
        <div style="background-color: white; border: 1px solid #ccc; padding: 10px; display: flex">           
        <strong>${seriesLabel}</strong>${value}             
        </div>
          `

        return tooltipContent
      },
    },
  }

  // wave data
  const wavedata = [
    ["", ""],
    ["jan", 0],
    ["feb", 20],
    ["mar", 15],
    ["apr", 40],
    ["may", 18],
    ["june", 20],
    ["july", 18],
    ["aug", 23],
    ["sep", 18],
    ["oct", 35],
    ["nov", 30],
    ["dec", 55],
    ["jan", 0],
  ]
  const waveoptions = {
    title: "",
    curveType: "function",
    vAxis: {
      textPosition: "none",
      viewWindow: { min: 0, max: 55 },
      gridlines: { color: "none" },
      baselineColor: "transparent",
    },
    hAxis: {
      textPosition: "none",
    },
    legend: "none",
    series: {
      0: {
        lineWidth: 3,
        color: "#884ffb",
      },
    },
    animation: {
      startup: true,
      duration: 1000,
      easing: "out",
    },
    chartArea: {
      left: 0,
      top: 60,
      right: 0,
      bottom: 60,
    },
  }

  const pieData = [
    ["Buyers and Suppliers", "How many on the platform"],
    ["Buyers", 15],
    ["Suppliers", 8],
    ["Both", 4],
  ]
  const pieOptions = {
    width: "100%",
    height: 350,
    // title: "Buyers and Suppliers",
  }
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
  )

  const weekdayOfFirstDay = firstDayOfMonth.getDay()
  const currentDays = []

  for (let day = 0; day < 42; day++) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7)
    } else if (day === 0) {
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() + (day - weekdayOfFirstDay),
      )
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1)
    }

    let calendarDay = {
      currentMonth: firstDayOfMonth.getMonth() === currentDate.getMonth(),
      date: new Date(firstDayOfMonth),
      month: firstDayOfMonth.getMonth(),
      number: firstDayOfMonth.getDate(),
      selected: firstDayOfMonth.toDateString() === currentDate.toDateString(),
      year: firstDayOfMonth.getFullYear(),
    }

    currentDays.push(calendarDay)
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const weekdays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
  const [selectedDate, setSelectedDate] = useState({})
  const handleDayClick = day => {
    if (!day.currentMonth) {
      const clickedMonth = day.month
      const clickedYear = day.year

      const newDate = new Date(clickedYear, clickedMonth, 1)
      setCurrentDate(newDate)
      setSelectedDate({})
    } else {
      const clickedMonth = day.month
      const clickedYear = day.year
      const clickedDate = day.number

      setSelectedDate({ clickedYear, clickedMonth, clickedDate })
    }
  }
  const [addStyle, setAddStyle] = useState(false)

  // const handleClick = (e) => {
  //   setAddStyle(!addStyle);
  //   chatBoxVisibility();
  // };

  // function chatBoxVisibility() {
  //   if (action) {
  //     const styleTimer = setTimeout(() => {
  //       setAction(false);
  //       clearTimeout(styleTimer);
  //     }, 200);
  //   } else {
  //     setAction(true);
  //   }
  // }

  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // }, []);

  useEffect(() => {
    const temp = setTimeout(() => {
      setChartState(!ChartState)
    }, 250)

    return () => clearTimeout(temp)
  }, [MinimizeSidebar])

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
                <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          {/* {showChat && (
            <Chatbox showChat={showChat} setShowChat={setShowChat} />
          )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              {/* <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
              /> */}
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setInitialRenderAnimation={setInitialRenderAnimation}
                initialRenderAnimation={initialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Dashboard</div>
                  {/* <div className="mch-path">
                    DashBoard / Analytic */}
                  {/* <Breadcrumb /> */}
                  {/* </div> */}
                </div>
                <div className='mch2 flex-center'>
                  <div className='mch2-duration'>
                    August 1,2020 - August 31,2020
                  </div>
                  <div
                    className='mch2-action'
                    onClick={() =>
                      handleAnimation(setAddStyle, addStyle, setAction, action)
                    }
                  >
                    <span className=''>Action</span>
                    <span className='material-symbols-rounded expand'>
                      expand_more
                    </span>
                    {action && (
                      <div
                        className={
                          "action-tooltip" +
                          (addStyle
                            ? " show_component-common-animation"
                            : " hide_component-common-animation ")
                        }
                      >
                        <span className=''>Download</span>
                        <span className=''>Print</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='mc-1'>
                <div className='mc1-1'>
                  <div className='mc1-1-head'>
                    <div className='h-title'>
                      Production Manufacturing Stats
                    </div>
                    <div className='mc1-1-right'>
                      {revenueData.map((item, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => setRevenueActive(index)}
                            className={index === revenueActive ? "active" : ""}
                          >
                            {item}
                          </span>
                        )
                      })}
                    </div>
                  </div>
                  <div className='mc1-1-chart responsive-chart'>
                    <div className='chart-div'>
                      <Chart
                        ref={ChartRefOne.current[0]}
                        chartType='ComboChart'
                        width='100%'
                        height='100%'
                        data={data}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
                <div className='mc2-2'>
                  <div className='mc1-1-head'>
                    <div>
                      <div className='h-title'>Buyers And Suppliers</div>
                      <div className='h-description'>Company Stats</div>
                    </div>

                    {/* <div className='mc1-1-right'>
                      {revenueData.slice(1).map((item, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => setVisiterActive(index)}
                            className={index === visiterActive ? "active" : ""}
                          >
                            {item}
                          </span>
                        )
                      })}
                    </div> */}
                  </div>
                  <div>
                    <div className='mc2-2-chart'>
                      {/* <Chart
                      ref={ChartRefOne.current[1]}
                      chartType='LineChart'
                      width='100%'
                      height='100%'
                      data={wavedata}
                      options={waveoptions}
                    /> */}
                      <Chart
                        ref={ChartRefOne.current[1]}
                        chartType='PieChart'
                        width='115%'
                        height={"350px"}
                        data={pieData}
                        // options={pieOptions}
                      />
                    </div>
                    {/* <div className='mc2-2-details'>
                    <div className='mcd-data'>
                      <div className='left-data'>
                        <div className='icon'>
                          <span className='material-symbols-rounded'>apps</span>
                        </div>
                        <div className='content'>
                          <div className='c1'>Top Sales</div>
                          <div className='c2'>Johnathan Doe</div>
                        </div>
                      </div>
                      <div className='right-data'>
                        <span className='material-symbols-rounded'>add</span>
                        68
                      </div>
                    </div>
                    <div className='mcd-data'>
                      <div className='left-data'>
                        <div className='icon'>
                          <span className='material-symbols-rounded'>apps</span>
                        </div>
                        <div className='content'>
                          <div className='c1'>Best Seller</div>
                          <div className='c2'>Footware</div>
                        </div>
                      </div>
                      <div className='right-data'>
                        <span className='material-symbols-rounded'>add</span>
                        45
                      </div>
                    </div>
                    <div className='mcd-data'>
                      <div className='left-data'>
                        <div className='icon'>
                          <span className='material-symbols-rounded'>apps</span>
                        </div>
                        <div className='content'>
                          <div className='c1'>Most Commented</div>
                          <div className='c2'>Fashionware</div>
                        </div>
                      </div>
                      <div className='right-data'>
                        <span className='material-symbols-rounded'>add</span>
                        14
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>{" "}
              <div className='mc-1'>
                <div className='mc1-1'>
                  <div className='mc1-1-head'>
                    <div className='mc-3-heading-container'>
                      <div className='h-title'>
                        Revenue Updates
                        <span className='overview'>Overview of Profit</span>
                      </div>
                      <div>
                        <DropDownBox
                          options={[
                            { label: "Jan 2024", value: "jan 2024" },
                            { label: "Feb 2024", value: "feb 2024" },
                            { label: "Mar 2024", value: "mar 2024" },
                            { label: "Apr 2024", value: "apr 2024" },
                          ]}
                          defaultValue='Select Date'
                          size='small'
                          customSetter={setDropBoxValue}
                        />
                      </div>
                    </div>

                    {/* <div className="mc1-1-right">
                      {revenueData.map((item, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => setRevenueActive(index)}
                            className={index === revenueActive ? "active" : ""}
                          >
                            {item}
                          </span>
                        );
                      })}
                    </div> */}
                  </div>
                  <div className='mc1-1-chart mc1-2-chart'>
                    <div className='chart-div profit-chart'>
                      <Chart
                        ref={ChartRefOne.current[2]}
                        chartType='ComboChart'
                        width='100%'
                        height='100%'
                        data={dataTwo}
                        options={options2}
                      />
                    </div>
                    <div className='mc2-2-details profilt-chart'>
                      <div className='mcd-data'>
                        <div className='left-data'>
                          <div className='icon'>
                            <span className='material-symbols-rounded'>
                              apps
                            </span>
                          </div>
                          <div className='content'>
                            <div className='c2'>Total earnings</div>
                            <div className='c1'>₹63,489.50</div>
                          </div>
                        </div>
                      </div>
                      <div className='mcd-data'>
                        <div className='left-data'>
                          <div className='chart-icon'></div>
                          <div className='content'>
                            <div className='c2'>Earnings this month</div>
                            <div className='c1'>₹48,820</div>
                          </div>
                        </div>
                      </div>
                      <div className='mcd-data'>
                        <div className='left-data extra-data'>
                          <div className='chart-icon'></div>
                          <div className='content'>
                            <div className='c2'>Expense this month</div>
                            <div className='c1'>₹26,498</div>
                          </div>
                        </div>
                      </div>
                      <button className='btn-primary'> View Full Report</button>
                    </div>
                  </div>
                </div>
                <div className='mc2-3'>
                  <div className='cal-header'>
                    <span className='month'>
                      {months[currentDate.getMonth()]} ,
                    </span>
                    <span className='year'>{currentDate.getFullYear()}</span>
                  </div>
                  <div className='cal-main'>
                    <div className='cal-dates'>
                      {weekdays.map(weekday => (
                        <div className='weekday' key={weekday}>
                          <span>{weekday}</span>
                        </div>
                      ))}
                      {currentDays.map((day, index) => {
                        return (
                          <div
                            className={
                              "calendar-day" +
                              (day.currentMonth ? " current" : "") +
                              (day.selected ||
                              (selectedDate?.clickedDate === day?.number &&
                                day.currentMonth)
                                ? " selected"
                                : "")
                            }
                            onClick={() => handleDayClick(day)}
                            key={index}
                          >
                            <span>{day.number}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className='mc-1'>
                <div className='mc-3'>
                  <div className='mc-3-content'>
                    <div className='mc-3-heading-container'>
                      <div className='h-title'>Buyers & Suppliers</div>
                      <DropDownBox
                        dropDownTitle=''
                        options={[
                          { label: "Jan 2024", value: "jan 2024" },
                          { label: "Feb 2024", value: "feb 2024" },
                          { label: "Mar 2024", value: "mar 2024" },
                          { label: "Mar 2024", value: "mar 2024" },
                          { label: "Mar 2024", value: "mar 2024" },
                          { label: "Mar 2024", value: "mar 2024" },
                          { label: "Apr 2024", value: "apr 2024" },
                          { label: "Apr 2024", value: "apr 2024" },
                          { label: "Apr 2024", value: "apr 2024" },
                        ]}
                        defaultValue='Select Date'
                        size='small'
                        customSetter={setDropBoxValueTwo}
                      />
                    </div>
                    <div className='table-div'>
                      <CampainTable />
                    </div>
                  </div>
                </div>
                <div className='mc2-2'>
                  <div className='h-title'>Recent Update</div>

                  <div className='update-details'>
                    <div className='mcd-data'>
                      <div className='left-data'>
                        <span className='material-symbols-rounded'>circle</span>
                        <div className='content'>
                          <div className='c1 update'>5 min ago</div>
                          <div className='c2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Quisque scelerisque
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mcd-data'>
                      <div className='left-data'>
                        <span className='material-symbols-rounded'>circle</span>
                        <div className='content'>
                          <div className='c1 update'>5 min ago</div>
                          <div className='c2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Quisque scelerisque
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mcd-data'>
                      <div className='left-data'>
                        <span className='material-symbols-rounded'>circle</span>
                        <div className='content'>
                          <div className='c1 update'>5 min ago</div>
                          <div className='c2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Quisque scelerisque
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mcd-data'>
                      <div className='left-data'>
                        <span className='material-symbols-rounded'>circle</span>
                        <div className='content'>
                          <div className='c1 update'>5 min ago</div>
                          <div className='c2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Quisque scelerisque
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          {/* <Loader isLoading={isLoading} /> */}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
