import React from "react"

const CampainTable = () => {
  const tabledata = [
    {
      name: "Sunil Joshi",
      role: "Buyers",
      city: "Ahmedabad",
      date: "20 Dec, 2023",
      budget: "3.9k ₹",
    },
    {
      name: "John Deo",
      role: "Suppliers",
      city: "Surat",
      date: "20 Dec, 2023",
      budget: "24.5k ₹",
    },
    {
      name: "Mathew Anderson",
      role: "Suppliers",
      city: "Rajkot",
      date: "20 Dec, 2023",
      budget: "12.8k ₹",
    },
    {
      name: "Yuvraj Sheth",
      role: "Buyers",
      city: "Mumbai",
      date: "20 Dec, 2023",
      budget: "2.4k ₹",
    },
  ]

  return (
    <div className='table-container'>
      <div className='table-body'>
        <div className='main-table-body'>
          <table className='main_table' cellSpacing={0}>
            <thead className='tb-head'>
              <tr className='tb-row'>
                <th className='tb-head-name extend'>Name</th>
                <th className='tb-head-name'>City</th>
                <th className='tb-head-name'>Date</th>
                <th className='tb-head-name'>Budget</th>
              </tr>
            </thead>

            <tbody className='tb-body'>
              {tabledata.map((data, index) => (
                <tr key={index} className='tb-row'>
                  <td className='tb_data extend'>
                    <div className='model-data'>
                      <div className='person-details'>
                        <img
                          src={
                            require("../assets/images/unsplash_jFAG_ixCrsM.webp")
                              .default
                          }
                          alt=''
                        />

                        <div className='pd-2'>
                          <span className='name'>{data.name}</span>
                          <span className='role'>{data.role}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className='tb_data'>
                    <div className='person-details'>
                      {" "}
                      <div className='pd-2'>
                        <span className='role'>{data.city}</span>
                      </div>
                    </div>
                  </td>
                  <td className='tb_data'>
                    <div className='person-details'>
                      <div className='pd-2'>
                        <span className='role'>{data.date}</span>
                      </div>
                    </div>
                  </td>
                  <td className='tb_data'>
                    <span className='role'>{data.budget}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CampainTable
