/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import Header from "../../../components/Header"
import Sidebar from "../../../components/Sidebar"
import Footer from "../../../components/Footer"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import { useNavigate } from "react-router-dom"
import ConformationPopup from "../../../components/ConformationPopup"
import Breadcrumb from "../../../components/BreadCrumbs"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { deleteEmail, getEmailList } from "../redux/EmailApi"
import { unixTimeToReadableFormat } from "../../../helper/helper"
import Skelaton from "../../../components/Skelaton"
import { setPageDetails } from "../../auth/redux/authSlice"
import Pagination from "../../../components/Pagination"
import SearchBox from "../../../components/SearchBox"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"

const Email = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Email Management")
  }, [setTitle])
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [active, setActive] = useState("email")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  // const [mailList, setMailList] = useState(dummy_emails);
  const [isEventCard, setIsEventCard] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [deleteAllPopup, setDeleteAllPopup] = useState(false)
  const [popUpData, setPopupData] = useState({})
  const [checkedCollection, setCheckedCollection] = useState([])
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const { emailLoading, emailList, metaData } = useSelector(
    store => store.email,
  )
  //
  //
  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )
  const [selectAllChecked, setSelectAllChecked] = useState([
    { checked: false },
    { checked: false },
    { checked: false },
    { checked: false },
    { checked: false },
    { checked: false },
    { checked: false },
  ])

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  // const handlecheckAll = () => {
  //   setMailList((oldList) => {
  //     let newList = JSON.parse(JSON.stringify(oldList));
  //     // newList[tableIndex].map((item) => {
  //     //   return {
  //     //     title: "Lorem consectetur",
  //     //     created_at: "2umang",
  //     //     checked: true,
  //     //   };
  //     // });

  //     // eslint-disable-next-line
  //     newList[tableIndex].map((item) => {
  //       item.checked = !selectAllChecked[tableIndex].checked;
  //     });

  //     return newList;
  //   });

  //   setSelectAllChecked((oldList) => {
  //     const newList = JSON.parse(JSON.stringify(oldList));
  //     newList[tableIndex].checked = !newList[tableIndex].checked;
  //     return newList;
  //   });
  // };

  const deleteFunction = (id, title) => {
    setPopupData({
      data: {
        id,
        page: 0,
        search: "",
        sort: "desc",
        limit: "5",
        send: {
          is_delete: true,
          slug: collectAllProducts,
        },
      },
      title,
    })
    setIsEventCard(true)
  }

  const handleChange = no => {
    // setTableIndex(index);
    if (
      no > 0 &&
      no <= new Array(metaData?.totalPages).fill(undefined).length
    ) {
      setTableIndex(no - 1)
    }
    if (
      no > 1 &&
      no < new Array(metaData?.totalPages).fill(undefined).length - 1
    ) {
      if (no < btnNo[1]) {
        setBtnNo([btnNo[0] - 1, btnNo[1] - 1, btnNo[2] - 1])
      } else if (no > btnNo[1]) {
        setBtnNo([btnNo[0] + 1, btnNo[1] + 1, btnNo[2] + 1])
      }
    }
  }

  const handleLastBtn = () => {
    setTableIndex(new Array(metaData?.totalPages).fill(undefined).length - 1)
    setBtnNo([
      new Array(metaData?.totalPages).fill(undefined).length - 3,
      new Array(metaData?.totalPages).fill(undefined).length - 2,
      new Array(metaData?.totalPages).fill(undefined).length - 1,
    ])
    dispatch(
      getEmailList({
        page: new Array(metaData?.totalPages).fill(undefined).length - 1,
        limit: 5,
        sort: "desc",
        search: "",
      }),
    )
  }
  const handleFirstBtn = () => {
    setTableIndex(0)
    setBtnNo([1, 2, 3])
    dispatch(
      getEmailList({
        page: 0,
        limit: 5,
        sort: "desc",
        search: "",
      }),
    )
  }

  // const handleCheckBoxChange = (index) => {
  //   setMailList((oldList) => {
  //     let newList = JSON.parse(JSON.stringify(oldList));

  //     newList[tableIndex][index].checked = !newList[tableIndex][index].checked;

  //     return newList;
  //   });
  // };

  // const handleSelectAllAndCheck = () => {
  //   const slcList = selectedList.length;

  //   const depList =
  //     (mailList.length - 1) * 5 + mailList[mailList.length - 1]?.length;

  //   setMailList((oldList) => {
  //     let newList = JSON.parse(JSON.stringify(oldList));
  //     //eslint-disable-next-line
  //     newList.forEach((item) => {
  //       item.forEach((item2) => {
  //         item2.checked = slcList === depList ? false : true;
  //       });
  //     });
  //     return newList;
  //   });

  //   setSelectAllChecked((oldList) => {
  //     const newList = JSON.parse(JSON.stringify(oldList));
  //     newList.forEach((item) => {
  //       item.checked = slcList === depList ? false : true;
  //     });
  //     return newList;
  //   });
  // };
  // useEffect(() => {
  //   let empArr = [];
  //   mailList.forEach((item) => {
  //     item.forEach((item2) => {
  //       if (item2.checked === true) {
  //         empArr.push(item);
  //       }
  //     });
  //   });
  //   setSelectedList(empArr);
  // }, [mailList]);

  const handleSelectAllAndCheck = value => {
    // const slcList = selectedList.length;
    // const depList = 5;

    setData(oldList => {
      let newList = JSON.parse(JSON.stringify(oldList))
      //eslint-disable-next-line
      newList.forEach(item => {
        item.checked = value
      })
      return newList
    })

    // setSelectAllChecked((oldList) => {
    //   const newList = JSON.parse(JSON.stringify(oldList));
    //   newList.forEach((item) => {
    //     item.checked = slcList === depList ? false : true;
    //   });
    //   return newList;
    // });
  }

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )
  // useEffect(() => {
  //   dispatch(getEmailList({ page: 0, limit: 5, sort:"desc", search: "" }));
  // }, []);

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      dispatch(
        getEmailList({
          page:
            Object.keys(pageDetails).length > 0 &&
            pageDetails?.page !== undefined
              ? pageDetails?.page
              : "0",
          limit: records,
          search:
            Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
          sort: "desc",
        }),
      )
      setCheck(true)
      dispatch(setPageDetails({ page: metaData?.page, search }))
      return
    }

    const timeoutId = setTimeout(async () => {
      dispatch(getEmailList({ limit: records, page: 0, search, sort: "desc" }))
      setTableIndex(0)
      setBtnNo([1, 2, 3])
      if (selectAllProducts) {
        setSelectAllProducts(false)
        setCollectAllProducts([])
      }
    }, 1000)
    dispatch(setPageDetails({ page: metaData?.page, search }))
    setSearchTimeout(timeoutId)
  }, [dispatch, records, search])

  useEffect(() => {
    if (pageDetails?.btnNo) {
      setBtnNo(pageDetails?.btnNo)
    }

    dispatch(setPageDetails({ page: metaData?.page, search }))
  }, [metaData])

  const [checkboxState, setCheckboxState] = useState(false)
  const [selectAllProducts, setSelectAllProducts] = useState(false)
  const [collectAllProducts, setCollectAllProducts] = useState([])
  const handleTableCheck = e => {
    // const list = document.getElementsByClassName("checkbox-animated");
    // new Array(metaData?.total).fill(undefined).forEach((item, index) => {
    //   if (e.target.checked) {
    //     if (list[index]) {
    //       list[index].checked = true;
    //     }
    //   } else {
    //     if (list[index]) {
    //       list[index].checked = false;
    //     }
    //   }
    // });
    setCheckboxState(!checkboxState)
  }

  useEffect(() => {
    const updateListData = async () => {
      if (emailList?.length > 0) {
        const newList = JSON.parse(JSON.stringify(emailList))

        newList.forEach(item => {
          item["checked"] = false
        })
        setData(newList)
      } else {
        setData([])
      }
    }
    updateListData()

    if (checkedCollection.length > 0) {
      checkedCollection.map(item => {
        if (item.page === metaData.page) {
          setData(oldList => {
            let newList = JSON.parse(JSON.stringify(oldList))
            newList.map(data => {
              if (item.collection.includes(data._id)) {
                data.checked = true
              }
            })

            return newList
          })
        }
      })
    }
  }, [emailList])

  // const [tableLoading, setTableLoading] = useState(false)
  // const [tableCount, setTableCount] = useState(null)

  // useEffect(() => {
  //   if (tableCount) {
  //     clearTimeout(tableCount)
  //   }
  //   const count = setTimeout(() => {
  //     // if (emailLoading) {
  //     //   setTableLoading(true);
  //     // } else {
  //     //   setTableLoading(false);
  //     // }
  //     setTableLoading(emailLoading)
  //   }, 1000)
  //   setTableCount(count)
  // }, [emailLoading])

  const { tableLoading } = useLoaderDelay({ loadingState: emailLoading })

  useEffect(() => {
    const idArr = emailList?.map(item => {
      return item.slug
    })
    if (selectAllProducts) {
      setCollectAllProducts(idArr)
    } else {
      setCollectAllProducts([])
    }
  }, [selectAllProducts])

  useEffect(() => {
    if (selectAllProducts && collectAllProducts.length === 0) {
      setSelectAllProducts(false)
      setCollectAllProducts([])
      return
    }
  }, [collectAllProducts])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  //
  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
                api={getEmailList}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
                api={getEmailList}

              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
              api={getEmailList}

            />
          )}
          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Email Management</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
                {/* <div className="mch2">
                  <div className="mch2-duration">
                    August 1,2020 - August 31,2020
                  </div>
                  <div
                    className="mch2-action"
                    onClick={() => setAction(!action)}
                  >
                    <span className="">Action</span>
                    <span className="material-symbols-rounded expand">
                      expand_more
                    </span>
                    {action && (
                      <div className="action-tooltip">
                        <span className="">Download</span>
                        <span className="">Print</span>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
              <div className='data-table-container'>
                <div className='heading-tools'>
                  <h3>Email List</h3>
                  <div className='table-search'>
                    <div className='col1 search-container'>
                      <SearchBox
                        customSetter={setSearch}
                        customState={search}
                      />
                      {Object.values(privileges["email_management"]).includes(
                        "write",
                      ) && (
                        <button
                          className='btn-primary header-btn'
                          onClick={() => navigate("/email/add-email")}
                        >
                          Add
                        </button>
                      )}
                      {Object.values(privileges["email_management"]).includes(
                        "delete",
                      ) && selectAllProducts ? (
                        <button
                          className='btn-secondary header-btn delete-primary-button'
                          onClick={() => {
                            deleteFunction("all products")
                          }}
                        >
                          <span className='material-symbols-outlined medium'>
                            delete
                          </span>{" "}
                          Delete{" "}
                          {collectAllProducts.length > 1
                            ? "All " + collectAllProducts.length
                            : ""}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* {selectedList.length > 0 && (
                  <div className="selectAll-container">
                    <div className="selectedDetails">
                      <span>
                        {selectedList?.length} /{" "}
                        {(mailList.length - 1) * 5 +
                          mailList[mailList.length - 1]?.length}{" "}
                        Emails selected.
                      </span>{" "}
                      <span
                        className="slc-btn"
                        onClick={handleSelectAllAndCheck}
                      >
                        {selectedList.length ===
                        (mailList.length - 1) * 5 +
                          mailList[mailList.length - 1]?.length
                          ? "Unselect"
                          : "Select"}{" "}
                        all{" "}
                        {(mailList.length - 1) * 5 +
                          mailList[mailList.length - 1]?.length}{" "}
                        emails
                      </span>
                    </div>
                    <div className="selectedAction">
                      {" "}
                      <button
                        className="btn-primary header-btn"
                        onClick={() => {
                          setSelectedList([]);
                          setSelectAllChecked([
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                          ]);
                          setDeleteAllPopup(!deleteAllPopup);
                          setMailList(
                            new Array(metaData?.totalPages).fill(undefined)
                          );
                        }}
                      >
                        <span className="material-symbols-outlined medium">
                          Delete
                        </span>
                      </button>
                    </div>
                  </div>
                )} */}
                {tableLoading ? (
                  <Skelaton col={4} row={data?.length ? data?.length : 5} />
                ) : data?.length > 0 ? (
                  <>
                    <div className='data-table-box scrollbar-visible'>
                      <table className='data-table fix-width-table-row'>
                        <thead>
                          <tr>
                            {/* eslint-disable-next-line */}
                            {/* {selectAllChecked?.map((item, index) => {
                          const { checked } = item;
                          if (index === tableIndex) {
                            return (
                              <th className="tb-head-name checkNox" key={index}>
                                <input
                                  id={index}
                                  type="checkbox"
                                  className="checkbox-animated"
                                  checked={checked}
                                  onClick={(e) => handleTableCheck(e)}
                                  onChange={() => handleCheckBoxChange(index)}
                                />
                                <label htmlFor={index}>
                                  <span></span>
                                </label>
                              </th>
                            );
                          }
                        })} */}
                            <th className='tb-head-name padding-07 '>
                              <div>
                                <span>
                                  <input
                                    name='checkbox'
                                    id='checkbox'
                                    type='checkbox'
                                    className='checkbox-animated'
                                    onChange={() =>
                                      setSelectAllProducts(!selectAllProducts)
                                    }
                                    checked={selectAllProducts}
                                  />
                                  <label htmlFor={"checkbox"}>
                                    <span className='filter-check-background'></span>
                                  </label>
                                </span>
                              </div>
                            </th>
                            <th>Title</th>
                            <th>Created Date</th>
                            {Object.values(
                              privileges["email_management"],
                            ).includes("read") && <th>Action</th>}
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item, index) => {
                            const { title, createdAt, slug, checked, num } =
                              item

                            return (
                              <tr key={index}>
                                <td className='tb_data padding-07'>
                                  {selectAllProducts ? (
                                    <span
                                      onChange={
                                        e => e.stopPropagation()
                                        // setSelectAllProducts(
                                        //   !selectAllProducts,
                                        // )
                                      }
                                    >
                                      <input
                                        name={"checkbox" + index}
                                        id={"checkbox" + index}
                                        type='checkbox'
                                        className='checkbox-animated'
                                        onChange={e => {
                                          e.stopPropagation()
                                          setCollectAllProducts(oldList => {
                                            let newList = JSON.parse(
                                              JSON.stringify(oldList),
                                            )

                                            if (newList.includes(slug)) {
                                              newList.splice(
                                                newList.indexOf(slug),
                                                1,
                                              )
                                            } else {
                                              newList.unshift(slug)
                                            }

                                            return newList
                                          })
                                        }}
                                        checked={collectAllProducts.includes(
                                          slug,
                                        )}
                                      />
                                      <label htmlFor={"checkbox" + index}>
                                        <span className='filter-check-background'></span>
                                      </label>
                                    </span>
                                  ) : (
                                    num + "."
                                  )}
                                </td>
                                <td>{title}</td>
                                <td>{unixTimeToReadableFormat(createdAt)}</td>
                                <td className='padding-07'>
                                  <div className='email-action-box'>
                                    {Object.values(
                                      privileges["email_management"],
                                    ).includes("read") && (
                                      <button
                                        className='icon-btn see'
                                        onClick={() => {
                                          dispatch(
                                            setPageDetails({
                                              ...pageDetails,
                                              btnNo,
                                            }),
                                          )
                                          navigate(`/email/edit-email/${slug}`)
                                        }}
                                      >
                                        <span className='material-symbols-outlined medium'>
                                          visibility
                                        </span>
                                      </button>
                                    )}

                                    {Object.values(
                                      privileges["email_management"],
                                    ).includes("delete") && (
                                      <button
                                        className='icon-btn delete'
                                        onClick={() => {
                                          setIsEventCard(true)
                                          setPopupData({
                                            data: {
                                              slug,
                                              page: 0,
                                              search: "",
                                              sort: "desc",
                                              limit: "5",
                                              send: {
                                                is_delete: true,
                                                slug: [slug],
                                              },
                                            },
                                            title,
                                          })
                                        }}
                                      >
                                        <span className='material-symbols-outlined medium'>
                                          Delete
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                    {/* <div className="pagination-container">
                      <div className="pagination-box flex-center">
                        <button
                          className="pagBTN flex-center"
                          onClick={() => {
                            handleChange(tableIndex);
                            if (tableIndex > 0) {
                              dispatch(
                                getEmailList({
                                  page: tableIndex - 1,
                                  limit: 5,
                                  sort:"desc",
                                  search: "",
                                })
                              );
                            }
                          }}
                        >
                          <span className="material-symbols-outlined small">
                            west
                          </span>
                        </button>
                        {new Array(metaData?.totalPages).fill(undefined)
                          .length <= 3 ? (
                          new Array(metaData?.totalPages)
                            .fill(undefined)
                            ?.map((item, index) => {
                              return (
                                <button
                                  key={index}
                                  className={
                                    index === tableIndex
                                      ? "pagBTN active"
                                      : "pagBTN"
                                  }
                                  onClick={() => {
                                    setTableIndex(index);
                                    dispatch(
                                      getEmailList({
                                        page: index,
                                        limit: 5,
                                        sort:"desc",
                                        search: "",
                                      })
                                    );
                                  }}
                                >
                                  {index + 1}
                                </button>
                              );
                            })
                        ) : new Array(metaData?.totalPages).fill(undefined)
                            .length > 3 &&
                          tableIndex + 1 <
                            new Array(metaData?.totalPages).fill(undefined)
                              .length -
                              1 ? (
                          <>
                            {btnNo?.map((item, i) => {
                              return (
                                <button
                                  key={i}
                                  className={
                                    item === tableIndex + 1
                                      ? "pagBTN active"
                                      : "pagBTN"
                                  }
                                  onClick={() => {
                                    handleChange(item);
                                    dispatch(
                                      getEmailList({
                                        page: item - 1,
                                        limit: 5,
                                        sort:"desc",
                                        search: "",
                                      })
                                    );
                                  }}
                                >
                                  {item}
                                </button>
                              );
                            })}{" "}
                            <span className="page-separation">...</span>
                            <button
                              className={
                                tableIndex ===
                                new Array(metaData?.totalPages).fill(undefined)
                                  .length -
                                  1
                                  ? "pagBTN active"
                                  : "pagBTN"
                              }
                              onClick={handleLastBtn}
                            >
                              {
                                new Array(metaData?.totalPages).fill(undefined)
                                  .length
                              }
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className={
                                tableIndex === 0 ? "pagBTN active" : "pagBTN"
                              }
                              onClick={handleFirstBtn}
                            >
                              1
                            </button>{" "}
                            <span className="page-separation">...</span>
                            {[
                              new Array(metaData?.totalPages).fill(undefined)
                                .length - 2,
                              new Array(metaData?.totalPages).fill(undefined)
                                .length - 1,
                              new Array(metaData?.totalPages).fill(undefined)
                                .length,
                            ]?.map((item, i) => {
                              return (
                                <button
                                  key={i}
                                  className={
                                    item === tableIndex + 1
                                      ? "pagBTN active"
                                      : "pagBTN"
                                  }
                                  onClick={() => {
                                    handleChange(item);
                                    dispatch(
                                      getEmailList({
                                        page: item - 1,
                                        limit: 5,
                                        sort:"desc",
                                        search: "",
                                      })
                                    );
                                  }}
                                >
                                  {item}
                                </button>
                              );
                            })}{" "}
                          </>
                        )}
                        <button
                          className="pagBTN  flex-center"
                          onClick={() => {
                            handleChange(tableIndex + 2);
                            if (
                              tableIndex <
                              new Array(metaData?.totalPages).fill(undefined)
                                .length -
                                1
                            ) {
                              dispatch(
                                getEmailList({
                                  page: tableIndex + 1,
                                  limit: 5,
                                  sort:"desc",
                                  search: "",
                                })
                              );
                              handleChange(tableIndex + 2);
                            }
                          }}
                        >
                          <span className="material-symbols-outlined small">
                            east
                          </span>
                        </button>
                      </div>
                    </div> */}
                    <Pagination
                      sliceName={"email"}
                      search={search}
                      filter={"desc"}
                      api={getEmailList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                      callbackFunction={() => {
                        setSelectAllProducts(false)
                        setCollectAllProducts([])
                      }}
                    />
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteEmail}
              popUpData={popUpData}
              customFunction={() => {
                setSelectAllProducts(false)
                setCollectAllProducts([])
              }}
              toastMessage={"Email removed successfully"}
            />
          )}{" "}
          {deleteAllPopup && (
            <ConformationPopup
              customState={deleteAllPopup}
              customSetter={setDeleteAllPopup}
            />
          )}{" "}
        </div>
      </div>
    </div>
  )
}

export default Email
