import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { ClipLoader } from "react-spinners"
import { convertToWebP } from "../helper/helper"
import { useParams } from "react-router-dom"

const ImagePreviewBox = ({
  customState,
  customSetter,
  imageChangeApi,
  slug,
  incomingImage,
}) => {
  const { id } = useParams()
  const [addStyle, setAddStyle] = useState(true)
  const dispatch = useDispatch()
  const [profilePic, setProfilePic] = useState(
    require("../assets/images/user(1).webp"),
  )
  const [imgInfo, setImgInfo] = useState("")
  const [loader, setLoader] = useState(false)
  const inputFile_1 = useRef(null)
  const label = slug ? slug.trim() : "profile_picture"
  useEffect(() => {
    if (incomingImage) {
      setProfilePic(incomingImage ? incomingImage : profilePic)
    }
  }, [incomingImage])

  const handleIMg = async event => {
    if (!event.currentTarget.files[0]) {
      return
    } else {
      const selectedFile = event.currentTarget.files[0]
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/webp" ||
        selectedFile.type === "image/png" ||
        selectedFile.type === "image/jpg"
      ) {
        if (selectedFile) {
          if (selectedFile.size > 5 * 1024 * 1024) {
            toast.warning(
              "File size exceeds 5MB limit. Please select a smaller file.",
            )
            event.currentTarget.value = null
          } else {
            setProfilePic(selectedFile)
            setImgInfo(URL.createObjectURL(selectedFile))
          }
        }
      } else {
        toast.warning("please select jpeg, jpg, png and webp files only")
        event.target.value = null
      }
    }
  }

  const onSubmitFunction = async () => {
    if (imgInfo) {
      setLoader(true)
      const convertedFile = await convertToWebP(profilePic)
      if (convertedFile) {
        const response = await dispatch(
          id
            ? imageChangeApi({ id, send: { [label]: convertedFile } })
            : imageChangeApi({ [label]: convertedFile }),
        )
        if (response?.payload) {
          handleClick()
          setLoader(false)
        }
      }
    }
  }
  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (customState) {
      const styleTimer = setTimeout(() => {
        customSetter(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])
  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "popPic" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        {/* <h2>Profile Preview</h2> */}
        <div className='alpha'>
          <div className='imageContainer'>
            {!loader && (
              <div
                className='changeDiv'
                onClick={() => inputFile_1.current.click()}
              >
                Change
                <input
                  style={{ display: "none" }}
                  type='file'
                  ref={inputFile_1}
                  accept='image/*'
                  onChange={event => {
                    handleIMg(event)
                  }}
                />
              </div>
            )}

            <img
              src={typeof profilePic === typeof "" ? profilePic : imgInfo}
              alt='profile'
            />
          </div>

          <div className='fieldsContainer'>
            <div className='info'>
              * Accepted image formats include webp, jpg, and png.
            </div>
            <div className='info'>
              * Ensure that the image file size does not exceed 5MB.
            </div>
          </div>
        </div>
        <div className='beta'>
          {!loader && (
            <button className='btn-secondary' onClick={() => handleClick()}>
              Discard
            </button>
          )}
          {loader ? (
            <button className='btn-primary' style={{ cursor: "not-allowed" }}>
              {" "}
              <ClipLoader
                loading={true}
                size={15}
                aria-label='Loading Spinner'
                data-testid='loader'
              />
            </button>
          ) : (
            <button className='btn-primary' onClick={() => onSubmitFunction()}>
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImagePreviewBox
