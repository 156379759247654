/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
// import { deleteEmail, getEmailList } from "../redux/EmailApi";
import FilterBox from "../../../components/FilterBox"
import SearchBox from "../../../components/SearchBox"
import Skelaton from "../../../components/Skelaton"
// import { getInventoryList } from "../redux/InventoryApi"
import SalesTable from "../components/SalesTable"
import SalesOrderNumberPopup from "../components/SalesOrderNumberPopup"

const Sales = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Sales Management")
  }, [setTitle])
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [active, setActive] = useState("sales_details")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const [isEventCard, setIsEventCard] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [showSalesNumber, setShowSalesNumber] = useState(false)
  const { inventoryList } = useSelector(state => state.inventory)

  const [checkedCollection, setCheckedCollection] = useState([])
  const [filterTable, setFilterTable] = useState({
    stock_quantity: true,
    sold: true,
    created_at: true,
    minimum_stock_quantity: true,
  })
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const { inventoryLoading, metaData } = useSelector(store => store.inventory)
  const [searchShow, setSearchShow] = useState(false)
  const [activePo, setActivePo] = useState(1)

  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )

  //   useEffect(() => {
  //     if (searchTimeout || check) {
  //       clearTimeout(searchTimeout)
  //     } else {
  //       dispatch(
  //         getInventoryList({
  //           page: pageDetails?.page ? pageDetails?.page : 0,
  //           limit: records,
  //           search:
  //             Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
  //           sort: "desc",
  //         }),
  //       )
  //       setCheck(true)
  //       dispatch(setPageDetails({ page: metaData?.page, search }))
  //       return
  //     }

  //     const timeoutId = setTimeout(async () => {
  //       dispatch(
  //         getInventoryList({ limit: records, page: 0, search, sort: "desc" }),
  //       )
  //       setTableIndex(0)
  //       setBtnNo([1, 2, 3])
  //     }, 1000)
  //     dispatch(setPageDetails({ page: metaData?.page, search }))
  //     setSearchTimeout(timeoutId)
  //   }, [dispatch, records, search])

  //   useEffect(() => {
  //     dispatch(setPageDetails({ page: metaData?.page, search }))
  //   }, [metaData])

  const [tableLoading, setTableLoading] = useState(false)
  const [tableCount, setTableCount] = useState(null)

  useEffect(() => {
    if (tableCount) {
      clearTimeout(tableCount)
    }
    const count = setTimeout(() => {
      setTableLoading(inventoryLoading)
    }, 1000)
    setTableCount(count)
  }, [inventoryLoading])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  // Data for Purchase Orders
  const SalesOrder = [
    {
      order_number: "O-007845",
      so_number: "PO-007845",
      delivery_date: "13/03/24",
      creation_date: "13/03/24, 12:00 AM",
      company: "Bmo Paint",
      delivery_status: "Received",
      creation_status: "Draft",
      total: "₹15,500",
      created_by: "Billy",
    },
    {
      order_number: "O-007845",
      so_number: "PO-007845",
      delivery_date: "13/03/24",
      creation_date: "13/03/24, 12:00 AM",
      company: "Bmo Paint",
      delivery_status: "Received",
      creation_status: "Draft",
      total: "₹15,500",
      created_by: "Billy",
    },
  ]

  // Data for Purchase Returns
  const salesProforma = [
    {
      invoice_number: "io-007845",
      company: "Bmo Paint",
      title: "lorem",
      delivery_date: "13/03/24",
      creation_date: "13/03/24, 12:00 AM",
      creation_status: "Draft",
      total: "₹15,500",
      created_by: "Billy",
    },
    {
      invoice_number: "io-007847",
      company: "asain Paint",
      title: "lorem",
      delivery_date: "15/03/24",
      creation_date: "15/03/24, 12:00 AM",
      creation_status: "Draft",
      total: "₹135,500",
      created_by: "Cami",
    },
  ]

  const salesReturn = [
    {
      sales_return_no: "SO-007845",
      proforma_no: "SO-007845",
      company: "Bmo Paint",
      delivery_date: "13/03/24",
      creation_date: "13/03/24, 12:00 AM",
      created_by: "Billy",
      item_qty: "5",
      return_qty: "5",
    },
    {
      sales_return_no: "SO-007845",
      proforma_no: "SO-007845",
      company: "Bmo Paint",
      delivery_date: "13/03/24",
      creation_date: "13/03/24, 12:00 AM",
      created_by: "Billy",
      item_qty: "5",
      return_qty: "5",
    },
  ]

  const deliveryChallan = [
    {
      schallan_no: "SO-007845",
      so_number: "SO-007845",
      company: "Bmo Paint",
      delivery_date: "13/03/24",
      creation_date: "13/03/24, 12:00 AM",
      total: "₹15,500",
      item_qty: "5",
    },
    {
      schallan_no: "SO-007845",
      so_number: "SO-007845",
      company: "Bmo Paint",
      delivery_date: "13/03/24",
      creation_date: "13/03/24, 12:00 AM",
      total: "₹15,500",
      item_qty: "5",
    },
  ]

  const salesPerson = [
    {
      salesPerson: "John Jacob",
      brand: "Jotun",
      month: "August",
      year: "2024",
      sales_order: "115",
      total: "₹15,500",
    },
    {
      salesPerson: "John Jacob",
      brand: "Jotun",
      month: "August",
      year: "2024",
      sales_order: "115",
      total: "₹15,500",
    },
  ]
  const Orderheaders = [
    { key: "order_number", label: "Order Number" },
    { key: "so_number", label: "SO Number" },
    { key: "company", label: "Company" },
    { key: "delivery_date", label: "Delivery Date" },
    { key: "delivery_status", label: "Delivery Status" },
    { key: "creation_status", label: "Creation Status" },
    { key: "creation_date", label: "Creation Date" },
    { key: "total", label: "Total Amount" },
    { key: "created_by", label: "Created By" },
  ]

  const proformaheaders = [
    { key: "invoice_number", label: "Invoice Number" },
    { key: "company", label: "Company" },
    { key: "title", label: "Title" },
    { key: "delivery_date", label: "Delivery Date" },
    { key: "creation_status", label: "Creation Status" },
    { key: "creation_date", label: "Creation Date" },
    { key: "total", label: "Total Amount" },
    { key: "created_by", label: "Created By" },
  ]

  const Returnheaders = [
    { key: "sales_return_no", label: "Sales Return No." },
    { key: "proforma_no", label: "Sales Proforma Invoice No." },
    { key: "company", label: "Company name" },
    { key: "creation_date", label: "Creation Date" },
    { key: "delivery_date", label: "Delivery Date" },
    { key: "created_by", label: "Created By" },
    { key: "item_qty", label: "No. of Items" },
    { key: "return_qty", label: "Sales Return Qty" },
  ]

  const deliveryChallanheaders = [
    { key: "schallan_no", label: "Challan No." },
    { key: "so_number", label: "Sales Order No." },
    { key: "company", label: "Company name" },
    { key: "creation_date", label: "Creation Date" },
    { key: "delivery_date", label: "Delivery Date" },
    { key: "item_qty", label: "No. of Items" },
    { key: "total", label: "Total Amount" },
  ]

  const salesPersonheaders = [
    { key: "salesPerson", label: "Sales Person" },
    { key: "brand", label: "Brand" },
    { key: "month", label: "Month" },
    { key: "year", label: "Year" },
    { key: "sales_order", label: "Total Sales Order" },
    { key: "total", label: "Total Amount" },
  ]
  // Create array2 with rows of values
  const Orderlistcontent = SalesOrder.map(row =>
    Orderheaders.map(header => row[header.key]),
  )
  const Proformalistcontent = salesProforma.map(row =>
    proformaheaders.map(header => row[header.key]),
  )
  const Returnlistcontent = salesReturn.map(row =>
    Returnheaders.map(header => row[header.key]),
  )
  const callanistcontent = deliveryChallan.map(row =>
    deliveryChallanheaders.map(header => row[header.key]),
  )
  const perosnlistcontent = salesPerson.map(row =>
    salesPersonheaders.map(header => row[header.key]),
  )

  const headerToDisplay =
    activePo === 1
      ? Orderheaders
      : activePo === 2
        ? proformaheaders
        : activePo === 3
          ? Returnheaders
          : activePo === 4
            ? deliveryChallanheaders
            : salesPersonheaders

  const dataToDisplay =
    activePo === 1
      ? Orderlistcontent
      : activePo === 2
        ? Proformalistcontent
        : activePo === 3
          ? Returnlistcontent
          : activePo === 4
            ? callanistcontent
            : perosnlistcontent

  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        <div className='home-container'>
          {showSalesNumber && (
            <SalesOrderNumberPopup
              customState={showSalesNumber}
              customSetter={setShowSalesNumber}
              activePo={activePo}
            />
          )}

          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Sales Order</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>
              <div className='data-table-container pur-data'>
                <div className='col1 heading-tools'>
                  <div className='p-change'>
                    <span
                      className={activePo === 1 ? "active" : ""}
                      onClick={() => setActivePo(1)}
                    >
                      Sales Order
                    </span>
                    <span
                      className={activePo === 3 ? "active" : ""}
                      onClick={() => setActivePo(3)}
                    >
                      Sales Return
                    </span>
                    <span
                      className={activePo === 2 ? "active" : ""}
                      onClick={() => setActivePo(2)}
                    >
                      Sales Proforma Invoice
                    </span>
                    <span
                      className={activePo === 4 ? "active" : ""}
                      onClick={() => setActivePo(4)}
                    >
                      Delivery Challan
                    </span>
                    <span
                      className={activePo === 5 ? "active" : ""}
                      onClick={() => setActivePo(5)}
                    >
                      Sales Person
                    </span>
                  </div>
                </div>
                <div className='table-search sales'>
                  <div className='search-container'>
                    <div className='search-filter-flex'>
                      <SearchBox
                        customSetter={setSearch}
                        customState={search}
                      />
                      <FilterBox
                        customState={filterTable}
                        customSetter={setFilterTable}
                        customPosition={{ right: "0", left: "auto" }}
                      />
                      {Object.values(privileges["sales_management"]).includes(
                        "write",
                      ) &&
                        activePo !== 5 && (
                          <button
                            className='btn-alpha header-btn  '
                            onClick={() => {
                              activePo === 1
                                ? navigate("/sales/sales-order")
                                : setShowSalesNumber(true)

                              // activePo === 2
                              //   ? navigate("/sales/sales-proforma-invoice")
                              //   : activePo === 3
                              //     ? navigate("/sales/sales-return")
                              //     : navigate("/sales/delivery-challan")
                            }}
                          >
                            {activePo === 1
                              ? "Create Sales Order"
                              : activePo === 2
                                ? "Create Invoice"
                                : activePo === 3
                                  ? "Create Sales Return"
                                  : "Create Delivery Challan"}
                          </button>
                        )}{" "}
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton col={6} row={inventoryList?.length} />
                ) : dataToDisplay?.length > 0 ? (
                  <>
                    <SalesTable
                      filterTable={filterTable}
                      listContent={dataToDisplay}
                      listHeader={headerToDisplay}
                      activePo={activePo}
                    />

                    {/* <Pagination
                      sliceName={"inventory"}
                      search={search}
                      filter={"desc"}
                      api={getInventoryList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                    /> */}
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sales
