/* eslint-disable no-useless-escape */
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { toast } from "react-toastify"
import * as Yup from "yup"
import DropDownBox from "../../../components/DropDownBox"
import SearchDropDown from "../../../components/SearchDropDown"
import { addProducts, getHsnList, getOptionsList } from "../redux/ProductsApi"
import Tooltip from "../../../components/Tooltip"
import {
  convertRupeesToNumber,
  findProductType,
  findValueFromPercentage,
  numberWithCommas,
  priceValidator,
} from "../../../helper/helper"

const AddNewProduct = ({ popup, setPopup }) => {
  const dispatch = useDispatch()

  // const [activeInputs, setActiveInputs] = useState(false);
  // const [gstn, setGstn] = useState(false);
  const [addStyle, setAddStyle] = useState(true)
  const [priceFieldDisabler, setPriceFieldDisabler] = useState("")
  const [focusedField, setFocusedField] = useState()
  const { productsLoading, optionsLoading } = useSelector(
    store => store.products,
  )
  const { role } = useSelector(store => store.auth)
  const [uomId, setUomId] = useState("")
  const [categoryReset, setCategoryReset] = useState(false)
  const [subCategoryReset, setSubCategoryReset] = useState(false)
  const [subChildCategoryReset, setSubChildCategoryReset] = useState(false)
  const initialValues = {
    // skuNum: "",
    productName: "",
    description: "",
    brand: "",
    category: "",
    subCategory: "",
    subChildCategory: "",
    color: "",
    packSize: "",
    paintType: "",
    finish: "",
    unitPrice: "",
    costPrice: "",
    sellingPrice: "",
    hsnCode: "",
    uom: "",
    hsnId: "",
    productCode: "",
    // batchNumber: "",
    productType: "",
    marginPercentage: "",
    packQuantity: "",
    standard: "",
  }
  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object({
    // skuNum: Yup.string().required("SKU number is required"),
    productName: Yup.string()
      .trim()
      .min(2, "Product name must be at least 2 characters long")
      .max(80, "Name must be less than 80 characters")
      .required("Product name is required"),
    description: Yup.string()
      .trim()
      .max(150, "Description must be less than 150 characters"),
    // .required("Description is required"),
    brand: Yup.string().required("Brand name is required"),
    category: Yup.string().required("Category name is required"),
    subCategory: Yup.string(),
    category: Yup.string().required("Category name is required"),
    productType: Yup.string().required("Product type is required"),

    subChildCategory: Yup.string(),
    color: Yup.object(),
    packSize: Yup.string().required("Pack size is required"),
    packQuantity: Yup.string()
      .required("Pack quantity is required")
      .test(
        "quantity",
        "Pack quantity can not be greater than Pack Size",
        value => {
          if (Number(value) > Number(formik.values.packSize)) {
            return false
          } else {
            return true
          }
        },
      ),
    paintType: Yup.string(),
    // .required("Paint type is required"),
    finish: Yup.string(),
    // .required("Finish is required"),
    unitPrice: Yup.string().required("Unit price is required").matches(/[\d]/),
    costPrice: Yup.string().required("Cost price is required").matches(/[\d]/),
    sellingPrice: Yup.string()
      .required("selling price is required")
      .matches(/[\d]/),
    hsnCode: Yup.string()

      .trim()
      .min(2, "HSN code must be at least 2 characters long")
      .max(8, "HSN code must be less than 8 characters")
      .required("HSN code is required"),
    hsnId: Yup.string().required("HSN code is required"),
    productCode: Yup.string().min(1).max(12),
    // .required("Product code is required"),
    // batchNumber: Yup.string().min(1).max(17),
    // .required("Batch number is required"),
    uom: Yup.string().required("UOM is required"),
    marginPercentage: Yup.string().required("Margin percentage is required"),
    standard: Yup.string().required("Product standard is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        name: values.productName,
        description: values.description,
        brand_id: values.brand,
        category_id: values.category,
        subCategory_id: values.subCategory,
        selling_price: convertRupeesToNumber(values.sellingPrice),
        cost_price: convertRupeesToNumber(values.costPrice),
        unit_price: convertRupeesToNumber(values.unitPrice),
        painttype_id: values.paintType,
        finishtype_id: values.finish,
        color_id: values.color._id,
        subChildCategory_id: values.subChildCategory,
        hsn_id: values.hsnId,
        product_weight: values.packSize,
        uom_id: values.uom,
        product_code: values.productCode,
        // batch_number: values.batchNumber,
        productType: values.productType,
        // margin: values.marginPercentage,
        packQuantity: convertRupeesToNumber(values.packQuantity),
        standard: values.standard,
      }

      const response = await dispatch(addProducts(data))

      if (response?.payload === 200) {
        handleClick()
      }
    },
  })

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (popup) {
      const styleTimer = setTimeout(() => {
        setPopup(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  const [hsnList, setHsnList] = useState([])

  const [optionsList, setOptionsList] = useState({
    uomList: [],
    brandList: [],
    categoryList: [],
    subCategoryList: [],
    subChildCategoryList: [],
  })

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  const apiCall = async (
    listName,
    optionsName,
    dynamicLabel = "name",
    dynamicValue = "_id",
    dynamicApiLabel,
    formikLabel,
  ) => {
    if (listName) {
      const response = await dispatch(
        getOptionsList({
          listName,
          search: "",
          sort: "desc",
          limit: "0",
          page: "0",
          send:
            formikLabel && dynamicApiLabel
              ? {
                  [dynamicApiLabel]: formik.values[formikLabel],
                }
              : undefined,
        }),
      )

      if (optionsName) {
        const options = response?.payload?.map(item => {
          return {
            label: item[dynamicLabel],
            value: optionsName === "categoryList" ? item : item[dynamicValue],
            // value: item[dynamicValue],
          }
        })
        // setOptionsList({ ...optionsList, [optionsName]: options })
        setOptionsList(oldList => {
          let newList = JSON.parse(JSON.stringify(oldList))
          newList[optionsName] = options
          return newList
        })
      }
    }

    // {
    //   const response = await dispatch(
    //     getOptionsList({
    //       listName: "brand-list",
    //       search: "",
    //       sort: "desc",
    //       limit: 0,
    //       page: 0,
    //     }),
    //   )

    //   const options = response?.payload?.map(item => {
    //     return { label: item.name, value: item._id }
    //   })
    //   setBrandList(options)
    // }
    // {
    //   const response = await dispatch(
    //     getOptionsList({
    //       listName,
    //       search: "",
    //       sort: "desc",
    //       limit: 0,
    //       page: 0,
    //     }),
    //   )
    //   const options = response?.payload?.map(item => {
    //     return { label: item.name, value: item._id }
    //   })
    //   setCategoryList(options)
    // }
    // {
    //   const response = await dispatch(
    //     getOptionsList({ listName: "subCategory-list" }),
    //   )
    //   const options = response?.payload?.map(item => {
    //     return { label: item.name, value: item._id }
    //   })
    //   setSubCategoryList(options)
    // }
    // {
    //   const response = await dispatch(
    //     getOptionsList({ listName: "subChildCategory-list" }),
    //   )
    //   const options = response?.payload?.map(item => {
    //     return { label: item.name, value: item._id }
    //   })
    //   setSubChildCategoryList(options)
    // }
    // {
    //   const response = await dispatch(
    //     getOptionsList({ listName: "subChildCategory-list" }),
    //   )
    //   const options = response?.payload?.map(item => {
    //     return { label: item.name, value: item._id }
    //   })
    //   setSubChildCategoryList(options)
    // }
    // {
    //   const response = await dispatch(
    //     getOptionsList({ listName: "color-list" }),
    //   )
    //   if (response.payload.length > 0) {
    //     const options = response?.payload?.map(item => {
    //       return { label: item.name, value: item._id }
    //     })
    //     setColorList(options)
    //   }
    // }
  }

  useEffect(() => {
    if (optionsList?.uomList?.length === 0)
      apiCall("uom-list", "uomList", "uom_type")
  }, [])

  useEffect(() => {
    if (formik.values.brand)
      apiCall(
        "category-list",
        "categoryList",
        undefined,
        undefined,
        "brand_id",
        "brand",
      )

    if (formik.values.category)
      apiCall(
        "subCategory-list",
        "subCategoryList",
        undefined,
        undefined,
        "category_id",
        "category",
      )
    if (formik.values.subCategory)
      apiCall(
        "subChildCategory-list",
        "subChildCategoryList",
        undefined,
        undefined,
        "subCategory_id",
        "subCategory",
      )
  }, [formik.values.brand, formik.values.category, formik.values.subCategory])

  useEffect(() => {
    if (formik.values.productType) {
      const id = optionsList?.uomList?.find(
        item => item.label === findProductType(formik.values.productType),
      )

      if (id) {
        setUomId(id?.value)
        if (formik.values.productType === "sheet") {
          formik.setFieldValue("packSize", "1")
          formik.setFieldValue("packQuantity", "1")
          formik.setFieldValue("uom", id?.value)
        } else {
          formik.setFieldValue("packSize", "")
          formik.setFieldValue("packQuantity", "")
        }
      } else {
        toast.error(`UOM does not have unit for ${formik.values.productType}`)
      }
    }
  }, [formik.values.productType])

  const [searchTimeout, setSearchTimeout] = useState(null)
  const [showHsnBox, setShowHsnBox] = useState(false)
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    const timeoutId = setTimeout(async () => {
      if (formik.values.hsnCode.length > 0) {
        const response = await dispatch(
          getHsnList({ search: formik.values.hsnCode }),
        )
        setHsnList(response.payload)
      }
    }, 1000)

    setSearchTimeout(timeoutId)
  }, [formik.values.hsnCode])

  // useEffect(() => {
  //   if (formik.values.packSize && formik.values.unitPrice) {
  //     formik.setFieldValue(
  //       "costPrice",
  //       `${Number(formik.values.packSize) * Number(formik.values.unitPrice)}`,
  //     )
  //   } else {
  //     formik.setFieldValue("costPrice", "")
  //   }
  // }, [formik.values.packSize, formik.values.unitPrice])

  const calPrice = (value, pack_size, name) => {
    if (name === "unitPrice") {
      document.getElementById("costPrice").disabled = value ? true : false
      let calValue = Number(pack_size) * Number(value)
      formik.setFieldValue(
        "costPrice",
        `${calValue !== 0 ? numberWithCommas(calValue) : ""}`,
      )
    } else if (name === "costPrice") {
      document.getElementById("unitPrice").disabled = value ? true : false
      let calValue = (Number(value) / Number(pack_size)).toFixed(2)
      formik.setFieldValue(
        "unitPrice",
        `${calValue !== 0 ? numberWithCommas(calValue) : ""}`,
      )
    }
  }
  useEffect(() => {
    if (formik.values.costPrice) {
      formik.setFieldValue(
        "sellingPrice",
        numberWithCommas(
          findValueFromPercentage({
            percentage: formik.values.marginPercentage,
            value: formik.values.costPrice,
            returnTotalValue: true,
            packSize: formik.values.packSize,
          }),
        ),
      )
    }
  }, [
    formik.values.costPrice,
    formik.values.marginPercentage,
    formik.values.unitPrice,
  ])

  useEffect(() => {
    if (formik.values.costPrice || formik.values.unitPrice) {
      const isDisabled = document.getElementById("costPrice").disabled
      const value = isDisabled
        ? formik.values.unitPrice
        : formik.values.costPrice
      const name = isDisabled ? "unitPrice" : "costPrice"
      calPrice(value, formik.values.packSize, name)
    }
  }, [formik.values.packSize])
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])
  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>Add Product</h2>
          <button className='exit-btn' onClick={() => handleClick()}>
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>

        <div className='modelEx_content singleAdd'>
          <form className='scrollForm' onSubmit={formik.handleSubmit}>
            <div
              className='form-content'
              style={
                formik.values.productType === "sheet"
                  ? { padding: "1rem 1rem 3rem 1rem" }
                  : { padding: "1rem" }
              }
            >
              <div className='form-group form'>
                <div
                  className={`input-field ${
                    formik.values.productName !== "" ||
                    focusedField === "productName"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='productName' className='toggle-label'>
                    Product Name
                  </label>
                  <input
                    id='productName'
                    name='productName'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.productName}
                    onInput={e => {
                      e.target.value = e.target.value.replace(
                        /(?:^|\s)\S/g,
                        match => match.toUpperCase(),
                      )
                    }}
                    maxLength={81}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("productName")}
                  />
                  {formik.touched.productName && formik.errors.productName ? (
                    <span className='err'>{formik.errors.productName}</span>
                  ) : null}
                </div>{" "}
                <div
                //  className='col-2'
                >
                  {/* <div
                    className={`input-field ${
                      formik.values.skuNum !== "" || focusedField === "skuNum"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='email' className='toggle-label'>
                      SKU Number
                    </label>
                    <input
                      id='skuNum'
                      name='skuNum'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.skuNum}
                      // maxLength={80}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("skuNum")}
                       
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      disabled
                    />
                    {formik.touched.skuNum && formik.errors.skuNum ? (
                      <span className='err'>{formik.errors.skuNum}</span>
                    ) : null}
                  </div> */}

                  <div className='col-2'>
                    <div
                      className='input-field'
                      // onMouseEnter={() => apiCall("brand-list", "brandList")}
                    >
                      <DropDownBox
                        options={[
                          {
                            label: "Paint",
                            value: "paint",
                          },
                          {
                            label: "Putty",
                            value: "putty",
                          },
                          {
                            label: "Powder",
                            value: "powder",
                          },
                          {
                            label: "Sheet",
                            value: "sheet",
                          },
                        ]}
                        customSetter={formik.setFieldValue}
                        dropDownTitle='Product Type'
                        animateDropDownTitle
                        customFormikLabel={"productType"}
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      />
                      {/* <SearchDropDown
                      label='Brand'
                      name='brand'
                      api={getOptionsList}
                      listName={"brand-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                      {formik.touched.productType &&
                      formik.errors.productType ? (
                        <span className='err'>{formik.errors.productType}</span>
                      ) : null}
                    </div>{" "}
                    <div
                      className='input-field'
                      // onMouseEnter={() => apiCall("brand-list", "brandList")}
                    >
                      <DropDownBox
                        options={[
                          {
                            label: "Standard Product",
                            value: "Standard",
                          },
                          {
                            label: "Non Standard Product",
                            value: "Non-Standard",
                          },
                        ]}
                        customSetter={formik.setFieldValue}
                        dropDownTitle='Product Standard'
                        animateDropDownTitle
                        customFormikLabel={"standard"}
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      />
                      {/* <SearchDropDown
                      label='Brand'
                      name='brand'
                      api={getOptionsList}
                      listName={"brand-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                      {formik.touched.standard && formik.errors.standard ? (
                        <span className='err'>{formik.errors.standard}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.productCode !== "" ||
                      focusedField === "productCode"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='productCode' className='toggle-label'>
                      Product Code
                    </label>
                    <input
                      id='productCode'
                      name='productCode'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.productCode}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /(?:^|\s)\S/g,
                          match => match.toUpperCase(),
                        )
                      }}
                      maxLength={12}
                      minLength={1}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("productCode")}
                    />
                    {formik.touched.productCode && formik.errors.productCode ? (
                      <span className='err'>{formik.errors.productCode}</span>
                    ) : null}
                  </div>{" "}
                  {/* <div
                    className={`input-field ${
                      formik.values.batchNumber !== "" ||
                      focusedField === "batchNumber"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='batchNumber' className='toggle-label'>
                      Batch Number
                    </label>
                    <input
                      id='batchNumber'
                      name='batchNumber'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.batchNumber}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /(?:^|\s)\S/g,
                          match => match.toUpperCase(),
                        )
                      }}
                      maxLength={17}
                      minLength={1}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("batchNumber")}
                    />
                    {formik.touched.batchNumber && formik.errors.batchNumber ? (
                      <span className='err'>{formik.errors.batchNumber}</span>
                    ) : null}
                  </div> */}{" "}
                  <div>
                    <div className='hsnBox'>
                      <div
                        className={`input-field ${
                          formik.values.hsnCode !== "" ||
                          focusedField === "hsnCode"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='hsnCode' className='toggle-label'>
                          HSN/SAC Code
                        </label>
                        <input
                          id='hsnCode'
                          name='hsnCode'
                          type='text'
                          className='form-input'
                          onChange={formik.handleChange}
                          value={formik.values.hsnCode}
                          onInput={e => {
                            e.target.value = e.target.value
                              .replace(/[^\d ]/gi, "")
                              .replace(/(?:^|\s)\S/g, match =>
                                match.toUpperCase(),
                              )
                            setShowHsnBox(true)
                          }}
                          maxLength={8}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("hsnCode")}
                        />
                      </div>

                      {formik.values.hsnCode.length > 0 && showHsnBox && (
                        <div className='hsnSelectBox'>
                          {hsnList.length > 0 && !productsLoading ? (
                            hsnList?.map(({ _id, hsn }, index) => (
                              <div
                                key={index}
                                className={
                                  "drop-down-item2" +
                                  (formik.values.hsnCode === hsn
                                    ? " selectedDropBox"
                                    : "")
                                }
                                onClick={() => {
                                  formik.setFieldValue("hsnCode", hsn)
                                  formik.setFieldValue("hsnId", _id)
                                  setShowHsnBox(false)
                                  setHsnList([])
                                }}
                              >
                                <span>{hsn}</span>
                              </div>
                            ))
                          ) : (
                            <div className='drop-down-item2'>
                              {productsLoading ? (
                                <ClipLoader
                                  loading={true}
                                  size={13}
                                  aria-label='Loading Spinner'
                                  data-testid='loader'
                                />
                              ) : (
                                <span>No Data Found</span>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>{" "}
                    {formik.touched.hsnId && formik.errors.hsnId ? (
                      <span className='err'>{formik.errors.hsnId}</span>
                    ) : null}{" "}
                  </div>
                </div>
                <div
                  className={`input-field ${
                    formik.values.description !== "" ||
                    focusedField === "aboutUs"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='description' className='toggle-label'>
                    Product Description
                  </label>
                  <textarea
                    id='description'
                    name='description'
                    rows={4}
                    className={"form-input"}
                    onChange={formik.handleChange}
                    value={formik.values.description || ""}
                    maxLength={151}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("aboutUs")}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <span className='err'>{formik.errors.description}</span>
                  ) : null}
                </div>
                <div className='col-2'>
                  <div
                    className='input-field'
                    onClick={() => {
                      apiCall("brand-list", "brandList")
                    }}
                    // onMouseEnter={() => apiCall("brand-list", "brandList")}
                  >
                    <DropDownBox
                      options={optionsList.brandList}
                      customSetter={formik.setFieldValue}
                      dropDownTitle='Brand'
                      animateDropDownTitle
                      customFormikLabel={"brand"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      showSearchBar
                      loading={optionsLoading}
                      callCustomFunction={value => {
                        formik.setFieldValue("brand", value)
                        if (formik.values.category) {
                          setCategoryReset(true)
                        }
                        if (formik.values.subCategory) {
                          setSubCategoryReset(true)
                        }
                        if (formik.values.subChildCategory) {
                          setSubChildCategoryReset(true)
                        }
                        if (formik.values.marginPercentage) {
                          formik.setFieldValue("marginPercentage", "")
                        }
                      }}
                    />
                    {/* <SearchDropDown
                      label='Brand'
                      name='brand'
                      api={getOptionsList}
                      listName={"brand-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                    {formik.touched.brand && formik.errors.brand ? (
                      <span className='err'>{formik.errors.brand}</span>
                    ) : null}
                  </div>

                  <div
                    className='input-field'
                    onClick={() => {
                      if (!formik.values.brand) {
                        toast.warning("Please Select Brand First")
                      }
                    }}
                  >
                    <DropDownBox
                      options={optionsList.categoryList}
                      customSetter={formik.setFieldValue}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Category'
                      customFormikLabel={"category"}
                      animateDropDownTitle
                      showSearchBar
                      disabled={formik.values.brand ? true : false}
                      loading={optionsLoading}
                      // callCustomFunction={value => {
                      //   formik.setFieldValue(
                      //     "marginPercentage",
                      //     value?.marginPercentage,
                      //   )
                      //   formik.setFieldValue("category", value?._id)
                      resetState={categoryReset}
                      resetSetter={setCategoryReset}
                      callCustomFunction={value => {
                        formik.setFieldValue("category", value?._id)
                        formik.setFieldValue("marginPercentage", value?.margin)

                        if (formik.values.subCategory) {
                          setSubCategoryReset(true)
                        }
                        if (formik.values.subChildCategory) {
                          setSubChildCategoryReset(true)
                        }
                      }}
                      // }}
                    />
                    {/* <SearchDropDown
                      label='Category'
                      name='category'
                      api={getOptionsList}
                      listName={"category-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                    {formik.touched.category && formik.errors.category ? (
                      <span className='err'>{formik.errors.category}</span>
                    ) : null}
                  </div>
                </div>
                {formik.values.productType !== "sheet" && (
                  <>
                    <div className='col-2'>
                      <div
                        className='input-field'
                        onClick={() => {
                          if (!formik.values.category) {
                            toast.warning("Please Select Category First")
                          }
                        }}
                      >
                        <DropDownBox
                          options={optionsList.subCategoryList}
                          customSetter={formik.setFieldValue}
                          dropDownTitle='Sub Category'
                          animateDropDownTitle
                          customFormikLabel={"subCategory"}
                          customDropBoxStyles={{
                            border: "1px solid #d7cece",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          customTextStyle={{ color: "#212229", opacity: "0.7" }}
                          showSearchBar
                          disabled={formik.values.category ? true : false}
                          resetState={subCategoryReset}
                          resetSetter={setSubCategoryReset}
                          callCustomFunction={value => {
                            formik.setFieldValue("subCategory", value)
                            if (formik.values.subChildCategory) {
                              setSubChildCategoryReset(true)
                            }
                          }}
                        />

                        {/* <SearchDropDown
                      label='Sub Category'
                      name='subCategory'
                      api={getOptionsList}
                      listName={"subCategory-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                        {formik.touched.subCategory &&
                        formik.errors.subCategory ? (
                          <span className='err'>
                            {formik.errors.subCategory}
                          </span>
                        ) : null}
                      </div>

                      <div
                        className='input-field'
                        onClick={() => {
                          if (!formik.values.subCategory) {
                            toast.warning("Please Select Sub Category First")
                          }
                        }}
                      >
                        <DropDownBox
                          options={optionsList.subChildCategoryList}
                          customSetter={formik.setFieldValue}
                          customFormikLabel={"subChildCategory"}
                          customDropBoxStyles={{
                            border: "1px solid #d7cece",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          customTextStyle={{ color: "#212229", opacity: "0.7" }}
                          dropDownTitle='Sub Child Category'
                          animateDropDownTitle
                          showSearchBar
                          disabled={formik.values.subCategory ? true : false}
                          resetState={subChildCategoryReset}
                          resetSetter={setSubChildCategoryReset}
                        />

                        {/* <SearchDropDown
                      label='Sub Child Category'
                      name='subChildCategory'
                      api={getOptionsList}
                      listName={"subChildCategory-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                        {formik.touched.subChildCategory &&
                        formik.errors.subChildCategory ? (
                          <span className='err'>
                            {formik.errors.subChildCategory}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-2'>
                      {" "}
                      <div className='col-2 gstn'>
                        {/* <div className='input-field'>
                      <DropDownBox
                        options={[
                          { label: "3", value: "3" },
                          { label: "5", value: "5" },
                          { label: "10", value: "10" },
                          { label: "20", value: "20" },
                        ]}
                        customSetter={formik.setFieldValue}
                        customFormikLabel={"packSize"}
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                        dropDownTitle='Pack Size'
                        animateDropDownTitle
                      />
                      {formik.touched.packSize && formik.errors.packSize ? (
                        <span className='err'>{formik.errors.packSize}</span>
                      ) : null}
                    </div> */}
                        <div
                          className={`input-field ${
                            formik.values.packSize !== "" ||
                            focusedField === "packSize"
                              ? "active"
                              : ""
                          }`}
                        >
                          <label htmlFor='packSize' className='toggle-label'>
                            Pack Size
                          </label>
                          <input
                            id='packSize'
                            name='packSize'
                            type='text'
                            className='form-input'
                            onChange={formik.handleChange}
                            value={formik.values.packSize}
                            onInput={e => {
                              e.target.value = priceValidator(e.target.value)
                            }}
                            maxLength={10}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField("packSize")}
                            disabled={formik.values.productType === "sheet"}
                          />
                          {formik.touched.packSize && formik.errors.packSize ? (
                            <span className='err'>
                              {formik.errors.packSize}
                            </span>
                          ) : null}
                        </div>{" "}
                        <div
                          className='input-field'
                          onClick={() => {
                            if (!formik.values.uom) {
                              toast.warning(
                                "Please select Product Type to select uom",
                              )
                            }
                          }}
                        >
                          <DropDownBox
                            options={optionsList.uomList}
                            customSetter={formik.setFieldValue}
                            defaultValue='UOM'
                            customFormikLabel={"uom"}
                            customDropBoxStyles={{
                              border: "1px solid #d7cece",
                              borderRadius: "0.5rem",
                              color: "#333",
                            }}
                            incomingValue={uomId && uomId}
                            disabled={false}
                            customTextStyle={{
                              color: "#212229",
                              opacity: "0.7",
                            }}
                          />
                          {formik.touched.uom && formik.errors.uom ? (
                            <span className='err'>{formik.errors.uom}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className='input-field'>
                        {/* <DropDownBox
                      options={colorList}
                      customSetter={formik.setFieldValue}
                      dropDownTitle='Color'
                      animateDropDownTitle
                      customFormikLabel={"color"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      showSearchBar
                    /> */}
                        <div
                          className={`input-field ${
                            formik.values.packQuantity !== "" ||
                            focusedField === "packQuantity"
                              ? "active"
                              : ""
                          }`}
                        >
                          <label
                            htmlFor='packQuantity'
                            className='toggle-label'
                          >
                            Pack Quantity
                          </label>
                          <div
                            className={
                              formik.values.packQuantity
                                ? "margin_percentage_box"
                                : ""
                            }
                          >
                            {
                              <span className='m_percentage'>
                                {formik.values.packQuantity
                                  ? formik.values.productType === "paint"
                                    ? "liter"
                                    : formik.values.productType ===
                                        "powder/putty"
                                      ? "kg"
                                      : formik.values.productType === "sheet"
                                        ? "Number"
                                        : ""
                                  : ""}
                              </span>
                            }{" "}
                            <input
                              id='packQuantity'
                              name='packQuantity'
                              type='text'
                              className='form-input'
                              onChange={e => {
                                formik.setFieldValue(
                                  "packQuantity",
                                  numberWithCommas(e.target.value),
                                )
                              }}
                              value={formik.values.packQuantity}
                              onInput={e => {
                                e.target.value = priceValidator(e.target.value)
                              }}
                              maxLength={10}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("packQuantity")}
                              disabled={formik.values.productType === "sheet"}
                            />
                          </div>

                          {formik.touched.packQuantity &&
                          formik.errors.packQuantity ? (
                            <span className='err'>
                              {formik.errors.packQuantity}
                            </span>
                          ) : null}
                        </div>

                        {/* {formik?.values?.color && (
                      <div
                        // onClick={}
                        className=' color-preview-box show_search-common-animation'
                        style={{ background: formik?.values?.color?.hex_code }}
                        // title={}
                      >
                        <Tooltip
                          message={`Hex: ${formik?.values?.color?.hex_code} Ral: ${formik?.values?.color?.ral_code}`}
                        >
                          <span
                            style={{
                              width: "25px",
                              height: "25px",
                            }}
                          ></span>
                        </Tooltip>
                      </div>
                    )} */}
                      </div>{" "}
                    </div>
                    <SearchDropDown
                      label='Color'
                      name='color'
                      api={getOptionsList}
                      listName={"color-list"}
                      customSetter={formik.setFieldValue}
                      dynamicValue={"all"}
                    />
                    {formik.touched.color && formik.errors.color ? (
                      <span className='err'>{formik.errors.color}</span>
                    ) : null}
                    <div className='col-2'>
                      <div className='input-field'>
                        {/* <DropDownBox
                      options={[{ label: "Acrylic", value: "Acrylic" }]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"paintType"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Paint Type'
                      animateDropDownTitle
                    /> */}
                        <SearchDropDown
                          label={"Paint Type"}
                          name='paintType'
                          api={getOptionsList}
                          customSetter={formik.setFieldValue}
                          listName={"painttype-list"}
                          dynamicLabel={"paint_type"}
                          // dynamicValue={"paint_type"}
                        />{" "}
                        {formik.touched.paintType && formik.errors.paintType ? (
                          <span className='err'>{formik.errors.paintType}</span>
                        ) : null}
                      </div>{" "}
                      <div className='input-field'>
                        {/* <DropDownBox
                      options={[{ label: "Matte", value: "Matte" }]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"finish"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Finish'
                      animateDropDownTitle
                    /> */}
                        <SearchDropDown
                          label='Finish Type'
                          customSetter={formik.setFieldValue}
                          name='finish'
                          listName={"finishtype-list"}
                          dynamicLabel={"finish_type"}
                          // dynamicValue={"finish_type"}
                          api={getOptionsList}
                        />
                        {formik.touched.finish && formik.errors.finish ? (
                          <span className='err'>{formik.errors.finish}</span>
                        ) : null}
                      </div>{" "}
                    </div>
                  </>
                )}
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.unitPrice !== "" ||
                      focusedField === "unitPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='unitPrice' className='toggle-label'>
                      Unit Price
                    </label>
                    <div className={formik.values.unitPrice ? "phone_box" : ""}>
                      {
                        <span className='phone_code'>
                          {formik.values.unitPrice ? "₹" : ""}
                        </span>
                      }
                      <input
                        id='unitPrice'
                        name='unitPrice'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          if (!formik.values.packSize) {
                            e.preventDefault()
                          } else {
                            // formik.handleChange(e)
                            formik.setFieldValue(
                              "unitPrice",
                              numberWithCommas(e.target.value),
                            )
                            calPrice(
                              e.target.value,
                              formik.values.packSize,
                              "unitPrice",
                            )
                          }
                        }}
                        value={formik.values.unitPrice}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)

                          // setPriceFieldDisabler("costPrice")
                        }}
                        maxLength={14}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => {
                          setFocusedField("unitPrice")
                          if (!formik.values.packSize) {
                            toast.warning("Please enter pack size first")
                          }
                        }}
                        // disabled={
                        //   priceFieldDisabler === "unitPrice" &&
                        //   formik.values.costPrice
                        // }
                      />
                    </div>

                    {formik.touched.unitPrice && formik.errors.unitPrice ? (
                      <span className='err'>{formik.errors.unitPrice}</span>
                    ) : null}
                  </div>{" "}
                  <div
                    className={`input-field ${
                      formik.values.costPrice !== "" ||
                      focusedField === "costPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='costPrice' className='toggle-label'>
                      Cost Price
                    </label>
                    <div className={formik.values.costPrice ? "phone_box" : ""}>
                      {
                        <span className='phone_code'>
                          {formik.values.costPrice ? "₹" : ""}
                        </span>
                      }
                      <input
                        id='costPrice'
                        name='costPrice'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          if (!formik.values.packSize) {
                            e.preventDefault()
                          } else {
                            formik.setFieldValue(
                              "costPrice",
                              numberWithCommas(e.target.value),
                            )
                            calPrice(
                              e.target.value,
                              formik.values.packSize,
                              "costPrice",
                            )
                          }
                        }}
                        value={formik.values.costPrice}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                          setPriceFieldDisabler("unitPrice")
                        }}
                        maxLength={14}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => {
                          setFocusedField("costPrice")
                          if (!formik.values.packSize) {
                            toast.warning("Please enter pack size first")
                          }
                        }}
                        disabled={
                          priceFieldDisabler === "costPrice" &&
                          formik.values.unitPrice
                        }
                      />
                    </div>

                    {formik.touched.costPrice && formik.errors.costPrice ? (
                      <span className='err'>{formik.errors.costPrice}</span>
                    ) : null}
                  </div>{" "}
                </div>
                <div className={role === 1 ? "col-2" : ""}>
                  {role === 1 && (
                    <div
                      className={`input-field ${
                        formik.values.marginPercentage !== "" ||
                        focusedField === "marginPercentage"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor='marginPercentage'
                        className='toggle-label'
                      >
                        Margin Percentage (%)
                      </label>
                      <div
                        className={
                          formik.values.marginPercentage
                            ? "margin_percentage_box"
                            : ""
                        }
                      >
                        {
                          <span className='m_percentage'>
                            {formik.values.marginPercentage ? "%" : ""}
                          </span>
                        }{" "}
                        <input
                          id='marginPercentage'
                          name='marginPercentage'
                          type='text'
                          className='form-input'
                          onChange={e => {
                            if (formik.values.costPrice) {
                              formik.handleChange(e)
                            } else {
                              toast.warning("Please enter cost price first")
                            }
                          }}
                          value={formik.values.marginPercentage}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^\d]/gi,
                              "",
                            )
                          }}
                          maxLength={2}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("marginPercentage")}
                          disabled
                        />
                      </div>

                      {formik.touched.marginPercentage &&
                      formik.errors.marginPercentage ? (
                        <span className='err'>
                          {formik.errors.marginPercentage}
                        </span>
                      ) : null}
                    </div>
                  )}
                  <div
                    className={`input-field ${
                      formik.values.sellingPrice !== "" ||
                      focusedField === "sellingPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='sellingPrice' className='toggle-label'>
                    Selling Unit Price
                    </label>
                    <div
                      className={formik.values.sellingPrice ? "phone_box" : ""}
                    >
                      {
                        <span className='phone_code'>
                          {formik.values.sellingPrice ? "₹" : ""}
                        </span>
                      }
                      <input
                        id='sellingPrice'
                        name='sellingPrice'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.sellingPrice}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^\d ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        maxLength={14}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        disabled
                        onFocus={() => setFocusedField("sellingPrice")}
                      />
                    </div>

                    {formik.touched.sellingPrice &&
                    formik.errors.sellingPrice ? (
                      <span className='err'>{formik.errors.sellingPrice}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='footer flex-center'>
              <button
                type='submit'
                disabled={productsLoading}
                className='btn-primary'
                // disabled={profileLoading}
                style={productsLoading ? { cursor: "not-allowed" } : {}}
              >
                {productsLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddNewProduct
