/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import Sidebar from "../../../components/Sidebar"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import DropDownBox from "../../../components/DropDownBox"
import Breadcrumb from "../../../components/BreadCrumbs"
import Privileges from "./components/Privileges"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { useNavigate, useParams } from "react-router-dom"
import {
  unixTimeToYYYYMMDD,
  yyyyMMDDToISO,
  convertToWebP,
} from "../../../helper/helper"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import {
  changeUserProfilePicture,
  getSingleUserDetail,
  updateSingleUserDetail,
} from "../redux/userApi"
import { ClipLoader } from "react-spinners"
import { getAllDepartments } from "../../department/redux/DepartmentApi"
import Loader from "../../../components/Loader"
import ImagePreviewBox from "../../../components/ImagePreviewBox"
import FormButtons from "../../../components/SubmitBtns"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"

const UpdateUser = ({ setTitle }) => {
  const { userDetail, userLoading } = useSelector(
    state => state.user,
    shallowEqual,
  )

  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const { departments } = useSelector(state => state.department, shallowEqual)
  const [activeInputs, setActiveInputs] = useState(false)
  const [active, setActive] = useState("user")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [focusedField, setFocusedField] = useState()
  const [profilePic, setProfilePic] = useState(null)
  const [imageInfo, setimageInfo] = useState("")
  const [customPrivi, setCustomPrivi] = useState(false)
  const [departMentList, setDepartMentList] = useState([
    { label: "Buyers", value: "buyers" },
    { label: "Suppliers", value: "suppliers" },
    { label: "Both", value: "both" },
  ])

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showPreview, setShowPreview] = useState(false)

  useEffect(() => {
    setTitle("User Management")
    dispatch(getSingleUserDetail({ id: id }))
    dispatch(getAllDepartments())
    //
  }, [setTitle, dispatch, id])

  const setPrivileges = useCallback(data => {
    formik.setFieldValue("privileges", data)
  })

  useEffect(() => {
    const dropdownList = departments.map(department => ({
      label: department?.name,
      value: department?._id,
    }))
    setDepartMentList(dropdownList)
  }, [departments])

  useEffect(() => {
    if (userDetail) {
      formik.setValues({
        first_name: userDetail?.fname,
        last_name: userDetail?.lname,
        email: userDetail?.email?.value,
        phone_no: userDetail?.phone?.value?.slice(-10),
        privileges: userDetail?.privileges,
        dob: userDetail?.dob ? unixTimeToYYYYMMDD(userDetail?.dob) : "",
        gender: userDetail?.gender,
        role: userDetail?.role,
        department: userDetail?.department_id,
      })
      setProfilePic(userDetail?.profilePicture)
    }
  }, [userDetail])

  useEffect(() => {
    if (imageInfo) {
      const data = {
        id: id,
        send: {
          profile_picture: imageInfo,
        },
      }
      const response = dispatch(changeUserProfilePicture(data))
    }
  }, [imageInfo, dispatch, id])

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    gender: "",
    dob: "",
    role: "",
    department: "",
    privileges: {},
  }

  const today = new Date()
  today.setFullYear(today.getFullYear() - 18)

  const profileSchema = Yup.object({
    first_name: Yup.string()
      .trim()
      .min(3, "First Name must be at least 3 characters long")
      .max(80, "First name must be less than 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("First name is required"),
    last_name: Yup.string()
      .trim()
      .min(3, "Last Name must be at least 3 characters long")
      .max(80, "Last name must be less than 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Last name is required"),
    email: Yup.string()
      .trim()
      .email("Please provide valid email")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please!",
      )
      .matches(
        "@poonamcoatings.com",
        "Email must contain @poonamcoatings.com domain",
      )
      .required("Email address should not be empty"),
    phone_no: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
      .required("Phone number is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date()
      .required("Birth date is required")
      .test("age", "You must be 18 years old or above", value => {
        const today = new Date() // Define 'today' as the current date
        const birthDate = new Date(value)
        const ageDifferenceInMilliseconds = today - birthDate
        const ageInYears =
          ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000) // Approximate calculation
        return ageInYears >= 18
      })
      .test("age two", "User's age must be less than 100 years", value => {
        if (new Date(value).getFullYear() >= new Date().getFullYear() - 100) {
          return true
        } else {
          return false
        }
      }),
    role: Yup.string().required("Role is required"),
    department: Yup.string().required("Department is required"),
    // privileges: Yup.object().required("Please Add privileges first"),
    // privileges: Yup.object().test(
    //   "has-Privilege",
    //   "Privilege is required",
    //   (value) => {
    //     return (
    //       value !== null &&
    //       value !== undefined &&
    //       Object?.keys(value)?.length > 0
    //     );
    //   }
    // ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: profileSchema,
    onSubmit: async values => {
      const data = {
        send: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: `+91${values.phone_no}`,
          gender: values.gender,
          department_id: values.department,
          date_of_birth: yyyyMMDDToISO(values.dob),
          role: Number(values.role),
          privileges: Object.entries(values.privileges).map(([key, value]) => [
            key,
            value,
          ]),
        },
        id: id,
      }
      // if (values.role === "6") {
      // const CHECK = [{ request_management: ["read", "write", "delete"] }];
      // data.send.privileges = CHECK.map((obj) => Object.entries(obj)[0]);
      // } else {
      // data.send.privileges = Object.entries(values.priviledge).map(
      // ([key, value]) => [key, value]
      // );
      // }
      const response = await dispatch(updateSingleUserDetail(data))
      if (response?.payload) {
        navigate("/user")
      }
    },
  })

  const { tableLoading: isUserLoading } = useLoaderDelay({
    loadingState: userLoading,
  })

  const updateProf = async e => {
    const selectedImg = e.target.files[0]

    if (
      selectedImg.type === "image/jpeg" ||
      selectedImg.type === "image/webp" ||
      selectedImg.type === "image/png" ||
      selectedImg.type === "image/jpg"
    ) {
      if (selectedImg.size > 5 * 1024 * 1024) {
        toast.warning(
          "File size exceeds 5MB limit. Please select a smaller file.",
        )
        e.target.value = null
      } else {
        setProfilePic(URL.createObjectURL(selectedImg))
        const convertedImg = await convertToWebP(selectedImg)
        setimageInfo(convertedImg)
        e.target.value = null
      }
    } else {
      toast.warning("accepts only .jpeg, .jpg, .png and .webp files only")
      e.target.value = null
    }
  }

  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  return (
    <div className='container' style={!customPrivi ? heightAuto : null}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {showPreview && (
            <ImagePreviewBox
              customSetter={setShowPreview}
              imageChangeApi={changeUserProfilePicture}
              customState={showPreview}
              incomingImage={profilePic}
            />
          )}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          {/* {showChat && (
<Chatbox showChat={showChat} setShowChat={setShowChat} />
)} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>User Management</div>
                  <div className='mch-path'>
                    <Breadcrumb
                      id={userDetail?._id}
                      title={`${userDetail?.fname} ${userDetail?.lname}`}
                    />
                  </div>
                </div>
                {/* <div className="mch2">
<div className="mch2-duration">
August 1,2020 - August 31,2020
</div>
<div
className="mch2-action"
onClick={() => setAction(!action)}
>
<span className="">Action</span>
<span className="material-symbols-rounded expand">
expand_more
</span>
{action && (
<div className="action-tooltip">
<span className="">Download</span>
<span className="">Print</span>
</div>
)}
</div>
</div> */}
              </div>
              <div className='profile_form_box'>
                <form onSubmit={formik.handleSubmit} className='form-content'>
                  <div className='form-group form'>
                    <div className='profile_picture_box'>
                      <div className='profile-header'>
                        <div className='header-content'>
                          <div className='profile-details'>
                            <label
                              htmlFor='choose_photo'
                              className={`updtIMG ${
                                Object?.values(
                                  privileges["user_management"],
                                ).includes("write") && " showCameraIcon"
                              }`}
                              onClick={() => {
                                if (
                                  Object.values(
                                    privileges["user_management"],
                                  ).includes("write")
                                ) {
                                  setShowPreview(true)
                                }
                              }}
                            >
                              <span className='material-symbols-rounded updatelogo'>
                                add_a_photo
                              </span>
                              <img
                                src={
                                  profilePic
                                    ? profilePic
                                    : require("../../../assets/images/user(1).webp")
                                }
                                alt='profile'
                                className='profile_user-pic'
                              />
                              {/* <input
                                id='choose_photo'
                                hidden={true}
                                type='file'
                                accept='.png,.jpeg,.jpg,.webp'
                                className='company-pic-input'
                                onChange={updateProf}
                              /> */}
                            </label>
                            {/* <img
                              src={
                                profilePic
                                  ? profilePic
                                  : require("../../../assets/images/user(1).webp")
                              }
                              alt="profile"
                              className="profile_user-pic"
                            /> */}
                            {/* <div className="profile-controls">
                              <label
                                htmlFor="choose_photo"
                                className="update-img"
                              >
                                Update
                                <input
                                  id="choose_photo"
                                  hidden={true}
                                  type="file"
                                  accept=".png,.jpeg,.jpg,.webp"
                                  className="company-pic-input"
                                  onChange={updateProf}
                                />
                              </label>
                              <button
                                onClick={delProf}
                                type="button"
                                className="btn-secondary"
                              >
                                Remove
                              </button>
                            </div> */}
                          </div>
                          {Object?.values(
                            privileges["user_management"],
                          ).includes("write") && (
                            <div className='flex-center gap-4'>
                              <div className='add-privileges divide-border-right'>
                                <button
                                  type='button'
                                  className='btn-primary'
                                  onClick={() => setCustomPrivi(true)}
                                  disabled={!activeInputs}
                                >
                                  Edit Privileges
                                </button>
                                {formik.errors.privileges &&
                                formik.touched.privileges ? (
                                  <span className='err'>
                                    {formik.errors.privileges}
                                  </span>
                                ) : null}
                              </div>
                              {activeInputs ? (
                                <button
                                  type='button'
                                  onClick={e => {
                                    e.preventDefault()
                                    setActiveInputs(false)
                                  }}
                                  className='btn-secondary'
                                  disabled={userLoading}
                                  style={
                                    userLoading ? { cursor: "not-allowed" } : {}
                                  }
                                >
                                  {userLoading ? (
                                    <ClipLoader
                                      loading={true}
                                      size={13}
                                      aria-label='Loading Spinner'
                                      data-testid='loader'
                                    />
                                  ) : (
                                    "Abort"
                                  )}
                                </button>
                              ) : (
                                <button
                                  type='button'
                                  onClick={e => {
                                    e.preventDefault()
                                    setActiveInputs(true)
                                  }}
                                  className='btn-primary'
                                >
                                  <span className='material-symbols-outlined small'>
                                    border_color
                                  </span>{" "}
                                  EDIT
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='col-2'>
                      <div
                        className={`input-field f-contents ${
                          formik.values.first_name !== "" ||
                          focusedField === "first_name"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='first_name' className='toggle-label'>
                          First Name
                        </label>
                        <input
                          type='text'
                          name='first_name'
                          id='first_name'
                          className='form-input'
                          value={formik.values.first_name || ""}
                          onChange={formik.handleChange}
                          onInput={e => {
                            e.target.value = e.target.value
                              .replace(/[^A-Za-z ]/gi, "")
                              .replace(/(?:^|\s)\S/g, match =>
                                match.toUpperCase(),
                              )
                          }}
                          maxLength={81}
                          onKeyDown={e => {
                            if (e.key === " ") {
                              e.preventDefault()
                            }
                          }}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("first_name")}
                          disabled={!activeInputs}
                        />
                        {formik.errors.first_name &&
                        formik.touched.first_name ? (
                          <span className='err'>
                            {formik.errors.first_name}
                          </span>
                        ) : null}
                      </div>
                      <div
                        className={`input-field f-contents ${
                          formik.values.last_name !== "" ||
                          focusedField === "last_name"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='last_name' className='toggle-label'>
                          Last Name
                        </label>
                        <input
                          type='text'
                          name='last_name'
                          id='last_name'
                          maxLength={81}
                          className='form-input'
                          value={formik.values.last_name || ""}
                          onChange={formik.handleChange}
                          onInput={e => {
                            e.target.value = e.target.value
                              .replace(/[^A-Za-z ]/gi, "")
                              .replace(/(?:^|\s)\S/g, match =>
                                match.toUpperCase(),
                              )
                          }}
                          onKeyDown={e => {
                            if (e.key === " ") {
                              e.preventDefault()
                            }
                          }}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("last_name")}
                          disabled={!activeInputs}
                        ></input>{" "}
                        {formik.errors.last_name && formik.touched.last_name ? (
                          <span className='err'>{formik.errors.last_name}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-2'>
                      <div
                        className={`input-field f-contents ${
                          formik.values.email !== "" || focusedField === "email"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='email' className='toggle-label'>
                          Email ID
                        </label>
                        <input
                          type='email'
                          name='email'
                          id='email'
                          className='form-input'
                          value={formik.values.email || ""}
                          onChange={formik.handleChange}
                          onKeyDown={e => {
                            if (e.key === " ") {
                              e.preventDefault()
                            }
                          }}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("email")}
                          disabled={!activeInputs}
                        ></input>
                        {formik.errors.email && formik.touched.email ? (
                          <span className='err'>{formik.errors.email}</span>
                        ) : null}
                      </div>
                      <div
                        className={`input-field f-contents ${
                          formik.values.phone_no !== "" ||
                          focusedField === "phone"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='phone_no' className='toggle-label'>
                          Phone Number
                        </label>
                        <div
                          className={formik.values.phone_no ? "phone_box" : ""}
                        >
                          {
                            <span className='phone_code'>
                              {formik.values.phone_no ? "+91" : ""}
                            </span>
                          }{" "}
                          <input
                            type='text'
                            name='phone_no'
                            id='phone_no'
                            className='form-input'
                            value={formik.values.phone_no || ""}
                            onChange={formik.handleChange}
                            onInput={e => {
                              e.target.value = e.target.value
                                .slice(0, 10)
                                .replace(/\D/g, "")
                            }}
                            onKeyDown={e => {
                              if (e.key === " ") {
                                e.preventDefault()
                              }
                            }}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField("phone")}
                            disabled={!activeInputs}
                          ></input>
                        </div>{" "}
                        {formik.errors.phone_no && formik.touched.phone_no ? (
                          <span className='err'>{formik.errors.phone_no}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className='f-contents'>
                        <DropDownBox
                          options={[
                            { label: "Male", value: "male" },
                            { label: "Female", value: "female" },
                            { label: "Other", value: "other" },
                          ]}
                          customSetter={formik.setFieldValue}
                          customFormikLabel='gender'
                          animateDropDownTitle
                          dropDownTitle='Gender'
                          customDropBoxStyles={{
                            border: "1px solid #e6e6e6",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          customTextStyle={{ color: "#212229", opacity: "0.7" }}
                          incomingValue={userDetail?.gender}
                          disabled={activeInputs}
                        />
                        {formik.errors.gender && formik.touched.gender ? (
                          <span className='err'>{formik.errors.gender}</span>
                        ) : null}
                      </div>

                      <div
                        className={`input-field f-contents ${
                          formik.values.dob !== "" || focusedField === "dob"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='dob' className='toggle-label'>
                          Date of Birth
                        </label>
                        <input
                          type='date'
                          name='dob'
                          id='dob'
                          className='form-input'
                          value={formik.values.dob || ""}
                          onChange={formik.handleChange}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("dob")}
                          disabled={!activeInputs}
                          max='9999-12-31'
                        />{" "}
                        {formik.errors.dob && formik.touched.dob ? (
                          <span className='err'>{formik.errors.dob}</span>
                        ) : null}
                      </div>
                    </div>

                    <div className='col-2'>
                      <div className='f-contents'>
                        <DropDownBox
                          options={[
                            { label: "Admin", value: "2" },
                            {
                              label: "Manager",
                              value: "3",
                            },
                          ]}
                          dropDownTitle='Select Role'
                          animateDropDownTitle={true}
                          customSetter={formik.setFieldValue}
                          customFormikLabel='role'
                          customDropBoxStyles={{
                            border: "1px solid #e6e6e6",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          customTextStyle={{ color: "#212229", opacity: "0.7" }}
                          incomingValue={String(userDetail?.role)}
                          disabled={activeInputs}
                        />
                        {formik.errors.role && formik.touched.role ? (
                          <span className='err'>{formik.errors.role}</span>
                        ) : null}
                      </div>

                      <div className='f-contents'>
                        <DropDownBox
                          options={departMentList}
                          preselected={formik.values.department || ""}
                          dropDownTitle='Select Department'
                          animateDropDownTitle={true}
                          customSetter={formik.setFieldValue}
                          customFormikLabel='department'
                          customDropBoxStyles={{
                            border: "1px solid #e6e6e6",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          customTextStyle={{ color: "#212229", opacity: "0.7" }}
                          disabled={activeInputs}
                          incomingValue={userDetail.department_id}
                        />
                        {formik.errors.department &&
                        formik.touched.department ? (
                          <span className='err'>
                            {formik.errors.department}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className='f-footer'>
                      <button
                        type='button'
                        className='btn-secondary'
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </button>
                      {}
                      <button
                        type='submit'
                        className='btn-primary'
                        style={
                          userLoading
                            ? {
                                cursor: "not-allowed",
                                textAlign: "center",
                                // background: "gray",
                              }
                            : {}
                        }
                        disabled={!activeInputs ? true : false}
                      >
                        {userLoading ? (
                            <ClipLoader
              loading={true}
              size={15}
              aria-label='Loading Spinner'
              data-testid='loader'
              color='white'
            />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div> */}

                    <FormButtons
                      cancelBtnText='Back'
                      loading={userLoading}
                      abortHandleClick={() => navigate("/user")}
                      disabled={!activeInputs}
                    />
                  </div>
                </form>
              </div>
            </div>
            <Footer />
          </div>
          {customPrivi && (
            <Privileges
              customPrivi={customPrivi}
              setCustomPrivi={setCustomPrivi}
              setPrivileges={setPrivileges}
              privilege={formik.values.privileges}
              modUsed={"Add"}
            />
          )}
          <Loader isLoading={isUserLoading} />
        </div>
      </div>
    </div>
  )
}

export default UpdateUser
