import { createSlice } from "@reduxjs/toolkit"
import { getCompanyAddress, getSalesAndOrderList } from "./PurchaseApi"

const purchaseSlice = createSlice({
  name: "purchase",
  initialState: {
    purchaseLoading: false,
    metaData: [],
    purchasePreview: [],
    supplierAddress: [],
    warehouseAddress: {},
    commonPageList: [],
    metaData: [],
  },
  reducers: {
    isPurchaseToggle: (state, { payload }) => {
      state.purchaseLoading = payload
    },

    setPurchasePreview: (state, { payload }) => {
      state.purchasePreview = payload
    },

    setSupplierAddress: (state, { payload }) => {
      state.supplierAddress = payload
    },
    setWarehouseAddress: (state, { payload }) => {
      state.warehouseAddress = payload
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getCompanyAddress.pending, (state, { payload }) => {
        // state.purchaseLoading = true
      })
      .addCase(getCompanyAddress.fulfilled, (state, { payload }) => {
        state.purchaseLoading = false
        state.supplierAddress = payload
      })
      .addCase(getCompanyAddress.rejected, state => {
        state.purchaseLoading = false
      })
      .addCase(getSalesAndOrderList.pending, (state, { payload }) => {
        state.purchaseLoading = true
      })
      .addCase(getSalesAndOrderList.fulfilled, (state, { payload }) => {
        state.commonPageList = payload?.data
        state.metaData = payload?.metadata[0]

        state.purchaseLoading = false
      })
      .addCase(getSalesAndOrderList.rejected, (state, { payload }) => {
        state.purchaseLoading = false
      })
  },
})

export const {
  isPurchaseToggle,
  setPurchasePreview,
  setSupplierAddress,
  setWarehouseAddress,
} = purchaseSlice.actions
export default purchaseSlice.reducer
