import React, { useEffect, useRef, useState } from "react"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import Sidebar from "../../../components/Sidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ReactQuill from "react-quill"
import { useFormik } from "formik"
import * as Yup from "yup"
// import Breadcrumb from "../../../components/BreadCrumbs";
import { addEmail } from "../redux/EmailApi"
import FormButtons from "../../../components/SubmitBtns"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
import Loader from "../../../components/Loader"

const AddEmail = ({ setTitle }) => {
  const [active, setActive] = useState("email")
  // const [action, setAction] = useState(false);
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [focusedField, setFocusedField] = useState()
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const dispatch = useDispatch()
  // const { id } = useParams();
  const navigate = useNavigate()
  const { emailLoading } = useSelector(store => store.email)
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  useEffect(() => {
    setTitle("Email Management: Add New Template")
  }, [setTitle])

  // useEffect(() => {
  //   if (id) {
  //     setTitle("Edit Email");
  //     formik.setFieldValue("subject", emailDetails[0]?.subject);
  //     formik.setFieldValue("title", emailDetails[0]?.title);
  //     formik.setFieldValue("email_text", emailDetails[0]?.email_text);
  //   }
  // }, [id, setTitle]);

  const profileSchema = Yup.object({
    subject: Yup.string()
      .trim()
      .max(80, "Subject must be less than 80 characters")
      .required("Subject is required"),
    title: Yup.string()
      .trim()
      .max(80, "Title must be less than 80 characters")
      .required("Title is required"),
    email_text: Yup.string().required("Email description is required"),
  })

  const formik = useFormik({
    initialValues: {
      subject: "",
      title: "",
      email_text: "",
    },
    validationSchema: profileSchema,
    onSubmit: async values => {
      // const data = {
      //   subject: values.subject,
      //   title: values.title,
      //   email_text: values.email_text,
      // };

      const data = {
        title: values.title,
        subject: values.subject,
        body: values.email_text,
      }

      const response = await dispatch(addEmail(data))

      if (response.payload) {
        navigate("/email")
      }
    },
  })
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", { list: "ordered" }, { list: "bullet" }], // toggled buttons
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  ]

  const { tableLoading: isEmailLoading } = useLoaderDelay({
    loadingState: emailLoading,
  })

  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>
                    Add Email Template
                    {/* Write Down New Emails Quickly and Easily */}
                  </div>
                  <div className='mch-path'>
                    {/* <Breadcrumb /> */}
                    {/* <span onClick={() => navigate("/email")}>Email</span> /
                    {id ? " Edit Email" : " Add Email"} */}
                    <div className='breadcrumbs_container'>
                      <Link to='/' className='flex-center'>
                        {/* <span className="material-symbols-rounded medium">
                          home
                        </span> */}
                        Dashboard
                      </Link>
                      <div className='breadcrumbs'>
                        <span>
                          {"/"} <Link to={"/email"}>Emails</Link>
                        </span>{" "}
                        <span>/ Add Email</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mch2'>
                  {/* <div className="mch2-duration">
                    August 1,2020 - August 31,2020
                  </div>
                  <div
                    className="mch2-action"
                    onClick={() => setAction(!action)}
                  >
                    <span className="">Action</span>
                    <span className="material-symbols-rounded expand">
                      expand_more
                    </span>
                    {action && (
                      <div className="action-tooltip">
                        <span className="">Download</span>
                        <span className="">Print</span>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
              <div className='main-box-padding'>
                <div className='form-content'>
                  <form className='' onSubmit={formik.handleSubmit}>
                    <div className='form-group form'>
                      <div
                        className={`input-field f-contents ${
                          formik.values.title !== "" || focusedField === "title"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='company_name' className='toggle-label'>
                          Title
                        </label>
                        <input
                          type='text'
                          name='title'
                          id='title'
                          maxLength={81}
                          className='form-input'
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          // onKeyDown={(e) => {
                          //   if (e.key === " ") {
                          //     e.preventDefault();
                          //   }
                          // }}

                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("title")}
                        ></input>{" "}
                        {formik.errors.title && formik.touched.title ? (
                          <span className='err'>{formik.errors.title}</span>
                        ) : null}
                      </div>{" "}
                      <div
                        className={`input-field f-contents ${
                          formik.values.subject !== "" ||
                          focusedField === "company"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='company_name' className='toggle-label'>
                          Subject
                        </label>
                        <input
                          type='text'
                          name='subject'
                          id='subject'
                          className='form-input'
                          value={formik.values.subject}
                          onChange={formik.handleChange}
                          maxLength={81}
                          // onKeyDown={(e) => {
                          //   if (e.key === " ") {
                          //     e.preventDefault();
                          //   }
                          // }}

                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("company")}
                        ></input>
                        {formik.errors.subject && formik.touched.subject ? (
                          <span className='err'>{formik.errors.subject}</span>
                        ) : null}
                      </div>
                      <div className=' f-contents '>
                        <div className='text-editor'>
                          <ReactQuill
                            modules={{ toolbar: toolbarOptions }}
                            theme='snow'
                            value={formik.values.email_text}
                            onChange={v =>
                              formik.setFieldValue("email_text", v)
                            }
                          />
                        </div>{" "}
                        {formik.errors.email_text &&
                        formik.touched.email_text ? (
                          <span className='err'>
                            {formik.errors.email_text}
                          </span>
                        ) : null}
                      </div>{" "}
                      {/* <div className='f-footer'>
                        <button
                          type='button'
                          className='btn-secondary'
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>
                        <button type='submit' className='btn-primary'>
                          Submit
                        </button>
                      </div> */}
                      <FormButtons
                        cancelBtnText='Back'
                        loading={emailLoading}
                        abortHandleClick={() => navigate("/email")}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Footer />{" "}
          </div>
          <Loader isLoading={isEmailLoading} />
        </div>
      </div>
    </div>
  )
}

export default AddEmail
