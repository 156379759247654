import { useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import * as Yup from "yup"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
import ReactQuill from "react-quill"
import FormButtons from "../../../components/SubmitBtns"
import ApprovalPopup from "./approvalPopup"
import RejectPopup from "./RejectPopup"
// import SelectCompanyPopup from "./SelectCompanyPopup"
const tempImage = require("../../../assets/icons/logo.svg").default

const ApprovalPage = ({ setTitle }) => {
  const [active, setActive] = useState("approvals")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  const [isEventCard, setIsEventCard] = useState(false)
  const [popUpData, setPopupData] = useState({})
  const [isSelectCompany, setIsSelectCompany] = useState(false)
  const [showApproval, setShowApproval] = useState(false)
  // const { emailDetails } = useSelector((store) => store.email);
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { productDetails } = useSelector(state => state.products, shallowEqual)

  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  useEffect(() => {
    setTitle("Approval Details")
  }, [setTitle])

  const [focusedField, setFocusedField] = useState()
  const { purchaseLoading } = useSelector(store => store.purchase)
  const initialValues = {
    productName: "",
    skuNum: "233",
    brand: "",
    category: "",
    color: "",
    packSize: "",
    uom: "",
    productCode: "",
    batchNumber: "",
  }

  const singleComSchema = Yup.object({
    skuNum: Yup.string().required("SKU number is required"),
    productName: Yup.string()
      .trim()
      .min(2, "Paint name must be at least 2 characters long")
      .max(80, "Name must be less than 80 characters")
      .required("Paint name is required"),
    brand: Yup.string().required("Brand name is required"),
    category: Yup.string().required("Category name is required"),
    color: Yup.string(),
    // .required("Color name is required"),
    packSize: Yup.string().required("Pack size is required"),
    uom: Yup.string().required("UOM is required"),
    productCode: Yup.string().min(1).max(12),
    // .required("Product code is required"),
    batchNumber: Yup.string().min(1).max(17),
    // .required("Batch number is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        id,
        send: {
          brand_id: values?.brand,
          category_id: values?.category,
          color_id: values?.color,
          product_weight: values?.packSize,
          // product_weight: "5",
          uom_id: values?.uom,
          name: values?.productName,
          product_code: values?.productCode,
          batch_number: values?.batchNumber,
        },
        details_type: "update-basicDetail",
      }
    },
  })
  const deleteFunction = title => {
    setPopupData({
      data: {
        is_delete: true,
        product_ids: [id],
      },
      title,
    })
    setIsEventCard(true)
  }

  const [uomList, setUomList] = useState([])

  //   const apiCall = async () => {
  //     {
  //       const response = await dispatch(
  //         getOptionsList({
  //           listName: "uom-list",
  //           search: "",
  //           sort: "desc",
  //           limit: 0,
  //           page: 0,
  //         }),
  //       )

  //       const options = response?.payload?.map(item => {
  //         return { label: item.uom_type, value: item._id }
  //       })
  //       setUomList(options)
  //     }
  //   }

  //   useEffect(() => {
  //     dispatch(getProductsDetails({ id }))
  //     if (uomList.length === 0) apiCall()
  //   }, [])

  useEffect(() => {
    formik.setValues({
      skuNum: productDetails?.basic_details?.sku,
      productName: productDetails?.basic_details?.name,
      brand: productDetails?.basic_details?.brandId,
      color: productDetails?.basic_details?.colorId,
      category: productDetails?.basic_details?.categoryId,
      packSize: productDetails?.basic_details?.pack_size?.weight.$numberDecimal,
      uom: productDetails?.basic_details?.pack_size?.uomID,
      productCode: productDetails?.basic_details?.productCode,
      batchNumber: productDetails?.basic_details?.batchNumber,
    })
  }, [productDetails])

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  const [activeInputs, setActiveInputs] = useState(false)

  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {showApproval && (
            <RejectPopup
              customSetter={setShowApproval}
              customState={showApproval}
            />
          )}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Approval Details</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>

                <div className='table-search'>
                  <div
                    className='search-container'
                    style={{ justifyContent: "flex-end" }}
                  >
                    {Object.values(privileges["purchase_management"]).includes(
                      "write",
                    ) && (
                      <>
                        <button
                          className='btn-alpha header-btn bg-none'
                          onClick={() => setShowApproval(true)}
                        >
                          Reject
                        </button>
                        <button className='btn-alpha  header-btn '>
                          Approve
                        </button>{" "}
                      </>
                    )}{" "}
                  </div>
                </div>
              </div>
              <div
                className='main-box-padding'
                style={{ borderRadius: ".4rem" }}
              >
                <div className='gray-border'>
                  <LabeAndValues
                    labels={{ first: "GST Number", second: "GST Number" }}
                    values={{ first: "24ERTGHN98948511", second: "AAW85841" }}
                  />
                </div>

                <div className='gray-border flex flex-direction-column gap-8'>
                  <div className='flex address-flex'>
                    <div className='modelEx_content singleAdd w-full'>
                      <div className='profile_personal_details'>
                        <div className='details-controls'>
                          <h2>Buyer Details</h2>
                        </div>
                      </div>
                      <div className='location-collection flex'>
                        <div className='card' onClick={() => {}}>
                          <div className='col1'>
                            <h3 className='card-name'>Poonam Coatings</h3>

                            <div className='card-address'>
                              <p>{`A-465, Lorem Ipsum Avenue, Lorem Ipsum Nagar, `}</p>
                              <p>{`Nr. Lorem Ipsum, Nr. Lorem Ipsum,`}</p>
                              <p>{`Lorem Ipsum, Lorem Ipsum,`}</p>
                              <p>{`Contact No:  9773203623`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    <div className='dash-border'></div>
                    <div className='modelEx_content singleAdd w-full'>
                      <div className='profile_personal_details'>
                        <div className='details-controls'>
                          <h2>Delivery Location</h2>
                        </div>
                      </div>
                      <div className='location-collection flex'>
                        <div className='card' onClick={() => {}}>
                          <div className='col1'>
                            <h3 className='card-name'>Poonam Coatings</h3>

                            <div className='card-address'>
                              <p>{`A-465, Lorem Ipsum Avenue, Lorem Ipsum Nagar, `}</p>
                              <p>{`Nr. Lorem Ipsum, Nr. Lorem Ipsum,`}</p>
                              <p>{`Lorem Ipsum, Lorem Ipsum,`}</p>
                              <p>{`Contact No:  9773203623`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ width: "100%", margin: 0 }} />
                  <div className='modelEx_content singleAdd w-full'>
                    <div className='profile_personal_details'>
                      <div className='details-controls'>
                        <h2>Supplier Details</h2>
                      </div>
                    </div>
                    <div className='location-collection flex'>
                      <div className='card' onClick={() => {}}>
                        <div className='col1'>
                          <h3 className='card-name'>Jotun</h3>

                          <div className='card-address'>
                            <p>{`Fulcrum, A wing - 601(II)/602, `}</p>
                            <p>{`Next to Hyatt Regency, Sahar Road Andheri (E),`}</p>
                            <p>{`Mumbai – 400 099 Maharashtra`}</p>
                            <p>{`Contact No: +91 276787 2100`}</p>
                            <p
                              style={{ marginTop: "0.7rem" }}
                            >{`GSTIN - 123BBGNB456UJ90`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='gray-border'>
                  <h2 className='heading'>Purchase Order Details</h2>
                  <div className='flex flex-direction-column gap-2'>
                    <LabeAndValues
                      labels={{ first: "PO Number", second: "Payment Term" }}
                      values={{ first: "PO-007845", second: "NET 30" }}
                    />{" "}
                    <LabeAndValues
                      labels={{
                        first: "Customer ID",
                        second: "Number of Items",
                      }}
                      values={{ first: "PO-007845", second: "NET 30" }}
                    />{" "}
                    <LabeAndValues
                      labels={{
                        first: "Delivery Date",
                        second: "Selling Officer",
                      }}
                      values={{ first: "13/06/2024", second: "John Jacob" }}
                    />{" "}
                  </div>
                </div>

                <>
                  <div
                    className='data-table-container'
                    style={{ padding: "0" }}
                  >
                    <div
                      className='data-table-box scrollbar-visible'
                      style={{
                        margin: 0,
                        border: "1px solid #BFBFBF",
                      }}
                    >
                      <table className='data-table fix-width-table-row preview-data-table'>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>HSN Code</th>
                            <th>Pack Quantity</th>
                            <th>No. of Packs</th>
                            <th>Total Quantity</th>
                            <th>Total Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {/* {mailList[tableIndex]?.map((item, index) => { */}
                          {[
                            {
                              productName: "Raw Material 1",
                              hsn: "1121",
                              pack: "19.95 Lt",
                              noOfPack: "10",
                              quantity: "299.25 Lt",
                              amount: "5,500",
                            },
                            {
                              productName: "Raw Material 1",
                              hsn: "1121",
                              pack: "19.95 Lt",
                              noOfPack: "10",
                              quantity: "299.25 Lt",
                              amount: "5,500",
                            },
                            {
                              productName: "Raw Material 1",
                              hsn: "1121",
                              pack: "19.95 Lt",
                              noOfPack: "10",
                              quantity: "299.25 Lt",
                              amount: "5,500",
                            },
                          ]?.map((item, index) => {
                            const {
                              productName,
                              hsn,
                              pack,
                              noOfPack,
                              quantity,
                              amount,
                            } = item

                            return (
                              <tr key={index}>
                                <td className='preview-td'>{index + 1}</td>
                                <td className='preview-td'>{productName}</td>
                                <td className='preview-td'>{hsn}</td>
                                <td className='preview-td'>{pack}</td>
                                <td className='preview-td'>{noOfPack}</td>
                                <td className='preview-td'>{quantity}</td>
                                <td className='preview-td'>₹ {amount}</td>
                              </tr>
                            )
                          })}
                          <tr>
                            <td className='preview-td' colSpan={4}>
                              Gross Total:
                            </td>
                            <td className='preview-td'>30</td>
                            <td
                              className='preview-td'
                              style={{ borderRight: "1px solid #bfbfbf" }}
                            >
                              995 litre
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>

                <form className=''>
                  <div className='form-group'>
                    <div className={`input-field ${"active"}`}>
                      <label htmlFor='about_us' className='toggle-label'>
                        Comment
                      </label>
                      <textarea
                        id='about_us'
                        name='about_us'
                        rows={4}
                        className={
                          !activeInputs
                            ? "form-input disabeldTextArea"
                            : "form-input"
                        }
                        disabled={true}
                        value={"hello"}
                      />
                      {formik.touched.about_us && formik.errors.about_us ? (
                        <span className='err'>{formik.errors.about_us}</span>
                      ) : null}
                    </div>
                  </div>
                </form>
                <TermsCondition />
                <>
                  <div className='tp-main'>
                    <div className='tp-inner'>
                      <span>Subtotal :</span>
                      <hr />
                      <span>₹15,000</span>
                    </div>
                    <div className='tp-inner'>
                      <span>Taxes :</span>
                      <hr />
                      <span>₹750</span>
                    </div>
                    <div className='tp-inner'>
                      <span>Grand Total  :</span>
                      <hr />
                      <span>₹16,750</span>
                    </div>
                    {/* <div className='tp-inner'>
                        <span>Advance to pay :</span>
                        <input
                          type='text'
                          className='form-input'
                          placeholder='eg. 10000'
                        ></input>
                      </div> */}
                  </div>
                </>
                <div className='logo-stamp flex'>
                  <div className='stamp-box'>
                    <img className='stamp-img' src={tempImage} alt='' />
                  </div>
                </div>
              </div>

            </div>
            <Footer />{" "}
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              //   api={deleteProducts}
              popUpData={popUpData}
              // reFetchApi={getProductsList}
              navigateTo='/products'
              // reData={{ search: "", sort:"desc", limit: 5, page: 0 }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ApprovalPage

const LabeAndValues = ({ labels, values }) => {
  return (
    <div className='flex labeAndValue'>
      <div className='w-full'>
        <p className='remove-margin'>
          <b>{labels.first} : </b>
          {values.first}
        </p>
      </div>
      <div className='dash-border'></div>

      <div className='w-full'>
        <p className='remove-margin'>
          <b>{labels.second} : </b>
          {values.second}
        </p>
      </div>
    </div>
  )
}
const TermsCondition = () => {
  const formik = useFormik({
    initialValues: {
      terms: ` <ul>
            <li>GST extra 18%</li>
            <li>Transportation extra as at actual.</li>
            <li>Delivery within 10 days after confimation.</li>
            <li>Paymnet Terms : 60 Days</li>
            <li>
              Goods once sold will not be taken back. Prices valid till 31 July
              2024.&nbsp;
            </li>
            <li>
              Technical service will charge extra as per manufacturer
              Standard.&nbsp;
            </li>
            <li>
              If there are any changes in material prices by Jotun and Taxes by
              the Govt. at the time of order will be applicable.
            </li>
          </ul>`,
    },

    onSubmit: async values => {},
  })

  const modules = {
    toolbar: [
      // [{ header: [2, 3, 4, true] }],
    ],
  }

  return (
    <div className='gray-border' style={{ padding: "0" }}>
      {" "}
      <div className='profile_personal_details'>
        <div
          className='col1 heading-tools details-controls'
          style={{ padding: "1.8rem 1.8rem 1rem 1.8rem" }}
        >
          <h2>Terms & Condition</h2>
        </div>
        <div className='ae-evIp flex-column-center'>
          <ReactQuill
            modules={modules}
            theme='snow'
            placeholder=''
            // onChange={handleDescChange}
            value={formik.values.terms}
            readOnly={true}
          />
        </div>
        {/* <FormButtons
        // loading={companyLoading}
        removeCancel
        btnContainerStyles={{
          justifyContent: "flex-end",
          padding: ".5rem",
        }}
      /> */}
      </div>
    </div>
  )
}
