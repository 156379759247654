import { createSlice } from "@reduxjs/toolkit"
import {
  getInventoryDetails,
  getInventoryHistory,
  getInventoryList,
} from "./InventoryApi"

const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    inventoryLoading: false,
    stockLoading: false,
    inventoryList: [],
    stockMovementData: [],
    inventoryDetails: [],
    inventoryHistory: [],
    metaData: [],
    inventoryHistoryMetaData: [],
  },
  reducers: {
    isInventoryToggle: (state, { payload }) => {
      state.inventoryLoading = payload
    },
    isStockMovementToggle: (state, { payload }) => {
      state.stockLoading = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getInventoryList.pending, (state, action) => {
        state.inventoryLoading = true
      })
      .addCase(getInventoryList.fulfilled, (state, { payload }) => {
        state.inventoryLoading = false
        state.inventoryList = payload?.data
        state.metaData = payload?.metadata[0]
      })
      .addCase(getInventoryList.rejected, (state, { payload }) => {
        state.inventoryLoading = false
      })
    builder
      .addCase(getInventoryDetails.pending, (state, { payload }) => {
        state.inventoryLoading = true
      })
      .addCase(getInventoryDetails.fulfilled, (state, { payload }) => {
        state.inventoryLoading = false
        state.inventoryDetails = payload?.data?.data
      })
      .addCase(getInventoryDetails.rejected, state => {
        state.inventoryLoading = false
      })
    builder
      .addCase(getInventoryHistory.pending, (state, { payload }) => {
        state.inventoryLoading = true
      })
      .addCase(getInventoryHistory.fulfilled, (state, { payload }) => {
        state.inventoryLoading = false
        state.inventoryHistory = payload?.data
        state.inventoryHistoryMetaData = payload?.metadata?.[0]
          ? payload?.metadata?.[0]
          : {}
      })
      .addCase(getInventoryHistory.rejected, state => {
        state.inventoryLoading = false
      })
  },
})

export const { isInventoryToggle, isStockMovementToggle } =
  inventorySlice.actions
export default inventorySlice.reducer
