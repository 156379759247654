import { createSlice } from "@reduxjs/toolkit"
import { getEmailDetail, getEmailList } from "./paymentApi"

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentLoading: false,
    metaData: {},
  },

  reducers: {
    isPaymentToggle: (state, { payload }) => {
      state.paymentLoading = payload
    },
  },
})

export const { isPaymentToggle } = paymentSlice.actions
export default paymentSlice.reducer
