/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react"
// import { ClipLoader } from "react-spinners"
// import { STOCK_TRANSFER_DATA } from "../../../helper/dummy_data"
// import Skelaton from "../../../components/Skelaton"
import { useNavigate } from "react-router-dom"
// import { shallowEqual, useSelector } from "react-redux"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import * as Yup from "yup"
import DropDownBox from "../../../components/DropDownBox"
import {
  getStockHistoryList,
  getStockList,
  stockMovement,
} from "../redux/stockManagementApi"
import { removeToAndFromLists } from "../redux/stockManagementSlice"
import FormButtons from "../../../components/SubmitBtns"
import { toast } from "react-toastify"

const StockTransfer = ({ popup, setPopup, onSave }) => {
  const [addStyle, setAddStyle] = useState(true)
  // const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { fromList, toList, stockProductLoading } = useSelector(
    store => store.stock,
  )

  const { warehousesList } = useSelector(store => store.company)

  const [fromStore, setFromStore] = useState("")

  const [toStore, setToStore] = useState("")

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleClick = () => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (popup) {
      const styleTimer = setTimeout(() => {
        setPopup(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  const formik = useFormik({
    initialValues: {
      rows: [
        {
          sku_num: "",
          product: "",
          from_store: "",
          to_location: "",
          quantity: "",
          unit: "",
          productId: "",
        },
      ],
    },
    validationSchema: Yup.object().shape({
      rows: Yup.array().of(
        Yup.object().shape({
          sku_num: Yup.string().required("SKU is required"),
          product: Yup.string().required("Product Name is required"),
          from_store: Yup.string(),
          to_location: Yup.string(),
          quantity: Yup.string().required("Quantity is required"),
          unit: Yup.string().required("Price/Unit is required"),
        }),
      ),
    }),
    onSubmit: async values => {
      const options = values.rows.map((item, index) => {
        return {
          product_id: item.productId,
          quantity: item.quantity,
          price: item.unit,
        }
      })
      const data = {
        products: options,
        sourceLocation: fromStore,
        destinationLocation: toStore,
      }

      const response = await dispatch(stockMovement(data))
      if (response.payload) {
        dispatch(
          getStockHistoryList({
            search: "",
            page: "0",
            limit: 5,
            sort: "desc",
          }),
        )
        onSave(values.rows)
        dispatch(removeToAndFromLists())
        handleClick()
      }
    },
  })
  const [focusedField, setFocusedField] = useState()
  const [showHsnBox, setShowHsnBox] = useState(false)
  const [hsnList, setHsnList] = useState([])

  //
  const addRow = () => {
    const newRow = {
      sku_num: "",
      product: "",
      from_store: "",
      to_location: "",
      quantity: "",
      schedule_date: "",
      unit: "",
      productId: "",
    }
    formik.setFieldValue("rows", [...formik.values.rows, newRow])
  }

  const deleteRow = index => {
    const rows = [...formik.values.rows]
    rows.splice(index, 1)
    formik.setFieldValue("rows", rows)
  }

  // useEffect(() => {
  //   formik.setFieldValue("rows", [
  //     {
  //       sku_num: stockList[0]?.sku,
  //       product: stockList[0]?.name,
  //       uom: stockList[0]?.uom,
  //       unit: stockList[0]?.unitPrice,
  //     },
  //   ])
  // }, [stockList, formik.values.from])

  //

  // useEffect(() => {
  //   if (searchTimeout) {
  //     clearTimeout(searchTimeout)
  //   }

  //   const timeoutId = setTimeout(async () => {
  //     if (true) {
  //       const response = await dispatch(
  //         getStockList({ search: formik.values.hsnCode }),
  //       )
  //       setHsnList(response.payload)
  //     }
  //   }, 1000)

  //   setSearchTimeout(timeoutId)
  // }, [formik.values])
  const [searchTimeout, setSearchTimeout] = useState(null)
  const callApi = async (value, index) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    const timeoutId = setTimeout(async () => {
      if (value?.length > 2) {
        const response = await dispatch(
          getStockList({ search: value, send: { locationId: fromStore } }),
        )
        setHsnList(response?.payload?.data)
      }
    }, 1000)
    setSearchTimeout(timeoutId)
  }

  // useEffect(() => {
  //   if(formik.value)
  // }, [fromStore, toStore])

  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>Transfer Details</h2>
          <button
            className='exit-btn'
            onClick={() => {
              handleClick()
              dispatch(removeToAndFromLists())
            }}
          >
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>
        <div className='modelEx_content'>
          {/* <div className='data-table-container'> */}
          <form onSubmit={formik.handleSubmit}>
            <div
              className='td_table_container'
              style={
                formik.values.rows.length === 1
                  ? { paddingBottom: "9rem" }
                  : formik.values.rows.length === 2
                    ? { paddingBottom: "4.5rem" }
                    : {}
              }
              // style={{paddingBottom:"6rem"}}
            >
              <table className='td_table'>
                <thead>
                  <tr>
                    <th>From Location</th>
                    <th>To Location</th>
                    <th>Product Name</th>
                    <th>SKU</th>
                    <th>Transfer Quantity</th>
                    {/* <th>Schedule Date</th> */}
                    <th>Price/Unit</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.rows.map((row, index) => (
                    <tr key={index}>
                      {" "}
                      <td>
                        {/* <input
                          type='text'
                          name={`rows[${index}].from_store`}
                          onChange={formik.handleChange}
                          // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.from_store ? "error" : ""}`}
                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.from_store && formik?.touched?.rows[index]?.from_store ? "error" : ""}`}
                          value={row.from_store}
                        /> */}
                        <div
                          style={{
                            width: "300px",
                            // border: "1px solid #d7cece",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.from_store && formik?.touched?.rows[index]?.from_store ? "error" : ""}`}
                        >
                          <DropDownBox
                            options={warehousesList}
                            customSetter={setFromStore}
                            dropDownTitle='From Store'
                            animateDropDownTitle
                            customDropBoxStyles={{
                              border: "none",
                            }}
                            customTextStyle={{
                              color: "#212229",
                              opacity: "0.7",
                            }}
                            size={""}
                            disabled={index === 0 ? true : false}
                            incomingValue={index !== 0 ? fromStore : undefined}
                          />
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "300px",
                            // border: "1px solid #d7cece",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.to_location && formik?.touched?.rows[index]?.to_location ? "error" : ""}`}
                        >
                          <DropDownBox
                            options={warehousesList}
                            customSetter={setToStore}
                            dropDownTitle='To Location'
                            animateDropDownTitle
                            customDropBoxStyles={{
                              border: "none",
                            }}
                            customTextStyle={{
                              color: "#212229",
                              opacity: "0.7",
                            }}
                            size={""}
                            disabled={index === 0 ? true : false}
                            incomingValue={index !== 0 ? toStore : undefined}
                          />
                        </div>
                        {/* <input
                          type='text'
                          name={`rows[${index}].to_location`}
                          onChange={formik.handleChange}
                          // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.to_location ? "error" : ""}`}
                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.to_location && formik?.touched?.rows[index]?.to_location ? "error" : ""}`}
                          value={row.to_location}
                        /> */}
                      </td>
                      <td>
                        {/* <input
                          type='text'
                          name={`rows[${index}].product`}
                          onChange={formik.handleChange}
                          onInput={async (e, index) => {
                            if (e.target.value.length >= 3) {
                              const response = await dispatch(
                                getStockList({ search: e.target.value }),
                              )
                              
                            }
                          }}
                          // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.product ? "error" : ""}`}
                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.product && formik?.touched?.rows[index]?.product ? "error" : ""}`}
                          value={row.product}
                        /> */}
                        <div className='hsnBox' style={{ width: "300px" }}>
                          <div
                            className={`input-field ${
                              formik.values.hsnCode !== "" ||
                              focusedField === "hsnCode"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              // id={`rows[${index}].product`}
                              name={`rows[${index}].product`}
                              type='text'
                              // className='form-input td_input'
                              onChange={e => {
                                formik.handleChange(e)
                                callApi(e.target.value, index)
                                setShowHsnBox(true)
                              }}
                              value={row.product}
                              maxLength={81}
                              onBlur={e => {
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField(index)}
                              className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.sku_num && formik?.touched?.rows[index]?.sku_num ? "error" : ""}`}
                              disabled={fromStore && toStore ? false : true}
                            />
                          </div>

                          {formik.values.rows[index].product.length >= 2 &&
                            index === focusedField &&
                            showHsnBox && (
                              <div className='hsnSelectBox'>
                                {hsnList?.length > 0 && !stockProductLoading ? (
                                  hsnList?.map((item, i) => {
                                    const { _id, name, brand_name } = item
                                    return (
                                      <div
                                        key={i}
                                        className={
                                          "drop-down-item2" +
                                          (formik?.values?.rows[index]
                                            ?.product === name
                                            ? " selectedDropBox"
                                            : "")
                                        }
                                        onClick={() => {
                                          formik.setFieldValue(
                                            `rows[${index}].product`,
                                            name,
                                          )
                                          formik.setFieldValue(
                                            `rows[${index}].productId`,
                                            _id,
                                          )
                                          formik.setFieldValue(
                                            `rows[${index}].sku_num`,
                                            item.sku,
                                          )
                                          // formik.setFieldValue(
                                          //   `rows[${index}].unit`,
                                          //   item.unitPrice,
                                          // )
                                          formik.setFieldValue(
                                            `rows[${index}].unit`,
                                            item.price,
                                          )
                                          setShowHsnBox(false)
                                          setHsnList([])
                                        }}
                                      >
                                        <span>{`${name} (${brand_name})`}</span>
                                      </div>
                                    )
                                  })
                                ) : (
                                  <div className='drop-down-item2'>
                                    {stockProductLoading ? (
                                      <ClipLoader
                                        loading={true}
                                        size={13}
                                        aria-label='Loading Spinner'
                                        data-testid='loader'
                                      />
                                    ) : (
                                      <span>No Data Found</span>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                        </div>{" "}
                      </td>{" "}
                      <td>
                        <div className='input-field'>
                          <input
                            type='text'
                            name={`rows[${index}].sku_num`}
                            onChange={formik.handleChange}
                            value={row.sku_num}
                            style={{ cursor: "not-allowed" }}
                            disabled
                            className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.sku_num && formik?.touched?.rows[index]?.sku_num ? "error" : ""}`}
                          />
                        </div>
                      </td>
                      {/* <td>
                        <input
                          type='text'
                          name={`{rows[${index}].quantity}`}
                          onChange={formik.handleChange}
                          // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.quantity ? "error" : ""}`}
                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.quantity && formik?.touched?.rows[index]?.quantity ? "error" : ""}`}
                          value={row.quantity}
                        />
                      </td> */}
                      <td>
                        <div className='input-field'>
                          <input
                            type='text'
                            name={`rows[${index}].quantity`}
                            onChange={formik.handleChange}
                            // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.quantity ? "error" : ""}`}
                            className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.quantity && formik?.touched?.rows[index]?.quantity ? "error" : ""}`}
                            value={row.quantity}
                            disabled={fromStore && toStore ? false : true}
                          />
                        </div>
                      </td>
                      {/* <td>
                        <input
                          type='date'
                          name={`rows[${index}].schedule_date`}
                          onChange={formik.handleChange}
                          // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.schedule_date ? "error" : ""}`}
                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.schedule_date && formik?.touched?.rows[index]?.schedule_date ? "error" : ""}`}
                          value={row.schedule_date}
                        />
                      </td> */}
                      <td>
                        <div
                          className='input-field'
                          onClick={() => {
                            if (true) {
                              toast.error(
                                "Please select from location and to location",
                              )
                            }
                          }}
                        >
                          <input
                            type='text'
                            name={`rows[${index}].unit`}
                            onChange={formik.handleChange}
                            // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                            className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.unit && formik?.touched?.rows[index]?.unit ? "error" : ""}`}
                            value={row.unit}
                            disabled={fromStore && toStore ? false : true}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          type='button'
                          className='icon-btn delete'
                          onClick={() => deleteRow(index)}
                          disabled={
                            formik.values.rows.length === 1 ? true : false
                          }
                        >
                          <span className='material-symbols-outlined medium'>
                            delete
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='error-msg'>
                {formik.errors.rows ? "Please fill the data first!" : ""}
              </div>
            </div>

            {/* <div className='flex-center modelEx_footer'>
              <button type='button' className='btn-primary' onClick={addRow}>
                Add Row
              </button>
              <button
                type='submit'
                disabled={stockProductLoading}
                className='btn-secondary'
                // disabled={profileLoading}
                style={
                  stockProductLoading
                    ? {
                        cursor: "not-allowed",
                        color: "transparent",
                        position: "relative",
                      }
                    : {}
                }
              >
                Transfer Stock{" "}
                {stockProductLoading && (
                  <span
                    style={{
                      position: "absolute",
                      left: "0",
                      right: "0",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <ClipLoader
                      loading={true}
                      size={13}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                      color='white'
                    />
                  </span>
                )}
              </button>
            </div> */}

            <FormButtons
              cancelBtnText='Add Row'
              loading={stockProductLoading}
              abortHandleClick={addRow}
              submitBtnText='Transfer Stock'
              btnContainerStyles={{
                justifyContent: "center",
                gap: "2rem",
                padding: "1rem",
              }}
            />
          </form>
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export default StockTransfer
