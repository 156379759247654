import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { isCatalogueToggle } from "./ProductCatalogueSlice"
import { toast } from "react-toastify"
// import { isCatalogueToggle } from "./ProductCatalogueSlice"
// import axios from "axios"
// import { toast } from "react-toastify"

// export const addMoreOptions = createAsyncThunk(
//   "catalogue/add-more-options",
//   async (data, { fulfillWithValue, rejectWithValue }) => {
//     try {
//       const response = await axios({
//         method: "POST",
//         url: `${process.env.REACT_APP_AUTH_URL}/admin/catalouge/${data.slug}`,
//       })

//       if()
//     } catch (error) {}
//   },
// )
export const addMoreOptions = createAsyncThunk(
  "catalogue/add-more-options",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    
    dispatch(isCatalogueToggle(true))
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalouge/${data.slug}`,
        headers: {
          "Content-type": "application/json",
        },
        data: data.send,
      })
      if (response.status === 200) {
        toast.success(response?.data?.message)
        dispatch(isCatalogueToggle(false))
        return response?.data?.status
      } else {
        toast.error(response.data.message)
        dispatch(isCatalogueToggle(false))
      }
    } catch (error) {
      dispatch(isCatalogueToggle(false))
      toast.error(error)
    }
  },
)
export const updateMoreOptions = createAsyncThunk(
  "catalogue/update-more-options",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    
    dispatch(isCatalogueToggle(true))
    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalouge/${data?.slug}/${data?.id}`,
        headers: {
          "Content-type": "application/json",
        },
        data: data.send,
      })
      if (response.status === 200) {
        toast.success(response?.data?.message)
        dispatch(isCatalogueToggle(false))
        return response?.data?.status
      } else {
        toast.error(response.data.message)
        dispatch(isCatalogueToggle(false))
      }
    } catch (error) {
      dispatch(isCatalogueToggle(false))
      toast.error(error)
    }
  },
)
