/* eslint-disable no-useless-escape */
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import * as Yup from "yup"
import DropDownBox from "../../../components/DropDownBox"

const PaymentModePopup = ({ paymentPopup, setPaymentPopup }) => {
  const { companyLoading } = useSelector(store => store.company)

  const [focusedField, setFocusedField] = useState()
  const [addStyle, setAddStyle] = useState(true)
  const formik = useFormik({
    initialValues: {
      mode: "",
      paymentAccount: "",
      ifsc: "",
      bankName: "",
      beneficiaryName: "",
      chequeNumber: "",
      payeeName: "",
      chequeDate: "",
      accountNumber: "",
      upiId: ""
    },
    // validationSchema: locationSchema,
    onSubmit: async (values, { resetForm }) => {
       
    },
  })

  const handleClick = e => {
    setPaymentPopup({ mode: "", statue: false })
  }

  useEffect(() => {
    formik.setValues({
      mode: paymentPopup.mode
    })
  }, [paymentPopup])


  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>
            Payment Details
          </h2>
          <button className='exit-btn' onClick={() => handleClick()}>
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>

        <div className='modelEx_content singleAdd'>
          <form className='scrollForm' onSubmit={formik.handleSubmit}>
            <div
              className='form-content form-for-Address'
              style={{ paddingBottom: "2.5rem" }}
            >
              <div className='update-form-activation'>
                <h3 className='title'>
                  Total Amount : ₹50,500
                </h3>

              </div>
              <div className='form-group form'>
                <div className='input-field'>
                  <DropDownBox
                    options={[
                      { label: "Bank Transfer", value: "banktransfer" },
                      { label: "Cheque", value: "cheque" },
                      { label: "UPI", value: "upi" },
                      { label: "Cash", value: "cash" },
                    ]}
                    customSetter={formik.setFieldValue}
                    customDropBoxStyles={{
                      border: "1px solid #d7cece",
                      borderRadius: "0.5rem",
                      color: "#333",
                    }}
                    customTextStyle={{ color: "#212229", opacity: "0.7" }}
                    dropDownTitle='Payment Mode'
                    animateDropDownTitle
                    customFormikLabel={"mode"}
                    incomingValue={formik.values.mode}
                  />
                  {formik.touched.mode && formik.errors.mode ? (
                    <span className='err'>{formik.errors.mode}</span>
                  ) : null}
                </div>
                {
                  formik.values.mode === "banktransfer" ?
                    <>
                      <div className='col-2'>
                        <div
                          className={`input-field ${formik.values.paymentAccount !== "" || focusedField === "paymentAccount"
                            ? "active"
                            : ""
                            }`}
                        >
                          <label htmlFor='address-line-one' className='toggle-label'>
                            Payment Account
                          </label>
                          <input
                            id='paymentAccount'
                            name='paymentAccount'
                            type='text'
                            className='form-input'
                            value={formik.values.paymentAccount}
                            onChange={formik.handleChange}
                            onInput={e => {
                              e.target.value = e.target.value.toUpperCase()
                            }}
                            maxLength={11}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField("paymentAccount")}

                          />
                          {formik.touched.paymentAccount && formik.errors.paymentAccount ? (
                            <span className='err'>{formik.errors.paymentAccount}</span>
                          ) : null}
                        </div>
                        <div
                          className={`input-field ${formik.values.ifsc !== "" ||
                            focusedField === "ifsc"
                            ? "active"
                            : ""
                            }`}
                        >
                          <label htmlFor='address-line-one' className='toggle-label'>
                            IFSC Code
                          </label>
                          <input
                            id='ifsc'
                            name='ifsc'
                            type='text'
                            className='form-input'
                            value={formik.values.ifsc}
                            onChange={formik.handleChange}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /(?:^|\s)\S/g,
                                match => match.toUpperCase(),
                              )
                            }}
                            maxLength={80}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField("ifsc")}

                          />
                          {formik.touched.ifsc &&
                            formik.errors.ifsc ? (
                            <span className='err'>
                              {formik.errors.ifsc}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className='input-field'>
                          <DropDownBox
                            options={[
                              { label: "Bank of Baroda", value: "bob" },
                              { label: "State Bank of Imdia", value: "sbi" },
                              { label: "HDFC", value: "hdfc" },
                            ]}
                            customSetter={formik.setFieldValue}
                            customDropBoxStyles={{
                              border: "1px solid #d7cece",
                              borderRadius: "0.5rem",
                              color: "#333",
                            }}
                            customTextStyle={{ color: "#212229", opacity: "0.7" }}
                            dropDownTitle='Payment Bank'
                            animateDropDownTitle
                            // loading={optionsLoading}
                            customFormikLabel={"bankName"}
                          />
                          {formik.touched.bankName && formik.errors.bankName ? (
                            <span className='err'>{formik.errors.bankName}</span>
                          ) : null}
                        </div>
                        <div
                          className={`input-field ${formik.values.beneficiaryName !== "" || focusedField === "beneficiaryName"
                            ? "active"
                            : ""
                            }`}
                        >
                          <label htmlFor='address-line-one' className='toggle-label'>
                            Beneficiary Name
                          </label>
                          <input
                            id='beneficiaryName'
                            name='beneficiaryName'
                            type='text'
                            className='form-input'
                            value={formik.values.beneficiaryName}
                            onChange={formik.handleChange}
                            onInput={e => {
                              e.target.value = e.target.value.toUpperCase()
                            }}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField("beneficiaryName")}

                          />
                          {formik.touched.beneficiaryName && formik.errors.beneficiaryName ? (
                            <span className='err'>{formik.errors.beneficiaryName}</span>
                          ) : null}
                        </div>
                      </div>
                    </> : formik.values.mode === "cheque" ?
                      <>
                        <div className='col-2'>
                          <div
                            className={`input-field ${formik.values.chequeNumber !== "" || focusedField === "chequeNumber"
                              ? "active"
                              : ""
                              }`}
                          >
                            <label htmlFor='address-line-one' className='toggle-label'>
                              Cheque Number
                            </label>
                            <input
                              id='chequeNumber'
                              name='chequeNumber'
                              type='text'
                              className='form-input'
                              value={formik.values.chequeNumber}
                              onChange={formik.handleChange}
                              onInput={e => {
                                e.target.value = e.target.value.toUpperCase()
                              }}
                              maxLength={11}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("chequeNumber")}

                            />
                            {formik.touched.chequeNumber && formik.errors.chequeNumber ? (
                              <span className='err'>{formik.errors.chequeNumber}</span>
                            ) : null}
                          </div>
                          <div
                            className={`input-field ${formik.values.payeeName !== "" ||
                              focusedField === "payeeName"
                              ? "active"
                              : ""
                              }`}
                          >
                            <label htmlFor='address-line-one' className='toggle-label'>
                              Payee Name
                            </label>
                            <input
                              id='payeeName'
                              name='payeeName'
                              type='text'
                              className='form-input'
                              value={formik.values.payeeName}
                              onChange={formik.handleChange}
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /(?:^|\s)\S/g,
                                  match => match.toUpperCase(),
                                )
                              }}
                              maxLength={80}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("payeeName")}

                            />
                            {formik.touched.payeeName &&
                              formik.errors.payeeName ? (
                              <span className='err'>
                                {formik.errors.payeeName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className='col-2'>
                          <div
                            className={`input-field ${formik.values.chequeDate !== "" || focusedField === "chequeDate"
                              ? "active"
                              : ""
                              }`}
                          >
                            <label htmlFor='address-line-one' className='toggle-label'>
                              Cheque Date
                            </label>
                            <input
                              id='chequeDate'
                              name='chequeDate'
                              type='date'
                              className='form-input'
                              value={formik.values.chequeDate}
                              onChange={formik.handleChange}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("chequeDate")}

                            />
                            {formik.touched.chequeDate && formik.errors.chequeDate ? (
                              <span className='err'>{formik.errors.chequeDate}</span>
                            ) : null}
                          </div>
                          <div
                            className={`input-field ${formik.values.accountNumber !== "" ||
                              focusedField === "accountNumber"
                              ? "active"
                              : ""
                              }`}
                          >
                            <label htmlFor='address-line-one' className='toggle-label'>
                              Account Number
                            </label>
                            <input
                              id='accountNumber'
                              name='accountNumber'
                              type='text'
                              className='form-input'
                              value={formik.values.accountNumber}
                              onChange={formik.handleChange}
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /(?:^|\s)\S/g,
                                  match => match.toUpperCase(),
                                )
                              }}
                              maxLength={80}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("accountNumber")}

                            />
                            {formik.touched.accountNumber &&
                              formik.errors.accountNumber ? (
                              <span className='err'>
                                {formik.errors.accountNumber}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </> : formik.values.mode === "upi" ?
                        <div
                          className={`input-field ${formik.values.upiId !== "" || focusedField === "upiId"
                            ? "active"
                            : ""
                            }`}
                        >
                          <label htmlFor='address-line-one' className='toggle-label'>
                            UPI ID
                          </label>
                          <input
                            id='upiId'
                            name='upiId'
                            type='text'
                            className='form-input'
                            value={formik.values.upiId}
                            onChange={formik.handleChange}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField("upiId")}

                          />
                          {formik.touched.upiId && formik.errors.upiId ? (
                            <span className='err'>{formik.errors.upiId}</span>
                          ) : null}
                        </div> : ""
                }
              </div>
            </div>

            <div className='footer flex-center'>
              {/* <button type="submit" className="btn-primary">
                Submit
              </button> */}
              <button
                className='btn-primary'
                type='submit'
                disabled={companyLoading}
              >
                {companyLoading ? (
                  <ClipLoader
                    loading={true}
                    size={15}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                    color='white'
                  />
                ) : (
                  "SUBMIT"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PaymentModePopup
