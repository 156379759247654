import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"
import { IpAddress, options } from "../../../helper/helper"
import { isAddressLoading } from "../../buyers&suppliers/redux/bNsSlice"
import { setProfileDetail } from "../../profile/redux/profileSlice"
import {
  isAuthToggle,
  setIp,
  setIsVerify,
  setLatitude,
  setLongitude,
  setUserEmail,
} from "./authSlice"
import {
  setBankDetail,
  setCompanyDetail,
} from "../../company/redux/companySlice"

export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/login`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        let is2FA = response?.data?.data?.is_2FA?.value
        let isVerify = response?.data?.data?.is_2FA?.is_verified
        if (((is2FA && isVerify) || (!is2FA && !isVerify)) === false) {
          dispatch(
            sendOtp({
              email: response?.data?.data?.email,
              phone: response?.data?.data?.phone,
            }),
          )
        } else {
          toast.success(response?.data?.message, options)
        }
        // dispatch(setUserEmail(data?.email));
        return fulfillWithValue(response?.data)
      } else {
        toast.error(response?.data?.message, options)
        return rejectWithValue()
      }
    } catch (err) {
      // toast.error(err.?message, options);
      return rejectWithValue()
    }
  },
)

export const logout = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/logout`,
      })
      if (response.status === 200) {
        toast.success(response?.data?.message, options)
        dispatch(setProfileDetail([]))
        dispatch(setCompanyDetail([]))
        dispatch(setBankDetail([]))
        return fulfillWithValue(response?.data?.status)
      } else {
        toast.error(response?.data?.message, options)
        return rejectWithValue()
      }
    } catch (err) {
      return rejectWithValue()
    }
  },
)

export const forgotPassword = createAsyncThunk(
  "auth/forgot-password",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      // dispatch(isAuthToggle(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/forgot-password`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        toast.success(response?.data?.message, options)
        dispatch(isAuthToggle(false))
        return fulfillWithValue(response?.data)
      } else {
        toast.error(response?.data?.message, options)
        dispatch(isAuthToggle(false))
        return rejectWithValue()
      }
    } catch (err) {
      dispatch(isAuthToggle(false))
      return rejectWithValue()
    }
  },
)

export const verifyTokenAsync = createAsyncThunk(
  "auth/verify-token",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAuthToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/auth/verify-token`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        dispatch(isAuthToggle(false))
        return response.data.status
      } else {
        dispatch(isAuthToggle(false))
        return response.data.status
      }
    } catch (err) {
      dispatch(isAuthToggle(false))
      return
    }
  },
)

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAuthToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/reset-password`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        toast.success(response?.data?.message, options)
        dispatch(isAuthToggle(false))
        return response?.data?.status
      } else {
        dispatch(isAuthToggle(false))
        toast.error(response?.data?.message, options)
        return response?.data?.status
      }
    } catch (err) {
      dispatch(isAuthToggle(false))
      toast.error(err, options)
      return
    }
  },
)

export const verifyOtp = createAsyncThunk(
  "auth/verify-otp",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAuthToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/auth/verify-otp`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        toast.success(response?.data?.message, options)
        dispatch(isAuthToggle(false))
        dispatch(setIsVerify(true))
        return response?.data?.status
      } else {
        toast.error(response?.data?.message, options)
        dispatch(isAuthToggle(false))
      }
    } catch (err) {
      dispatch(isAuthToggle(false))
      toast.error(err, options)
    }
  },
)

export const getIPaddressAsync = createAsyncThunk(
  "auth/get-ip-address",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const responseIp = await IpAddress()

      if (responseIp) {
        dispatch(setLatitude(responseIp?.latitude))
        dispatch(setLongitude(responseIp?.longitude))
        dispatch(setIp(responseIp?.ip))

        return fulfillWithValue(responseIp)
      } else {
        return rejectWithValue()
      }
    } catch (err) {
      return rejectWithValue()
    }
  },
)

export const sendOtp = createAsyncThunk(
  "auth/send-otp/message",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAuthToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/public/send-otp/mail`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isAuthToggle(false))
        toast.success(response?.data?.message, options)
        return response?.data?.status
      } else {
        dispatch(isAuthToggle(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isAuthToggle(false))
      toast.error(err, options)
    }
  },
)

export const getLocalityDetail = createAsyncThunk(
  "public/get-locality-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAddressLoading(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/public/locality-detail`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        // toast.success(response?.data?.message, options);
        dispatch(isAddressLoading(false))
        return response
      } else {
        toast.error(response?.data?.message, options)
        dispatch(isAddressLoading(false))
      }
    } catch (err) {
      dispatch(isAddressLoading(false))
      // toast.error(err, options);
    }
  },
)

export const googleLogin = createAsyncThunk(
  "user/google-login",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/google-login`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        toast.success(response?.data?.message, options)
        dispatch(setUserEmail(data?.email))
        return fulfillWithValue(response?.data)
      } else {
        toast.error(response?.data?.message, options)
        return rejectWithValue()
      }
    } catch (err) {
      return rejectWithValue()
    }
  },
)
