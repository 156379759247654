import { createSlice } from "@reduxjs/toolkit"
import {
  getProductsDetails,
  getProductsList,
} from "../../products/redux/ProductsApi"
import {
  getStockHistoryDetail,
  getStockHistoryList,
  getStockList,
} from "./stockManagementApi"

const stockManagementSlice = createSlice({
  name: "stock",
  initialState: {
    singleStockHistoryDetail: [],
    stockProductLoading: false,
    stockHistoryList: [],
    stockLoading: false,
    stockDetails: [],
    stockList: [],
    metaData: [],
  },
  reducers: {
    isStockToggle: (state, { payload }) => {
      state.stockLoading = payload
    },
    isStockProductLoading: (state, { payload }) => {
      state.stockProductLoading = payload
    },
    handleSingleStockHistory: (state, { payload }) => {
      state.singleStockHistoryDetail = payload
    },
    removeToAndFromLists: state => {
      state.fromList = []
      state.toList = []
    },
  },

  extraReducers: builder => {
    // builder
    //   .addCase(getProductsList.pending, (state, action) => {
    //     state.stockLoading = true
    //   })
    //   .addCase(getProductsList.fulfilled, (state, { payload }) => {
    //     state.stockLoading = false
    //     state.stockList = payload?.data
    //     state.metaData = payload?.metadata[0]
    //   })
    //   .addCase(getProductsList.rejected, (state, { payload }) => {
    //     state.stockLoading = false
    //   })
    builder
      .addCase(getStockList.pending, (state, action) => {
        state.stockProductLoading = true
      })
      .addCase(getStockList.fulfilled, (state, { payload }) => {
        state.stockProductLoading = false
        state.stockList = [...state.stockList, payload?.data[0]]
      })
      .addCase(getStockList.rejected, (state, { payload }) => {
        state.stockProductLoading = false
      })
    builder
      .addCase(getStockHistoryList.pending, (state, action) => {
        state.stockLoading = true
      })
      .addCase(getStockHistoryList.fulfilled, (state, { payload }) => {
        state.stockLoading = false
        state.stockHistoryList = payload?.data[0]?.data
        state.metaData = payload?.data[0]?.metadata[0]
      })
      .addCase(getStockHistoryList.rejected, (state, { payload }) => {
        state.stockLoading = false
      })
    builder
      .addCase(getStockHistoryDetail.pending, (state, action) => {
        state.stockLoading = true
      })
      .addCase(getStockHistoryDetail.fulfilled, (state, { payload }) => {
        state.singleStockHistoryDetail = payload?.data?.data[0]
        state.stockLoading = false
      })
      .addCase(getStockHistoryDetail.rejected, (state, { payload }) => {
        state.stockLoading = false
      })
  },
})

export const {
  isStockToggle,
  handleSingleStockHistory,
  isStockProductLoading,
  removeToAndFromLists,
} = stockManagementSlice.actions
export default stockManagementSlice.reducer
