/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import FilterBox from "../../../components/FilterBox"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Pagination from "../../../components/Pagination"
import SearchBox from "../../../components/SearchBox"
import Sidebar from "../../../components/Sidebar"
import Skelaton from "../../../components/Skelaton"
import { formatTimestamp } from "../../../helper/helper"
import { getSelfCompanyAddress } from "../../company/redux/companyApi"
import StockTransfer from "../components/StockTransfer"
import {
  downloadStockSheet,
  getStockHistoryList,
} from "../redux/stockManagementApi"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
// import Loader from "../../../components/Loader";

const StockMovement = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Stock Management")
  }, [setTitle])

  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const [active, setActive] = useState("stock-movement")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [showStockTransfer, setShowStockTransfer] = useState(false)
  const [rows, setRows] = useState([])
  const { companyAddress } = useSelector(store => store.company)
  const [scrollPosition, setScrollPosition] = useState(
    document?.getElementById("scrollbar-visible")?.scrollLeft,
  )

  const scrollbarShadow = useRef(null)
  const { stockHistoryList, metaData, stockLoading } = useSelector(
    store => store.stock,
  )
  // const [tableLoading, setTableLoading] = useState(false)
  // const [tableCount, setTableCount] = useState(null)
  // useEffect(() => {
  //   if (tableCount) {
  //     clearTimeout(tableCount)
  //   }
  //   const count = setTimeout(() => {
  //     // if (stockLoading) {
  //     //   setTableLoading(true);
  //     // } else {
  //     //   setTableLoading(false);
  //     // }
  //     setTableLoading(stockLoading)
  //   }, 1000)
  //   setTableCount(count)
  // }, [stockLoading])
  const { tableLoading } = useLoaderDelay({ loadingState: stockLoading })

  const [filterTable, setFilterTable] = useState({
    number_of_items: true,
    create_on: true,
    user: true,
  })

  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )
  const [btnNo, setBtnNo] = useState([1, 2, 3])

  const handleSave = data => {
    setRows(data)
  }

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  const [search, setSearch] = useState(
    // pageDetails?.search ? pageDetails?.search : "",
    "",
  )

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      dispatch(
        getStockHistoryList({ limit: records, page: 0, search, sort: "desc" }),
      )
      setCheck(true)
      return
    }

    const timeoutId = setTimeout(async () => {
      dispatch(
        getStockHistoryList({ limit: records, page: 0, search, sort: "desc" }),
      )
      setTableIndex(0)
      setBtnNo([1, 2, 3])
    }, 1000)

    setSearchTimeout(timeoutId)
  }, [dispatch, records, search])

  // useEffect(() => {
  //   dispatch(
  //     getStockHistoryList({ search: "", page: "0", limit: 5, sort: "desc" }),
  //   )

  //   // if (companyAddress.length === 0) {
  //   //   dispatch(getSelfCompanyAddress())
  //   // }
  // }, [])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          {/* 
        {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Stock Management</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                    {/* Profile / User Info */}
                  </div>
                </div>
              </div>

              <div className='data-table-container'>
                <div className='tab-header flex-wrap gap-4'>
                  <h3>Stock Movements</h3>
                  <div className='flex-center gap-4 flex-wrap'>
                    <SearchBox customSetter={setSearch} customState={search} />

                    <FilterBox
                      customState={filterTable}
                      customSetter={setFilterTable}
                      customPosition={{ right: "0%", left: "auto" }}
                    />

                    {companyAddress?.length >= 2 &&
                      Object.values(
                        privileges["inventory_management"],
                      ).includes("write") && (
                        <button
                          className='btn-primary self-center'
                          onClick={() =>
                            setShowStockTransfer(!showStockTransfer)
                          }
                          style={{ alignSelf: "self-start" }}
                        >
                          Stock Transfer
                        </button>
                      )}
                  </div>
                </div>
                {tableLoading ? (
                  <Skelaton col={5} row={stockHistoryList.length} />
                ) : stockHistoryList?.length > 0 ? (
                  <>
                    <div
                      ref={scrollbarShadow}
                      className='data-table-box scrollbar-visible'
                      id='scrollbar-visible'
                      onScroll={e => {
                        setScrollPosition(
                          document?.getElementById("scrollbar-visible")
                            ?.scrollLeft,
                        )
                      }}
                    >
                      <table className='data-table stock-table'>
                        <thead>
                          <tr>
                            {/* <th className="tb-head-name">#</th> */}
                            <th
                              className='sticky'
                              style={{
                                padding: 0,
                                paddingRight: "20px",
                                width: "200px",
                              }}
                            >
                              <div
                                className='doc_link  table_nav'
                                style={
                                  scrollPosition > 0
                                    ? {
                                        boxShadow:
                                          "10px 0 15px rgb(209 209 209)",
                                        transition: "ease .2s",
                                        width: "175px",
                                      }
                                    : {
                                        transition: "ease .2s",
                                        width: "175px",
                                      }
                                }
                              >
                                <td>Transaction ID</td>
                              </div>
                            </th>
                            <th>From Store</th>

                            <th>To Location</th>
                            {filterTable.number_of_items ? (
                              <th>Numbers of Items</th>
                            ) : null}
                            {filterTable.create_on ? <th>Created On</th> : null}
                            {filterTable.user ? <th>Created By</th> : null}
                            {/* {Object.values(
                              privileges["production_management"]
                            ).includes("write") && <th>Action</th>} */}
                          </tr>
                        </thead>

                        <tbody>
                          {stockHistoryList?.map((item, index) => {
                            const {
                              _id,
                              transaction_id,
                              from_store_slug,
                              to_store_slug,
                              total_items_transffered,
                              updatedAt,
                              user,
                              user_fname,
                              user_lname,
                            } = item
                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  navigate(`/stock-movement/${_id}`)
                                  // dispatch(handleSingleStockHistory(item))
                                }}
                                className='navig-table-row'
                              >
                                <td
                                  className='sticky'
                                  style={{ padding: 0, paddingRight: "20px" }}
                                >
                                  <div
                                    className='doc_link  table_nav'
                                    style={
                                      scrollPosition > 0
                                        ? {
                                            boxShadow:
                                              "10px 0 15px rgb(209 209 209)",
                                            transition: "ease .2s",
                                            width: "175px",
                                          }
                                        : {
                                            transition: "ease .2s",
                                            width: "175px",
                                          }
                                    }
                                  >
                                    <td>
                                      {transaction_id}
                                      &nbsp; &nbsp;
                                    </td>
                                  </div>
                                </td>

                                <td>{from_store_slug}</td>
                                <td>{to_store_slug}</td>
                                {filterTable.number_of_items ? (
                                  <td>
                                    <span className='status gray'>
                                      {total_items_transffered}{" "}
                                      {total_items_transffered > 1
                                        ? "items"
                                        : "item"}
                                    </span>
                                  </td>
                                ) : null}
                                {filterTable.create_on ? (
                                  <td>{formatTimestamp(updatedAt)}</td>
                                ) : null}
                                {filterTable.user ? (
                                  <td>
                                    {user_fname} {user_lname}
                                  </td>
                                ) : null}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                    {/* <Pagination
                      sliceName={"stock"}
                      search={search}
                      api={getStockHistoryList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                    /> */}
                    <Pagination
                      sliceName={"stock"}
                      search={search}
                      api={getStockHistoryList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                    />
                    <div className='tab-footer'>
                      <a href={process.env.REACT_APP_STOCK_SHEET}>
                        <button
                          className='btn-primary flex-center'
                          // onClick={() => dispatch(downloadStockSheet())}
                        >
                          Download File{" "}
                          <span className='material-symbols-outlined medium'>
                            download
                          </span>
                        </button>
                      </a>
                    </div>
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
          {showStockTransfer && (
            <StockTransfer
              popup={showStockTransfer}
              setPopup={setShowStockTransfer}
              onSave={handleSave}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default StockMovement
