import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { useFormik } from "formik"
import * as Yup from "yup"

import { useState, useEffect } from "react"
import { getOptionsListForTable } from "../../products/redux/ProductsApi"
import { addMoreOptions, updateMoreOptions } from "../redux/ProductCatalogueApi"
import { capitalizeInput, focusOnElement } from "../../../helper/helper"

const UpdatePaintForm = ({ customState, customSetter }) => {
  const [addStyle, setAddStyle] = useState(true)
  const [focusedField, setFocusedField] = useState()
  const { catalogueLoading } = useSelector(store => store.catalogue)
  const dispatch = useDispatch()

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(3, "Paint name must be at least 3 characters long")
        .max(30, "Paint name must be less than 30 characters")
        .required("Please enter paint type name"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        slug: "update-paintType",
        id: customState?.popupData?._id,
        send: {
          paint_type: values.name.trim(),
        },
      }
      const response = await dispatch(updateMoreOptions(data))
      if (response.payload) {
        dispatch(
          getOptionsListForTable({
            listName: "painttype-list",
            page: 0,
            limit: 5,
            search: "",
            sort: "desc",
          }),
        )
        handleClick()
      }
    },
  })

  function chatBoxVisibility() {
    if (customState.edit) {
      const styleTimer = setTimeout(() => {
        customSetter({ ...customState, edit: false })
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")

    formik.setValues({
      name: customState?.popupData?.paint_type,
    })
    focusOnElement("name")
  }, [])

  return (
    <div
      id='delete-card-container'
      className={`${
        addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation"
      }`}
    >
      <div
        className={
          "event-card" +
          (addStyle
            ? " show_delete-component-animation"
            : " hide_delete-component-animation")
        }
      >
        <h2>Update Paint Type</h2>

        <div className='modelEx_content'>
          <form
            className='editbox-form flex-center'
            onSubmit={formik.handleSubmit}
          >
            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.name !== "" || focusedField === "name"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='name' className='toggle-label'>
                  Paint Type Name
                </label>
                <input
                  id='name'
                  name='name'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onInput={capitalizeInput}
                  maxLength={30}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("name")}
                />
                {formik.touched.name && formik.errors.name ? (
                  <span className='err'>{formik.errors.name}</span>
                ) : null}
              </div>
            </div>

            {/* <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.comment !== "" || focusedField === "comment"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='comment' className='toggle-label'>
                  Comment
                </label>
                <textarea
                  id='comment'
                  name='comment'
                  rows={4}
                  className={"form-input"}
                  onChange={formik.handleChange}
                  value={formik.values.comment}
                  maxLength={151}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("comment")}
                    
                />
                {formik.touched.comment && formik.errors.comment ? (
                  <span className='err mt-2'>{formik.errors.comment}</span>
                ) : null}
              </div>
            </div> */}
            <div className='addPopup-btn-box'>
              <button
                type='button'
                className='btn-secondary'
                onClick={handleClick}
              >
                Abort
              </button>
              <button
                className='btn-primary'
                type='submit'
                style={
                  catalogueLoading
                    ? {
                        cursor: "not-allowed",
                        // background: "gray",
                      }
                    : {}
                }
                disabled={catalogueLoading}
              >
                {catalogueLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdatePaintForm
