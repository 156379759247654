export const STOCK_MOVEMENT_DATA = [
  {
    id: 1,
    docNum: "A7300",
    from: "Poonam Coatings Ahmedabad",
    to: "Poonam Coatings Mumbai",
    items: 5,
    startDate: "2020-01-01",
    endDate: "2020-01-10",
    user: "Author1",
    status: "Approved",
  },
  {
    id: 2,
    docNum: "A7300",
    from: "Poonam Coatings Surat",
    to: "Poonam Coatings Vapi",
    items: 5,
    startDate: "2020-02-01",
    endDate: "2020-02-10",
    user: "Author2",
    status: "Rejected",
  },
  {
    id: 3,
    docNum: "A7300",
    from: "Poonam Coatings Ahmedabad",
    to: "Poonam Coatings Jodhpur",
    items: 5,
    startDate: "2020-03-01",
    endDate: "2020-03-10",
    user: "Author3",
    status: "Pending",
  },
  {
    id: 4,
    docNum: "A7300",
    from: "Dispatch4",
    to: "Delivery4",
    items: 5,
    startDate: "2020-04-01",
    endDate: "2020-04-10",
    user: "Author4",
    status: "Approved",
  },
  {
    id: 5,
    docNum: "A7300",
    from: "Dispatch5",
    to: "Delivery5",
    items: 5,
    startDate: "2020-05-01",
    endDate: "2020-05-10",
    user: "Author5",
    status: "Rejected",
  },
  {
    id: 6,
    docNum: "A7300",
    from: "Dispatch6",
    to: "Delivery6",
    items: 5,
    startDate: "2020-06-01",
    endDate: "2020-06-10",
    user: "Author6",
    status: "Pending",
  },
  {
    id: 7,
    docNum: "A7300",
    from: "Dispatch7",
    to: "Delivery7",
    items: 5,
    startDate: "2020-07-01",
    endDate: "2020-07-10",
    user: "Author7",
    status: "Approved",
  },
  {
    id: 8,
    docNum: "A7300",
    from: "Dispatch8",
    to: "Delivery8",
    items: 5,
    startDate: "2020-08-01",
    endDate: "2020-08-10",
    user: "Author8",
    status: "Rejected",
  },
]

export const STOCK_TRANSFER_DATA = [
  {
    id: 1,
    sku: "JS078",
    productName: "ACROLON 7300",
    from: "Poonam Coatings Ahmedabad",
    to: "Poonam Coatings Mumbai",
    quantity: 5,
    uom: "ltr",
    schedule_date: "2020-01-10",
    price: 500,
  },
  {
    id: 2,
    sku: "JS078",
    productName: "ACROLON ERP 7300",
    from: "Poonam Coatings Ahmedabad",
    to: "Poonam Coatings Mumbai",
    quantity: 5,
    uom: "ml",
    schedule_date: "2020-01-10",
    price: 500,
  },
  {
    id: 3,
    sku: "JS078",
    productName: "ACROLON 7301",
    from: "Poonam Coatings Ahmedabad",
    to: "Poonam Coatings Mumbai",
    quantity: 5,
    uom: "ml",
    schedule_date: "2020-01-10",
    price: 500,
  },
]

export const STOCK_MOVEMENT_DETAILS = [
  {
    id: 1,
    sku: "JS078",
    productName: "ACROLON 7300",
    from: "Poonam Coatings Ahmedabad",
    to: "Poonam Coatings Mumbai",
    quantity: 5,
    uom: "ltr",
    comment: "Rhoncus lacus in habitant...",
    schedule_date: "2020-01-10",
    price: 500,
  },
  {
    id: 2,
    sku: "JS078",
    productName: "ACROLON ERP 7300",
    from: "Poonam Coatings Ahmedabad",
    to: "Poonam Coatings Mumbai",
    quantity: 5,
    uom: "ml",
    comment: "Rhoncus lacus in habitant...",
    schedule_date: "2020-01-10",
    price: 500,
  },
  {
    id: 3,
    sku: "JS078",
    productName: "ACROLON 7301",
    from: "Poonam Coatings Ahmedabad",
    to: "Poonam Coatings Mumbai",
    quantity: 5,
    uom: "ml",
    comment: "Rhoncus lacus in habitant...",
    schedule_date: "2020-01-10",
    price: 500,
  },
]

export const BRAND = [
  {
    name: "Brand 1",
    id: "1",
    comments: "Comment 1",
  },
  {
    name: "Brand 2",
    id: "2",
    comments: "Comment 2",
  },
  {
    name: "Brand 3",
    id: "3",
    comments: "Comment 3",
  },
]
export const PRODUCTCATAGORY = [
  {
    name: "Paints",
    subCategory: "Interior Paints",
    subChildCategory: "Wall Paints",
    description: "High-quality paints for interior walls",
  },
  {
    name: "Paints",
    subCategory: "Interior Paints",
    subChildCategory: "Ceiling Paints",
    description: "Specially formulated paints for ceilings",
  },
  {
    name: "Paints",
    subCategory: "Exterior Paints",
    subChildCategory: "Masonry Paints",
    description: "Weather-resistant paints for masonry surfaces",
  },
  {
    name: "Paints",
    subCategory: "Exterior Paints",
    subChildCategory: "Wood Paints",
    description: "Durable paints for wooden surfaces",
  },
]

export const PAINT = [
  {
    id: "1",
    name: "Acrylic",
    comments: "Dries quickly",
  },
  {
    id: "2",
    name: "Oil",
    comments: "Long drying time",
  },
  {
    id: "3",
    name: "Watercolor",
    comments: "Transparent and vibrant",
  },
]
export const UOM = [
  {
    id: "1",
    UOM: "litre",
    description: "A unit of volume equal to 1,000 cubic centimeters.",
  },
  {
    id: "2",
    UOM: "ml",
    description: "A unit of volume equal to one thousandth of a litre.",
  },
  {
    id: "3",
    UOM: "gallon",
    description: "A unit of volume equal to 231 cubic inches or 3.785 liters.",
  },
]
export const FINISH = [
  {
    id: "1",
    name: "Matte",
    comments: "None",
  },
  {
    id: "2",
    name: "Glossy",
    comments: "Commonly used",
  },
  {
    id: "3",
    name: "Satin",
    comments: "Provides a smooth finish",
  },
]
export const COLORS = [
  {
    name: "Red",
    id: "1",
    ral: "RAL 3000",
    hex: "#FF0000",
    other: "example other here with string type",
  },
  {
    name: "Blue",
    id: "2",
    ral: "RAL 5002",
    hex: "#0000FF",
    other: "example other here with string type",
  },
  {
    name: "Green",
    id: "3",
    ral: "RAL 6018",
    hex: "#00FF00",
    other: "example other here with string type",
  },
  {
    name: "Yellow",
    id: "4",
    ral: "RAL 1021",
    hex: "#FFFF00",
    other: "example other here with string type",
  },
]

export const paymentList = [
  {
    Order_ID: "PO-007845",
    Company_name: "BMO Paint",
    Delivery_Status: "Pending",
    Payment_Status: "Pending",
    Payment_date: "13/03/24",
    Advance_Amount: "₹15,500",
    Amount_Paid: "₹15,500",
    Amount_to_pay: "₹1,500",
    Grand_Total: "₹15,500",
    Action_By: "John Jacob",
    Action_Date: "13/03/24, 12:00 AM",
    Payment_Mode: "banktransfer"
  },
  {
    Order_ID: "PO-007845",
    Company_name: "BMO Paint",
    Delivery_Status: "Pending",
    Payment_Status: "Pending",
    Payment_date: "13/03/24",
    Advance_Amount: "₹15,500",
    Amount_Paid: "₹15,500",
    Amount_to_pay: "₹1,500",
    Grand_Total: "₹15,500",
    Action_By: "John Jacob",
    Action_Date: "13/03/24, 12:00 AM",
    Payment_Mode: "cheque"
  },
  {
    Order_ID: "PO-007845",
    Company_name: "BMO Paint",
    Delivery_Status: "Pending",
    Payment_Status: "Pending",
    Payment_date: "13/03/24",
    Advance_Amount: "₹15,500",
    Amount_Paid: "₹15,500",
    Amount_to_pay: "₹1,500",
    Grand_Total: "₹15,500",
    Action_By: "John Jacob",
    Action_Date: "13/03/24, 12:00 AM",
    Payment_Mode: "upi"
  },
  {
    Order_ID: "PO-007845",
    Company_name: "BMO Paint",
    Delivery_Status: "Pending",
    Payment_Status: "Pending",
    Payment_date: "13/03/24",
    Advance_Amount: "₹15,500",
    Amount_Paid: "₹15,500",
    Amount_to_pay: "₹1,500",
    Grand_Total: "₹15,500",
    Action_By: "John Jacob",
    Action_Date: "13/03/24, 12:00 AM",
    Payment_Mode: "cash"
  },
]
