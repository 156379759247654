/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import Header from "../../../components/Header"
import Sidebar from "../../../components/Sidebar"
import Footer from "../../../components/Footer"
import MobileSidebar from "../../../components/MobileSidebar"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import { useFormik } from "formik"
import * as Yup from "yup"
import DropDownBox from "../../../components/DropDownBox"
import { toast } from "react-toastify"
import { handleAnimation } from "../../../helper/helper"
import Breadcrumb from "../../../components/BreadCrumbs"

const Calendar = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Calendar")
  }, [setTitle])

  const [active, setActive] = useState("calendar")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  //revenue data
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const containerRef = useRef(null)
  const [isEventCard, setIsEventCard] = useState(false)
  const [eventCardData, setEventCardData] = useState()

  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const [selectDate, setSelectedDate] = useState()
  const [calWindow, setCalWindow] = useState("calendar")
  const [reverseEvents, setReverseEvents] = useState([])
  const [currDay, setCurrDay] = useState(new Date())
  const [todaysEvents, setTodaysEvents] = useState()

  //for month data
  const [currentDay, setCurrentDay] = useState(new Date())

  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [currentDateWeek, setCurrentDateWeek] = useState(new Date())

  //calendar display logic
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  // const colorsObj = {
  //   most_importent: "#f65365",
  //   importent: "orange",
  //   regular: "#26ac3b",
  //   normal: "dodgerblue",
  // };
  const eventsData = [
    {
      id: "1",
      startDate: new Date(2024, 2, 11, 10, 0),
      endDate: new Date(2024, 2, 14, 12, 0),
      title: "Event 1",
      priority: { priority: "Regular", color: "#26ac3b" },
    },
    {
      id: "3",
      startDate: new Date(2024, 11, 1, 11, 30),
      endDate: new Date(2024, 11, 4, 12, 30),
      title: "Event 3",
      priority: { priority: "Normal", color: "dodgerblue" },
    },
    {
      id: "1a",
      startDate: new Date(2024, 2, 3, 10, 0),
      endDate: new Date(2024, 2, 4, 14, 0),
      title: "Event 1ss",
      priority: { priority: "Most Impotent", color: "#f65365" },
    },
    {
      id: "2",
      startDate: new Date(2024, 2, 28, 9, 0),
      endDate: new Date(2024, 2, 28, 11, 30),
      title: "Event 2",
      priority: { priority: "Impotent", color: "dodgerblue" },
    },
  ]
  const [events, setEvents] = useState(eventsData)
  const [currentDays, setCurrentDays] = useState([])

  const currentMonthCal = () => {
    const currentDays = []
    if (calWindow === "calendar") {
      const firstDayOfMonth = new Date(
        currentDay.getFullYear(),
        currentDay.getMonth(),
        1,
      )

      const weekdayOfFirstDay = firstDayOfMonth.getDay()
      //weekdayOfFirstDay of which day month start

      //currentDays is month days which looks brighter
      //

      for (let day = 0; day < 42; day++) {
        if (day === 0 && weekdayOfFirstDay === 0) {
          firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7)
        } else if (day === 0) {
          firstDayOfMonth.setDate(
            firstDayOfMonth.getDate() + (day - weekdayOfFirstDay),
          )
        } else {
          firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1)
        }

        let calendarDay = {
          currentMonth: firstDayOfMonth.getMonth() === currentDay.getMonth(),
          date: new Date(firstDayOfMonth),
          month: firstDayOfMonth.getMonth(),
          number: firstDayOfMonth.getDate(),
          selected:
            firstDayOfMonth.toDateString() === currentDay.toDateString(),
          year: firstDayOfMonth.getFullYear(),
        }

        currentDays.push(calendarDay)
      }
    }

    setCurrentDays(currentDays)
  }

  //for active

  //for weekly data
  const weekDaysAndTitle = () => {
    const daysInCurrentWeek = [...Array(7)].map((_, i) => {
      // const currentDate = new Date();
      const firstDayOfWeek = new Date(currentDateWeek)
      const currentDay = firstDayOfWeek.getDate() - firstDayOfWeek.getDay() + i
      firstDayOfWeek.setDate(currentDay)

      const dayName = new Intl.DateTimeFormat("en-US", {
        weekday: "short",
      }).format(firstDayOfWeek)
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        day: "numeric",
      }).format(firstDayOfWeek)

      return {
        dayName,
        formattedDate,
        date: firstDayOfWeek,
        isToday:
          firstDayOfWeek.toDateString() === currentDateWeek.toDateString(),
      }
    })
    setDaysInCurrentWeek(daysInCurrentWeek)
    const firstDate =
      daysInCurrentWeek.length > 0 ? daysInCurrentWeek[0]?.date : null
    const lastDate =
      daysInCurrentWeek.length > 0 ? daysInCurrentWeek[6]?.date : null

    const Weektitle =
      firstDate && lastDate
        ? `${firstDate.getDate()} ${firstDate.toLocaleString("default", {
            month: "long",
          })} - ${lastDate.getDate()} ${lastDate.toLocaleString("default", {
            month: "long",
          })}`
        : ""

    setWeektitle(Weektitle)
  }

  //end weekData

  const [daysInCurrentWeek, setDaysInCurrentWeek] = useState([])
  const [Weektitle, setWeektitle] = useState([])

  //for day data
  //

  const dayNameAndEvent = () => {
    const todaysEvents = events.filter(event => {
      const eventStartDate = event.startDate
      const eventEndDate = event.endDate
      const currentDate = new Date(
        currDay.getFullYear(),
        currDay.getMonth(),
        currDay.getDate(),
      )

      return (
        currentDate >=
          new Date(
            eventStartDate.getFullYear(),
            eventStartDate.getMonth(),
            eventStartDate.getDate(),
          ) &&
        currentDate <=
          new Date(
            eventEndDate.getFullYear(),
            eventEndDate.getMonth(),
            eventEndDate.getDate(),
          )
      )
    })
    setTodaysEvents(todaysEvents)
  }

  useEffect(() => {
    if (calWindow === "week") {
      weekDaysAndTitle()
    }

    if (calWindow === "day") {
      dayNameAndEvent()
    }
  }, [currentDay, calWindow, currentDateWeek, events, currDay])

  useEffect(() => {
    if (calWindow === "calendar") {
      currentMonthCal()
    }
  }, [currentDay, calWindow])

  useEffect(() => {
    weekDaysAndTitle()
    dayNameAndEvent()
  }, [])

  //end day data

  //for drag and drop data
  const dragItem = useRef()
  const dragOverItem = useRef()
  const dragStart = (e, eventId) => {
    dragItem.current = eventId
  }
  const dragEnter = (e, eventId) => {
    dragOverItem.current = eventId
  }

  const drop = (e, targetDay, halfHourIndex) => {
    e.preventDefault()
    const draggedEvent = events.find(event => event.id === dragItem.current)
    // const draggEventStartTime = parseInt(
    //   `${draggedEvent.startDate}`.slice(15, 18)
    // );

    if (halfHourIndex !== undefined) {
      const hours = Math.floor(halfHourIndex * 0.5)
      const minutes = (halfHourIndex % 2) * 30

      let newStartDate = new Date(targetDay.date)

      newStartDate.setHours(hours)
      newStartDate.setMinutes(minutes)

      const newStartTime = parseInt(`${newStartDate}`.slice(15, 18).trim())

      const durationInMinutes =
        draggedEvent.endDate.getTime() - draggedEvent.startDate.getTime()

      const newEndDate = new Date(newStartDate.getTime() + durationInMinutes)
      const newEndTime = parseInt(`${newEndDate}`.slice(15, 18).trim())

      // const handleExtraTime = (event) => {
      //   newEndDate.setHours(23);
      //   newEndDate.setMinutes(30);
      //   const tempTime = new Date(newEndDate.getTime() - durationInMinutes);
      //   newStartDate.setHours(tempTime.getHours());
      //   newStartDate.setMinutes(tempTime.getMinutes());
      //   newStartDate.setDate(targetDay.formattedDate);
      //   newEndDate.setDate(newEndDate.getDate() - 1);
      //   // newStartDate = new Date(newEndDate.getTime() - durationInMinutes);
      //   return {
      //     ...event,
      //     startDate: newStartDate,
      //     endDate: newEndDate,
      //   };
      // };

      const updatedEvents = events.map(event =>
        event.id === draggedEvent.id
          ? newStartTime <= newEndTime
            ? {
                ...event,
                startDate: newStartDate,
                endDate: newEndDate,
              }
            : event
          : event,
      )

      setEvents(updatedEvents)
    } else {
      let date1 = new Date(
        new Date(new Date(draggedEvent.startDate).toDateString()),
      )

      let date2 = new Date(new Date(new Date(targetDay.date).toDateString()))

      // Calculating the time difference
      // of two dates
      let Difference_In_Time = date2.getTime() - date1.getTime()

      // Calculating the no. of days between
      // two dates
      let differenceInDays = Math.round(Difference_In_Time / (1000 * 3600 * 24))

      // const differenceInDays =
      //   targetDay.number - draggedEvent.startDate.getDate();

      const newStartDate = new Date(draggedEvent.startDate)
      const newEndDate = new Date(draggedEvent.endDate)

      newStartDate.setDate(newStartDate.getDate() + differenceInDays)
      newEndDate.setDate(newEndDate.getDate() + differenceInDays)

      const updatedEvents = events.map(event =>
        event.id === draggedEvent.id
          ? {
              ...event,
              startDate: newStartDate,
              endDate: newEndDate,
            }
          : event,
      )

      setEvents(updatedEvents)
    }
  }

  const dropDay = (e, targetDay, halfHourIndex) => {
    e.preventDefault()

    const draggedEvent = events.find(event => event.id === dragItem.current)
    if (halfHourIndex !== undefined) {
      const hours = Math.floor(halfHourIndex * 0.5)
      const minutes = (halfHourIndex % 2) * 30

      const newStartDate = new Date(targetDay)
      newStartDate.setHours(hours)
      newStartDate.setMinutes(minutes)

      const newStartTime = parseInt(`${newStartDate}`.slice(15, 18).trim())

      const durationInMinutes =
        draggedEvent.endDate.getTime() - draggedEvent.startDate.getTime()
      const newEndDate = new Date(newStartDate.getTime() + durationInMinutes)
      const newEndTime = parseInt(`${newEndDate}`.slice(15, 18).trim())

      // const handleExtraTime = (event) => {
      //   newEndDate.setHours(23);
      //   newEndDate.setMinutes(30);
      //   const tempTime = new Date(newEndDate.getTime() - durationInMinutes);
      //   newStartDate.setHours(tempTime.getHours());
      //   newStartDate.setMinutes(tempTime.getMinutes());
      //   newStartDate.setDate(tempTime.getDate() - 1);
      //   // newEndDate.setDate(newEndDate.getDate() - 1);
      //   // newStartDate = new Date(newEndDate.getTime() - durationInMinutes);
      //   return {
      //     ...event,
      //     startDate: newStartDate,
      //     endDate: newEndDate,
      //   };
      // };

      const updatedEvents = events.map(event =>
        event.id === draggedEvent.id
          ? newStartTime <= newEndTime
            ? {
                ...event,
                startDate: newStartDate,
                endDate: newEndDate,
              }
            : event
          : event,
      )

      setEvents(updatedEvents)
    }
  }

  // const dropMultipleEvent = (e, targetDay) => {
  //
  //   e.preventDefault();

  //   const draggedEvent = events.find(
  //     (event) => event.title === dragItem.current
  //   );
  //   const differenceInMilliseconds =
  //     targetDay.date.getTime() - draggedEvent.startDate.getTime();
  //   const oneDayInMilliseconds = 1000 * 60 * 60 * 24; // milliseconds in a day

  //   const differenceInDays = Math.floor(
  //     differenceInMilliseconds / oneDayInMilliseconds
  //   );

  //   const newStartDate = new Date(draggedEvent.startDate);
  //   const newEndDate = new Date(draggedEvent.endDate);

  //   newStartDate.setDate(newStartDate.getDate() + differenceInDays);
  //   newEndDate.setDate(newEndDate.getDate() + differenceInDays);

  //   const updatedEvents = events.map((event) =>
  //     event.title === draggedEvent.title
  //       ? {
  //           ...event,
  //           startDate: newStartDate,
  //           endDate: newEndDate,
  //         }
  //       : event
  //   );

  //   setEvents(updatedEvents);
  //
  // };

  const handleNext = () => {
    if (calWindow === "calendar") {
      setCurrentDay(
        prevDate =>
          new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1),
      )
    } else if (calWindow === "week") {
      const prevWeekDate = new Date(currentDateWeek)
      prevWeekDate.setDate(prevWeekDate.getDate() + 7)
      setCurrentDateWeek(prevWeekDate)
    } else if (calWindow === "day") {
      setCurrDay(
        new Date(
          currDay.getFullYear(),
          currDay.getMonth(),
          currDay.getDate() + 1,
        ),
      )
    }
  }
  const handleBack = () => {
    if (calWindow === "calendar") {
      setCurrentDay(
        prevDate =>
          new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1),
      )
    } else if (calWindow === "week") {
      const nextWeekDate = new Date(currentDateWeek)
      nextWeekDate.setDate(nextWeekDate.getDate() - 7)
      setCurrentDateWeek(nextWeekDate)
    } else if (calWindow === "day") {
      setCurrDay(
        new Date(
          currDay.getFullYear(),
          currDay.getMonth(),
          currDay.getDate() - 1,
        ),
      )
    }
  }

  const handleToday = () => {
    if (calWindow === "calendar") {
      setCurrentDay(new Date())
    } else if (calWindow === "week") {
      setCurrentDateWeek(new Date())
    } else if (calWindow === "day") {
      setCurrDay(new Date())
    }
  }

  const handleDateClick = (month, date, number, year) => {
    setSelectedDate({ month, date: `${date}`, number, year })
    setIsOpenPopup(true)
  }

  const handleShowMore = (e, date) => {
    e.stopPropagation()
    setCalWindow("week")
    const prevWeekDate = new Date(date)
    prevWeekDate.setDate(prevWeekDate.getDate())
    setCurrentDateWeek(prevWeekDate)
  }

  useEffect(() => {
    let revArr = []
    for (let i = 0; i < events.length; i++) {
      revArr.unshift(events[i])
    }

    setReverseEvents(revArr)
  }, [events])

  const [addStyle, setAddStyle] = useState()
  let eventRef = useRef()

  const handleEventCardClick = (e, event, month, date, number, year) => {
    const dayNum = new Date(date).getDay()
    const left = e.target.getBoundingClientRect().left
    const top = e.target.getBoundingClientRect().top
    eventRef.current = e.target
    e.stopPropagation()
    setSelectedDate({ month, date: `${date}`, number, year })
    // setIsOpenPopup(true);
    setIsEventCard(true)
    setEventCardData({ ...event, left, top, dayNum })

    handleAnimation(setAddStyle, addStyle, setIsEventCard, isEventCard, 1000)
  }

  const handleEventDelete = id => {
    setIsEventCard(false)
    setAddStyle(false)
    let newList = events
    newList = newList.filter(item => item.id !== id)
    setEvents(newList)
    toast.success("Event deleted successfully")
    setEventCardData(null)
  }
  const handleEventEdit = () => {
    setAddStyle(false)
    setIsEventCard(false)
    setIsOpenPopup(true)
  }
  //

  useEffect(() => {
    if (!isEventCard && eventCardData) {
      // setEventCardData(null);
      eventRef.current = null
    }
  }, [isEventCard])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container calendar-home-container'>
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              active={active}
              MbSidebar={MbSidebar}
              setActive={setActive}
            />
          )}
          {/* {showChat && (
            <Chatbox showChat={showChat} setShowChat={setShowChat} />
          )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            onScroll={() => {
              if (eventCardData) {
                setEventCardData({
                  ...eventCardData,
                  top: eventRef?.current?.getBoundingClientRect()?.top,
                })
              }
            }}
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Calendar</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>
              <div className='main-calendar-container'>
                <div className='calendar-container'>
                  <div className='change-controls'>
                    <span onClick={handleBack}>Back</span>
                    <span onClick={handleToday}>Today</span>
                    <span onClick={handleNext}>Next</span>
                  </div>
                  <div className='header-date'>
                    {calWindow === "calendar" && (
                      <span>
                        {months[currentDay.getMonth()]}{" "}
                        {currentDay.getFullYear()}
                      </span>
                    )}
                    {calWindow === "week" && <span>{Weektitle}</span>}
                    {calWindow === "day" && (
                      <span>
                        {weekdays[currDay.getDay()]} {currDay.getDate()}{" "}
                        {months[currDay.getMonth()].slice(0, 3)}
                      </span>
                    )}
                  </div>

                  <div className='calendar-nav'>
                    <span
                      onClick={() => {
                        calWindow === "calendar" && setCurrentDay(new Date())
                        setCalWindow("calendar")
                      }}
                    >
                      Month
                    </span>
                    <span
                      onClick={() => {
                        calWindow === "week" && setCurrentDateWeek(new Date())
                        setCalWindow("week")
                      }}
                    >
                      Week
                    </span>
                    <span
                      onClick={() => {
                        calWindow === "day" && setCurrDay(new Date())
                        setCalWindow("day")
                      }}
                    >
                      Day
                    </span>
                    <span onClick={() => setCalWindow("agenda")}>Agenda</span>
                  </div>
                </div>
                <div
                  className='calender-main'
                  style={
                    calWindow === "calendar"
                      ? { border: " 1px solid #ddd", borderRight: "none" }
                      : { border: "none" }
                  }
                >
                  {calWindow === "calendar" && (
                    <div className='calWrapper'>
                      {calWindow === "calendar" && (
                        <div className='calendar'>
                          <div className='calendar-body'>
                            <div className='table-header'>
                              {weekdays?.map(weekday => (
                                <div className='weekday' key={weekday}>
                                  <span>{weekday}</span>
                                </div>
                              ))}
                            </div>
                            <div className='table-content'>
                              {currentDays?.map((day, index) => {
                                // const eventsOnDay = events.filter((event) => {
                                //   const eventStartDate = event.startDate;
                                //   const eventEndDate = event.endDate;
                                //   const currentDate = new Date(
                                //     day.year,
                                //     day.month,
                                //     day.number
                                //   );

                                //   return (
                                //     currentDate >=
                                //       new Date(
                                //         eventStartDate.getFullYear(),
                                //         eventStartDate.getMonth(),
                                //         eventStartDate.getDate()
                                //       ) &&
                                //     currentDate <=
                                //       new Date(
                                //         eventEndDate.getFullYear(),
                                //         eventEndDate.getMonth(),
                                //         eventEndDate.getDate()
                                //       )
                                //   );
                                // });

                                return (
                                  <div
                                    className={
                                      "calendar-day" +
                                      (day.currentMonth ? " current" : "") +
                                      (day.selected ? " todays-date" : "")
                                    }
                                    // style={{background:"#F8EDFF"}}
                                    key={index}
                                    // onClick={() =>
                                    //   handleDateClick(
                                    //     day.currentMonth,
                                    //     day.date,
                                    //     day.number,
                                    //     day.year
                                    //   )
                                    // }
                                    // key={day.date.toDateString()}
                                    // onDrop={(e) => drop(e, day)}
                                    // onDragOver={(e) => e.preventDefault()}
                                  >
                                    <p>{day.number}</p>
                                    {/* {eventsOnDay.length > 0 ? (
                                  <div className="events-container">
                                    {eventsOnDay
                                      .slice(0, 2)
                                      .map((event, index) => (
                                        <div
                                          key={index}
                                          className="event"
                                          onDragStart={(e) =>
                                            dragStart(e, event.title)
                                          }
                                          onDragEnter={(e) =>
                                            dragEnter(e, event.title)
                                          }
                                          draggable
                                        >
                                          {event.title}
                                        </div>
                                      ))}
                                    {eventsOnDay.length > 2 ? (
                                      <div
                                        className="event more-events"
                                        onClick={(e) =>
                                          handleShowMore(e, day.date)
                                        }
                                      >
                                        +{eventsOnDay.length - 2} more events
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null} */}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      )}{" "}
                      {calWindow === "calendar" && (
                        <div className='calendar second-calendar'>
                          <div className='calendar-body'>
                            <div className='table-header'>
                              {weekdays?.map(weekday => (
                                <div className='weekday' key={weekday}>
                                  <span></span>
                                </div>
                              ))}
                            </div>
                            <div className='table-content'>
                              {currentDays?.map(day => {
                                const eventsOnDay = events.filter(event => {
                                  const eventStartDate = event.startDate
                                  const eventEndDate = event.endDate
                                  const currentDate = new Date(
                                    day.year,
                                    day.month,
                                    day.number,
                                  )
                                  if (
                                    eventStartDate.getFullYear() ===
                                      currentDate.getFullYear() &&
                                    eventStartDate.getMonth() ===
                                      currentDate.getMonth() &&
                                    eventStartDate.getDate() === day.number
                                  ) {
                                    event["firstEvent"] = true
                                  } else {
                                    event["firstEvent"] = false
                                  }

                                  if (
                                    eventEndDate.getFullYear() ===
                                      currentDate.getFullYear() &&
                                    eventEndDate.getMonth() ===
                                      currentDate.getMonth() &&
                                    eventEndDate.getDate() ===
                                      currentDate.getDate()
                                  ) {
                                    event["lastEvent"] = true
                                  } else {
                                    event["lastEvent"] = false
                                  }
                                  return (
                                    currentDate >=
                                      new Date(
                                        eventStartDate.getFullYear(),
                                        eventStartDate.getMonth(),
                                        eventStartDate.getDate(),
                                      ) &&
                                    currentDate <=
                                      new Date(
                                        eventEndDate.getFullYear(),
                                        eventEndDate.getMonth(),
                                        eventEndDate.getDate(),
                                      )
                                  )
                                })

                                return (
                                  <div
                                    className={
                                      "calendar-day second-calendar-day" +
                                      (day.currentMonth ? " current" : "")
                                    }
                                    onClick={() => {
                                      if (isEventCard) {
                                        setIsEventCard(false)
                                        setEventCardData(null)
                                        setAddStyle(false)
                                      }
                                      handleDateClick(
                                        day.currentMonth,
                                        day.date,
                                        day.number,
                                        day.year,
                                      )
                                    }}
                                    key={day.date.toDateString()}
                                    onDrop={e => drop(e, day)}
                                    onDragOver={e => e.preventDefault()}
                                  >
                                    {/* <p></p> */}
                                    {eventsOnDay?.length > 0 ? (
                                      <div className='events-container'>
                                        {eventsOnDay
                                          .slice(0, 2)
                                          .map((event, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className={
                                                  "event" +
                                                  (event?.firstEvent
                                                    ? " firstCellStyle"
                                                    : "") +
                                                  (event?.lastEvent
                                                    ? " lastCellStyle"
                                                    : "")
                                                }
                                                onDragStart={e =>
                                                  dragStart(e, event.id)
                                                }
                                                onDragEnter={e =>
                                                  dragEnter(e, event.id)
                                                }
                                                draggable
                                                style={{
                                                  backgroundColor: `${event?.priority.color}`,
                                                  color: `${
                                                    event.firstEvent
                                                      ? "aliceblue"
                                                      : event?.priority.color
                                                  }`,
                                                }}
                                                onClick={e =>
                                                  handleEventCardClick(
                                                    e,
                                                    event,
                                                    day.currentMonth,
                                                    day.date,
                                                    day.number,
                                                    day.year,
                                                  )
                                                }
                                              >
                                                {event.title}
                                              </div>
                                            )
                                          })}
                                        {eventsOnDay?.length > 2 ? (
                                          <div
                                            className=' more-events'
                                            onClick={e =>
                                              handleShowMore(e, day.date)
                                            }
                                          >
                                            +{eventsOnDay.length - 2} more
                                            events
                                          </div>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {calWindow === "week" && (
                    <div className='week'>
                      <table className='week-table'>
                        <thead>
                          <tr>
                            <th className='week-header'></th>
                            {daysInCurrentWeek?.map((day, index) => (
                              <th
                                key={index}
                                className={`week-header ${
                                  day.isToday ? "todays-date" : ""
                                }`}
                              >
                                {day.formattedDate} {day.dayName}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {[...Array(48)].map((_, halfHourIndex) => {
                            const baseTime = 0.5 // For half-hour intervals
                            const currentHour = baseTime * halfHourIndex
                            const hours = Math.floor(currentHour)
                            const minutes = (currentHour % 1) * 60
                            const currentTime = `${hours % 12 || 12}:${
                              minutes === 0 ? "00" : minutes
                            } ${hours >= 12 ? "PM" : "AM"}`

                            return (
                              <tr key={halfHourIndex}>
                                <th
                                  className='week-header'
                                  style={{
                                    visibility:
                                      halfHourIndex % 2 !== 0
                                        ? "hidden"
                                        : "visible",
                                  }}
                                >
                                  {currentTime}
                                </th>
                                {daysInCurrentWeek?.map((day, dayIndex) => {
                                  const currentMinuteTime = hours * 60 + minutes

                                  const dayEvents = events.filter(event => {
                                    const eventStart = event.startDate
                                    const eventEnd = event.endDate
                                    const currentDay = new Date(
                                      day.date.getFullYear(),
                                      day.date.getMonth(),
                                      day.date.getDate(),
                                    )
                                    // const isSameDay =
                                    //   eventStart.getFullYear() ===
                                    //     day.date.getFullYear() &&
                                    //   eventStart.getMonth() ===
                                    //     day.date.getMonth() &&
                                    //   eventStart.getDate() ===
                                    //     day.date.getDate();

                                    const eventStartTime =
                                      eventStart.getHours() * 60 +
                                      eventStart.getMinutes()
                                    const eventEndTime =
                                      eventEnd.getHours() * 60 +
                                      eventEnd.getMinutes()

                                    return (
                                      currentDay >=
                                        new Date(
                                          eventStart.getFullYear(),
                                          eventStart.getMonth(),
                                          eventStart.getDate(),
                                        ) &&
                                      currentDay <=
                                        new Date(
                                          eventEnd.getFullYear(),
                                          eventEnd.getMonth(),
                                          eventEnd.getDate(),
                                        ) &&
                                      eventStartTime <= currentMinuteTime &&
                                      eventEndTime >= currentMinuteTime
                                    )
                                  })

                                  return (
                                    <td
                                      key={dayIndex}
                                      onDrop={e => drop(e, day, halfHourIndex)}
                                      onDragOver={e => e.preventDefault()}
                                      className={
                                        day.isToday
                                          ? "week-data todays-date"
                                          : "week-data"
                                      }
                                    >
                                      {dayEvents?.map(event => (
                                        <div
                                          key={event.id}
                                          className='event'
                                          onDragStart={e =>
                                            dragStart(e, event.id)
                                          }
                                          onDragEnter={e =>
                                            dragEnter(e, event.id)
                                          }
                                          draggable
                                        >
                                          {`${event.title}`}
                                        </div>
                                      ))}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {calWindow === "day" && (
                    <div className='week'>
                      <table className='week-table'>
                        {/* <thead>
                        <tr>
                          <th
                            className="day-header"
                            style={{ width: "10rem" }}
                          ></th>
                          <th className="day-header"></th>
                        </tr>
                      </thead> */}
                        <tbody>
                          {[...Array(48)].map((_, halfHourIndex) => {
                            const baseTime = 0.5
                            const currentHour = baseTime * halfHourIndex
                            const hours = Math.floor(currentHour)
                            const minutes = (currentHour % 1) * 60
                            const currentTime = `${hours % 12 || 12}:${
                              minutes === 0 ? "00" : minutes
                            } ${hours >= 12 ? "PM" : "AM"}`

                            return (
                              <tr key={halfHourIndex}>
                                <th
                                  className='day-header'
                                  style={{
                                    visibility:
                                      halfHourIndex % 2 !== 0
                                        ? "hidden"
                                        : "visible",
                                  }}
                                >
                                  {currentTime}
                                </th>

                                <td
                                  className={
                                    `${currDay}`.slice(0, 15) ===
                                    `${new Date()}`.slice(0, 15)
                                      ? "todays-date"
                                      : "day-data"
                                  }
                                  onDrop={e =>
                                    dropDay(e, currDay, halfHourIndex)
                                  }
                                  onDragOver={e => e.preventDefault()}
                                >
                                  {todaysEvents?.map(event => {
                                    const startHour = event.startDate.getHours()
                                    const startMinute =
                                      event.startDate.getMinutes()
                                    const endHour = event.endDate.getHours()
                                    const endMinute = event.endDate.getMinutes()

                                    const eventStartsNow =
                                      startHour < hours ||
                                      (startHour === hours &&
                                        startMinute <= minutes)
                                    const eventEndsNow =
                                      endHour > hours ||
                                      (endHour === hours &&
                                        endMinute >= minutes)

                                    if (eventStartsNow && eventEndsNow) {
                                      return (
                                        <div
                                          key={event.id}
                                          className='event'
                                          onDragStart={e =>
                                            dragStart(e, event.id)
                                          }
                                          onDragEnter={e =>
                                            dragEnter(e, event.id)
                                          }
                                          draggable
                                        >
                                          {`${event.title} (${startHour}:${
                                            startMinute < 10
                                              ? "0" + startMinute
                                              : startMinute
                                          } - ${endHour}:${
                                            endMinute < 10
                                              ? "0" + endMinute
                                              : endMinute
                                          })`}
                                        </div>
                                      )
                                    }
                                    return null
                                  })}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {calWindow === "agenda" && (
                    <div className='agenda-main'>
                      <table className='agenda-table'>
                        <thead>
                          <tr>
                            <th className='ag-header'>Date</th>
                            <th className='ag-header'>Time</th>
                            <th className='ag-header'>Event</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reverseEvents?.map((item, index) => {
                            const { endDate, startDate, title } = item
                            let startTime = `${startDate}`
                              .slice(16, 21)
                              .split(":")

                            let meridiemTime1 =
                              (startTime[0] >= 12 &&
                                (startTime[0] - 12 || 12) +
                                  ":" +
                                  startTime[1] +
                                  " PM") ||
                              (Number(startTime[0]) || 12) +
                                ":" +
                                startTime[1] +
                                " AM"

                            let endTime = `${endDate}`.slice(16, 21).split(":")

                            let meridiemTime2 =
                              (endTime[0] >= 12 &&
                                (endTime[0] - 12 || 12) +
                                  ":" +
                                  endTime[1] +
                                  " PM") ||
                              (Number(endTime[0]) || 12) +
                                ":" +
                                endTime[1] +
                                " AM"
                            return (
                              <tr key={index}>
                                <td className='ag-data'>
                                  {`${startDate}`.slice(0, 11)}
                                </td>
                                <td className='ag-data'>
                                  {meridiemTime1} - {meridiemTime2}{" "}
                                </td>
                                <td className='ag-data'>{title}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='main-footer'>
              <Footer />
            </div>
          </div>
          {isOpenPopup && (
            <CalendarForm
              setIsOpenPopup={setIsOpenPopup}
              isOpenPopup={isOpenPopup}
              events={events}
              setEvents={setEvents}
              selectDate={selectDate}
              eventCardData={eventCardData}
              setEventCardData={setEventCardData}
            />
          )}
          {isEventCard && (
            <div
              className={
                "event-card" +
                (addStyle
                  ? `${
                      eventCardData.dayNum <= 3
                        ? " eventCardSlideInAni"
                        : " eventLeftCardSlideInAni "
                    }`
                  : ` ${
                      eventCardData.dayNum <= 3
                        ? " eventCardSlideOutAni"
                        : " eventLeftCardSlideOutAni"
                    }`)
              }
              style={{
                top: `${eventCardData.top + 30}px`,
                left: `${
                  eventCardData.dayNum > 3
                    ? eventCardData.left - 400
                    : eventCardData.left + 15
                }px`,
              }}
            >
              <div className='tool-icons'>
                <span
                  className='material-symbols-outlined tl-icons'
                  onClick={handleEventEdit}
                >
                  edit
                </span>
                <span
                  className='material-symbols-outlined tl-icons'
                  onClick={() => handleEventDelete(eventCardData.id)}
                >
                  delete
                </span>
                <span
                  className='material-symbols-outlined tl-icons'
                  onClick={async () => {
                    // setIsEventCard(false);
                    await handleAnimation(
                      setAddStyle,
                      addStyle,
                      setIsEventCard,
                      isEventCard,
                      300,
                    )

                    setTimeout(() => {
                      setEventCardData(null)
                    }, 300)
                  }}
                >
                  close
                </span>
              </div>
              <div className='event-details'>
                <div>
                  <p>
                    {" "}
                    <span>Event Detail:</span> {eventCardData.title}
                  </p>
                  <p>
                    <span>Start Date:</span>{" "}
                    <span>
                      {`${new Date(eventCardData?.startDate)}`
                        .slice(0, 10)
                        .trim()}
                    </span>
                  </p>{" "}
                  {eventCardData.location && (
                    <p>
                      <span>Location:</span>{" "}
                      <span>{eventCardData?.location}</span>
                    </p>
                  )}{" "}
                </div>

                <p>
                  <span>Priority Level:</span>{" "}
                  <span>{eventCardData?.priority?.priority}</span>
                </p>
              </div>
            </div>
          )}{" "}
        </div>
      </div>
    </div>
  )
}

export default Calendar

const CalendarForm = ({
  setIsOpenPopup,
  isOpenPopup,
  events,
  setEvents,
  selectDate,
  eventCardData,
  setEventCardData,
}) => {
  const calendarSchema = Yup.object({
    task: Yup.string()
      .trim()
      .required()
      .max(95, "Event title must be less than 95 characters"),
  })
  const [priority, setPriority] = useState("")
  const [defaultValue, setDefaultValue] = useState("Select Priority Level")
  const { month, date, year, number } = selectDate
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const colorsObj = {
    high: ["High", "#f65365"],
    medium: ["Medium", "orange"],
    low: ["Low", "dodgerblue"],
  }

  const formik = useFormik({
    initialValues: {
      task: "",
      startDate: "",
      endDate: "",
      location: "",
    },
    validationSchema: calendarSchema,
    onSubmit: (values, { resetForm }) => {
      if (eventCardData) {
        events.forEach(element => {
          const { id } = element
          if (id === eventCardData.id) {
            // (id = `${Math.random() * 500000 + 1}`),
            element.startDate = new Date(
              `${values.startDate}`.slice(0, 4),
              parseInt(`${values.startDate}`.slice(5, 7)) - 1,
              `${values.startDate}`.slice(8, 10),
              `${values.startDate}`.slice(11, 13),
              `${values.startDate}`.slice(14, 16),
            )
            element.endDate = new Date(
              `${values.endDate}`.slice(0, 4),
              parseInt(`${values.endDate}`.slice(5, 7)) - 1,
              `${values.endDate}`.slice(8, 10),
              `${values.endDate}`.slice(11, 13),
              `${values.endDate}`.slice(14, 16),
            )
            element.title = values.task
            element.priority = priority
              ? {
                  priority: colorsObj[priority][0],
                  color: colorsObj[priority][1],
                }
              : {
                  priority: element.priority.priority,
                  color: element.priority.color,
                }
            element.location = values.location
          }
        })
        setEvents(events)
      } else {
        setEvents([
          ...events,
          {
            id: `${Math.random() * 500000 + 1}`,
            startDate: new Date(
              `${values.startDate}`.slice(0, 4),
              parseInt(`${values.startDate}`.slice(5, 7)) - 1,
              `${values.startDate}`.slice(8, 10),
              `${values.startDate}`.slice(11, 13),
              `${values.startDate}`.slice(14, 16),
            ),
            endDate: new Date(
              `${values.endDate}`.slice(0, 4),
              parseInt(`${values.endDate}`.slice(5, 7)) - 1,
              `${values.endDate}`.slice(8, 10),
              `${values.endDate}`.slice(11, 13),
              `${values.endDate}`.slice(14, 16),
            ),
            title: values.task,
            //   color:colorsObj[priority]
            priority: priority
              ? {
                  priority: colorsObj[priority][0],
                  color: colorsObj[priority][1],
                }
              : { priority: "Low", color: "dodgerblue" },
            location: values.location,
          },
        ])
      }

      setEventCardData(null)
      handleClick()
    },
  })

  useEffect(() => {
    if (eventCardData) {
      formik.setFieldValue("task", eventCardData.title)
      formik.setFieldValue("location", eventCardData.location)
      formik.setFieldValue(
        "startDate",
        new Date(eventCardData.startDate).toISOString().slice(0, 16),
      )
      formik.setFieldValue(
        "endDate",
        new Date(eventCardData.endDate).toISOString().slice(0, 16),
      )
      setDefaultValue(eventCardData.priority.priority)
    } else {
      let calMonth = months.indexOf(`${date}`.slice(4, 7)) + 1
      calMonth = calMonth < 10 ? "0" + calMonth : calMonth

      formik.setFieldValue(
        "startDate",
        `${year}-${calMonth}-${date.slice(8, 10)}T${
          new Date().getHours("en-US", {
            hour12: false,
          }) < 10
            ? "0" +
              new Date().getHours("en-US", {
                hour12: false,
              })
            : new Date().getHours("en-US", {
                hour12: false,
              })
        }:${
          new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes()
        }
  
   `.trim(),
      )

      //     formik.setFieldValue(
      //       "endDate",
      //       `${year}-${calMonth}-${date.slice(8, 10)}T${
      //         new Date().getHours("en-US", {
      //           hour12: false,
      //         }) < 10
      //           ? "0" +
      //             (new Date().getHours("en-US", {
      //               hour12: false,
      //             }) +
      //               1)
      //           : new Date().getHours("en-US", {
      //               hour12: false,
      //             }) == 23
      //           ? "0"
      //           : new Date().getHours("en-US", {
      //               hour12: false,
      //             }) + 1
      //       }:${
      //         new Date().getMinutes() < 10
      //           ? "0" + new Date().getMinutes()
      //           : new Date().getMinutes()
      //       }
      //  `.trim()
      //     );

      formik.setFieldValue(
        "endDate",
        `${year}-${calMonth}-${date.slice(8, 10)}T${
          new Date().getHours("en-US", {
            hour12: false,
          }) < 9
            ? "0" +
              (new Date().getHours("en-US", {
                hour12: false,
              }) +
                1)
            : new Date().getHours("en-US", {
                  hour12: false,
                  // eslint-disable-next-line
                }) == 23
              ? "0"
              : new Date().getHours("en-US", {
                  hour12: false,
                }) + 1
        }:${
          new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes()
        }
 `.trim(),
      )
    }
  }, [])

  const stopPropagation = e => {
    e.stopPropagation()
  }
  const [addStyle, setAddStyle] = useState(true)

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
  }

  function chatBoxVisibility() {
    if (isOpenPopup) {
      const styleTimer = setTimeout(() => {
        setIsOpenPopup(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  return (
    <div
      className={
        "form-container" +
        (addStyle
          ? " show_component-common-animation"
          : " hide_component-common-animation")
      }
      onClick={() => {
        setEventCardData(null)
        handleClick()
      }}
    >
      <div
        className={"form-box"}
        onClick={e => {
          stopPropagation(e)
        }}
      >
        <span className='form-heading'>
          {eventCardData ? "Edit Event" : "Add Event"}
        </span>
        <form className='calendar-form' onSubmit={formik.handleSubmit}>
          <div className='form-control'>
            <label htmlFor='task'>Title</label>
            <input
              type='text'
              name='task'
              className='calendar-input'
              value={formik.values.task}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder='title here...'
              maxLength={96}
            />
            {formik.errors.task && formik.touched.task ? (
              <span className='err'>{formik.errors.task}</span>
            ) : null}
          </div>

          <div className='form-control'>
            <label htmlFor='startDate'>Start Date</label>
            <input
              type='datetime-local'
              name='startDate'
              className='calendar-input'
              value={formik.values.startDate}
              onChange={formik.handleChange}
              // readOnly
            />
          </div>

          <div className='form-control'>
            <label htmlFor='endDate'>End Date</label>
            <input
              type='datetime-local'
              name='endDate'
              className='calendar-input'
              value={formik.values.endDate}
              onChange={formik.handleChange}
            />
          </div>

          <div className='form-control'>
            <label htmlFor='location'>Location</label>
            <input
              type='text'
              name='location'
              placeholder='Add Location'
              className='calendar-input'
              value={formik.values.location}
              onChange={formik.handleChange}
            />
          </div>

          <DropDownBox
            dropDownTitle='Priority Level'
            options={[
              { label: "High", value: "high" },
              { label: "Medium", value: "medium" },
              { label: "Low", value: "low" },
            ]}
            defaultValue={defaultValue}
            customSetter={setPriority}
            customDropBoxStyles={{
              color: "#333",
              padding: ".5rem",
            }}
          />

          <div className='form-btns'>
            <button
              type='button'
              onClick={() => {
                handleClick()
              }}
            >
              Cancel
            </button>{" "}
            <button type='submit'>Submit</button>
          </div>
        </form>
      </div>
    </div>
  )
}
