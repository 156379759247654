import Buyers_Suppliers from "../../modules/buyers&suppliers/pages/Buyers_Suppliers"
import CompanyDetails from "../../modules/buyers&suppliers/pages/CompanyDetails"
import Calendar from "../../modules/calendar/pages/Calendar"
import ProductCatalogue from "../../modules/catalogue/pages/ProductCatalogue"
import Company from "../../modules/company/pages/Company"
import Dashboard from "../../modules/dashboard/pages/Dashboard"
import Department from "../../modules/department/pages/Department"
import AddEmail from "../../modules/email/pages/AddEmail"
import EditEmail from "../../modules/email/pages/EditEmail"
import Email from "../../modules/email/pages/Email"
import Inventory from "../../modules/inventory/pages/Inventory"
import InventoryDetails from "../../modules/inventory/pages/InventoryDetails"
import StockDetails from "../../modules/inventory/pages/StockDetails"
import StockMovement from "../../modules/inventory/pages/StockMovement"
import ProductDetails from "../../modules/products/pages/productDetails"
import Products from "../../modules/products/pages/products"
import Profile from "../../modules/profile/pages/Profile"
import PreviewPurchase from "../../modules/purchase/components/PreviewPurchase"
import Purchase from "../../modules/purchase/pages/Purchase"
import PurchaseDetails from "../../modules/purchase/pages/PurchaseDetails"
import StockAlert from "../../modules/purchase/pages/StockAlert"
import AddNew from "../../modules/user/pages/AddNew"
import UpdateUser from "../../modules/user/pages/UpdateUser"
import Users from "../../modules/user/pages/Users"
import Approval from "../../modules/approvals/pages/Approval"
import SalesQuotation from "../../modules/sales/pages/SalesQuotation"
import Sales from "../../modules/sales/pages/Sales"
import SalesDetails from "../../modules/sales/pages/SalesDetails"
import ApprovalPage from "../../modules/approvals/components/approvalPage"
import SalesPreview from "../../modules/sales/pages/SalesPreivew"
import Order from "../../modules/order/pages/SalesOrder"
import PurchaseOrder from "../../modules/order/pages/PurchaseOrder"
import Payment from "../../modules/payments/pages/Payment"

export const MainRoutes = [
  {
    path: "/",
    component: Dashboard,
    position: "head",
  },
  {
    path: "/profile",
    component: Profile,
    position: "head",
  },
  {
    path: "/company",
    component: Company,
    position: "parent",
    privilegeTag: "company_management",
  },
  {
    path: "/calendar",
    component: Calendar,
    position: "parent",
    privilegeTag: "calendar_management",
  },

  {
    path: "/departments",
    component: Department,
    position: "parent",
    privilegeTag: "department_management",
  },

  {
    path: "/user",
    component: Users,
    position: "parent",
    privilegeTag: "user_management",
  },
  {
    path: "/user/:id",
    component: UpdateUser,
    position: "head",
    privilegeTag: "user_management",
  },
  {
    path: "/user/add-user",
    component: AddNew,
    position: "child",
    privilegeTag: "user_management",
  },
  {
    path: "/email",
    component: Email,
    position: "parent",
    privilegeTag: "email_management",
  },
  {
    path: "/email/add-email",
    component: AddEmail,
    position: "child",
    privilegeTag: "email_management",
  },
  {
    path: "email/edit-email/:id",
    component: EditEmail,
    position: "child",
    privilegeTag: "email_management",
  },
  {
    path: "/buyers-and-suppliers",
    component: Buyers_Suppliers,
    position: "parent",
    privilegeTag: "buyers_and_suppliers_management",
  },
  {
    path: "/buyers-and-suppliers/:id",
    component: CompanyDetails,
    position: "child",
    privilegeTag: "buyers_and_suppliers_management",
  },
  {
    path: "/products",
    component: Products,
    position: "parent",
    privilegeTag: "production_management",
  },
  {
    path: "/products/:id",
    component: ProductDetails,
    position: "child",
    privilegeTag: "production_management",
  },
  {
    path: "/inventory",
    component: Inventory,
    position: "parent",
    privilegeTag: "inventory_management",
  },
  {
    path: "/inventory/:id",
    component: InventoryDetails,
    position: "child",
    privilegeTag: "inventory_management",
  },
  {
    path: "/stock-movement",
    component: StockMovement,
    position: "parent",
    privilegeTag: "inventory_management",
  },
  {
    path: "/stock-movement/:id",
    component: StockDetails,
    position: "child",
    privilegeTag: "inventory_management",
  },
  {
    path: "/product-catalogue",
    component: ProductCatalogue,
    position: "parent",
    privilegeTag: "catalogue_management",
  },
  {
    path: "/stock-alert",
    component: StockAlert,
    position: "parent",
    privilegeTag: "purchase_management",
  },
  {
    path: "/purchase/:slug",
    component: PurchaseDetails,
    position: "child",
    privilegeTag: "purchase_management",
  },
  {
    path: "/purchase/:slug/preview",
    component: PreviewPurchase,
    position: "child",
    privilegeTag: "purchase_management",
  },

  {
    path: "/purchase",
    component: Purchase,
    position: "parent",
    privilegeTag: "purchase_management",
  },
  {
    path: "/approvals",
    component: Approval,
    position: "parent",
    privilegeTag: "purchase_management",
  },
  {
    path: "/approvals/approval-details",
    component: ApprovalPage,
    position: "child",
    privilegeTag: "purchase_management",
  },
  {
    path: "/sales/sales-order",
    component: SalesDetails,
    position: "child",
    privilegeTag: "sales_management",
  },
  {
    // path: "/sales/sales-return",
    path: "/sales/:slug",
    component: SalesDetails,
    position: "child",
    privilegeTag: "sales_management",
  },
  {
    // path: "/sales/sales-return",
    path: "/sales/:slug/preview",
    component: SalesPreview,
    position: "child",
    privilegeTag: "sales_management",
  },
  {
    path: "/sales",
    component: Sales,
    position: "parent",
    privilegeTag: "sales_management",
  },
  {
    path: "/sales-quotation",
    component: SalesQuotation,
    position: "parent",
    privilegeTag: "sales_management",
  },
  {
    path: "/sales-quotation/:slug",
    component: SalesDetails,
    position: "child",
    privilegeTag: "sales_management",
  },
  {
    path: "/sales-quotation/:slug/preview",
    component: SalesPreview,
    position: "child",
    privilegeTag: "sales_management",
  },
  {
    path: "/order/sales",
    component: Order,
    position: "parent",
    privilegeTag: "order_management",
  },
  {
    path: "/order/purchase",
    component: PurchaseOrder,
    position: "parent",
    privilegeTag: "order_management",
  },
  {
    path: "/payment",
    component: Payment,
    position: "parent",
    privilegeTag: "order_management",
  },
]
