import { createSlice } from "@reduxjs/toolkit"
import { getAllDepartments, getDepartmentList } from "./DepartmentApi"

const departmentSlice = createSlice({
  name: "department",
  initialState: {
    departmentLoading: false,
    departmentDetails: [],
    departmentList: [],
    departments: [],
    metaData: [],
  },

  reducers: {
    isDepartmentToggle: (state, { payload }) => {
      state.departmentLoading = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getDepartmentList.pending, (state, action) => {
      state.departmentLoading = true
    })
    builder.addCase(getDepartmentList.fulfilled, (state, action) => {
      state.departmentLoading = false
      state.departmentList = action?.payload?.data
      state.metaData = action?.payload?.metadata[0]
    })
    builder.addCase(getDepartmentList.rejected, (state, action) => {
      state.departmentLoading = false
    })
    // fetch departments
    builder.addCase(getAllDepartments.pending, (state, action) => {
      state.departmentLoading = true
    })
    builder.addCase(getAllDepartments.fulfilled, (state, action) => {
      state.departmentLoading = false
      state.departments = action?.payload?.data
      // state.metaData = action?.payload?.data[0].metadata[0];
    })
    builder.addCase(getAllDepartments.rejected, (state, action) => {
      state.departmentLoading = false
    })
  },
})

export const { isDepartmentToggle } = departmentSlice.actions
export default departmentSlice.reducer
