/* eslint-disable no-unused-vars */
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
// import { addDepartment } from "../redux/DepartmentApi";
import FormButtons from "../../../components/SubmitBtns"
// import { addDepartment } from "../redux/DepartmentApi"

const RejectPopup = ({ customSetter, customState }) => {
  const [addStyle, setAddStyle] = useState(true)
  const dispatch = useDispatch()
  const [focusedField, setFocusedField] = useState()
  const { departmentLoading } = useSelector(store => store.department)

  const userDetailsSchema = Yup.object({
    description: Yup.string()
      .trim()
      .max(150, "words must be less than 150 words")
      .required("Description field is required"),
  })

  const formik = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: userDetailsSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = { name: values.name, description: values.description }
      //   const response = await dispatch(addDepartment(data))
      //   if (response.payload) {
      //     handleClick()
      //   }
    },
  })

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (customState) {
      const styleTimer = setTimeout(() => {
        customSetter(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  return (
    <div
      id='delete-card-container'
      className={`${
        addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation"
      }`}
    >
      <div
        className={
          "event-card" +
          (addStyle
            ? " show_delete-component-animation"
            : " hide_delete-component-animation")
        }
      >
        <h2>Reason to Reject</h2>

        <div className='modelEx_content'>
          <form
            className='editbox-form flex-center'
            onSubmit={formik.handleSubmit}
          >
            {/* <div className="form-group">
              <div
                className={`input-field ${
                  formik.values.email !== "" || focusedField === "email"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor="email" className="toggle-label">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  className="form-input"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  maxLength={80}
                  onBlur={(e) => {
                    setFocusedField("");
                    formik.handleBlur(e);
                  }}
                  onFocus={() => setFocusedField("email")}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.touched.email && formik.errors.email ? (
                  <span className="err">{formik.errors.email}</span>
                ) : null}
              </div>
            </div> */}
            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.description !== "" || focusedField === "aboutUs"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='description' className='toggle-label'>
                  Reason to Reject
                </label>
                <textarea
                  id='description'
                  name='description'
                  rows={4}
                  className={"form-input"}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  maxLength={151}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("aboutUs")}
                />
                {formik.touched.description && formik.errors.description ? (
                  <span className='err mt-2'>{formik.errors.description}</span>
                ) : null}
              </div>
            </div>
            {/* <div className='addPopup-btn-box'>
              <button
                type='button'
                className='btn-secondary'
                onClick={handleClick}
              >
                Abort
              </button>
              <button
                className='btn-primary'
                type='submit'
                style={
                  departmentLoading
                    ? {
                        cursor: "not-allowed",
                        // background: "gray",
                      }
                    : {}
                }
                disabled={departmentLoading}
              >
                {departmentLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div> */}
            <FormButtons
              loading={departmentLoading}
              abortHandleClick={handleClick}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default RejectPopup
