import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const ConformationPopup = ({
  customSetter,
  customState,
  api,
  mainTitle,
  subTitle,
  popUpData,
  reFetchApi,
  reData = {},
  navigateTo, // path to navigate
  customFunction = false,
  popupType = "delete",
  toastMessage,
}) => {
  const [addStyle, setAddStyle] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const title = popUpData?.title
  const data = popUpData?.data
  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (customState) {
      const styleTimer = setTimeout(() => {
        customSetter(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])
  const handleDeletion = async () => {
    if (api) {
      const { payload } = await dispatch(api(data))

      if (payload) {
        toast.success(
          `${toastMessage ? toastMessage : title + " deleted successfully"}`,
        )
        if (reFetchApi) {
          dispatch(reFetchApi(reData))
        }
        if (navigateTo) {
          navigate(`${navigateTo}`)
        }
        if (customFunction) {
          customFunction()
        }
      }
    }
  }

  return (
    <div
      id='delete-card-container'
      className={`${
        addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation"
      }`}
    >
      <div
        className={
          "event-card" +
          (addStyle
            ? " show_delete-component-fadeIn-animation"
            : " hide_delete-component-fadeOut-animation")
        }
      >
        <span
          className='material-symbols-outlined con_delete'
          style={
            popupType !== "delete"
              ? { color: "rgb(48, 133, 214)" }
              : { color: "red" }
          }
        >
          {popupType !== "delete" ? popupType : "delete"}
        </span>

        <div className='del-message'>
          <span>
            {mainTitle
              ? mainTitle
              : title
                ? `Are you sure about deleting ${title + "?"}`
                : "Are you sure?"}
          </span>
          <span>
            {subTitle ? (
              <>
                <i className='fa-regular fa-triangle-exclamation'></i>{" "}
                {subTitle}
              </>
            ) : (
              <>
                <i className='fa-regular fa-triangle-exclamation'></i> You won't
                be able to revert this!
              </>
            )}
          </span>
        </div>

        <div className='del_ben-container'>
          <button className='del-cancel' onClick={handleClick}>
            Cancel
          </button>
          <button
            className='del-conform'
            onClick={() => {
              handleDeletion()
              handleClick()
            }}
          >
            {popupType !== "delete" ? "Submit" : "Delete"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConformationPopup
