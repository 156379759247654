/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import { ClipLoader } from "react-spinners"
import { updateInventory } from "../redux/InventoryApi"
import FormButtons from "../../../components/SubmitBtns"
import { getOptionsList } from "../../products/redux/ProductsApi"
import DropDownBox from "../../../components/DropDownBox"
import {
  capitalizeInput,
  filterNumbersRegex,
  focusOnElement,
} from "../../../helper/helper"

const UpdateInventory = ({ customSetter, customState, popupData }) => {
  const [addStyle, setAddStyle] = useState(true)
  const [focusedField, setFocusedField] = useState()
  const { inventoryLoading } = useSelector(store => store.inventory)
  const [activeInputs, setActiveInputs] = useState(false)
  const [litreId, setLitreId] = useState("")
  const dispatch = useDispatch()
  const userDetailsSchema = Yup.object({
    batchNumber:
      popupData?.manufacture !== "self"
        ? Yup.string().required("Batch number is required")
        : Yup.string(),
    numberOfunits: Yup.string().required("Number of units are required"),
    uom: Yup.string().required("UOM is required"),
    msl: Yup.string().required("Minimum stock level is required"),
  })

  const formik = useFormik({
    initialValues: {
      batchNumber: "",
      numberOfunits: "",
      uom: "",
      msl: "",
    },
    validationSchema: userDetailsSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        // batchNo: popupData.product_id,
        // packSize: popupData.address_id,
        // packQuantity: values.quantity,
        // pack: values.minimumQuantity,
        // quantityToReduce: values.remove ? values.remove : "0",
        // totalAmount: values.add ? values.add : "0",
        product_id: popupData.product_id,
        location_id: popupData.address_id,
        operationType: !activeInputs ? "Add" : "Remove",
        msl: values.msl,
        numberOfunits: values.numberOfunits,
        uom: values.uom,
        batchNumber: values.batchNumber,
      }
      const response = await dispatch(updateInventory(data))
      if (response.payload) {
        handleClick()
      }
    },
  })

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (customState) {
      const styleTimer = setTimeout(() => {
        customSetter(!customState)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  const [uomList, setUomList] = useState([])

  const apiCall = async () => {
    {
      const response = await dispatch(
        getOptionsList({
          listName: "uom-list",
          search: "",
          sort: "desc",
          limit: "0",
          page: "0",
        }),
      )
      const options = response?.payload?.map(item => {
        // if (item.uom_type === (popupData?.type === "paint" ? "litre" : "kg")) {
        if (
          item.uom_type ===
          (popupData?.type === "powder" || popupData?.type === "putty"
            ? "kg"
            : popupData?.type === "sheet"
              ? "Number"
              : "litre")
        ) {
          setLitreId(item._id)
        }
        return { label: item.uom_type, value: item._id }
      })
      setUomList(options)
    }
  }

  useEffect(() => {
    if (uomList.length === 0) apiCall()
  }, [])
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")

    formik.setFieldValue("msl", String(popupData?.msl))
    focusOnElement("batchNumber")
  }, [])
  return (
    <div
      id='delete-card-container'
      className={`${
        addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation"
      }`}
    >
      <div
        className={
          "event-card" +
          (addStyle
            ? " show_delete-component-animation"
            : " hide_delete-component-animation")
        }
      >
        <h2>Update Inventory</h2>
        {popupData?.manufacture !== "self" && (
          <div className='department_closer'>
            {activeInputs ? (
              <button
                type='button'
                onClick={e => {
                  e.preventDefault()
                  setActiveInputs(false)
                  if (formik.values.remove) formik.setFieldValue("remove", "")
                }}
                className='btn-primary'
                disabled={inventoryLoading}
                style={inventoryLoading ? { cursor: "not-allowed" } : {}}
              >
                {inventoryLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  <span>Add</span>
                )}
              </button>
            ) : (
              <button
                type='button'
                onClick={e => {
                  e.preventDefault()
                  setActiveInputs(true)
                  if (formik.values.add) formik.setFieldValue("add", "")
                }}
                className='btn-secondary'
              >
                <span>Remove</span>{" "}
              </button>
            )}
          </div>
        )}

        <div className='modelEx_content'>
          <form
            className='editbox-form flex-center'
            onSubmit={formik.handleSubmit}
          >
            <div className='form-group'>
              {popupData?.manufacture !== "self" && (
                <div
                  className={`input-field ${
                    formik.values.batchNumber !== "" ||
                    focusedField === "batchNumber"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='batchNumber' className='toggle-label'>
                    Batch Number
                  </label>
                  <input
                    id='batchNumber'
                    name='batchNumber'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.batchNumber}
                    // onInput={filterNumbersRegex}
                    maxLength={80}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("batchNumber")}
                    // disabled={true}
                  />
                  {formik.touched.batchNumber && formik.errors.batchNumber ? (
                    <span className='err'>{formik.errors.batchNumber}</span>
                  ) : null}
                </div>
              )}
            </div>{" "}
            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.msl !== "" || focusedField === "msl"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='msl' className='toggle-label'>
                  Minimum Stock ( In{" "}
                  {popupData?.type === "powder" || popupData?.type === "putty"
                    ? "Kg"
                    : popupData?.type === "sheet"
                      ? "Number"
                      : "Litre"}
                  )
                </label>
                <input
                  id='msl'
                  name='msl'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.msl}
                  onInput={filterNumbersRegex}
                  maxLength={25}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("msl")}
                  // disabled={true}
                />
                {formik.touched.msl && formik.errors.msl ? (
                  <span className='err'>{formik.errors.msl}</span>
                ) : null}
              </div>
            </div>{" "}
            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.numberOfunits !== "" ||
                  focusedField === "numberOfunits"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='numberOfunits' className='toggle-label'>
                  {!activeInputs ? "Add No Of Packs" : "Remove No Of Packs"}
                </label>
                <input
                  id='numberOfunits'
                  name='numberOfunits'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.numberOfunits}
                  onInput={filterNumbersRegex}
                  maxLength={25}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("numberOfunits")}
                  // disabled={true}
                />
                {formik.touched.numberOfunits && formik.errors.numberOfunits ? (
                  <span className='err'>{formik.errors.numberOfunits}</span>
                ) : null}
              </div>
            </div>{" "}
            <div className='form-group'>
              <div className='input-field'>
                <DropDownBox
                  options={uomList}
                  customSetter={formik.setFieldValue}
                  dropDownTitle='UOM'
                  animateDropDownTitle
                  customFormikLabel={"uom"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  customTextStyle={{
                    color: "#212229",
                    opacity: "0.7",
                  }}
                  disabled={false}
                  incomingValue={litreId}
                />
                {formik.touched.uom && formik.errors.uom ? (
                  <span className='err'>{formik.errors.uom}</span>
                ) : null}
              </div>
            </div>
            {/* <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.msl !== "" || focusedField === "msl"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='msl' className='toggle-label'>
                  Minimum Quantity
                </label>
                <input
                  id='msl'
                  name='msl'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.msl}
                  onInput={e => {
                    e.target.value = e.target.value.replace(/[^\d ]/gi, "")
                  }}
                  maxLength={25}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("msl")}
                />
                {formik.touched.msl && formik.errors.msl ? (
                  <span className='err'>{formik.errors.msl}</span>
                ) : null}
              </div>
            </div> */}
            {/* {!activeInputs ? (
              <div className='form-group'>
                <div
                  className={`input-field ${
                    formik.values.add !== "" || focusedField === "add"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='quantity' className='toggle-label'>
                    Add Products
                  </label>
                  <input
                    id='add'
                    name='add'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.add}
                    onInput={e => {
                      e.target.value = e.target.value
                        .replace(/[^\d ]/gi, "")
                        .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                    }}
                    maxLength={25}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("add")}
                  />
                  {formik.touched.quantity && formik.errors.quantity ? (
                    <span className='err'>{formik.errors.quantity}</span>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className='form-group'>
                <div
                  className={`input-field ${
                    formik.values.remove !== "" || focusedField === "remove"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='quantity' className='toggle-label'>
                    Remove Products
                  </label>
                  <input
                    id='remove'
                    name='remove'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.remove}
                    onInput={e => {
                      e.target.value = e.target.value
                        .replace(/[^\d ]/gi, "")
                        .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                    }}
                    maxLength={25}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("remove")}
                    disabled={!activeInputs}
                  />
                  {formik.touched.quantity && formik.errors.quantity ? (
                    <span className='err'>{formik.errors.quantity}</span>
                  ) : null}
                </div>
              </div>
            )}{" "} */}
            {/* <div className='addPopup-btn-box'>
              <button
                type='button'
                className='btn-secondary'
                onClick={handleClick}
              >
                Abort
              </button>

              <button
                className='btn-primary'
                type='submit'
                style={
                  inventoryLoading ||
                  (!formik.values.add &&
                    !formik.values.remove &&
                    formik.values.minimumQuantity === String(popupData?.msl))
                    ? {
                        cursor: "not-allowed",
                        // background: "gray",
                      }
                    : {}
                }
                disabled={
                  inventoryLoading ||
                  (!formik.values.add &&
                    !formik.values.remove &&
                    formik.values.minimumQuantity === String(popupData?.msl))
                    ? true
                    : false
                }
              >
                {inventoryLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div> */}
            <FormButtons
              loading={inventoryLoading}
              abortHandleClick={handleClick}
              disabled={
                inventoryLoading ||
                formik.values.minimumQuantity === String(popupData?.msl)
                  ? true
                  : false
              }
              submitBtnStyle={
                inventoryLoading ||
                (!formik.values.add &&
                  !formik.values.remove &&
                  formik.values.minimumQuantity === String(popupData?.msl))
                  ? {
                      cursor: "not-allowed",
                    }
                  : {}
              }
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdateInventory
