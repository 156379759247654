import React from "react"

const SkeletonBar = ({ skeletonNumber }) => {
  const skeletonList = Array(skeletonNumber).fill(null)

  return skeletonList?.map((i, index) => {
    return (
      <div id='skeleton-bar'>
        <div className='skeleton-card-image'>
          <div className='skeleton-block pulsate'></div>
        </div>
        <div className='skeleton-card-content'>
          <div className='skeleton-block2 pulsate'></div>
          <div className='skeleton-block3 pulsate'></div>

          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    )
  })
}

export default SkeletonBar
