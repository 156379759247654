/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
import {
  deleteUserAccountAsync,
  getUserList,
  userActivateDeactivate,
} from "../redux/userApi"
import Skelaton from "../../../components/Skelaton"
import ConformationPopup from "../../../components/ConformationPopup"
import { setPageDetails } from "../../auth/redux/authSlice"
import Pagination from "../../../components/Pagination"
import SearchBox from "../../../components/SearchBox"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"

const Users = ({ setTitle }) => {
  useEffect(() => {
    setTitle("User Management")
  }, [setTitle])
  const dispatch = useDispatch()
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )
  const { userList, metaData, userLoading } = useSelector(
    state => state.user,
    shallowEqual,
  )

  const [records, setRecords] = useState(5)
  const [search, setSearch] = useState(
    pageDetails.search ? pageDetails.search : "",
  )
  const [popupCustomWarning, setPopupCustomWarning] = useState("")
  const [filter, setFilter] = useState("desc")

  const [data, setData] = useState(null)
  const [active, setActive] = useState("user")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [isUserActive, setIsUserActive] = useState(false)
  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  // const [tableList, setTableList] = useState([]);
  const [isEventCard, setIsEventCard] = useState(false)
  const [popupData, setPopupData] = useState()
  const [popupDataTwo, setPopupDataTwo] = useState()
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [deleteAllPopup, setDeleteAllPopup] = useState(false)
  // const [selectedList, setSelectedList] = useState([]);
  // const [checkboxState, setCheckboxState] = useState(false);
  const [checkedCollection] = useState([])
  const [isEventCardTwo, setIsEventCardTwo] = useState(false)

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  const [selectAllProducts, setSelectAllProducts] = useState(false)
  const [collectAllProducts, setCollectAllProducts] = useState([])
  // const handleChange = (no) => {
  //   // setTableIndex(index);
  //   if (no > 0 && no <= users.length) {
  //     setTableIndex(no - 1);
  //   }
  //   if (no > 1 && no < users.length - 1) {
  //     if (no < btnNo[1]) {
  //       setBtnNo([btnNo[0] - 1, btnNo[1] - 1, btnNo[2] - 1]);
  //     } else if (no > btnNo[1]) {
  //       setBtnNo([btnNo[0] + 1, btnNo[1] + 1, btnNo[2] + 1]);
  //     }
  //   }
  // };

  // const handleLastBtn = () => {
  //   setTableIndex(users.length - 1);
  //   setBtnNo([users.length - 3, users.length - 2, users.length - 1]);
  // };
  // const handleFirstBtn = () => {
  //   setTableIndex(0);
  //   setBtnNo([1, 2, 3]);
  // };

  const handleChange = no => {
    // setTableIndex(index);
    if (
      no > 0 &&
      no <= new Array(metaData?.totalPages).fill(undefined).length
    ) {
      setTableIndex(no - 1)
    }
    if (
      no > 1 &&
      no < new Array(metaData?.totalPages).fill(undefined).length - 1
    ) {
      if (no < btnNo[1]) {
        setBtnNo([btnNo[0] - 1, btnNo[1] - 1, btnNo[2] - 1])
      } else if (no > btnNo[1]) {
        setBtnNo([btnNo[0] + 1, btnNo[1] + 1, btnNo[2] + 1])
      }
    }
  }

  const handleLastBtn = () => {
    setTableIndex(new Array(metaData?.totalPages).fill(undefined).length - 1)
    setBtnNo([
      new Array(metaData?.totalPages).fill(undefined).length - 3,
      new Array(metaData?.totalPages).fill(undefined).length - 2,
      new Array(metaData?.totalPages).fill(undefined).length - 1,
    ])
    dispatch(
      getUserList({
        page: new Array(metaData?.totalPages).fill(undefined).length - 1,
        limit: 5,
        sort: "desc",
        search,
      }),
    )
  }

  const handleFirstBtn = () => {
    setTableIndex(0)
    setBtnNo([1, 2, 3])
    dispatch(
      getUserList({
        page: 0,
        limit: 5,
        sort: "desc",
        search,
      }),
    )
  }

  const deleteFunction = (id, title) => {
    setPopupData({
      data: {
        id,
        page: 0,
        search: "",
        sort: "desc",
        limit: "5",
        send: {
          is_delete: true,
          user_ids: selectAllProducts ? collectAllProducts : [id],
        },
      },
      title,
    })
    setIsEventCard(true)
  }

  useEffect(() => {
    const updateListData = async () => {
      if (userList?.length > 0) {
        const newList = JSON.parse(JSON.stringify(userList))

        newList.forEach(item => {
          item["checked"] = false
        })
        setData(newList)
      } else {
        setData([])
      }
    }

    updateListData()

    // if (checkedCollection.length > 0) {
    //   checkedCollection.forEach((item) => {
    //     if (item?.page === metaData.page) {
    //       if (
    //         item?.isSelectAllCheckToggle !==
    //         document.getElementById("check").checked
    //       ) {
    //         document.getElementById("check").checked =
    //           item?.isSelectAllCheckToggle;
    //       }
    //       setData((oldList) => {
    //         let newList = JSON.parse(JSON.stringify(oldList));
    //         newList.forEach((data) => {
    //           if (item?.collection.includes(data._id)) {
    //             data.checked = true;
    //           }
    //         });

    //         return newList;
    //       });
    //     } else {
    //       const checkValue = document.getElementById("check");
    //       if (checkValue.checked) {
    //         checkValue.checked = false;
    //       }
    //     }
    //   });
    // }
  }, [userList, checkedCollection])

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      dispatch(
        getUserList({
          page:
            Object.keys(pageDetails).length > 0 &&
            pageDetails.page !== undefined
              ? pageDetails?.page
              : 0,
          limit: records,
          search:
            Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
          sort: "desc",
        }),
      )
      setCheck(true)
      if (metaData?.length > 0) {
        dispatch(setPageDetails({ page: metaData?.page, search }))
      }
      return
    }
    const timeoutId = setTimeout(async () => {
      dispatch(getUserList({ page: 0, limit: records, search, filter }))
      setTableIndex(0)
      setBtnNo([1, 2, 3])
      if (selectAllProducts) {
        setSelectAllProducts(false)
        setCollectAllProducts([])
      }
    }, 1000)
    setSearchTimeout(timeoutId)
    dispatch(setPageDetails({ page: metaData?.page, search }))
  }, [search, dispatch, records, filter])

  useEffect(() => {
    if (pageDetails?.btnNo) {
      setBtnNo(pageDetails?.btnNo)
    }

    dispatch(setPageDetails({ page: metaData?.page, search }))
  }, [metaData])
  // useEffect(() => {
  //   dispatch(getUserList({ page: 0, limit: records, search, filter }));
  //   const updateListData = async () => {
  //     if (userList?.length > 0) {
  //       setData(userList);
  //     } else {
  //       setData([]);
  //     }
  //   };
  //   updateListData();

  //   let empArr = [];

  //   mailList.forEach((item) => {
  //     item?.forEach((item2) => {
  //       if (item2.checked === true) {
  //         empArr.push(item);
  //       }
  //     });
  //   });
  //   setSelectedList(empArr);
  // }, [mailList]);

  const userPosition = role => {
    if (role === 2) {
      return "Admin"
    } else if (role === 3) {
      return "Manager"
    }
  }

  // const [tableLoading, setTableLoading] = useState(false)
  // const [tableCount, setTableCount] = useState(null)

  // useEffect(() => {
  //   if (tableCount) {
  //     clearTimeout(tableCount)
  //   }
  //   const count = setTimeout(() => {
  //     // if (userLoading) {
  //     //   setTableLoading(true);
  //     // } else {
  //     //   setTableLoading(false);
  //     // }
  //     setTableLoading(userLoading)
  //   }, 1000)
  //   setTableCount(count)
  // }, [userLoading])

  const { tableLoading } = useLoaderDelay({ loadingState: userLoading })

  useEffect(() => {
    const idArr = userList?.map(item => {
      return item?._id
    })
    if (selectAllProducts) {
      setCollectAllProducts(idArr)
    } else {
      setCollectAllProducts([])
    }
  }, [selectAllProducts])

  useEffect(() => {
    if (selectAllProducts && collectAllProducts.length === 0) {
      setSelectAllProducts(false)
      setCollectAllProducts([])
      return
    }
  }, [collectAllProducts])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
                api={getUserList}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
                api={getUserList}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
              api={getUserList}
            />
          )}
          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>User Management</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
                {/* <div className="mch2">
                  <div className="mch2-duration">
                    August 1,2020 - August 31,2020
                  </div>
                  <div
                    className="mch2-action"
                    onClick={() => setAction(!action)}
                  >
                    <span className="">Action</span>
                    <span className="material-symbols-rounded expand">
                      expand_more
                    </span>
                    {action && (
                      <div className="action-tooltip">
                        <span className="">Download</span>
                        <span className="">Print</span>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
              <div className='data-table-container'>
                <div className='heading-tools'>
                  <h3>User List</h3>
                  <div className='table-search'>
                    <div className='col1 search-container'>
                      {/* <div
                        className={
                          "search-box " + (searchShow ? "searchShow" : "")
                        }
                      >
                        <input
                          type='search'
                          className='table-search-input '
                          placeholder='Search here...'
                          value={search}
                          onChange={e => setSearch(e.target.value)}
                        />
                        <span
                          className='material-symbols-outlined '
                          onClick={() => setSearchShow(!searchShow)}
                        >
                          search
                        </span>{" "}
                      </div> */}
                      <SearchBox
                        customSetter={setSearch}
                        customState={search}
                      />
                      {Object.values(privileges["user_management"]).includes(
                        "write",
                      ) && (
                        <button
                          className='btn-primary header-btn'
                          onClick={() => navigate("/user/add-user")}
                        >
                          Add
                        </button>
                      )}
                      {Object.values(privileges["user_management"]).includes(
                        "delete",
                      ) && selectAllProducts ? (
                        <button
                          className='btn-secondary header-btn delete-primary-button'
                          onClick={() => {
                            deleteFunction("all products")
                          }}
                        >
                          <span className='material-symbols-outlined medium'>
                            delete
                          </span>{" "}
                          Delete{" "}
                          {collectAllProducts.length > 1
                            ? "All " + collectAllProducts.length
                            : ""}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* {selectedList.length > 0 && (
                  <div className="selectAll-container">
                    <div className="selectedDetails">
                      <span>
                        {selectedList?.length} / {mailList.length * 5} Users
                        selected.
                      </span>{" "}
                      <span
                        className="slc-btn"
                        onClick={handleSelectAllAndCheck}
                      >
                        {selectedList.length === mailList.length * 5
                          ? "Unselect"
                          : "Select"}{" "}
                        all {mailList.length * 5} users
                      </span>
                    </div>
                    <div className="selectedAction">
                      {" "}
                      <button
                        className="btn-primary header-btn"
                        onClick={() => {
                          setMailList(users);
                          setSelectedList([]);
                          setSelectAllChecked([
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                          ]);
                          setDeleteAllPopup(!deleteAllPopup);
                        }}
                      >
                        <span className="material-symbols-outlined medium">
                          Delete
                        </span>
                      </button>
                    </div>
                  </div>
                )} */}

                {tableLoading ? (
                  <Skelaton col={5} row={data?.length ? data?.length : 5} />
                ) : (
                  <>
                    {data?.length > 0 ? (
                      <>
                        <div className='data-table-box scrollbar-visible'>
                          <table className='data-table fix-width-table-row'>
                            <thead>
                              <tr>
                                {/* eslint-disable-next-line */}
                                {/* {selectAllChecked?.map((item, index) => {
                          const { checked } = item;
                          if (index === tableIndex) {
                            return (
                              <th className="tb-head-name" key={index}>
                                <input
                                  id="check"
                                  type="checkbox"
                                  className="checkbox-animated"
                                  onChange={handlecheckAll}
                                  checked={checked}
                                />
                                <label htmlFor={"check"}>
                                  <span></span>
                                </label>
                              </th>
                            );
                          }
                        })} */}
                                <th className='tb-head-name padding-07 '>
                                  <div>
                                    <span>
                                      <input
                                        name='checkbox'
                                        id='checkbox'
                                        type='checkbox'
                                        className='checkbox-animated'
                                        onChange={() =>
                                          setSelectAllProducts(
                                            !selectAllProducts,
                                          )
                                        }
                                        checked={selectAllProducts}
                                      />
                                      <label htmlFor={"checkbox"}>
                                        <span className='filter-check-background'></span>
                                      </label>
                                    </span>
                                  </div>
                                </th>
                                <th>User Name</th>
                                <th>Department</th>
                                <th>Position</th>
                                <th>Phone No</th>
                                <th>Status</th>
                                {Object.values(
                                  privileges["user_management"],
                                ).includes("read") && <th>Action</th>}
                              </tr>
                            </thead>

                            <tbody>
                              {/* {mailList[tableIndex]?.map((item, index) => { */}
                              {data?.map((item, index) => {
                                const { num, _id, department_detail } = item
                                //
                                return (
                                  <tr key={index}>
                                    <td className='tb_data padding-07'>
                                      {selectAllProducts ? (
                                        <span
                                          onChange={
                                            e => e.stopPropagation()
                                            // setSelectAllProducts(
                                            //   !selectAllProducts,
                                            // )
                                          }
                                        >
                                          <input
                                            name={"checkbox" + index}
                                            id={"checkbox" + index}
                                            type='checkbox'
                                            className='checkbox-animated'
                                            onChange={e => {
                                              e.stopPropagation()
                                              setCollectAllProducts(oldList => {
                                                let newList = JSON.parse(
                                                  JSON.stringify(oldList),
                                                )

                                                if (newList.includes(_id)) {
                                                  newList.splice(
                                                    newList.indexOf(_id),
                                                    1,
                                                  )
                                                } else {
                                                  newList.unshift(_id)
                                                }

                                                return newList
                                              })
                                            }}
                                            checked={collectAllProducts.includes(
                                              _id,
                                            )}
                                          />
                                          <label htmlFor={"checkbox" + index}>
                                            <span className='filter-check-background'></span>
                                          </label>
                                        </span>
                                      ) : (
                                        num + "."
                                      )}
                                    </td>
                                    <td>
                                      {item?.fname} {item?.lname}
                                    </td>
                                    <td>{department_detail?.name}</td>
                                    <td>{userPosition(item?.role)}</td>
                                    <td>{item?.phone?.value.slice(3)}</td>
                                    <td>
                                      <span
                                        className={
                                          item?.status
                                            ? "status active"
                                            : "status inactive"
                                        }
                                      >
                                        {item?.status ? "Active" : "Inactive"}
                                      </span>
                                    </td>
                                    <td>
                                      <div className='email-action-box'>
                                        {Object.values(
                                          privileges["user_management"],
                                        ).includes("read") && (
                                          <button
                                            className='icon-btn see'
                                            onClick={() => {
                                              dispatch(
                                                setPageDetails({
                                                  ...pageDetails,
                                                  btnNo,
                                                }),
                                              )
                                              navigate(`/user/${item?._id}`)
                                            }}
                                          >
                                            <span className='material-symbols-outlined medium'>
                                              visibility
                                            </span>
                                          </button>
                                        )}{" "}
                                        {Object.values(
                                          privileges["user_management"],
                                        ).includes("write") && (
                                          <button
                                            className={`icon-btn ${!item?.status ? "disable" : "delete"} `}
                                            onClick={() => {
                                              // setIsEventCard(true);
                                              setPopupDataTwo({
                                                data: {
                                                  id: item?._id,
                                                  send: {
                                                    status: !item?.status,
                                                  },
                                                },
                                                title: "user state changed",
                                              })
                                              setIsEventCardTwo(true)
                                              setPopupCustomWarning(
                                                !item?.status
                                                  ? "Are you sure you want to activate this user's account?"
                                                  : "Are you sure you want to deactivate this user's account?",
                                              )
                                            }}
                                            title={
                                              !item?.status
                                                ? "activate user account"
                                                : "deactivate user account"
                                            }
                                          >
                                            <span className='material-symbols-outlined medium'>
                                              {item?.status
                                                ? "person_cancel"
                                                : " person_add"}
                                            </span>
                                            {/* <i
                                              // style={{fontSize:"1rem"}}
                                              className={`fa-solid ${!item?.status ? "fa-user-check" : "person_cancel"}  medium`}
                                            ></i> */}
                                          </button>
                                        )}{" "}
                                        {Object.values(
                                          privileges["user_management"],
                                        ).includes("delete") && (
                                          <button
                                            className='icon-btn delete'
                                            onClick={() => {
                                              // setIsEventCard(true);
                                              deleteFunction(
                                                item?._id,
                                                item?.fname,
                                              )
                                            }}
                                          >
                                            <span className='material-symbols-outlined medium'>
                                              Delete
                                            </span>
                                          </button>
                                        )}{" "}
                                      </div>

                                      {/* <button
                                className="icon-btn edit"
                                // onClick={() => navigate("/user/1")}
                              >
                                <span className="material-symbols-outlined">
                                  visibility
                                </span>
                              </button> */}
                                      {/* <button
                                className="icon-btn delete"
                                onClick={() =>
                                  deleteFunction(index, item?.fullName)
                                }
                              >
                                <span className="material-symbols-outlined medium">
                                  Delete
                                </span>
                              </button> */}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>

                        <Pagination
                          sliceName={"user"}
                          search={search}
                          filter={"desc"}
                          api={getUserList}
                          limit={5}
                          tableIndex={tableIndex}
                          setTableIndex={setTableIndex}
                          btnNo={btnNo}
                          setBtnNo={setBtnNo}
                          callbackFunction={() => {
                            setSelectAllProducts(false)
                            setCollectAllProducts([])
                          }}
                        />
                      </>
                    ) : (
                      <div className='Nodata-txt'>
                        <img
                          src={
                            require("../../../assets/images/nodata.svg").default
                          }
                          alt=''
                          srcSet=''
                        />
                        No Data Found
                      </div>
                    )}{" "}
                  </>
                )}
              </div>
            </div>
            <Footer />
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteUserAccountAsync}
              popUpData={popupData}
              customFunction={() => {
                setSelectAllProducts(false)
                setCollectAllProducts([])
              }}
              toastMessage={"User removed successfully"}
            />
          )}{" "}
          {isEventCardTwo && (
            <ConformationPopup
              customState={isEventCardTwo}
              customSetter={setIsEventCardTwo}
              mainTitle={popupCustomWarning}
              subTitle="This action will change this user's account status"
              api={userActivateDeactivate}
              popUpData={popupDataTwo}
              reData={{ page: 0, search: "", sort: "desc", limit: 5 }}
              reFetchApi={getUserList}
              popupType='edit'
              toastMessage='User state changed'
            />
          )}
          {deleteAllPopup && (
            <ConformationPopup
              customState={deleteAllPopup}
              customSetter={setDeleteAllPopup}
            />
          )}{" "}
        </div>
      </div>
    </div>
  )
}

export default Users
