import React from "react"

const DataNotFound = ({ title = "No Data Found" }) => {
  return (
    <div className='Nodata-txt'>
      <img
        src={require("../../src/assets/images/nodata.svg").default}
        alt=''
        srcSet=''
      />
      {title}
    </div>
  )
}

export default DataNotFound
