import { createSlice } from "@reduxjs/toolkit"
import { getProductsDetails, getProductsList } from "./ProductsApi"

const productsSlice = createSlice({
  name: "products",
  initialState: {
    productsLoading: false,
    optionsLoading: false,
    productDetails: [],
    productsList: [],
    metaData: [],
  },
  reducers: {
    isProductsToggle: (state, { payload }) => {
      state.productsLoading = payload
    },
    isOptionsLoading: (state, { payload }) => {
      state.optionsLoading = payload
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getProductsList.pending, (state, action) => {
        state.productsLoading = true
      })
      .addCase(getProductsList.fulfilled, (state, { payload }) => {
        state.productsLoading = false
        if (
          payload?.hasOwnProperty("metadata") &&
          payload?.hasOwnProperty("data")
        ) {
          state.productsList = payload?.data
          state.metaData = payload?.metadata?.[0]
        }
      })
      .addCase(getProductsList.rejected, (state, { payload }) => {
        state.productsLoading = false
      })
    builder
      .addCase(getProductsDetails.pending, (state, { payload }) => {
        state.productsLoading = true
      })
      .addCase(getProductsDetails.fulfilled, (state, { payload }) => {
        state.productsLoading = false
        state.productDetails = payload?.data?.data
      })
      .addCase(getProductsDetails.rejected, state => {
        state.productsLoading = false
      })
  },
})

export const { isProductsToggle, isOptionsLoading } = productsSlice.actions
export default productsSlice.reducer
