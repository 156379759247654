/* eslint-disable no-useless-escape */
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import * as Yup from "yup"
import DropDownBox from "../../../components/DropDownBox"

const ApprovalForm = ({ approvalPopupForm, setApprovalPopupForm }) => {
    const { companyLoading } = useSelector(store => store.company)

    const [focusedField, setFocusedField] = useState()
    const [addStyle, setAddStyle] = useState(true)
    const formik = useFormik({
        initialValues: {
            status: "",
            note: "",
        },
        // validationSchema: locationSchema,
        onSubmit: async (values, { resetForm }) => {
             
        },
    })

    const handleClick = e => {
        setApprovalPopupForm({ data: {}, status: false })
    }

    useEffect(() => {
        formik.setValues({
            mode: approvalPopupForm.data
        })
    }, [approvalPopupForm])


    return (
        <div
            className={
                "mainExelPop" +
                (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
            }
        >
            <div
                className={
                    "modelExel" +
                    (addStyle
                        ? " show_component-common-animation"
                        : " hide_component-common-animation")
                }
            >
                <div className='modelEx_header'>
                    <h2>
                        Approval Status
                    </h2>
                    <button className='exit-btn' onClick={() => handleClick()}>
                        <span className='material-symbols-outlined medium'>close</span>
                    </button>
                </div>

                <div className='modelEx_content singleAdd'>
                    <form className='scrollForm' onSubmit={formik.handleSubmit}>
                        <div
                            className='form-content form-for-Address'
                            style={{ paddingBottom: "2.5rem", paddingTop: "1.5rem" }}
                        >
                            <div className='form-group form'>
                                <div className='input-field'>
                                    <DropDownBox
                                        options={[
                                            { label: "Pending", value: "pending" },
                                            { label: "Approved ", value: "approved " },
                                            { label: "Rejected", value: "rejected" },
                                        ]}
                                        customSetter={formik.setFieldValue}
                                        customDropBoxStyles={{
                                            border: "1px solid #d7cece",
                                            borderRadius: "0.5rem",
                                            color: "#333",
                                        }}
                                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                                        dropDownTitle='Approval Status'
                                        animateDropDownTitle
                                        customFormikLabel={"status"}
                                        incomingValue={formik.values.status}
                                    />
                                    {formik.touched.status && formik.errors.status ? (
                                        <span className='err'>{formik.errors.status}</span>
                                    ) : null}
                                </div>
                                <div
                                    className={`input-field ${formik.values.note !== "" || focusedField === "note"
                                        ? "active"
                                        : ""
                                        }`}
                                >
                                    <label htmlFor='address-line-one' className='toggle-label'>
                                        Note
                                    </label>
                                    <textarea
                                        id='note'
                                        name='note'
                                        type='text'
                                        className='form-input'
                                        value={formik.values.note}
                                        onChange={formik.handleChange}
                                        onBlur={e => {
                                            setFocusedField("")
                                            formik.handleBlur(e)
                                        }}
                                        onFocus={() => setFocusedField("note")}
                                    />
                                    {formik.touched.note && formik.errors.note ? (
                                        <span className='err'>{formik.errors.note}</span>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className='footer flex-center'>
                            <button
                                className='btn-primary'
                                type='submit'
                                disabled={companyLoading}
                            >
                                {companyLoading ? (
                                    <ClipLoader
                                        loading={true}
                                        size={15}
                                        aria-label='Loading Spinner'
                                        data-testid='loader'
                                        color='white'
                                    />
                                ) : (
                                    "SUBMIT"
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ApprovalForm
