import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { addBulkCompaniesAsync } from "../redux/bNsApi"
import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import template from "../../../assets/files/template.xlsx"
import FormButtons from "../../../components/SubmitBtns"

const AddMultipleCom = ({ popup, setPopup }) => {
  const [selectedFileDoc, setSelectedFileDoc] = useState(null)
  const [addStyle, setAddStyle] = useState(true)
  const { companyLoading } = useSelector(store => store.bNs)

  const dispatch = useDispatch()
  //
  const handleChange = e => {
    const selectedFile = e.currentTarget.files[0]
    if (selectedFile) {
      const docType = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ]
      if (docType.includes(selectedFile.type)) {
        if (selectedFile.size > 5 * 1024 * 1024) {
          toast.warning(
            "File size exceeds 5MB limit. Please select a smaller file.",
          )
          e.target.value = null
        } else {
          setSelectedFileDoc(selectedFile)
          formik.setFieldValue("excelFile", e.currentTarget.files[0])
          e.target.value = null
        }
      } else {
        toast.warning("accepts .xlxs, .xlsm, .xlsb, .xltx, and .xls files only")
        e.target.value = null
      }
    }
  }
  const validationSchema = Yup.object().shape({
    excelFile: Yup.mixed()
      .required("File is required")
      .test("fileFormat", "Invalid file format", value => {
        return (
          value &&
          (value.type === "application/vnd.ms-excel" ||
            value.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        )
      }),
  })

  const formik = useFormik({
    initialValues: {
      excelFile: "",
    },
    validationSchema,
    onSubmit: async (value, { setSubmitting, resetForm }) => {
      const data = {
        excelFile: value.excelFile,
      }
      const response = await dispatch(addBulkCompaniesAsync(data))
      if (response?.payload) {
        setSubmitting(false)
        resetForm()
        // setPopup(false);
        handleClick()
      }

      // setSubmitting(false);
      // resetForm();
      // // setPopup(false);
      // handleClick();
    },
  })
  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (popup === 2) {
      const styleTimer = setTimeout(() => {
        setPopup(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  function handleDelete() {
    formik.setFieldValue("excelFile", null)
    setSelectedFileDoc()
  }
  //
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  const downLoadSheet = async () => {
    // const fileURL = template;
    try {
      const response = await fetch(template)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.download = `Template`
      link.click()
    } catch (error) {}
  }

  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>Add Multiple Companies</h2>
          {!companyLoading && (
            <button className='exit-btn' onClick={() => handleClick()}>
              <span className='material-symbols-outlined medium'>close</span>
            </button>
          )}
        </div>
        <div className='modelEx_Steps'>
          <div className='step'>
            <span className='num'>1</span>
            <p>Upload File</p>
          </div>
          <span className='steps_gap' />
          <div className='step'>
            <span className='num'>2</span>
            <p>Verify Header</p>
          </div>
          <span className='steps_gap' />
          <div className='step'>
            <span className='num'>3</span>
            <p>Verify Data</p>
          </div>
          <span className='steps_gap' />
          <div className='step'>
            <span className='num'>4</span>
            <p>Finish</p>
          </div>
        </div>

        <hr color='#D9D9D9' style={{ margin: 0 }} />

        <div className='modelEx_content'>
          <p className='instruction'>
            You can add multiple companies using an Excel{"("}.xlsx{")"} file.{" "}
            {"["}Note: a minimum of 200 items is allowed at a time{"]"}
          </p>

          <div className='require_data'>
            <h3 className='title'>Required Data Format</h3>
            <div className='table-wrapper'>
              <div className='table_box scrollbar-visible'>
                <table className='mxTable'>
                  <thead>
                    <tr className='mxTb-row'>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Name
                          <span
                            className='tooltip label flex-center'
                            data-title='Company Name'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Company Type
                          <span
                            className='tooltip label flex-center'
                            data-title='Type Of Company'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Business Type
                          <span
                            className='tooltip label flex-center'
                            data-title='Business Type'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          GST Type
                          <span
                            className='tooltip label flex-center'
                            data-title='GST Type'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>{" "}
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          GST Number
                          <span
                            className='tooltip label flex-center'
                            data-title='GST Number'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>{" "}
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Email
                          <span
                            className='tooltip label flex-center'
                            data-title='Company Email'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>{" "}
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Phone
                          <span
                            className='tooltip label flex-center'
                            data-title='Company Phone No.'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Mobile
                          <span
                            className='tooltip label flex-center'
                            data-title='Company Mobile No.'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Address Line 1
                          <span
                            className='tooltip label flex-center'
                            data-title='Company Address Line 1'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Address Line 2
                          <span
                            className='tooltip label flex-center'
                            data-title='Company Address Line 2'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          City
                          <span
                            className='tooltip label flex-center'
                            data-title='City'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Zip Code
                          <span
                            className='tooltip label flex-center'
                            data-title='Zip Code'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          State
                          <span
                            className='tooltip label flex-center'
                            data-title='State'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Area
                          <span
                            className='tooltip label flex-center'
                            data-title='Area'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Contact Person Name 1
                          <span
                            className='tooltip label flex-center'
                            data-title='Contact Person Name In The Company'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Contact Person Phone 1
                          <span
                            className='tooltip label flex-center'
                            data-title='Contact Person Phone No.'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Contact Person Email 1
                          <span
                            className='tooltip label flex-center'
                            data-title='Contact Person Email'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Contact Person Name 2
                          <span
                            className='tooltip label flex-center'
                            data-title='Contact Person Name In Your Company'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Contact Person Phone 2
                          <span
                            className='tooltip label flex-center'
                            data-title='Contact Person Phone No.'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                      <th className='mxTb-header'>
                        <div className='h-content'>
                          Contact Person Email 2
                          <span
                            className='tooltip label flex-center'
                            data-title='Contact Person Email'
                          >
                            <span className='material-symbols-rounded medium'>
                              info
                            </span>
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='mxTb-row text-center'>
                      <td>Buyer Company 1</td>
                      <td>Individual</td>
                      <td>Supplier</td>
                      <td>Regular</td>
                      <td>19AADCA7065R1ZY</td>
                      <td>helpdeskin@jotun.com</td>
                      <td>7778889990</td>
                      <td>8886669990</td>
                      <td>Ph1 C1/14 PHASE 1 GIDC ESTATE </td>
                      <td>NARODA AHMEDABAD</td>
                      <td>Ahmedabad</td>
                      <td>382481</td>
                      <td>Gujarat</td>
                      <td>Ranip</td>
                      <td>Ramesh Bhai</td>
                      <td>9996663330</td>
                      <td>ramesh@gmail.com</td>
                      <td>Pankaj Bhai</td>
                      <td>9996665550</td>
                      <td>pankaj@gmail.com</td>
                    </tr>
                    <tr className='mxTb-row text-center'>
                      <td>Supplier Company 1</td>
                      <td>Company</td>
                      <td>Buyer</td>
                      <td>Composite</td>
                      <td>24ABGFA0448R1Z7</td>
                      <td>helpdeskin@sherwinwilliams.com</td>
                      <td>7779998880</td>
                      <td>9997770008</td>
                      <td>PLOT NO. 4, REVENUE SURVEY NO. 473,</td>
                      <td>OPP. AMBICA BOILER, GIDC VATVA</td>
                      <td>Surat</td>
                      <td>110101</td>
                      <td>Gujarat</td>
                      <td>Gota</td>
                      <td>Suresh Bhai</td>
                      <td>7771114440</td>
                      <td>suresh@gmail.com</td>
                      <td>Manoj Bhai</td>
                      <td>7771112220</td>
                      <td>manoj@gmail.com</td>
                    </tr>
                  </tbody>
                </table>
                <span className='sample_data_view'>Sample Data</span>
              </div>
            </div>

            <p className='instruction'>
              Column headers marked with an asterisk{"(*)"} are required
            </p>
            <div className='flex-center pt-1'>
              <a href={process.env.REACT_APP_MULTI_FILE}>
                <button className='btn-secondary'>
                  Download Excel Template
                </button>
              </a>
            </div>
          </div>
          <form
            className='multipleComForm flex-center pt-1 form-group'
            onSubmit={formik.handleSubmit}
          >
            <div className='file_input flex-center file_name-box'>
              {selectedFileDoc ? (
                <div className=''>
                  <u>File</u>: {selectedFileDoc.name}
                  {!companyLoading && (
                    <span
                      className='material-symbols-outlined medium'
                      onClick={handleDelete}
                    >
                      cancel
                    </span>
                  )}
                </div>
              ) : (
                <>
                  <div className='txt flex-center'>
                    <span>Drag & Drop</span>
                    <span>OR</span>
                    <span>Select File</span>
                  </div>
                  <input
                    type='file'
                    className='excel_input'
                    id='excel_input'
                    name='file'
                    accept='.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    onChange={handleChange}
                  />
                </>
              )}
            </div>
            {formik.errors.excelFile && formik.touched.excelFile && (
              <span className='err'> {formik.errors.excelFile} </span>
            )}
            {/* <button
              type='submit'
              disabled={formik.isSubmitting}
              className='btn-primary'
              style={companyLoading ? { cursor: "not-allowed" } : {}}
            >
              {companyLoading ? (
                <ClipLoader
                  loading={true}
                  size={13}
                  aria-label='Loading Spinner'
                  data-testid='loader'
                />
              ) : (
                "Save"
              )}
            </button> */}
            <FormButtons
              loading={companyLoading}
              removeCancel
              btnContainerStyles={{
                justifyContent: "center",
                padding: ".5rem",
              }}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddMultipleCom
