/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
// import { deleteEmail, getEmailList } from "../redux/EmailApi";
// import { getInventoryList } from "../redux/InventoryApi"
// import PurchaseTable from "../components/PurchaseTable"
import FilterBox from "../../../components/FilterBox"
import SearchBox from "../../../components/SearchBox"
import Skelaton from "../../../components/Skelaton"
import OrderTable from "../components/OrderTable"
import UpdateSalesPopup from "../components/UpdateSalesPopup"

const SalesOrder = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Sales Order Management")
  }, [setTitle])
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [active, setActive] = useState("sales_order")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [isEventCard, setIsEventCard] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const { inventoryList } = useSelector(state => state.inventory)

  const [checkedCollection, setCheckedCollection] = useState([])
  const [filterTable, setFilterTable] = useState({
    stock_quantity: true,
    sold: true,
    created_at: true,
    minimum_stock_quantity: true,
  })
  const { inventoryLoading, metaData } = useSelector(store => store.inventory)
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const [searchShow, setSearchShow] = useState(false)
  const [activePo, setActivePo] = useState(3)
  const [isSalesPopUp, setIsSalesPopUp] = useState(false)

  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )

  const [showTable, setShowTable] = useState("order")

  //   useEffect(() => {
  //     if (searchTimeout || check) {
  //       clearTimeout(searchTimeout)
  //     } else {
  //       dispatch(
  //         getInventoryList({
  //           page: pageDetails?.page ? pageDetails?.page : 0,
  //           limit: records,
  //           search:
  //             Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
  //           sort: "desc",
  //         }),
  //       )
  //       setCheck(true)
  //       dispatch(setPageDetails({ page: metaData?.page, search }))
  //       return
  //     }

  //     const timeoutId = setTimeout(async () => {
  //       dispatch(
  //         getInventoryList({ limit: records, page: 0, search, sort: "desc" }),
  //       )
  //       setTableIndex(0)
  //       setBtnNo([1, 2, 3])
  //     }, 1000)
  //     dispatch(setPageDetails({ page: metaData?.page, search }))
  //     setSearchTimeout(timeoutId)
  //   }, [dispatch, records, search])

  //   useEffect(() => {
  //     dispatch(setPageDetails({ page: metaData?.page, search }))
  //   }, [metaData])

  const [tableLoading, setTableLoading] = useState(false)
  const [tableCount, setTableCount] = useState(null)

  useEffect(() => {
    if (tableCount) {
      clearTimeout(tableCount)
    }
    const count = setTimeout(() => {
      setTableLoading(inventoryLoading)
    }, 1000)
    setTableCount(count)
  }, [inventoryLoading])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  // Data for Purchase Orders
  const salesOrder = [
    {
      so_number: "SO-007845",
      sr_number: "SR-007845",
      company_Name: "Lorem Ipsum",
      delivery_Status: "Delivered",
      total_Products: "10",
      total_delivered_products: "8",
      creation_date: "13/03/24",
      action_by: "John Jacob",
      delivery_date: "13/03/24",
      total_amount: "1500",
    },
    {
      so_number: "SO-007845",
      sr_number: "SR-007845",
      company_Name: "Lorem Ipsum",
      delivery_Status: "Partially Delivered",
      total_Products: "10",
      total_delivered_products: "8",
      creation_date: "13/03/24",
      action_by: "John Jacob",
      delivery_date: "13/03/24",
      total_amount: "1500",
    },
  ]

  // Data for Purchase Returns
  const salesReturn = [
    {
      sales_return_number: "SR-007845",
      so_number: "SO-007845",
      delivery_Status: "Pending",
      total_return_qty: "9",
      creation_date: "13/03/24",
      action_by: "John Jacob",
      delivery_date: "13/03/24",
      total_amount: "500",
    },
    {
      sales_return_number: "SR-107845",
      so_number: "SO-307845",
      delivery_Status: "Pending",
      total_return_qty: "90",
      creation_date: "13/03/24",
      action_by: "John Jacob",
      delivery_date: "13/03/24",
      total_amount: "11500",
    },
  ]

  const Orderheaders = [
    { key: "so_number", label: "SO Number" },
    { key: "sr_number", label: "Sales Return No." },
    { key: "company_Name", label: "Company Name" },
    { key: "delivery_Status", label: "Delivery Status" },
    { key: "total_Products", label: "Total Products" },
    { key: "total_delivered_products", label: "Total Delivered Products" },
    { key: "creation_date", label: "Creation Date" },
    { key: "action_by", label: "Action By" },
    { key: "delivery_date", label: "Delivery date" },
    { key: "total_amount", label: "Total Amount" },
  ]

  const Returnheaders = [
    { key: "sales_return_number", label: "Sales Return Number" },
    { key: "so_number", label: "SO Number" },
    { key: "delivery_Status", label: "Delivery Status" },
    { key: "total_return_qty", label: "Total Return Qty" },
    { key: "creation_date", label: "Creation Date" },
    { key: "action_by", label: "Action By" },
    { key: "delivery_date", label: "Delivery Date" },
    { key: "total_amount", label: "Total Amount" },
  ]
  // Create array2 with rows of values
  const Orderlistcontent = salesOrder.map(row =>
    Orderheaders.map(header => row[header.key]),
  )

  const Returnlistcontent = salesReturn.map(row =>
    Returnheaders.map(header => row[header.key]),
  )

  const headerToDisplay = activePo === 3 ? Orderheaders : Returnheaders
  const dataToDisplay = activePo === 3 ? Orderlistcontent : Returnlistcontent

  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        {isSalesPopUp && (
          <UpdateSalesPopup
            activePo={activePo}
            customSetter={setIsSalesPopUp}
            customState={isSalesPopUp}
          />
        )}
        <div className='home-container'>
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>
                    {" "}
                    {activePo === 3 ? "Sales Order" : "Sales Return"}{" "}
                  </div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>
              <div className='data-table-container pur-data'>
                <div className='col1 heading-tools'>
                  <div className='p-change'>
                    <span
                      className={activePo === 3 ? "active" : ""}
                      onClick={() => setActivePo(3)}
                    >
                      Sales Order
                    </span>
                    <span
                      className={activePo === 4 ? "active" : ""}
                      onClick={() => setActivePo(4)}
                    >
                      Sales Return
                    </span>
                  </div>
                  <div className='table-search pur'>
                    <div className='search-container'>
                      <div className='search-filter-flex'>
                        <SearchBox
                          customSetter={setSearch}
                          customState={search}
                        />
                        <FilterBox
                          customState={filterTable}
                          customSetter={setFilterTable}
                          customPosition={{ right: "0", left: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton col={6} row={inventoryList?.length} />
                ) : dataToDisplay?.length > 0 ? (
                  <>
                    <OrderTable
                      filterTable={filterTable}
                      listContent={dataToDisplay}
                      listHeader={headerToDisplay}
                      activePo={activePo}
                      setIsSalesPopUp={setIsSalesPopUp}
                    />

                    {/* <Pagination
                      sliceName={"inventory"}
                      search={search}
                      filter={"desc"}
                      api={getInventoryList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                    /> */}
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesOrder
