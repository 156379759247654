import React, { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"

const Pagination = ({
  sliceName,
  tableIndex,
  setTableIndex,
  api,
  filter = "desc",
  search = "",
  limit = "5",
  btnNo,
  setBtnNo,
  send,
  callbackFunction,
  listName,
  metaDataName = "metaData",
}) => {
  const dispatch = useDispatch()
  //
  const { [metaDataName]: metaData } = useSelector(store => store[sliceName])
   

  const pageList = new Array(metaData?.totalPages).fill(undefined)
  const { pageDetails } = useSelector(state => state.auth, shallowEqual)
  const [timerId, setTimerId] = useState()
  const handleChange = no => {
    // setTableIndex(index);
    if (no > 0 && no <= pageList.length) {
      setTableIndex(no - 1)
    }
    if (no > 1 && no < pageList.length - 1) {
      if (no < btnNo[1]) {
        setBtnNo([btnNo[0] - 1, btnNo[1] - 1, btnNo[2] - 1])
        // dispatch(
        //   setPageDetails({
        //     ...pageDetails,
        //     btnNo: [btnNo[0] - 1, btnNo[1] - 1, btnNo[2] - 1],
        //   }),
        // )
      } else if (no > btnNo[1]) { 
        // dispatch(
        //   setPageDetails({
        //     ...pageDetails,
        //     btnNo: [btnNo[0] + 1, btnNo[1] + 1, btnNo[2] + 1],
        //   }),
        // )
        setBtnNo([btnNo[0] + 1, btnNo[1] + 1, btnNo[2] + 1])
      }
    }
  }

  const handleLastBtn = () => {
    if (timerId) {
      clearTimeout(timerId)
    }
    setTableIndex(pageList.length - 1)
    setBtnNo([pageList.length - 3, pageList.length - 2, pageList.length - 1])
    dispatch(
      api({
        page: pageList.length - 1,
        limit: 5,
        sort: "desc",
        search,
        filter,
        send: send ? send : {},
        listName: listName ? listName : "",
      }),
    )
    if (callbackFunction) {
      callbackFunction()
    }
  }
  const handleFirstBtn = () => {
    if (timerId) {
      clearTimeout(timerId)
    }
    setTableIndex(0)
    setBtnNo([1, 2, 3])
    dispatch(
      api({
        page: "0",
        limit: 5,
        sort: "desc",
        filter,
        search,
        send: send ? send : {},
        listName: listName ? listName : "",
      }),
    )
    if (callbackFunction) {
      callbackFunction()
    }
  }

  const handlePageChange = value => {
    if (timerId) {
      clearTimeout(timerId)
    }

    let tempTimer = setTimeout(() => {
      if (value !== metaData.page) {
        dispatch(
          api({
            page: value,
            limit,
            sort: "desc",
            filter,
            search,
            send: send ? send : {},
            listName: listName ? listName : "",
          }),
        )

        if (callbackFunction) {
          callbackFunction()
        }
      }
    }, 500)
    setTimerId(tempTimer)
  }

  useEffect(() => {
    if (
      pageDetails.page === 0 ||
      (Object.keys(pageDetails).length === 0 && tableIndex !== 0)
    ) {
      setTableIndex(0)
      setBtnNo([1, 2, 3])
      // handleFirstBtn()
    } else {
      if (tableIndex !== metaData?.page) {
        setTableIndex(metaData?.page)
      }
    }
  }, [pageDetails])

  return (
    <div className='pagination-container'>
      <div className='pagination-box flex-center'>
        <button
          className='pagBTN flex-center'
          onClick={() => {
            if (tableIndex > 0) {
              handlePageChange(tableIndex - 1)
            }
            handleChange(tableIndex)
          }}
        >
          <span className='material-symbols-outlined small'>west</span>
        </button>
        {new Array(metaData?.totalPages).fill(undefined).length <= 3 ? (
          new Array(metaData?.totalPages)
            .fill(undefined)
            ?.map((item, index) => {
              return (
                <button
                  key={index}
                  className={index === tableIndex ? "pagBTN active" : "pagBTN"}
                  onClick={() => {
                    setTableIndex(index)
                    handlePageChange(index)
                  }}
                >
                  {index + 1}
                </button>
              )
            })
        ) : new Array(metaData?.totalPages).fill(undefined).length > 3 &&
          tableIndex + 1 <
            new Array(metaData?.totalPages).fill(undefined).length - 1 ? (
          <>
            {btnNo?.map((item, i) => {
              return (
                <button
                  key={i}
                  className={
                    item === tableIndex + 1 ? "pagBTN active" : "pagBTN"
                  }
                  onClick={() => {
                    handleChange(item)
                    handlePageChange(item - 1)
                  }}
                >
                  {item}
                </button>
              )
            })}{" "}
            <span className='page-separation'>...</span>
            <button
              className={
                tableIndex ===
                new Array(metaData?.totalPages).fill(undefined).length - 1
                  ? "pagBTN active"
                  : "pagBTN"
              }
              onClick={handleLastBtn}
            >
              {new Array(metaData?.totalPages).fill(undefined).length}
            </button>
          </>
        ) : (
          <>
            <button
              className={tableIndex === 0 ? "pagBTN active" : "pagBTN"}
              onClick={handleFirstBtn}
            >
              1
            </button>{" "}
            <span className='page-separation'>...</span>
            {[
              new Array(metaData?.totalPages).fill(undefined).length - 2,
              new Array(metaData?.totalPages).fill(undefined).length - 1,
              new Array(metaData?.totalPages).fill(undefined).length,
            ]?.map((item, i) => {
              return (
                <button
                  key={i}
                  className={
                    item === tableIndex + 1 ? "pagBTN active" : "pagBTN"
                  }
                  onClick={() => {
                    handleChange(item)
                    handlePageChange(item - 1)
                  }}
                >
                  {item}
                </button>
              )
            })}{" "}
          </>
        )}
        <button
          className='pagBTN  flex-center'
          onClick={() => {
            if (
              tableIndex <
              new Array(metaData?.totalPages).fill(undefined).length - 1
            ) {
              handlePageChange(tableIndex + 1)
              handleChange(tableIndex + 2)
            }
          }}
        >
          <span className='material-symbols-outlined small'>east</span>
        </button>
      </div>
    </div>
  )
}

export default Pagination
