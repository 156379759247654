/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import Sidebar from "../../../components/Sidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import { Link, useNavigate, useParams } from "react-router-dom"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import ReactQuill from "react-quill"
import { useFormik } from "formik"
import * as Yup from "yup"
import { getEmailDetail, updateSingleEmail } from "../redux/EmailApi"
import { ClipLoader } from "react-spinners"
import FormButtons from "../../../components/SubmitBtns"
import Loader from "../../../components/Loader"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
// import Breadcrumb from "../../../components/BreadCrumbs";

const EditEmail = ({ setTitle }) => {
  const [active, setActive] = useState("email")
  // const [action, setAction] = useState(false);
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [focusedField, setFocusedField] = useState()
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const { emailDetails, emailLoading } = useSelector(store => store.email)
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  const [activeInputs, setActiveInputs] = useState(false)

  useEffect(() => {
    setTitle("Edit Email")
    formik.setFieldValue("subject", emailDetails?.subject)
    formik.setFieldValue("title", emailDetails?.title)
    formik.setFieldValue("email_text", emailDetails?.body)
  }, [setTitle, emailDetails])

  useEffect(() => {
    dispatch(getEmailDetail({ slug: id }))
  }, [dispatch, id])

  const profileSchema = Yup.object({
    subject: Yup.string()
      .trim()
      .max(80, "Subject must be less than 80 characters")
      .required("Subject is required"),
    title: Yup.string()
      .trim()
      .max(80, "Title must be less than 80 characters")
      .required("Title is required"),
    email_text: Yup.string().required("Email description is required"),
  })

  const formik = useFormik({
    initialValues: {
      subject: "",
      title: "",
      email_text: "",
    },
    validationSchema: profileSchema,
    onSubmit: async values => {
      const data = {
        send: {
          title: values?.title,
          subject: values?.subject,
          body: values?.email_text,
        },
        slug: emailDetails?.slug,
      }

      const response = await dispatch(updateSingleEmail(data))

      if (response.payload) {
        navigate("/email")
      }
    },
  })

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", { list: "ordered" }, { list: "bullet" }], // toggled buttons
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  ]
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  const { tableLoading: isEmailLoading } = useLoaderDelay({
    loadingState: emailLoading,
  })

  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>
                    Edit Email Template
                    {/* Updating Emails: Strategies for Building Lasting Relationships */}
                  </div>
                  <div className='mch-path'>
                    {/* <Breadcrumb /> */}
                    <div className='breadcrumbs_container'>
                      <Link to='/' className='flex-center'>
                        {/* <span className="material-symbols-rounded medium">
                          home
                        </span> */}
                        Dashboard
                      </Link>
                      <div className='breadcrumbs'>
                        <span>
                          {"/"} <Link to={"/email"}>Emails</Link>
                        </span>{" "}
                        <span>/ Edit Email</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mch2'>
                  {/* <div className="mch2-duration">
                    August 1,2020 - August 31,2020
                  </div>
                  <div
                    className="mch2-action"
                    onClick={() => setAction(!action)}
                  >
                    <span className="">Action</span>
                    <span className="material-symbols-rounded expand">
                      expand_more
                    </span>
                    {action && (
                      <div className="action-tooltip">
                        <span className="">Download</span>
                        <span className="">Print</span>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
              <div className='main-box-padding'>
                <div
                  className='gap-4'
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div className='add-privileges'></div>
                  {Object.values(privileges["email_management"]).includes(
                    "write",
                  ) ? (
                    activeInputs ? (
                      <button
                        type='button'
                        onClick={e => {
                          e.preventDefault()
                          setActiveInputs(false)
                        }}
                        className='btn-secondary'
                        disabled={emailLoading}
                        style={emailLoading ? { cursor: "not-allowed" } : {}}
                      >
                        {emailLoading ? (
                          <ClipLoader
                            loading={true}
                            size={13}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                          />
                        ) : (
                          "Abort"
                        )}
                      </button>
                    ) : (
                      <button
                        type='button'
                        onClick={e => {
                          e.preventDefault()
                          setActiveInputs(true)
                        }}
                        className='btn-primary'
                      >
                        <span className='material-symbols-outlined small'>
                          border_color
                        </span>{" "}
                        EDIT
                      </button>
                    )
                  ) : null}
                </div>
                <div className='form-content'>
                  <form className='' onSubmit={formik.handleSubmit}>
                    <div className='form-group form'>
                      <div
                        className={`input-field f-contents ${
                          formik.values.title !== "" || focusedField === "title"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='company_name' className='toggle-label'>
                          Title
                        </label>
                        <input
                          type='text'
                          name='title'
                          id='title'
                          maxLength={81}
                          className='form-input'
                          value={formik.values.title || ""}
                          onChange={formik.handleChange}
                          // onKeyDown={(e) => {
                          //   if (e.key === " ") {
                          //     e.preventDefault();
                          //   }
                          // }}

                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("title")}
                          disabled={!activeInputs}
                        ></input>{" "}
                        {formik.errors.title && formik.touched.title ? (
                          <span className='err'>{formik.errors.title}</span>
                        ) : null}
                      </div>{" "}
                      <div
                        className={`input-field f-contents ${
                          formik.values.subject !== "" ||
                          focusedField === "company"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor='company_name' className='toggle-label'>
                          Subject
                        </label>
                        <input
                          type='text'
                          name='subject'
                          id='subject'
                          className='form-input'
                          value={formik.values.subject || ""}
                          onChange={formik.handleChange}
                          maxLength={81}
                          // onKeyDown={(e) => {
                          //   if (e.key === " ") {
                          //     e.preventDefault();
                          //   }
                          // }}

                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          disabled={!activeInputs}
                          onFocus={() => setFocusedField("company")}
                        ></input>
                        {formik.errors.subject && formik.touched.subject ? (
                          <span className='err'>{formik.errors.subject}</span>
                        ) : null}
                      </div>
                      <div className=' f-contents '>
                        <div className='text-editor'>
                          <ReactQuill
                            modules={{ toolbar: toolbarOptions }}
                            readOnly={!activeInputs}
                            theme='snow'
                            value={formik.values.email_text || ""}
                            onChange={v =>
                              formik.setFieldValue("email_text", v)
                            }
                          />
                        </div>{" "}
                        {formik.errors.email_text &&
                        formik.touched.email_text ? (
                          <span className='err'>
                            {formik.errors.email_text}
                          </span>
                        ) : null}
                      </div>{" "}
                      {/* <div className='f-footer'>
                        <button
                          type='button'
                          className='btn-secondary'
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>
                        <button
                          type='submit'
                          className='btn-primary'
                          style={
                            emailLoading
                              ? {
                                  cursor: "not-allowed",
                                  textAlign: "center",
                                  // background: "gray",
                                }
                              : {}
                          }
                          disabled={emailLoading}
                        >
                          {emailLoading ? (
                            <ClipLoader
                              loading={true}
                              size={15}
                              aria-label='Loading Spinner'
                              data-testid='loader'
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div> */}
                      <FormButtons
                        cancelBtnText='Back'
                        loading={emailLoading}
                        abortHandleClick={() => navigate("/email")}
                        disabled={!activeInputs}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Footer />{" "}
          </div>
          <Loader isLoading={isEmailLoading}/>
        </div>
      </div>
    </div>
  )
}

export default EditEmail
