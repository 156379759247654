import CryptoJS from "crypto-js"
import { toast } from "react-toastify"

// it accepts file = event.target.files[0]
export const convertToWebP = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const img = new Image()

      img.onload = () => {
        const canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height

        const ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)

        canvas.toBlob(blob => {
          const convertedFile = new File([blob], `${Date.now()}.webp`, {
            type: "image/webp",
          })
          resolve(convertedFile)
        }, "image/webp")
      }

      img.src = reader.result
    }

    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const yyyyMMDDToISO = yyyyMMDDDate => {
  // Split the date string into year, month, and day components
  const [year, month, day] = yyyyMMDDDate.split("-")

  // Convert date components to milliseconds, assuming UTC
  const timestamp = new Date(
    Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day)),
  ).getTime()

  // Create a Date object from the milliseconds
  const date = new Date(timestamp)

  // Format the date object to ISO format
  const formattedDate = date.toISOString().replace(/T00:00:00Z/, "")
  return formattedDate
}

export const options = {
  // position: toast.POSITION.TOP_RIGHT,
  // autoClose: 3000,
  // icon: true,
  // closeButton: false,
}

export const IpAddress = async () => {
  try {
    const response1 = await fetch(`${process.env.REACT_APP_IP_Address1}`)
    const data1 = await response1.json()
    const [latitude, longitude] = data1.loc.split(",")
    const Values = {
      latitude: latitude,
      longitude: longitude,
      ip: data1?.ip,
    }
    return Values
  } catch (error) {
    try {
      const response2 = await fetch(`${process.env.REACT_APP_IP_Address2}`)
      const data2 = await response2.json()
      const Values = {
        latitude: data2?.latitude,
        longitude: data2?.longitude,
        ip: data2?.IPv4,
      }
      return Values
    } catch (error) {
      // If both APIs fail, return static data
      const staticData = {
        latitude: "",
        longitude: "",
        ip: "00.00.00.00",
      }
      return staticData
    }
  }
}

export const unixTimeToYYYYMMDD = unixTimestamp => {
  const date = new Date(unixTimestamp)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Pad the month with a leading zero if necessary
  const day = String(date.getDate()).padStart(2, "0") // Pad the day with a leading zero if necessary

  // Combine the components into the desired format
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export const incrptPSW = async password => {
  const key = Array.from({ length: 64 }, () =>
    "0123456789abcdefghijklmnopqrstuvwxyz".charAt(
      Math.floor(Math.random() * 36),
    ),
  ).join("")
  const decrypt = CryptoJS.AES.encrypt(password, key).toString()
  return { decrypt, key }
}

export const decryptPSW = (password, key) => {
  const bytes = CryptoJS.AES.decrypt(password, key)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const getDeviceId = () => {
  const today = new Date()
  const day = today.getDate()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  const newDate = new Date(`${year}-${month}-${day}`)
  let uid = newDate.getTime()
  uid += navigator.mediaDevices?.enumerateDevices?.length
  uid += navigator.userAgent.replace(/\D+/g, "")
  uid += window.screen.height || ""
  uid += window.screen.width || ""
  uid += window.screen.pixelDepth || ""

  return uid
}
// export const options = {
// //   position: toast.POSITION,
//   autoClose: 5000,
//   // autoClose : false,
//   icon: false,
//   // icon: true,
//   hideProgressBar: true,
//   closeOnClick: true,
//   draggable: true,
//   pauseOnHover: false,
//   style: {
//     backgroundColor: "#000000",
//     // borderRadius: "20px",
//     color: "white",
//   },
//   // closeButton: false,
//   closeButton: (
//     <span
//      className="material-symbols-outlined"
//       style={{
//         color: "white",
//         fontSize: "1.2rem",
//         display: "flex",
//         alignItems: "center",
//       }}
//     >
//       close
//     </span>
//   ),
// };

//! animation function //
export const handleAnimation = (
  setAddStyle,
  addStyle,
  setShowComponent,
  showComponent,
  delay = 200,
) => {
  setAddStyle(!addStyle)
  let styleTimer
  if (showComponent) {
    styleTimer = setTimeout(() => {
      setShowComponent(false)
    }, delay)
  } else {
    setShowComponent(true)
  }
  return () => clearTimeout(styleTimer)
}
//! animation function ends//

export const AddNumField = async (Mod, startNumbering) => {

  return new Promise((resolve, reject) => {
    try {
      const newData = Mod.data.map((item, index) => {
        return { ...item, num: startNumbering + 1 + index }
      })
      const updatedMod = { ...Mod, data: newData }
      resolve(updatedMod)
    } catch (error) {
      reject(error)
    }
  })
}

export const AddNumFieldCompany = async (Mod, startNumbering) => {
  return new Promise((resolve, reject) => {
    try {
      const newData = Mod.map((item, index) => {
        return { ...item, num: startNumbering + 1 + index }
      })
      const updatedMod = { ...Mod, data: newData }
      resolve(updatedMod)
    } catch (error) {
      reject(error)
    }
  })
}

export const unixTimeToReadableFormat = unixTimestamp => {
  // Create a Date object from the Unix timestamp
  const date = new Date(unixTimestamp)

  // Extract the day, month, and year components
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  // Convert the month number to the corresponding month name
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const monthName = monthNames[month]

  // Format the date in the desired format
  const formattedDate = `${day} ${monthName}, ${year}`

  return formattedDate
}

export const formatTimestamp = timestamp => {
  const date = new Date(timestamp)

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }

  const day = date.getDate()
  const month = date.toLocaleString("default", { month: "long" })
  const year = date.getFullYear()

  const timeString = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })

  return `${day} ${month}, ${year}, ${timeString}`
}

// ! to make api calls dynamically

export const apiCall = async (props = {}) => {
  const listName = props?.listName ? props?.listName : ""
  const customState = props?.customState ? props?.customState : ""
  const setCustomSetter = props?.setCustomSetter
    ? props.setCustomSetter
    : () => { }
  const api = props?.api ? props.api : () => { }
  const dispatch = props?.dispatch ? props.dispatch : () => { }
  const optionsName = props?.optionsName ? props?.optionsName : ""
  const dynamicLabel = props?.dynamicLabel ? props?.dynamicLabel : "name"
  const dynamicValue = props?.dynamicValue ? props?.dynamicValue : "_id"
  const dynamicApiLabel = props?.dynamicApiLabel ? props?.dynamicApiLabel : ""
  const formikLabel = props?.formikLabel ? props?.formikLabel : ""
  const formikValues = props?.formikValues ? props?.formikValues : {}
  if (listName) {
    const response = await dispatch(
      api({
        listName,
        search: "",
        sort: "desc",
        limit: "0",
        page: "0",
        send:
          formikLabel && dynamicApiLabel
            ? {
              [dynamicApiLabel]: formikValues[formikLabel],
            }
            : undefined,
      }),
    )
    if (optionsName) {
      const options = response?.payload?.map(item => {
        return {
          label: item[dynamicLabel],
          value: item[dynamicValue],
        }
      })
      setCustomSetter({ ...customState, [optionsName]: options })
    }
  }
}

export const convertNumberToRupees = number => {
  let format = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  })
  // for 4800 INR

  return format.format(number)
}

export const convertRupeesToNumber = rupees =>
  rupees.replaceAll(",", "").replace("₹", "")

export function numberWithCommas(x) {
  x = String(x)
  return x.toString().split(".")[0].length > 3
    ? x
      .toString()
      .substring(0, x.toString().split(".")[0].length - 3)
      .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    "," +
    x.toString().substring(x.toString().split(".")[0].length - 3)
    : x.toString()
}

export function calPrice(arr) {
  let totalPrice = 0
  let totalQuantity = 0
  try {
    arr.forEach(item => {
      let { unitType, unit, price } = item
      // unitType = "litre"
      if (typeof unit === "string") {
        unit = Number(unit.startsWith(".") ? unit.replace(".", "0.") : unit)
      }
      if (typeof price === "string") {
        price = Number(price)
      }

      switch (unitType) {
        case "ml":
        case "g":
          let convertedToLiter = unit / 1000 // this will convert ml to litre 1000ml=1liter
          totalQuantity = convertedToLiter + totalQuantity
          totalPrice = convertedToLiter * price + totalPrice
          // code

          break

        case "mg":
          let convertedToLiterTwo = unit / 100000 // this will convert ml to litre 1000ml=1liter
          totalQuantity = convertedToLiterTwo + totalQuantity
          totalPrice = convertedToLiterTwo * price + totalPrice
          break

        case "litre":
        case "kg":
          totalPrice = unit * price + totalPrice
          totalQuantity = unit + totalQuantity
          break
        default:
          break
      }
    })
  } catch (error) {
    toast.error("please provide all information")
  }

  totalPrice = totalPrice.toFixed(2)
  totalQuantity = Number(totalQuantity).toFixed(2)
  return { totalPrice, totalQuantity }
}

// const arr = [
//   { productName: "gold color", unit: 5, unitType: "litre", price: 100 },
//   { productName: "red color", unit: 300, unitType: "g", price: 100 },
//   { productName: "blue color", unit: 600, unitType: "ml", price: 100 },
//   { productName: "yellow color", unit: 200, unitType: "ml", price: 1200 },
// ]

//

export const priceValidator = str => {
  str = String(str)
  let firstPeriod = false
  let newStr = str.replace(/[^\d .+]/gi, "").replace(/[.]/g, function (match) {
    if (match === "." && !firstPeriod) {
      firstPeriod = true
      return "."
    }
    return ""
  })
  let [firstNum, secondNum] = newStr.split(".")
  if (secondNum) {
    secondNum = secondNum.slice(0, 2)
    return `${firstNum}.${secondNum}`
  }
  return newStr
}

export const findValueFromPercentage = ({
  percentage = 0,
  value = "",
  returnTotalValue = false,
  packSize = 1,
}) => {
  if (typeof value === "string" && value.includes(",")) {
    value = convertRupeesToNumber(value)
  }

  if (returnTotalValue) {
    return priceValidator(
      String(
        ((Number(percentage) / 100) * Number(value) +
          (returnTotalValue ? Number(value) : 0)) /
        packSize,
      ),
    )
  } else {
    return String((Number(percentage) / 100) * Number(value))
  }
}

export const removePhoneNoCountryCode = value => {
  if (value) {
    value = value.toString()
    return value?.slice(-10)
  }
}

export const convertToLitre = ({ value = 0, unit = "", wight = 0 }) => {
  let convertedValue = Number(value)
  wight = Number(wight)

  switch (unit) {
    case "ml":
    case "g":
      convertedValue = (value / 1000).toFixed(2)

      break
    case "mg":
      convertedValue = (value / 100000).toFixed(2)
      break

    case "litre":
    case "kg":
      convertedValue = convertedValue.toFixed(2)
      break

    case "pack":
      convertedValue = (wight * value).toFixed(2)
      break

    default:
      break
  }

  return convertedValue
}

//?

export const errorShakeAnimation = ({ id, showBorder = true }) => {
  const element = document.getElementById(id)

  element.classList.add("error-shake-animation")
  if (showBorder) {
    element.classList.add("error-border")
  }
  const shaker = setTimeout(function () {
    element.classList.remove("error-shake-animation")
    if (element.classList.contains("error-border")) {
      element.classList.remove("error-border")
    }
  }, 1000)

  return () => clearTimeout(shaker)
}
export function findProductType(productType) {
  return `${productType === "powder" || productType === "putty" ? "kg" : productType === "sheet" ? "Number" : productType === "paint" ? "litre" : ""}`
}

export const focusOnElement = id => {
  setTimeout(() => {
    if (id && document.getElementById(id)) {
      document.getElementById(id).focus()
    }
  }, 220)
}

export const capitalizeInput = e => {
  e.target.value = e.target.value.replace(/(?:^|\s)\S/g, match =>
    match.toUpperCase(),
  )
}

export const filterNumbersRegex = e => {
  e.target.value = e.target.value.replace(/[^\d ]/gi, "")
}

export const createOrderFormData = (
  data,
  status = "pending",
  key = "purchaseOrder",
  orderNumber,
) => {
  return new Promise((res, rej) => {
    let FormD = new FormData()

    const formObj = {
      ...data?.addressDetails?.AddressDetails,
      ...data?.grandTotals,
      comment: data?.additionalCharges?.comment,
      status,
      isDeleted: false,
      primaryDocumentDetails:
        key === "purchaseOrder"
          ? JSON.stringify(data?.primaryDetails)
          : data?.primaryDetails,
      orderDate: data?.primaryDetails?.orderDate,
      items:
        key === "purchaseOrder"
          ? JSON.stringify(data?.productDetails.rows)
          : data?.productDetails.rows,
      company_signature: data?.additionalCharges?.company_signature,
      extraCharge:
        key === "purchaseOrder"
          ? JSON.stringify(data?.additionalCharges?.extraCharges?.rows)
          : data?.additionalCharges?.extraCharges?.rows,
    }

    if (key == "purchaseReturn") {
      formObj.orderNumber = data?.orderNumber
    }

    if (key === "purchaseOrder") {
      for (let [key, value] of Object.entries(formObj)) {
        FormD.append(key, value)
      }
      res(FormD)
    } else {
      res(formObj)
    }
  })
}


export const numberToWords = (num) => {
  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  const thousands = ['', 'thousand', 'million', 'billion'];

  let words = '';
  let i = 0;
  let numStr = num.toString();

  while (numStr.length > 0) {
    if (numStr.length >= 4) {
      let chunk = numStr.substring(numStr.length - 3);
      numStr = numStr.substring(0, numStr.length - 3);
      words = convertChunk(chunk) + (numStr.length > 0 ? ' ' + thousands[i] + ' ' : '') + words;
      i++;
    } else {
      words = convertChunk(numStr) + words;
      numStr = '';
    }
  }

  return words.trim();

  function convertChunk(chunk) {
    let words = '';
    if (chunk.length === 3) {
      if (chunk[0] !== '0') {
        words += ones[chunk[0]] + ' hundred';
        if (chunk[1] !== '0' || chunk[2] !== '0') {
          words += ' and ';
        }
      }
      chunk = chunk.substring(1);
    }
    if (chunk.length === 2) {
      if (chunk[0] === '1') {
        words += teens[chunk[1]];
      } else {
        words += tens[chunk[0]];
        if (chunk[1] !== '0') {
          words += '-' + ones[chunk[1]];
        }
      }
    } else {
      words += ones[chunk[0]];
    }
    return words;
  }
}