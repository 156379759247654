import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import SearchBox from "../../../components/SearchBox"
import Skelaton from "../../../components/Skelaton"
import { useLocation, useParams } from "react-router-dom"
import { getProductDetail } from "../redux/PurchaseApi"
import { ClipLoader } from "react-spinners"
import {
  numberWithCommas,
  priceValidator,
  unixTimeToYYYYMMDD,
} from "../../../helper/helper"
import { toast } from "react-toastify"

const PurchaseProductsdetail = ({
  formData,
  setFormData,
  setGrandTotals,
  grandTotals,
  purchaseApiDetails,
}) => {
  const [addStyle, setAddStyle] = useState(true)
  // const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { fromList, toList, stockProductLoading } = useSelector(
    store => store.stock,
  )
  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const { warehousesList } = useSelector(store => store.company)

  const [fromStore, setFromStore] = useState("")

  const [currentSelectedProduct, setCurrentSelectedProduct] = useState("")
  const [hsnList, setHsnList] = useState([])
  const [focusedField, setFocusedField] = useState()
  const [toggleState, setToggleState] = useState(false)
  const location = useLocation()
  const [showHsnBox, setShowHsnBox] = useState(false)

  const isPurchaseOrder =
    location?.pathname === "/purchase/purchase-order" ? true : false

  const dispatch = useDispatch()
  const { purchaseLoading, supplierAddress, warehouseAddress } = useSelector(
    store => store.purchase,
    shallowEqual,
  )

  // const formik = useFormik({
  //   initialValues: {
  //     rows: [
  //       {
  //         sku_num: "",
  //         product_name: "",
  //         product_code: "",
  //         hsn_code: "",
  //         category: "",
  //         packQuantity: "",
  //         totalQuantity: "",
  //         noOfPacks: "",
  //         packSize: "",
  //         taxableAmount: "",
  //         taxes: "",
  //         totalAmount: "",
  //         additional_details: "",
  //         deliveryDate: "",
  //       },
  //     ],
  //   },
  //   validationSchema: Yup.object().shape({
  //     rows: Yup.array().of(
  //       Yup.object().shape({
  //         sku_num: Yup.string().required("SKU is required"),
  //         product: Yup.string().required("Product Name is required"),
  //         quantity: Yup.string().required("Quantity is required"),
  //         productCode: Yup.string().required("Product code is required"),
  //         package: Yup.string().required("Pack Size is required"),
  //         uom: Yup.string().required("UOM is required"),
  //         deliveryDate: Yup.string().required("Delivery date is required"),
  //       }),
  //     ),
  //   }),
  //   onSubmit: async values => {
  //     const options = values.rows.map((item, index) => {
  //       return {
  //         product_id: item.productId,
  //         quantity: item.quantity,
  //         price: item.unit,
  //       }
  //     })
  //     const data = {
  //       products: options,
  //       sourceLocation: fromStore,
  //       destinationLocation: toStore,
  //     }

  //     //   const response = await dispatch(stockMovement(data))
  //     //   if (response.payload) {
  //     //     dispatch(
  //     //       getStockHistoryList({
  //     //         search: "",
  //     //         page: "0",
  //     //         limit: 5,
  //     //         sort: "desc",
  //     //       }),
  //     //     )
  //     //     onSave(values.rows)
  //     //     dispatch(removeToAndFromLists())
  //     //     handleClick()
  //     //   }
  //   },
  // })

  //
  // const addRow = () => {
  //   const newRow = {
  //     sku_num: "",
  //     product: "",
  //     quantity: "",
  //     productCode: "",
  //     packSize: "",
  //     uom: "",
  //     deliveryDate: "",
  //   }
  //   formik.setFieldValue("rows", [...formik.values.rows, newRow])
  // }

  // const deleteRow = index => {
  //   const rows = [...formik.values.rows]
  //   rows.splice(index, 1)
  //   formik.setFieldValue("rows", rows)
  // }

  // Define common fields and validation
  const commonFields = {
    sku_num: "",
    product_name: "",
    product_code: "",
    hsn_code: "",
    packQuantity: "",
    totalQuantity: "",
    noOfPacks: "",
    packSize: "",
    totalAmount: "",
    additional_details: "",
    deliveryDate: "",
    selectedProduct: "",
    productId: "",
    sellingPrice: "",
    gst: "",
    unit: "",
    hsn: "",
    status: { status: "pending", reason: "", comment: "" },
  }

  // Define purchase-order specific fields
  const purchaseOrderFields = {
    category: "",
    taxableAmount: "",
    taxes: "",
  }

  // Define purchase-return specific fields
  const purchaseReturnFields = {
    deliverd_packs: "",
    purchase_returnQty: "",
    batchNo: "",
  }

  const getInitialValues = () => {
    const specificFields = isPurchaseOrder
      ? purchaseOrderFields
      : purchaseReturnFields
    return {
      rows: [{ ...commonFields, ...specificFields }],
    }
  }

  const getValidationSchema = isPurchaseOrder => {
    const commonValidationSchema = {
      sku_num: Yup.string().required("SKU is required"),
      product_name: Yup.string().required("Product Name is required"),
      deliveryDate: Yup.string().required("Delivery date is required"),
      product_code: Yup.string().required("Product code is required"),
      hsn_code: Yup.string().required("HSN code is required"),
      packQuantity: Yup.string().required("Pack Quantity is required"),
      totalQuantity: Yup.string().required("Total Quantity is required"),
      packSize: Yup.string().required("Pack Size is required"),
      noOfPacks: Yup.string().required("no. of pack is required"),
      totalAmount: Yup.string().required("Total amount is required"),
      // additional_details: Yup.string().required(
      //   "Additional details is required",
      // ),
    }

    const purchaseOrderValidationSchema = {
      category: Yup.string().required("Category is required"),
      taxableAmount: Yup.string().required("Amount is required"),
      taxes: Yup.string().required("Tax is required"),
    }

    const purchaseReturnValidationSchema = {
      deliverd_packs: Yup.string().required("This field is required"),
      purchase_returnQty: Yup.string().required("Return quantity is required"),
      purchase_returnQty: Yup.string().required("Batch No is required"),
    }

    const specificValidationSchema = isPurchaseOrder
      ? purchaseOrderValidationSchema
      : purchaseReturnValidationSchema

    return Yup.object().shape({
      rows: Yup.array().of(
        Yup.object().shape({
          ...commonValidationSchema,
          ...specificValidationSchema,
        }),
      ),
    })
  }

  const formik = useFormik({
    initialValues: getInitialValues(isPurchaseOrder),
    validationSchema: getValidationSchema(isPurchaseOrder),
    onSubmit: async values => {
      // Your onSubmit logic here
    },
  })

  const addRow = () => {
    const specificFields = isPurchaseOrder
      ? purchaseOrderFields
      : purchaseReturnFields
    formik.setFieldValue("rows", [
      ...formik.values.rows,
      { ...commonFields, ...specificFields },
    ])
  }

  const deleteRow = index => {
    const rows = [...formik.values.rows]
    rows.splice(index, 1)
    formik.setFieldValue("rows", rows)
  }
  // useEffect(() => {
  //   formik.setFieldValue("rows", [
  //     {
  //       sku_num: stockList[0]?.sku,
  //       product: stockList[0]?.name,
  //       uom: stockList[0]?.uom,
  //       unit: stockList[0]?.unitPrice,
  //     },
  //   ])
  // }, [stockList, formik.values.from])

  //

  // useEffect(() => {
  //   if (searchTimeout) {
  //     clearTimeout(searchTimeout)
  //   }

  //   const timeoutId = setTimeout(async () => {
  //     if (true) {
  //       const response = await dispatch(
  //         getStockList({ search: formik.values.hsnCode }),
  //       )
  //       setHsnList(response.payload)
  //     }
  //   }, 1000)

  //   setSearchTimeout(timeoutId)
  // }, [formik.values])
  const [searchTimeout, setSearchTimeout] = useState(null)
  const callApi = async (value, index) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    const timeoutId = setTimeout(async () => {
      if (value) {
        const response = await dispatch(
          getProductDetail({
            search: value,
            companyId: supplierAddress?.companyId
              ? supplierAddress?.companyId
              : "",
          }),
        )
        setHsnList(response?.payload?.data?.data)
      }
    }, 1000)
    setSearchTimeout(timeoutId)
  }

  const [search, setSearch] = useState("")

  const checkValue = valueList => {
    return valueList.length === 0 ? true : false
  }
  useEffect(() => {
    if (
      !formData?.productDetails &&
      checkValue(Object.keys(formik.errors)) &&
      formik.values?.rows[0].sku_num
    ) {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))
        newList.productDetails = { ...formik.values }

        return newList
      })
    } else {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))
        newList.productDetails = true
        return newList
      })
    }
  }, [formData?.productDetails])

  const findType = row => {
    return row?.selectedProduct?.uom ? row?.selectedProduct?.uom : ""
  }

  useEffect(() => {
    let grandTax = 0
    let grandSubtotal = 0
    let grandTotal = 0
    formik.values.rows.forEach(item => {
      const { taxes, totalAmount, taxableAmount } = item
      grandTax += Number(taxes)
      grandSubtotal += Number(taxableAmount)
      grandTotal += Number(totalAmount)
    })
    setGrandTotals(oldValues => {
      let newValues = JSON.parse(JSON.stringify(oldValues))

      newValues.taxes = grandTax
      newValues.subtotal = grandSubtotal
      newValues.grandTotal = grandTotal
      newValues.reservedSubtotal = grandSubtotal

      return newValues
    })
  }, [toggleState])

  useEffect(() => {
    if (purchaseApiDetails) {
      const newFormikData = purchaseApiDetails?.map(item => {
        return {
          sku_num: item?.sku,
          product_name: item?.name,
          product_code: item?.productCode ? item?.productCode : "",
          hsn_code: item?.hsn,
          packQuantity: item?.quantityInPack,
          totalQuantity: item?.totalQuantity,
          noOfPacks: item?.noOfPacks,
          packSize: item?.packsize,
          totalAmount: item?.totalAmount,
          additional_details: "",
          deliveryDate: unixTimeToYYYYMMDD(item?.deliveryDate),
          selectedProduct: { ...item, GST: item?.gst },
          //  {
          //   name: "New ",
          //   productCode: "",
          //   Type: "",
          //   brandId: "",
          //   categoryId: "",
          //   productStandard: "Non-",
          //   sku: "SW-PR--NP/20.",
          //   GST: 5,
          //   HSN: "",
          //   productId: "",
          //   sellingPrice: 3588,
          //   costPrice: 46000,
          //   unitPrice: 2300,
          //   packSize: 20,
          //   quantityInPack: 20,
          //   unit: "",
          //   uom: "",
          //   hsnNumber: "",
          //   categoryName: "",
          //   brandName: "Sherwin ",
          //   margin: 56,
          //   lastSellingPrice: 3588,
          // }

          productId: item?.productId,
          sellingPrice: item?.sellingPrice,
          gst: item?.gst,
          unit: item?.unit,
          hsn: item?.hsnId,
          status: {
            status: item?.status,
            reason: "",
            comment: item?.comment,
          },
          category: item?.categoryName,
          taxableAmount: item?.taxableAmount,
          taxes: item?.taxAmount,
        }
      })

      formik.setValues({
        rows: newFormikData,
      })
    }

    // formik.setValues({
    //   rows: [
    //     {
    //       sku_num: "SW-PR--NP/20.00",
    //       product_name: "New Product",
    //       product_code: "GreenLeaf44",
    //       hsn_code: "1111",
    //       packQuantity: 20,
    //       totalQuantity: 20,
    //       noOfPacks: "1",
    //       packSize: 20,
    //       totalAmount: "48300",
    //       additional_details: "",
    //       deliveryDate: "0011-11-11",
    //       selectedProduct: {
    //         name: "New Product",
    //         productCode: "GreenLeaf44",
    //         Type: "paint",
    //         brandId: "66b744e802f6d01e15105495",
    //         categoryId: "66b7450c02f6d01e151054c2",
    //         productStandard: "Non-Standard",
    //         sku: "SW-PR--NP/20.00",
    //         GST: 5,
    //         HSN: "664420f18f97057837fcf41c",
    //         productId: "66b76abc19d12143e166a44d",
    //         sellingPrice: 3588,
    //         costPrice: 46000,
    //         unitPrice: 2300,
    //         packSize: 20,
    //         quantityInPack: 20,
    //         unit: "66b73435575522b9fc3ebbe0",
    //         uom: "litre",
    //         hsnNumber: "1111",
    //         categoryName: "Primer",
    //         brandName: "Sherwin Williams",
    //         margin: 56,
    //         lastSellingPrice: 3588,
    //       },
    //       productId: "66b76abc19d12143e166a44d",
    //       batchNo: "",
    //       sellingPrice: 3588,
    //       gst: 5,
    //       unit: "66b73435575522b9fc3ebbe0",
    //       hsn: "664420f18f97057837fcf41c",
    //       status: {
    //         status: "pending",
    //         reason: "because it does",
    //         comment: "1",
    //       },
    //       category: "Primer",
    //       taxableAmount: "46000",
    //       taxes: "2300",
    //     },
    //     {
    //       sku_num: "AP-TI--GWC/15",
    //       product_name: "Gold Wall Color",
    //       product_code: "GreenLeaf44",
    //       hsn_code: "11010000",
    //       packQuantity: 14,
    //       totalQuantity: 14,
    //       noOfPacks: "1",
    //       packSize: 15,
    //       totalAmount: "33810",
    //       additional_details: "",
    //       deliveryDate: "0011-11-11",
    //       selectedProduct: {
    //         name: "Gold Wall Color",
    //         description: "",
    //         productCode: "GreenLeaf44",
    //         Type: "putty",
    //         brandId: "66b7455502f6d01e15105597",
    //         categoryId: "66b7455502f6d01e15105599",
    //         productStandard: "Standard",
    //         sku: "AP-TI--GWC/15",
    //         GST: 5,
    //         HSN: "664420f18f97057837fcf382",
    //         productId: "66b7677719d12143e166a219",
    //         sellingPrice: 2645,
    //         costPrice: 34500,
    //         unitPrice: 2300,
    //         packSize: 15,
    //         quantityInPack: 14,
    //         unit: "66b73432575522b9fc3ebbd4",
    //         uom: "kg",
    //         hsnNumber: "11010000",
    //         categoryName: "Tinters",
    //         brandName: "Asian Paints",
    //         margin: 15,
    //         lastSellingPrice: 2645,
    //       },
    //       productId: "66b7677719d12143e166a219",
    //       batchNo: "",
    //       sellingPrice: 2645,
    //       gst: 5,
    //       unit: "66b73432575522b9fc3ebbd4",
    //       hsn: "664420f18f97057837fcf382",
    //       status: {
    //         status: "pending",
    //         reason: "because it does",
    //         comment: "1",
    //       },
    //       category: "Tinters",
    //       taxableAmount: "32200",
    //       taxes: "1610",
    //     },
    //   ],
    // })
  }, [purchaseApiDetails?.length])

  return (
    <>
      <div
        className='main-box-padding data-table-container'
        style={{ padding: "1.8rem", borderRadius: "1.5rem" }}
      >
        <div className='modelEx_content singleAdd'>
          <div className='profile_personal_details'>
            <div className='col1 heading-tools details-controls'>
              <h2>Product Details</h2>
              <div className='table-search'>
                <div className='search-container'>
                  {" "}
                  <SearchBox customSetter={setSearch} customState={search} />
                  <div className='create-btn-container'>
                    {Object.values(privileges["purchase_management"]).includes(
                      "write",
                    ) && (
                      <button
                        className='btn-secondary header-btn  '
                        onClick={addRow}
                      >
                        Add Products
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {false ? (
              <Skelaton col={4} row={5} />
            ) : true ? (
              <>
                <div className='modelEx_content'>
                  {/* <div className='data-table-container'> */}
                  <form onSubmit={formik.handleSubmit}>
                    <div
                      className='td_table_container'
                      // style={
                      //   formik.values.rows.length === 1
                      //     ? { paddingBottom: "9rem" }
                      //     : formik.values.rows.length === 2
                      //       ? { paddingBottom: "5.4rem" }
                      //       : {}
                      // }
                    >
                      <table className='td_table'>
                        <thead>
                          <tr>
                            <th>SKU</th>
                            <th>Product Name</th>
                            <th>Product Code</th>
                            <th>HSN Code</th>
                            {isPurchaseOrder && <th>Categories</th>}
                            <th>Pack Size</th>
                            <th>Pack Quantity</th>
                            <th>No. of packs</th>
                            <th>Total Quantity</th>
                            <th>Delivery Date</th>
                            {!isPurchaseOrder && (
                              <>
                                <th>Batch No</th>
                                <th>Delivered Packs</th>
                                <th>Purchase Return Qty.</th>
                              </>
                            )}

                            {isPurchaseOrder && (
                              <>
                                <th>Total Before Tax</th>
                                <th>Tax</th>
                              </>
                            )}
                            <th>Total Amount</th>
                            <th>Additional Details</th>
                            <td className='stick-rh' />
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.rows.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].sku_num`}
                                    onChange={formik.handleChange}
                                    value={row.sku_num}
                                    style={{ cursor: "not-allowed" }}
                                    disabled
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.sku_num && formik?.touched?.rows[index]?.sku_num ? "error" : ""}`}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  className='hsnBox'
                                  style={{ width: "300px" }}
                                >
                                  <div
                                    className={`input-field ${
                                      formik.values.product_name !== "" ||
                                      focusedField === "product_name"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <input
                                      // id={`rows[${index}].product`}
                                      name={`rows[${index}].product_name`}
                                      type='text'
                                      // className='form-input td_input'
                                      onChange={e => {
                                        formik.handleChange(e)
                                        callApi(e.target.value, index)
                                        setShowHsnBox(true)
                                      }}
                                      value={row.product_name}
                                      maxLength={81}
                                      onBlur={e => {
                                        formik.handleBlur(e)
                                      }}
                                      onFocus={() => setFocusedField(index)}
                                      className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.sku_num && formik?.touched?.rows[index]?.sku_num ? "error" : ""}`}
                                      disabled={
                                        supplierAddress?.companyId
                                          ? false
                                          : true
                                      }
                                      title={
                                        supplierAddress?.companyId
                                          ? "Search Product Name"
                                          : "Please Select Company Address"
                                      }
                                    />
                                  </div>

                                  {formik.values.rows[index].product_name
                                    .length >= 1 &&
                                    index === focusedField &&
                                    showHsnBox && (
                                      <div className='hsnSelectBox'>
                                        {hsnList?.length > 0 &&
                                        !stockProductLoading ? (
                                          hsnList?.map((item, i) => {
                                            const { _id, name, brandName } =
                                              item
                                            return (
                                              <div
                                                key={i}
                                                className={
                                                  "drop-down-item2" +
                                                  (formik?.values?.rows[index]
                                                    ?.product_name === name
                                                    ? " selectedDropBox"
                                                    : "")
                                                }
                                                onClick={() => {
                                                  formik.setFieldValue(
                                                    `rows[${index}].product_name`,
                                                    name,
                                                  )
                                                  // formik.setFieldValue(
                                                  //   `rows[${index}].productId`,
                                                  //   _id,
                                                  // )

                                                  if (row.noOfPacks) {
                                                    formik.setFieldValue(
                                                      `rows[${index}].noOfPacks`,
                                                      "",
                                                    )
                                                  }
                                                  formik.setFieldValue(
                                                    `rows[${index}].selectedProduct`,
                                                    item,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].sku_num`,
                                                    item.sku,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].product_code`,
                                                    item.productCode,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].category`,
                                                    item.categoryName,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].hsn_code`,
                                                    item.hsnNumber,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].packQuantity`,
                                                    item?.quantityInPack,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].packSize`,
                                                    item?.packSize,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].productId`,
                                                    item?.productId,
                                                  )

                                                  // // formik.setFieldValue(
                                                  // //   `rows[${index}].unit`,
                                                  // //   item.unitPrice,
                                                  // // )
                                                  formik.setFieldValue(
                                                    `rows[${index}].sellingPrice`,
                                                    item.sellingPrice,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].gst`,
                                                    item.GST,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].hsn`,
                                                    item.HSN,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].unit`,
                                                    item.unit,
                                                  )

                                                  setShowHsnBox(false)
                                                  setHsnList([])
                                                }}
                                              >
                                                <span>{`${name} (${brandName})`}</span>
                                              </div>
                                            )
                                          })
                                        ) : (
                                          <div className='drop-down-item2'>
                                            {stockProductLoading ? (
                                              <ClipLoader
                                                loading={true}
                                                size={13}
                                                aria-label='Loading Spinner'
                                                data-testid='loader'
                                              />
                                            ) : (
                                              <span>No Data Found</span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].product_code`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.product_code && formik?.touched?.rows[index]?.product_code ? "error" : ""}`}
                                    value={row.product_code}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].hsn_code`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.hsn_code && formik?.touched?.rows[index]?.hsn_code ? "error" : ""}`}
                                    value={row.hsn_code}
                                    disabled
                                  />
                                </div>
                              </td>
                              {isPurchaseOrder && (
                                <td>
                                  <div className='input-field'>
                                    <input
                                      type='text'
                                      name={`rows[${index}].category`}
                                      onChange={formik.handleChange}
                                      // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                      className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.category && formik?.touched?.rows[index]?.category ? "error" : ""}`}
                                      value={row.category}
                                      disabled
                                    />
                                  </div>
                                </td>
                              )}
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].packSize`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.packSize && formik?.touched?.rows[index]?.packSize ? "error" : ""}`}
                                    value={`${row.packSize} ${findType(row)}`}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].packQuantity`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.packQuantity && formik?.touched?.rows[index]?.packQuantity ? "error" : ""}`}
                                    value={`${row.packQuantity} ${findType(row)}`}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].noOfPacks`}
                                    onChange={e => {
                                      formik.handleChange(e)
                                      const totalQuantity =
                                        formik.values.rows[index]
                                          .selectedProduct?.quantityInPack *
                                        e?.target?.value

                                      const totalBeforeTax = priceValidator(
                                        totalQuantity *
                                          formik.values.rows[index]
                                            .selectedProduct?.unitPrice,
                                      )

                                      const taxes = priceValidator(
                                        totalBeforeTax *
                                          (formik.values.rows[index]
                                            .selectedProduct?.GST /
                                            100),
                                      )

                                      formik.setFieldValue(
                                        `rows[${index}].totalQuantity`,
                                        totalQuantity,
                                      )
                                      formik.setFieldValue(
                                        `rows[${index}].taxableAmount`,
                                        totalBeforeTax,
                                      )
                                      formik.setFieldValue(
                                        `rows[${index}].taxes`,
                                        taxes,
                                      )
                                      formik.setFieldValue(
                                        `rows[${index}].totalAmount`,
                                        priceValidator(
                                          Number(taxes) +
                                            Number(totalBeforeTax),
                                        ),
                                      )
                                      setToggleState(!toggleState)
                                    }}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.noOfPacks && formik?.touched?.rows[index]?.noOfPacks ? "error" : ""}`}
                                    value={row.noOfPacks}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].totalQuantity`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.totalQuantity && formik?.touched?.rows[index]?.totalQuantity ? "error" : ""}`}
                                    value={`${numberWithCommas(row.totalQuantity)} ${row.totalQuantity ? findType(row) : ""}`}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='date'
                                    name={`rows[${index}].deliveryDate`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.deliveryDate && formik?.touched?.rows[index]?.deliveryDate ? "error" : ""}`}
                                    value={row.deliveryDate}
                                    // disabled={
                                    //   fromStore && toStore ? false : true
                                    // }
                                  />
                                </div>
                              </td>
                              {!isPurchaseOrder && (
                                <>
                                  <td>
                                    <div className='input-field'>
                                      <input
                                        type='text'
                                        name={`rows[${index}].batchNo`}
                                        onChange={formik.handleChange}
                                        // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                        className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.batchNo && formik?.touched?.rows[index]?.batchNo ? "error" : ""}`}
                                        value={row.batchNo}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='input-field'>
                                      <input
                                        type='number'
                                        name={`rows[${index}].deliverd_packs`}
                                        onChange={formik.handleChange}
                                        // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                        className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.deliverd_packs && formik?.touched?.rows[index]?.deliverd_packs ? "error" : ""}`}
                                        value={row.deliverd_packs}
                                      />
                                    </div>
                                  </td>

                                  <td>
                                    <div className='input-field'>
                                      <input
                                        type='number'
                                        name={`rows[${index}].purchase_returnQty`}
                                        onChange={formik.handleChange}
                                        // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                        className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.purchase_returnQty && formik?.touched?.rows[index]?.purchase_returnQty ? "error" : ""}`}
                                        value={row.purchase_returnQty}
                                      />
                                    </div>
                                  </td>
                                </>
                              )}
                              {isPurchaseOrder && (
                                <>
                                  <td>
                                    <div className='input-field'>
                                      <input
                                        type='text'
                                        name={`rows[${index}].taxableAmount`}
                                        onChange={formik.handleChange}
                                        // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                        className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.taxableAmount && formik?.touched?.rows[index]?.taxableAmount ? "error" : ""}`}
                                        value={numberWithCommas(
                                          row.taxableAmount,
                                        )}
                                        disabled
                                      />
                                    </div>
                                  </td>

                                  <td>
                                    <div className='input-field'>
                                      <input
                                        type='text'
                                        name={`rows[${index}].taxes`}
                                        onChange={formik.handleChange}
                                        // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                        className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.taxes && formik?.touched?.rows[index]?.taxes ? "error" : ""}`}
                                        value={numberWithCommas(row.taxes)}
                                        disabled
                                      />
                                    </div>
                                  </td>
                                </>
                              )}
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].totalAmount`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.totalAmount && formik?.touched?.rows[index]?.totalAmount ? "error" : ""}`}
                                    value={numberWithCommas(row.totalAmount)}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].status.comment`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.status?.comment && formik?.touched?.rows[index]?.status?.comment ? "error" : ""}`}
                                    value={row.status?.comment}
                                  />
                                </div>
                              </td>
                              <td className='stick-rh'>
                                <button
                                  type='button'
                                  className='icon-btn delete'
                                  onClick={() => deleteRow(index)}
                                  disabled={
                                    formik.values.rows.length === 1
                                      ? true
                                      : false
                                  }
                                >
                                  <span className='material-symbols-outlined medium'>
                                    delete
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className='error-msg'>
                        {formik.errors.rows
                          ? "Please fill the data first!"
                          : ""}
                      </div>
                    </div>
                  </form>
                  {/* </div> */}
                </div>
              </>
            ) : (
              <div className='Nodata-txt'>
                <img
                  src={require("../../../assets/images/nodata.svg").default}
                  alt=''
                  srcSet=''
                />
                No Data Found
              </div>
            )}
            {/* <div className='create-btn-container'>
                {Object.values(privileges["purchase_management"]).includes(
                  "write",
                ) && (
                  <button className='btn-secondary header-btn  ' onClick={addRow}>
                    Add Row
                  </button>
                )}
              </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default PurchaseProductsdetail
