import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit"
import axios from "axios"
import { AddNumField, options } from "../../../helper/helper"
import { toast } from "react-toastify"
import { isOptionsLoading, isProductsToggle } from "./ProductsSlice"

export const getProductsList = createAsyncThunk(
  "products/get-products-list",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/product-list?search=${data?.search}&page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}`,
        method: "POST",
      })

      if (response?.status === 200) {
        if (response?.data?.data.length > 0) {
          const res = await AddNumField(
            response?.data?.data[0],
            data?.page * data.limit,
          )

          return fulfillWithValue(res)
        } else {
          const response = await axios({
            url: `${process.env.REACT_APP_AUTH_URL}/admin/product/product-list?search=${data.search}&page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}`,
            method: "POST",
          })

          if (response?.status === 200) {
            const res = await AddNumField(
              response?.data?.data[0],
              data?.page * data.limit,
            )

            return fulfillWithValue(res)
          } else {
            toast.error(response.data.message)
            return rejectWithValue()
          }
        }
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const getBasePaint = createAsyncThunk(
  "products/get-base-paint-list",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/product-list?search=${data?.search}&page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}`,
        method: "POST",
      })

      if (response?.status === 200) {
        return response?.data?.data[0]?.data
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const addProducts = createAsyncThunk(
  "products/add-products",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/add-product`,
        method: "POST",
        data,
      })

      if (response.status === 200) {
        toast.success(response.data.message)
        dispatch(
          getProductsList({ page: 0, limit: 5, sort: "desc", search: "" }),
        )
        return fulfillWithValue(response.status)
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)
export const createProducts = createAsyncThunk(
  "products/create-products",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/create-product`,
        method: "POST",
        data,
      })

      if (response.status === 200) {
        toast.success(response.data.message)
        dispatch(
          getProductsList({ page: 0, limit: 5, sort: "desc", search: "" }),
        )
        return fulfillWithValue(response.status)
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const getOptionsList = createAsyncThunk(
  "products/get-options-list",
  async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    if (data.listName === "category-list") {
    }
    dispatch(isOptionsLoading(true))
    const baseUrl = `${process.env.REACT_APP_AUTH_URL}/admin/catalouge/${data?.listName}?page=${data?.page ? data?.page : 0}&limit=${data?.limit >= 0 ? data?.limit : 5}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data?.search : ""}`
    try {
      const response = await axios({
        url: baseUrl,
        method: "POST",
        data:
          data?.send
              ? data?.send
              : {},
      })

      if (response?.status === 200) {
        dispatch(isOptionsLoading(false))

        const res = await AddNumField(response?.data, data?.page * data?.limit)

        if (data?.listName === "tinters-list") {
          return response?.data?.data[0]?.data
        } else {
          return res.data
        }
      } else {
        dispatch(isOptionsLoading(false))

        return []
      }
    } catch (error) {
      dispatch(isOptionsLoading(false))
      return rejectWithValue()
    }
  },
)

export const getOptionsListForTable = createAsyncThunk(
  "products/get-options-list-table",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalouge/${data?.listName}?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort}&search=${data?.search}`,
        method: "POST",
        data:
          data?.listName === "tinters-list"
            ? {
                category: "tinters",
              }
            : {},
      })

      if (response?.status === 200) {
        const res = await AddNumField(response?.data, data?.page * data?.limit)

        if (data?.listName === "tinters-list") {
          return response?.data?.data[0]?.data
        } else {
          return res
        }
      } else {
        return []
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const getHsnList = createAsyncThunk(
  "products/get-hsn-list",
  async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
    dispatch(isProductsToggle(true))
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/public/hsn-list?page=0&limit=0&sort=desc&search=${data.search}`,
        method: "POST",
      })

      if (response?.status === 200) {
        dispatch(isProductsToggle(false))
        return fulfillWithValue(response?.data?.data[0].data)
      } else {
        toast.error(response.data.message)
        dispatch(isProductsToggle(false))
        return rejectWithValue()
      }
    } catch (error) {
      dispatch(isProductsToggle(false))
      return rejectWithValue()
    }
  },
)

export const getProductsDetails = createAsyncThunk(
  "products/get-products-details",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/product-detail/${data.id}`,
      })

      if (response.status === 200) {
        return fulfillWithValue(response)
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const deleteProducts = createAsyncThunk(
  "products/delete-products",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    dispatch(isProductsToggle(true))
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/delete-product`,
        data,
      })

      if (response.status === 200) {
        dispatch(isProductsToggle(false))
        // toast.success(response.data.message)
        return response.data.status
      } else {
        dispatch(isProductsToggle(false))
        toast.error(response.data.message)
      }
    } catch (error) {
      dispatch(isProductsToggle(false))
      toast.error(error)
    }
  },
)

export const addComment = createAsyncThunk(
  "products/add-comments",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isProductsToggle(true))

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/admin/comment/add-comment`,
        method: "POST",
        data,
      })

      if (response.status === 200) {
        dispatch(isProductsToggle(false))
        toast.success(response.data.message)
        return response.data.status
      } else {
        dispatch(isProductsToggle(false))
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      dispatch(isProductsToggle(false))
      return rejectWithValue()
    }
  },
)

export const updateBasicDetails = createAsyncThunk(
  "product/update-basic-details",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    
    try {
      dispatch(isProductsToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/${data?.details_type}/${data?.id}`,
        data: data?.send,
      })

      if (response.status === 200) {
        dispatch(isProductsToggle(false))
        dispatch(getProductsDetails({ id: data?.id }))

        toast.success(response?.data?.message, options)

        return response?.data?.status
      } else {
        dispatch(isProductsToggle(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isProductsToggle(false))
      toast.error(err, options)
    }
  },
)

export const deleteCatalogueOptions = createAsyncThunk(
  "products/delete-catalogueOptions",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    dispatch(isProductsToggle(true))
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalouge/${data?.deleteType}/${data?.id}`,
        data: data.send,
      })

      if (response.status === 200) {
        dispatch(isProductsToggle(false))

        dispatch(
          getOptionsListForTable({
            page: 0,
            limit: 5,
            search: "",
            sort: "desc",
            listName: data.listName,
          }),
        )

        return response.data.status
      } else {
        dispatch(isProductsToggle(false))
        toast.error(response.data.message)
      }
    } catch (error) {
      dispatch(isProductsToggle(false))
      toast.error(error)
    }
  },
)
