import { createSlice } from "@reduxjs/toolkit"
import { getSingleUserDetail, getUserList } from "./userApi"

const userSlice = createSlice({
  name: "user",
  initialState: {
    userLoading: false,
    userList: [],
    metaData: [],
    userDetail: [],
  },
  reducers: {
    isUserLoadingToggle: (state, action) =>
      (state = {
        ...state,
        userLoading: action.payload,
      }),
  },
  extraReducers: builder => {
    // getUserList reducers-------------------------
    builder.addCase(getUserList.pending, (state, action) => {
      state.userLoading = true
    })
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.userLoading = false
      state.userList = action.payload.data
      state.metaData = action.payload.metadata[0]
    })
    builder.addCase(getUserList.rejected, (state, action) => {
      state.userLoading = false
      state.userList = []
    })
    // getSingleUserDetail reducers-------------------------
    builder.addCase(getSingleUserDetail.pending, (state, action) => {
      state.userLoading = true
    })
    builder.addCase(getSingleUserDetail.fulfilled, (state, action) => {
      state.userLoading = false
      state.userDetail = action.payload.data
    })
    builder.addCase(getSingleUserDetail.rejected, (state, action) => {
      state.userLoading = false
    })
    // // change profile
    // builder.addCase(getProfileDetails.pending, (state) => {
    //   state.profileLoading = true;
    // });
    // builder.addCase(getProfileDetails.fulfilled, (state, { payload }) => {
    //   state.profileLoading = false;
    //   state.profileDetail = payload.data;
    // });
    // builder.addCase(getProfileDetails.rejected, (state) => {
    //   state.profileLoading = false;
    // });
  },
})

export const { isUserLoadingToggle } = userSlice.actions
export default userSlice.reducer
