/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
// import { deleteEmail, getEmailList } from "../redux/EmailApi";
import Skelaton from "../../../components/Skelaton"
import {
  handleAnimation,
  unixTimeToReadableFormat,
} from "../../../helper/helper"
import AddNewPaint from "../components/AddNewPaint"
import AddNewProduct from "../components/AddNewProduct"
import FilterBox from "../../../components/FilterBox"
import SearchBox from "../../../components/SearchBox"
import {
  deleteProducts,
  getBrandList,
  getProductsList,
} from "../redux/ProductsApi"
import Pagination from "../../../components/Pagination"
import { setPageDetails } from "../../auth/redux/authSlice"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"

const Products = ({ setTitle }) => {
  const { productsList } = useSelector(state => state.products)

  const dispatch = useDispatch()
  useEffect(() => {
    setTitle("Products Management")
  }, [setTitle])
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [active, setActive] = useState("product")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const [isEventCard, setIsEventCard] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [selectAllProducts, setSelectAllProducts] = useState(false)
  const [popUpData, setPopupData] = useState({})

  const [addProduct, setAddProduct] = useState(false)
  const [addPaint, setAddPaint] = useState(false)
  const [collectAllProducts, setCollectAllProducts] = useState([])
  const [filterTable, setFilterTable] = useState({
    description: true,
    brand: true,
    category: true,
    user: true,
    createOn: true,
    Type: true,
  })
  const [data, setData] = useState()
  const { productsLoading, metaData } = useSelector(store => store.products)
  const [searchShow, setSearchShow] = useState(false)

  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      dispatch(
        getProductsList({
          page: pageDetails?.page ? pageDetails?.page : 0,
          limit: records,
          search:
            Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
          sort: "desc",
        }),
      )
      setCheck(true)
      dispatch(setPageDetails({ page: metaData?.page, search }))
      return
    }

    const timeoutId = setTimeout(async () => {
      dispatch(
        getProductsList({ limit: records, page: 0, search, sort: "desc" }),
      )
      setTableIndex(0)
      setBtnNo([1, 2, 3])
    }, 1000)
    dispatch(setPageDetails({ page: metaData?.page, search }))
    setSearchTimeout(timeoutId)
  }, [dispatch, records, search])

  useEffect(() => {
    if (pageDetails?.btnNo) {
      setBtnNo(pageDetails?.btnNo)
    }

    dispatch(setPageDetails({ page: metaData?.page, search }))
  }, [metaData])

  // useEffect(() => {
  //   const updateListData = async () => {
  //     if (emailList?.length > 0) {
  //       const newList = JSON.parse(JSON.stringify(emailList))

  //       newList.forEach(item => {
  //         item["checked"] = false
  //       })
  //       setData(newList)
  //     } else {
  //       setData([])
  //     }
  //   }
  //   updateListData()

  //   if (checkedCollection.length > 0) {
  //     checkedCollection.map(item => {
  //       if (item.page === metaData.page) {
  //         setData(oldList => {
  //           let newList = JSON.parse(JSON.stringify(oldList))
  //           newList.map(data => {
  //             if (item.collection.includes(data._id)) {
  //               data.checked = true
  //             }
  //           })

  //           return newList
  //         })
  //       }
  //     })
  //   }
  // }, [emailList])
  const deleteFunction = title => {
    setPopupData({
      data: {
        is_delete: true,
        product_ids: collectAllProducts,
      },
      title,
    })
    setIsEventCard(true)
  }

  // const [tableLoading, setTableLoading] = useState(false)
  // const [tableCount, setTableCount] = useState(null)

  // useEffect(() => {
  //   if (tableCount) {
  //     clearTimeout(tableCount)
  //   }
  //   const count = setTimeout(() => {
  //     setTableLoading(productsLoading)
  //   }, 1000)
  //   setTableCount(count)
  // }, [productsLoading])

  const { tableLoading } = useLoaderDelay({ loadingState: productsLoading })

  useEffect(() => {
    const idArr = productsList?.map(item => {
      return item._id
    })
    if (selectAllProducts) {
      setCollectAllProducts(idArr)
    }
  }, [selectAllProducts])

  useEffect(() => {
    if (selectAllProducts && collectAllProducts.length === 0) {
      setSelectAllProducts(false)
      setCollectAllProducts([])
      return
    }
  }, [collectAllProducts])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        <div className='home-container'>
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
                api={getProductsList}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
                api={getProductsList}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
              api={getProductsList}
            />
          )}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Product Management</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>
              <div className='data-table-container '>
                <div className='col1 heading-tools'>
                  <h3>Products List</h3>
                  <div className='table-search'>
                    <div className='search-container'>
                      <div className='search-filter-flex'>
                        <SearchBox
                          customSetter={setSearch}
                          customState={search}
                        />

                        <FilterBox
                          customState={filterTable}
                          customSetter={setFilterTable}
                          customPosition={{ right: "0%", left: "auto" }}
                        />
                      </div>
                      {Object.values(
                        privileges["production_management"],
                      ).includes("write") && (
                        <button
                          className='btn-primary header-btn  '
                          onClick={() => setAddProduct(!addProduct)}
                        >
                          Add Product
                        </button>
                      )}{" "}
                      {Object.values(
                        privileges["production_management"],
                      ).includes("write") && (
                        <button
                          className='btn-primary header-btn header-btn-second'
                          onClick={() => setAddPaint(!addPaint)}
                        >
                          Create New Paint
                        </button>
                      )}
                      {Object.values(
                        privileges["production_management"],
                      ).includes("delete") && selectAllProducts ? (
                        <button
                          className='btn-secondary header-btn delete-primary-button'
                          onClick={() => {
                            deleteFunction("all products")
                          }}
                        >
                          <span className='material-symbols-outlined medium'>
                            delete
                          </span>{" "}
                          Delete{" "}
                          {collectAllProducts.length > 1
                            ? "All " + collectAllProducts.length
                            : ""}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton col={6} row={productsList?.length} />
                ) : productsList?.length > 0 ? (
                  <>
                    <div className='data-table-box scrollbar-visible'>
                      <table className='data-table products-table'>
                        <thead>
                          <tr>
                            <th className='tb-head-name padding-07 '>
                              <div>
                                <span>
                                  <input
                                    name='checkbox'
                                    id='checkbox'
                                    type='checkbox'
                                    className='checkbox-animated'
                                    onChange={() =>
                                      setSelectAllProducts(!selectAllProducts)
                                    }
                                    checked={selectAllProducts}
                                  />
                                  <label htmlFor={"checkbox"}>
                                    <span className='filter-check-background'></span>
                                  </label>
                                </span>
                              </div>
                            </th>
                            <th>SKU</th>
                            <th>Product Name</th>
                            {filterTable.description ? (
                              <th>Description</th>
                            ) : null}
                            {filterTable.Type ? <th>Product Type</th> : null}
                            {filterTable.brand ? <th>Brand</th> : null}
                            {filterTable.category ? <th>Category</th> : null}

                            {filterTable.user ? <th>Created By</th> : null}
                            {filterTable.createOn ? <th>Created On</th> : null}

                            {/* {Object.values(
                              privileges["production_management"]
                            ).includes("write") && <th>Action</th>} */}
                          </tr>
                        </thead>

                        <tbody>
                          {productsList?.map((item, index) => {
                            const {
                              sku,
                              name,
                              description,
                              brand,
                              category,
                              user,
                              createdAt,
                              num,
                              _id,
                              Type,
                            } = item

                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  dispatch(
                                    setPageDetails({
                                      ...pageDetails,
                                      btnNo,
                                    }),
                                  )
                                  navigate(`/products/${_id}`)
                                }}
                                className='navig-table-row'
                              >
                                <td
                                  className='tb_data padding-07'
                                  onClick={e => {
                                    e.stopPropagation()
                                  }}
                                >
                                  {/* {num} */}
                                  {selectAllProducts ? (
                                    <span
                                      onChange={
                                        e => e.stopPropagation()
                                        // setSelectAllProducts(
                                        //   !selectAllProducts,
                                        // )
                                      }
                                    >
                                      <input
                                        name={"checkbox" + index}
                                        id={"checkbox" + index}
                                        type='checkbox'
                                        className='checkbox-animated'
                                        onChange={e => {
                                          e.stopPropagation()
                                          setCollectAllProducts(oldList => {
                                            let newList = JSON.parse(
                                              JSON.stringify(oldList),
                                            )

                                            if (newList.includes(_id)) {
                                              newList.splice(
                                                newList.indexOf(_id),
                                                1,
                                              )
                                            } else {
                                              newList.unshift(_id)
                                            }

                                            return newList
                                          })
                                        }}
                                        checked={collectAllProducts.includes(
                                          _id,
                                        )}
                                      />
                                      <label htmlFor={"checkbox" + index}>
                                        <span className='filter-check-background'></span>
                                      </label>
                                    </span>
                                  ) : (
                                    num + "."
                                  )}
                                </td>
                                <td>{sku}</td>
                                <td className=''>
                                  <span className='control-td-width'>
                                    {name}{" "}
                                  </span>
                                </td>
                                {filterTable.description ? (
                                  <td>
                                    {description
                                      ? description
                                      : "No Description"}
                                  </td>
                                ) : null}{" "}
                                {filterTable.Type ? <td>{Type}</td> : null}
                                {filterTable.brand ? <td>{brand}</td> : null}
                                {filterTable.category ? (
                                  <td>{category}</td>
                                ) : null}
                                {filterTable.user ? <td>{user}</td> : null}
                                {filterTable.createOn ? (
                                  <td>{unixTimeToReadableFormat(createdAt)}</td>
                                ) : null}
                                {/* <td className="email-action-box">
                                  {Object.values(
                                    privileges["production_management"]
                                  ).includes("write") && (
                                    <button
                                      className="icon-btn edit"
                                      onClick={() => navigate(`/edit-email/`)}
                                    >
                                      <span className="material-symbols-outlined medium">
                                        border_color
                                      </span>
                                    </button>
                                  )}
                                </td> */}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                    <Pagination
                      sliceName={"products"}
                      search={search}
                      filter={"desc"}
                      api={getProductsList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                    />
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteProducts}
              popUpData={popUpData}
              reFetchApi={getProductsList}
              reData={{ search: "", sort: "desc", limit: 5, page: 0 }}
              customFunction={() => {
                setSelectAllProducts(false)
              }}
            />
          )}{" "}
          {addProduct && (
            <AddNewProduct popup={addProduct} setPopup={setAddProduct} />
          )}{" "}
          {addPaint && <AddNewPaint popup={addPaint} setPopup={setAddPaint} />}{" "}
        </div>
      </div>
    </div>
  )
}

export default Products
