/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import Sidebar from "../../../components/Sidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import Header from "../../../components/Header"
import Breadcrumb from "../../../components/BreadCrumbs"
import Footer from "../../../components/Footer"
import Loader from "../../../components/Loader"
import AddLocations from "../components/AddLocations"
import UpdateLocations from "../components/UpdateLocations"
import ConformationPopup from "../../../components/ConformationPopup"
import {
  capitalizeInput,
  convertRupeesToNumber,
  convertToWebP,
  numberWithCommas,
  priceValidator,
  removePhoneNoCountryCode,
} from "../../../helper/helper"
import { useFormik } from "formik"
import { useParams } from "react-router-dom"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { toast } from "react-toastify"
import {
  changeCompanylogo,
  deleteCompanyAddress,
  getCompanyAddress,
  getCompanyDetail,
  updateCompanyAsync,
  updatePrimaryAddress,
} from "../redux/bNsApi"
import * as Yup from "yup"
import logo from "../../../assets/images/festival.webp"
import ImagePreviewBox from "../../../components/ImagePreviewBox"
import EditFormButtons from "../../../components/EditFormBtns"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
import SearchDropDown from "../../../components/SearchDropDown"
import { getUserList } from "../../user/redux/userApi"
import SearchUser from "../components/SearchUser"

const CompanyDetails = ({ setTitle }) => {
  const { companyLoading, companyDetail, companyAddress } = useSelector(
    state => state.bNs,
    shallowEqual,
  )
  const dispatch = useDispatch()
  const { id } = useParams()

  let pageIndex = 0
  let pageIndexTwo = 0
  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const [active, setActive] = useState("buyers")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [activeInputs, setActiveInputs] = useState(false)
  const [focusedField, setFocusedField] = useState()
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [popup, setPopup] = useState(0)
  const [type, setType] = useState("")
  const [isEventCard, setIsEventCard] = useState(false)
  const [isEventCardTwo, setIsEventCardTwo] = useState(false)
  const [popupData, setPopupData] = useState()
  const [companyLogo, setCompanyLogo] = useState(logo)
  const [imageInfo, setImageInfo] = useState()
  const [showPreview, setShowPreview] = useState(false)

  useEffect(() => {
    setTitle("Company Details")
    dispatch(getCompanyDetail(id))
  }, [setTitle, dispatch, id])

  const handleCompanyLogo = async e => {
    const selectedImg = e.target.files[0]
    if (
      selectedImg.type === "image/jpeg" ||
      selectedImg.type === "image/webp" ||
      selectedImg.type === "image/png" ||
      selectedImg.type === "image/jpg"
    ) {
      if (selectedImg.size > 5 * 1024 * 1024) {
        toast.warning(
          "File size exceeds 5MB limit. Please select a smaller file.",
        )
        e.target.value = null
      } else {
        const convertedImg = await convertToWebP(selectedImg)
        setImageInfo(convertedImg)
        setCompanyLogo(URL.createObjectURL(selectedImg))
        e.target.value = null
      }
    } else {
      toast.warning("accepts only .jpeg, .jpg, .png and .webp files only")
      e.target.value = null
    }
  }

  useEffect(() => {
    if (imageInfo) {
      const data = {
        id: id,
        send: {
          company_logo: imageInfo,
        },
      }

      const response = dispatch(changeCompanylogo(data))
    }
  }, [imageInfo])

  const initialValues = {
    user_name: "",
    user_email: "",
    user_num: "",
    company_name: "",
    company_email: "",
    company_num: "",
    gst_number: "",
    // type: "",
    creditLimit: "",
    payDate: "",
    handlerName: "",
    handlerPhone: "",
    handlerEmail: "",
  }
  const companydetailSchema = Yup.object({
    user_name: Yup.string()
      .trim()
      .min(3, "Full Name must be at least 3 characters long")
      .max(80, "Full Name must be less than 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Company name is required"),
    user_email: Yup.string()
      .trim()
      .email("Please enter valid email address")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please!",
      )
      .test("fakeMail", "Please provide valid email", function (value) {
        if (
          value.includes("@yopmail.") ||
          value.includes("@mywrld.") ||
          value.includes("@nicoric.") ||
          value.includes("@huleos.") ||
          value.includes("@guerrillamail.") ||
          value.includes("@maildrop.")
        ) {
          return false
        } else {
          return true
        }
      })
      .required("Email address should not be empty"),
    user_num: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
      .required("Phone number is required"),
    company_name: Yup.string()
      .trim()
      .min(3, "Full Name must be at least 3 characters long")
      .max(80, "Full Name must be less than 80 characters")
      .required("Company name is required"),
    company_email: Yup.string()
      .trim()
      .email("Please provide valid email")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please!",
      )
      .required("Email address should not be empty")
      .test("fakeMail", "Please provide valid email", function (value) {
        if (
          value.includes("@yopmail.") ||
          value.includes("@mywrld.") ||
          value.includes("@nicoric.") ||
          value.includes("@huleos.") ||
          value.includes("@guerrillamail.") ||
          value.includes("@maildrop.")
        ) {
          return false
        } else {
          return true
        }
      }),
    company_num: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
      .required("Phone number is required"),
    gst_number: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Not matched with the valid schema, Please enter valid GST number",
      )
      .required("GST number is required"),
    creditLimit: Yup.string()
      // .required("Cost price is required")
      .matches(/[\d]/),
    payDate: Yup.string().matches(/[\d]/),
    // type: Yup.string().trim().min(3, "Name must be at least 3 characters long"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: companydetailSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        contactPersonName: formik.values.user_name.trim(),
        contact_email: formik.values.user_email,
        contact_phone: `+91${formik.values.user_num}`,
        phone: `+91${formik.values.company_num}`,
        name: formik.values.company_name,
        email: formik.values.company_email,
        gstNumber: formik.values.gst_number,
        creditLimit: Number(convertRupeesToNumber(values.creditLimit)),
        validity: Number(values.payDate),
        salesPersonId: formik.values.handlerName,
        salesPersonPhone: formik.values.handlerPhone,
        salesPersonEmail: formik.values.handlerEmail,
      }
      const sendData = {
        id: id,
        send: data,
      }

      const response = await dispatch(updateCompanyAsync(sendData))
      if (response?.payload) {
        setActiveInputs(false)
        resetForm()
      }
    },
  })
  const checkStringNull = value => {
    return !value.includes("null")
  }
  const setFormikValues = () => {
    formik.setValues({
      user_name:
        companyDetail?.contactPersonName &&
        checkStringNull(companyDetail?.contactPersonName)
          ? companyDetail?.contactPersonName
          : "",
      user_email:
        companyDetail?.contactEmail &&
        checkStringNull(companyDetail?.contactEmail)
          ? companyDetail?.contactEmail
          : "",
      user_num:
        companyDetail?.contactPhone &&
        !companyDetail?.contactPhone.includes("null")
          ? removePhoneNoCountryCode(companyDetail?.contactPhone)
          : "",
      company_name:
        companyDetail?.name && checkStringNull(companyDetail?.name)
          ? companyDetail?.name
          : "",
      company_email:
        companyDetail?.companyEmail?.value &&
        checkStringNull(companyDetail?.companyEmail?.value)
          ? companyDetail?.companyEmail?.value
          : "",
      company_num:
        companyDetail?.companyPhone?.value &&
        !companyDetail?.companyPhone?.value.includes("null")
          ? removePhoneNoCountryCode(companyDetail?.companyPhone?.value)
          : "",
      gst_number: companyDetail?.gst?.value ? companyDetail?.gst?.value : "",
      payDate: companyDetail?.creditLimit?.validity
        ? companyDetail?.creditLimit?.validity
        : "",
      creditLimit: companyDetail?.creditLimit?.value
        ? numberWithCommas(companyDetail?.creditLimit?.value)
        : "",
      handlerName: companyDetail?.salespersonId
        ? companyDetail?.salespersonId
        : "",
      handlerPhone: companyDetail?.salesPersonPhone
        ? removePhoneNoCountryCode(companyDetail?.salesPersonPhone)
        : "",
      handlerEmail: companyDetail?.salespersonEmail
        ? companyDetail?.salespersonEmail
        : "",
    })
  }

  useEffect(() => {
    if (companyDetail) {
      setFormikValues()
      setCompanyLogo(companyDetail?.logo ? companyDetail.logo : logo)
    }
  }, [companyDetail])
  // useEffect(() => {
  //   if (companyAddress) {
  //     companyAddress.map((item) => {
  //       if (item.type !== "billing" || item.constraint === "primary") {
  //
  //         setShippingAddress((oldList) => {
  //           let newList = JSON.parse(JSON.stringify(oldList));
  //           newList.push(item);
  //           return newList;
  //         });
  //       }
  //     });
  //   }
  // }, [companyAddress]);
  //
  //
  // const personalInfoSchema = Yup.object({
  //   fullName: Yup.string()
  //     .trim()
  //     .min(3, "Name must be at least 3 characters long")
  //     .max(30, "Name must be less than 30 characters")
  //     .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
  //     .required("Company name is required"),
  //   email: Yup.string().email("Enter valid email").required("Mail is required"),
  //   company_num: Yup.string()
  //     .required("Contact number is required")
  //     .matches(/^\d{10}$/, "Invalid contact number"),
  // });

  // const formik_person = useFormik({
  //   initialValues: {
  //     fullName: "",
  //     email: "",
  //     company_num: "",
  //   },
  //   validationSchema: personalInfoSchema,
  //   onSubmit: (values, { resetForm }) => {
  //
  //     setActiveInputs(false);
  //     resetForm();
  //   },
  // });

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }

  const deleteFunction = (address_id, title) => {
    setPopupData({
      data: { id: address_id, send: { company_id: id, is_delete: true } },
      title: title,
    })
    setIsEventCard(true)
  }

  function updatePopup(val, title, address) {
    setPopup({ val, address })
    setType(title)
  }
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  const handleCancel = () => {
    formik.resetForm()
    setActiveInputs(false)
    setFormikValues()
  }
  const { tableLoading: showLoader } = useLoaderDelay({
    loadingState: companyLoading,
  })

  return (
    <div
      className='container'
      style={!isEventCard && !popup ? heightAuto : null}
    >
      <div className='app-container'>
        {popup.val === 1 && (
          <AddLocations popup={popup} setPopup={setPopup} type={type} />
        )}
        {popup.val === 2 && (
          <UpdateLocations
            popup={popup}
            setPopup={setPopup}
            type={type}
            customSetter={setIsEventCardTwo}
          />
        )}
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              active={active}
              setActive={setActive}
              MbSidebar={MbSidebar}
              setMbsidebar={setMbsidebar}
            />
          )}
          {/* 
      {showChat && (
        <Chatbox showChat={showChat} setShowChat={setShowChat} />
      )} 
      */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Buyers and Suppliers Details</div>
                  {/* <div className="mch-path">Company / Poonam Coatings</div> */}
                  <div className='mch-path'>
                    <Breadcrumb
                      id={companyDetail?._id}
                      title={companyDetail?.name}
                    />
                  </div>
                </div>
                {/* <div className="mch2">
                  <div className="mch2-duration">
                    August 1,2020 - August 31,2020
                  </div>
                  <div
                    className="mch2-action"
                    onClick={() => setAction(!action)}
                  >
                    <span className="">Action</span>
                    <span className="material-symbols-rounded expand">
                      expand_more
                    </span>
                    {action && (
                      <div className="action-tooltip">
                        <span className="">Download</span>
                        <span className="">Print</span>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
              <div className='company-header'>
                <div className='header-content'>
                  <div className='col1'>
                    <div className='company-details'>
                      <label
                        htmlFor='choose_photo'
                        className={`updtIMG ${
                          Object.values(
                            privileges["buyers_and_suppliers_management"],
                          ).includes("write") && " showCameraIcon"
                        }`}
                        onClick={() => {
                          if (
                            Object.values(
                              privileges["buyers_and_suppliers_management"],
                            ).includes("write")
                          ) {
                            setShowPreview(true)
                          }
                        }}
                      >
                        <span className='material-symbols-rounded updatelogo'>
                          add_a_photo
                        </span>
                        <img
                          src={
                            companyLogo
                            // ? companyLogo
                            // : require("../../../assets/images/building.png")
                          }
                          alt='profile'
                          className='company-logo-edit'
                        />
                        {/* <input
                          id='choose_photo'
                          hidden={true}
                          type='file'
                          accept='.png,.jpeg,.jpg,.webp'
                          className='company-pic-input'
                          onChange={handleCompanyLogo}
                        /> */}
                      </label>
                      <div>
                        <h2 className='company-name'>{companyDetail?.name}</h2>
                        <p className='company-address'>
                          <span className='material-symbols-outlined medium'>
                            location_on
                          </span>
                          {`${companyAddress[0]?.address?.city}, ${companyAddress[0]?.address?.state}`}
                        </p>
                      </div>
                    </div>
                    {/* <label htmlFor="choose_photo" className="change-image-btn">
                      Change Logo
                      <input
                        id="choose_photo"
                        hidden={true}
                        type="file"
                        accept=".png,.jpeg,.jpg,.webp"
                        className="company-pic-input"
                        onChange={handleCompanyLogo}
                      />
                    </label> */}
                  </div>
                  {/* <div className="col2">
                    <button>Delete Company</button>
                  </div> */}
                </div>
              </div>

              <div className='bns-company-details'>
                <form className='company_details_form'>
                  <div className='details_control'>
                    <h3>Personal Info</h3>
                    {Object.values(
                      privileges["buyers_and_suppliers_management"],
                    ).includes("write") ? (
                      <EditFormButtons
                        loading={companyLoading}
                        activeInputs={activeInputs}
                        handleSubmit={formik.handleSubmit}
                        handleCancel={handleCancel}
                        handleInputs={setActiveInputs}
                      />
                    ) : null}
                  </div>
                  <div className='form-group'>
                    <div
                      className={`input-field ${
                        formik.values.user_name !== "" ||
                        focusedField === "user_name"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='user_name' className='toggle-label'>
                        Full Name
                      </label>
                      <input
                        id='user_name'
                        name='user_name'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        disabled={!activeInputs}
                        value={formik.values.user_name || ""}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^A-Za-z ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        // onInput={capitalizeInput}
                        maxLength={81}
                        onFocus={() => setFocusedField("user_name")}
                      />
                      {formik.touched.user_name && formik.errors.user_name ? (
                        <span className='err'>{formik.errors.user_name}</span>
                      ) : null}
                    </div>
                    <div
                      className={`input-field ${
                        formik.values.user_email !== "" ||
                        focusedField === "user_email"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='user_email' className='toggle-label'>
                        Email ID
                      </label>
                      <input
                        id='user_email'
                        name='user_email'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.user_email || ""}
                        disabled={!activeInputs}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("user_email")}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                      />
                      {formik.touched.user_email && formik.errors.user_email ? (
                        <span className='err'>{formik.errors.user_email}</span>
                      ) : null}
                    </div>
                    <div
                      className={`input-field ${
                        formik.values.user_num !== "" ||
                        focusedField === "user_num"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='user_num' className='toggle-label'>
                        Contact Number
                      </label>
                      <div
                        className={formik.values.user_num ? "phone_box" : ""}
                      >
                        {
                          <span className='phone_code'>
                            {formik.values.user_num ? "+91" : ""}
                          </span>
                        }{" "}
                        <input
                          id='user_num'
                          name='user_num'
                          type='text'
                          className='form-input'
                          onChange={formik.handleChange}
                          value={formik.values.user_num || ""}
                          disabled={!activeInputs}
                          onInput={e => {
                            e.target.value = e.target.value
                              .slice(0, 10)
                              .replace(/\D/g, "")
                          }}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("user_num")}
                          onKeyDown={e => {
                            if (e.key === " ") {
                              e.preventDefault()
                            }
                          }}
                        />
                      </div>{" "}
                      {formik.touched.user_num && formik.errors.user_num ? (
                        <span className='err'>{formik.errors.user_num}</span>
                      ) : null}
                    </div>
                    <div
                      className={`input-field ${
                        formik.values.company_name !== "" ||
                        focusedField === "company"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='company_name' className='toggle-label'>
                        Name of Company
                      </label>
                      <input
                        id='company_name'
                        name='company_name'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        disabled={!activeInputs}
                        maxLength={81}
                        value={formik.values.company_name || ""}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("company")}
                        onInput={capitalizeInput}
                      />
                      {formik.touched.company_name &&
                      formik.errors.company_name ? (
                        <span className='err'>
                          {formik.errors.company_name}
                        </span>
                      ) : null}
                    </div>
                    <div
                      className={`input-field ${
                        formik.values.company_email !== "" ||
                        focusedField === "company_email"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='company_email' className='toggle-label'>
                        Official Company Email ID
                      </label>
                      <input
                        id='company_email'
                        name='company_email'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.company_email || ""}
                        disabled={!activeInputs}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("company_email")}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                      />
                      {formik.touched.company_email &&
                      formik.errors.company_email ? (
                        <span className='err'>
                          {formik.errors.company_email}
                        </span>
                      ) : null}
                    </div>
                    <div
                      className={`input-field ${
                        formik.values.company_num !== "" ||
                        focusedField === "company_num"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='company_num' className='toggle-label'>
                        Company Contact Number
                      </label>
                      <div
                        className={formik.values.company_num ? "phone_box" : ""}
                      >
                        {
                          <span className='phone_code'>
                            {formik.values.company_num ? "+91" : ""}
                          </span>
                        }
                        <input
                          id='company_num'
                          name='company_num'
                          type='text'
                          className='form-input'
                          onChange={formik.handleChange}
                          value={formik.values.company_num || ""}
                          disabled={!activeInputs}
                          onInput={e => {
                            e.target.value = e.target.value
                              .slice(0, 10)
                              .replace(/\D/g, "")
                          }}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("company_num")}
                          onKeyDown={e => {
                            if (e.key === " ") {
                              e.preventDefault()
                            }
                          }}
                        />
                      </div>{" "}
                      {formik.touched.company_num &&
                      formik.errors.company_num ? (
                        <span className='err'>{formik.errors.company_num}</span>
                      ) : null}
                    </div>
                    <div
                      className={`input-field ${
                        formik.values.gst_number !== "" ||
                        focusedField === "gst_number"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='gst_number' className='toggle-label'>
                        GST Number
                      </label>
                      <input
                        id='gst_number'
                        name='gst_number'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.gst_number || ""}
                        disabled={!activeInputs}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 15)
                            .toUpperCase()
                        }}
                        onBlur={e => {
                          formik.handleBlur(e)
                          setFocusedField("")
                        }}
                        onFocus={() => setFocusedField("gst_number")}
                        // onPaste={(e) => {
                        //   e.preventDefault();
                        // }}
                        onCopy={e => {
                          e.preventDefault()
                        }}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                      />
                      {formik.touched.gst_number && formik.errors.gst_number ? (
                        <span className='err'>{formik.errors.gst_number}</span>
                      ) : null}
                    </div>
                    <div
                      className={`input-field ${
                        formik.values.creditLimit !== "" ||
                        focusedField === "creditLimit"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='creditLimit' className='toggle-label'>
                        Credit Limit
                      </label>
                      <div
                        className={formik.values.creditLimit ? "phone_box" : ""}
                      >
                        {
                          <span className='phone_code'>
                            {formik.values.creditLimit ? "₹" : ""}
                          </span>
                        }{" "}
                        <input
                          id='creditLimit'
                          name='creditLimit'
                          type='text'
                          className='form-input'
                          onChange={e => {
                            formik.setFieldValue(
                              "creditLimit",
                              numberWithCommas(e.target.value),
                            )
                          }}
                          value={formik.values.creditLimit}
                          onInput={e => {
                            e.target.value = priceValidator(e.target.value)
                          }}
                          maxLength={12}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          disabled={!activeInputs}
                          onFocus={() => setFocusedField("creditLimit")}
                        />
                      </div>

                      {formik.touched.creditLimit &&
                      formik.errors.creditLimit ? (
                        <span className='err'>{formik.errors.creditLimit}</span>
                      ) : null}
                    </div>{" "}
                    <div
                      className={`input-field ${
                        formik.values.payDate !== "" ||
                        focusedField === "payDate"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='payDate' className='toggle-label'>
                        Pay Duration In days
                      </label>
                      <div
                        className={
                          formik.values.payDate ? "margin_percentage_box" : ""
                        }
                      >
                        {
                          <span className='m_percentage'>
                            {formik.values.payDate
                              ? Number(formik.values.payDate) > 1
                                ? "Days"
                                : "Day"
                              : ""}
                          </span>
                        }{" "}
                        <input
                          id='payDate'
                          name='payDate'
                          type='text'
                          className='form-input'
                          onChange={formik.handleChange}
                          value={formik.values.payDate}
                          onInput={e => {
                            e.target.value = e.target.value
                              .replace(/[^\d ]/gi, "")
                              .replace(/(?:^|\s)\S/g, match =>
                                match.toUpperCase(),
                              )
                          }}
                          maxLength={3}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("payDate")}
                          disabled={!activeInputs}
                        />
                      </div>

                      {formik.touched.payDate && formik.errors.payDate ? (
                        <span className='err'>{formik.errors.payDate}</span>
                      ) : null}
                    </div>{" "}
                  </div>
                  <div className='details_control pt-1-05'>
                    <h3>Sales Person Info</h3>
                  </div>
                  <div className='form-group'>
                    <div className='input-field'>
                      <SearchUser
                        label={"Full Name"}
                        name='handlerName'
                        customSetter={formik.setFieldValue}
                        api={getUserList}
                        // listName={"painttype-list"}
                        dynamicLabel={"fname"}
                        dynamicValue={"all"}
                        listName='userList'
                        customFunctionOnClick={value => {
                          formik.setFieldValue(
                            "handlerPhone",
                            removePhoneNoCountryCode(value.phone.value),
                          )
                          formik.setFieldValue(
                            "handlerEmail",
                            value.email.value,
                          )
                          formik.setFieldValue("handlerName", value._id)
                        }}
                        disabled={!activeInputs}
                        incomingValue={
                          companyDetail?.salesPersonName
                            ? companyDetail?.salesPersonName
                            : " "
                        }
                      />{" "}
                      {formik.touched.handlerName &&
                      formik.errors.handlerName ? (
                        <span className='err'>{formik.errors.handlerName}</span>
                      ) : null}
                    </div>
                    <div
                      className={`input-field ${
                        formik.values.handlerPhone !== "" ||
                        focusedField === "mobile"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='handlerPhone' className='toggle-label'>
                        Phone
                      </label>
                      <div
                        className={
                          formik.values.handlerPhone ? "phone_box" : ""
                        }
                      >
                        {
                          <span className='phone_code'>
                            {formik.values.handlerPhone ? "+91" : ""}
                          </span>
                        }
                        <input
                          type='text'
                          name='handlerPhone'
                          id='handlerPhone'
                          className='form-input'
                          value={formik.values.handlerPhone}
                          onChange={formik.handleChange}
                          onInput={e => {
                            e.target.value = e.target.value
                              .slice(0, 10)
                              .replace(/\D/g, "")
                          }}
                          onKeyDown={e => {
                            if (e.key === " ") {
                              e.preventDefault()
                            }
                          }}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("mobile")}
                          disabled
                        />
                      </div>{" "}
                      {formik.errors.handlerPhone &&
                      formik.touched.handlerPhone ? (
                        <span className='err'>
                          {formik.errors.handlerPhone}
                        </span>
                      ) : null}
                    </div>
                    <div
                      className={`input-field ${
                        formik.values.handlerEmail !== "" ||
                        focusedField === "handlerEmail"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='handlerEmail' className='toggle-label'>
                        Email
                      </label>
                      <input
                        type='handlerEmail'
                        name='handlerEmail'
                        id='handlerEmail'
                        className='form-input'
                        value={formik.values.handlerEmail}
                        onChange={formik.handleChange}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("handlerEmail")}
                        disabled
                      ></input>
                      {formik.errors.handlerEmail &&
                      formik.touched.handlerEmail ? (
                        <span className='err'>
                          {formik.errors.handlerEmail}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>

              <div className='bns-company-details'>
                <div className='company_details_form'>
                  <div className='details_control'>
                    <h3>Billing Address</h3>
                    {Object.values(
                      privileges["buyers_and_suppliers_management"],
                    ).includes("write") && (
                      <button
                        className='btn-secondary'
                        onClick={() => {
                          updatePopup(1, "warehouse", companyDetail._id)
                        }}
                      >
                        <span className='material-symbols-outlined small'>
                          add
                        </span>
                        <span>ADD</span>
                      </button>
                    )}
                  </div>

                  <div className='card-container'>
                    {companyAddress.map((item, i) => {
                      if (
                        item.type !== "shipping" ||
                        item.constraint === "primary"
                      ) {
                        pageIndex = pageIndex + 1
                        return (
                          <div
                            className='card'
                            key={i}
                            onClick={() => {
                              if (
                                Object.values(
                                  privileges["buyers_and_suppliers_management"],
                                ).includes("write")
                              ) {
                                updatePopup(2, item.type, item)
                              }
                            }}
                          >
                            {item?.constraint === "primary" && (
                              <div className='primary-ribbon'>
                                <span>Primary</span>
                              </div>
                            )}
                            <div className='col1'>
                              <h4 className='card-title'>
                                <span className='material-symbols-outlined'>
                                  {item?.type === "warehouse"
                                    ? "warehouse"
                                    : "work"}
                                </span>{" "}
                                {item.type}
                              </h4>{" "}
                              {/* {item?.type === "work" && (
                                <h4 className='card-title'>
                                  <i className='fa-regular fa-map-location-dot'></i>{" "}
                                  Primary
                                </h4>
                              )} */}
                              {/* <p className="card-gstn">
                              <b>GST No:</b> {item.gst_number}
                            </p> */}
                              {/* <p className="card-gstn">
                                <b>Email: </b> {item.email}
                              </p>
                              <p className="card-gstn">
                              <b>Phone: </b> {item.phone}
                            </p> */}
                              <h3 className='card-name'>{item.name}</h3>
                              <div className='card-address'>
                                <p>{`${item.address.line_one}, `}</p>
                                <p>{`${item.address.line_two}${item.address.line_two ? "," : ""}`}</p>
                                <p>{`${item.address.city}- ${item.address.pin_code},`}</p>
                                <p>{`${item.address.state}- ${item.address.country}`}</p>
                              </div>
                            </div>
                            <div className='col2'>
                              {/* <button
                                className='icon-btn edit'
                            
                              >
                                <span className='material-symbols-outlined medium'>
                                  border_color
                                </span>
                              </button> */}
                              {Object.values(
                                privileges["user_management"],
                              ).includes("delete") && (
                                <button
                                  className='icon-btn delete hover-close'
                                  onClick={e => {
                                    e.stopPropagation()
                                    if (
                                      Object.values(
                                        privileges["user_management"],
                                      ).includes("delete")
                                    ) {
                                      deleteFunction(
                                        item._id,
                                        "Address Details",
                                      )
                                    }
                                  }}
                                >
                                  <span className='material-symbols-outlined medium '>
                                    close
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>
                </div>
              </div>
              <div className='bns-company-details'>
                <div className='company_details_form'>
                  <div className='details_control'>
                    <h3>Delivery Locations</h3>
                    {Object.values(
                      privileges["buyers_and_suppliers_management"],
                    ).includes("write") && (
                      <button
                        className='btn-secondary'
                        onClick={() => {
                          updatePopup(1, "shipping", companyDetail._id)
                        }}
                      >
                        <span className='material-symbols-outlined small'>
                          add
                        </span>
                        <span>ADD</span>
                      </button>
                    )}
                  </div>

                  <div className='card-container'>
                    {companyAddress.map((item, i) => {
                      if (item.type === "shipping") {
                        pageIndexTwo = pageIndexTwo + 1
                        return (
                          <div
                            className='card'
                            key={i}
                            // onClick={() => {
                            //   updatePopup(2, item.type, item)
                            // }}
                            onClick={() => {
                              if (
                                Object.values(
                                  privileges["buyers_and_suppliers_management"],
                                ).includes("write")
                              ) {
                                updatePopup(2, item.type, item)
                              }
                            }}
                          >
                            {item?.constraint === "primary" && (
                              <div className='primary-ribbon'>
                                <span>Primary</span>
                              </div>
                            )}
                            <div className='col1'>
                              <h4 className='card-title'>
                                <span className='material-symbols-outlined'>
                                  local_shipping
                                </span>
                                {item.type}
                              </h4>{" "}
                              <h3 className='card-name'>{item.name}</h3>
                              {/* <p className="card-gstn">
                              <b>GST No:</b> {item.gst_number}
                            </p> */}
                              {/* <p className='card-gstn'>
                                <b>Address Type:</b> {item.type}
                              </p> */}
                              <div className='card-address'>
                                <p>{`${item.address.line_one}, `}</p>
                                <p>{`${item.address.line_two}${item.address.line_two ? "," : ""}`}</p>
                                <p>{`${item.address.city} - ${item.address.pin_code},`}</p>
                                <p>{`${item.address.state} - ${item.address.country}`}</p>
                              </div>
                              {/* <p className='card-address'>
                                {item.address.line_one},{item.address.line_two},
                                {item.address.city} - {item.address.pin_code},{" "}
                                {item.address.state} - {item.address.country}
                              </p> */}
                            </div>
                            <div className='col2'>
                              {/* <button
                                className='icon-btn edit'
                                
                              >
                                <span className='material-symbols-outlined medium'>
                                  border_color
                                </span>
                              </button> */}

                              {/* <button
                                className='btn-secondary hover-close'
                                onClick={() => {
                                  updatePopup(1, "warehouse", companyDetail._id)
                                }}
                              >
                                <span>Set As Default</span>
                              </button> */}
                              {Object.values(
                                privileges["user_management"],
                              ).includes("delete") && (
                                <button
                                  className='icon-btn delete hover-close'
                                  // onClick={e => {
                                  //   e.stopPropagation()
                                  //   deleteFunction(item._id, "Address Details")
                                  // }}

                                  onClick={e => {
                                    e.stopPropagation()
                                    if (
                                      Object.values(
                                        privileges["user_management"],
                                      ).includes("delete")
                                    ) {
                                      deleteFunction(
                                        item._id,
                                        "Address Details",
                                      )
                                    }
                                  }}
                                >
                                  <span className='material-symbols-outlined medium'>
                                    close
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>
                  {pageIndexTwo ? null : (
                    <div className='Nodata-txt'>
                      {" "}
                      <img
                        src={
                          require("../../../assets/images/nodata.svg").default
                        }
                        alt=''
                        srcSet=''
                      />
                      No Data Found
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='data-table-container'>
                <div className='details_control'>
                  <h3>Company Balance</h3>
                </div>
                <div className='data-table-box'>
                  <table className='company-balance'>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Payable</th>
                        <th>Receivables</th>
                        <th>Supply</th>
                        <th>Return</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>12 December, 2023</td>
                        <td>₹10,000</td>
                        <td>₹9,000</td>
                        <td>10</td>
                        <td>6</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
            </div>
            <Footer />
          </div>
          {isEventCardTwo && (
            <ConformationPopup
              customState={isEventCardTwo}
              customSetter={setIsEventCardTwo}
              mainTitle='Are you sure you want to make this address your Primary address?'
              subTitle='This action will change you primary address'
              api={updatePrimaryAddress}
              popUpData={isEventCardTwo}
              reData={id}
              reFetchApi={getCompanyDetail}
              popupType='edit'
              toastMessage='Primary address changed'
            />
          )}{" "}
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteCompanyAddress}
              popUpData={popupData}
              reData={companyDetail._id}
              reFetchApi={getCompanyAddress}
            />
          )}{" "}
          {showPreview && (
            <ImagePreviewBox
              customSetter={setShowPreview}
              imageChangeApi={changeCompanylogo}
              customState={showPreview}
              slug={"company_logo"}
              incomingImage={companyLogo}
            />
          )}
          <Loader isLoading={showLoader} />
        </div>
      </div>
    </div>
  )
}

export default CompanyDetails
