/* eslint-disable no-useless-escape */
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { toast } from "react-toastify"
import * as Yup from "yup"
import DropDownBox from "../../../components/DropDownBox"
import SearchDropDown from "../../../components/SearchDropDown"
import {
  calPrice,
  capitalizeInput,
  convertRupeesToNumber,
  convertToLitre,
  errorShakeAnimation,
  findValueFromPercentage,
  numberWithCommas,
  priceValidator,
} from "../../../helper/helper"
import {
  createProducts,
  getBasePaint,
  getHsnList,
  getOptionsList,
} from "../redux/ProductsApi"

const AddNewProduct = ({ popup, setPopup }) => {
  const dispatch = useDispatch()
  const { role } = useSelector(store => store.auth)

  // const [activeInputs, setActiveInputs] = useState(false);
  // const [gstn, setGstn] = useState(false);
  const [addStyle, setAddStyle] = useState(true)
  const [focusedField, setFocusedField] = useState()
  const [litreId, setLitreId] = useState("")
  const [categoryReset, setCategoryReset] = useState(false)
  const [subCategoryReset, setSubCategoryReset] = useState(false)
  const [subChildCategoryReset, setSubChildCategoryReset] = useState(false)
  const [tintersList, setTintersList] = useState([
    {
      tintersName: "tinters",
      proportionName: "proportionTinters",
      uomName: "dynamicUom",
      values: {},
      calList: {},
    },
  ])

  const [paintComponentsData, setPaintComponentData] = useState([{}, {}])
  const [proportionHistory, setProportionHistory] = useState({
    prop1: "",
    prop2: "",
    tinterHistory: [],
  })
  // const [tintersData, setTintersData] = useState([])

  const { productsLoading, optionsLoading } = useSelector(
    store => store.products,
  )
  const initialValues = {
    paintName: "",
    brand: "",
    category: "",
    subCategory: "",
    subChildCategory: "",
    color: "",
    packSize: "",
    paintType: "",
    finish: "",
    unitPrice: "0",
    costPrice: "",
    sellingPrice: "",
    uom: "",
    basePaint1: "",
    basePaint2: "",
    proportionPaint1: "",
    proportionPaint2: "",
    tinters: "",
    quantity: "",
    uomOfPaint1: "",
    dynamicUom: "",
    uomOfPaint2: "",
    proportionTinters: "",
    hsnId: "",
    productCode: "",
    // batchNumber: "",
    marginPercentage: "",
    standard: "",
  }

  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object({
    paintName: Yup.string()
      .trim()
      .min(2, "Paint name must be at least 2 characters long")
      .max(80, "Name must be less than 80 characters")
      .required("Paint name is required"),
    brand: Yup.string().required("Brand name is required"),
    category: Yup.string().required("Category name is required"),
    subCategory: Yup.string(),
    subChildCategory: Yup.string(),
    color: Yup.object(),
    packSize: Yup.string().required("Pack size is required"),
    paintType: Yup.string(),
    finish: Yup.string(),
    // unitPrice: Yup.string().required("Unit price is required").matches(/[\d]/),
    costPrice: Yup.string().required("Cost price is required").matches(/[\d]/),
    marginPercentage: Yup.string()
      .required("Margin Percentage is required")
      .matches(/[\d]/),
    sellingPrice: Yup.string()
      .required("selling price is required")
      .matches(/[\d]/),
    uom: Yup.string().required("UOM is required"),
    uomOfPaint1: Yup.string().required("UOM is required"),
    dynamicUom: Yup.string().required("UOM is required"),
    uomOfPaint2: Yup.string().required("UOM is required"),
    basePaint1: Yup.string().required("Base paint 1 is required"),
    basePaint2: Yup.string().required("Base paint 2 is required"),
    proportionPaint1: Yup.string().required(
      "Proportion of Paint 1 is required",
    ),
    proportionPaint2: Yup.string().required(
      "Proportion of Paint 2 is required",
    ),
    tinters: Yup.string().required("Tinters is required"),
    proportionTinters: Yup.string().required(
      "Proportion of tinters is required",
    ),
    quantity: Yup.string()
      .required("Pack quantity is required")
      .test("quantity", "Quantity can not be greater than Pack Size", value => {
        if (Number(value) > Number(formik.values.packSize)) {
          return false
        } else {
          return true
        }
      }),
    hsnId: Yup.string().required("HSN code is required"),
    productCode: Yup.string().min(1).max(12),
    // .required("Product code is required"),
    // batchNumber: Yup.string().min(1).max(17),
    // .required("Batch number is required"),
    standard: Yup.string().required("Product standard is required"),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      // handleClick()
      const tinterData = tintersList.map(item => {
        return { ...item?.values }
      })
      const data = {
        hsn_id: values.hsnId,

        name: values.paintName,
        brand_id: values.brand,
        category_id: values.category,
        subCategory_id: values.subCategory,
        selling_price: convertRupeesToNumber(values.sellingPrice),
        cost_price: convertRupeesToNumber(values.costPrice),
        // unit_price: convertRupeesToNumber(values.unitPrice),
        painttype_id: values.paintType,
        finishtype_id: values.finish,
        color_id: values.color._id,
        subChildCategory_id: values.subChildCategory,
        product_weight: values.packSize,
        uom_id: values.uom,
        base_paint_one_productId: values.basePaint1,
        base_paint_two_productId: values.basePaint2,
        base_paint_one_product_weight: values.proportionPaint1,
        base_paint_one_uomId: values.uomOfPaint1,
        base_paint_two_product_weight: values.proportionPaint2,
        base_paint_two_uomId: values.uomOfPaint2,
        tinters: tinterData,
        product_code: values.productCode,
        batch_number: values.batchNumber,
        quantity: values.quantity,
        standard: values.standard,
        // margin: values.marginPercentage,
      }

      const response = await dispatch(createProducts(data))
      if (response?.payload) {
        handleClick()
      }
    },
  })

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (popup) {
      const styleTimer = setTimeout(() => {
        setPopup(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  const handleAddTinters = () => {
    setTintersList([
      ...tintersList,
      {
        tintersName: `tinters${tintersList.length + 1}`,
        proportionName: `proportionTinters${tintersList.length + 1}`,
        uomName: `dynamicUom${tintersList.length + 1}`,
        values: {},
        calList: {},
      },
    ])
  }
  const handleRemoveTinters = () => {
    setTintersList(tintersList => {
      const newArr = JSON.parse(JSON.stringify(tintersList))
      newArr.pop()
      return newArr
    })
    setProportionHistory(oldList => {
      let newList = JSON.parse(JSON.stringify(oldList))
      newList.tinterHistory.pop()
      return newList
    })
    // setTintersData(tintersList => {
    //   const newArr = JSON.parse(JSON.stringify(tintersList))
    //   if (newArr.length > 1) {
    //     newArr.pop()
    //   }
    //   return newArr
    // })
  }

  const handleFormState = (value, obj) => {
    const {
      index,
      name,
      formikLabel,
      valueLabel,
      listLabel,
      listValue,
      propFormikLabel,
    } = obj

    setTintersList(oldValue => {
      const newValues = JSON.parse(JSON.stringify(oldValue))
      // let convertedValue
      // if (name === "uomId") {
      //   convertedValue = convertToLitre({
      //     value: proportionHistory.tinterHistory[index],
      //     unit: value.label,
      //   })

      //   // newList[index].unit = convertedValue
      //   newValues[index].values["weight"] = convertedValue
      //   let input = document.getElementById(
      //     "proportionTinters" + (index ? index + 1 : ""),
      //   )
      //   input.value = convertedValue
      //   newValues[index].calList.unit = convertedValue
      // }

      newValues[index].values[name] =
        typeof value === "string" ? value : value[valueLabel]

      newValues[index].calList[listLabel] =
        typeof value === "string" ? value : value[listValue]
      //
      newValues[index].calList.unitType = "litre"
      return newValues
    })

    // setPaintComponentData(oldValue => {
    //   const newList = JSON.parse(JSON.stringify(oldValue))
    //   newList[index][listLabel] =
    //     typeof value === "string" ? value : value[valueLabel]
    //   return newList
    // })

    // setTintersData(tintersData => {
    //   const newList = JSON.parse(JSON.stringify(tintersData))

    //   if (newList[index] === undefined) {
    //     newList.push({ [name]: value })
    //   } else {
    //     newList[index][name] = value
    //   }
    //   return newList
    // })

    if (index === 0) {
      formik.setFieldValue(
        formikLabel,
        typeof value === "string" ? value : value[valueLabel],
      )
    }
  }

  // const [uomList, setUomList] = useState([])

  // const apiCall = async () => {
  //   {
  //     const response = await dispatch(
  //       getOptionsList({
  //         listName: "uom-list",
  //         search: "",
  //         sort: "desc",
  //         limit: 0,
  //         page: 0,
  //       }),
  //     )
  //     const options = response?.payload?.map(item => {
  //       return { label: item.uom_type, value: item._id }
  //     })
  //     setUomList(options)
  //   }
  // }
  const [optionsList, setOptionsList] = useState({
    uomList: [],
    brandList: [],
    categoryList: [],
    subCategoryList: [],
    subChildCategoryList: [],
  })

  // useEffect(() => {
  //   if (uomList.length === 0) apiCall()
  //   // if (brandList.length === 0) fetchData(urls)
  // }, [])

  const apiCall = async (
    listName,
    optionsName,
    dynamicLabel = "name",
    dynamicValue = "_id",
    dynamicApiLabel,
    formikLabel,
  ) => {
    if (listName) {
      const response = await dispatch(
        getOptionsList({
          listName,
          search: "",
          sort: "desc",
          limit: "0",
          page: "0",
          send:
            formikLabel && dynamicApiLabel
              ? {
                  [dynamicApiLabel]: formik.values[formikLabel],
                }
              : undefined,
        }),
      )

      if (listName === "uom-list" && optionsName) {
        const id = response?.payload?.find(item => item.slug === "litre")?._id

        setLitreId(id)
        // formik.setFieldValue("", id)
        // formik.setFieldValue("uomOfPaint2", id)
        const options = [
          {
            label: "litre",
            value: id,
          },
        ]
        setOptionsList(oldList => {
          let newList = JSON.parse(JSON.stringify(oldList))

          newList[optionsName] = options
          return newList
        })

        return
      }
      if (optionsName) {
        const options = response?.payload?.map(item => {
          return {
            label: item[dynamicLabel],
            value: optionsName === "categoryList" ? item : item[dynamicValue],
            // value: item[dynamicValue],
          }
        })
        setOptionsList(oldList => {
          let newList = JSON.parse(JSON.stringify(oldList))

          newList[optionsName] = options
          return newList
        })
      }
    }
  }

  useEffect(() => {
    if (optionsList?.uomList?.length === 0)
      apiCall("uom-list", "uomList", "uom_type")
  }, [])
  useEffect(() => {
    if (formik.values.brand)
      apiCall(
        "category-list",
        "categoryList",
        undefined,
        undefined,
        "brand_id",
        "brand",
      )

    if (formik.values.category)
      apiCall(
        "subCategory-list",
        "subCategoryList",
        undefined,
        undefined,
        "category_id",
        "category",
      )
    if (formik.values.subCategory)
      apiCall(
        "subChildCategory-list",
        "subChildCategoryList",
        undefined,
        undefined,
        "subCategory_id",
        "subCategory",
      )
  }, [formik.values.brand, formik.values.category, formik.values.subCategory])

  const handleProductData = (
    value,
    {
      name,
      index,
      valueLabel,
      listLabel,
      formikLabel = undefined,
      propFormikLabel,
    },
  ) => {
    if (formikLabel) {
      formik.setFieldValue(
        name,
        value[formikLabel] ? value[formikLabel] : litreId,
      )
    }

    setPaintComponentData(oldValue => {
      const newList = JSON.parse(JSON.stringify(oldValue))

      // if (name === "uomOfPaint1" || name === "uomOfPaint2") {
      //   const convertedValue = convertToLitre({
      //     value:
      //       proportionHistory[
      //         propFormikLabel === "proportionPaint1" ? "prop1" : "prop2"
      //       ],
      //     unit: value.label,
      //   })

      //   formik.setFieldValue(propFormikLabel, convertedValue)
      //   newList[index].unit = convertedValue
      // }

      newList[index][listLabel] =
        typeof value === "string" ? value : value[valueLabel]
      newList[index].unitType = "litre"

      return newList
    })
  }

  // const handleProductData = (
  //   value,
  //   {
  //     name,
  //     index,
  //     valueLabel,
  //     listLabel,
  //     formikLabel = undefined,
  //     propFormikLabel,
  //   },
  // ) => {
  //   let convertedValue
  //   if (formikLabel) {
  //     if (name === "uomOfPaint1" || name === "uomOfPaint2") {
  //       convertedValue = convertToLitre({
  //         value:
  //           proportionHistory[
  //             propFormikLabel === "proportionPaint1" ? "prop1" : "prop2"
  //           ],
  //         unit: value.label,
  //       })
  //     }
  //     formik.setFieldValue(name, value[formikLabel])
  //     formik.setFieldValue(propFormikLabel, convertedValue)
  //   }
  //   setPaintComponentData(oldValue => {
  //     const newList = JSON.parse(JSON.stringify(oldValue))
  //     newList[index][listLabel] =
  //       typeof value === "string" ? value : value[valueLabel]
  //     //
  //     newList[index].unit =
  //       proportionHistory[
  //         propFormikLabel === "proportionPaint1" ? "prop1" : "prop2"
  //       ]
  //     newList[index].unitType = "litre"
  //     return newList
  //   })
  // }

  const checkItHas = value => {
    return formik.errors.hasOwnProperty(value)
  }

  useEffect(() => {
    if (
      formik.values.basePaint1 &&
      formik.values.basePaint2 &&
      formik.values.proportionPaint1 &&
      formik.values.proportionPaint2 &&
      formik.values.tinters &&
      formik.values.proportionTinters
    ) {
      const { totalPrice = "", totalQuantity = "" } = calPrice(
        paintComponentsData.concat(
          tintersList.map(item => {
            return item?.calList
          }),
        ),
      )

      formik.setFieldValue("quantity", totalQuantity)
      formik.setFieldValue("packSize", totalQuantity)
      formik.setFieldValue(
        "costPrice",
        numberWithCommas(priceValidator(totalPrice)),
      )
    }
  }, [paintComponentsData, tintersList])

  useEffect(() => {
    if (formik.values.costPrice) {
      formik.setFieldValue(
        "sellingPrice",
        numberWithCommas(
          priceValidator(
            findValueFromPercentage({
              percentage: formik.values.marginPercentage,
              value: formik.values.costPrice,
              returnTotalValue: true,
              packSize: formik.values.packSize,
            }),
          ),
        ),
      )
    }
  }, [
    formik.values.costPrice,
    formik.values.marginPercentage,
    formik.values.unitPrice,
    formik.values.packSize,
  ])

  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>Create New Paint</h2>
          <button className='exit-btn' onClick={() => handleClick()}>
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>

        <div className='modelEx_content singleAdd'>
          <form className='scrollForm' onSubmit={formik.handleSubmit}>
            <div className='form-content' style={{ padding: "1rem" }}>
              <div className='form-group form'>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.paintName !== "" ||
                      focusedField === "paintName"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='paintName' className='toggle-label'>
                      Paint Name
                    </label>
                    <input
                      id='paintName'
                      name='paintName'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.paintName}
                      onInput={capitalizeInput}
                      maxLength={81}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("paintName")}
                    />
                    {formik.touched.paintName && formik.errors.paintName ? (
                      <span className='err'>{formik.errors.paintName}</span>
                    ) : null}
                  </div>{" "}
                  <div
                    className='input-field'
                    // onMouseEnter={() => apiCall("brand-list", "brandList")}
                  >
                    <DropDownBox
                      options={[
                        {
                          label: "Standard Product",
                          value: "Standard",
                        },
                        {
                          label: "Non Standard Product",
                          value: "Non-Standard",
                        },
                      ]}
                      customSetter={formik.setFieldValue}
                      dropDownTitle='Product Standard'
                      animateDropDownTitle
                      customFormikLabel={"standard"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                    />
                    {/* <SearchDropDown
                      label='Brand'
                      name='brand'
                      api={getOptionsList}
                      listName={"brand-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                    {formik.touched.standard && formik.errors.standard ? (
                      <span className='err'>{formik.errors.standard}</span>
                    ) : null}
                  </div>
                </div>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.productCode !== "" ||
                      focusedField === "productCode"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='productCode' className='toggle-label'>
                      Product Code
                    </label>
                    <input
                      id='productCode'
                      name='productCode'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.productCode}
                      // onInput={capitalizeInput}
                      maxLength={12}
                      minLength={1}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("productCode")}
                    />
                    {formik.touched.productCode && formik.errors.productCode ? (
                      <span className='err'>{formik.errors.productCode}</span>
                    ) : null}
                  </div>{" "}
                  {/* <div
                    className={`input-field ${
                      formik.values.batchNumber !== "" ||
                      focusedField === "batchNumber"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='batchNumber' className='toggle-label'>
                      Batch Number
                    </label>
                    <input
                      id='batchNumber'
                      name='batchNumber'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.batchNumber}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /(?:^|\s)\S/g,
                          match => match.toUpperCase(),
                        )
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      maxLength={17}
                      minLength={1}
                      onFocus={() => setFocusedField("batchNumber")}
                    />
                    {formik.touched.batchNumber && formik.errors.batchNumber ? (
                      <span className='err'>{formik.errors.batchNumber}</span>
                    ) : null}
                  </div> */}
                  <div className='input-field'>
                    <SearchDropDown
                      label='HSN Code'
                      name='hsnId'
                      api={getHsnList}
                      customSetter={formik.setFieldValue}
                      dynamicLabel={"hsn"}
                      maxLength={8}
                      type='number'
                      removeSpace
                    />
                    {formik.touched.hsnId && formik.errors.hsnId ? (
                      <span className='err'>{formik.errors.hsnId}</span>
                    ) : null}
                  </div>
                </div>
                <div className='col-2'>
                  <div
                    className='input-field'
                    onClick={() => {
                      if (optionsList?.brandList.length === 0) {
                        apiCall("brand-list", "brandList")
                      }
                    }}
                    // onMouseEnter={() => apiCall("brand-list", "brandList")}
                  >
                    <DropDownBox
                      options={optionsList.brandList}
                      customSetter={formik.setFieldValue}
                      dropDownTitle='Brand'
                      animateDropDownTitle
                      customFormikLabel={"brand"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      showSearchBar
                      loading={optionsLoading}
                      callCustomFunction={value => {
                        formik.setFieldValue("brand", value)
                        if (formik.values.category) {
                          setCategoryReset(true)
                        }
                        if (formik.values.subCategory) {
                          setSubCategoryReset(true)
                        }
                        if (formik.values.subChildCategory) {
                          setSubChildCategoryReset(true)
                        }
                        if (formik.values.marginPercentage) {
                          formik.setFieldValue("marginPercentage", "")
                        }
                      }}
                    />
                    {/* <SearchDropDown
                      label='Brand'
                      name='brand'
                      api={getOptionsList}
                      listName={"brand-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                    {formik.touched.brand && formik.errors.brand ? (
                      <span className='err'>{formik.errors.brand}</span>
                    ) : null}
                  </div>
                  <div
                    className='input-field'
                    onClick={() => {
                      if (!formik.values.brand) {
                        toast.error("Please Select Brand First")
                      }
                    }}
                  >
                    <DropDownBox
                      options={optionsList.categoryList}
                      customSetter={formik.setFieldValue}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Category'
                      animateDropDownTitle
                      showSearchBar
                      disabled={formik.values.brand ? true : false}
                      loading={optionsLoading}
                      customFormikLabel={"category"}
                      resetState={categoryReset}
                      resetSetter={setCategoryReset}
                      callCustomFunction={value => {
                        formik.setFieldValue("category", value?._id)
                        formik.setFieldValue("marginPercentage", value?.margin)
                        if (formik.values.subCategory) {
                          setSubCategoryReset(true)
                        }
                        if (formik.values.subChildCategory) {
                          setSubChildCategoryReset(true)
                        }
                      }}
                      // callCustomFunction={value => {
                      //   formik.setFieldValue(
                      //     "marginPercentage",
                      //     value?.marginPercentage,
                      //   )
                      //   formik.setFieldValue("category", value?._id)
                      // }}
                    />
                    {/* <SearchDropDown
                      label='Category'
                      name='category'
                      api={getOptionsList}
                      listName={"category-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                    {formik.touched.category && formik.errors.category ? (
                      <span className='err'>{formik.errors.category}</span>
                    ) : null}
                  </div>
                </div>{" "}
                <div className='col-2'>
                  <div
                    className='input-field'
                    onClick={() => {
                      if (!formik.values.category) {
                        toast.error("Please Select Category First")
                      }
                    }}
                  >
                    <DropDownBox
                      options={optionsList.subCategoryList}
                      customSetter={formik.setFieldValue}
                      dropDownTitle='Sub Category'
                      animateDropDownTitle
                      customFormikLabel={"subCategory"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      showSearchBar
                      disabled={formik.values.category ? true : false}
                      resetState={subCategoryReset}
                      resetSetter={setSubCategoryReset}
                      callCustomFunction={value => {
                        formik.setFieldValue("subCategory", value)
                        if (formik.values.subChildCategory) {
                          setSubChildCategoryReset(true)
                        }
                      }}
                    />

                    {/* <SearchDropDown
                      label='Sub Category'
                      name='subCategory'
                      api={getOptionsList}
                      listName={"subCategory-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                    {formik.touched.subCategory && formik.errors.subCategory ? (
                      <span className='err'>{formik.errors.subCategory}</span>
                    ) : null}
                  </div>

                  <div
                    className='input-field'
                    onClick={() => {
                      if (!formik.values.subCategory) {
                        toast.error("Please Select Sub Category First")
                      }
                    }}
                  >
                    <DropDownBox
                      options={optionsList.subChildCategoryList}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"subChildCategory"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Sub Child Category'
                      animateDropDownTitle
                      showSearchBar
                      disabled={formik.values.subCategory ? true : false}
                      resetState={subChildCategoryReset}
                      resetSetter={setSubChildCategoryReset}
                    />

                    {/* <SearchDropDown
                      label='Sub Child Category'
                      name='subChildCategory'
                      api={getOptionsList}
                      listName={"subChildCategory-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                    {formik.touched.subChildCategory &&
                    formik.errors.subChildCategory ? (
                      <span className='err'>
                        {formik.errors.subChildCategory}
                      </span>
                    ) : null}
                  </div>
                </div>{" "}
                <div className='col-2'>
                  <div className='input-field'>
                    {/* <DropDownBox
                      options={[
                        { label: "Buyers", value: "buyers" },
                        { label: "Suppliers", value: "suppliers" },
                        { label: "Both", value: "both" },
                      ]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"basePaint1"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Base Paint 1'
                      animateDropDownTitle
                    /> */}
                    <SearchDropDown
                      label='Base Paint Component A'
                      name='basePaint1'
                      api={getBasePaint}
                      customSetter={formik.setFieldValue}
                      dynamicLabel={"name"}
                      dynamicValue={"all"}
                      customFunctionOnClick={(
                        value,
                        customValueForCustomFunction,
                      ) => {
                        // if (formik.values.proportionPaint1) {
                        //   errorShakeAnimation({ id: "proportionPaint1" })
                        //   formik.setFieldValue("proportionPaint1", "")
                        // }
                        handleProductData(value, customValueForCustomFunction)
                      }}
                      customValueForFunctionOnClick={{
                        name: "basePaint1",
                        index: 0,
                        listLabel: "price",
                        valueLabel: "unitPrice",
                        formikLabel: "_id",
                      }}
                      showSize
                    />
                    {formik.touched.basePaint1 && formik.errors.basePaint1 ? (
                      <span className='err'>{formik.errors.basePaint1}</span>
                    ) : null}
                  </div>{" "}
                  <div className='col-2 gstn'>
                    <div
                      className={`input-field ${
                        formik.values.proportionPaint1 !== "" ||
                        focusedField === "proportionPaint1"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor='proportionPaint1'
                        className='toggle-label'
                      >
                        Proportion Of Paint 1
                      </label>
                      <input
                        id='proportionPaint1'
                        name='proportionPaint1'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          formik.handleChange(e)
                          setProportionHistory(oldList => {
                            let newList = JSON.parse(JSON.stringify(oldList))
                            newList.prop1 = e.target.value
                            return newList
                          })
                          // setPaintComponentData(oldValue => {
                          //   const newList = JSON.parse(JSON.stringify(oldValue))
                          //   newList[0]. = value.unitPrice
                          //   return newList
                          // })

                          handleProductData(
                            { unitValue: e.target.value },
                            {
                              index: 0,
                              listLabel: "unit",
                              valueLabel: "unitValue",
                            },
                          )
                        }}
                        value={formik.values.proportionPaint1}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                        }}
                        maxLength={10}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("proportionPaint1")}
                      />
                      {formik.touched.proportionPaint1 &&
                      formik.errors.proportionPaint1 ? (
                        <span className='err'>
                          {formik.errors.proportionPaint1}
                        </span>
                      ) : null}
                    </div>{" "}
                    <div className='input-field'>
                      <DropDownBox
                        options={optionsList.uomList}
                        defaultValue='UOM'
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                        callCustomFunction={handleProductData}
                        customValueForCustomFunction={{
                          name: "uomOfPaint1",
                          index: 0,
                          listLabel: "unitType",
                          valueLabel: "label",
                          formikLabel: "value",
                          propFormikLabel: "proportionPaint1",
                        }}
                        incomingValue={litreId}
                      />
                      {formik.touched.uomOfPaint1 &&
                      formik.errors.uomOfPaint1 ? (
                        <span className='err'>{formik.errors.uomOfPaint1}</span>
                      ) : null}
                    </div>
                  </div>
                </div>{" "}
                {tintersList.map((item, index) => {
                  const { tintersName, proportionName, uomName } = item

                  return (
                    <div className='col-2' key={index}>
                      <div className='input-field'>
                        {/* <DropDownBox
                          options={[
                            { label: "Buyers", value: "buyers" },
                            { label: "Suppliers", value: "suppliers" },
                            { label: "Both", value: "both" },
                          ]}
                          customSetter={formik.setFieldValue}
                          customFormikLabel={tintersName}
                          customDropBoxStyles={{
                            border: "1px solid #d7cece",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          customTextStyle={{ color: "#212229", opacity: "0.7" }}
                          dropDownTitle={`TInters ${index + 1}`}
                          animateDropDownTitle
                          callCustomFunction={handleFormState}
                          customValueForCustomFunction={{
                            index,
                            name: "productId",
                            formikLabel: "tinters",
                          }}
                        /> */}
                        <SearchDropDown
                          label={`Tinters ${index + 1}`}
                          name={`tinters${index + 1}`}
                          api={getOptionsList}
                          listName={"tinters-list"}
                          customFunctionOnClick={handleFormState}
                          customSetter={formik.setFieldValue}
                          customValueForFunctionOnClick={{
                            index,
                            name: "productId",
                            formikLabel: "tinters",
                            valueLabel: "_id",
                            listLabel: "price",
                            listValue: "unitPrice",
                          }}
                          dynamicLabel={"productName"}
                          dynamicValue={"all"}
                          static
                          apiData={{
                            send: {
                              brandId: formik.values.brand
                                ? formik.values.brand
                                : "",
                              category: "tinters",
                            },
                          }}
                          showSize
                        />
                        {formik.touched[tintersName] &&
                        formik.errors[tintersName] ? (
                          <span className='err'>
                            {formik.errors[tintersName]}
                          </span>
                        ) : null}
                      </div>{" "}
                      <div className='col-2 gstn'>
                        <div
                          className={`input-field ${
                            formik.values.proportionTinters !== "" ||
                            focusedField === proportionName
                              ? "active"
                              : ""
                          }`}
                        >
                          <label
                            htmlFor={proportionName}
                            className='toggle-label'
                          >
                            Proportion of Tinters
                          </label>
                          <input
                            id={proportionName}
                            name={proportionName}
                            type='text'
                            className='form-input'
                            onChange={e => {
                              setProportionHistory(oldList => {
                                let newList = JSON.parse(
                                  JSON.stringify(oldList),
                                )
                                newList.tinterHistory[index] = e.target.value
                                return newList
                              })

                              handleFormState(e.target.value, {
                                index,
                                name: "weight",
                                formikLabel: "proportionTinters",
                                listLabel: "unit",
                              })
                            }}
                            // value={formik.values.proportionTinters}
                            onInput={e => {
                              e.target.value = priceValidator(e.target.value)
                            }}
                            maxLength={10}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField(proportionName)}
                          />
                          {formik.touched[proportionName] &&
                          formik.errors[proportionName] ? (
                            <span className='err'>
                              {formik.errors[proportionName]}
                            </span>
                          ) : null}
                        </div>{" "}
                        <div className='input-field'>
                          <DropDownBox
                            options={optionsList.uomList}
                            customSetter={formik.setFieldValue}
                            defaultValue='UOM'
                            customFormikLabel={uomName}
                            customDropBoxStyles={{
                              border: "1px solid #d7cece",
                              borderRadius: "0.5rem",
                              color: "#333",
                            }}
                            customTextStyle={{
                              color: "#212229",
                              opacity: "0.7",
                            }}
                            customValueForCustomFunction={{
                              index,
                              name: "uomId",
                              formikLabel: "dynamicUom",
                              listLabel: "unitType",
                              listValue: "label",
                              valueLabel: "value",
                              propFormikLabel: { proportionName },
                            }}
                            callCustomFunction={handleFormState}
                            incomingValue={litreId}
                          />
                          {formik.touched[uomName] && formik.errors[uomName] ? (
                            <span className='err'>
                              {formik.errors[uomName]}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div
                  style={{ marginLeft: "auto", display: "flex", gap: "1rem" }}
                >
                  <button
                    type='button'
                    className='btn-primary header-btn'
                    title='Add Button'
                    onClick={handleAddTinters}
                  >
                    Add Tinters
                  </button>
                  {tintersList.length > 1 && (
                    <button
                      type='button'
                      className='btn-secondary'
                      onClick={handleRemoveTinters}
                    >
                      Remove Tinters
                    </button>
                  )}
                </div>
                <div className='col-2'>
                  <div className='input-field'>
                    {/* <DropDownBox
                      options={[
                        { label: "Buyers", value: "buyers" },
                        { label: "Suppliers", value: "suppliers" },
                        { label: "Both", value: "both" },
                      ]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"basePaint2"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Base Paint 2'
                      animateDropDownTitle
                    /> */}
                    <SearchDropDown
                      label='Base Paint Component B'
                      name='basePaint2'
                      api={getBasePaint}
                      customSetter={formik.setFieldValue}
                      dynamicLabel={"name"}
                      dynamicValue={"all"}
                      customFunctionOnClick={(
                        value,
                        customValueForCustomFunction,
                      ) => {
                        // if (formik.values.proportionPaint2) {
                        //   errorShakeAnimation({ id: "proportionPaint2" })
                        //   formik.setFieldValue("proportionPaint2", "")
                        // }

                        handleProductData(value, customValueForCustomFunction)
                      }}
                      customValueForFunctionOnClick={{
                        name: "basePaint2",
                        index: 1,
                        listLabel: "price",
                        valueLabel: "unitPrice",
                        formikLabel: "_id",
                      }}
                      showSize={true}
                    />
                    {formik.touched.basePaint2 && formik.errors.basePaint2 ? (
                      <span className='err'>{formik.errors.basePaint2}</span>
                    ) : null}
                  </div>{" "}
                  <div className='col-2 gstn'>
                    <div
                      className={`input-field ${
                        formik.values.proportionPaint2 !== "" ||
                        focusedField === "proportionPaint2"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor='proportionPaint2'
                        className='toggle-label'
                      >
                        Proportion Of Paint 2
                      </label>
                      <input
                        id='proportionPaint2'
                        name='proportionPaint2'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          formik.handleChange(e)
                          setProportionHistory(oldList => {
                            let newList = JSON.parse(JSON.stringify(oldList))
                            newList.prop2 = e.target.value
                            return newList
                          })
                          handleProductData(
                            { unitValue: e.target.value },
                            {
                              index: 1,
                              listLabel: "unit",
                              valueLabel: "unitValue",
                            },
                          )
                        }}
                        value={formik.values.proportionPaint2}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                        }}
                        maxLength={10}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("proportionPaint2")}
                      />
                      {formik.touched.proportionPaint2 &&
                      formik.errors.proportionPaint2 ? (
                        <span className='err'>
                          {formik.errors.proportionPaint2}
                        </span>
                      ) : null}
                    </div>{" "}
                    <div className='input-field'>
                      <DropDownBox
                        options={optionsList.uomList}
                        customSetter={formik.setFieldValue}
                        defaultValue='UOM'
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                        callCustomFunction={handleProductData}
                        customValueForCustomFunction={{
                          name: "uomOfPaint2",
                          index: 1,
                          listLabel: "unitType",
                          valueLabel: "label",
                          formikLabel: "value",
                          propFormikLabel: "proportionPaint2",
                        }}
                        // resetState={produc}
                        // resetSetter={value => {
                        //   handleProductData("", {
                        //     name: "uomOfPaint2",
                        //     index: 1,
                        //     listLabel: "unitType",
                        //     valueLabel: "label",
                        //     formikLabel: "value",
                        //     propFormikLabel: "proportionPaint2",
                        //   })
                        // }}
                        incomingValue={litreId}
                      />
                      {formik.touched.uomOfPaint2 &&
                      formik.errors.uomOfPaint2 ? (
                        <span className='err'>{formik.errors.uomOfPaint2}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <div className='col-2'>
                  <div className='input-field' style={{ position: "relative" }}>
                 
                    <SearchDropDown
                      label='Color'
                      name='color'
                      api={getOptionsList}
                      listName={"color-list"}
                      customSetter={formik.setFieldValue}
                      dynamicValue={"all"}
                    />
                    {formik.touched.color && formik.errors.color ? (
                      <span className='err'>{formik.errors.color}</span>
                    ) : null}
                    {formik?.values?.color && (
                      <div
                        // onClick={}
                        className=' color-preview-box show_search-common-animation'
                        style={{ background: formik?.values?.color?.hex_code }}
                        // title={}
                      >
                        <span
                          className='tooltip label flex-center'
                          data-title={`Hex: ${formik?.values?.color?.hex_code} Ral: ${formik?.values?.color?.ral_code} 
                          `}
                        >
                          <span
                            style={{
                              width: "25px",
                              height: "25px",
                            }}
                          ></span>
                        </span>
                      </div>
                    )}
                  </div>{" "}
                  <div
                    className='input-field '
                    style={{ position: "position" }}
                  >
                    <SearchDropDown
                      label='HSN Code'
                      name='hsnId'
                      api={getHsnList}
                      customSetter={formik.setFieldValue}
                      dynamicLabel={"hsn"}
                      maxLength={8}
                      type='number'
                      removeSpace
                    />
                    {formik.touched.hsnId && formik.errors.hsnId ? (
                      <span className='err'>{formik.errors.hsnId}</span>
                    ) : null}
                  </div>
                </div> */}
                {/* <div
                  className={`input-field ${
                    formik.values.packSize !== "" || focusedField === "packSize"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='packSize' className='toggle-label'>
                    Pack Size
                  </label>
                  <input
                    id='packSize'
                    name='packSize'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.packSize}
                    onInput={e => {
                      e.target.value = e.target.value.replace(/[^\d ]/gi, "")
                    }}
                    maxLength={10}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("packSize")}
                  />
                  {formik.touched.packSize && formik.errors.packSize ? (
                    <span className='err'>{formik.errors.packSize}</span>
                  ) : null}
                </div>{" "} */}
                <div className='col-2'>
                  {" "}
                  <div className='col-2 gstn'>
                    {/* <div className='input-field'>
                      <DropDownBox
                        options={[
                          { label: "3", value: "3" },
                          { label: "5", value: "5" },
                          { label: "10", value: "10" },
                          { label: "20", value: "20" },
                        ]}
                        customSetter={formik.setFieldValue}
                        customFormikLabel={"packSize"}
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                        dropDownTitle='Pack Size'
                        animateDropDownTitle
                      />
                      {formik.touched.packSize && formik.errors.packSize ? (
                        <span className='err'>{formik.errors.packSize}</span>
                      ) : null}
                    </div> */}
                    <div
                      className={`input-field ${
                        formik.values.packSize !== "" ||
                        focusedField === "packSize"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='packSize' className='toggle-label'>
                        Pack Size
                      </label>
                      <input
                        id='packSize'
                        name='packSize'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.packSize}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                        }}
                        maxLength={10}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("packSize")}
                      />
                      {formik.touched.packSize && formik.errors.packSize ? (
                        <span className='err'>{formik.errors.packSize}</span>
                      ) : null}
                    </div>{" "}
                    <div className='input-field'>
                      <DropDownBox
                        options={optionsList.uomList}
                        customSetter={formik.setFieldValue}
                        defaultValue='UOM'
                        customFormikLabel={"uom"}
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                        incomingValue={litreId}
                      />
                      {formik.touched.uom && formik.errors.uom ? (
                        <span className='err'>{formik.errors.uom}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className='input-field'>
                    <div
                      className={`input-field ${
                        formik.values.quantity !== "" ||
                        focusedField === "quantity"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='quantity' className='toggle-label'>
                        Produce Quantity In Liters
                      </label>
                      <div
                        className={
                          formik.values.quantity ? "margin_percentage_box" : ""
                        }
                      >
                        {
                          <span className='m_percentage'>
                            {formik.values.quantity ? "liter" : ""}
                          </span>
                        }{" "}
                        <input
                          id='quantity'
                          name='quantity'
                          type='text'
                          className='form-input'
                          onChange={formik.handleChange}
                          value={formik.values.quantity}
                          onInput={e => {
                            e.target.value = priceValidator(e.target.value)
                          }}
                          maxLength={7}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("quantity")}
                          onKeyDown={e => {
                            if (e.key === " ") {
                              e.preventDefault()
                            }
                          }}
                          // readOnly
                        />
                      </div>

                      {formik.touched.quantity && formik.errors.quantity ? (
                        <span className='err'>{formik.errors.quantity}</span>
                      ) : null}
                    </div>
                  </div>{" "}
                </div>{" "}
                <div className='input-field'>
                  {/* <DropDownBox
                      options={colorList}
                      customSetter={formik.setFieldValue}
                      dropDownTitle='Color'
                      animateDropDownTitle
                      customFormikLabel={"color"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      showSearchBar
                    /> */}
                  <SearchDropDown
                    label='Color'
                    name='color'
                    api={getOptionsList}
                    listName={"color-list"}
                    customSetter={formik.setFieldValue}
                    dynamicValue={"all"}
                  />
                  {formik.touched.color && formik.errors.color ? (
                    <span className='err'>{formik.errors.color}</span>
                  ) : null}
                  {/* {formik?.values?.color && (
                      <div
                        // onClick={}
                        className=' color-preview-box show_search-common-animation'
                        style={{ background: formik?.values?.color?.hex_code }}
                        // title={}
                      >
                        <Tooltip
                          message={`Hex: ${formik?.values?.color?.hex_code} Ral: ${formik?.values?.color?.ral_code}`}
                        >
                          <span
                            style={{
                              width: "25px",
                              height: "25px",
                            }}
                          ></span>
                        </Tooltip>
                      </div>
                    )} */}
                </div>{" "}
                <div className='col-2'>
                  <div className='input-field'>
                    {/* <DropDownBox
                      options={[{ label: "Acrylic", value: "Acrylic" }]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"paintType"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Paint Type'
                      animateDropDownTitle
                    /> */}
                    <SearchDropDown
                      label={"Paint Type"}
                      name='paintType'
                      api={getOptionsList}
                      customSetter={formik.setFieldValue}
                      listName={"painttype-list"}
                      dynamicLabel={"paint_type"}
                      // dynamicValue={"paint_type"}
                    />{" "}
                    {formik.touched.paintType && formik.errors.paintType ? (
                      <span className='err'>{formik.errors.paintType}</span>
                    ) : null}
                  </div>{" "}
                  <div className='input-field'>
                    {/* <DropDownBox
                      options={[{ label: "Matte", value: "Matte" }]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"finish"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Finish'
                      animateDropDownTitle
                    /> */}
                    <SearchDropDown
                      label='Finish Type'
                      customSetter={formik.setFieldValue}
                      name='finish'
                      listName={"finishtype-list"}
                      dynamicLabel={"finish_type"}
                      // dynamicValue={"finish_type"}
                      api={getOptionsList}
                    />
                    {formik.touched.finish && formik.errors.finish ? (
                      <span className='err'>{formik.errors.finish}</span>
                    ) : null}
                  </div>{" "}
                </div>
                <div className=''>
                  <div
                    className={`input-field ${
                      formik.values.costPrice !== "" ||
                      focusedField === "costPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='costPrice' className='toggle-label'>
                      Cost Price
                    </label>
                    <div className={formik.values.costPrice ? "phone_box" : ""}>
                      {
                        <span className='phone_code'>
                          {formik.values.costPrice ? "₹" : ""}
                        </span>
                      }{" "}
                      <input
                        id='costPrice'
                        name='costPrice'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          formik.setFieldValue(
                            "costPrice",
                            numberWithCommas(e.target.value),
                          )
                        }}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                        }}
                        value={formik.values.costPrice}
                        maxLength={14}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("costPrice")}
                      />
                    </div>

                    {formik.touched.costPrice && formik.errors.costPrice ? (
                      <span className='err'>{formik.errors.costPrice}</span>
                    ) : null}
                  </div>{" "}
                  {/* <div
                    className={`input-field ${
                      formik.values.unitPrice !== "" ||
                      focusedField === "unitPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='unitPrice' className='toggle-label'>
                      Unit Price
                    </label>
                    <div className={formik.values.unitPrice ? "phone_box" : ""}>
                      {
                        <span className='phone_code'>
                          {formik.values.unitPrice ? "₹" : ""}
                        </span>
                      }
                      <input
                        id='unitPrice'
                        name='unitPrice'
                        type='text'
                        className='form-input'
                        value={formik.values.unitPrice}
                        onChange={e => {
                          formik.setFieldValue(
                            "unitPrice",
                            numberWithCommas(e.target.value),
                          )
                        }}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                        }}
                        maxLength={14}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("unitPrice")}
                      />
                    </div>

                    {formik.touched.unitPrice && formik.errors.unitPrice ? (
                      <span className='err'>{formik.errors.unitPrice}</span>
                    ) : null}
                  </div>{" "} */}
                </div>
                <div className={role === 1 ? "col-2" : ""}>
                  {role === 1 && (
                    <div
                      className={`input-field ${
                        formik.values.marginPercentage !== "" ||
                        focusedField === "marginPercentage"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor='marginPercentage'
                        className='toggle-label'
                      >
                        Margin Percentage (%)
                      </label>
                      <input
                        id='marginPercentage'
                        name='marginPercentage'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.marginPercentage}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^\d ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        maxLength={2}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("marginPercentage")}
                        disabled
                      />
                      {formik.touched.marginPercentage &&
                      formik.errors.marginPercentage ? (
                        <span className='err'>
                          {formik.errors.marginPercentage}
                        </span>
                      ) : null}
                    </div>
                  )}
                  <div
                    className={`input-field ${
                      formik.values.sellingPrice !== "" ||
                      focusedField === "sellingPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='sellingPrice' className='toggle-label'>
                    Selling Unit Price
                    </label>
                    <div
                      className={formik.values.sellingPrice ? "phone_box" : ""}
                    >
                      {
                        <span className='phone_code'>
                          {formik.values.sellingPrice ? "₹" : ""}
                        </span>
                      }{" "}
                      <input
                        id='sellingPrice'
                        name='sellingPrice'
                        type='text'
                        className='form-input'
                        value={formik.values.sellingPrice}
                        onChange={e => {
                          formik.setFieldValue(
                            "sellingPrice",
                            numberWithCommas(e.target.value),
                          )
                        }}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                        }}
                        maxLength={14}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("sellingPrice")}
                      />
                    </div>

                    {formik.touched.sellingPrice &&
                    formik.errors.sellingPrice ? (
                      <span className='err'>{formik.errors.sellingPrice}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='footer flex-center'>
              <button
                type='submit'
                disabled={productsLoading}
                className='btn-primary'
                // disabled={profileLoading}
                style={productsLoading ? { cursor: "not-allowed" } : {}}
              >
                {productsLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddNewProduct
