/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
// import { deleteEmail, getEmailList } from "../redux/EmailApi";
import FilterBox from "../../../components/FilterBox"
import Skelaton from "../../../components/Skelaton"
import SearchBox from "../../../components/SearchBox"
import { getInventoryList } from "../redux/InventoryApi"
import { setPageDetails } from "../../auth/redux/authSlice"
import { unixTimeToReadableFormat } from "../../../helper/helper"
import Pagination from "../../../components/Pagination"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"

const Inventory = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Inventory Management")
  }, [setTitle])
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [active, setActive] = useState("inventoryList")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const [isEventCard, setIsEventCard] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const { inventoryList } = useSelector(state => state.inventory)

  const [checkedCollection, setCheckedCollection] = useState([])
  const [filterTable, setFilterTable] = useState({
    stock_quantity: true,
    sold: true,
    created_at: true,
    minimum_stock_quantity: true,
  })
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const { inventoryLoading, metaData } = useSelector(store => store.inventory)
  const [searchShow, setSearchShow] = useState(false)

  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      dispatch(
        getInventoryList({
          page: pageDetails?.page ? pageDetails?.page : 0,
          limit: records,
          search:
            Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
          sort: "desc",
        }),
      )
      setCheck(true)
      dispatch(setPageDetails({ page: metaData?.page, search }))
      return
    }

    const timeoutId = setTimeout(async () => {
      dispatch(
        getInventoryList({ limit: records, page: 0, search, sort: "desc" }),
      )
      setTableIndex(0)
      setBtnNo([1, 2, 3])
    }, 1000)
    dispatch(setPageDetails({ page: metaData?.page, search }))
    setSearchTimeout(timeoutId)
  }, [dispatch, records, search])

  useEffect(() => {
    if (pageDetails?.btnNo) {
      setBtnNo(pageDetails?.btnNo)
    }

    dispatch(setPageDetails({ page: metaData?.page, search }))
  }, [metaData])

  // useEffect(() => {
  //   const updateListData = async () => {
  //     if (emailList?.length > 0) {
  //       const newList = JSON.parse(JSON.stringify(emailList))

  //       newList.forEach(item => {
  //         item["checked"] = false
  //       })
  //       setData(newList)
  //     } else {
  //       setData([])
  //     }
  //   }
  //   updateListData()

  //   if (checkedCollection.length > 0) {
  //     checkedCollection.map(item => {
  //       if (item.page === metaData.page) {
  //         setData(oldList => {
  //           let newList = JSON.parse(JSON.stringify(oldList))
  //           newList.map(data => {
  //             if (item.collection.includes(data._id)) {
  //               data.checked = true
  //             }
  //           })

  //           return newList
  //         })
  //       }
  //     })
  //   }
  // }, [emailList])

  // const [tableLoading, setTableLoading] = useState(false)
  // const [tableCount, setTableCount] = useState(null)
  // // const [search, setSearch] = useState(
  // //   pageDetails?.search ? pageDetails?.search : "",
  // // )
  // useEffect(() => {
  //   if (tableCount) {
  //     clearTimeout(tableCount)
  //   }
  //   const count = setTimeout(() => {
  //     setTableLoading(inventoryLoading)
  //   }, 1000)
  //   setTableCount(count)
  // }, [inventoryLoading])
  const { tableLoading } = useLoaderDelay({ loadingState: inventoryLoading })

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        <div className='home-container'>
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
                api={getInventoryList}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
                api={getInventoryList}

              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
              api={getInventoryList}

            />
          )}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Inventory Management</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>
              <div className='data-table-container '>
                <div className='col1 heading-tools'>
                  <h3>Inventory List</h3>
                  <div className='table-search'>
                    <div className='search-container'>
                      <div className='search-filter-flex'>
                        <SearchBox
                          customSetter={setSearch}
                          customState={search}
                        />

                        <FilterBox
                          customState={filterTable}
                          customSetter={setFilterTable}
                          customPosition={{ right: "0", left: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton col={6} row={inventoryList?.length} />
                ) : inventoryList?.length > 0 ? (
                  <>
                    <div className='data-table-box scrollbar-visible'>
                      <table className='data-table '>
                        <thead>
                          <tr>
                            <th className='tb-head-name'>#</th>
                            <th>Products Name</th>
                            {filterTable.stock_quantity ? (
                              <th>Stock Quantity</th>
                            ) : null}
                            {filterTable.minimum_stock_quantity ? (
                              <th>MInimum Stock Quantity</th>
                            ) : null}
                            {filterTable.sold ? <th>Sold</th> : null}

                            {filterTable.created_at ? <th>Create On</th> : null}

                            {/* {Object.values(
                              privileges["inventory_management"],
                            ).includes("write") && <th>Action</th>} */}
                          </tr>
                        </thead>

                        <tbody>
                          {inventoryList?.map((item, index) => {
                            const {
                              productName,
                              quantity,
                              msl,
                              sold,
                              createdAt,
                              num,
                              _id,
                              uom,
                            } = item
                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  dispatch(
                                    setPageDetails({
                                      ...pageDetails,
                                      btnNo,
                                    }),
                                  )
                                  navigate(`/inventory/${_id}`)
                                }}
                                className='navig-table-row'
                                style={{ cursor: "pointer" }}
                              >
                                <td className='tb_data padding-07'>
                                  {/* {num} */}
                                  {num}
                                  {"."}
                                </td>
                                <td className='product_nav'>{productName} </td>
                                {filterTable.stock_quantity ? (
                                  <td title='Total quantity of all warehouses'>
                                    {quantity?.toFixed(2) + " " + " " + uom}

                                    {/* {Object.values(
                                      privileges["inventory_management"],
                                    ).includes("write") && (
                                      <button
                                        className='icon-btn edit'
                                        onClick={() => {}}
                                        style={{ marginLeft: "1rem" }}
                                      >
                                        <span className='material-symbols-outlined medium'>
                                          border_color
                                        </span>
                                      </button>
                                    )} */}
                                  </td>
                                ) : null}
                                {filterTable.minimum_stock_quantity ? (
                                  <td title='Minimum quantity of primary warehouse'>
                                    {msl?.toFixed(2) + " " + uom}
                                  </td>
                                ) : null}
                                {filterTable.sold ? (
                                  <td>{sold?.toFixed(2)}</td>
                                ) : null}

                                {filterTable.created_at ? (
                                  <td>{unixTimeToReadableFormat(createdAt)}</td>
                                ) : null}

                                {/* {Object.values(
                                  privileges["production_management"],
                                ).includes("write") && (
                                  // <button
                                  //   className="icon-btn edit"
                                  //   onClick={() => navigate(`/edit-email/`)}
                                  // >
                                  //   <span className="material-symbols-outlined medium">
                                  //     border_color
                                  //   </span>
                                  // </button>
                                  // <td className='table_nav'>
                                  //   <Link to='/inventory/id'>
                                  //     <span className='material-symbols-outlined'>
                                  //       open_in_new
                                  //     </span>
                                  //   </Link>
                                  // </td>
                                )} */}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                    <Pagination
                      sliceName={"inventory"}
                      search={search}
                      filter={"desc"}
                      api={getInventoryList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                    />
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inventory
