import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"
import { options } from "../../../helper/helper"
import { isCompanyToggle } from "./companySlice"
import profileSlice from "../../profile/redux/profileSlice"

export const getCompanyDetailData = createAsyncThunk(
  "company/company-details",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/get-company-detail`,
      })

      if (response.status === 200) {
        toast.success(response?.data?.message)
        return fulfillWithValue(response?.data)
      } else {
        toast.error(response?.data?.message?.message)
        return rejectWithValue()
      }
    } catch (error) {
      toast.error(error)
      return rejectWithValue()
    }
  },
)

export const updateCompanyDetailsData = createAsyncThunk(
  "company/update-company-details",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    dispatch(isCompanyToggle(true))
    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/update-self-company`,
        headers: {
          "Content-Type": "application/json",
        },
        data,
      })
      if (response.status === 200) {
        toast.success(response?.data?.message)
        dispatch(isCompanyToggle(false))
        dispatch(getCompanyDetailData())
        dispatch(getSelfCompanyAddress())
        return response?.status
      } else {
        toast.error(response?.data?.message)
        dispatch(isCompanyToggle(false))
      }
    } catch (error) {
      toast.error(error)
      dispatch(isCompanyToggle(false))
    }
  },
)

export const changeSelfCompanylogo = createAsyncThunk(
  "company/change-company-logo",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      // dispatch(isCompanyToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/change-selfCompanyLogo`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      })

      if (response.status === 200) {
        dispatch(getCompanyDetailData())
        // dispatch(isCompanyToggle(false))
        toast.success(response?.data?.message, options)
        return response?.data?.status
      } else {
        // dispatch(isCompanyToggle(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      // dispatch(isCompanyToggle(false))
      toast.error(err, options)
    }
  },
)

export const getSelfCompanyAddress = createAsyncThunk(
  "company/get-self-company-address",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/public/get-selfAddresses`,
      })
      if (response.status === 200) {
        return fulfillWithValue(response)
      } else {
        // toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const getBankDetails = createAsyncThunk(
  "company/get-bank-details",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/bank/list-bank?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort}&search=${data?.search}`,
      })

      if (response.status === 200) {
        return fulfillWithValue(response?.data)
      } else {
        // toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const addBankDetails = createAsyncThunk(
  "company/add-bank-details",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(isCompanyToggle(true))
      const response = await axios({
        method: data?.method ? data?.method : "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/bank/${data?.type}`,
        data: data?.send,
      })

      if (response.status === 200) {
        toast.success(response?.data?.message)
        dispatch(isCompanyToggle(false))
        dispatch(
          getBankDetails({
            page: "0",
            limit: "0",
            search: "",
            sort: "desc",
          }),
        ) // dispatch(getCompanyDetailData())
        // dispatch(getSelfCompanyAddress())
        return response?.status
      } else {
        dispatch(isCompanyToggle(false))
        toast.error(response?.data?.message)
        dispatch(isCompanyToggle(false))
      }
    } catch (error) {
      dispatch(isCompanyToggle(false))
      toast.error(error)
      rejectWithValue(error)
    }
  },
)
export const deleteBankDetail = createAsyncThunk(
  "company/delete-bank-delete",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCompanyToggle(true))
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/bank/delete-bank/${data.id}`,
        data: data?.send,
      })

      if (response.status === 200) {
        dispatch(isCompanyToggle(false))
        // toast.success(response?.data?.message, options);
        // dispatch(getCompanyDetailData())
        dispatch(
          getBankDetails({
            page: "0",
            limit: "0",
            search: "",
            sort: "desc",
          }),
        )
        return response?.data?.status
      } else {
        dispatch(isCompanyToggle(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isCompanyToggle(false))
      toast.error(err, options)
    }
  },
)
