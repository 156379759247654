import { useFormik } from "formik"
import React, { useState, useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import SearchBox from "../../../components/SearchBox"
import Skelaton from "../../../components/Skelaton"
import { useLocation } from "react-router-dom"
import { getProductDetail } from "../../purchase/redux/PurchaseApi"
import { ClipLoader } from "react-spinners"
import { numberWithCommas, priceValidator } from "../../../helper/helper"

const SalesProducts = ({
  formData,
  setFormData,
  setGrandTotals,
  grandTotals,
}) => {
  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const [toggleState, setToggleState] = useState(false)

  const dispatch = useDispatch()
  const location = useLocation()
  const { stockProductLoading } = useSelector(store => store.stock)

  const [focusedField, setFocusedField] = useState()
  const [hsnList, setHsnList] = useState([]) 
  const [showHsnBox, setShowHsnBox] = useState(false)

  const activePath = () => {
    if (location?.pathname === "/sales/sales-order") {
      return "isSalesOrder"
    } else if (location?.pathname === "/sales/sales-return") {
      return "isSalesReturn"
    } else if (location?.pathname === "/sales-quotation/create-quotation") {
      return "isSalesQuotation"
    } else if (location?.pathname === "/sales/sales-proforma-invoice") {
      return "isSalesProforma"
    } else if (location?.pathname === "/sales/delivery-challan") {
      return "isSalesDeliveryChallan"
    }
  }

  const commonFields = {
    sku_num: "",
    product_name: "",
    product_code: "",
    hsn_code: "",
    packSize: "",
    packQuantity: "",
    noOfPacks: "",
    totalQuantity: "",
    deliveryDate: "",
    totalAmount: "",
    additional_details: "",
    selectedProduct: "",
    gst: "",
    unit: "",
    hsn: "",
    status: { status: "pending", reason: "", comment: "" },
  }

  // Define purchase-order specific fields
  const salesOrderFields = {
    batchNo:"",
    taxAmount: "",
    prev_unitPrice: "",
    unitPrice: "",
    margin: "",
    taxableAmount: "",
    final_unitPrice: "",
  }
  const salesProformaFields = {
    prev_unitPrice: "",
    unitPrice: "",
    margin: "",
    final_unitPrice: "",
  }

  const salesDeliveryChallan = {
    batch_no: "",
    categories: "",
    taxableAmount: "",
    taxAmount: "",
  }
  // Define purchase-return specific fields
  const salesReturnFields = {
    deliverd_packs: "",
    sales_returnQty: "",
  }

  const getInitialValues = () => {
    const specificFields =
      activePath() === "isSalesOrder" || activePath() === "isSalesQuotation"
        ? salesOrderFields
        : activePath() === "isSalesReturn"
          ? salesReturnFields
          : activePath() === "isSalesProforma"
            ? salesProformaFields
            : salesDeliveryChallan
    return {
      rows: [{ ...commonFields, ...specificFields }],
    }
  }

  const getValidationSchema = isPurchaseOrder => {
    const commonValidationSchema = {
      sku_num: Yup.string().required("SKU is required"),
      product_name: Yup.string().required("Product Name is required"),
      batchNo: Yup.string().required("Batch is required"),
      deliveryDate: Yup.string().required("Delivery date is required"),
      product_code: Yup.string().required("Product code is required"),
      hsn_code: Yup.string().required("HSN code is required"),
      packQuantity: Yup.string().required("Pack Quantity is required"),
      totalQuantity: Yup.string().required("Total Quantity is required"),
      packSize: Yup.string().required("Pack Size is required"),
      noOfPacks: Yup.string().required("no. of pack is required"),
      totalAmount: Yup.string().required("Total amount is required"),
      additional_details: Yup.string().required(
        "Additional details is required",
      ),
    }

    const salesOrderValidationSchema = {
      taxableAmount: Yup.string().required("Amount is required"),
      taxAmount: Yup.string().required("Tax is required"),
      prev_unitPrice: Yup.string().required("previous price is required"),
      unitPrice: Yup.string().required("Unitprice is required"),
      margin: Yup.string().required("Margin is required"),
      final_unitPrice: Yup.string().required("Final unitprice is required"),
    }

    const salesReturnValidationSchema = {
      deliverd_packs: Yup.string().required("This field is required"),
      purchase_returnQty: Yup.string().required("Return quantity is required"),
    }

    const salesProformaValidationSchema = {
      prev_unitPrice: Yup.string().required("This field is required"),
      unitPrice: Yup.string().required("Unit price is required"),
      margin: Yup.string().required("Margin is required"),
      final_unitPrice: Yup.string().required("Final unitprice is required"),
    }

    const deliveryChallanValidationSchema = {
      batch_no: Yup.string().required("This field is required"),
      categories: Yup.string().required("Unit price is required"),
      taxableAmount: Yup.string().required("Amount is required"),
      taxAmount: Yup.string().required("Tax is required"),
    }

    const specificValidationSchema =
      activePath() === "isSalesOrder" || activePath() === "isSalesQuotation"
        ? salesOrderValidationSchema
        : activePath() === "isSalesReturn"
          ? salesReturnValidationSchema
          : activePath() === "isSalesProforma"
            ? salesProformaValidationSchema
            : deliveryChallanValidationSchema

    return Yup.object().shape({
      rows: Yup.array().of(
        Yup.object().shape({
          ...commonValidationSchema,
          ...specificValidationSchema,
        }),
      ),
    })
  }

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: getValidationSchema(),
    onSubmit: async values => {
      const options = values.rows.map((item, index) => {
        return {
          product_id: item.productId,
          quantity: item.quantity,
          price: item.unit,
        }
      })
    },
  }) 
  //
  const addRow = () => {
    const specificFields =
      activePath() === "isSalesOrder" || activePath() === "isSalesQuotation"
        ? salesOrderFields
        : activePath() === "isSalesReturn"
          ? salesReturnFields
          : activePath() === "isSalesProforma"
            ? salesProformaFields
            : salesDeliveryChallan
    formik.setFieldValue("rows", [
      ...formik.values.rows,
      { ...commonFields, ...specificFields },
    ])
  }

  const deleteRow = index => {
    const rows = [...formik.values.rows]
    rows.splice(index, 1)
    formik.setFieldValue("rows", rows)
  }

  const [searchTimeout, setSearchTimeout] = useState(null)
  const callApi = async (value, index) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    const timeoutId = setTimeout(async () => {
      if (value) {
        const response = await dispatch(getProductDetail({ search: value }))
        setHsnList(response?.payload?.data?.data)
      }
    }, 1000)
    setSearchTimeout(timeoutId)
  }

  const [search, setSearch] = useState("")

  const checkValue = valueList => {
    return valueList.length === 0 ? true : false
  }

  useEffect(() => {
    if (
      !formData?.productDetails &&
      checkValue(Object.keys(formik.errors)) &&
      formik.values?.rows[0].sku_num
    ) {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))
        newList.productDetails = { ...formik.values }

        return newList
      })
    } else {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))
        newList.productDetails = true
        return newList
      })
    }
  }, [formData?.productDetails])

  const findType = row => {
    return row?.selectedProduct?.uom ? row?.selectedProduct?.uom : ""
  }

  useEffect(() => {
    let grandTax = 0
    let grandSubtotal = 0
    let grandTotal = 0
    formik.values.rows.forEach(item => {
      const { taxAmount, totalAmount, taxableAmount } = item
      grandTax += Number(taxAmount)
      grandSubtotal += Number(taxableAmount)
      grandTotal += Number(totalAmount)
    })
    setGrandTotals(oldValues => {
      let newValues = JSON.parse(JSON.stringify(oldValues))

      newValues.taxAmount = grandTax
      newValues.subtotal = grandSubtotal
      newValues.grandTotal = grandTotal
      newValues.reservedSubtotal = grandSubtotal

      return newValues
    })
  }, [toggleState])

  const calculateRowValues = (formik, index, noOfPacks) => {
    const quantityInPack = formik.values.rows[index].selectedProduct?.quantityInPack;
    const unitPrice = formik.values.rows[index].selectedProduct?.unitPrice;
    const margin = formik.values.rows[index].margin;
    const GST = formik.values.rows[index].selectedProduct?.GST;

    const totalQuantity = priceValidator(quantityInPack * noOfPacks);
    const finalUnitPrice = priceValidator((unitPrice * margin) / 100 + unitPrice);
    const totalBeforeTax = priceValidator(totalQuantity * finalUnitPrice);
    const taxAmount = priceValidator(totalBeforeTax * (GST / 100));
    const totalAmount = priceValidator(Number(taxAmount) + Number(totalBeforeTax));

    // Update formik values
    formik.setFieldValue(`rows[${index}].final_unitPrice`, finalUnitPrice);
    formik.setFieldValue(`rows[${index}].totalQuantity`, totalQuantity);
    formik.setFieldValue(`rows[${index}].taxableAmount`, totalBeforeTax);
    formik.setFieldValue(`rows[${index}].taxAmount`, taxAmount);
    formik.setFieldValue(`rows[${index}].totalAmount`, totalAmount);
  };

  const calculateMarginValues = (formik, index, margin) => {

    const quantityInPack = formik.values.rows[index].selectedProduct?.quantityInPack;
    const unitPrice = formik.values.rows[index].selectedProduct?.unitPrice;
    const noOfPacks = formik.values.rows[index].noOfPacks;
    const GST = formik.values.rows[index].selectedProduct?.GST;

    const totalQuantity = priceValidator(quantityInPack * noOfPacks);
    const finalUnitPrice = priceValidator((unitPrice * margin) / 100 + unitPrice);
    const totalBeforeTax = priceValidator(totalQuantity * finalUnitPrice);
    const taxAmount = priceValidator(totalBeforeTax * (GST / 100));
    const totalAmount = priceValidator(Number(taxAmount) + Number(totalBeforeTax));

    // Update formik values
    formik.setFieldValue(`rows[${index}].final_unitPrice`, finalUnitPrice);
    formik.setFieldValue(`rows[${index}].totalQuantity`, totalQuantity);
    formik.setFieldValue(`rows[${index}].taxableAmount`, totalBeforeTax);
    formik.setFieldValue(`rows[${index}].taxAmount`, taxAmount);
    formik.setFieldValue(`rows[${index}].totalAmount`, totalAmount);
  }


  return (
    <>
      <div
        className='main-box-padding data-table-container'
        style={{ padding: "1.8rem", borderRadius: "1.5rem" }}
      >
        <div className='modelEx_content singleAdd'>
          <div className='profile_personal_details'>
            <div className='col1 heading-tools details-controls'>
              <h2>Product Details</h2>
              <div className='table-search'>
                <div className='search-container'>
                  <SearchBox customSetter={setSearch} customState={search} />
                  <div className='create-btn-container'>
                    {Object.values(privileges["purchase_management"]).includes(
                      "write",
                    ) && (
                        <button
                          className='btn-secondary header-btn  '
                          onClick={addRow}
                        >
                          Add Products
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {false ? (
              <Skelaton col={4} row={5} />
            ) : true ? (
              <>
                <div className='modelEx_content'>
                  {/* <div className='data-table-container'> */}
                  <form onSubmit={formik.handleSubmit}>
                    <div
                      className='td_table_container'
                    // style={{paddingBottom:"6rem"}}
                    >
                      <table className='td_table'>
                        <thead>
                          <tr>
                            <th>SKU</th>
                            <th>Product Name</th>
                            <th>Batch</th>
                            <th>Product Code</th>
                            <th>HSN Code</th>
                            {activePath() === "isSalesDeliveryChallan" && (
                              <>
                                <th>Batch No.</th>
                                <th>Categories</th>
                              </>
                            )}

                            <th>Pack Size</th>
                            <th>Pack Quantity</th>
                            <th>No. of packs</th>
                            <th>Total Quantity</th>
                            {(activePath() === "isSalesOrder" ||
                              activePath() === "isSalesProforma" ||
                              activePath() === "isSalesQuotation") && (
                                <>
                                  <th>Previous Unit Price</th>
                                  <th>Unit Price</th>
                                  <th>Margin</th>
                                  <th>Final Unit Price</th>
                                </>
                              )}
                            {(activePath() === "isSalesOrder" ||
                              activePath() === "isSalesDeliveryChallan" ||
                              activePath() === "isSalesQuotation") && (
                                <>
                                  <th>Amount</th>
                                  <th>Tax</th>
                                </>
                              )}
                            <th>Total Amount</th>

                            {activePath() === "isSalesReturn" && (
                              <>
                                <th>Delivered Products</th>
                                <th>Sales Return Qty.</th>
                              </>
                            )}
                            <th>Delivery Date</th>
                            <th>Additional Details</th>
                            <td className='stick-rh' />
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.rows.map((row, index) => (
                            <tr key={index}>
                              {" "}
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].sku_num`}
                                    onChange={formik.handleChange}
                                    value={row.sku_num}
                                    disabled
                                    style={{ cursor: "not-allowed" }}
                                    // disabled
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.sku_num && formik?.touched?.rows[index]?.sku_num ? "error" : ""}`}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  className='hsnBox'
                                  style={{ width: "300px" }}
                                >
                                  <div className='input-field'>
                                    <input
                                      type='text'
                                      name={`rows[${index}].product_name`}
                                      onChange={e => {
                                        formik.handleChange(e)
                                        callApi(e.target.value, index)
                                        setShowHsnBox(true)
                                      }}
                                      maxLength={81}
                                      onBlur={e => {
                                        formik.handleBlur(e)
                                      }}
                                      onFocus={() => setFocusedField(index)}
                                      className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.product_name && formik?.touched?.rows[index]?.product_name ? "error" : ""}`}
                                      value={row.product_name}
                                    />
                                  </div>
                                  {formik.values.rows[index].product_name
                                    .length >= 1 &&
                                    index === focusedField &&
                                    showHsnBox && (
                                      <div className='hsnSelectBox'>
                                        {hsnList?.length > 0 &&
                                          !stockProductLoading ? (
                                          hsnList?.map((item, i) => { 
                                            const { _id, name, brandName } =
                                              item
                                            return (
                                              <div
                                                key={i}
                                                className={
                                                  "drop-down-item2" +
                                                  (formik?.values?.rows[index]
                                                    ?.product_name === name
                                                    ? " selectedDropBox"
                                                    : "")
                                                }
                                                onClick={() => {
                                                  formik.setFieldValue(
                                                    `rows[${index}].product_name`,
                                                    name,
                                                  )
                                                  // formik.setFieldValue(
                                                  //   `rows[${index}].productId`,
                                                  //   _id,
                                                  // )

                                                  if (row.noOfPacks) {
                                                    formik.setFieldValue(
                                                      `rows[${index}].noOfPacks`,
                                                      "",
                                                    )
                                                  }
                                                  formik.setFieldValue(
                                                    `rows[${index}].selectedProduct`,
                                                    item,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].sku_num`,
                                                    item.sku,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].product_code`,
                                                    item.productCode,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].category`,
                                                    item.categoryName,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].hsn_code`,
                                                    item.hsnNumber,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].packQuantity`,
                                                    item?.quantityInPack,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].packSize`,
                                                    item?.packSize,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].productId`,
                                                    item?.productId,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].unitPrice`,
                                                    item.unitPrice,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].sellingPrice`,
                                                    item.sellingPrice,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].gst`,
                                                    item.GST,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].hsn`,
                                                    item.HSN,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].unit`,
                                                    item.unit,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].margin`,
                                                    item.margin,
                                                  )
                                                  formik.setFieldValue(
                                                    `rows[${index}].prev_unitPrice`,
                                                    item.lastSellingPrice,
                                                  )
                                                  setShowHsnBox(false)
                                                  setHsnList([])
                                                }}
                                              >
                                                <span>{`${name} (${brandName})`}</span>
                                              </div>
                                            )
                                          })
                                        ) : (
                                          <div className='drop-down-item2'>
                                            {stockProductLoading ? (
                                              <ClipLoader
                                                loading={true}
                                                size={13}
                                                aria-label='Loading Spinner'
                                                data-testid='loader'
                                              />
                                            ) : (
                                              <span>No Data Found</span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].batchNo`}
                                    onChange={formik.handleChange}
                                    value={row.batchNo}
                                    style={{ cursor: "not-allowed" }}
                                    // disabled
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.batchNo && formik?.touched?.rows[index]?.batchNo ? "error" : ""}`}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].product_code`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.product_code && formik?.touched?.rows[index]?.product_code ? "error" : ""}`}
                                    value={row.product_code}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].hsn_code`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.hsn_code && formik?.touched?.rows[index]?.hsn_code ? "error" : ""}`}
                                    value={row.hsn_code}
                                    // disabled={
                                    //   fromStore && toStore ? false : true
                                    // }
                                    disabled
                                  />
                                </div>
                              </td>
                              {activePath() === "isSalesDeliveryChallan" && (
                                <>
                                  <td>
                                    <div className='input-field'>
                                      <input
                                        type='text'
                                        name={`rows[${index}].batch_no`}
                                        onChange={formik.handleChange}
                                        // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                        className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.batch_no && formik?.touched?.rows[index]?.batch_no ? "error" : ""}`}
                                        value={row.batch_no}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='input-field'>
                                      <input
                                        type='text'
                                        name={`rows[${index}].categories`}
                                        onChange={formik.handleChange}
                                        // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                        className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.categories && formik?.touched?.rows[index]?.categories ? "error" : ""}`}
                                        value={row.categories}
                                      />
                                    </div>
                                  </td>
                                </>
                              )}
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].packSize`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.packSize && formik?.touched?.rows[index]?.packSize ? "error" : ""}`}
                                    value={`${row.packSize} ${findType(row)}`}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].packQuantity`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.packQuantity && formik?.touched?.rows[index]?.packQuantity ? "error" : ""}`}
                                    value={`${row.packQuantity} ${findType(row)}`}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].noOfPacks`}
                                    onChange={e => {
                                      formik.handleChange(e);
                                      calculateRowValues(formik, index, e.target.value);
                                      setToggleState(!toggleState);
                                    }}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.noOfPacks && formik?.touched?.rows[index]?.noOfPacks ? "error" : ""}`}
                                    value={row.noOfPacks}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].totalQuantity`}
                                    onChange={formik.handleChange}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.totalQuantity && formik?.touched?.rows[index]?.totalQuantity ? "error" : ""}`}
                                    value={`${numberWithCommas(row.totalQuantity)} ${row.totalQuantity ? findType(row) : ""}`}
                                    disabled
                                  />
                                </div>
                              </td>
                              {(activePath() === "isSalesOrder" ||
                                activePath() === "isSalesProforma" ||
                                activePath() === "isSalesQuotation") && (
                                  <>
                                    <td>
                                      <div className='input-field'>
                                        <input
                                          type='text'
                                          name={`rows[${index}].prev_unitPrice`}
                                          onChange={formik.handleChange}
                                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.prev_unitPrice && formik?.touched?.rows[index]?.prev_unitPrice ? "error" : ""}`}
                                          value={row.prev_unitPrice}
                                          disabled
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className='input-field'>
                                        <input
                                          type='text'
                                          name={`rows[${index}].unitPrice`}
                                          onChange={formik.handleChange}
                                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.unitPrice && formik?.touched?.rows[index]?.unitPrice ? "error" : ""}`}
                                          value={row.unitPrice}
                                          disabled
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className='input-field'>
                                        <input
                                          type='text'
                                          name={`rows[${index}].margin`}
                                          onChange={e => {
                                            formik.handleChange(e);
                                            calculateMarginValues(formik, index, e.target.value);
                                            setToggleState(!toggleState);
                                          }}
                                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.margin && formik?.touched?.rows[index]?.margin ? "error" : ""}`}
                                          value={row.margin}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className='input-field'>
                                        <input
                                          type='text'
                                          name={`rows[${index}].final_unitPrice`}
                                          onChange={formik.handleChange}
                                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.final_unitPrice && formik?.touched?.rows[index]?.final_unitPrice ? "error" : ""}`}
                                          value={row.final_unitPrice}
                                          // disabled={
                                          //   fromStore && toStore ? false : true
                                          // }
                                          disabled
                                        />
                                      </div>
                                    </td>
                                  </>
                                )}
                              {(activePath() === "isSalesOrder" ||
                                activePath() === "isSalesDeliveryChallan" ||
                                activePath() === "isSalesQuotation") && (
                                  <>
                                    <td>
                                      <div className='input-field'>
                                        <input
                                          type='text'
                                          name={`rows[${index}].taxableAmount`}
                                          onChange={formik.handleChange}
                                          // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.taxableAmount && formik?.touched?.rows[index]?.taxableAmount ? "error" : ""}`}
                                          value={row.taxableAmount}
                                          disabled
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className='input-field'>
                                        <input
                                          type='text'
                                          name={`rows[${index}].taxAmount`}
                                          onChange={formik.handleChange}
                                          // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                          className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.taxAmount && formik?.touched?.rows[index]?.taxAmount ? "error" : ""}`}
                                          value={row.taxAmount}
                                          disabled
                                        />
                                      </div>
                                    </td>
                                  </>
                                )}
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].totalAmount`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.totalAmount && formik?.touched?.rows[index]?.totalAmount ? "error" : ""}`}
                                    value={row.totalAmount}
                                    disabled
                                  />
                                </div>
                              </td>
                              {activePath() === "isSalesReturn" && (
                                <>
                                  <td>
                                    <div className='input-field'>
                                      <input
                                        type='number'
                                        name={`rows[${index}].deliverd_packs`}
                                        onChange={formik.handleChange}
                                        // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                        className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.deliverd_packs && formik?.touched?.rows[index]?.deliverd_packs ? "error" : ""}`}
                                        value={row.deliverd_packs}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='input-field'>
                                      <input
                                        type='number'
                                        name={`rows[${index}].sales_returnQty`}
                                        onChange={formik.handleChange}
                                        // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                        className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.sales_returnQty && formik?.touched?.rows[index]?.sales_returnQty ? "error" : ""}`}
                                        value={row.sales_returnQty}
                                      />
                                    </div>
                                  </td>
                                </>
                              )}
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='date'
                                    name={`rows[${index}].deliveryDate`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.deliveryDate && formik?.touched?.rows[index]?.deliveryDate ? "error" : ""}`}
                                    value={row.deliveryDate}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].additional_details`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.additional_details && formik?.touched?.rows[index]?.additional_details ? "error" : ""}`}
                                    value={row.additional_details}
                                  />
                                </div>
                              </td>
                              <td className='stick-rh'>
                                <button
                                  type='button'
                                  className='icon-btn delete'
                                  onClick={() => deleteRow(index)}
                                  disabled={
                                    formik.values.rows.length === 1
                                      ? true
                                      : false
                                  }
                                >
                                  <span className='material-symbols-outlined medium'>
                                    delete
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className='error-msg'>
                        {formik.errors.rows
                          ? "Please fill the data first!"
                          : ""}
                      </div>
                    </div>
                  </form>
                  {/* </div> */}
                </div>
              </>
            ) : (
              <div className='Nodata-txt'>
                <img
                  src={require("../../../assets/images/nodata.svg").default}
                  alt=''
                  srcSet=''
                />
                No Data Found
              </div>
            )}
            {/* <div className='create-btn-container'>
                {Object.values(privileges["purchase_management"]).includes(
                  "write",
                ) && (
                  <button className='btn-secondary header-btn  ' onClick={addRow}>
                    Add Row
                  </button>
                )}
              </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default SalesProducts
