import { createAsyncThunk } from "@reduxjs/toolkit"
import { AddNumField } from "../../../helper/helper"
import axios from "axios"
import { toast } from "react-toastify"
import { isInventoryToggle } from "./InventorySlice"

export const getInventoryList = createAsyncThunk(
  "inventory/get-inventory-list",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/inventory/inventory-list?search=${data.search}&page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}`,
        method: "POST",
      })

      if (response?.status === 200) {
        if (response?.data?.data.length > 0) {
          const res = await AddNumField(
            { data: response.data.data, metadata: response.data.metadata },
            data?.page * data.limit,
          )

          return fulfillWithValue(res)
        } else {
          const response = await axios({
            url: `${process.env.REACT_APP_AUTH_URL}/inventory/inventory-list?search=${data.search}&page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}`,
            method: "POST",
          })

          if (response?.status === 200) {
            const res = await AddNumField(
              response?.data?.data[0],
              data?.page * data.limit,
            )

            return fulfillWithValue(res)
          } else {
            toast.error(response.data.message)
            return rejectWithValue()
          }
        }
      } else {
        // toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const getInventoryDetails = createAsyncThunk(
  "inventory/get-inventory-details",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/inventory/inventory-detail/${data.id}`,
      })

      if (response.status === 200) {
        return fulfillWithValue(response)
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const getInventoryHistory = createAsyncThunk(
  "inventory/get-history-details",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/inventory/inventory-history-list?page=${data?.page}&limit=${data?.limit}&skip&sort=desc`,
        data: data.send,
      })

      if (response.status === 200) {
        if (response?.data?.data.length > 0) {
          const res = await AddNumField(
            { data: response.data.data, metadata: response.data.metadata },
            data?.page * data.limit,
          )

          return fulfillWithValue(res)
        }
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const updateInventory = createAsyncThunk(
  "inventory/update-inventory",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isInventoryToggle(true))
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/inventory/manage-inventory`,
        method: "PUT",
        data,
      })

      if (response.status === 200) {
        toast.success(response.data.message)
        dispatch(getInventoryDetails({ id: data?.product_id }))
        // dispatch(getInventoryHistory({ product_id: data?.product_id }))
        dispatch(
          getInventoryHistory({
            page: "0",
            limit: "5",
            search: "",
            sort: "desc",
            send: { product_id: data?.product_id },
          }),
        )
        dispatch(isInventoryToggle(false))
        return fulfillWithValue(response.status)
      } else {
        toast.error(response.data.message)
        dispatch(isInventoryToggle(false))
        return rejectWithValue()
      }
    } catch (error) {
      dispatch(isInventoryToggle(false))
      return rejectWithValue()
    }
  },
)
