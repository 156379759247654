/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Pagination from "../../../components/Pagination"
import SearchBox from "../../../components/SearchBox"
import Sidebar from "../../../components/Sidebar"
import Skelaton from "../../../components/Skelaton"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
import AddDepartment from "../components/AddDepartment"
import EditDepartment from "../components/EditDepartment"
import { deleteDepartment, getDepartmentList } from "../redux/DepartmentApi"

const Department = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Department Management")
  }, [setTitle])
  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const [active, setActive] = useState("department")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  // const [dList, setDepartmentList] = useState(departmentData);
  const [isEventCard, setIsEventCard] = useState(false)
  const [popupData, setPopupData] = useState()
  const [deleteAllPopup, setDeleteAllPopup] = useState(false)
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  const dispatch = useDispatch()

  const { departmentLoading, departmentList, metaData } = useSelector(
    store => store.department,
  )
  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )

  const [data, setData] = useState(null)
  const [selectAllProducts, setSelectAllProducts] = useState(false)
  const [collectAllProducts, setCollectAllProducts] = useState([])

  const [departmentPopup, setDepartmentPopup] = useState({
    addDepartment: false,
    editDepartment: false,
    departmentDetail: false,
  })
  // useEffect(() => {
  //   let empArr = [];
  //   dList.forEach((item) => {
  //     item.forEach((item2) => {
  //       if (item2.checked === true) {
  //         empArr.push(item);
  //       }
  //     });
  //   });
  //   setSelectedList(empArr);
  // }, [dList]);

  // const handleChange = (no) => {
  //   // setTableIndex(index);
  //   if (
  //     no > 0 &&
  //     no <= new Array(metaData?.totalPages).fill(undefined).length
  //   ) {
  //     setTableIndex(no - 1);
  //   }
  //   if (
  //     no > 1 &&
  //     no < new Array(metaData?.totalPages).fill(undefined).length - 1
  //   ) {
  //     if (no < btnNo[1]) {
  //       setBtnNo([btnNo[0] - 1, btnNo[1] - 1, btnNo[2] - 1]);
  //     } else if (no > btnNo[1]) {
  //       setBtnNo([btnNo[0] + 1, btnNo[1] + 1, btnNo[2] + 1]);
  //     }
  //   }
  // };

  // const handleLastBtn = () => {
  //   setTableIndex(new Array(metaData?.totalPages).fill(undefined).length - 1);
  //   setBtnNo([
  //     new Array(metaData?.totalPages).fill(undefined).length - 3,
  //     new Array(metaData?.totalPages).fill(undefined).length - 2,
  //     new Array(metaData?.totalPages).fill(undefined).length - 1,
  //   ]);
  //   dispatch(
  //     getDepartmentList({
  //       page: new Array(metaData?.totalPages).fill(undefined).length - 1,
  //       limit: 5,
  //       sort:"desc",
  //       search,
  //     })
  //   );
  // };

  // const handleFirstBtn = () => {
  //   setTableIndex(0);
  //   setBtnNo([1, 2, 3]);
  //   dispatch(
  //     getDepartmentList({
  //       page: 0,
  //       limit: 5,
  //       sort:"desc",
  //       search,
  //     })
  //   );
  // };

  // const handleCheckBoxChange = (index) => {
  //   setDepartmentList((oldList) => {
  //     let newList = JSON.parse(JSON.stringify(oldList));
  //     newList[tableIndex][index].checked = !newList[tableIndex][index].checked;
  //     return newList;
  //   });
  // };

  const deleteFunction = (id, title) => {
    setPopupData({
      data: {
        id,
        page: 0,
        search: "",
        sort: "desc",
        limit: "5",
        send: {
          is_delete: true,
          department_ids: selectAllProducts ? collectAllProducts : [id],
        },
      },
      title,
    })
    setIsEventCard(true)
  }
  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      dispatch(
        getDepartmentList({ limit: records, page: 0, search, sort: "desc" }),
      )
      setCheck(true)
      return
    }

    const timeoutId = setTimeout(async () => {
      dispatch(
        getDepartmentList({ limit: records, page: 0, search, sort: "desc" }),
      )
      setTableIndex(0)
      setBtnNo([1, 2, 3])
      if (selectAllProducts) {
        setSelectAllProducts(false)
        setCollectAllProducts([])
      }
    }, 1000)

    setSearchTimeout(timeoutId)
  }, [dispatch, records, search])

  // const handleCheckBoxChange = (index, id, status) => {
  //   setData((oldList) => {
  //     let newList = JSON.parse(JSON.stringify(oldList));
  //     newList[index].checked = !newList[index].checked;
  //     return newList;
  //   });

  //   if (checkedCollection?.length > 0) {
  //     const finder = checkedCollection?.filter(
  //       (item, index) => item?.page === metaData?.page
  //     )[0];

  //     if (finder) {
  //       const checkedIndex = checkedCollection.findIndex(
  //         (item) => item === finder
  //       );
  //       setCheckedCollection((oldList) => {
  //         const newList = JSON.parse(JSON.stringify(oldList));

  //         status
  //           ? newList[checkedIndex]?.collection.push(id)
  //           : newList[checkedIndex]?.collection.splice(
  //               newList[checkedIndex]?.collection.findIndex(
  //                 (item) => item === id
  //               ),
  //               1
  //             );

  //         return newList;
  //       });
  //     } else {
  //       setCheckedCollection([
  //         ...checkedCollection,
  //         { page: metaData?.page, collection: [id] },
  //       ]);
  //     }
  //   } else {
  //     setCheckedCollection([{ page: metaData?.page, collection: [id] }]);
  //   }
  // };

  useEffect(() => {
    const updateListData = async () => {
      if (departmentList?.length > 0) {
        const newList = JSON.parse(JSON.stringify(departmentList))

        newList.forEach(item => {
          item["checked"] = false
        })
        setData(newList)
      } else {
        setData([])
      }
    }
    updateListData()

    // if (checkedCollection.length > 0) {
    //   checkedCollection.map((item) => {
    //     if (item.page === metaData.page) {
    //       setData((oldList) => {
    //         let newList = JSON.parse(JSON.stringify(oldList));
    //         newList.map((data) => {
    //           if (item.collection.includes(data._id)) {
    //             data.checked = true;
    //           }
    //         });

    //         return newList;
    //       });
    //     }
    //   });
    // }
  }, [departmentList])

  // const [searchShow, setSearchShow] = useState(false)
  // const [tableLoading, setTableLoading] = useState(false)
  // const [tableCount, setTableCount] = useState(null)

  // useEffect(() => {
  //   if (tableCount) {
  //     clearTimeout(tableCount)
  //   }
  //   const count = setTimeout(() => {
  //     // if (departmentLoading) {
  //     //   setTableLoading(true);
  //     // } else {
  //     //   setTableLoading(false);
  //     // }
  //     setTableLoading(departmentLoading)
  //   }, 1000)
  //   setTableCount(count)
  // }, [departmentLoading])

  const { tableLoading } = useLoaderDelay({ loadingState: departmentLoading })

  useEffect(() => {
    const idArr = departmentList?.map(item => {
      return item._id
    })
    if (selectAllProducts) {
      setCollectAllProducts(idArr)
    } else {
      setCollectAllProducts([])
    }
  }, [selectAllProducts])

  useEffect(() => {
    if (selectAllProducts && collectAllProducts.length === 0) {
      setSelectAllProducts(false)
      setCollectAllProducts([])
      return
    }
  }, [collectAllProducts])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  return (
    <div
      className='container'
      style={
        !isEventCard &&
        !departmentPopup.addDepartment &&
        !departmentPopup.editDepartment &&
        !departmentPopup.departmentDetail &&
        !deleteAllPopup
          ? heightAuto
          : null
      }
    >
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Department Management</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
                {/* <div className="mch2">
                  <div className="mch2-duration">
                    August 1,2020 - August 31,2020
                  </div>
                  <div
                    className="mch2-action"
                    onClick={() => setAction(!action)}
                  >
                    <span className="">Action</span>
                    <span className="material-symbols-rounded expand">
                      expand_more
                    </span>
                    {action && (
                      <div className="action-tooltip">
                        <span className="">Download</span>
                        <span className="">Print</span>
                      </div>
                    )}
                  </div>
                </div> */}

                <div className='table-search'></div>
              </div>
              <div className='data-table-container'>
                <div className='heading-tools'>
                  <h3>Department List</h3>
                  <div className='table-search'>
                    <div className='col1 search-container'>
                      <SearchBox
                        customSetter={setSearch}
                        customState={search}
                      />

                      {Object.values(
                        privileges["department_management"],
                      ).includes("write") && (
                        <button
                          className='btn-primary header-btn'
                          onClick={() =>
                            setDepartmentPopup({
                              ...departmentPopup,
                              addDepartment: !departmentPopup.addDepartment,
                            })
                          }
                        >
                          Add
                        </button>
                      )}

                      {Object.values(
                        privileges["department_management"],
                      ).includes("delete") && selectAllProducts ? (
                        <button
                          className='btn-secondary header-btn delete-primary-button'
                          onClick={() => {
                            deleteFunction("all products")
                          }}
                        >
                          <span className='material-symbols-outlined medium'>
                            delete
                          </span>{" "}
                          Delete{" "}
                          {collectAllProducts.length > 1
                            ? "All " + collectAllProducts.length
                            : ""}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton
                    col={5}
                    row={departmentList?.length ? departmentList?.length : 5}
                  />
                ) : (
                  <>
                    {" "}
                    {/* {selectedList.length > 0 && (
                  <div className="selectAll-container">
                    <div className="selectedDetails">
                      <span>
                        {selectedList?.length} /{" "}
                        {(dList.length - 1) * 5 +
                          dList[dList.length - 1]?.length}{" "}
                        Departments selected.
                      </span>{" "}
                      <span
                        className="slc-btn"
                        onClick={handleSelectAllAndCheck}
                      >
                        {selectedList.length ===
                        (dList.length - 1) * 5 + dList[dList.length - 1]?.length
                          ? "Unselect"
                          : "Select"}{" "}
                        all {dList.length * 5} department
                      </span>
                    </div>
                    <div className="selectedAction">
                      {" "}
                      <button
                        className="btn-primary header-btn"
                        onClick={() => {
                          setDepartmentList(departmentData);
                          setSelectedList([]);
                          setSelectAllChecked([
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                            { checked: false },
                          ]);
                          setDeleteAllPopup(!deleteAllPopup);
                        }}
                      >
                        <span className="material-symbols-outlined medium">
                          Delete
                        </span>
                      </button>
                    </div>
                  </div>
                )}  */}
                    {departmentList.length > 0 ? (
                      <>
                        <div className='data-table-box scrollbar-visible'>
                          <table className='data-table fix-width-table-row'>
                            <thead>
                              <tr>
                                {/* eslint-disable-next-line */}
                                {/* {selectAllChecked?.map((item, index) => {
                          const { checked } = item;
                          if (index === tableIndex) {
                            return (
                              <th key={index} className="tb-head-name">
                                <input
                                  id={index}
                                  type="checkbox"
                                  className="checkbox-animated"
                                  checked={checked}
                                  onChange={() => handleCheckBoxChange(index)}
                                />
                                <label htmlFor={index}>
                                  <span></span>
                                </label>
                              </th>
                            );
                          }
                        })} */}
                                <th className='tb-head-name padding-07 '>
                                  <span>
                                    <input
                                      name='checkbox'
                                      id='checkbox'
                                      type='checkbox'
                                      className='checkbox-animated'
                                      onChange={() =>
                                        setSelectAllProducts(!selectAllProducts)
                                      }
                                      checked={selectAllProducts}
                                    />
                                    <label htmlFor={"checkbox"}>
                                      <span className='filter-check-background'></span>
                                    </label>
                                  </span>
                                </th>
                                <th>Name Of Department</th>
                                <th style={{ textAlign: "center" }}>
                                  Description
                                </th>
                                <th>Manager</th>
                                {Object?.values(
                                  privileges["department_management"],
                                )?.includes("read") && <th>Actions</th>}
                              </tr>
                            </thead>

                            <tbody>
                              {data?.map((item, index) => {
                                const { name, manager, _id, num, description } =
                                  item
                                //
                                return (
                                  <tr key={index}>
                                    <td className='tb_data padding-07'>
                                      {selectAllProducts ? (
                                        <span
                                          onChange={
                                            e => e.stopPropagation()
                                            // setSelectAllProducts(
                                            //   !selectAllProducts,
                                            // )
                                          }
                                        >
                                          <input
                                            name={"checkbox" + index}
                                            id={"checkbox" + index}
                                            type='checkbox'
                                            className='checkbox-animated'
                                            onChange={e => {
                                              e.stopPropagation()
                                              setCollectAllProducts(oldList => {
                                                let newList = JSON.parse(
                                                  JSON.stringify(oldList),
                                                )

                                                if (newList.includes(_id)) {
                                                  newList.splice(
                                                    newList.indexOf(_id),
                                                    1,
                                                  )
                                                } else {
                                                  newList.unshift(_id)
                                                }

                                                return newList
                                              })
                                            }}
                                            checked={collectAllProducts.includes(
                                              _id,
                                            )}
                                          />
                                          <label htmlFor={"checkbox" + index}>
                                            <span className='filter-check-background'></span>
                                          </label>
                                        </span>
                                      ) : (
                                        num + "."
                                      )}
                                      {/* {num}
                                      {"."} */}
                                    </td>
                                    <td className='company_details'>
                                      {name} <br />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <span>{description}</span>
                                    </td>
                                    <td className='tags_heading'>
                                      {manager
                                        ? manager
                                        : "Manager Not Assigned Yet"}
                                    </td>
                                    <td className=' padding-07'>
                                      <div className='email-action-box'>
                                        {Object.values(
                                          privileges["department_management"],
                                        ).includes("read") && (
                                          <button
                                            className='icon-btn see'
                                            onClick={() => {
                                              setDepartmentPopup({
                                                ...departmentPopup,
                                                editDepartment:
                                                  !departmentPopup.editDepartment,
                                              })
                                              setPopupData(item)
                                            }}
                                          >
                                            <span className='material-symbols-outlined medium'>
                                              visibility
                                            </span>
                                          </button>
                                        )}
                                        {/* {Object.values(
                                        privileges["department_management"],
                                      ).includes("read") && (
                                        <button
                                          className='icon-btn see'
                                          onClick={() => {
                                            setDepartmentPopup({
                                              ...departmentPopup,
                                              departmentDetail:
                                                !departmentPopup.departmentDetail,
                                            })
                                            setPopupData(item)
                                          }}
                                        >
                                          <span className='material-symbols-outlined medium'>
                                            visibility
                                          </span>
                                        </button>
                                      )} */}
                                        {Object.values(
                                          privileges["department_management"],
                                        ).includes("delete") && (
                                          <button
                                            className='icon-btn delete'
                                            onClick={() =>
                                              deleteFunction(_id, name)
                                            }
                                          >
                                            <span className='material-symbols-outlined medium'>
                                              Delete
                                            </span>
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>

                        <Pagination
                          sliceName={"department"}
                          search={search}
                          filter={"desc"}
                          api={getDepartmentList}
                          limit={5}
                          tableIndex={tableIndex}
                          setTableIndex={setTableIndex}
                          btnNo={btnNo}
                          setBtnNo={setBtnNo}
                          callbackFunction={() => {
                            setSelectAllProducts(false)
                            setCollectAllProducts([])
                          }}
                        />
                      </>
                    ) : (
                      <div className='Nodata-txt'>
                        <img
                          src={
                            require("../../../assets/images/nodata.svg").default
                          }
                          alt=''
                          srcSet=''
                        />
                        No Data Found
                      </div>
                    )}{" "}
                  </>
                )}
              </div>
            </div>
            <Footer />{" "}
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteDepartment}
              popUpData={popupData}
              customFunction={() => {
                setSelectAllProducts(false)
                setCollectAllProducts([])
              }}
              toastMessage={"Department removed successfully"}
            />
          )}{" "}
          {deleteAllPopup && (
            <ConformationPopup
              customState={deleteAllPopup}
              customSetter={setDeleteAllPopup}
            />
          )}{" "}
          {departmentPopup.addDepartment && (
            <AddDepartment
              customState={departmentPopup}
              customSetter={setDepartmentPopup}
            />
          )}{" "}
        </div>
        {departmentPopup.editDepartment && (
          <EditDepartment
            customState={departmentPopup}
            customSetter={setDepartmentPopup}
            popupData={popupData}
          />
        )}{" "}
        {/* {departmentPopup.departmentDetail && (
          <DepartmentDetails
            customState={departmentPopup}
            customSetter={setDepartmentPopup}
            popupData={popupData}
          />
        )} */}
      </div>
    </div>
  )
}

export default Department
