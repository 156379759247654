/* eslint-disable no-useless-escape */
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import * as Yup from "yup"
import DropDownBox from "../../../components/DropDownBox"

const BankForm = ({
  type,
  api,
  companyData,
  customSetter,
  customState,
  popUpSetter,
}) => {
  const [localityAddress, setLocalityAddress] = useState({})
  const { companyLoading } = useSelector(store => store.company)
  const [activeInputs, setActiveInputs] = useState(false)
  const { companyDetails, companyAddress } = companyData

  const [focusedField, setFocusedField] = useState()
  const [addStyle, setAddStyle] = useState(true)
  // const [activeInputs, setActiveInputs] = useState(false);

  const dispatch = useDispatch()

  const locationSchema = Yup.object({
    bankName: Yup.string().trim().required("Bank name required"),
    accountNo: Yup.string()
      .trim()
      .min(8, "Account number at least be 8 characters long")
      .max(17, "Account number must be less than 17 characters")
      .matches(/^[0-9]{9,18}$/, "Please enter valid account number")
      .required("Account number is required"),
    accountType: Yup.string().required("Account Type is required"),
    ifsc: Yup.string()
      .required("IFSC code is required")
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Please enter valid IFSC code")
      .min(11),
    branch: Yup.string().required("Branch name is required"),
    accountHolderName: Yup.string().required("Account Holder Name is required"),
    upi: Yup.string()
      .required("UPI id required")
      .matches(
        /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/,
        "Please enter valid UPI id",
      ),
  })
  const formik = useFormik({
    initialValues: {
      bankName: "",
      accountNo: "",
      accountType: "",
      ifsc: "",
      branch: "",
      accountHolderName: "",
      upi: "",
    },
    validationSchema: locationSchema,
    onSubmit: async (values, { resetForm }) => {
      let data = {}
      if (type === "Edit") {
        data = {
          send: {
            company_id: companyData,
            branchName: values.branch,
            ifsc: values.ifsc,
            accountName: values.accountHolderName,
            bank_name: values.bankName,
            accountType: values.accountType,
            account_no: {
              value: values.accountNo,
            },
            upi: values?.upi,
          },
          id: companyData?.companyAddress?._id
            ? companyData?.companyAddress?._id
            : "",
          type: `update-bank/${customState?._id}`,
          method: "PUT",
        }
      } else {
        data = {
          send: {
            company_id: companyData,
            branchName: values.branch,
            ifsc: values.ifsc,
            accountName: values.accountHolderName,
            bank_name: values.bankName,
            accountType: values.accountType,
            account_no: {
              value: values.accountNo,
            },
            upi: values.upi,
          },
          type: `add-bank`,
        }
      }
      const response = await dispatch(api(data))
      if (response.payload) {
        handleClick()
      }
      //   const response = await dispatch(addCompanyAddress(data))
      //   if (response.payload) {
      //     customSetter(0)
      //     resetForm()
      //   }

      // if (type === "delivery") {
      //   delivery_locations.push(values);
      // }
      // if (type === "billing") {
      //   billing_locations.push(values);
      // }
      // SetData(data, ...values);
    },
  })

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (customState) {
      const styleTimer = setTimeout(() => {
        customSetter(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")

    if (type === "Edit") {
      formik.setValues({
        branch: customState?.branch_name,
        ifsc: customState?.ifsc,
        accountHolderName: customState?.account_name,
        bankName: customState.bank_name,
        accountType: customState.account_type,
        accountNo: customState?.account_no?.value,
        upi: customState?.UPI,
      })
    }
  }, [])

  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>
            {type === "Edit" ? (activeInputs && type ? type : "") : type} Bank
            Details
          </h2>
          <button className='exit-btn' onClick={() => handleClick()}>
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>

        <div className='modelEx_content singleAdd'>
          <form className='scrollForm' onSubmit={formik.handleSubmit}>
            <div
              className='form-content form-for-Address'
              style={{ paddingBottom: "2.5rem" }}
            >
              <div className='update-form-activation'>
                <h3 className='title'>
                  {type === "Edit" ? (activeInputs && type ? type : "") : type}{" "}
                  Details*
                </h3>
                {type === "Edit" && (
                  <div
                    className='detail-btn update-form-activation'
                    style={{ gap: "1rem" }}
                  >
                    {/* {companyData?.companyAddress?.type === "warehouse" && (
                        <div className='divide-border-right'>
                          <button
                            type='button'
                            onClick={e => {
                              e.preventDefault()
                              popUpSetter({
                                data: {
                                  address_id: companyData?.companyAddress?._id,
                                  company_id: companyData?.companyDetails?._id,
                                  address_type:
                                    companyData?.companyAddress?.type,
                                },
                              })

                              
                              handleClick()
                            }}
                            className='btn-secondary changes-primary'
                          >
                            <i className='fa-sharp fa-solid fa-thumbtack'></i>{" "}
                            SET AS PRIMARY
                          </button>
                        </div>
                      )} */}
                    {activeInputs ? (
                      <button
                        type='button'
                        onClick={e => {
                          e.preventDefault()
                          setActiveInputs(false)
                        }}
                        className='btn-secondary'
                        disabled={companyLoading}
                        style={companyLoading ? { cursor: "not-allowed" } : {}}
                      >
                        Abort
                      </button>
                    ) : (
                      <button
                        type='button'
                        onClick={e => {
                          e.preventDefault()
                          setActiveInputs(true)
                        }}
                        className='btn-primary'
                      >
                        <span className='material-symbols-outlined small'>
                          border_color
                        </span>{" "}
                        EDIT
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className='form-group form'>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.bankName !== "" ||
                      focusedField === "bankName"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='bankName' className='toggle-label'>
                      Bank Name
                    </label>
                    <input
                      id='bankName'
                      name='bankName'
                      type='text'
                      className='form-input'
                      value={formik.values.bankName}
                      onChange={formik.handleChange}
                      // onInput={(e) => {
                      //   e.target.value = e.target.value;
                      // .replace(/[^A-Za-z ]/gi, "")
                      // .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                      // }}
                      maxLength={80}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("bankName")}
                      disabled={type === "Edit" ? !activeInputs : false}
                    />
                    {formik.touched.bankName && formik.errors.bankName ? (
                      <span className='err'>{formik.errors.bankName}</span>
                    ) : null}
                  </div>{" "}
                  <div
                    className={`input-field ${
                      formik.values.branch !== "" || focusedField === "branch"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='branch' className='toggle-label'>
                      Branch Name
                    </label>
                    <input
                      id='branch'
                      name='branch'
                      type='text'
                      className='form-input'
                      value={formik.values.branch}
                      onChange={formik.handleChange}
                      // onInput={(e) => {
                      //   e.target.value = e.target.value;
                      // .replace(/[^A-Za-z ]/gi, "")
                      // .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                      // }}
                      maxLength={80}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("branch")}
                      disabled={type === "Edit" ? !activeInputs : false}
                    />
                    {formik.touched.branch && formik.errors.branch ? (
                      <span className='err'>{formik.errors.branch}</span>
                    ) : null}
                  </div>{" "}
                </div>{" "}
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.accountNo !== "" ||
                      focusedField === "accountNo"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='accountNo' className='toggle-label'>
                      Account No.
                    </label>
                    <input
                      id='accountNo'
                      name='accountNo'
                      type='text'
                      className='form-input'
                      value={formik.values.accountNo}
                      onChange={formik.handleChange}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^\d ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      maxLength={17}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("accountNo")}
                      disabled={type === "Edit" ? !activeInputs : false}
                    />
                    {formik.touched.accountNo && formik.errors.accountNo ? (
                      <span className='err'>{formik.errors.accountNo}</span>
                    ) : null}
                  </div>
                  <div className='input-field'>
                    <DropDownBox
                      options={[
                        { label: "Saving", value: "saving" },
                        { label: "Current", value: "current" },
                      ]}
                      customSetter={formik.setFieldValue}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Account Type'
                      animateDropDownTitle
                      // loading={optionsLoading}
                      customFormikLabel={"accountType"}
                      disabled={type === "Edit" ? activeInputs : true}
                      incomingValue={
                        customState.account_type
                          ? customState.account_type
                          : null
                      }
                    />
                    {formik.touched.accountNo && formik.errors.accountNo ? (
                      <span className='err'>{formik.errors.accountNo}</span>
                    ) : null}
                  </div>
                </div>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.ifsc !== "" || focusedField === "ifsc"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='address-line-one' className='toggle-label'>
                      IFSC
                    </label>
                    <input
                      id='ifsc'
                      name='ifsc'
                      type='text'
                      className='form-input'
                      value={formik.values.ifsc}
                      onChange={formik.handleChange}
                      onInput={e => {
                        e.target.value = e.target.value.toUpperCase()
                      }}
                      maxLength={11}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("ifsc")}
                      disabled={type === "Edit" ? !activeInputs : false}
                    />
                    {formik.touched.ifsc && formik.errors.ifsc ? (
                      <span className='err'>{formik.errors.ifsc}</span>
                    ) : null}
                  </div>{" "}
                  <div
                    className={`input-field ${
                      formik.values.accountHolderName !== "" ||
                      focusedField === "accountHolderName"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='address-line-one' className='toggle-label'>
                      Account Holder Name
                    </label>
                    <input
                      id='accountHolderName'
                      name='accountHolderName'
                      type='text'
                      className='form-input'
                      value={formik.values.accountHolderName}
                      onChange={formik.handleChange}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /(?:^|\s)\S/g,
                          match => match.toUpperCase(),
                        )
                      }}
                      maxLength={80}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("accountHolderName")}
                      disabled={type === "Edit" ? !activeInputs : false}
                    />
                    {formik.touched.accountHolderName &&
                    formik.errors.accountHolderName ? (
                      <span className='err'>
                        {formik.errors.accountHolderName}
                      </span>
                    ) : null}
                  </div>{" "}
                </div>
                <div
                  className={`input-field ${
                    formik.values.upi !== "" || focusedField === "upi"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='address-line-one' className='toggle-label'>
                    UPI ID
                  </label>
                  <input
                    id='upi'
                    name='upi'
                    type='text'
                    className='form-input'
                    value={formik.values.upi}
                    onChange={formik.handleChange}
                    // onInput={e => {
                    //   e.target.value = e.target.value.replace(
                    //     /(?:^|\s)\S/g,
                    //     match => match.toUpperCase(),
                    //   )
                    // }}
                    maxLength={80}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("upi")}
                    disabled={type === "Edit" ? !activeInputs : false}
                  />
                  {formik.touched.upi && formik.errors.upi ? (
                    <span className='err'>{formik.errors.upi}</span>
                  ) : null}
                </div>{" "}
                {/* <div className="form-group contact-info"> */}
                {/* <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.pinCode !== "" || focusedField === "pinCode"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='pinCode' className='toggle-label'>
                      Pincode
                    </label>
                    <div className='pinCode_wrapper'>
                      <input
                        id='pinCode'
                        name='pinCode'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          formik.setFieldValue("pinCode", e.target.value)
                          if (e.target.value.length > 5) {
                            hitPincodeApi(e.target.value)
                          }
                        }}
                        value={formik.values.pinCode}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 6)
                            .replace(/\D/g, "")
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("pinCode")}
                        maxLength={6}
                        disabled={type === "Edit" ? !activeInputs : false}
                      />
                      {addressLoading ? (
                        <span className='pinCode_spinner'>
                          <ClipLoader
                            loading={true}
                            size={15}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                            color='white'
                          />
                        </span>
                      ) : null}
                    </div>

                    {formik.touched.pinCode && formik.errors.pinCode ? (
                      <span className='err'>{formik.errors.pinCode}</span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.city !== "" || focusedField === "city"
                        ? "active"
                        : ""
                    }`}
                  >
                    {" "}
                    <label htmlFor='city' className='toggle-label'>
                      City
                    </label>
                    <input
                      id='city'
                      name='city'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      onFocus={() => setFocusedField("city")}
                      disabled
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <span className='err'>{formik.errors.city}</span>
                    ) : null}
                  </div>
                </div>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.state !== "" || focusedField === "state"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='state' className='toggle-label'>
                      State
                    </label>
                    <input
                      id='state'
                      name='state'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("state")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      disabled
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <span className='err'>{formik.errors.state}</span>
                    ) : null}
                  </div>
                  <div
                    className={`input-field ${
                      formik.values.country !== "" || focusedField === "country"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='country' className='toggle-label'>
                      Country
                    </label>
                    <input
                      id='country'
                      name='country'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.country}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("country")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      disabled
                    />
                    {formik.touched.country && formik.errors.country ? (
                      <span className='err'>{formik.errors.country}</span>
                    ) : null}
                  </div>
                </div> */}
              </div>
            </div>
            {(activeInputs || type !== "Edit") && (
              <div className='footer flex-center'>
                {/* <button type="submit" className="btn-primary">
                Submit
              </button> */}
                <button
                  className='btn-primary'
                  type='submit'
                  style={
                    companyLoading
                      ? {
                          cursor: "not-allowed",
                          // background: "gray",
                        }
                      : {}
                  }
                  disabled={companyLoading}
                >
                  {companyLoading ? (
                    <ClipLoader
                      loading={true}
                      size={15}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                      color='white'
                    />
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default BankForm
