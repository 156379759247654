import React from 'react'

const SalesPriceDetails = ({setGrandTotals, grandTotals}) => {
  return (
    <>
      <div className='main-box-padding'>
        <div className='tp-main'>
          <div className='tp-inner'>
            <span>Subtotal :</span>
            <hr />
            <span>₹ {grandTotals?.subtotal}</span>
          </div>
          <div className='tp-inner'>
            <span>Taxes :</span>
            <hr />
            <span>₹ {grandTotals?.taxAmount}</span>
          </div>
          <div className='tp-inner'>
            <span>Grand Total  :</span>
            <hr />
            <span>₹ {grandTotals?.grandTotal}</span>
          </div>
          <div className='tp-inner'>
            <span>Advance to pay :</span>
            <input
              type='text'
              className='form-input'
              placeholder='eg. 10000'
              onInput={e => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "")
              }}
            ></input>
          </div>
        </div>
      </div>
    </>
  )
}

export default SalesPriceDetails