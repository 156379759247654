import React from "react"
import { Link, useLocation } from "react-router-dom"

const Breadcrumb = ({ id, title }) => {
  const location = useLocation()
  const pathnames = location.pathname.split("/").filter(x => x)
  const IdRemovedPath = pathnames.slice(0, pathnames.length - 1)

  if (id !== undefined) {
    return (
      <div className='breadcrumbs_container'>
        {location.pathname === "/" ? null : (
          <Link to='/' className='flex-center'>
            {/* <span className="material-symbols-rounded medium">home</span> */}
            Dashboard
          </Link>
        )}
        {IdRemovedPath.map((value, index) => {
          const to = `/${IdRemovedPath.slice(0, index + 1).join("/")}`
          return (
            <div className='breadcrumbs' key={index}>
              <span key={to}>
                {"/"} <Link to={to}>{value.replaceAll("-", " ")}</Link>
              </span>
              <span>{` / ${title}`}</span>
            </div>
          )
        })}
      </div>
    )
  } else {
    return (
      <div className='breadcrumbs_container'>
        {location.pathname === "/" ? null : (
          <Link to='/' className='flex-center'>
            {/* <span className="material-symbols-rounded medium">home</span> */}
            Dashboard
          </Link>
        )}

        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1
          const to = `/${pathnames.slice(0, index + 1).join("/")}`

          return (
            <div className='breadcrumbs' key={index}>
              {last ? (
                <span key={to}>
                  {"/"} {value.replaceAll("-", " ")}
                </span>
              ) : (
                <span key={to}>
                  {"/"} <Link to={to}>{value.replaceAll("-", " ")}</Link>
                </span>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

export default Breadcrumb
