import React, { useEffect, useRef, useState } from "react"
import { handleAnimation } from "../helper/helper"
// import { deleteEmail, getEmailList } from "../redux/EmailApi";

const FilterBox = ({
  customState,
  customSetter,
  customPosition,
  iconColor,
}) => {
  const [showFilter, setShowFilter] = useState(false)
  const [addStyle, setAddStyle] = useState(false)

  return (
    <div
      className='filter-container'
      onClick={() =>
        handleAnimation(setAddStyle, addStyle, setShowFilter, showFilter, 200)
      }
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='24'
        viewBox='0 -960 960 960'
        width='24'
        style={iconColor ? iconColor : { fill: "#a1a4b9" }}
      >
        <path d='M440-160q-17 0-28.5-11.5T400-200v-240L168-736q-15-20-4.5-42t36.5-22h560q26 0 36.5 22t-4.5 42L560-440v240q0 17-11.5 28.5T520-160h-80Zm40-308 198-252H282l198 252Zm0 0Z' />
      </svg>
      <div className={showFilter ? "filterContainer-wrapper" : " "}>
        {showFilter && (
          <FIlterCheckBoxContainer
            customState={customState}
            customSetter={customSetter}
            animationState={showFilter}
            animationSetter={setShowFilter}
            addStyle={addStyle}
            setAddStyle={setAddStyle}
            customPosition={customPosition}
          />
        )}
      </div>
    </div>
  )
}

const FIlterCheckBoxContainer = ({
  customState,
  customSetter,
  animationState,
  animationSetter,
  setAddStyle,
  addStyle,
  customPosition,
}) => {
  const handleCheck = item => {
    customSetter(oldLIst => {
      let newList = JSON.parse(JSON.stringify(oldLIst))
      newList[item] = !newList[item]
      return newList
    })
  }

  const handleReset = () => {
    customSetter(oldLIst => {
      let newList = JSON.parse(JSON.stringify(oldLIst))
      Object.keys(newList).map(item => {
        newList[item] = true
      })
      return newList
    })
  }
  const [globalClick, setGlobalClick] = useState(false)
  const filterRef = useRef()

  useEffect(() => {
    const handleGlobalClick = event => {
      if (filterRef?.current && !filterRef?.current?.contains(event.target)) {
        handleAnimation(
          setAddStyle,
          addStyle,
          animationSetter,
          animationState,
          200,
        )
      }
    }

    document.addEventListener("click", handleGlobalClick)
    return () => {
      document.removeEventListener("click", handleGlobalClick)
    }
  }, [filterRef])

  useEffect(() => {
    if (animationState) {
      setGlobalClick(true)
    }
  }, [])

  return (
    <div
      className={
        "filter-box" +
        (addStyle
          ? " show_component-common-animation"
          : " hide_component-common-animation")
      }
      onClick={e => {
        e.stopPropagation()
      }}
      ref={animationState && globalClick ? filterRef : null}
      style={customPosition ? customPosition : {}}
    >
      <p>View Columns</p>
      <div className='filter-grid'>
        {Object.keys(customState).map((item, index) => {
          return (
            <div className='filter-flex' key={index+item}>
              {/* <input
          type='checkbox'
          name={item}
          onClick={() => handleCheck(item)}
          checked={customState[item]}
        />{" "}
        <label htmlFor={item}>{item}</label>{" "} */}
              <input
                id={index}
                type='checkbox'
                className='checkbox-animated'
                onChange={() => handleCheck(item)}
                checked={customState[item]}
              />
              <label htmlFor={index}>
                <span className='filter-check-background'></span>
              </label>
              <label htmlFor={index}>
                <span>{item.replaceAll("_", " ")}</span>
              </label>{" "}
            </div>
          )
        })}
      </div>
      <div className='filter-buttons'>
        <button className='filter-reset-btn' onClick={handleReset}>
          Reset All
        </button>
        <button
          className='filter-done-btn'
          onClick={() =>
            handleAnimation(
              setAddStyle,
              addStyle,
              animationSetter,
              animationState,
              200,
            )
          }
        >
          Done
        </button>
      </div>
    </div>
  )
}

export default FilterBox
