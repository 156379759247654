/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { handleAnimation } from "../helper/helper"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { ClipLoader } from "react-spinners"
import { logout } from "../modules/auth/redux/authApi"
import { getProfileDetails } from "../modules/profile/redux/profileApi"
import { getSelfCompanyAddress } from "../modules/company/redux/companyApi"
import { setWarehouseList } from "../modules/company/redux/companySlice"

const Header = ({
  MinimizeSidebar,
  setMinimizeSidebar,
  setMbsidebar,
  // showChat,
  setShowChat,
  setInitialRenderAnimation,
  initialRenderAnimation,
}) => {
  const [showSearch, setShowSearch] = useState(false)
  const [showMsg, setShowMsg] = useState(false)
  const [addStyle, setAddStyle] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { profileDetail } = useSelector(store => store.profile)
  const { authLoading, role } = useSelector(state => state.auth, shallowEqual)

  // useEffect(() => {
  //   if (Object.keys(profileDetail).length === 0) dispatch(getProfileDetails())
  // }, [])

  const handleLogout = async () => {
    const response = await dispatch(logout())
    if (response?.payload) {
      navigate("/login")
    }
  }
  // const handleClick = (e) => {
  //   setAddStyle(!addStyle);
  //   chatBoxVisibility();
  //   setShowChat(false);
  // };

  // function chatBoxVisibility() {
  //   if (showMsg) {
  //     const styleTimer = setTimeout(() => {
  //       setShowMsg(false);
  //       clearTimeout(styleTimer);
  //     }, 200);
  //   } else {
  //     setShowMsg(true);
  //   }
  // }
  const [addSearchStyle, setAddSearchStyle] = useState(false)
  // const handleSearchClick = (e) => {
  //   setAddSearchStyle(!addSearchStyle);
  //   setShowMsg(false);
  //   setAddStyle(false);
  //   searchBoxVisibility();
  // };

  // function searchBoxVisibility() {
  //   if (showSearch) {
  //     const styleTimer = setTimeout(() => {
  //       setShowSearch(false);
  //     }, 180);
  //   } else {
  //     setShowSearch(true);
  //   }
  // }

  const searchRef = useRef()
  const notifyRef = useRef()

  useEffect(() => {
    const handleGlobalClick = event => {
      if (searchRef?.current && !searchRef?.current?.contains(event.target)) {
        setShowSearch(false)
        setAddSearchStyle(false)
      }
      if (notifyRef?.current && !notifyRef?.current?.contains(event.target)) {
        setShowMsg(false)
        setAddStyle(false)
      }
    }
    // document
    //   .querySelector(".magic-icon-search")
    //   .addEventListener("click", (e) => {
    //     e.target.classList.toggle("close");
    //   });
    document.addEventListener("click", handleGlobalClick)
    return () => {
      document.removeEventListener("click", handleGlobalClick)
    }
  }, [searchRef])

  // const chatRef = useRef();

  // const handleNavClick = () => {
  //   setNavbarAni(!navbarAni);
  //   navVisibility();
  // };

  // function navVisibility() {
  //   if (!MinimizeSidebar) {
  //     const styleTimer = setTimeout(() => {
  //       setMinimizeSidebar(true);
  //     }, 300);
  //   } else {
  //     const styleTimer = setTimeout(() => {
  //       setMinimizeSidebar(false);
  //     }, 300);
  //   }
  // }

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  // const { warehousesList, companyAddress } = useSelector(store => store.company)
  


  // useEffect(() => {
  //   if (warehousesList.length === 0) {
  //     dispatch(getSelfCompanyAddress())
  //   }
  //   if (warehousesList.length === 0) {
  //     const newList = companyAddress.map((item, index) => {
  //       return {
  //         value: item?._id,
  //         label: item?.slug,
  //       }
  //     })
  //     dispatch(setWarehouseList(newList))
  //   }
  // }, [companyAddress])

  return (
    <div className={`header-main ${showSearch ? "justify-center" : ""}`}>
      <div
        className={`flex-center hm-left ${showSearch ? "search-active" : ""}`}
      >
        <span
          className='material-symbols-rounded mb'
          onClick={() => {
            setMbsidebar(true)
            setShowChat(false)
          }}
        >
          Menu
        </span>
        {/* <label className='switch' htmlFor='checkSidebar'>
          <input
            type='checkbox'
            id='checkSidebar'
            name='checkSidebar'
            onClick={() => handleNavToggle()}
          />
          <span className='slider round'></span>
        </label> */}
      </div>
      <div className={`hm-right`}>
        {/* <div ref={showSearch ? searchRef : null} className='search-div'>
          {showSearch ? (
            <div
              className={
                "search-control" +
                (addSearchStyle
                  ? " show_search-common-animation"
                  : " hide_search-common-animation ")
              }
            >
              <span className='material-symbols-rounded medium'>search</span>
              <input
                type='search'
                placeholder='Search here...'
                className='search-input'
              />
            </div>
          ) : null}
          <div
            className='hmr-search'
            onClick={() => {
              handleAnimation(
                setAddSearchStyle,
                addSearchStyle,
                setShowSearch,
                showSearch,
                180,
              )
              setShowMsg(false)
              setAddStyle(false)
            }}
          >
            <span className='material-symbols-rounded medium'>search</span>
          </div>
        </div> */}

        <div
          className={`hmr-notify ${showSearch ? "search-active" : ""}`}
          ref={showMsg ? notifyRef : null}
        >
          <div
            onClick={() =>
              handleAnimation(setAddStyle, addStyle, setShowMsg, showMsg, 200)
            }
          >
            <span className='num'>2</span>
            <span className='material-symbols-rounded'>notifications</span>
          </div>

          {showMsg ? (
            <div
              className={
                "notify-main" +
                (addStyle
                  ? " show_component-common-animation"
                  : showMsg === false && showSearch === false
                    ? ""
                    : " hide_component-common-animation")
              }
            >
              <div className='n-title'>Notification</div>
              <div className='n-content'>
                <div className='nc1'>
                  <div className='nc1-img'>
                    <img
                      src={require("../assets/images/unsplash_jFAG_ixCrsM.webp")}
                      alt='profile'
                    />
                  </div>
                  <div className='nc1-data'>
                    <span className='ncd1'>Cool Marketing</span>
                    <span className='ncd2'>Lorem ipsum dolor sit amet</span>
                  </div>
                </div>
                <div className='nc1'>
                  <div className='nc1-img'>
                    <img
                      src={require("../assets/images/unsplash_jFAG_ixCrsM.webp")}
                      alt='profile'
                    />
                  </div>
                  <div className='nc1-data'>
                    <span className='ncd1'>Cool Marketing</span>
                    <span className='ncd2'>Lorem ipsum dolor sit amet</span>
                  </div>
                </div>
                <div className='nc1'>
                  <div className='nc1-img'>
                    <img
                      src={require("../assets/images/unsplash_jFAG_ixCrsM.webp")}
                      alt='profile'
                    />
                  </div>
                  <div className='nc1-data'>
                    <span className='ncd1'>Cool Marketing</span>
                    <span className='ncd2'>Lorem ipsum dolor sit amet</span>
                  </div>
                </div>
                <div className='nc1'>
                  <div className='nc1-img'>
                    <img
                      src={require("../assets/images/unsplash_jFAG_ixCrsM.webp")}
                      alt='profile'
                    />
                  </div>
                  <div className='nc1-data'>
                    <span className='ncd1'>Cool Marketing</span>
                    <span className='ncd2'>Lorem ipsum dolor sit amet</span>
                  </div>
                </div>
                <div className='nc1'>
                  <div className='nc1-img'>
                    <img
                      src={require("../assets/images/unsplash_jFAG_ixCrsM.webp")}
                      alt='profile'
                    />
                  </div>
                  <div className='nc1-data'>
                    <span className='ncd1'>Cool Marketing</span>
                    <span className='ncd2'>Lorem ipsum dolor sit amet</span>
                  </div>
                </div>
                <div className='nc1'>
                  <div className='nc1-img'>
                    <img
                      src={require("../assets/images/unsplash_jFAG_ixCrsM.webp")}
                      alt='profile'
                    />
                  </div>
                  <div className='nc1-data'>
                    <span className='ncd1'>Cool Marketing</span>
                    <span className='ncd2'>Lorem ipsum dolor sit amet</span>
                  </div>
                </div>
              </div>
              <div className='n-footer'>
                <button className='see-btn'>See More</button>
              </div>
            </div>
          ) : null}
        </div>

        {/* <div
          className={`hmr-notify ${showSearch ? "search-active" : ""}`}
          onClick={() => setShowChat(!showChat)}
          ref={chatRef}
        >
          <span className="num">2</span>
          <span className="material-symbols-rounded">forum</span>
        </div> */}

        <div className={`hrm-prof ${showSearch ? "search-active" : ""}`}>
          <img
            src={
              profileDetail?.profilePicture
                ? profileDetail?.profilePicture
                : require("../assets/images/user(1).webp")
            }
            alt='profile'
          />
          <div className='profile-details'>
            <div className='pd-head'>
              <div>
                {role === 1
                  ? profileDetail.fname === "Super" &&
                    profileDetail.lname === "Admin"
                    ? ""
                    : "Super Admin"
                  : role === 2
                    ? "Admin"
                    : role === 3
                      ? "Manager"
                      : ""}
              </div>
              <div className='title'>
                {profileDetail.fname} {profileDetail.lname}
              </div>
            </div>
            <div className='pd-data'>
              <span onClick={() => navigate("/profile")} className='pd-link'>
                <div className='flex gap-1 align-center'>
                  <span className='material-symbols-outlined'>
                    manage_accounts
                  </span>
                  My Profile
                </div>
              </span>
              <span className='pd-link'>
                {authLoading ? (
                  <div className="flex-center w-full">
                    <ClipLoader
                      loading={true}
                      size={15}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                      // color='white'
                    />
                  </div>
                ) : (
                  <div onClick={() => handleLogout()} className='flex gap-1 w-full'>
                    <span className='material-symbols-outlined'>logout</span>
                    <span>Log Out</span>
                  </div>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
