import React from "react"
import { numberWithCommas } from "../../../helper/helper"

const PriceDetails = ({ grandTotals, setGrandTotals }) => {
  return (
    <>
      <div className='main-box-padding'>
        <div className='tp-main'>
          <div className='tp-inner'>
            <span>Subtotal :</span>
            <hr />
            <span>₹ {numberWithCommas(grandTotals?.subtotal)}</span>
          </div>
          <div className='tp-inner'>
            <span>Taxes :</span>
            <hr />
            <span>₹ {numberWithCommas(grandTotals?.taxes)}</span>
          </div>
          <div className='tp-inner'>
            <span>Grand Total  :</span>
            <hr />
            <span>₹ {numberWithCommas(grandTotals?.grandTotal)}</span>
          </div>
          <div className='tp-inner'>
            <span>Advance to pay :</span>
            <input
              type='text'
              className='form-input'
              placeholder='eg. 10000'
              value={grandTotals?.advanceToPay ? grandTotals?.advanceToPay : ""}
              onChange={e =>
                setGrandTotals({ ...grandTotals, advanceToPay: e.target.value })
              }
              onInput={e => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "")
              }}
            ></input>
          </div>
        </div>
      </div>
    </>
  )
}

export default PriceDetails
