import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"
import { isProfileToggle } from "./profileSlice"

export const getProfileDetails = createAsyncThunk(
  "profile/profile-details",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/get-detail`,
      })

      if (response.status === 200) {
        return fulfillWithValue(response.data)
      } else {
        toast.error(response?.data?.message)
        return rejectWithValue()
      }
    } catch (error) {
      toast.error(error)
      return rejectWithValue()
    }
  },
)

export const changePassword = createAsyncThunk(
  "profile/change-password",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isProfileToggle(true))
    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/change-password`,
        headers: {
          "Content-type": "application/json",
        },
        data,
      })
      if (response.status === 200) {
        toast.success(response?.data?.message)
        dispatch(isProfileToggle(false))
        return response?.data?.status
      } else {
        toast.error(response.data.message)
        dispatch(isProfileToggle(false))
      }
    } catch (error) {
      dispatch(isProfileToggle(false))
      toast.error(error)
    }
  },
)

export const updateProfile = createAsyncThunk(
  "profile/update-profile",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/update-detail`,
        data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isProfileToggle(false))
        toast.success(response?.data?.message)
        dispatch(getProfileDetails())
        return response?.data?.status
      } else {
        dispatch(isProfileToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isProfileToggle(false))
      toast.error(err)
    }
  },
)

export const changeProfilePicture = createAsyncThunk(
  "profile/change-profile-picture",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/change-profilePicture`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      })
      if (response.status === 200) {
        dispatch(getProfileDetails())
        dispatch(isProfileToggle(false))
        toast.success(response?.data?.message)
        return response?.data?.status
      } else {
        dispatch(isProfileToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isProfileToggle(false))
      toast.error(err)
    }
  },
)
