/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { ClipLoader } from "react-spinners"
import Cookies from "js-cookie"
import {
  incrptPSW,
  decryptPSW,
  getDeviceId,
  options,
} from "../../../helper/helper"
import { getIPaddressAsync, googleLogin, login } from "../redux/authApi"
import { setLatitude, setLongitude } from "../redux/authSlice"
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"
import { jwtDecode } from "jwt-decode"
import { toast } from "react-toastify"
import { getProfileDetails } from "../../profile/redux/profileApi"
import {
  getCompanyDetailData,
  getSelfCompanyAddress,
} from "../../company/redux/companyApi"

const Login = ({ setTitle }) => {
  const [visibility, setVisibility] = useState(false)
  const [focusedField, setFocusedField] = useState()
  const year = new Date().getFullYear()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const emailRef = useRef()
  const passwordRef = useRef()

  useEffect(() => {
    setTitle("Login")
    dispatch(getIPaddressAsync())
    //
    // emailRef.current
    // passwordRef.current.focus()

    // if (Cookies.get("email")) {
    //   emailRef.current.focus()
    // }
    // if (Cookies.get("sessionID")) {
    //   passwordRef.current.focus()
    // }
  }, [setTitle, dispatch])

  const ref = useRef()
  const ClientId = process.env.REACT_APP_GOOGLE_LOGIN

  const {
    authLoading,
    // privileges,
    // token,
    // role,
    // is_2fa,
    // is_verify,
    ip,
    latitude,
    longitude,
  } = useSelector(state => state.auth, shallowEqual)

  const decryptedPSW = Cookies.get("sessionID")
    ? decryptPSW(Cookies.get("sessionID"), Cookies.get("sessionKey"))
    : ""

  const initialValues = {
    email: !Cookies.get("email") ? "" : Cookies.get("email"),
    password: !Cookies.get("sessionID") ? "" : decryptedPSW,
    isRem: false,
  }

  const loginSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Please enter email address")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please enter a valid email!",
      )
      .required("Email address should not be empty")
      .matches(
        "@poonamcoatings.com",
        "Email must contain @poonamcoatings.com domain",
      ),
    password: Yup.string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/,
        "Password should contains at least 1 digit, 1 uppercase & 1 lowercase,1 special character,length between 8 to 20",
      )
      .required("Please enter a password"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async values => {
      if (values.isRem) {
        const { decrypt, key } = await incrptPSW(values.password)
        Cookies.set("email", values.email, { expires: 5, sameSite: "strict" })
        Cookies.set("sessionID", decrypt, {
          expires: 5,
          sameSite: "strict",
        })
        Cookies.set("sessionKey", key, { expires: 5, sameSite: "strict" })
      }

      const data = {
        email: values.email,
        password: values.password,
        device_info: {
          device_id: getDeviceId(),
          app_id: navigator?.buildID ? navigator?.buildID : "20181001000000",
          name:
            navigator.userAgentData &&
            navigator.userAgentData.brands &&
            navigator.userAgentData.brands.length > 0
              ? navigator.userAgentData.brands[0].brand
              : navigator.appName,
          model: navigator?.userAgent,
          platform:
            navigator.userAgentData && navigator.userAgentData.platform
              ? navigator.userAgentData.platform
              : navigator.platform,
          version:
            navigator.userAgentData &&
            navigator.userAgentData.brands &&
            navigator.userAgentData.brands.length > 0
              ? navigator.userAgentData.brands[0].version
              : navigator.appVersion,
          latitude: "",
          longitude: "",

          ip: ip?.length > 0 ? ip : "00.00.00.00",
        },
      }
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject)
        })

        data.device_info.latitude = position.coords.latitude
        data.device_info.longitude = position.coords.longitude

        if (position.coords) {
          dispatch(setLatitude(position.coords.latitude))
          dispatch(setLongitude(position.coords.longitude))
        }

        const response = await dispatch(login(data))
        if (response?.meta?.requestStatus === "fulfilled") {
          await dispatch(getProfileDetails())
          await dispatch(getCompanyDetailData())
          await dispatch(getSelfCompanyAddress())
          navigate("/")
        }
      } catch (error) {
        // data.device_info.latitude = null;
        // data.device_info.longitude = null;
        data.device_info.latitude = latitude
        data.device_info.longitude = longitude
        const response = await dispatch(login(data))
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/")
        }
      }
    },
  })

  const googleLoginResponse = async response => {
    const googleLoginDetail = jwtDecode(response?.credential)
    if (googleLoginDetail?.email_verified) {
      const data = {
        credential: response?.credential,
        device_info: {
          userId: "",
          ip: "1111111111",
          device_id: "12345678910",
          app_id: "889976",
          name: "chrome firefox",
          model: "Bkj L2r",
          platform: "windows",
          version: "2.",
          ipAddress: "192.17.28",
          latitude: 23.0276,
          longitude: 72.5871,
        },
      }

      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject)
        })

        data.device_info.latitude = position.coords.latitude
        data.device_info.longitude = position.coords.longitude

        const response = await dispatch(googleLogin(data))
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/")
        }
      } catch (error) {
        data.device_info.latitude = null
        data.device_info.longitude = null

        const response = await dispatch(googleLogin(data))
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/")
        }
      }
    } else {
      toast(
        "This account is not verified please login with different account.",
        options,
      )
    }
  }

  return (
    <div className='container'>
      <div className='app-container'>
        <div className='auth-container'>
          <div className='auth-logo'>
            <Link to='/'>
              <img
                src={require("../../../assets/icons/logo.svg").default}
                className='poonam-coatings-logo'
                alt='poonam_coatings'
              />
            </Link>
          </div>
          <main className='auth-main flex-center'>
            <div className='form-box'>
              <header className='auth-heading text-center'>
                <h2 className='title'>Welcome back!</h2>
                <p className='description'>
                  Sign in to continue with Poonam Coatings
                </p>
              </header>
              <form
                className='auth-form'
                onSubmit={formik.handleSubmit}
                noValidate='novalidate'
                autoComplete='off'
              >
                <div className='form-control'>
                  <div
                    className={`form-input-box ${
                      formik.values.email !== "" || focusedField === "email"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='email' className='toggle-label'>
                      Email
                    </label>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      className='form-input'
                      ref={emailRef}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("email")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      autoComplete='false'
                    />
                  </div>
                  {formik.errors.email && formik.touched.email ? (
                    <span className='err'>{formik.errors.email}</span>
                  ) : null}
                </div>
                <div className='form-control'>
                  <div
                    className={`form-input-box ${
                      formik.values.password !== "" ||
                      focusedField === "password"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='password' className='toggle-label'>
                      Password
                    </label>
                    <input
                      type={visibility ? "text" : "password"}
                      ref={passwordRef}
                      id='password'
                      name='password'
                      className='form-input'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      maxLength={20}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("password")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      autoComplete='false'
                    />
                    <span
                      className='material-symbols-rounded medium visiblity'
                      onClick={() => setVisibility(!visibility)}
                    >
                      {!visibility ? "visibility_off" : "visibility"}
                    </span>
                  </div>
                  {formik.errors.password && formik.touched.password ? (
                    <span className='err'>{formik.errors.password}</span>
                  ) : null}
                </div>
                <div className='f-check'>
                  <div className='check-left'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        id='check'
                        name='isRem'
                        value={formik.values.isRem}
                        onChange={formik.handleChange}
                      />
                      <span className='slider round'></span>
                    </label>
                    <span className='rem-txt'>Remember me</span>
                  </div>
                  <Link to='/forgot-password' className='forgot-txt'>
                    Forgot Your Password?
                  </Link>
                </div>
                <button
                  className='btn-tertiary'
                  type='submit'
                  style={
                    authLoading
                      ? {
                          cursor: "not-allowed",
                          textAlign: "center",
                          // background: "gray",
                        }
                      : {}
                  }
                  ref={ref}
                  disabled={authLoading}
                >
                  {authLoading ? (
                    <ClipLoader
                      loading={true}
                      size={15}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                      color='white'
                    />
                  ) : (
                    "SIGN IN"
                  )}
                </button>

                {/* <span className='divider flex-center'>
                  <hr />
                  Or
                  <hr />
                </span> */}
                {/* <button type="button" className="option-btn flex-center">
                  <img
                    src={require("../../../assets/icons/google.svg").default}
                    alt=""
                  />
                  Sign In with Google
                </button> */}
                {/* <button type="button" className="btn2"> */}
                {/* <GoogleOAuthProvider clientId={ClientId}>
                  <GoogleLogin
                    render={renderProps => (
                      <button
                        type='button'
                        className=''
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      ></button>
                    )}
                    onSuccess={googleLoginResponse}
                    onFailure={googleLoginResponse}
                    cookiePolicy='single_host_origin'
                    size='large'
                    width={ref.current?.clientWidth}
                    theme='outlined'
                    shape='rectangular'
                    logo_alignment='center'
                  />
                </GoogleOAuthProvider> */}
                {/* </button> */}
              </form>

              {/* <div className="auth-footer text-center">
                Don't have an account{" "}
                <Link to="/login" className="link">
                  Sign up
                </Link>
              </div> */}
            </div>

            <nav className='auth-nav flex-center'>
              <a
                href='https://poonamcoatings.com/about-us/'
                className='nl'
                target='_blank'
                rel='noreferrer'
              >
                About us
              </a>
              <a
                href='https://poonamcoatings.com/contact-us/'
                className='nl'
                target='_blank'
                rel='noreferrer'
              >
                Contact us
              </a>
              <a
                href='https://poonamcoatings.com/services/'
                className='nl'
                target='_blank'
                rel='noreferrer'
              >
                Services
              </a>
            </nav>

            <footer className='auth_copyright'>
              Copyright © {year}{" "}
              <a
                href='https://poonamcoatings.com/'
                target='blank'
                rel='noreferrer'
                className='footer_link'
              >
                Poonam Coatings.
              </a>{" "}
              All Rights Reserved
            </footer>
          </main>
        </div>
      </div>
    </div>
  )
}

export default Login
