import { createSlice } from "@reduxjs/toolkit"
import {
  getOptionsList,
  getOptionsListForTable,
} from "../../products/redux/ProductsApi"
import { options } from "../../../helper/helper"

const catalogueSlice = createSlice({
  name: "catalogue",
  initialState: {
    catalogueLoading: false,
    optionsList: [],
    metaData: [],
  },

  reducers: {
    isCatalogueToggle: (state, { payload }) => {
      state.catalogueLoading = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getOptionsListForTable.pending, (state, { payload }) => {
        state.catalogueLoading = true
      })
      .addCase(getOptionsListForTable.fulfilled, (state, { payload }) => {
        state.catalogueLoading = false
        state.optionsList = payload?.data
        state.metaData = payload?.metadata ? payload?.metadata[0] : {}
      })
      .addCase(getOptionsListForTable.rejected, state => {
        state.catalogueLoading = false
      })
  },
})

export const { isCatalogueToggle } = catalogueSlice.actions
export default catalogueSlice.reducer
