import { useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import DropDownBox from "../../../components/DropDownBox"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Pagination from "../../../components/Pagination"
import SearchDropDown from "../../../components/SearchDropDown"
import Sidebar from "../../../components/Sidebar"
import Skelaton from "../../../components/Skelaton"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
import {
  capitalizeInput,
  filterNumbersRegex,
  formatTimestamp,
  numberWithCommas,
  priceValidator,
} from "../../../helper/helper"
import { setPageDetails } from "../../auth/redux/authSlice"
import { updatePrimaryAddress } from "../../buyers&suppliers/redux/bNsApi"
import { getSelfCompanyAddress } from "../../company/redux/companyApi"
import {
  addComment,
  getBasePaint,
  getOptionsList,
} from "../../products/redux/ProductsApi"
import UpdateInventory from "../components/UpdateInventory"
import { getInventoryDetails, getInventoryHistory } from "../redux/InventoryApi"

const InventoryDetails = ({ setTitle }) => {
  const [active, setActive] = useState("inventoryList")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [showUpdateInventory, setShowUpdateInventory] = useState(false)
  const [popupData, setPopupData] = useState(false)
  const [isPopup, setIsPopup] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [activeInputs, setActiveInputs] = useState(false)
  const { inventoryLoading, inventoryDetails } = useSelector(
    store => store.inventory,
  )
  // const { emailDetails } = useSelector((store) => store.email);
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  useEffect(() => {
    setTitle("Inventory Details")
    dispatch(getInventoryDetails({ id }))
  }, [setTitle])

  const [uomList, setUomList] = useState([])

  const apiCall = async () => {
    {
      const response = await dispatch(
        getOptionsList({
          listName: "uom-list",
          search: "",
          sort: "desc",
          limit: 0,
          page: 0,
        }),
      )
      const options = response?.payload?.map(item => {
        return { label: item.uom_type, value: item._id }
      })
      setUomList(options)
    }
  }

  useEffect(() => {
    if (uomList?.length === 0) apiCall()
  }, [])

  // useEffect(() => {
  //   if (id) {
  //     setTitle("Edit Email");
  //     formik.setFieldValue("subject", emailDetails[0]?.subject);
  //     formik.setFieldValue("title", emailDetails[0]?.title);
  //     formik.setFieldValue("email_text", emailDetails[0]?.email_text);
  //   }
  // }, [id, setTitle]);

  const [focusedField, setFocusedField] = useState()

  const initialValues = {
    productName: "",
    skuNum: "233",
    brand: "",
    category: "",
    color: "",
    packSize: "",
    uom: "",
    packQuantity: "",
    productCode: "",
    productType: "",
    hsn: "",
  }
  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object()

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      setActiveInputs(false)

      //   const data = {
      //     contactPersonName: values.name.trim(),
      //     name: values.company_name,
      //     contact_email: values.email,
      //     contact_phone: `+91${values.mobile_no}`,
      //     phone: `+91${values.productName}`,
      //     reference_id: values.company_ref_id,
      //     email: values.skuNum,
      //     gstNumber: values.gst_number,
      //     buyerAndSupplier: values.company_role,
      //     address_line_one: values.address_line_one,
      //     address_line_two: values.address_line_two,
      //     city: values.city,
      //     state: values.state,
      //     pin_code: values.pinCode,
      //   };
      //   const response = await dispatch(AddSingleCompany(data));
      //   if (response?.payload) {
      //     setPopup(0);
      //     resetForm();
      //   }
    },
  })

  const handleCancel = () => {
    if (Object.keys(formik.errors)?.length === 0) {
      setActiveInputs(false)
    }
  }
  useEffect(() => {
    formik.setValues({
      skuNum: inventoryDetails?.basic_details?.sku
        ? inventoryDetails?.basic_details?.sku
        : "",
      productName: inventoryDetails?.basic_details?.name
        ? inventoryDetails?.basic_details?.name
        : "",
      productCode: inventoryDetails?.basic_details?.productCode
        ? inventoryDetails?.basic_details?.productCode
        : "",
      packSize: inventoryDetails?.basic_details?.pack_size
        ? inventoryDetails?.basic_details?.pack_size
        : "",
      packQuantity: inventoryDetails?.basic_details?.pack_quantity
        ? inventoryDetails?.basic_details?.pack_quantity
        : "",
    })
  }, [inventoryDetails])

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {showUpdateInventory ? (
            <UpdateInventory
              popupData={popupData}
              customState={showUpdateInventory}
              customSetter={setShowUpdateInventory}
            />
          ) : null}
          {isPopup && (
            <ConformationPopup
              customState={isPopup}
              customSetter={setIsPopup}
              mainTitle='Do you want to make this inventory as your primary inventory?'
              subTitle='This action will change your primary inventory'
              api={updatePrimaryAddress}
              popUpData={isPopup}
              reData={""}
              reFetchApi={getSelfCompanyAddress}
              popupType='warehouse'
              toastMessage='Primary address changed'
            />
          )}
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Inventory Details</div>
                  <div className='mch-path'>
                    <Breadcrumb id title={"Inventory Details"} />
                  </div>
                </div>
              </div>
              <div className='main-box-padding'>
                <div className='modelEx_content singleAdd'>
                  <div className='profile_personal_details'>
                    <div className='details-controls'>
                      <h2>Basic Details</h2>
                    </div>
                  </div>

                  <form
                    className='product-detail-form'
                    onSubmit={formik.handleSubmit}
                  >
                    <div className='form-content'>
                      <div className='form-group form'>
                        <div className='col-2'>
                          <div
                            className={`input-field ${
                              formik.values.skuNum !== "" ||
                              focusedField === "skuNum"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label htmlFor='email' className='toggle-label'>
                              SKU Number
                            </label>
                            <input
                              id='skuNum'
                              name='skuNum'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.skuNum}
                              // maxLength={80}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("skuNum")}
                              onKeyDown={e => {
                                if (e.key === " ") {
                                  e.preventDefault()
                                }
                              }}
                              disabled={!activeInputs}
                            />
                            {formik.touched.skuNum && formik.errors.skuNum ? (
                              <span className='err'>
                                {formik.errors.skuNum}
                              </span>
                            ) : null}
                          </div>
                          <div
                            className={`input-field ${
                              formik.values.productName !== "" ||
                              focusedField === "productName"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label
                              htmlFor='productName'
                              className='toggle-label'
                            >
                              Product Name
                            </label>
                            <input
                              id='productName'
                              name='productName'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.productName}
                              onInput={capitalizeInput}
                              maxLength={81}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("productName")}
                              disabled={!activeInputs}
                            />
                          </div>{" "}
                        </div>
                        <div className='col-2'>
                          <div
                            className={`input-field ${
                              formik.values.productCode !== "" ||
                              focusedField === "productCode"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label
                              htmlFor='productCode'
                              className='toggle-label'
                            >
                              Product Code
                            </label>
                            <input
                              id='productCode'
                              name='productCode'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.productCode}
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /(?:^|\s)\S/g,
                                  match => match.toUpperCase(),
                                )
                              }}
                              maxLength={12}
                              minLength={1}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("productCode")}
                              disabled={!activeInputs}
                            />
                            {formik.touched.productCode &&
                            formik.errors.productCode ? (
                              <span className='err'>
                                {formik.errors.productCode}
                              </span>
                            ) : null}
                          </div>{" "}
                          {/* <div
                            className={`input-field ${
                              formik.values.batchNumber !== "" ||
                              focusedField === "batchNumber"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label
                              htmlFor='batchNumber'
                              className='toggle-label'
                            >
                              Batch Number
                            </label>
                            <input
                              id='batchNumber'
                              name='batchNumber'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.batchNumber}
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /(?:^|\s)\S/g,
                                  match => match.toUpperCase(),
                                )
                              }}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              maxLength={17}
                              minLength={1}
                              onFocus={() => setFocusedField("batchNumber")}
                              disabled={!activeInputs}
                            />
                            {formik.touched.batchNumber &&
                            formik.errors.batchNumber ? (
                              <span className='err'>
                                {formik.errors.batchNumber}
                              </span>
                            ) : null}
                          </div> */}
                          <div className='input-field'>
                            <DropDownBox
                              options={[
                                {
                                  label: "Standard Product",
                                  value: "Standard",
                                },
                                {
                                  label: "Non Standard Product",
                                  value: "Non-Standard",
                                },
                              ]}
                              customSetter={formik.setFieldValue}
                              dropDownTitle='Product Standard'
                              animateDropDownTitle
                              customFormikLabel={"productType"}
                              customDropBoxStyles={{
                                border: "1px solid #d7cece",
                                borderRadius: "0.5rem",
                                color: "#333",
                              }}
                              customTextStyle={{
                                color: "#212229",
                                opacity: "0.7",
                              }}
                              disabled={false}
                              incomingValue={
                                inventoryDetails?.basic_details?.productStandard
                              }
                            />
                            {/* <SearchDropDown
                      label='Brand'
                      name='brand'
                      api={getOptionsList}
                      listName={"brand-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                            {formik.touched.productType &&
                            formik.errors.productType ? (
                              <span className='err'>
                                {formik.errors.productType}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className='col-2'>
                          <div className='input-field'>
                            <SearchDropDown
                              label='Brand'
                              name='brand'
                              api={getOptionsList}
                              listName={"brand-list"}
                              customSetter={formik.setFieldValue}
                              incomingValue={
                                inventoryDetails?.basic_details?.brand
                              }
                              disabled={!activeInputs}
                            />
                            {formik.touched.brand && formik.errors.brand ? (
                              <span className='err'>{formik.errors.brand}</span>
                            ) : null}
                          </div>

                          <div className='input-field'>
                            <SearchDropDown
                              label='Category'
                              name='category'
                              api={getOptionsList}
                              listName={"category-list"}
                              customSetter={formik.setFieldValue}
                              incomingValue={
                                inventoryDetails?.basic_details?.category
                              }
                              disabled={!activeInputs}
                            />
                          </div>
                        </div>{" "}
                        {/* <div className='col-2'>
                          <div className='input-field'>
                            <SearchDropDown
                              label='Color'
                              name='color'
                              api={getOptionsList}
                              listName={"color-list"}
                              customSetter={formik.setFieldValue}
                              incomingValue={
                                inventoryDetails?.basic_details?.color
                              }
                              disabled={!activeInputs}
                            />
                          </div>
                          <div className='col-2 gstn'>
                            <div
                              className={`input-field ${
                                formik.values.packSize !== "" ||
                                focusedField === "packSize"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <label
                                htmlFor='packSize'
                                className='toggle-label'
                              >
                                Pack Size
                              </label>
                              <input
                                id='packSize'
                                name='packSize'
                                type='text'
                                className='form-input'
                                onChange={formik.handleChange}
                                value={formik.values.packSize}
                                onInput={e => {
                                  e.target.value = e.target.value
                                    .replace(/[^A-Za-z ]/gi, "")
                                    .replace(/(?:^|\s)\S/g, match =>
                                      match.toUpperCase(),
                                    )
                                }}
                                maxLength={81}
                                onBlur={e => {
                                  setFocusedField("")
                                  formik.handleBlur(e)
                                }}
                                disabled={!activeInputs}
                                onFocus={() => setFocusedField("packSize")}
                                onPaste={e => {
                                  e.preventDefault()
                                }}
                                onCopy={e => {
                                  e.preventDefault()
                                }}
                              />
                              {formik.touched.packSize &&
                              formik.errors.packSize ? (
                                <span className='err'>
                                  {formik.errors.packSize}
                                </span>
                              ) : null}
                            </div>{" "}
                            <div className='input-field'>
                              <DropDownBox
                                options={uomList}
                                customSetter={formik.setFieldValue}
                                dropDownTitle='UOM'
                                animateDropDownTitle
                                customFormikLabel={"uom"}
                                disabled={activeInputs}
                                customDropBoxStyles={{
                                  border: "1px solid #d7cece",
                                  borderRadius: "0.5rem",
                                  color: "#333",
                                }}
                                customTextStyle={{
                                  color: "#212229",
                                  opacity: "0.7",
                                }}
                                resetButton
                                incomingValue={
                                  inventoryDetails?.basic_details?.uomID
                                }
                              />
                              {formik.touched.uom && formik.errors.uom ? (
                                <span className='err'>{formik.errors.uom}</span>
                              ) : null}
                            </div>
                          </div>
                        </div>{" "} */}
                        <div className='col-2'>
                          <div className='input-field'>
                            <SearchDropDown
                              label='Color'
                              name='color'
                              api={getOptionsList}
                              listName={"color-list"}
                              customSetter={formik.setFieldValue}
                              incomingValue={
                                inventoryDetails?.basic_details?.color
                              }
                              disabled={!activeInputs}
                            />
                          </div>
                          <SearchDropDown
                            label='HSN Code'
                            name='hsnId'
                            customSetter={formik.setFieldValue}
                            dynamicLabel={"hsn"}
                            maxLength={8}
                            type='number'
                            removeSpace
                            disabled={true}
                            incomingValue={inventoryDetails?.basic_details?.hsn}
                          />
                        </div>{" "}
                        <div className='col-2'>
                          <div className='col-2 gstn'>
                            <div
                              className={`input-field ${
                                formik.values.packSize !== "" ||
                                focusedField === "packSize"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <label
                                htmlFor='packSize'
                                className='toggle-label'
                              >
                                Pack Size
                              </label>
                              <input
                                id='packSize'
                                name='packSize'
                                type='text'
                                className='form-input'
                                onChange={formik.handleChange}
                                value={formik.values.packSize}
                                onInput={e => {
                                  e.target.value = e.target.value
                                    .replace(/[^A-Za-z ]/gi, "")
                                    .replace(/(?:^|\s)\S/g, match =>
                                      match.toUpperCase(),
                                    )
                                }}
                                maxLength={81}
                                onBlur={e => {
                                  setFocusedField("")
                                  formik.handleBlur(e)
                                }}
                                disabled={!activeInputs}
                                onFocus={() => setFocusedField("packSize")}
                                onPaste={e => {
                                  e.preventDefault()
                                }}
                                onCopy={e => {
                                  e.preventDefault()
                                }}
                              />
                              {formik.touched.packSize &&
                              formik.errors.packSize ? (
                                <span className='err'>
                                  {formik.errors.packSize}
                                </span>
                              ) : null}
                            </div>{" "}
                            <div className='input-field'>
                              <DropDownBox
                                options={uomList}
                                customSetter={formik.setFieldValue}
                                dropDownTitle='UOM'
                                animateDropDownTitle
                                customFormikLabel={"uom"}
                                disabled={activeInputs}
                                customDropBoxStyles={{
                                  border: "1px solid #d7cece",
                                  borderRadius: "0.5rem",
                                  color: "#333",
                                }}
                                customTextStyle={{
                                  color: "#212229",
                                  opacity: "0.7",
                                }}
                                resetButton
                                incomingValue={
                                  inventoryDetails?.basic_details?.uomID
                                }
                              />
                              {formik.touched.uom && formik.errors.uom ? (
                                <span className='err'>{formik.errors.uom}</span>
                              ) : null}
                            </div>
                          </div>{" "}
                          <div
                            className={`input-field ${
                              formik.values.packSize !== "" ||
                              focusedField === "packSize"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label htmlFor='packSize' className='toggle-label'>
                              Pack Quantity
                            </label>
                            <input
                              id='packSize'
                              name='packSize'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.packQuantity}
                              onInput={filterNumbersRegex}
                              maxLength={81}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              disabled={!activeInputs}
                              onFocus={() => setFocusedField("packSize")}
                              onPaste={e => {
                                e.preventDefault()
                              }}
                              onCopy={e => {
                                e.preventDefault()
                              }}
                            />
                            {formik.touched.packSize &&
                            formik.errors.packSize ? (
                              <span className='err'>
                                {formik.errors.packSize}
                              </span>
                            ) : null}
                          </div>{" "}
                        </div>
                        {formik.touched.hsnId && formik.errors.hsnId ? (
                          <span className='err'>{formik.errors.hsnId}</span>
                        ) : null}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <WarehouseDetails
                warehouseDetails={inventoryDetails?.inventory_detail}
                customSetter={setShowUpdateInventory}
                setPopupData={setPopupData}
                inventoryDetail={{
                  id,
                  manufacture: inventoryDetails?.manufacturer,
                  type: inventoryDetails?.basic_details?.Type,
                }}
                setIsPopup={setIsPopup}
              />
              <OtherDetails
                otherDetails={inventoryDetails?.other_details}
                id={id}
              />

              {inventoryDetails?.manufacturer === "self" && (
                <PaintDetails
                  id={id}
                  PaintDetails={inventoryDetails?.paint_formula}
                  uomList={uomList}
                  brandId={""}
                />
              )}
              <PriceDetails
                id={id}
                priceDetails={inventoryDetails?.price_details}
              />
              <InventoryHistory
                id={id}
                unitType={
                  inventoryDetails?.basic_details?.Type === "powder" ||
                  inventoryDetails?.basic_details?.Type === "putty"
                    ? "kg"
                    : inventoryDetails?.basic_details?.Type === "sheet"
                      ? "Numbers"
                      : "litres"
                }
              />

              <Comments
                commentDetails={inventoryDetails?.commentsDetail}
                id={id}
              />
            </div>
            <Footer />{" "}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InventoryDetails

const OtherDetails = ({ otherDetails, id }) => {
  const [activeInputs, setActiveInputs] = useState(false)

  const [focusedField, setFocusedField] = useState()
  const initialValues = {
    description: "",
    subCategory: "",
    subChildCategory: "",
    paintType: "",
    finish: "",
  }

  const singleComSchema = Yup.object()

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {},
  })

  const handleCancel = () => {
    if (Object.keys(formik.errors)?.length === 0) {
      setActiveInputs(false)
    }
  }

  useEffect(() => {
    formik.setValues({
      description: otherDetails?.description ? otherDetails?.description : "",
    })
  }, [otherDetails])

  return (
    <>
      <div className='main-box-padding'>
        <div className='modelEx_content singleAdd'>
          <div className='profile_personal_details'>
            <div className='details-controls'>
              <h2>Other Details</h2>
              {/* {Object.values(privileges["production_management"]).includes(
                "write",
              ) && (
                <div className='detail-btn'>
                  {activeInputs ? (
                    <>
                      <button
                        type='submit'
                        onClick={handleCancel}
                        className='btn-secondary'
                        disabled={productsLoading}
                        style={productsLoading ? { cursor: "not-allowed" } : {}}
                      >
                        {productsLoading ? (
                            <ClipLoader
              loading={true}
              size={15}
              aria-label='Loading Spinner'
              data-testid='loader'
              color='white'
            />
                        ) : (
                          "Cancel"
                        )}
                      </button>{" "}
                      <button
                        type='submit'
                        onClick={formik.handleSubmit}
                        className='btn-primary'
                        disabled={productsLoading}
                        style={productsLoading ? { cursor: "not-allowed" } : {}}
                      >
                        {productsLoading ? (
                            <ClipLoader
              loading={true}
              size={15}
              aria-label='Loading Spinner'
              data-testid='loader'
              color='white'
            />
                        ) : (
                          "Save"
                        )}
                      </button>{" "}
                    </>
                  ) : (
                    <button
                      onClick={() => setActiveInputs(true)}
                      className='btn-secondary'
                    >
                      <span className='material-symbols-outlined small'>
                        border_color
                      </span>
                      Edit
                    </button>
                  )}
                </div>
              )}{" "} */}
            </div>
          </div>

          <form className='product-detail-form' onSubmit={formik.handleSubmit}>
            <div className='form-content'>
              <div className='form-group form'>
                <div className='col-2'>
                  <div className='input-field'>
                    <SearchDropDown
                      label={"Paint Type"}
                      name='paintType'
                      api={getOptionsList}
                      customSetter={formik.setFieldValue}
                      listName={"painttype-list"}
                      dynamicLabel={"paint_type"}
                      disabled={!activeInputs}
                      incomingValue={otherDetails?.paintType}
                      // dynamicLabel='paint_type'
                    />{" "}
                    {formik.touched.paintType && formik.errors.paintType ? (
                      <span className='err'>{formik.errors.paintType}</span>
                    ) : null}
                  </div>{" "}
                  <div className='input-field'>
                    <SearchDropDown
                      label='Finish Type'
                      customSetter={formik.setFieldValue}
                      name='finish'
                      listName={"finishtype-list"}
                      dynamicLabel={"finish_type"}
                      api={getOptionsList}
                      disabled={!activeInputs}
                      incomingValue={otherDetails?.finishType}
                    />
                    {formik.touched.finish && formik.errors.finish ? (
                      <span className='err'>{formik.errors.finish}</span>
                    ) : null}
                  </div>{" "}
                </div>
                <div className='col-2'>
                  <div className='input-field'>
                    <SearchDropDown
                      label='Sub Category'
                      name='subCategory'
                      api={getOptionsList}
                      listName={"subCategory-list"}
                      customSetter={formik.setFieldValue}
                      disabled={!activeInputs}
                      incomingValue={otherDetails?.subcategory}
                    />
                    {formik.touched.subCategory && formik.errors.subCategory ? (
                      <span className='err'>{formik.errors.subCategory}</span>
                    ) : null}
                  </div>

                  <div className='input-field'>
                    <SearchDropDown
                      label='Sub Child Category'
                      name='subChildCategory'
                      api={getOptionsList}
                      listName={"subChildCategory-list"}
                      customSetter={formik.setFieldValue}
                      disabled={!activeInputs}
                      incomingValue={otherDetails?.subchildategory}
                    />
                    {formik.touched.subChildCategory &&
                    formik.errors.subChildCategory ? (
                      <span className='err'>
                        {formik.errors.subChildCategory}
                      </span>
                    ) : null}
                  </div>
                </div>{" "}
                <div
                  className={`input-field ${
                    formik.values.description !== "" ||
                    focusedField === "aboutUs"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='description' className='toggle-label'>
                    Description
                  </label>
                  <textarea
                    id='description'
                    name='description'
                    rows={4}
                    className={
                      !activeInputs
                        ? "form-input disabeldTextArea"
                        : "form-input"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.description || ""}
                    maxLength={151}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("aboutUs")}
                    disabled={!activeInputs}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const WarehouseDetails = ({
  warehouseDetails,
  customSetter,
  setPopupData,
  inventoryDetail,
  setIsPopup,
}) => {
  const { inventoryLoading } = useSelector(store => store.inventory)
  const { companyAddress, companyDetails } = useSelector(store => store.company)

  const [warehouseList, setWarehouseList] = useState([])

  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const [tableLoading, setTableLoading] = useState(false)
  const [tableCount, setTableCount] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    if (tableCount) {
      clearTimeout(tableCount)
    }
    const count = setTimeout(() => {
      setTableLoading(inventoryLoading)
    }, 1000)
    setTableCount(count)
  }, [inventoryLoading])

  // useEffect(() => {
  //   if (companyAddress?.length === 0) {
  //     dispatch(getSelfCompanyAddress())
  //   }
  // }, [])

  useEffect(() => {
    // if (warehouseDetails?.length > 0) {
    //   const newList = companyAddress.map((item, index) => {
    //     if (
    //       item?._id ===
    //       warehouseDetails.find(findItem => findItem.address_id === item?._id)
    //     ) {
    //       return {
    //         address_id: warehouseDetails[index]?.address_id,
    //         inventory_name: warehouseDetails[index]?.inventory_name,
    //         product_id: inventoryDetail?.id,
    //         msl: warehouseDetails[index]?.msl,
    //         quantity: warehouseDetails[index]?.quantity,
    //       }
    //     } else {
    //       return {
    //         address_id: item?._id,
    //         inventory_name: item?.slug,
    //         product_id: inventoryDetail?.id,
    //         msl: 0,
    //         quantity: 0,
    //       }
    //     }
    //   })
    //   setWarehouseList(newList)
    // } else {
    //   const newList = companyAddress.map((item, index) => {
    //     return {
    //       address_id: item?._id,
    //       inventory_name: item?.slug,
    //       product_id: inventoryDetail?.id,
    //       msl: 0,
    //       quantity: 0,
    //     }
    //   })
    //   setWarehouseList(newList)
    // }

    if (warehouseDetails?.length > 0) {
      let findIndex
      const newList = companyAddress.map((item, index) => {
        if (
          item?._id ===
          warehouseDetails.find((findItem, index) => {
            findIndex = index
            return findItem?.address_id === item?._id
          })?.address_id
        ) {
          return {
            address_id: warehouseDetails[findIndex]?.address_id,
            inventory_name: warehouseDetails[findIndex]?.inventory_name,
            product_id: inventoryDetail?.id,
            msl: warehouseDetails[findIndex]?.msl,
            quantity: warehouseDetails[findIndex]?.quantity,
            type: item.type,
            constraint: item.constraint,
          }
        } else {
          return {
            address_id: item?._id,
            inventory_name: item?.slug,
            product_id: inventoryDetail?.id,
            msl: 0,
            quantity: 0,
            type: item.type,
            constraint: item.constraint,
          }
        }
      })
      setWarehouseList(newList)
    } else {
      const newList = companyAddress.map((item, index) => {
        return {
          address_id: item?._id,
          inventory_name: item?.slug,
          product_id: inventoryDetail?.id,
          msl: 0,
          quantity: 0,
          type: item.type,
          constraint: item.constraint,
        }
      })
      setWarehouseList(newList)
    }
  }, [companyAddress, warehouseDetails])
  // useEffect(() => {
  //   // const newList = companyAddress.map((item, index) => {
  //   //   if (warehouseDetails[index]) {
  //   //     return warehouseDetails[index]
  //   //   } else {
  //   //     return {
  //   //       address_id: item?._id,
  //   //       inventory_name: item?.slug,
  //   //       product_id: id,
  //   //       msl: 0,
  //   //       quantity: 0,
  //   //     }
  //   //   }
  //   // })

  //   // const newList = companyAddress.map((item, index) => {})
  //   // setWarehouseList(newList)
  // }, [warehouseDetails])

  return (
    <>
      <div
        className='main-box-padding data-table-container'
        style={{ padding: "1.8rem", borderRadius: "1.5rem" }}
      >
        <div className='modelEx_content singleAdd'>
          <div className='profile_personal_details'>
            <div className='details-controls'>
              <h2>Update Inventory</h2>
            </div>
            {tableLoading ? (
              <Skelaton
                col={4}
                row={warehouseDetails?.length ? warehouseDetails?.length : 3}
              />
            ) : (
              // :
              //  warehouseDetails?.length ? (
              //   <>
              //     <div className='data-table-box inventory scrollbar-visible'>
              //       <table className='data-table fix-width-table-row '>
              //         <thead>
              //           <tr>
              //             <th className='tb-head-name'>#</th>
              //             <th>Warehouse Name</th>
              //             <th>
              //               Quantity{" "}
              //               {inventoryDetail?.type === "powder/putty"
              //                 ? "(In kgs)"
              //                 : inventoryDetail?.type === "sheet"
              //                   ? "(In Numbers)"
              //                   : "(In litres)"}
              //             </th>
              //             <th>
              //               Minimum Quantity{" "}
              //               {inventoryDetail?.type === "powder/putty"
              //                 ? "(In kgs)"
              //                 : inventoryDetail?.type === "sheet"
              //                   ? "(In Numbers)"
              //                   : "(In litres)"}
              //             </th>
              //             {Object?.values(
              //               privileges["inventory_management"],
              //             )?.includes("write") && <th>Action</th>}
              //           </tr>
              //         </thead>

              //         <tbody>
              //           {warehouseDetails?.map((item, index) => {
              //             const { inventory_name, quantity, msl } = item

              //             return (
              //               <tr key={index}>
              //                 <td>{index + 1}</td>
              //                 <td>{inventory_name}</td>
              //                 <td
              //                   style={
              //                     quantity <= msl
              //                       ? { color: "red" }
              //                       : { color: "green" }
              //                   }
              //                   className='remove-overflow'
              //                   title={
              //                     quantity <= msl
              //                       ? "Low Stock Level"
              //                       : "Efficient Stock Level"
              //                   }
              //                 >
              //                   {quantity.toFixed(2)}
              //                 </td>
              //                 <td>{msl.toFixed(2)}</td>
              //                 <td className='padding-07'>
              //                   <div className='email-action-box'>
              //                     {Object?.values(
              //                       privileges["inventory_management"],
              //                     )?.includes("write") && (
              //                       <button
              //                         className='icon-btn edit'
              //                         onClick={
              //                           () => {
              //                             customSetter(true)
              //                             setPopupData({
              //                               ...item,
              //                               manufacture:
              //                                 inventoryDetail?.manufacture,
              //                               type: inventoryDetail?.type,
              //                             })
              //                           }
              //                           // navigate(`/buyers-and-suppliers/${_id}`)
              //                         }
              //                       >
              //                         <span className='material-symbols-outlined medium'>
              //                           border_color
              //                         </span>
              //                       </button>
              //                     )}{" "}
              //                   </div>
              //                 </td>
              //               </tr>
              //             )
              //           })}
              //         </tbody>
              //       </table>
              //     </div>
              //   </>
              // )
              <>
                <div className='data-table-box'>
                  <table className='data-table fix-width-table-row'>
                    <thead>
                      <tr>
                        <th className='tb-head-name'>#</th>
                        <th>Warehouse Name</th>
                        <th>Quantity </th>
                        <th>
                          Minimum Quantity
                          {/* {inventoryDetail?.type === "powder" ||
                          inventoryDetail?.type === "putty"
                            ? "(In kgs)"
                            : inventoryDetail?.type === "sheet"
                              ? "(In Numbers)"
                              : "(In litres)"} */}
                        </th>
                        {Object?.values(
                          privileges["inventory_management"],
                        )?.includes("write") && <th>Action</th>}
                      </tr>
                    </thead>

                    <tbody>
                      {warehouseList?.map((item, index) => {
                        const {
                          inventory_name,
                          quantity,
                          msl,
                          type,
                          address_id,
                          constraint,
                        } = item

                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{inventory_name}</td>
                            <td
                              style={
                                quantity <= msl
                                  ? { color: "red" }
                                  : { color: "green" }
                              }
                              className='remove-overflow'
                              title={
                                quantity <= msl
                                  ? "Low Stock Level"
                                  : "Efficient Stock Level"
                              }
                            >
                              {quantity?.toFixed(2)}{" "}
                              {inventoryDetail?.type === "powder" ||
                              inventoryDetail?.type === "putty"
                                ? "kg"
                                : inventoryDetail?.type === "sheet"
                                  ? "Number"
                                  : "litres"}
                            </td>
                            <td>
                              {msl?.toFixed(2)}{" "}
                              {inventoryDetail?.type === "powder" ||
                              inventoryDetail?.type === "putty"
                                ? "kg"
                                : inventoryDetail?.type === "sheet"
                                  ? "Number"
                                  : "litres"}
                            </td>
                            <td className='padding-07'>
                              <div className='email-action-box'>
                                {Object?.values(
                                  privileges["inventory_management"],
                                )?.includes("write") &&
                                  (constraint === "primary" ? (
                                    <button
                                      className='icon-btn see'
                                      onClick={
                                        () => {
                                          customSetter(true)
                                          setPopupData({
                                            ...item,
                                            manufacture:
                                              inventoryDetail?.manufacture,
                                            type: inventoryDetail?.type,
                                          })
                                        }
                                        // navigate(`/buyers-and-suppliers/${_id}`)
                                      }
                                    >
                                      <span className='material-symbols-outlined medium'>
                                        border_color
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      className='icon-btn  btn-secondary'
                                      onClick={
                                        () => {
                                          setIsPopup({
                                            data: {
                                              address_id: address_id,
                                              company_id: companyDetails?._id,
                                              address_type: type,
                                            },
                                          })
                                        }
                                        // navigate(`/buyers-and-suppliers/${_id}`)
                                      }
                                    >
                                      <span className='material-symbols-outlined medium'>
                                        keep
                                      </span>
                                    </button>
                                  ))}{" "}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const InventoryHistory = ({ id, unitType }) => {
  const { inventoryHistory } = useSelector(store => store.inventory)

  const { inventoryLoading } = useSelector(store => store.inventory)
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const { inventoryHistoryMetaData } = useSelector(store => store.inventory)
  const dispatch = useDispatch()

  const [tableIndex, setTableIndex] = useState(
    inventoryHistoryMetaData?.page ? inventoryHistoryMetaData?.page : 0,
  )

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      dispatch(
        getInventoryHistory({
          page: pageDetails?.page ? pageDetails?.page : 0,
          limit: records,
          search:
            Object.keys(pageDetails)?.length > 0 ? pageDetails?.search : "",
          sort: "desc",

          send: { product_id: id },
        }),
      )
      setCheck(true)
      // dispatch(setPageDetails({ page: inventoryHistoryMetaData?.page, search }))
      return
    }

    const timeoutId = setTimeout(async () => {
      dispatch(
        getInventoryHistory({
          limit: records,
          page: 0,
          search,
          sort: "desc",

          send: { product_id: id },
        }),
      )
      setTableIndex(0)
      setBtnNo([1, 2, 3])
    }, 1000)
    // dispatch(setPageDetails({ page: inventoryHistoryMetaData?.page, search }))
    setSearchTimeout(timeoutId)
  }, [dispatch, records, search])

  // useEffect(() => {
  //   dispatch(setPageDetails({ page: inventoryHistoryMetaData?.page, search }))
  // }, [inventoryHistoryMetaData])

  // useEffect(() => {
  //   if (tableCount) {
  //     clearTimeout(tableCount)
  //   }
  //   const count = setTimeout(() => {
  //     setTableLoading(inventoryLoading)
  //   }, 1000)
  //   setTableCount(count)
  // }, [inventoryLoading])
  const { tableLoading } = useLoaderDelay({ loadingState: inventoryLoading })

  // useEffect(() => {
  //   dispatch(
  //     getInventoryHistory(),
  //   )
  // }, [])
  return (
    <>
      <div
        className='main-box-padding data-table-container'
        style={{ padding: "1.8rem", borderRadius: "1.5rem" }}
      >
        <div className='modelEx_content singleAdd'>
          <div className='profile_personal_details'>
            <div className='details-controls'>
              <h2>Inventory History</h2>
            </div>
            {tableLoading ? (
              <Skelaton
                col={4}
                row={inventoryHistory?.length ? inventoryHistory?.length : 3}
              />
            ) : inventoryHistory?.length > 0 ? (
              <>
                <div className='data-table-box scrollbar-visible'>
                  <table className='data-table'>
                    <thead>
                      <tr>
                        <th className='tb-head-name'>#</th>
                        <th>Products Name</th>
                        <th>Warehouse Name</th>
                        <th>Batch Number</th>
                        <th>Previous Quantity In Packs</th>
                        <th>Changed Quantity In Packs</th>
                        <th>New Quantity In Packs</th>
                        <th>Previous Quantity</th>
                        <th>Changed Quantity</th>
                        <th>New Quantity</th>
                        <th>Previous Min Quantity</th>
                        <th>Changed Min Quantity</th>
                        <th>New Min Quantity</th>
                        <th>Selling Unit Price</th>
                        <th>User</th>
                        <th>Date</th>
                      </tr>
                    </thead>

                    <tbody>
                      {inventoryHistory?.map((item, index) => {
                        const {
                          quantityInLitres,
                          product_name,
                          batchNumber,
                          quantity,
                          location,
                          price,
                          num,
                          msl,
                          // createdAt,
                          // createdBy_lname,
                          // createdBy_fname
                        } = item

                        return (
                          <tr key={index}>
                            <td>{num}</td>
                            <td>{product_name}</td>
                            <td style={{ maxWidth: "fit-content" }}>
                              {location}
                            </td>
                            <td>{batchNumber}</td>
                            <td>{quantity.previous.toFixed(2)}</td>
                            <td>{quantity.changed.toFixed(2)}</td>
                            <td>{quantity.new.toFixed(2)}</td>{" "}
                            <td>
                              {quantityInLitres.previous.toFixed(2)} {unitType}
                            </td>
                            <td>
                              {quantityInLitres.changed.toFixed(2)} {unitType}
                            </td>
                            <td>
                              {quantityInLitres.new.toFixed(2)} {unitType}
                            </td>
                            <td>
                              {msl.previous.toFixed(2)} {unitType}
                            </td>
                            <td>
                              {msl.changed.toFixed(2)} {unitType}
                            </td>
                            <td>
                              {msl.new.toFixed(2)} {unitType}
                            </td>
                            <td>₹ {numberWithCommas(priceValidator(price))}</td>
                            {/* <td>{createdBy_fname} {createdBy_lname}</td>
                            <td>{createdAt}</td> */}
                            <td>
                              {item.createdBy_fname} {item.createdBy_lname}
                            </td>
                            <td
                              style={{
                                overflow: "visible",
                                maxWidth: "max-content",
                              }}
                            >
                              {formatTimestamp(item.createdAt)}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  sliceName={"inventory"}
                  search={search}
                  filter={"desc"}
                  api={getInventoryHistory}
                  limit={5}
                  tableIndex={tableIndex}
                  setTableIndex={setTableIndex}
                  btnNo={btnNo}
                  setBtnNo={setBtnNo}
                  metaDataName='inventoryHistoryMetaData'
                  send={{ product_id: id }}
                />
              </>
            ) : (
              <div className='Nodata-txt'>
                <img
                  src={require("../../../assets/images/nodata.svg").default}
                  alt=''
                  srcSet=''
                />
                No Data Found
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const PaintDetails = ({ id, PaintDetails, uomList, brandId }) => {
  const [activeInputs, setActiveInputs] = useState(false)
  const dispatch = useDispatch()
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const [tintersData, setTintersData] = useState([
    {
      productId: "buyers",
      unit: "both",
      weight: "232",
    },
  ])

  const [tintersList, setTintersList] = useState([
    {
      tintersName: "tinters",
      proportionName: "proportionTinters",
      uomName: "dynamicUom",
    },
  ])

  const [focusedField, setFocusedField] = useState()
  const { productsLoading } = useSelector(store => store.products)
  const initialValues = {
    basePaint1: "",
    basePaint2: "",
    proportionPaint1: "",
    proportionPaint2: "",
    quantity: "",
    uomOfPaint1: "",
    uomOfPaint2: "",
  }

  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object({
    basePaint2: Yup.string().required("Base paint 2 is required"),
    proportionPaint1: Yup.string().required(
      "Proportion of Paint 1 is required",
    ),
    proportionPaint2: Yup.string().required(
      "Proportion of Paint 2 is required",
    ),
    tinters: Yup.string(),
    quantity: Yup.string().required("quantity is required"),
    uomOfPaint1: Yup.string().required("UOM is required"),
    uomOfPaint2: Yup.string().required("UOM is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      // const newTinterList = tintersData?.map((item, index) => {
      //   return {
      //     productId: item.productId,
      //     weight: { value: item.weight, unit: item.uomId },
      //   }
      // })
      // const data = {
      //   id,
      //   send: {
      //     base_paint_one_productId: values.basePaint1,
      //     base_paint_two_productId: values.basePaint2,
      //     base_paint_one_product_weight: values.proportionPaint1,
      //     base_paint_one_uomId: values.uomOfPaint1,
      //     base_paint_two_product_weight: values.proportionPaint2,
      //     base_paint_two_uomId: values.uomOfPaint2,
      //     tinters: newTinterList,
      //     total_quantity: values.quantity,
      //   },
      //   details_type: "update-paintFormula",
      // }
      // const response = await dispatch(updateBasicDetails(data))
      // if (response.payload) {
      //   setActiveInputs(false)
      // }
    },
  })

  const handleCancel = () => {
    if (Object.keys(formik.errors)?.length === 0) {
      setActiveInputs(false)
    }
  }
  const handleFormState = (value, obj) => {
    const { index, name, formikLabel } = obj

    setTintersData(tintersData => {
      const newList = JSON.parse(JSON.stringify(tintersData))

      if (newList[index] === undefined) {
        newList.push({ [name]: value })
      } else {
        newList[index][name] = value
      }
      return newList
    })

    // if (index === 0) {
    //   formik.setFieldValue(formikLabel, value)
    // }
  }

  useEffect(() => {
    formik.setValues({
      basePaint1: PaintDetails?.base_paint_one?._id,
      proportionPaint1: PaintDetails?.base_paint_one?.pack_size?.value,
      uomOfPaint1: PaintDetails?.base_paint_one?.pack_size?.uomId,
      basePaint2: PaintDetails?.base_paint_two?._id,
      proportionPaint2: PaintDetails?.base_paint_two?.pack_size?.value,
      uomOfPaint2: PaintDetails?.base_paint_two?.pack_size?.uomId,
      quantity: "0",
    })

    const newTinters = PaintDetails?.tinters.map((item, index) => {
      return {
        productId: item?.productId,
        weight: item?.pack_size?.weight,
        uomId: item?.pack_size?.uomId,
        name: item?.name,
      }
    })

    setTintersData(newTinters)
  }, [PaintDetails])
  //

  return (
    <>
      <div className='main-box-padding'>
        <div className='modelEx_content singleAdd'>
          <div className='profile_personal_details'>
            <div className='details-controls'>
              <h2>Paint Formula</h2>
            </div>
          </div>

          <form className='product-detail-form' onSubmit={formik.handleSubmit}>
            <div className='form-content'>
              <div className='form-group form'>
                <div className='col-2'>
                  <div className='input-field'>
                    {/* <DropDownBox
                      options={[
                        { label: "Buyers", value: "buyers" },
                        { label: "Suppliers", value: "suppliers" },
                        { label: "Both", value: "both" },
                      ]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"basePaint1"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Base Paint 1'
                      animateDropDownTitle
                      disabled={activeInputs}
                    /> */}
                    <SearchDropDown
                      label='Base Paint 1'
                      name='basePaint1'
                      customSetter={formik.setFieldValue}
                      dynamicLabel={"name"}
                      incomingValue={PaintDetails?.base_paint_one?.name}
                      disabled={!activeInputs}
                      api={getBasePaint}
                    />
                    {formik.touched.basePaint1 && formik.errors.basePaint1 ? (
                      <span className='err'>{formik.errors.basePaint1}</span>
                    ) : null}
                  </div>{" "}
                  <div className='col-2 gstn'>
                    <div
                      className={`input-field ${
                        formik.values.proportionPaint1 !== "" ||
                        focusedField === "proportionPaint1"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor='proportionPaint1'
                        className='toggle-label'
                      >
                        Proportion Of Paint 1
                      </label>
                      <input
                        id='proportionPaint1'
                        name='proportionPaint1'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.proportionPaint1}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^A-Za-z ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        maxLength={81}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("proportionPaint1")}
                        disabled={!activeInputs}
                      />
                      {formik.touched.proportionPaint1 &&
                      formik.errors.proportionPaint1 ? (
                        <span className='err'>
                          {formik.errors.proportionPaint1}
                        </span>
                      ) : null}
                    </div>{" "}
                    <div className='input-field'>
                      <DropDownBox
                        options={uomList}
                        customSetter={formik.setFieldValue}
                        defaultValue='UOM'
                        customFormikLabel={"uomOfPaint1"}
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                        disabled={activeInputs}
                        incomingValue={formik.values.uomOfPaint1}
                      />
                      {formik.touched.uomOfPaint1 &&
                      formik.errors.uomOfPaint1 ? (
                        <span className='err'>{formik.errors.uomOfPaint1}</span>
                      ) : null}
                    </div>
                  </div>
                </div>{" "}
                {tintersData?.map((item, index) => {
                  const { uomId, weight, name, proportionName, uomName } = item

                  return (
                    <div className='col-2' key={index}>
                      <div className='input-field'>
                        {/* <DropDownBox
                          options={[
                            { label: "Buyers", value: "buyers" },
                            { label: "Suppliers", value: "suppliers" },
                            { label: "Both", value: "both" },
                          ]}
                          customSetter={formik.setFieldValue}
                          customFormikLabel={tintersName}
                          customDropBoxStyles={{
                            border: "1px solid #d7cece",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          customTextStyle={{ color: "#212229", opacity: "0.7" }}
                          dropDownTitle={`TInters ${index + 1}`}
                          animateDropDownTitle
                          callCustomFunction={handleFormState}
                          customValueForCustomFunction={{
                            index,
                            name: "productId",
                            formikLabel: "tinters",
                          }}
                          disabled={activeInputs}
                        /> */}
                        <SearchDropDown
                          label={`Tinters ${index + 1}`}
                          name={`tinters${index + 1}`}
                          api={getOptionsList}
                          listName={"tinters-list"}
                          customSetter={formik.setFieldValue}
                          customFunctionOnClick={handleFormState}
                          customValueForFunctionOnClick={{
                            index,
                            name: "productId",
                            formikLabel: "tinters",
                          }}
                          dynamicLabel={"productName"}
                          dynamicValue={"productId"}
                          disabled={!activeInputs}
                          incomingValue={name}
                          apiData={{
                            send: {
                              brandId: brandId ? brandId : "",
                              category: "tinters",
                            },
                          }}
                        />

                        {formik.touched[name] && formik.errors[name] ? (
                          <span className='err'>{formik.errors[name]}</span>
                        ) : null}
                      </div>{" "}
                      <div className='col-2 gstn'>
                        <div
                          className={`input-field ${
                            formik.values.proportionTinters !== "" ||
                            focusedField === proportionName
                              ? "active"
                              : ""
                          }`}
                        >
                          <label
                            htmlFor={proportionName}
                            className='toggle-label'
                          >
                            Proportion of Tinters
                          </label>
                          <input
                            id={proportionName}
                            name={proportionName}
                            type='text'
                            className='form-input'
                            value={weight}
                            onChange={e => {
                              // handleFormState(e.target.value, {
                              //   index,
                              //   name: "weight",
                              //   formikLabel: "proportionTinters",
                              // })
                              setTintersData(oldList => {
                                const newList = JSON.parse(
                                  JSON.stringify(oldList),
                                )

                                newList[index].weight = e.target.value

                                return newList
                              })
                            }}
                            disabled={!activeInputs}
                            // value={formik.values.proportionTinters}
                            onInput={e => {
                              e.target.value = e.target.value
                                .replace(/[^\d ]/gi, "")
                                .replace(/(?:^|\s)\S/g, match =>
                                  match.toUpperCase(),
                                )
                            }}
                            maxLength={81}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField(proportionName)}
                          />
                          {formik.touched[proportionName] &&
                          formik.errors[proportionName] ? (
                            <span className='err'>
                              {formik.errors[proportionName]}
                            </span>
                          ) : null}
                        </div>{" "}
                        <div className='input-field'>
                          <DropDownBox
                            options={uomList}
                            customSetter={formik.setFieldValue}
                            defaultValue='UOM'
                            customFormikLabel={uomName}
                            customDropBoxStyles={{
                              border: "1px solid #d7cece",
                              borderRadius: "0.5rem",
                              color: "#333",
                            }}
                            customTextStyle={{
                              color: "#212229",
                              opacity: "0.7",
                            }}
                            customValueForCustomFunction={{
                              index,
                              name: "unit",
                              formikLabel: "dynamicUom",
                            }}
                            callCustomFunction={handleFormState}
                            disabled={activeInputs}
                            incomingValue={uomId}
                          />
                          {formik.touched[uomName] && formik.errors[uomName] ? (
                            <span className='err'>
                              {formik.errors[uomName]}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className='col-2'>
                  <div className='input-field'>
                    <SearchDropDown
                      label='Base Paint 2'
                      name='basePaint2'
                      customSetter={formik.setFieldValue}
                      dynamicLabel={"name"}
                      incomingValue={PaintDetails?.base_paint_two?.name}
                      disabled={!activeInputs}
                      api={getBasePaint}
                    />
                    {formik.touched.basePaint2 && formik.errors.basePaint2 ? (
                      <span className='err'>{formik.errors.basePaint2}</span>
                    ) : null}
                  </div>{" "}
                  <div className='col-2 gstn'>
                    <div
                      className={`input-field ${
                        formik.values.proportionPaint2 !== "" ||
                        focusedField === "proportionPaint2"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor='proportionPaint2'
                        className='toggle-label'
                      >
                        Proportion Of Paint 2
                      </label>
                      <input
                        id='proportionPaint2'
                        name='proportionPaint2'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.proportionPaint2}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^A-Za-z ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        disabled={!activeInputs}
                        maxLength={81}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("proportionPaint2")}
                      />
                      {formik.touched.proportionPaint2 &&
                      formik.errors.proportionPaint2 ? (
                        <span className='err'>
                          {formik.errors.proportionPaint2}
                        </span>
                      ) : null}
                    </div>{" "}
                    <div className='input-field'>
                      <DropDownBox
                        options={uomList}
                        customSetter={formik.setFieldValue}
                        defaultValue='UOM'
                        customFormikLabel={"uomOfPaint3"}
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        disabled={activeInputs}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                        incomingValue={formik.values.uomOfPaint2}
                      />
                      {formik.touched.uomOfPaint3 &&
                      formik.errors.uomOfPaint3 ? (
                        <span className='err'>{formik.errors.uomOfPaint3}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <div className="input-field">
                  <DropDownBox
                    options={[
                      { label: "Buyers", value: "buyers" },
                      { label: "Suppliers", value: "suppliers" },
                      { label: "Both", value: "both" },
                    ]}
                    
                    customSetter={formik.setFieldValue}
                    customFormikLabel={"quantity"}
                    customDropBoxStyles={{
                      border: "1px solid #d7cece",
                      borderRadius: "0.5rem",
                      color: "#333",
                    }}
                    customTextStyle={{ color: "#212229", opacity: "0.7" }}
                    dropDownTitle="Total Quantity Produce"
                    animateDropDownTitle
                  />
                  {formik.touched.quantity && formik.errors.quantity ? (
                    <span className="err">{formik.errors.quantity}</span>
                  ) : null}
                </div>{" "} */}
                {/* <div
                  className={`input-field ${
                    formik.values.quantity !== "" || focusedField === "quantity"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='quantity' className='toggle-label'>
                    Total Quantity Produce
                  </label>
                  <input
                    id='quantity'
                    name='quantity'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.quantity}
                    onInput={e => {
                      e.target.value = e.target.value
                        .replace(/[^\d ]/gi, "")
                        .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                    }}
                    maxLength={81}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("quantity")}
                    disabled={!activeInputs}
                  />
                  {formik.touched.quantity && formik.errors.quantity ? (
                    <span className='err'>{formik.errors.quantity}</span>
                  ) : null}
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const PriceDetails = ({ id, priceDetails }) => {
  const [activeInputs, setActiveInputs] = useState(false)
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { productsLoading } = useSelector(store => store.products)

  const [focusedField, setFocusedField] = useState()
  const initialValues = {
    unitPrice: "",
    costPrice: "",
    sellingPrice: "",
  }

  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object()
  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      //   const data = {
      //     id,
      //     send: {
      //       selling_price: values.sellingPrice,
      //       unit_price: values.unitPrice,
      //       cost_price: values.costPrice,
      //     },
      //     details_type: "update-priceDetail",
      //   }
      //   const response = await dispatch(updateBasicDetails(data))
      //   if (response.payload) {
      //     setActiveInputs(false)
      //   }
    },
  })

  const handleCancel = () => {
    if (Object.keys(formik.errors)?.length === 0) {
      setActiveInputs(false)
    }
  }

  useEffect(() => {
    formik.setValues({
      costPrice: priceDetails?.costPrice
        ? numberWithCommas(priceValidator(priceDetails?.costPrice))
        : "",
      sellingPrice: priceDetails?.sellingPrice
        ? numberWithCommas(priceValidator(priceDetails?.sellingPrice))
        : "",
      unitPrice: priceDetails?.unitPrice
        ? numberWithCommas(priceValidator(priceDetails?.unitPrice))
        : "",
    })
  }, [priceDetails])
  return (
    <>
      <div className='main-box-padding'>
        <div className='modelEx_content singleAdd'>
          <div className='profile_personal_details'>
            <div className='details-controls'>
              <h2>Price Details</h2>
            </div>
          </div>

          <form className='product-detail-form' onSubmit={formik.handleSubmit}>
            <div className='form-content'>
              <div className='form-group form'>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.unitPrice !== "" ||
                      focusedField === "unitPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='unitPrice' className='toggle-label'>
                      Unit Price
                    </label>
                    <div className={formik.values.unitPrice ? "phone_box" : ""}>
                      {
                        <span className='phone_code'>
                          {formik.values.unitPrice ? "₹" : ""}
                        </span>
                      }
                      <input
                        id='unitPrice'
                        name='unitPrice'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.unitPrice}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                          // setPriceFieldDisabler("costPrice")
                        }}
                        maxLength={81}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("unitPrice")}
                        disabled={!activeInputs}
                      />
                    </div>

                    {formik.touched.unitPrice && formik.errors.unitPrice ? (
                      <span className='err'>{formik.errors.unitPrice}</span>
                    ) : null}
                  </div>
                  <div
                    className={`input-field ${
                      formik.values.costPrice !== "" ||
                      focusedField === "costPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='costPrice' className='toggle-label'>
                      Cost Price
                    </label>
                    <div className={formik.values.costPrice ? "phone_box" : ""}>
                      {
                        <span className='phone_code'>
                          {formik.values.costPrice ? "₹" : ""}
                        </span>
                      }{" "}
                      <input
                        id='costPrice'
                        name='costPrice'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.costPrice}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^\d ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        maxLength={81}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("costPrice")}
                        disabled={!activeInputs}
                      />
                    </div>

                    {formik.touched.costPrice && formik.errors.costPrice ? (
                      <span className='err'>{formik.errors.costPrice}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`input-field ${
                    formik.values.sellingPrice !== "" ||
                    focusedField === "sellingPrice"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='sellingPrice' className='toggle-label'>
                    Selling Price
                  </label>
                  <div
                    className={formik.values.sellingPrice ? "phone_box" : ""}
                  >
                    {
                      <span className='phone_code'>
                        {formik.values.sellingPrice ? "₹" : ""}
                      </span>
                    }
                    <input
                      id='sellingPrice'
                      name='sellingPrice'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.sellingPrice}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^\d ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      maxLength={81}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("sellingPrice")}
                      disabled={!activeInputs}
                    />{" "}
                  </div>

                  {formik.touched.sellingPrice && formik.errors.sellingPrice ? (
                    <span className='err'>{formik.errors.sellingPrice}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

// const Comments = ({ commentDetails, id }) => {
//   const { privileges } = useSelector(state => state.auth, shallowEqual)
//   const { profileDetail } = useSelector(store => store.profile)
//   const dispatch = useDispatch()
//   const { productsLoading } = useSelector(store => store.products)

//   const [comments, setComments] = useState(commentDetails)

//   const initialValues = {
//     commentBox: "",
//   }
//   const commentSchema = Yup.object({
//     commentBox: Yup.string()
//       .trim()
//       .required("Comment field is required")
//       .max(700),
//   })
//   const formik = useFormik({
//     initialValues,
//     validationSchema: commentSchema,
//     onSubmit: async (values, { resetForm }) => {
//       const data = {
//         product_id: id,
//         comment: values.commentBox.trim(),
//       }
//       const response = await dispatch(addComment(data))

//       if (response.payload) {
//         resetForm()
//       }
//     },
//   })

//   useEffect(() => {
//     setComments(commentDetails)
//   }, [commentDetails])

//   return (
//     <>
//       <div className='main-box-padding'>
//         <div className='modelEx_content singleAdd'>
//           <div className='profile_personal_details'>
//             <div className='details-controls'>
//               <h2>
//                 {commentDetails?.length}{" "}
//                 {commentDetails?.length === 1 ? "Comment" : "Comments"}
//               </h2>
//             </div>
//           </div>
//           <div className='commentBox'>
//             {commentDetails?.length > 0 ? (
//               comments?.map((item, index) => {
//                 const {
//                   name = "Steve Rogers",
//                   time = "12:56 PM",
//                   comment,
//                   date = "25/5/24",
//                 } = item
//                 return (
//                   <div
//                     key={index}
//                     className='commentBar'
//                     style={
//                       index > 0
//                         ? {
//                             borderTop: "1px solid #8c8c8c",
//                             paddingTop: "1.5rem",
//                           }
//                         : {}
//                     }
//                   >
//                     <div className='commentHeading'>
//                       <div className='commenterName'>
//                         <p>{name.charAt(0)}</p>
//                         <span>{name}</span>
//                       </div>
//                       <span>
//                         {date}, {time}
//                       </span>
//                     </div>
//                     <span className='commentMessage'>{comment}</span>
//                   </div>
//                 )
//               })
//             ) : (
//               <div className='Nodata-txt' style={{ padding: 0 }}>
//                 <img
//                   src={require("../../../assets/images/nodata.svg").default}
//                   alt=''
//                   srcSet=''
//                 />
//                 No Comment
//               </div>
//             )}
//           </div>
//           {Object.values(privileges["production_management"]).includes(
//             "write",
//           ) && (
//             <form
//               className='product-detail-form'
//               onSubmit={formik.handleSubmit}
//             >
//               <div className='form-content'>
//                 <div className='form-group form'>
//                   <div>
//                     <textarea
//                       type='text'
//                       name='commentBox'
//                       className='commentInput'
//                       placeholder='Type Comment Here...'
//                       value={formik.values.commentBox}
//                       onChange={formik.handleChange}
//                       maxLength={701}
//                       onKeyDown={e => {
//                         if (e.key === "Enter") {
//                           formik.handleSubmit()
//                         }
//                       }}
//                     />
//                     {formik.touched.commentBox && formik.errors.commentBox ? (
//                       <span className='err'>{formik.errors.commentBox}</span>
//                     ) : null}
//                   </div>
//                 </div>
//               </div>
//               <button
//                 type='submit'
//                 onClick={formik.handleSubmit}
//                 className='btn-primary'
//                 disabled={productsLoading}
//                 style={productsLoading ? { cursor: "not-allowed" } : {}}
//               >
//                 {productsLoading ? (
//                   <ClipLoader
//                     loading={true}
//                     size={13}
//                     aria-label='Loading Spinner'
//                     data-testid='loader'
//                   />
//                 ) : (
//                   "Submit"
//                 )}
//               </button>{" "}
//               {/* <div className="input-field">

//               {formik.touched.brand && formik.errors.brand ? (
//                 <span className="err">{formik.errors.brand}</span>
//               ) : null}
//             </div> */}
//             </form>
//           )}{" "}
//         </div>
//       </div>
//     </>
//   )
// }

const Comments = ({ commentDetails, id }) => {
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { profileDetail } = useSelector(store => store.profile)
  const dispatch = useDispatch()
  const { productsLoading } = useSelector(store => store.products)

  const [comments, setComments] = useState(commentDetails)

  const initialValues = {
    commentBox: "",
  }
  const commentSchema = Yup.object({
    commentBox: Yup.string()
      .trim()
      .required("Comment field is required")
      .max(700),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: commentSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        product_id: id,
        comment: values.commentBox.trim(),
      }
      const response = await dispatch(addComment(data))

      if (response.payload) {
        dispatch(getInventoryDetails({ id }))
        resetForm()
      }
    },
  })

  useEffect(() => {
    setComments(commentDetails)
  }, [commentDetails])

  return (
    <>
      <div className='main-box-padding'>
        <div className='modelEx_content singleAdd'>
          <div className='profile_personal_details'>
            <div className='details-controls'>
              <h2>
                {commentDetails?.length}{" "}
                {commentDetails?.length === 1 ? "Comment" : "Comments"}
              </h2>
            </div>
          </div>
          <div className='commentBox'>
            {}
            {commentDetails?.length > 0 ? (
              comments?.map((item, index) => {
                const {
                  name = item.fname + " " + item.lname,
                  time = formatTimestamp(item.createdAt),
                  comment,
                  date = formatTimestamp(item.createdAt),
                } = item
                return (
                  <div
                    key={index}
                    className='commentBar'
                    style={
                      index > 0
                        ? {
                            borderTop: "1px solid #8c8c8c",
                            paddingTop: "1.5rem",
                          }
                        : {}
                    }
                  >
                    <div className='commentHeading'>
                      <div className='commenterName'>
                        <p>{name.charAt(0)}</p>
                        <span>{name}</span>
                      </div>
                      <span>{date}</span>
                    </div>
                    <span className='commentMessage'>{comment}</span>
                  </div>
                )
              })
            ) : (
              <div className='Nodata-txt' style={{ padding: 0 }}>
                <img
                  src={require("../../../assets/images/nodata.svg").default}
                  alt=''
                  srcSet=''
                />
                No Comment
              </div>
            )}
          </div>
          {/* {Object.values(privileges["production_management"]).includes(
            "write",
          ) && (
            <form
              className='product-detail-form'
              onSubmit={formik.handleSubmit}
            >
              <div className='form-content'>
                <div className='form-group form'>
                  <div>
                    <textarea
                      type='text'
                      name='commentBox'
                      className='commentInput'
                      placeholder='Type Comment Here...'
                      value={formik.values.commentBox}
                      onChange={formik.handleChange}
                      maxLength={701}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          formik.handleSubmit()
                        }
                      }}
                    />
                    {formik.touched.commentBox && formik.errors.commentBox ? (
                      <span className='err'>{formik.errors.commentBox}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <button
                type='submit'
                onClick={formik.handleSubmit}
                className='btn-primary'
                disabled={productsLoading}
                style={productsLoading ? { cursor: "not-allowed" } : {}}
              >
                {productsLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "Submit"
                )}
              </button>{" "}
            
            </form>
          )} */}
        </div>
      </div>
    </>
  )
}
