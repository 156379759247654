import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import DataNotFound from "../../../components/DatanotFound"
import SkeletonBar from "../../../components/skeletenBar"
import FormButtons from "../../../components/SubmitBtns"
import { focusOnElement } from "../../../helper/helper"
import { getSelfWarehouseAddress, searchCompany } from "../redux/PurchaseApi"
import { isPurchaseToggle, setWarehouseAddress } from "../redux/PurchaseSlice"

const SelectCompanyPopup = ({
  popup,
  setPopup,
  setIsSelectCompany,
  isSelectCompany,
  api,
}) => {
  const dispatch = useDispatch()
  const { purchaseLoading } = useSelector(store => store.purchase)

  // const { tableLoading } = useLoaderDelay({ loadingState: purchaseLoading })
  const { companyAddress, companyDetails } = useSelector(
    store => store.company,
    shallowEqual,
  )

  // const [activeInputs, setActiveInputs] = useState(false);
  // const [gstn, setGstn] = useState(false);
  const [addStyle, setAddStyle] = useState(true)
  const [companyList, setCompanyList] = useState([])
  const [checkbox, setCheckbox] = useState(false)
  const { companyLoading } = useSelector(store => store.bNs)
  const initialValues = {
    name: "",
  }
  const [search, setSearch] = useState("")
  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object({
    name: Yup.string()
      .trim()
      .min(3, "Full Name must be at least 3 characters long")
      .max(80, "Full Name must be less than 80 characters")
      // .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Name is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        name: values.company_name,
      }

      // const response = await dispatch(AddSingleCompany(data))

      // if (response?.payload) {
      //   resetForm()
      //   handleClick()
      // }
    },
  })

  const handleClick = e => {
    
    setAddStyle(!addStyle)
    chatBoxVisibility(e)
    setIsSelectCompany({
      key: "",
      value: false,
    })
    // setShowChat(false);
  }

  function chatBoxVisibility(e) {
    if (popup === 2) {
      const styleTimer = setTimeout(() => {
        setPopup(e)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
    if (isSelectCompany?.showMyAddress) {
      const getSelfWareHouseAddress = async () => {
        const response = await dispatch(
          getSelfWarehouseAddress({ companyId: companyDetails?._id }),
        )

        if (response.payload.status === 200) {
          setCompanyList(response?.payload?.data?.data)
        }
      }

      getSelfWareHouseAddress()
    }
    focusOnElement("searchInput")
  }, [])
  useEffect(() => {
    setCompanyList(companyAddress)
  }, [companyAddress])

  const [searchTimeout, setSearchTimeout] = useState(null)

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    if (search) {
      const timeoutId = setTimeout(async () => {
        const response = await dispatch(
          searchCompany({
            send: {
              requestType:
                isSelectCompany?.key === "supplier"
                  ? "Supplier"
                  : isSelectCompany?.key === "buyer"
                    ? "Buyer"
                    : "",
              search,
            },
          }),
        )
        setCompanyList(response?.payload?.data)
      }, 1000)

      setSearchTimeout(timeoutId)
    } else if (isSelectCompany?.key !== "delivery") setCompanyList([])
  }, [search])

  const companyAddressApi = async ({ id, gst }) => {
    const response = await dispatch(api({ send: { companyId: id }, gst }))

    if (response?.payload?.status === 200) {
      handleClick()
    }
  }

  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>
            {isSelectCompany?.key === "supplier"
              ? "Supplier Details"
              : isSelectCompany?.key === "buyer"
                ? "Billing Address"
                : "Delivery Location"}{" "}
          </h2>
          <button className='exit-btn' onClick={() => handleClick(false)}>
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>

        <div className='modelEx_content singleAdd'>
          <form className='scrollForm' onSubmit={formik.handleSubmit}>
            <div className='form-content'>
              <div
                className='scp-searchbox input-field'
                style={
                  isSelectCompany?.key === "delivery"
                    ? { background: "#f0f0f0" }
                    : {}
                }
              >
                <i className='fa-regular fa-magnifying-glass'></i>{" "}
                <input
                  type='search'
                  id='searchInput'
                  className='table-search-input'
                  placeholder='Search here...'
                  value={search}
                  onChange={e => {
                    setSearch(e.target.value)
                    if (!purchaseLoading && e.target.value) {
                      dispatch(isPurchaseToggle(true))
                    } else if (purchaseLoading && !e.target.value) {
                      dispatch(isPurchaseToggle(false))
                    }
                  }}
                  disabled={isSelectCompany?.key === "delivery"}
                />
              </div>
              <ul className='scp-comp flex flex-direction-column gap-4'>
                {purchaseLoading ? (
                  <SkeletonBar skeletonNumber={3} />
                ) : companyList?.length > 0 ? (
                  companyList?.map((item, index) => {
                    const { name, gst, companyEmail, _id, slug } = item
                    return isSelectCompany?.showMyAddress ? (
                      <li
                        onClick={() => {
                          if (isSelectCompany?.showMyAddress) {
                            dispatch(setWarehouseAddress(item))
                            handleClick()
                          } else {
                            companyAddressApi({ id: _id, gst: gst?.value })
                          }
                        }}
                        key={index}
                      >
                        <div className='li-lf card-address'>
                          {/* <div>Name: {name ? name : slug}</div>
                          <div>GST: {gst?.value}</div>
                          <div>
                            Email:{" "}
                            {companyEmail?.value
                              ? companyEmail?.value === "null"
                                ? "NA"
                                : companyEmail?.value
                              : "NA"}
                          </div> */}
                          <div className='card-address'>
                            <p>{`${item?.address?.line_one}, `}</p>
                            <p>{`${item?.address?.line_two}${item?.address?.line_two ? "," : ""}`}</p>
                            <p>{`${item?.city_detail?.name ? item?.city_detail?.name : item?.cityName} - ${item?.address?.pin_code},`}</p>
                            <p>{`${item?.state_detail?.name ? item?.state_detail?.name : item?.stateName} - ${item?.country_detail?.name ? item?.country_detail?.name : item?.countryName}`}</p>
                          </div>
                        </div>
                        {/* <i className='fa-light fa-pen-to-square'></i> */}
                      </li>
                    ) : (
                      <li
                        onClick={() =>
                          companyAddressApi({ id: _id, gst: gst?.value })
                        }
                        key={index}
                      >
                        <div className='li-lf'>
                          <div>Name: {name ? name : slug}</div>
                          <div>GST: {gst?.value}</div>
                          <div>
                            Email:{" "}
                            {companyEmail?.value
                              ? companyEmail?.value === "null"
                                ? "NA"
                                : companyEmail?.value
                              : "NA"}
                          </div>
                        </div>
                        {/* <i className='fa-light fa-pen-to-square'></i> */}
                      </li>
                    )
                  })
                ) : (
                  <div
                    style={{
                      height: "20rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DataNotFound />
                  </div>
                )}
              </ul>

              {isSelectCompany?.showDeliveryAddressBtn && (
                <div
                  className='add-scp'
                  onClick={() => {
                    setIsSelectCompany({ key: "", value: false })
                    handleClick(1)
                  }}
                >
                  <i className='fa-regular fa-plus'></i>
                  <span>
                    {isSelectCompany?.key === "supplier"
                      ? "Add New Supplier Detail"
                      : isSelectCompany?.key === "buyer"
                        ? "Add New Billing Address"
                        : "Add New Delivery Location"}
                  </span>
                </div>
              )}
            </div>
            <div className='footer flex-center'>
              {/* <button
                type='submit'
                disabled={companyLoading}
                className='btn-primary'
                // disabled={profileLoading}
                style={companyLoading ? { cursor: "not-allowed" } : {}}
              >
                {companyLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "Save"
                )}
              </button> */}

              <FormButtons
                loading={companyLoading}
                removeCancel
                btnContainerStyles={{ justifyContent: "center" }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default SelectCompanyPopup

// import React, { useState } from "react"
// import SearchBox from "../../../components/SearchBox"

// const SelectCompanyPopup = ({
//   setIsSelectCompany,
//   isSelectCompany,
//   setPopup,
// }) => {
//   const [search, setSearch] = useState("")

//   return (
//     <div className='scp-main'>
//       <div className='scp-pu'>
//         <i
//           className='fa-sharp fa-regular fa-circle-xmark closeIcon'
//           onClick={() => setIsSelectCompany({ key: "", value: false })}
//         ></i>
//         <div className='scp-header'>
//           {isSelectCompany?.key === "supplier"
//             ? "Supplier Details"
//             : isSelectCompany?.key === "buyer"
//               ? "Billing Address"
//               : "Delivery Location"}
//         </div>
//         <div className='scp-searchbox'>
//           <i className='fa-regular fa-magnifying-glass'></i>{" "}
//           <input
//             type='search'
//             className='table-search-input'
//             placeholder='Search here...'
//             value={search}
//             onChange={e => setSearch(e.target.value)}
//           />
//         </div>
//         <ul className='scp-comp'>
//           <li>
//             <div className='li-lf'>
//               <div>Company Abc</div>
//               <div>
//                 A-465, Lorem Ipsum Avenue, Lorem Ipsum Nagar, Nr. Lorem Ipsum,
//                 Lorem Ipsum, Lorem Ipsum.
//               </div>
//             </div>
//             <i className='fa-light fa-pen-to-square'></i>
//           </li>
//           <li>
//             <div className='li-lf'>
//               <div>Company Abc</div>
//               <div>
//                 A-465, Lorem Ipsum Avenue, Lorem Ipsum Nagar, Nr. Lorem Ipsum,
//                 Lorem Ipsum, Lorem Ipsum.
//               </div>
//             </div>
//             <i className='fa-light fa-pen-to-square'></i>
//           </li>
//         </ul>

//         <div
//           className='add-scp'
//           onClick={() => {
//             setIsSelectCompany({ key: "", value: false })
//             setPopup(1)
//           }}
//         >
//           <i className='fa-regular fa-plus'></i>
//           <span>
//             {isSelectCompany?.key === "supplier"
//               ? "Add New Supplier Detail"
//               : isSelectCompany?.key === "buyer"
//                 ? "Add New Billing Address"
//                 : "Add New Delivery Location"}
//           </span>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default SelectCompanyPopup
