import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
import { createOrderFormData } from "../../../helper/helper"
import AddSingleCom from "../../buyers&suppliers/components/AddSingleCom"
import AdditionalCharges from "../components/AdditionalCharges"
import AddressDetails from "../components/AddressDetails"
import PriceDetails from "../components/PriceDetails"
import PrimaryDetails from "../components/PrimaryDetails"
import PurchaseProductsdetail from "../components/PurchaseProductsdetail"
import SelectCompanyPopup from "../components/SelectCompanyPopup"
import {
  createOrder,
  getCompanyAddress,
  getPurchaseOrderDetails,
} from "../redux/PurchaseApi"
import { setWarehouseAddress } from "../redux/PurchaseSlice"

const PurchaseDetails = ({ setTitle }) => {
  const [active, setActive] = useState("purchaseDetails")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [grandTotals, setGrandTotals] = useState({
    taxes: 0,
    subtotal: 0,
    grandTotal: 0,
    advanceToPay: 0,
    reservedSubtotal: 0,
  })

  const dispatch = useDispatch()
  const { id } = useParams()
  const [isEventCard, setIsEventCard] = useState(false)
  const [popUpData, setPopupData] = useState({})
  // const [isSelectCompany, setIsSelectCompany] = useState(false)
  const navigate = useNavigate()
  const [popup, setPopup] = useState(0)

  const [isSelectCompany, setIsSelectCompany] = useState({
    key: "",
    value: false,
  })
  const [allFormDataCollection, setAllFormDataCollection] = useState({
    primaryDetails: true,
    productDetails: true,
    additionalCharges: true,
    subTotal: true,
    addressDetails: true,
  })

  const [purchaseApiDetails, setPurchaseApiDetails] = useState()
  const location = useLocation()

  const { orderNumber, purchaseReturnResponse } = location?.state
    ? location?.state
    : {}

  const IspurchaseOrder =
    location?.pathname === "/purchase/purchase-order" ? true : false

  // const { emailDetails } = useSelector((store) => store.email);
  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  const linkRef = useRef()
  useEffect(() => {
    setTitle(
      IspurchaseOrder ? "Purchase Order Details" : "Purchase Return Details",
    )
  }, [setTitle])

  const { purchaseLoading } = useSelector(store => store.purchase)

  const deleteFunction = title => {
    setPopupData({
      data: {
        is_delete: true,
        product_ids: [id],
      },
      title,
    })
    setIsEventCard(true)
  }

  const checkLength = obj => {
    if (typeof obj === "object") {
      return Object.keys(obj).length > 0
    } else {
      // toast.error()
      return false
    }
  }
  let [showError, setShowError] = useState()
  useEffect(() => {
    // if (
    //   checkLength(allFormDataCollection?.additionalCharges) &&
    //   checkLength(allFormDataCollection?.addressDetails) &&
    //   checkLength(allFormDataCollection?.primaryDetails) &&
    //   checkLength(allFormDataCollection?.productDetails) &&
    //   checkLength(allFormDataCollection?.subTotal)
    // ) {
    //   navigate("/purchase/purchase-order/preview-purchase")
    // }
    if (
      checkLength(allFormDataCollection?.primaryDetails) &&
      checkLength(allFormDataCollection?.addressDetails) &&
      checkLength(allFormDataCollection?.addressDetails?.buyer) &&
      checkLength(allFormDataCollection?.addressDetails?.delivery) &&
      checkLength(allFormDataCollection?.addressDetails?.supplier) &&
      checkLength(allFormDataCollection?.productDetails)
      // checkLength(allFormDataCollection?.additionalCharges) &&
    ) {
      if (allFormDataCollection?.navigate) {
        navigate(`${location?.pathname}/preview`, {
          state: {
            key: IspurchaseOrder ? "purchaseOrder" : "purchaseReturn",
            data: {
              ...allFormDataCollection,
              grandTotals,
              orderNumber: purchaseApiDetails?.orderNumber,
            },
          },
        })
      } else {
        handleSaveAndSend({ status: "draft" })
      }
      // navigate("/purchase/purchase-order/preview-purchase")
    }
    //  else {
    //   if (showError) {
    //     clearTimeout(showError)
    //   }
    //   const errorId = setTimeout(() => {
    //     allFormDataCollection?.navigate !== undefined &&
    //       toast.error("Please fill all the details")
    //   }, 100)
    //   setShowError(errorId)
    // }
  }, [allFormDataCollection])

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  // const companyAddressApi = async id => {
  //   const response = await dispatch(getCompanyAddress({ companyId: id }))
  //
  // }
  const handleSaveAndSend = async ({ status }) => {
    const formData = await createOrderFormData(
      {
        ...allFormDataCollection,
        grandTotals,
      },
      status,
      undefined,
      purchaseApiDetails?.orderNumber,
    )

    const response = await dispatch(createOrder(formData))

    if (response?.payload?.status === 200) {
      navigate("/purchase")
    }
  }

  useEffect(() => {
    if (orderNumber) {
      ;(async function purchaseDetailAPI() {
        let response
        if (purchaseReturnResponse) {
          response = purchaseReturnResponse
        } else {
          response = await dispatch(
            getPurchaseOrderDetails({
              orderNumber,
              invoiceType: IspurchaseOrder
                ? "purchase order invoice"
                : "purchase return order invoice",
            }),
          )
        }

        dispatch(
          getCompanyAddress({
            send: {
              companyId: response.payload?.data?.supplierDetails?.companyId,
            },
          }),
        )
        dispatch(setWarehouseAddress(response?.payload?.data?.shippingDetails))
        setPurchaseApiDetails(response?.payload?.data)

        // setGrandTotals({
        //   taxes: 0,
        //   subtotal: 0,
        //   grandTotal: 0,
        //   advanceToPay: 0,
        //   reservedSubtotal: 0,
        // })
        setGrandTotals({
          taxes: response.payload?.data?.taxes
            ? response.payload?.data?.taxes
            : 0,
          subtotal: response.payload?.data?.subtotal
            ? response.payload?.data?.subtotal
            : 0,
          grandTotal: response.payload?.data?.grandTotal
            ? response.payload?.data?.grandTotal
            : 0,
          advanceToPay: response.payload?.data?.advanceToPay
            ? response.payload?.data?.advanceToPay
            : 0,
          reservedSubtotal: response.payload?.data?.subtotal
            ? response.payload?.data?.subtotal
            : 0,
        })
      })()
    }
  }, [])

  // useEffect(() => {
  //   dispatch(
  //     getCompanyAddress({ send: { companyId: "66b76299575522b9fc3f4731" } }),
  //   )
  // }, [])

  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        {popup === 2 && (
          <SelectCompanyPopup
            setIsSelectCompany={setIsSelectCompany}
            isSelectCompany={isSelectCompany}
            setPopup={setPopup}
            popup={popup}
            api={getCompanyAddress}
          />
        )}
        {popup === 1 && <AddSingleCom popup={popup} setPopup={setPopup} />}
        <div className='home-container'>
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>
                    {IspurchaseOrder
                      ? "Purchase Order #PO-12345"
                      : "Purchase Return #PO-12345"}
                  </div>
                  <div className='mch-path'>
                    <Breadcrumb
                      id
                      title={
                        IspurchaseOrder ? "Purchase Order" : "Purchase Return"
                      }
                    />
                  </div>
                </div>
              </div>
              <AddressDetails
                setIsSelectCompany={setIsSelectCompany}
                formData={allFormDataCollection}
                setFormData={setAllFormDataCollection}
                setPopup={setPopup}
                purchaseApiDetails={purchaseApiDetails}
              />

              <PrimaryDetails
                formData={allFormDataCollection}
                setFormData={setAllFormDataCollection}
                purchaseApiDetails={purchaseApiDetails}
              />

              <PurchaseProductsdetail
                formData={allFormDataCollection}
                setFormData={setAllFormDataCollection}
                setGrandTotals={setGrandTotals}
                grandTotals={grandTotals}
                purchaseApiDetails={purchaseApiDetails?.items}
              />
              <AdditionalCharges
                formData={allFormDataCollection}
                setFormData={setAllFormDataCollection}
                IspurchaseOrder={IspurchaseOrder}
                setGrandTotals={setGrandTotals}
                grandTotals={grandTotals}
                purchaseApiDetails={{
                  extraCharge: purchaseApiDetails?.extraCharge,
                  comment: purchaseApiDetails?.comment,
                  signature: purchaseApiDetails?.signature,
                }}
              />

              <PriceDetails
                grandTotals={grandTotals}
                setGrandTotals={setGrandTotals}
              />

              <div className='table-search'>
                <div
                  className='search-container'
                  style={{ justifyContent: "flex-end" }}
                >
                  {Object.values(privileges["purchase_management"]).includes(
                    "write",
                  ) && (
                    <>
                      <button className='btn-secondary header-btn'>
                        Cancel
                      </button>
                      {IspurchaseOrder && (
                        <button
                          className='btn-alpha header-btn bg-none'
                          onClick={() => {
                            setAllFormDataCollection({
                              primaryDetails: false,
                              productDetails: false,
                              additionalCharges: false,
                              subTotal: false,
                              addressDetails: false,
                              navigate: false,
                            })
                          }}
                        >
                          Save Draft
                        </button>
                      )}
                      {/* <Link
                        style={{ display: "none" }}
                        to={`${location?.pathname}/preview`}
                        state={{
                          key: IspurchaseOrder
                            ? "purchaseOrder"
                            : "purchaseReturn",
                          data: allFormDataCollection,
                        }}
                        ref={linkRef}
                      ></Link> */}
                      <button
                        className='btn-alpha header-btn  '
                        onClick={() => {
                          setAllFormDataCollection({
                            primaryDetails: false,
                            productDetails: false,
                            additionalCharges: false,
                            subTotal: false,
                            addressDetails: false,
                            navigate: true,
                          })
                        }}
                      >
                        Preview
                      </button>
                    </>
                  )}{" "}
                </div>
              </div>
            </div>
            <Footer />{" "}
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              //   api={deleteProducts}
              popUpData={popUpData}
              // reFetchApi={getProductsList}
              navigateTo='/products'
              // reData={{ search: "", sort:"desc", limit: 5, page: 0 }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PurchaseDetails
