/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import { ClipLoader } from "react-spinners"

const DropDownBox = ({
  dropDownTitle,
  options,
  defaultValue,
  size,
  showSearchBar,
  customSetter,
  customFormikLabel,
  customDropBoxStyles,
  disabled, // ? boolean false will disable the dropdown and true enable it
  customTextStyle,
  animateDropDownTitle,
  animateDropDownTitleStyle, //? provide the object with 2 object {labelDown:{style},labelUp{style}}
  incomingValue, // ? provide incoming string value which does not change often
  dynamicIncomingValue, // ! DEPRECATED ? it's similar to incomingValue but it's changes very often
  resetButton, // ? boolean or string to show reset btn text
  callCustomFunction, // ? formik.setValue
  customValueForCustomFunction, // ? value for formik.setValue
  listApi,
  apiData = {}, //? provide object or any data that you want to send with the request
  dispatch,
  loading, //? to show loading animation in the place of dropdown while some action is taking place
  listOfKeyValue, //? provide a object containing labelKey and valueKey ex-> {labelKey:"productName",valueKey:"productId"}
  resetState,
  resetSetter,
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const [addStyle, setAddStyle] = useState(false)
  const [menuOptions, setMenuOptions] = useState(options)
  const [dropDownLabel, setDropDownLabel] = useState(defaultValue)
  const [dropDownValueTwo, setDropDownValueTwo] = useState("")
  const [historyIncomingValue, setHistoryIncomingValue] = useState("")
  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
  }

  function chatBoxVisibility() {
    if (showMenu) {
      const styleTimer = setTimeout(() => {
        setShowMenu(false)
        clearTimeout(styleTimer)
      }, 200)
      return () => clearTimeout(styleTimer)
    } else {
      setShowMenu(true)
    }
  }

  // async function apiCall() {
  //   const response = await dispatch(listApi(apiData)) // ? return array containing value
  //
  //   if (options.length === 0) {
  //     const options = response?.payload?.map(item => {
  //       return { label: item.name, value: item._id }
  //     })

  //     // if (menuOptions.length === 0) {
  //     //   setMenuOptions(options)
  //     //   setCheck(false)
  //     // }
  //   }
  // }

  useEffect(() => {
    if (dropDownValueTwo || dropDownLabel === "All") {
      if (callCustomFunction) {
        callCustomFunction(dropDownValueTwo, customValueForCustomFunction)
      } else if (customFormikLabel) {
        customSetter(customFormikLabel, dropDownValueTwo)
      } else {
        customSetter(dropDownValueTwo)
      }
      if (dropDownLabel === "All") {
        setDropDownLabel(defaultValue ? defaultValue : "")
      }
    }
  }, [dropDownValueTwo])

  useEffect(() => {
    if (
      (historyIncomingValue && historyIncomingValue !== incomingValue) ||
      (!dropDownValueTwo && incomingValue)
    ) {
      options?.forEach(item => {
        const { label, value } = item
        if (value === incomingValue) {
          setHistoryIncomingValue(value)
          setDropDownValueTwo(value)
          setDropDownLabel(label)
        }
      })
    }

    // ! IN PROGRESS CODE FOR INCOMING VALUES

    //  const { label, value } = options.find(
    //     item => item.value === incomingValue,
    //   )
    //   setHistoryIncomingValue(value)
    //   setDropDownValueTwo(value)
    //   setDropDownLabel(label)

    // ! IN PROGRESS CODE FOR INCOMING VALUES

    // else if (
    //   dynamicIncomingValue &&
    //   dynamicIncomingValue !== dropDownValueTwo
    // ) {
    //   options?.forEach(item => {
    //     const { label, value } = item
    //     if (value === dynamicIncomingValue) {
    //       setDropDownValueTwo(value)
    //       setDropDownLabel(label)
    //     }
    //   })
    // }
  }, [options, incomingValue])

  useEffect(() => {
    let shakerTimer

    if (resetState) {
      setDropDownLabel(defaultValue)
      setHistoryIncomingValue("")
      setDropDownValueTwo("")
      setDropDownLabel("All")
      shakerTimer = setTimeout(function () {
        resetSetter(false)
      }, 1000)
    }

    return () => clearTimeout(shakerTimer)
  }, [resetState])

  // useEffect(() => {
  //   if (resetState === "") {
  //     resetSetter(false)
  //   }
  // }, [resetState])

  // ! i have commented this code due to incoming value bug
  // useEffect(() => {
  //   setDropDownLabel(defaultValue)
  // }, [defaultValue])

  // useEffect(() => {
  //   if (options.length === 0 && dispatch && listApi) {
  //     apiCall()
  //   }
  // }, [menuOptions])

  return (
    <div
      className={`container_for_dropDown ${resetState ? " error-shake-animation" : ""}`}
    >
      <div
        className={
          "drop-down-main" +
          (size === "small"
            ? " drop-down-main-small"
            : size === "medium"
              ? " drop-down-main-medium"
              : size === "mini"
                ? " drop-down-main-mini"
                : " drop-down-main-large")
        }
      >
        {dropDownTitle && (
          <div
            className={
              "drop-down-title" +
              (animateDropDownTitle
                ? dropDownValueTwo || showMenu
                  ? " animateDropDownLabel animateDropDownLabelUp"
                  : " animateDropDownLabel"
                : "")
            }
            style={
              animateDropDownTitle
                ? dropDownValueTwo || showMenu
                  ? { ...animateDropDownTitleStyle?.labelUp, padding: "0" }
                  : { ...animateDropDownTitleStyle?.labelDown, padding: "0" }
                : {}
            }
          >
            <span>{dropDownTitle ? dropDownTitle : ""}</span>
          </div>
        )}

        <div
          className={
            "drop-down-selector" +
            (!disabled && disabled !== undefined
              ? ""
              : menuOptions?.length > 4 ||
                  (showSearchBar && menuOptions?.length > 3)
                ? " show-drop-scroll"
                : " hide-drop-scroll")
          }
        >
          <div
            className={
              (!disabled && disabled !== undefined
                ? " direct disabledDropBox"
                : "direct") + (animateDropDownTitle ? " fixedHeight" : " ")
            }
            // className={`direct ${
            //   !disabled && disabled !== undefined
            //     ? "disabledDropBox"
            //     : "" + (animateDropDownTitle ? " fixedHeight" : " ")
            // }`}

            onClick={() => {
              if (disabled || disabled === undefined) {
                handleClick()
              }
            }}
            style={customDropBoxStyles ? customDropBoxStyles : {}}
          >
            <div
              className='default_value'
              style={
                customTextStyle && dropDownLabel === defaultValue
                  ? customTextStyle
                  : {}
              }
            >
              {dropDownLabel}
            </div>
            <span
              className={`material-symbols-outlined drop-arrow ${
                !disabled && disabled !== undefined
                  ? "disableDropDown"
                  : addStyle
                    ? "up-arrow"
                    : ""
              }`}
              style={
                customTextStyle && dropDownLabel === defaultValue
                  ? customTextStyle
                  : {}
              }
            >
              south
            </span>
          </div>
          {showMenu && (
            <DropDownMenu
              disabled={disabled}
              addStyle={addStyle}
              showSearchBar={showSearchBar}
              dropDownValueTwo={dropDownValueTwo}
              resetButton={resetButton}
              menuOptions={menuOptions}
              setDropDownLabel={setDropDownLabel}
              setDropDownValueTwo={setDropDownValueTwo}
              options={options}
              setMenuOptions={setMenuOptions}
              showMenu={showMenu}
              handleClick={handleClick}
              dispatch={dispatch}
              listApi={listApi}
              loading={loading}
              customTextStyle={customTextStyle}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DropDownBox

const DropDownMenu = ({
  options,
  disabled,
  addStyle,
  showSearchBar,
  dropDownValueTwo,
  resetButton,
  menuOptions,
  setDropDownLabel,
  setDropDownValueTwo,
  setMenuOptions,
  showMenu,
  handleClick,
  listApi,
  dispatch,
  loading,
  customTextStyle,
}) => {
  const [search, setSearch] = useState("")

  const handleSearch = e => {
    // setSearch(e.target.value.trim())
    // formik.setFieldValue("search", e.target.value.toLowerCase())
    setSearch(e.target.value)
  }

  useEffect(() => {
    if (showSearchBar) {
      const arr = []
      options?.forEach(item => {
        if (
          item.label &&
          item.label.toLowerCase().includes(search.trim().toLowerCase())
        ) {
          arr.push(item)
        }
      })

      setMenuOptions(arr)
    }
  }, [search])

  const [globalClick, setGlobalClick] = useState(false)
  const menuRef = useRef()

  useEffect(() => {
    const handleGlobalClick = event => {
      if (menuRef?.current && !menuRef?.current?.contains(event.target)) {
        handleClick()
      }
    }

    document.addEventListener("click", handleGlobalClick)
    return () => {
      document.removeEventListener("click", handleGlobalClick)
    }
  }, [menuRef])

  useEffect(() => {
    if (showMenu) {
      setGlobalClick(true)
    }
  }, [])

  useEffect(() => {
    if (options) {
      setMenuOptions(options)
    }
  }, [options])

  return (
    <>
      {!disabled && disabled !== undefined ? (
        ""
      ) : showMenu ? (
        <div
          className={
            addStyle ? "drop-down-menu" : "drop-down-menu hide_drop-down-menu"
          }
          ref={showMenu && globalClick ? menuRef : null}
        >
          {showSearchBar ? (
            <div className='drop-down-search-bar'>
              <input
                type='text'
                placeholder='search here...'
                name='search'
                value={search}
                onChange={handleSearch}
              />
            </div>
          ) : null}

          {resetButton && dropDownValueTwo && !search ? (
            <div
              className='drop-down-item'
              onClick={() => {
                setDropDownLabel("All")
                setDropDownValueTwo("")
                handleClick()
              }}
            >
              <span>
                {typeof resetButton === "string" ? resetButton : "All"}
              </span>
            </div>
          ) : null}

          {menuOptions?.length > 0 ? (
            menuOptions?.map(({ label, value }, index) => (
              <div
                key={index}
                className={
                  "drop-down-item" +
                  (dropDownValueTwo === value ? " selectedDropBox" : "")
                }
                onClick={() => {
                  setDropDownLabel(label)
                  setDropDownValueTwo(value)
                  handleClick()
                }}
              >
                <span>{label}</span>
              </div>
            ))
          ) : (
            <div className='drop-down-item'>
              {loading ? (
                // <ClipLoader
                //   loading={true}
                //   size={13}
                //   aria-label='Loading Spinner'
                //   data-testid='loader'
                // />
                <div className='custom_spinner'></div>
              ) : (
                <span>No Data Found</span>
              )}
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}
