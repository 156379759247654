import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Error = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Page Not Found")
  }, [setTitle])

  const navigate = useNavigate()
  return (
    <div className='container'>
      <div className='app-container'>
        <div className='error-container'>
          <div className='error-content'>
            <div className='error-bg'>
              <img src={require("../../assets/images/error.webp")} alt='' />
            </div>
            <div className='err-title'>404</div>
            <div className='err-description'>
              The page you are attempting to reach is currently not available.
              This may be because the page does not exist or has been moved.
            </div>

            <button className='back-btn' onClick={() => navigate("/")}>
              Back Home
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
