import { useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ClipLoader } from "react-spinners"
import * as Yup from "yup"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import DropDownBox from "../../../components/DropDownBox"
import EditFormButtons from "../../../components/EditFormBtns"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import SearchDropDown from "../../../components/SearchDropDown"
import Sidebar from "../../../components/Sidebar"
import {
  capitalizeInput,
  convertNumberToRupees,
  convertRupeesToNumber,
  errorShakeAnimation,
  findValueFromPercentage,
  formatTimestamp,
  numberWithCommas,
  priceValidator,
} from "../../../helper/helper"
import {
  addComment,
  deleteProducts,
  getBasePaint,
  getHsnList,
  getOptionsList,
  getProductsDetails,
  updateBasicDetails,
} from "../redux/ProductsApi"
import FormButtons from "../../../components/SubmitBtns"
import { toast } from "react-toastify"

const ProductDetails = ({ setTitle }) => {
  const [active, setActive] = useState("product")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  const [activeInputs, setActiveInputs] = useState(false)
  const [isEventCard, setIsEventCard] = useState(false)
  const [popUpData, setPopupData] = useState({})
  const [reset, setReset] = useState(false)
  const [categoryReset, setCategoryReset] = useState(false)
  const [subCategoryReset, setSubCategoryReset] = useState(false)
  const [subChildCategoryReset, setChildCategoryReset] = useState(false)
  const [catMargin, setCatMargin] = useState("")
  // const { emailDetails } = useSelector((store) => store.email);
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { productDetails } = useSelector(state => state.products, shallowEqual)

  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  useEffect(() => {
    setTitle("Product Details")
  }, [setTitle])

  // useEffect(() => {
  //   if (id) {
  //     setTitle("Edit Email");
  //     formik.setFieldValue("subject", emailDetails[0]?.subject);
  //     formik.setFieldValue("title", emailDetails[0]?.title);
  //     formik.setFieldValue("email_text", emailDetails[0]?.email_text);
  //   }
  // }, [id, setTitle]);

  const [focusedField, setFocusedField] = useState()
  const { productsLoading } = useSelector(store => store.products)
  const initialValues = {
    productName: "",
    skuNum: "233",
    brand: "",
    category: "",
    color: "",
    packSize: "",
    uom: "",
    productCode: "",
    // batchNumber: "",
    hsn: "",
    type: "",
    quantity: "",
    quantityUom: "",
    subCategory: "",
    subChildCategory: "",
  }

  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object({
    skuNum: Yup.string().required("SKU number is required"),
    productName: Yup.string()
      .trim()
      .min(2, "Paint name must be at least 2 characters long")
      .max(80, "Name must be less than 80 characters")
      .required("Paint name is required"),
    brand: Yup.string().required("Brand name is required"),
    category: Yup.string().required("Category name is required"),
    subCategory: Yup.string(),
    subChildCategory: Yup.string(),
    color: Yup.string(),
    // .required("Color name is required"),
    packSize: Yup.string().required("Pack size is required"),
    uom: Yup.string().required("UOM is required"),
    productCode: Yup.string().min(1).max(12),
    // .required("Product code is required"),
    // batchNumber: Yup.string().min(1).max(17),
    // .required("Batch number is required"),
    hsn: Yup.string().required("HSN code is required"),
    quantity: Yup.string()
      .required("Pack quantity is required")
      .test("quantity", "Quantity can not be greater than Pack Size", value => {
        if (Number(value) > Number(formik.values.packSize)) {
          return false
        } else {
          return true
        }
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        id,
        send: {
          brand_id: values?.brand,
          category_id: values?.category,
          color_id: values?.color,
          product_weight: values?.packSize,
          uom_id: values?.uom,
          name: values?.productName,
          product_code: values?.productCode,
          // batch_number: values?.batchNumber,
          type: values.type,
          hsn: values.hsn,
          quantity: values.quantity,
          subCategory_id: values?.subCategory,
          subChildCategory_id: values?.subChildCategory
            ? values?.subChildCategory
            : "",
        },
        details_type: "update-basicDetail",
      }
      const response = await dispatch(updateBasicDetails(data))
      if (response.payload) {
        setActiveInputs(false)
      }
    },
  })

  const deleteFunction = title => {
    setPopupData({
      data: {
        is_delete: true,
        product_ids: [id],
      },
      title,
    })
    setIsEventCard(true)
  }
  const handleCancel = () => {
    if (Object.keys(formik.errors)?.length === 0) {
      setActiveInputs(false)
      formik.setValues({
        skuNum: productDetails?.basic_details?.sku,
        productName: productDetails?.basic_details?.name,
        brand: productDetails?.basic_details?.brandId,
        color: productDetails?.basic_details?.colorId,
        category: productDetails?.basic_details?.categoryId,
        packSize: `${productDetails?.basic_details?.pack_size?.weight}`,
        uom: productDetails?.basic_details?.pack_size?.uomID,
        productCode: productDetails?.basic_details?.productCode,
        hsn: productDetails?.basic_details?.hsn,
        type: productDetails?.basic_details?.productStandard,
        quantity: productDetails?.basic_details?.pack_size?.productQuantity,
        subCategory: productDetails?.basic_details?.subcategoryId,
        subChildCategory: productDetails?.basic_details?.subchildategoryId,
      })

      setReset(true)
      setCategoryReset(true)
      setSubCategoryReset(true)
      setChildCategoryReset(true)
    }
  }
  const [uomList, setUomList] = useState([])

  const apiCall = async () => {
    {
      const response = await dispatch(
        getOptionsList({
          listName: "uom-list",
          search: "",
          sort: "desc",
          limit: "0",
          page: "0",
        }),
      )

      const options = response?.payload?.map(item => {
        return { label: item.uom_type, value: item._id }
      })

      // const options = response?.payload?.filter(
      //   item => item._id === productDetails?.basic_details?.pack_size?.uomID,
      // )

      // setUomList([{ label: options[0]?.uom_type, value: options[0]?._id }])
      setUomList(options)
    }
  }

  // useEffect(() => {
  //   dispatch(getProductsDetails({ id }))
  //   if (uomList.length === 0) apiCall()
  // }, [])

  useEffect(() => {
    dispatch(getProductsDetails({ id })).then(() => {
      apiCall()
    })
    // .then(async () => {
    //   const response = await dispatch(
    //     getOptionsList({
    //       listName: "category-list",
    //       search: productDetails?.basic_details?.category,
    //       sort: "desc",
    //       limit: "0",
    //       page: "0",
    //       send: {
    //         brandId: productDetails?.basic_details?.brandId,
    //       },
    //     }),
    //   )
    //   setCatMargin(response?.payload[0]?.margin)
    //    
    //     response?.payload.find(
    //       item => item._id === productDetails?.basic_details?.categoryId,
    //     ).margin,
    //   )
    // })
  }, [])

  useEffect(() => {
    formik.setValues({
      skuNum: productDetails?.basic_details?.sku
        ? productDetails?.basic_details?.sku
        : "",
      productName: productDetails?.basic_details?.name
        ? productDetails?.basic_details?.name
        : "",
      brand: productDetails?.basic_details?.brandId
        ? productDetails?.basic_details?.brandId
        : "",
      color: productDetails?.basic_details?.colorId
        ? productDetails?.basic_details?.colorId
        : "",
      category: productDetails?.basic_details?.categoryId
        ? productDetails?.basic_details?.categoryId
        : "",
      packSize: productDetails?.basic_details?.pack_size?.weight
        ? `${productDetails?.basic_details?.pack_size?.weight}`
        : "",
      uom: productDetails?.basic_details?.pack_size?.uomID
        ? productDetails?.basic_details?.pack_size?.uomID
        : "",
      productCode: productDetails?.basic_details?.productCode
        ? productDetails?.basic_details?.productCode
        : "",
      hsn: productDetails?.basic_details?.hsn_id
        ? productDetails?.basic_details?.hsn_id
        : "",
      quantity: productDetails?.basic_details?.pack_size?.productQuantity
        ? productDetails?.basic_details?.pack_size?.productQuantity
        : "",
      type: productDetails?.basic_details?.productStandard
        ? productDetails?.basic_details?.productStandard
        : "",
      subCategory: productDetails?.basic_details?.subcategoryId
        ? productDetails?.basic_details?.subcategoryId
        : "",
      subChildCategory: productDetails?.basic_details?.subchildategoryId
        ? productDetails?.basic_details?.subchildategoryId
        : "",
    })
  }, [productDetails])

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Product Details</div>
                  <div className='mch-path'>
                    <Breadcrumb id title={"Product Details"} />
                  </div>
                </div>

                <div className='table-search'>
                  <div className='search-container'>
                    {Object.values(
                      privileges["production_management"],
                    ).includes("delete") && (
                      <button
                        className='btn-secondary header-btn delete-primary-button'
                        onClick={() =>
                          deleteFunction(productDetails?.basic_details?.name)
                        }
                      >
                        <span className='material-symbols-outlined medium'>
                          delete
                        </span>{" "}
                        Delete
                      </button>
                    )}{" "}
                  </div>
                </div>
              </div>
              <div className='main-box-padding'>
                <div className='modelEx_content singleAdd'>
                  <form
                    className='product-detail-form'
                    onSubmit={formik.handleSubmit}
                  >
                    <div className='profile_personal_details'>
                      <div className='details-controls'>
                        <h2>Basic Details</h2>
                        {Object.values(
                          privileges["production_management"],
                        ).includes("write") && (
                          // <div className='detail-btn'>
                          //   {activeInputs ? (
                          //     <>
                          //       <button
                          //         type='submit'
                          //         onClick={handleCancel}
                          //         className='btn-secondary'
                          //         disabled={productsLoading}
                          //         style={
                          //           productsLoading
                          //             ? { cursor: "not-allowed" }
                          //             : {}
                          //         }
                          //       >
                          //         {productsLoading ? (
                          //           <ClipLoader
                          //             loading={true}
                          //             size={13}
                          //             aria-label='Loading Spinner'
                          //             data-testid='loader'
                          //           />
                          //         ) : (
                          //           "Cancel"
                          //         )}
                          //       </button>{" "}
                          //       <button
                          //         type='submit'
                          //         onClick={formik.handleSubmit}
                          //         className='btn-primary'
                          //         disabled={productsLoading}
                          //         style={
                          //           productsLoading
                          //             ? { cursor: "not-allowed" }
                          //             : {}
                          //         }
                          //       >
                          //         {productsLoading ? (
                          //           <ClipLoader
                          //             loading={true}
                          //             size={13}
                          //             aria-label='Loading Spinner'
                          //             data-testid='loader'
                          //           />
                          //         ) : (
                          //           "Save"
                          //         )}
                          //       </button>{" "}
                          //     </>
                          //   ) : (
                          //     <button
                          //       onClick={() => setActiveInputs(true)}
                          //       className='btn-secondary'
                          //     >
                          //       <span className='material-symbols-outlined small'>
                          //         border_color
                          //       </span>
                          //       Edit
                          //     </button>
                          //   )}
                          // </div>
                          <EditFormButtons
                            loading={productsLoading}
                            activeInputs={activeInputs}
                            handleSubmit={formik.handleSubmit}
                            handleCancel={handleCancel}
                            handleInputs={setActiveInputs}
                          />
                        )}{" "}
                      </div>
                    </div>
                    <div className='form-content'>
                      <div className='form-group form'>
                        <div className='col-2'>
                          <div
                            className={`input-field ${
                              formik.values.skuNum !== "" ||
                              focusedField === "skuNum"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label htmlFor='email' className='toggle-label'>
                              SKU Number
                            </label>
                            <input
                              id='skuNum'
                              name='skuNum'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.skuNum}
                              // maxLength={80}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("skuNum")}
                              onKeyDown={e => {
                                if (e.key === " ") {
                                  e.preventDefault()
                                }
                              }}
                              disabled={true}
                            />
                            {formik.touched.skuNum && formik.errors.skuNum ? (
                              <span className='err'>
                                {formik.errors.skuNum}
                              </span>
                            ) : null}
                          </div>
                          <div
                            className={`input-field ${
                              formik.values.productName !== "" ||
                              focusedField === "productName"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label
                              htmlFor='productName'
                              className='toggle-label'
                            >
                              Product Name
                            </label>
                            <input
                              id='productName'
                              name='productName'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.productName}
                              onInput={capitalizeInput}
                              maxLength={81}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("productName")}
                              disabled={!activeInputs}
                            />{" "}
                            {formik.touched.productName &&
                            formik.errors.productName ? (
                              <span className='err'>
                                {formik.errors.productName}
                              </span>
                            ) : null}
                          </div>{" "}
                        </div>
                        <div className='col-2'>
                          <div
                            className={`input-field ${
                              formik.values.productCode !== "" ||
                              focusedField === "productCode"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label
                              htmlFor='productCode'
                              className='toggle-label'
                            >
                              Product Code
                            </label>
                            <input
                              id='productCode'
                              name='productCode'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.productCode}
                              // onInput={e => {
                              //   e.target.value = e.target.value.replace(
                              //     /(?:^|\s)\S/g,
                              //     match => match.toUpperCase(),
                              //   )
                              // }}
                              maxLength={12}
                              minLength={1}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("productCode")}
                              disabled={!activeInputs}
                            />
                            {formik.touched.productCode &&
                            formik.errors.productCode ? (
                              <span className='err'>
                                {formik.errors.productCode}
                              </span>
                            ) : null}
                          </div>{" "}
                          {/* <div
                            className={`input-field ${
                              formik.values.batchNumber !== "" ||
                              focusedField === "batchNumber"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label
                              htmlFor='batchNumber'
                              className='toggle-label'
                            >
                              Batch Number
                            </label>
                            <input
                              id='batchNumber'
                              name='batchNumber'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.batchNumber}
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /(?:^|\s)\S/g,
                                  match => match.toUpperCase(),
                                )
                              }}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              maxLength={17}
                              minLength={1}
                              onFocus={() => setFocusedField("batchNumber")}
                              disabled={!activeInputs}
                            />
                            {formik.touched.batchNumber &&
                            formik.errors.batchNumber ? (
                              <span className='err'>
                                {formik.errors.batchNumber}
                              </span>
                            ) : null}
                          </div> */}
                          <div className='input-field'>
                            <DropDownBox
                              options={[
                                {
                                  label: "Standard Product",
                                  value: "Standard",
                                },
                                {
                                  label: "Non Standard Product",
                                  value: "Non-Standard",
                                },
                              ]}
                              customSetter={formik.setFieldValue}
                              dropDownTitle='Product Standard'
                              animateDropDownTitle
                              customFormikLabel={"type"}
                              customDropBoxStyles={{
                                border: "1px solid #d7cece",
                                borderRadius: "0.5rem",
                                color: "#333",
                              }}
                              customTextStyle={{
                                color: "#212229",
                                opacity: "0.7",
                              }}
                              disabled={activeInputs}
                              incomingValue={
                                productDetails?.basic_details?.productStandard
                              }
                            />
                            {/* <SearchDropDown
                      label='Brand'
                      name='brand'
                      api={getOptionsList}
                      listName={"brand-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                            {formik.touched.type && formik.errors.type ? (
                              <span className='err'>{formik.errors.type}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className='col-2'>
                          <div className='input-field'>
                            <SearchDropDown
                              label='Brand'
                              name='brand'
                              api={getOptionsList}
                              listName={"brand-list"}
                              customSetter={formik.setFieldValue}
                              incomingValue={
                                productDetails?.basic_details?.brand
                              }
                              disabled={!activeInputs}
                              customFunctionOnClick={value => {
                                formik.setFieldValue("brand", value)

                                if (formik.values.category) {
                                  setCategoryReset("clear")
                                  errorShakeAnimation({ id: "category" })
                                }
                                if (formik.values.subCategory) {
                                  errorShakeAnimation({ id: "subCategory" })
                                  setSubCategoryReset("clear")
                                }
                                if (formik.values.subChildCategory) {
                                  setChildCategoryReset("clear")
                                  errorShakeAnimation({
                                    id: "subChildCategory",
                                  })
                                }
                              }}
                            />
                            {formik.touched.brand && formik.errors.brand ? (
                              <span className='err'>{formik.errors.brand}</span>
                            ) : null}
                          </div>

                          <div className='input-field'>
                            <SearchDropDown
                              label='Category'
                              name='category'
                              api={getOptionsList}
                              listName={"category-list"}
                              customSetter={formik.setFieldValue}
                              incomingValue={
                                productDetails?.basic_details?.category
                              }
                              disabled={!activeInputs}
                              apiData={{
                                send: { brand_id: formik.values.brand },
                              }}
                              resetState={categoryReset}
                              resetSetter={setCategoryReset}
                              dynamicValue={"all"}
                              customFunctionOnClick={value => {
                                formik.setFieldValue("category", value?._id)
                                setCatMargin(value?.margin)
                                if (formik.values.subCategory) {
                                  setSubCategoryReset("clear")
                                  errorShakeAnimation({ id: "subCategory" })
                                }
                                if (formik.values.subChildCategory) {
                                  setChildCategoryReset("clear")
                                  errorShakeAnimation({
                                    id: "subChildCategory",
                                  })
                                }
                              }}
                            />
                            {formik.touched.category &&
                            formik.errors.category ? (
                              <span className='err'>
                                {formik.errors.category}
                              </span>
                            ) : null}
                          </div>
                        </div>{" "}
                        <div className='col-2'>
                          <div className='input-field'>
                            {/* <DropDownBox
                      options={subCategoryList}
                      customSetter={formik.setFieldValue}
                      dropDownTitle='Sub Category'
                      animateDropDownTitle
                      customFormikLabel={"subCategory"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      showSearchBar
                    /> */}

                            <SearchDropDown
                              label='Sub Category'
                              name='subCategory'
                              api={getOptionsList}
                              listName={"subCategory-list"}
                              customSetter={formik.setFieldValue}
                              disabled={!activeInputs}
                              incomingValue={
                                productDetails?.basic_details?.subcategory
                                  ? productDetails?.basic_details?.subcategory
                                  : " "
                              }
                              resetSetter={setSubCategoryReset}
                              resetState={subCategoryReset}
                              apiData={{
                                send: { category_id: formik.values.category },
                              }}
                              customFunctionOnClick={value => {
                                formik.setFieldValue("subCategory", value)

                                if (formik.values.subChildCategory) {
                                  setChildCategoryReset("clear")
                                  errorShakeAnimation({
                                    id: "subChildCategory",
                                  })
                                }
                              }}
                            />
                            {formik.touched.subCategory &&
                            formik.errors.subCategory ? (
                              <span className='err'>
                                {formik.errors.subCategory}
                              </span>
                            ) : null}
                          </div>

                          <div className='input-field'>
                            {/* <DropDownBox
                      options={subChildCategoryList}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"subChildCategory"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Sub Child Category'
                      animateDropDownTitle
                      showSearchBar
                    /> */}

                            <SearchDropDown
                              label='Sub Child Category'
                              name='subChildCategory'
                              api={getOptionsList}
                              listName={"subChildCategory-list"}
                              customSetter={formik.setFieldValue}
                              disabled={!activeInputs}
                              incomingValue={
                                productDetails?.basic_details?.subchildategory
                                  ? productDetails?.basic_details
                                      ?.subchildategory
                                  : " "
                              }
                              resetSetter={setChildCategoryReset}
                              resetState={subChildCategoryReset}
                              apiData={{
                                send: {
                                  subCategory_id: formik.values.subCategory,
                                },
                              }}
                            />
                            {formik.touched.subChildCategory &&
                            formik.errors.subChildCategory ? (
                              <span className='err'>
                                {formik.errors.subChildCategory}
                              </span>
                            ) : null}
                          </div>
                        </div>{" "}
                        {/* <div className='col-2'>
                          <div className='input-field'>
                            <SearchDropDown
                              label='Color'
                              name='color'
                              api={getOptionsList}
                              listName={"color-list"}
                              customSetter={formik.setFieldValue}
                              incomingValue={
                                productDetails?.basic_details?.color
                              }
                              disabled={!activeInputs}
                            />
                          </div>
                          <div className='col-2 gstn'>
                            <div
                              className={`input-field ${
                                formik.values.packSize !== "" ||
                                focusedField === "packSize"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <label
                                htmlFor='packSize'
                                className='toggle-label'
                              >
                                Pack Size
                              </label>
                              <input
                                id='packSize'
                                name='packSize'
                                type='text'
                                className='form-input'
                                onChange={formik.handleChange}
                                value={formik.values.packSize}
                                onInput={e => {
                                  e.target.value = e.target.value
                                    .replace(/[^A-Za-z ]/gi, "")
                                    .replace(/(?:^|\s)\S/g, match =>
                                      match.toUpperCase(),
                                    )
                                }}
                                maxLength={81}
                                onBlur={e => {
                                  setFocusedField("")
                                  formik.handleBlur(e)
                                }}
                                disabled={!activeInputs}
                                onFocus={() => setFocusedField("packSize")}
                                onPaste={e => {
                                  e.preventDefault()
                                }}
                                onCopy={e => {
                                  e.preventDefault()
                                }}
                              />
                              {formik.touched.packSize &&
                              formik.errors.packSize ? (
                                <span className='err'>
                                  {formik.errors.packSize}
                                </span>
                              ) : null}
                            </div>{" "}
                            <div className='input-field'>
                              <DropDownBox
                                options={uomList}
                                customSetter={formik.setFieldValue}
                                dropDownTitle='UOM'
                                animateDropDownTitle
                                customFormikLabel={"uom"}
                                disabled={activeInputs}
                                customDropBoxStyles={{
                                  border: "1px solid #d7cece",
                                  borderRadius: "0.5rem",
                                  color: "#333",
                                }}
                                customTextStyle={{
                                  color: "#212229",
                                  opacity: "0.7",
                                }}
                                resetButton
                                incomingValue={
                                  productDetails?.basic_details?.uomID
                                }
                              />
                              {formik.touched.uom && formik.errors.uom ? (
                                <span className='err'>{formik.errors.uom}</span>
                              ) : null}
                            </div>
                          </div>
                        </div>{" "} */}
                        <div className='col-2'>
                          <div className='input-field'>
                            <SearchDropDown
                              label='Color'
                              name='color'
                              api={getOptionsList}
                              listName={"color-list"}
                              customSetter={formik.setFieldValue}
                              incomingValue={
                                productDetails?.basic_details?.color
                              }
                              disabled={!activeInputs}
                            />
                          </div>
                          <div className='input-field'>
                            <SearchDropDown
                              label='HSN Code'
                              name='hsn'
                              customSetter={formik.setFieldValue}
                              api={getHsnList}
                              dynamicLabel={"hsn"}
                              maxLength={8}
                              type='number'
                              removeSpace
                              disabled={!activeInputs}
                              minimumSearchLetters={3}
                              incomingValue={productDetails?.basic_details?.hsn}
                            />
                            {formik.touched.hsn && formik.errors.hsn ? (
                              <span className='err'>{formik.errors.hsn}</span>
                            ) : null}
                          </div>
                        </div>{" "}
                        <div className='col-2'>
                          <div className='col-2 gstn'>
                            <div
                              className={`input-field ${
                                formik.values.packSize !== "" ||
                                focusedField === "packSize"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <label
                                htmlFor='packSize'
                                className='toggle-label'
                              >
                                Pack Size
                              </label>
                              <input
                                id='packSize'
                                name='packSize'
                                type='text'
                                className='form-input'
                                onChange={formik.handleChange}
                                value={formik.values.packSize}
                                onInput={e => {
                                  e.target.value = priceValidator(
                                    e.target.value,
                                  )
                                }}
                                maxLength={81}
                                onBlur={e => {
                                  setFocusedField("")
                                  formik.handleBlur(e)
                                }}
                                disabled={!activeInputs}
                                onFocus={() => setFocusedField("packSize")}
                                onPaste={e => {
                                  e.preventDefault()
                                }}
                                onCopy={e => {
                                  e.preventDefault()
                                }}
                              />
                              {formik.touched.packSize &&
                              formik.errors.packSize ? (
                                <span className='err'>
                                  {formik.errors.packSize}
                                </span>
                              ) : null}
                            </div>{" "}
                            <div className='input-field'>
                              <DropDownBox
                                options={uomList}
                                customSetter={formik.setFieldValue}
                                dropDownTitle='UOM'
                                animateDropDownTitle
                                customFormikLabel={"uom"}
                                disabled={activeInputs}
                                customDropBoxStyles={{
                                  border: "1px solid #d7cece",
                                  borderRadius: "0.5rem",
                                  color: "#333",
                                }}
                                customTextStyle={{
                                  color: "#212229",
                                  opacity: "0.7",
                                }}
                                incomingValue={
                                  productDetails?.basic_details?.pack_size
                                    ?.uomID
                                }
                              />
                              {formik.touched.uom && formik.errors.uom ? (
                                <span className='err'>{formik.errors.uom}</span>
                              ) : null}
                            </div>
                          </div>{" "}
                          <div
                            className={`input-field ${
                              formik.values.quantity !== "" ||
                              focusedField === "quantity"
                                ? "active"
                                : ""
                            }`}
                          >
                            <label htmlFor='quantity' className='toggle-label'>
                              Pack Quantity
                            </label>
                            <input
                              id='quantity'
                              name='quantity'
                              type='text'
                              className='form-input'
                              onChange={formik.handleChange}
                              value={formik.values.quantity}
                              onInput={e => {
                                e.target.value = priceValidator(e.target.value)
                              }}
                              maxLength={81}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              disabled={
                                productDetails?.manufacturer === "self"
                                  ? true
                                  : !activeInputs
                              }
                              onFocus={() => setFocusedField("quantity")}
                              onPaste={e => {
                                e.preventDefault()
                              }}
                              onCopy={e => {
                                e.preventDefault()
                              }}
                            />
                            {formik.touched.quantity &&
                            formik.errors.quantity ? (
                              <span className='err'>
                                {formik.errors.quantity}
                              </span>
                            ) : null}
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <OtherDetails
                otherDetails={productDetails?.other_details}
                id={id}
                category_id={formik.values.category}
              />
              {productDetails?.manufacturer === "self" && (
                <PaintDetails
                  id={id}
                  PaintDetails={productDetails?.paint_formula}
                  uomList={uomList}
                  quantity={productDetails?.QuantityToProduce}
                  brandId={formik.values.brand}
                  packSize={formik.values.packSize}
                />
              )}
              <PriceDetails
                id={id}
                priceDetails={productDetails?.price_details}
                packSize={Number(
                  productDetails?.basic_details?.pack_size?.weight,
                )}
                manufacturer={productDetails?.manufacturer}
                catMargin={productDetails?.basic_details?.margin}
              />
              <Comments
                commentDetails={productDetails?.commentsDetail}
                id={id}
              />
            </div>
            <Footer />{" "}
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteProducts}
              popUpData={popUpData}
              // reFetchApi={getProductsList}
              navigateTo='/products'
              // reData={{ search: "", sort:"desc", limit: 5, page: 0 }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductDetails

const OtherDetails = ({ otherDetails, id, category_id }) => {
  const [activeInputs, setActiveInputs] = useState(false)

  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const [focusedField, setFocusedField] = useState()
  const { productsLoading } = useSelector(store => store.products)
  const [reset, setReset] = useState(false)
  const initialValues = {
    description: "",

    paintType: "",
    finish: "",
  }

  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object({
    description: Yup.string()
      .trim()
      .max(150, "Description must be less than 150 characters"),
    // .required("Description is required"),

    paintType: Yup.string(),
    // .required("Paint type is required"),
    finish: Yup.string(),
    // .required("Finish is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        id,
        send: {
          painttype_id: values?.paintType,
          finishtype_id: values?.finish,

          description: values?.description ? values?.description : "",
        },
        details_type: "update-otherDetail",
      }
      const response = await dispatch(updateBasicDetails(data))

      if (response.payload) {
        setActiveInputs(false)
      }
    },
  })

  const handleCancel = () => {
    if (Object.keys(formik.errors)?.length === 0) {
      setActiveInputs(false)
      formik.setValues({
        description: otherDetails?.description,

        paintType: otherDetails?.paintTypeID,
        finish: otherDetails?.finishTypeID,
      })
      setReset(true)
    }
  }

  useEffect(() => {
    formik.setValues({
      description: otherDetails?.description ? otherDetails?.description : "",

      paintType: otherDetails?.paintTypeID,
      finish: otherDetails?.finishTypeID,
    })
  }, [otherDetails])

  return (
    <>
      <div className='main-box-padding'>
        <div className='modelEx_content singleAdd'>
          <form className='product-detail-form' onSubmit={formik.handleSubmit}>
            <div className='profile_personal_details'>
              <div className='details-controls'>
                <h2>Other Details</h2>
                {Object.values(privileges["production_management"]).includes(
                  "write",
                ) && (
                  // <div className='detail-btn'>
                  //   {activeInputs ? (
                  //     <>
                  //       {!productsLoading && (
                  //         <button
                  //           type='submit'
                  //           onClick={handleCancel}
                  //           className='btn-secondary'
                  //           disabled={productsLoading}
                  //           style={
                  //             productsLoading ? { cursor: "not-allowed" } : {}
                  //           }
                  //         >
                  //           Cancel
                  //         </button>
                  //       )}{" "}
                  //       <button
                  //         type='submit'
                  //         onClick={formik.handleSubmit}
                  //         className='btn-primary'
                  //         disabled={productsLoading}
                  //         style={productsLoading ? { cursor: "not-allowed" } : {}}
                  //       >
                  //         {productsLoading ? (
                  //           <ClipLoader
                  //             loading={true}
                  //             size={15}
                  //             aria-label='Loading Spinner'
                  //             data-testid='loader'
                  //             color='white'
                  //           />
                  //         ) : (
                  //           "Save"
                  //         )}
                  //       </button>{" "}
                  //     </>
                  //   ) : (
                  //     <button
                  //       onClick={() => setActiveInputs(true)}
                  //       className='btn-secondary'
                  //     >
                  //       <span className='material-symbols-outlined small'>
                  //         border_color
                  //       </span>
                  //       Edit
                  //     </button>
                  //   )}
                  // </div>
                  <EditFormButtons
                    loading={productsLoading}
                    activeInputs={activeInputs}
                    handleSubmit={formik.handleSubmit}
                    handleCancel={handleCancel}
                    handleInputs={setActiveInputs}
                  />
                )}{" "}
              </div>
            </div>{" "}
            <div className='form-content'>
              <div className='form-group form'>
                <div className='col-2'>
                  <div className='input-field'>
                    {/* <DropDownBox
                      options={[{ label: "Acrylic", value: "Acrylic" }]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"paintType"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Paint Type'
                      animateDropDownTitle
                    /> */}
                    <SearchDropDown
                      label={"Paint Type"}
                      name='paintType'
                      api={getOptionsList}
                      customSetter={formik.setFieldValue}
                      listName={"painttype-list"}
                      dynamicLabel={"paint_type"}
                      disabled={!activeInputs}
                      incomingValue={
                        otherDetails?.paintType ? otherDetails?.paintType : ""
                      }
                      // dynamicLabel='paint_type'
                      resetSetter={setReset}
                      resetState={reset}
                    />{" "}
                    {formik.touched.paintType && formik.errors.paintType ? (
                      <span className='err'>{formik.errors.paintType}</span>
                    ) : null}
                  </div>{" "}
                  <div className='input-field'>
                    {/* <DropDownBox
                      options={[{ label: "Matte", value: "Matte" }]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"finish"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Finish'
                      animateDropDownTitle
                    /> */}
                    <SearchDropDown
                      label='Finish Type'
                      customSetter={formik.setFieldValue}
                      name='finish'
                      listName={"finishtype-list"}
                      dynamicLabel={"finish_type"}
                      api={getOptionsList}
                      disabled={!activeInputs}
                      incomingValue={
                        otherDetails?.finishType ? otherDetails?.finishType : ""
                      }
                      resetSetter={setReset}
                      resetState={reset}
                    />
                    {formik.touched.finish && formik.errors.finish ? (
                      <span className='err'>{formik.errors.finish}</span>
                    ) : null}
                  </div>{" "}
                </div>

                <div
                  className={`input-field ${
                    formik.values.description !== "" ||
                    focusedField === "aboutUs"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='description' className='toggle-label'>
                    Description
                  </label>
                  <textarea
                    id='description'
                    name='description'
                    rows={4}
                    className={
                      !activeInputs
                        ? "form-input disabeldTextArea"
                        : "form-input"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.description || ""}
                    maxLength={151}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("aboutUs")}
                    disabled={!activeInputs}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <span className='err'>{formik.errors.description}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const PaintDetails = ({
  id,
  PaintDetails,
  uomList,
  quantity = "0",
  brandId,
  packSize,
}) => {
  const [activeInputs, setActiveInputs] = useState(false)
  const dispatch = useDispatch()
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const [reset, setReset] = useState(false)

  const [tintersData, setTintersData] = useState([
    {
      productId: "buyers",
      unit: "both",
      weight: "232",
    },
  ])

  const [tintersList, setTintersList] = useState([
    {
      tintersName: "tinters",
      proportionName: "proportionTinters",
      uomName: "dynamicUom",
    },
  ])

  const [focusedField, setFocusedField] = useState()
  const { productsLoading } = useSelector(store => store.products)
  const initialValues = {
    basePaint1: "",
    basePaint2: "",
    proportionPaint1: "",
    proportionPaint2: "",
    quantity: "",
    uomOfPaint1: "",
    uomOfPaint2: "",
  }

  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object({
    basePaint2: Yup.string().required("Base paint 2 is required"),
    proportionPaint1: Yup.string().required(
      "Proportion of Paint 1 is required",
    ),
    proportionPaint2: Yup.string().required(
      "Proportion of Paint 2 is required",
    ),
    tinters: Yup.string(),
    quantity: Yup.string()
      .required("Pack quantity is required")
      .test(
        "quantity",
        "Pack quantity can not be greater than Pack Size",
        value => {
          if (Number(value) > Number(packSize)) {
            return false
          } else {
            return true
          }
        },
      ),
    uomOfPaint1: Yup.string().required("UOM is required"),
    uomOfPaint2: Yup.string().required("UOM is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      const newTinterList = tintersData?.map((item, index) => {
        return {
          productId: item.productId,
          weight: { value: item.weight, unit: item.uomId },
        }
      })
      const data = {
        id,
        send: {
          base_paint_one_productId: values.basePaint1,
          base_paint_two_productId: values.basePaint2,
          base_paint_one_product_weight: values.proportionPaint1,
          base_paint_one_uomId: values.uomOfPaint1,
          base_paint_two_product_weight: values.proportionPaint2,
          base_paint_two_uomId: values.uomOfPaint2,
          tinters: newTinterList,
          total_quantity: values.quantity,
        },
        details_type: "update-paintFormula",
      }

      const response = await dispatch(updateBasicDetails(data))
      if (response.payload) {
        setActiveInputs(false)
      }
    },
  })

  const handleCancel = () => {
    if (Object.keys(formik.errors)?.length === 0) {
      setActiveInputs(false)
      formik.setValues({
        basePaint1: PaintDetails?.base_paint_one?._id,
        proportionPaint1: PaintDetails?.base_paint_one?.pack_size?.value,
        uomOfPaint1: PaintDetails?.base_paint_one?.pack_size?.uomId,
        basePaint2: PaintDetails?.base_paint_two?._id,
        proportionPaint2: PaintDetails?.base_paint_two?.pack_size?.value,
        uomOfPaint2: PaintDetails?.base_paint_two?.pack_size?.uomId,
        quantity: PaintDetails?.totalQuantityProduced,
      })

      const newTinters = PaintDetails?.tinters.map((item, index) => {
        return {
          productId: item?.productId,
          weight: item?.pack_size?.weight,
          uomId: item?.pack_size?.uomId,
          name: item?.name,
          quantity: item?.name,
        }
      })

      setTintersData(newTinters)
      setReset(true)
    }
  }
  const handleFormState = (value, obj) => {
    const { index, name, formikLabel } = obj

    setTintersData(tintersData => {
      const newList = JSON.parse(JSON.stringify(tintersData))

      if (newList[index] === undefined) {
        newList.push({ [name]: value })
      } else {
        newList[index][name] = value
      }
      return newList
    })

    // if (index === 0) {
    //   formik.setFieldValue(formikLabel, value)
    // }
  }

  useEffect(() => {
    formik.setValues({
      basePaint1: PaintDetails?.base_paint_one?._id,
      proportionPaint1: PaintDetails?.base_paint_one?.pack_size?.value,
      uomOfPaint1: PaintDetails?.base_paint_one?.pack_size?.uomId,
      basePaint2: PaintDetails?.base_paint_two?._id,
      proportionPaint2: PaintDetails?.base_paint_two?.pack_size?.value,
      uomOfPaint2: PaintDetails?.base_paint_two?.pack_size?.uomId,
      quantity: PaintDetails?.totalQuantityProduced
        ? PaintDetails?.totalQuantityProduced
        : "",
    })

    const newTinters = PaintDetails?.tinters.map((item, index) => {
      return {
        productId: item?.productId,
        weight: item?.pack_size?.weight,
        uomId: item?.pack_size?.uomId,
        name: item?.name,
        quantity: item?.name,
      }
    })

    setTintersData(newTinters)
  }, [PaintDetails])
  //
  const handleAddTinters = () => {
    setTintersData([
      ...tintersData,
      {
        productId: "",
        weight: "",
        uomId: "",
        // name: "",
        // quantity: "",
      },
    ])
  }
  const handleRemoveTinters = () => {
    // setTintersList(tintersList => {
    //   const newArr = JSON.parse(JSON.stringify(tintersList))
    //   newArr.pop()
    //   return newArr
    // })
    // setProportionHistory(oldList => {
    //   let newList = JSON.parse(JSON.stringify(oldList))
    //   newList.tinterHistory.pop()
    //   return newList
    // })
    setTintersData(tintersList => {
      const newArr = JSON.parse(JSON.stringify(tintersList))
      if (newArr.length > 1) {
        newArr.pop()
      }
      return newArr
    })
  }

  return (
    <>
      <div className='main-box-padding'>
        <div className='modelEx_content singleAdd'>
          <form className='product-detail-form' onSubmit={formik.handleSubmit}>
            <div className='profile_personal_details'>
              <div className='details-controls'>
                <h2>Paint Formula</h2>
                {Object.values(privileges["production_management"]).includes(
                  "write",
                ) && (
                  // <div className='detail-btn'>
                  //   {activeInputs ? (
                  //     <>
                  //       <button
                  //         type='submit'
                  //         onClick={handleCancel}
                  //         className='btn-secondary'
                  //         disabled={productsLoading}
                  //         style={productsLoading ? { cursor: "not-allowed" } : {}}
                  //       >
                  //         {productsLoading ? (
                  //           <ClipLoader
                  //             loading={true}
                  //             size={15}
                  //             aria-label='Loading Spinner'
                  //             data-testid='loader'
                  //             color='white'
                  //           />
                  //         ) : (
                  //           "Cancel"
                  //         )}
                  //       </button>{" "}
                  //       <button
                  //         type='submit'
                  //         onClick={formik.handleSubmit}
                  //         className='btn-primary'
                  //         disabled={productsLoading}
                  //         style={productsLoading ? { cursor: "not-allowed" } : {}}
                  //       >
                  //         {productsLoading ? (
                  //           <ClipLoader
                  //             loading={true}
                  //             size={15}
                  //             aria-label='Loading Spinner'
                  //             data-testid='loader'
                  //             color='white'
                  //           />
                  //         ) : (
                  //           "Save"
                  //         )}
                  //       </button>{" "}
                  //     </>
                  //   ) : (
                  //     <button
                  //       onClick={() => setActiveInputs(true)}
                  //       className='btn-secondary'
                  //     >
                  //       <span className='material-symbols-outlined small'>
                  //         border_color
                  //       </span>
                  //       Edit
                  //     </button>
                  //   )}
                  // </div>
                  <EditFormButtons
                    loading={productsLoading}
                    activeInputs={activeInputs}
                    handleSubmit={formik.handleSubmit}
                    handleCancel={handleCancel}
                    handleInputs={setActiveInputs}
                  />
                )}{" "}
              </div>
            </div>{" "}
            <div className='form-content'>
              <div className='form-group form'>
                <div className='col-2'>
                  <div className='input-field'>
                    {/* <DropDownBox
                      options={[
                        { label: "Buyers", value: "buyers" },
                        { label: "Suppliers", value: "suppliers" },
                        { label: "Both", value: "both" },
                      ]}
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"basePaint1"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                      dropDownTitle='Base Paint 1'
                      animateDropDownTitle
                      disabled={activeInputs}
                    /> */}
                    <SearchDropDown
                      label='Base Paint 1 Component A'
                      name='basePaint1'
                      api={getBasePaint}
                      customSetter={formik.setFieldValue}
                      dynamicLabel={"name"}
                      incomingValue={
                        PaintDetails?.base_paint_one?.name
                          ? PaintDetails?.base_paint_one?.name
                          : ""
                      }
                      disabled={!activeInputs}
                      resetSetter={setReset}
                      resetState={reset}
                      showSize
                    />
                    {formik.touched.basePaint1 && formik.errors.basePaint1 ? (
                      <span className='err'>{formik.errors.basePaint1}</span>
                    ) : null}
                  </div>{" "}
                  <div className='col-2 gstn'>
                    <div
                      className={`input-field ${
                        formik.values.proportionPaint1 !== "" ||
                        focusedField === "proportionPaint1"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor='proportionPaint1'
                        className='toggle-label'
                      >
                        Proportion Of Paint 1
                      </label>
                      <input
                        id='proportionPaint1'
                        name='proportionPaint1'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.proportionPaint1}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                        }}
                        maxLength={81}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("proportionPaint1")}
                        disabled={!activeInputs}
                      />
                      {formik.touched.proportionPaint1 &&
                      formik.errors.proportionPaint1 ? (
                        <span className='err'>
                          {formik.errors.proportionPaint1}
                        </span>
                      ) : null}
                    </div>{" "}
                    <div className='input-field'>
                      <DropDownBox
                        options={uomList}
                        customSetter={formik.setFieldValue}
                        defaultValue='UOM'
                        customFormikLabel={"uomOfPaint1"}
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                        disabled={false}
                        incomingValue={
                          PaintDetails?.base_paint_one?.pack_size?.uomId
                        }
                      />
                      {formik.touched.uomOfPaint1 &&
                      formik.errors.uomOfPaint1 ? (
                        <span className='err'>{formik.errors.uomOfPaint1}</span>
                      ) : null}
                    </div>
                  </div>
                </div>{" "}
                {tintersData?.map((item, index) => {
                  const { uomId, weight, name, proportionName, uomName } = item

                  return (
                    <div className='col-2' key={index}>
                      <div className='input-field'>
                        {/* <DropDownBox
                          options={[
                            { label: "Buyers", value: "buyers" },
                            { label: "Suppliers", value: "suppliers" },
                            { label: "Both", value: "both" },
                          ]}
                          customSetter={formik.setFieldValue}
                          customFormikLabel={tintersName}
                          customDropBoxStyles={{
                            border: "1px solid #d7cece",
                            borderRadius: "0.5rem",
                            color: "#333",
                          }}
                          customTextStyle={{ color: "#212229", opacity: "0.7" }}
                          dropDownTitle={`TInters ${index + 1}`}
                          animateDropDownTitle
                          callCustomFunction={handleFormState}
                          customValueForCustomFunction={{
                            index,
                            name: "productId",
                            formikLabel: "tinters",
                          }}
                          disabled={activeInputs}
                        /> */}
                        <SearchDropDown
                          label={`Tinters ${index + 1}`}
                          name={`tinters${index + 1}`}
                          api={getOptionsList}
                          listName={"tinters-list"}
                          customSetter={formik.setFieldValue}
                          customFunctionOnClick={handleFormState}
                          customValueForFunctionOnClick={{
                            index,
                            name: "productId",
                            formikLabel: "tinters",
                          }}
                          dynamicLabel={"productName"}
                          // dynamicValue={"productId"}
                          disabled={!activeInputs}
                          incomingValue={name}
                          resetSetter={setReset}
                          resetState={reset}
                          apiData={{
                            send: {
                              brandId: brandId ? brandId : "",
                              category: "tinters",
                            },
                          }}
                          showSize
                        />

                        {formik.touched[name] && formik.errors[name] ? (
                          <span className='err'>{formik.errors[name]}</span>
                        ) : null}
                      </div>{" "}
                      <div className='col-2 gstn'>
                        <div
                          className={`input-field ${
                            formik.values.proportionTinters !== "" ||
                            focusedField === proportionName
                              ? "active"
                              : ""
                          }`}
                        >
                          <label
                            htmlFor={proportionName}
                            className='toggle-label'
                          >
                            Proportion of Tinters
                          </label>
                          <input
                            id={proportionName}
                            name={proportionName}
                            type='text'
                            className='form-input'
                            value={weight}
                            onChange={e => {
                              // handleFormState(e.target.value, {
                              //   index,
                              //   name: "weight",
                              //   formikLabel: "proportionTinters",
                              // })
                              setTintersData(oldList => {
                                const newList = JSON.parse(
                                  JSON.stringify(oldList),
                                )

                                newList[index].weight = e.target.value

                                return newList
                              })
                            }}
                            disabled={!activeInputs}
                            // value={formik.values.proportionTinters}
                            onInput={e => {
                              e.target.value = priceValidator(e.target.value)
                            }}
                            maxLength={81}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField(proportionName)}
                          />
                          {formik.touched[proportionName] &&
                          formik.errors[proportionName] ? (
                            <span className='err'>
                              {formik.errors[proportionName]}
                            </span>
                          ) : null}
                        </div>{" "}
                        <div className='input-field'>
                          <DropDownBox
                            options={uomList}
                            customSetter={formik.setFieldValue}
                            defaultValue='UOM'
                            customFormikLabel={uomName}
                            customDropBoxStyles={{
                              border: "1px solid #d7cece",
                              borderRadius: "0.5rem",
                              color: "#333",
                            }}
                            customTextStyle={{
                              color: "#212229",
                              opacity: "0.7",
                            }}
                            customValueForCustomFunction={{
                              index,
                              name: "uomId",
                              formikLabel: "dynamicUom",
                            }}
                            callCustomFunction={handleFormState}
                            disabled={false}
                            incomingValue={
                              uomId
                                ? uomId
                                : PaintDetails?.base_paint_one?.pack_size
                                      ?.uomType === "litre"
                                  ? PaintDetails?.base_paint_one?.pack_size
                                      ?.uomId
                                  : ""
                            }
                          />
                          {formik.touched[uomName] && formik.errors[uomName] ? (
                            <span className='err'>
                              {formik.errors[uomName]}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div
                  style={{ marginLeft: "auto", display: "flex", gap: "1rem" }}
                >
                  <button
                    type='button'
                    className='btn-primary header-btn'
                    title='Add Button'
                    onClick={handleAddTinters}
                    disabled={!activeInputs}
                  >
                    Add Tinters
                  </button>
                  {tintersData.length > 1 && (
                    <button
                      type='button'
                      className='btn-secondary'
                      onClick={handleRemoveTinters}
                      disabled={!activeInputs}
                    >
                      Remove Tinters
                    </button>
                  )}
                </div>
                <div className='col-2'>
                  <div className='input-field'>
                    <SearchDropDown
                      label='Base Paint 2 Component B'
                      name='basePaint2'
                      api={getBasePaint}
                      customSetter={formik.setFieldValue}
                      dynamicLabel={"name"}
                      incomingValue={
                        PaintDetails?.base_paint_two?.name
                          ? PaintDetails?.base_paint_two?.name
                          : ""
                      }
                      disabled={!activeInputs}
                      resetSetter={setReset}
                      resetState={reset}
                      showSize
                    />
                    {formik.touched.basePaint2 && formik.errors.basePaint2 ? (
                      <span className='err'>{formik.errors.basePaint2}</span>
                    ) : null}
                  </div>{" "}
                  <div className='col-2 gstn'>
                    <div
                      className={`input-field ${
                        formik.values.proportionPaint2 !== "" ||
                        focusedField === "proportionPaint2"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor='proportionPaint2'
                        className='toggle-label'
                      >
                        Proportion Of Paint 2
                      </label>
                      <input
                        id='proportionPaint2'
                        name='proportionPaint2'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.proportionPaint2}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                        }}
                        disabled={!activeInputs}
                        maxLength={81}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("proportionPaint2")}
                      />
                      {formik.touched.proportionPaint2 &&
                      formik.errors.proportionPaint2 ? (
                        <span className='err'>
                          {formik.errors.proportionPaint2}
                        </span>
                      ) : null}
                    </div>{" "}
                    <div className='input-field'>
                      <DropDownBox
                        options={uomList}
                        customSetter={formik.setFieldValue}
                        defaultValue='UOM'
                        customFormikLabel={"uomOfPaint3"}
                        customDropBoxStyles={{
                          border: "1px solid #d7cece",
                          borderRadius: "0.5rem",
                          color: "#333",
                        }}
                        disabled={false}
                        customTextStyle={{ color: "#212229", opacity: "0.7" }}
                        incomingValue={formik.values.uomOfPaint2}
                      />
                      {formik.touched.uomOfPaint3 &&
                      formik.errors.uomOfPaint3 ? (
                        <span className='err'>{formik.errors.uomOfPaint3}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <div className="input-field">
                  <DropDownBox
                    options={[
                      { label: "Buyers", value: "buyers" },
                      { label: "Suppliers", value: "suppliers" },
                      { label: "Both", value: "both" },
                    ]}
                    
                    customSetter={formik.setFieldValue}
                    customFormikLabel={"quantity"}
                    customDropBoxStyles={{
                      border: "1px solid #d7cece",
                      borderRadius: "0.5rem",
                      color: "#333",
                    }}
                    customTextStyle={{ color: "#212229", opacity: "0.7" }}
                    dropDownTitle="Total Quantity Produce"
                    animateDropDownTitle
                  />
                  {formik.touched.quantity && formik.errors.quantity ? (
                    <span className="err">{formik.errors.quantity}</span>
                  ) : null}
                </div>{" "} */}
                <div
                  className={`input-field ${
                    formik.values.quantity !== "" || focusedField === "quantity"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='quantity' className='toggle-label'>
                    Total Quantity Produce
                  </label>
                  <div
                    className={
                      formik.values.quantity ? "margin_percentage_box" : ""
                    }
                  >
                    {
                      <span className='m_percentage'>
                        {formik.values.quantity ? "liter" : ""}
                      </span>
                    }{" "}
                    <input
                      id='quantity'
                      name='quantity'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.quantity}
                      onInput={e => {
                        e.target.value = priceValidator(e.target.value)
                      }}
                      maxLength={81}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("quantity")}
                      disabled={!activeInputs}
                    />
                  </div>

                  {formik.touched.quantity && formik.errors.quantity ? (
                    <span className='err'>{formik.errors.quantity}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const PriceDetails = ({
  id,
  priceDetails,
  packSize,
  manufacturer,
  catMargin,
}) => {
  const [activeInputs, setActiveInputs] = useState(false)

  const dispatch = useDispatch()
  const { privileges, role } = useSelector(state => state.auth, shallowEqual)
  const { productsLoading } = useSelector(store => store.products)

  const [focusedField, setFocusedField] = useState()
  const initialValues = {
    unitPrice: "",
    costPrice: "",
    sellingPrice: "",
    marginPercentage: "",
  }

  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object({
    unitPrice: Yup.string().required("Unit price is required").matches(/[\d]/),
    costPrice: Yup.string().required("Cost price is required").matches(/[\d]/),
    sellingPrice: Yup.string()
      .required("selling price is required")
      .matches(/[\d]/),
    marginPercentage: Yup.string().required("Margin percentage is required"),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        id,
        send: {
          selling_price: convertRupeesToNumber(values.sellingPrice),
          unit_price: convertRupeesToNumber(values.unitPrice),
          cost_price: convertRupeesToNumber(values.costPrice),
          // margin: values.marginPercentage,
        },
        details_type: "update-priceDetail",
      }
      const response = await dispatch(updateBasicDetails(data))
      if (response.payload) {
        setActiveInputs(false)
      }
    },
  })

  const handleCancel = () => {
    if (Object.keys(formik.errors)?.length === 0) {
      setActiveInputs(false)
      formik.setValues({
        costPrice: numberWithCommas(priceDetails?.costPrice),
        sellingPrice: numberWithCommas(priceDetails?.sellingPrice),
        unitPrice: numberWithCommas(priceDetails?.unitPrice),
        marginPercentage: catMargin,
      })
    }
  }

  useEffect(() => {
    formik.setValues({
      costPrice: numberWithCommas(priceValidator(priceDetails?.costPrice)),
      sellingPrice: numberWithCommas(
        priceValidator(priceDetails?.sellingPrice),
      ),
      unitPrice: numberWithCommas(priceValidator(priceDetails?.unitPrice)),
      marginPercentage: catMargin,
    })
  }, [priceDetails, catMargin])

  useEffect(() => {
    if (formik.values.costPrice) {
      formik.setFieldValue(
        "sellingPrice",
        numberWithCommas(
          priceValidator(
            findValueFromPercentage({
              percentage: formik.values.marginPercentage,
              value: formik.values.costPrice,
              returnTotalValue: true,
              packSize,
            }),
          ),
        ),
      )
    }
  }, [
    formik.values.costPrice,
    formik.values.marginPercentage,
    formik.values.unitPrice,
  ])
  const calPrice = (value, pack_size, name) => {
    if (name === "unitPrice") {
      let calValue = Number(pack_size) * Number(value)
      formik.setFieldValue(
        "costPrice",
        `${calValue !== 0 ? numberWithCommas(priceValidator(calValue)) : ""}`,
      )
    } else if (name === "costPrice") {
      let calValue = (Number(value) / Number(pack_size)).toFixed(2)
      formik.setFieldValue(
        "unitPrice",
        `${calValue !== 0 ? numberWithCommas(priceValidator(calValue)) : ""}`,
      )
    }
  }
  return (
    <>
      <div className='main-box-padding'>
        <div className='modelEx_content singleAdd'>
          <form className='product-detail-form' onSubmit={formik.handleSubmit}>
            <div className='profile_personal_details'>
              <div className='details-controls'>
                <h2>Price Details</h2>
                {Object.values(privileges["production_management"]).includes(
                  "write",
                ) && (
                  // <div className='detail-btn'>
                  //   {activeInputs ? (
                  //     <>
                  //       <button
                  //         type='submit'
                  //         onClick={handleCancel}
                  //         className='btn-secondary'
                  //         disabled={productsLoading}
                  //         style={productsLoading ? { cursor: "not-allowed" } : {}}
                  //       >
                  //         {productsLoading ? (
                  //           <ClipLoader
                  //             loading={true}
                  //             size={15}
                  //             aria-label='Loading Spinner'
                  //             data-testid='loader'
                  //             color='white'
                  //           />
                  //         ) : (
                  //           "Cancel"
                  //         )}
                  //       </button>{" "}
                  //       <button
                  //         type='submit'
                  //         onClick={formik.handleSubmit}
                  //         className='btn-primary'
                  //         disabled={productsLoading}
                  //         style={productsLoading ? { cursor: "not-allowed" } : {}}
                  //       >
                  //         {productsLoading ? (
                  //           <ClipLoader
                  //             loading={true}
                  //             size={15}
                  //             aria-label='Loading Spinner'
                  //             data-testid='loader'
                  //             color='white'
                  //           />
                  //         ) : (
                  //           "Save"
                  //         )}
                  //       </button>{" "}
                  //     </>
                  //   ) : (
                  //     <button
                  //       onClick={() => setActiveInputs(true)}
                  //       className='btn-secondary'
                  //     >
                  //       <span className='material-symbols-outlined small'>
                  //         border_color
                  //       </span>
                  //       Edit
                  //     </button>
                  //   )}
                  // </div>
                  <EditFormButtons
                    loading={productsLoading}
                    activeInputs={activeInputs}
                    handleSubmit={formik.handleSubmit}
                    handleCancel={handleCancel}
                    handleInputs={setActiveInputs}
                  />
                )}{" "}
              </div>
            </div>{" "}
            <div className='form-content'>
              <div className='form-group form'>
                <div className={manufacturer !== "self" ? "col-2" : ""}>
                  {manufacturer !== "self" && (
                    <div
                      className={`input-field ${
                        formik.values.unitPrice !== "" ||
                        focusedField === "unitPrice"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='unitPrice' className='toggle-label'>
                        Unit Price
                      </label>
                      <div
                        className={formik.values.unitPrice ? "phone_box" : ""}
                      >
                        {
                          <span className='phone_code'>
                            {formik.values.unitPrice ? "₹" : ""}
                          </span>
                        }
                        <input
                          id='unitPrice'
                          name='unitPrice'
                          type='text'
                          className='form-input'
                          onChange={e => {
                            if (!packSize) {
                              e.preventDefault()
                            } else {
                              // formik.handleChange(e)
                              formik.setFieldValue(
                                "unitPrice",
                                numberWithCommas(e.target.value),
                              )
                              calPrice(e.target.value, packSize, "unitPrice")
                            }
                          }}
                          value={formik.values.unitPrice}
                          onInput={e => {
                            e.target.value = priceValidator(e.target.value)

                            // setPriceFieldDisabler("costPrice")
                          }}
                          maxLength={14}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => {
                            setFocusedField("unitPrice")
                            if (!packSize) {
                              toast.warning("Please enter pack size first")
                            }
                          }}
                          disabled={!activeInputs}
                        />
                      </div>
                      {formik.touched.unitPrice && formik.errors.unitPrice ? (
                        <span className='err'>{formik.errors.unitPrice}</span>
                      ) : null}
                    </div>
                  )}

                  <div
                    className={`input-field ${
                      formik.values.costPrice !== "" ||
                      focusedField === "costPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='costPrice' className='toggle-label'>
                      Cost Price
                    </label>
                    <div className={formik.values.costPrice ? "phone_box" : ""}>
                      {
                        <span className='phone_code'>
                          {formik.values.costPrice ? "₹" : ""}
                        </span>
                      }
                      <input
                        id='costPrice'
                        name='costPrice'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          if (!packSize) {
                            e.preventDefault()
                          } else {
                            formik.setFieldValue(
                              "costPrice",
                              numberWithCommas(e.target.value),
                            )
                            calPrice(e.target.value, packSize, "costPrice")
                          }
                        }}
                        value={formik.values.costPrice}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                          // setPriceFieldDisabler("unitPrice")
                        }}
                        maxLength={14}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => {
                          setFocusedField("costPrice")
                          if (!packSize) {
                            toast.warning("Please enter pack size first")
                          }
                        }}
                        disabled={!activeInputs}
                      />
                    </div>

                    {formik.touched.costPrice && formik.errors.costPrice ? (
                      <span className='err'>{formik.errors.costPrice}</span>
                    ) : null}
                  </div>
                </div>
                <div className={role === 1 ? "col-2" : ""}>
                  {role === 1 && (
                    <div
                      className={`input-field ${
                        formik.values.marginPercentage !== "" ||
                        focusedField === "marginPercentage"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor='marginPercentage'
                        className='toggle-label'
                      >
                        Margin Percentage (%)
                      </label>
                      <div
                        className={
                          formik.values.marginPercentage
                            ? "margin_percentage_box"
                            : ""
                        }
                      >
                        {
                          <span className='m_percentage'>
                            {formik.values.marginPercentage ? "%" : ""}
                          </span>
                        }{" "}
                        <input
                          id='marginPercentage'
                          name='marginPercentage'
                          type='text'
                          className='form-input'
                          onChange={e => {
                            formik.handleChange(e)
                          }}
                          value={formik.values.marginPercentage}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^\d]/gi,
                              "",
                            )
                          }}
                          maxLength={2}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          disabled
                          onFocus={() => setFocusedField("marginPercentage")}
                        />
                      </div>

                      {formik.touched.marginPercentage &&
                      formik.errors.marginPercentage ? (
                        <span className='err'>
                          {formik.errors.marginPercentage}
                        </span>
                      ) : null}
                    </div>
                  )}

                  <div
                    className={`input-field ${
                      formik.values.sellingPrice !== "" ||
                      focusedField === "sellingPrice"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='sellingPrice' className='toggle-label'>
                    Selling Unit Price
                    </label>
                    <div
                      className={formik.values.sellingPrice ? "phone_box" : ""}
                    >
                      {
                        <span className='phone_code'>
                          {formik.values.sellingPrice ? "₹" : ""}
                        </span>
                      }{" "}
                      <input
                        id='sellingPrice'
                        name='sellingPrice'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          formik.setFieldValue(
                            "sellingPrice",
                            numberWithCommas(e.target.value),
                          )
                        }}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)

                          // setPriceFieldDisabler("costPrice")
                        }}
                        value={formik.values.sellingPrice}
                        maxLength={81}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("sellingPrice")}
                        disabled={!activeInputs}
                      />
                    </div>

                    {formik.touched.sellingPrice &&
                    formik.errors.sellingPrice ? (
                      <span className='err'>{formik.errors.sellingPrice}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const Comments = ({ commentDetails, id }) => {
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { profileDetail } = useSelector(store => store.profile)
  const dispatch = useDispatch()
  const { productsLoading } = useSelector(store => store.products)

  const [comments, setComments] = useState(commentDetails)

  const initialValues = {
    commentBox: "",
  }
  const commentSchema = Yup.object({
    commentBox: Yup.string()
      .trim()
      .required("Comment field is required")
      .max(700),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: commentSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        product_id: id,
        comment: values.commentBox.trim(),
      }
      const response = await dispatch(addComment(data))

      if (response.payload) {
        dispatch(getProductsDetails({ id }))
        resetForm()
      }
    },
  })

  useEffect(() => {
    setComments(commentDetails)
  }, [commentDetails])

  return (
    <>
      <div className='main-box-padding'>
        <div className='modelEx_content singleAdd'>
          <div className='profile_personal_details'>
            <div className='details-controls'>
              <h2>
                {commentDetails?.length}{" "}
                {commentDetails?.length === 1 ? "Comment" : "Comments"}
              </h2>
            </div>
          </div>
          <div className='commentBox'>
            {}
            {commentDetails?.length > 0 ? (
              comments?.map((item, index) => {
                const {
                  name = item.fname + " " + item.lname,
                  time = formatTimestamp(item.createdAt),
                  comment,
                  date = formatTimestamp(item.createdAt),
                } = item
                return (
                  <div
                    key={index}
                    className='commentBar'
                    style={
                      index > 0
                        ? {
                            borderTop: "1px solid #8c8c8c",
                            paddingTop: "1.5rem",
                          }
                        : {}
                    }
                  >
                    <div className='commentHeading'>
                      <div className='commenterName'>
                        <p>{name.charAt(0)}</p>
                        <span>{name}</span>
                      </div>
                      <span>{date}</span>
                    </div>
                    <span className='commentMessage'>{comment}</span>
                  </div>
                )
              })
            ) : (
              <div className='Nodata-txt' style={{ padding: 0 }}>
                <img
                  src={require("../../../assets/images/nodata.svg").default}
                  alt=''
                  srcSet=''
                />
                No Comment
              </div>
            )}
          </div>
          {Object.values(privileges["production_management"]).includes(
            "write",
          ) && (
            <form
              className='product-detail-form'
              onSubmit={formik.handleSubmit}
            >
              <div className='form-content'>
                <div className='form-group form'>
                  <div>
                    <textarea
                      type='text'
                      name='commentBox'
                      className='commentInput'
                      placeholder='Type Comment Here...'
                      value={formik.values.commentBox}
                      onChange={formik.handleChange}
                      maxLength={701}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          formik.handleSubmit()
                        }
                      }}
                    />
                    {formik.touched.commentBox && formik.errors.commentBox ? (
                      <span className='err'>{formik.errors.commentBox}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <FormButtons
                loading={productsLoading}
                removeCancel={true}
                btnContainerStyles={{ justifyContent: "flex-end" }}
              />
              {/* <button
                type='submit'
                onClick={formik.handleSubmit}
                className='btn-primary'
                disabled={productsLoading}
                style={productsLoading ? { cursor: "not-allowed" } : {}}
              >
                {productsLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "Submit"
                )}
              </button>{" "} */}
              {/* <div className="input-field">
            
              {formik.touched.brand && formik.errors.brand ? (
                <span className="err">{formik.errors.brand}</span>
              ) : null}
            </div> */}
            </form>
          )}{" "}
        </div>
      </div>
    </>
  )
}
