import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import DropDownBox from "../../../components/DropDownBox"
import * as Yup from "yup"
import { useLocation } from "react-router-dom"

const SalesPrimaryDetails = ({ formData, setFormData }) => {
  const [activeInputs, setActiveInputs] = useState(false)
  const dispatch = useDispatch()
  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const { productsLoading } = useSelector(store => store.products)

  const [primaryData, setPrimaryData] = useState({})

  const location = useLocation()
  const activePath = () => {
    if (location?.pathname === "/sales/sales-order") {
      return "isSalesOrder"
    } else if (location?.pathname === "/sales/sales-return") {
      return "isSalesReturn"
    } else if (location?.pathname === "/sales-quotation/create-quotation") {
      return "isSalesQuotation"
    } else if (location?.pathname === "/sales/sales-proforma-invoice") {
      return "isSalesProforma"
    } else if (location?.pathname === "/sales/delivery-challan") {
      return "isSalesDeliveryChallan"
    }
  }

  const checkValue = valueList => {
    const findValue = valueList.find(item => item === "")

    return findValue === "" ? false : true
  }

  useEffect(() => {
    if (!formData?.primaryDetails && checkValue(Object.values(primaryData))) {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))
        newList.primaryDetails = primaryData

        return newList
      })
    } else {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))
        newList.primaryDetails = true
        return newList
      })
    }

  }, [formData?.primaryDetails])

  const handleCancel = () => {
    // if (Object.keys(formik.errors).length === 0) {
    // }
    setActiveInputs(false)
  }
  return (
    <>
      <div className='main-box-padding'>
        <div className='modelEx_content singleAdd'>
          {activePath() === "isSalesQuotation" ? (
            <SalesQuotationForm
              activeInputs={activeInputs}
              setActiveInputs={setActiveInputs}
              privileges={privileges}
              productsLoading={productsLoading}
              handleCancel={handleCancel}
              setPrimaryData={setPrimaryData}
            />
          ) : activePath() === "isSalesOrder" ? (
            <SalesOrderForm
              activeInputs={activeInputs}
              setActiveInputs={setActiveInputs}
              privileges={privileges}
              productsLoading={productsLoading}
              handleCancel={handleCancel}
              setPrimaryData={setPrimaryData}
            />
          ) : activePath() === "isSalesReturn" ? (
            <SalesReturnForm
              activeInputs={activeInputs}
              setActiveInputs={setActiveInputs}
              privileges={privileges}
              productsLoading={productsLoading}
              handleCancel={handleCancel}
              setPrimaryData={setPrimaryData}
            />
          ) : activePath() === "isSalesProforma" ? (
            <SalesProformaForm
              activeInputs={activeInputs}
              setActiveInputs={setActiveInputs}
              privileges={privileges}
              productsLoading={productsLoading}
              handleCancel={handleCancel}
              setPrimaryData={setPrimaryData}
            />
          ) : (
            <DeliveryChallanForm
              activeInputs={activeInputs}
              setActiveInputs={setActiveInputs}
              privileges={privileges}
              productsLoading={productsLoading}
              handleCancel={handleCancel}
              setPrimaryData={setPrimaryData}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default SalesPrimaryDetails

const SalesOrderForm = ({ activeInputs, setActiveInputs, privileges, productsLoading, handleCancel , setPrimaryData}) => {
  const [focusedField, setFocusedField] = useState()
  const initialValues = {
    documentDate: "",
    deliveryDate: "",
    PaymentTerm: "",
    paymentDate: "",
    customerId: "",
    purchase_officer: "",
    transportation_cost: "",
  }

  const singleComSchema = Yup.object({
    documentDate: Yup.string().required("Please enter document date"),
    deliveryDate: Yup.string().required("Please enter delivery date"),
    PaymentTerm: Yup.string().required("Please enter payment term"),
    customerId: Yup.string().required("Sales order number is required"),
    paymentDate: Yup.string().required("Please enter payment date"),
    purchase_officer: Yup.string().required(
      "Please enter Sales officer name",
    ),
    transportation_cost: Yup.string().required(
      "Transpotation cost is required",
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      setActiveInputs(false)
      setPrimaryData(formik.values)
      //   const response = await dispatch(updateBasicDetails(data))
      //   if (response.payload) {
      //     setActiveInputs(false)
      //   }
    },
  })

  return (
    <form className="profile_personal_details" onSubmit={formik.handleSubmit}>
      <div className='details-controls'>
        <h2>Primary Document Details</h2>
        {Object.values(privileges["sales_management"]).includes(
          "write",
        ) && (
            <div className='detail-btn'>
              {activeInputs ? (
                <>
                  <button
                    type='button'
                    onClick={handleCancel}
                    className='btn-secondary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Cancel"
                    )}
                  </button>{" "}
                  <button
                    type='submit'
                    // onClick={formik.handleSubmit}
                    className='btn-primary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Save"
                    )}
                  </button>{" "}
                </>
              ) : (
                <button
                  type="button"
                  onClick={() => setActiveInputs(true)}
                  className='btn-secondary'
                >
                  <span className='material-symbols-outlined small'>
                    border_color
                  </span>
                  Edit
                </button>
              )}
            </div>
          )}{" "}
      </div>
      <div className='product-detail-form' >
        <div className='form-content'>
          <div className='form-group form'>
            <div className='col-2'>
              <div
                className={`input-field ${formik.values.customerId !== "" || focusedField === "customerId"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='customerId' className='toggle-label'>
                  Customer Id
                </label>
                <input
                  id='customerId'
                  name='customerId'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.customerId}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("customerId")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.customerId && formik.errors.customerId ? (
                  <span className='err'>{formik.errors.customerId}</span>
                ) : null}
              </div>

              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='documentDate' className='toggle-label'>
                  Document Date
                </label>
                <input
                  id='documentDate'
                  name='documentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.documentDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("documentDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.documentDate && formik.errors.documentDate ? (
                  <span className='err'>{formik.errors.documentDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='deliveryDate' className='toggle-label'>
                  Delivery Date
                </label>
                <input
                  id='deliveryDate'
                  name='deliveryDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.deliveryDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("deliveryDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.deliveryDate && formik.errors.deliveryDate ? (
                  <span className='err'>{formik.errors.deliveryDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.PaymentTerm !== "" ||
                  focusedField === "PaymentTerm"
                  ? "active"
                  : ""
                  }`}
              >
                <DropDownBox
                  options={[
                    { label: "Immediately", value: "immediately" },
                    { label: "Net 30", value: "Net30" },
                    { label: "Net 60", value: "Net60" },
                    { label: "Net 90", value: "Net90" },
                  ]}
                  dropDownTitle='Payment Term'
                  animateDropDownTitle
                  customSetter={formik.setFieldValue}
                  customFormikLabel={"PaymentTerm"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  disabled={activeInputs}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                />
                {formik.touched.PaymentTerm && formik.errors.PaymentTerm ? (
                  <span className='err'>{formik.errors.PaymentTerm}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='paymentDate' className='toggle-label'>
                  Payment Date
                </label>
                <input
                  id='paymentDate'
                  name='paymentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.paymentDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("paymentDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.paymentDate && formik.errors.paymentDate ? (
                  <span className='err'>{formik.errors.paymentDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.purchase_officer !== "" ||
                  focusedField === "purchase_officer"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='purchase_officer' className='toggle-label'>
                  Purchase Officer
                </label>
                <input
                  id='purchase_officer'
                  name='purchase_officer'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.purchase_officer}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("purchase_officer")}
                  disabled={!activeInputs}
                />
                {formik.touched.purchase_officer &&
                  formik.errors.purchase_officer ? (
                  <span className='err'>{formik.errors.purchase_officer}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.transportation_cost !== "" ||
                  focusedField === "transportation_cost"
                  ? "active"
                  : ""
                  }`}
              >
                <DropDownBox
                  options={[
                    { label: "Free", value: "free" },
                    { label: "To pay", value: "to-pay" },
                    { label: "In invoice", value: "in-invoice" },
                  ]}
                  dropDownTitle='Transportation Cost'
                  animateDropDownTitle
                  customSetter={formik.setFieldValue}
                  customFormikLabel={"transportation_cost"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  disabled={activeInputs}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                />
                {formik.touched.transportation_cost &&
                  formik.errors.transportation_cost ? (
                  <span className='err'>{formik.errors.transportation_cost}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const SalesQuotationForm = ({ activeInputs, setActiveInputs, privileges, productsLoading, handleCancel, setPrimaryData }) => {
  const [focusedField, setFocusedField] = useState()
  const initialValues = {
    documentDate: "",
    PaymentTerm: "",
    purchase_officer: "",
    transportation_cost: "",
  }

  const singleComSchema = Yup.object({
    documentDate: Yup.string().required("Please enter document date"),
    PaymentTerm: Yup.string().required("Please enter payment term"),
    purchase_officer: Yup.string().required(
      "Please enter Purchase officer name",
    ),
    transportation_cost: Yup.string().required(
      "Transpotation cost is required",
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      setActiveInputs(false)

      //   const response = await dispatch(updateBasicDetails(data))
      //   if (response.payload) {
      //     setActiveInputs(false)
      //   }
    },
  })

  return (
    <form className="profile_personal_details" onSubmit={formik.handleSubmit}>
      <div className='details-controls'>
        <h2>Primary Document Details</h2>
        {Object.values(privileges["sales_management"]).includes(
          "write",
        ) && (
            <div className='detail-btn'>
              {activeInputs ? (
                <>
                  <button
                    type='submit'
                    // onClick={handleCancel}
                    className='btn-secondary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Cancel"
                    )}
                  </button>{" "}
                  <button
                    type='submit'
                    // onClick={formik.handleSubmit}
                    className='btn-primary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Save"
                    )}
                  </button>{" "}
                </>
              ) : (
                <button
                  onClick={() => setActiveInputs(true)}
                  className='btn-secondary'
                >
                  <span className='material-symbols-outlined small'>
                    border_color
                  </span>
                  Edit
                </button>
              )}
            </div>
          )}{" "}
      </div>
      <div className='product-detail-form'>
        <div className='form-content'>
          <div className='form-group form'>
            <div className='col-2'>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='documentDate' className='toggle-label'>
                  Document Date
                </label>
                <input
                  id='documentDate'
                  name='documentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.documentDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("documentDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.documentDate && formik.errors.documentDate ? (
                  <span className='err'>{formik.errors.documentDate}</span>
                ) : null}
              </div>

              <div
                className={`input-field ${formik.values.PaymentTerm !== "" ||
                  focusedField === "PaymentTerm"
                  ? "active"
                  : ""
                  }`}
              >
                <DropDownBox
                  options={[
                    { label: "Immediately", value: "immediately" },
                    { label: "Net 30", value: "net-30" },
                    { label: "Net 60", value: "net-60" },
                    { label: "Net 90", value: "net-90" },
                  ]}
                  dropDownTitle='Payment Term'
                  animateDropDownTitle
                  customSetter={formik.setFieldValue}
                  customFormikLabel={"PaymentTerm"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  disabled={activeInputs}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                />
                {formik.touched.PaymentTerm && formik.errors.PaymentTerm ? (
                  <span className='err'>{formik.errors.PaymentTerm}</span>
                ) : null}
              </div>

              <div
                className={`input-field ${formik.values.purchase_officer !== "" ||
                  focusedField === "purchase_officer"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='purchase_officer' className='toggle-label'>
                  Purchase Officer
                </label>
                <input
                  id='purchase_officer'
                  name='purchase_officer'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.purchase_officer}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("purchase_officer")}
                  disabled={!activeInputs}
                />
                {formik.touched.purchase_officer &&
                  formik.errors.purchase_officer ? (
                  <span className='err'>{formik.errors.purchase_officer}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.transportation_cost !== "" ||
                  focusedField === "transportation_cost"
                  ? "active"
                  : ""
                  }`}
              >
                <DropDownBox
                  options={[
                    { label: "Free", value: "free" },
                    { label: "To pay", value: "to-pay" },
                    { label: "In invoice", value: "in-invoice" },
                  ]}
                  dropDownTitle='Transportation Cost'
                  animateDropDownTitle
                  customSetter={formik.setFieldValue}
                  customFormikLabel={"transportation_cost"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  disabled={activeInputs}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                />
                {formik.touched.transportation_cost &&
                  formik.errors.transportation_cost ? (
                  <span className='err'>{formik.errors.transportation_cost}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const SalesReturnForm = ({ activeInputs, setActiveInputs, privileges, productsLoading, handleCancel, setPrimaryData }) => {
  const [focusedField, setFocusedField] = useState()
  const initialValues = {
    documentDate: "",
    deliveryDate: "",
    SO_date: "",
    SO_number: "",
  }

  const singleComSchema = Yup.object({
    documentDate: Yup.string().required("Please enter document date"),
    deliveryDate: Yup.string().required("Please enter delivery date"),
    SO_number: Yup.string().required("Purchase order number is required"),
    SO_date: Yup.string().required("Please enter payment date"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      setActiveInputs(false)

      //   const response = await dispatch(updateBasicDetails(data))
      //   if (response.payload) {
      //     setActiveInputs(false)
      //   }
    },
  })

  return (
    <form className="profile_personal_details" onSubmit={formik.handleSubmit}>
      <div className='details-controls'>
        <h2>Primary Document Details</h2>
        {Object.values(privileges["sales_management"]).includes(
          "write",
        ) && (
            <div className='detail-btn'>
              {activeInputs ? (
                <>
                  <button
                    type='submit'
                    // onClick={handleCancel}
                    className='btn-secondary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Cancel"
                    )}
                  </button>{" "}
                  <button
                    type='submit'
                    // onClick={formik.handleSubmit}
                    className='btn-primary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Save"
                    )}
                  </button>{" "}
                </>
              ) : (
                <button
                  onClick={() => setActiveInputs(true)}
                  className='btn-secondary'
                >
                  <span className='material-symbols-outlined small'>
                    border_color
                  </span>
                  Edit
                </button>
              )}
            </div>
          )}{" "}
      </div>
      <div className='product-detail-form'>
        <div className='form-content'>
          <div className='form-group form'>
            <div className='col-2'>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='documentDate' className='toggle-label'>
                  Document Date
                </label>
                <input
                  id='documentDate'
                  name='documentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.documentDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("documentDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.documentDate && formik.errors.documentDate ? (
                  <span className='err'>{formik.errors.documentDate}</span>
                ) : null}
              </div>

              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='deliveryDate' className='toggle-label'>
                  Delivery Date
                </label>
                <input
                  id='deliveryDate'
                  name='deliveryDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.deliveryDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("deliveryDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.deliveryDate && formik.errors.deliveryDate ? (
                  <span className='err'>{formik.errors.deliveryDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.SO_number !== "" || focusedField === "SO_number"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='SO_number' className='toggle-label'>
                  SO number
                </label>
                <input
                  id='SO_number'
                  name='SO_number'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.SO_number}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("SO_number")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.SO_number && formik.errors.SO_number ? (
                  <span className='err'>{formik.errors.SO_number}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='SO_date' className='toggle-label'>
                  SO Date
                </label>
                <input
                  id='SO_date'
                  name='SO_date'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.SO_date}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("SO_date")}
                  disabled={!activeInputs}
                />
                {formik.touched.SO_date && formik.errors.SO_date ? (
                  <span className='err'>{formik.errors.SO_date}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const SalesProformaForm = ({ activeInputs, setActiveInputs, privileges, productsLoading, handleCancel, setPrimaryData }) => {
  const [focusedField, setFocusedField] = useState()
  const initialValues = {
    title: "",
    documentDate: "",
    deliveryDate: "",
    PaymentTerm: "",
    paymentDate: "",
    SO_number: "",
    SO_date: "",
    purchase_officer: "",
    // transportation_cost: "",
  }

  const singleComSchema = Yup.object({
    title: Yup.string().required("Please enter document number"),
    documentDate: Yup.string().required("Please enter document date"),
    deliveryDate: Yup.string().required("Please enter delivery date"),
    SO_date: Yup.string().required("Please enter sales order date"),
    PaymentTerm: Yup.string().required("Please enter payment term"),
    SO_number: Yup.string().required("Purchase order number is required"),
    paymentDate: Yup.string().required("Please enter payment date"),
    purchase_officer: Yup.string().required(
      "Please enter Purchase officer name",
    ),
    transportation_cost: Yup.string().required(
      "Transpotation cost is required",
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      setActiveInputs(false)

      //   const response = await dispatch(updateBasicDetails(data))
      //   if (response.payload) {
      //     setActiveInputs(false)
      //   }
    },
  })

  return (
    <form className="profile_personal_details" onSubmit={formik.handleSubmit}>
      <div className='details-controls'>
        <h2>Primary Document Details</h2>
        {Object.values(privileges["sales_management"]).includes(
          "write",
        ) && (
            <div className='detail-btn'>
              {activeInputs ? (
                <>
                  <button
                    type='submit'
                    // onClick={handleCancel}
                    className='btn-secondary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Cancel"
                    )}
                  </button>{" "}
                  <button
                    type='submit'
                    // onClick={formik.handleSubmit}
                    className='btn-primary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Save"
                    )}
                  </button>{" "}
                </>
              ) : (
                <button
                  onClick={() => setActiveInputs(true)}
                  className='btn-secondary'
                >
                  <span className='material-symbols-outlined small'>
                    border_color
                  </span>
                  Edit
                </button>
              )}
            </div>
          )}{" "}
      </div>
      <div className='product-detail-form'>
        <div className='form-content'>
          <div className='form-group form'>
            <div className='col-2'>
              <div
                className={`input-field ${formik.values.title !== "" || focusedField === "title"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='title' className='toggle-label'>
                  Title
                </label>
                <input
                  id='title'
                  name='title'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("title")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.title && formik.errors.title ? (
                  <span className='err'>{formik.errors.title}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.SO_number !== "" || focusedField === "SO_number"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='SO_number' className='toggle-label'>
                  SO number
                </label>
                <input
                  id='SO_number'
                  name='SO_number'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.SO_number}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("SO_number")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.SO_number && formik.errors.SO_number ? (
                  <span className='err'>{formik.errors.SO_number}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='SO_date' className='toggle-label'>
                  SO Date
                </label>
                <input
                  id='SO_date'
                  name='SO_date'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.SO_date}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("SO_date")}
                  disabled={!activeInputs}
                />
                {formik.touched.SO_date && formik.errors.SO_date ? (
                  <span className='err'>{formik.errors.SO_date}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='documentDate' className='toggle-label'>
                  Document Date
                </label>
                <input
                  id='documentDate'
                  name='documentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.documentDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("documentDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.documentDate && formik.errors.documentDate ? (
                  <span className='err'>{formik.errors.documentDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='deliveryDate' className='toggle-label'>
                  Delivery Date
                </label>
                <input
                  id='deliveryDate'
                  name='deliveryDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.deliveryDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("deliveryDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.deliveryDate && formik.errors.deliveryDate ? (
                  <span className='err'>{formik.errors.deliveryDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.PaymentTerm !== "" ||
                  focusedField === "PaymentTerm"
                  ? "active"
                  : ""
                  }`}
              >
                <DropDownBox
                  options={[
                    { label: "Immediately", value: "immediately" },
                    { label: "Net 30", value: "net-30" },
                    { label: "Net 60", value: "net-60" },
                    { label: "Net 90", value: "net-90" },
                  ]}
                  dropDownTitle='Payment Term'
                  animateDropDownTitle
                  customSetter={formik.setFieldValue}
                  customFormikLabel={"PaymentTerm"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  disabled={!activeInputs}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                />
                {formik.touched.PaymentTerm && formik.errors.PaymentTerm ? (
                  <span className='err'>{formik.errors.PaymentTerm}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='paymentDate' className='toggle-label'>
                  Payment Date
                </label>
                <input
                  id='paymentDate'
                  name='paymentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.paymentDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("paymentDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.paymentDate && formik.errors.paymentDate ? (
                  <span className='err'>{formik.errors.paymentDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.purchase_officer !== "" ||
                  focusedField === "purchase_officer"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='purchase_officer' className='toggle-label'>
                  Purchase Officer
                </label>
                <input
                  id='purchase_officer'
                  name='purchase_officer'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.purchase_officer}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("purchase_officer")}
                  disabled={!activeInputs}
                />
                {formik.touched.purchase_officer &&
                  formik.errors.purchase_officer ? (
                  <span className='err'>{formik.errors.purchase_officer}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const DeliveryChallanForm = ({ activeInputs, setActiveInputs, privileges, productsLoading, handleCancel, setPrimaryData }) => {
  const [focusedField, setFocusedField] = useState()
  const initialValues = {
    title: "",
    documentDate: "",
    deliveryDate: "",
    PaymentTerm: "",
    paymentDate: "",
    SO_number: "",
    purchase_officer: "",
    transportation_cost: "",
  }

  const singleComSchema = Yup.object({
    title: Yup.string().required("Please enter document number"),
    documentDate: Yup.string().required("Please enter document date"),
    deliveryDate: Yup.string().required("Please enter delivery date"),
    PaymentTerm: Yup.string().required("Please enter payment term"),
    SO_number: Yup.string().required("Purchase order number is required"),
    paymentDate: Yup.string().required("Please enter payment date"),
    purchase_officer: Yup.string().required(
      "Please enter Purchase officer name",
    ),
    transportation_cost: Yup.string().required(
      "Transpotation cost is required",
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      setPrimaryData(formik.values)
      setActiveInputs(false)

      //   const response = await dispatch(updateBasicDetails(data))
      //   if (response.payload) {
      //     setActiveInputs(false)
      //   }
    },
  })

  return (
    <form className="profile_personal_details" onSubmit={formik.handleSubmit}>
      <div className='details-controls'>
        <h2>Primary Document Details</h2>
        {Object.values(privileges["sales_management"]).includes(
          "write",
        ) && (
            <div className='detail-btn'>
              {activeInputs ? (
                <>
                  <button
                    type='submit'
                    // onClick={handleCancel}
                    className='btn-secondary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Cancel"
                    )}
                  </button>{" "}
                  <button
                    type='submit'
                    // onClick={formik.handleSubmit}
                    className='btn-primary'
                    disabled={productsLoading}
                    style={productsLoading ? { cursor: "not-allowed" } : {}}
                  >
                    {productsLoading ? (
                      <ClipLoader
                        loading={true}
                        size={15}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                        color='white'
                      />
                    ) : (
                      "Save"
                    )}
                  </button>{" "}
                </>
              ) : (
                <button
                  onClick={() => setActiveInputs(true)}
                  className='btn-secondary'
                >
                  <span className='material-symbols-outlined small'>
                    border_color
                  </span>
                  Edit
                </button>
              )}
            </div>
          )}{" "}
      </div>
      <div className='product-detail-form' >
        <div className='form-content'>
          <div className='form-group form'>
            <div className='col-2'>
              <div
                className={`input-field ${formik.values.title !== "" || focusedField === "title"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='title' className='toggle-label'>
                  Title
                </label>
                <input
                  id='title'
                  name='title'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("title")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.title && formik.errors.title ? (
                  <span className='err'>{formik.errors.title}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.SO_number !== "" || focusedField === "SO_number"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='SO_number' className='toggle-label'>
                  SO number
                </label>
                <input
                  id='SO_number'
                  name='SO_number'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.SO_number}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("SO_number")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.SO_number && formik.errors.SO_number ? (
                  <span className='err'>{formik.errors.SO_number}</span>
                ) : null}
              </div>

              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='documentDate' className='toggle-label'>
                  Document Date
                </label>
                <input
                  id='documentDate'
                  name='documentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.documentDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("documentDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.documentDate && formik.errors.documentDate ? (
                  <span className='err'>{formik.errors.documentDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='deliveryDate' className='toggle-label'>
                  Delivery Date
                </label>
                <input
                  id='deliveryDate'
                  name='deliveryDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.deliveryDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("deliveryDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.deliveryDate && formik.errors.deliveryDate ? (
                  <span className='err'>{formik.errors.deliveryDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.PaymentTerm !== "" ||
                  focusedField === "PaymentTerm"
                  ? "active"
                  : ""
                  }`}
              >
                <DropDownBox
                  options={[
                    { label: "Immediately", value: "immediately" },
                    { label: "Net 30", value: "net-30" },
                    { label: "Net 60", value: "net-60" },
                    { label: "Net 90", value: "net-90" },
                  ]}
                  dropDownTitle='Payment Term'
                  animateDropDownTitle
                  customSetter={formik.setFieldValue}
                  customFormikLabel={"PaymentTerm"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  disabled={activeInputs}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                />
                {formik.touched.PaymentTerm && formik.errors.PaymentTerm ? (
                  <span className='err'>{formik.errors.PaymentTerm}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
              }`}
              >
                <label htmlFor='paymentDate' className='toggle-label'>
                  Payment Date
                </label>
                <input
                  id='paymentDate'
                  name='paymentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.paymentDate}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("paymentDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.paymentDate && formik.errors.paymentDate ? (
                  <span className='err'>{formik.errors.paymentDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.purchase_officer !== "" ||
                  focusedField === "purchase_officer"
                  ? "active"
                  : ""
                  }`}
              >
                <label htmlFor='purchase_officer' className='toggle-label'>
                  Purchase Officer
                </label>
                <input
                  id='purchase_officer'
                  name='purchase_officer'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.purchase_officer}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /(?:^|\s)\S/g,
                      match => match.toUpperCase(),
                    )
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("purchase_officer")}
                  disabled={!activeInputs}
                />
                {formik.touched.purchase_officer &&
                  formik.errors.purchase_officer ? (
                  <span className='err'>{formik.errors.purchase_officer}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${formik.values.transportation_cost !== "" ||
                  focusedField === "transportation_cost"
                  ? "active"
                  : ""
                  }`}
              >
                <DropDownBox
                  options={[
                    { label: "Free", value: "free" },
                    { label: "To pay", value: "to-pay" },
                    { label: "In invoice", value: "in-invoice" },
                  ]}
                  dropDownTitle='Transportation Cost'
                  animateDropDownTitle
                  customSetter={formik.setFieldValue}
                  customFormikLabel={"transportation_cost"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  disabled={activeInputs}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                />
                {formik.touched.transportation_cost &&
                  formik.errors.transportation_cost ? (
                  <span className='err'>{formik.errors.transportation_cost}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
