/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import Sidebar from "../../../components/Sidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import Breadcrumb from "../../../components/BreadCrumbs"
import { toast } from "react-toastify"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  changePassword,
  changeProfilePicture,
  getProfileDetails,
  updateProfile,
} from "../redux/profileApi"
import DropDownBox from "../../../components/DropDownBox"
import {
  convertToWebP,
  unixTimeToYYYYMMDD,
  yyyyMMDDToISO,
} from "../../../helper/helper"
import Loader from "../../../components/Loader"
import TempProfile from "../../../assets/images/user(1).webp"
import ImagePreviewBox from "../../../components/ImagePreviewBox"
import EditFormButtons from "../../../components/EditFormBtns"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"

const Profile = ({ setTitle }) => {
  const dispatch = useDispatch()
  const { profileDetail, profileLoading } = useSelector(store => store.profile)
  const { companyDetails } = useSelector(store => store.company)
  useEffect(() => {
    if (Object.keys(profileDetail).length === 0) dispatch(getProfileDetails())
    setTitle("Profile")
  }, [setTitle, dispatch, profileDetail])

  const [active, setActive] = useState("profile")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [activeInputs, setActiveInputs] = useState(false)
  const [profilePic, setProfilePic] = useState(null)
  const [focusedField, setFocusedField] = useState()
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [reset, setReset] = useState(false)

  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const profileSchema = Yup.object({
    fname: Yup.string()
      .trim()
      .min(3, "First Name must be at least 3 characters long")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .max(80, "First name must be less than 80 characters")
      .required("First name is required"),
    lname: Yup.string()
      .trim()
      .min(3, "Last Name must be at least 3 characters long")
      .max(80, "Last name must be less than 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Last name is required"),
    email: Yup.string()
      .trim()
      .email("Please provide valid email")
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please enter a valid email")
      .required("Email address should not be empty"),
    phone: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
      .required("Phone number is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date()
      .required("Birth date is required")
      .test("age", "You must be 18 years old or above", value => {
        const today = new Date() // Define 'today' as the current date
        const birthDate = new Date(value)
        const ageDifferenceInMilliseconds = today - birthDate
        const ageInYears =
          ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000) // Approximate calculation
        return ageInYears >= 18
      })
      .test("age two", "User's age must be less than 100 years", value => {
        if (new Date(value).getFullYear() >= new Date().getFullYear() - 100) {
          return true
        } else {
          return false
        }
      }),
  })

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      gender: "",
      dob: "",
    },
    validationSchema: profileSchema,
    onSubmit: async values => {
      const data = {
        first_name: values.fname,
        last_name: values.lname,
        gender: values.gender,
        // email: values.email,
        phone: `+91${values.phone}`,
        date_of_birth: yyyyMMDDToISO(values.dob),
      }
      const response = await dispatch(updateProfile(data))
      if (response?.payload) {
        setActiveInputs(false)
      }
    },
  })

  const handleProfilePic = async e => {
    const selectedImg = e.target.files[0]

    if (
      selectedImg.type === "image/jpeg" ||
      selectedImg.type === "image/webp" ||
      selectedImg.type === "image/png" ||
      selectedImg.type === "image/jpg"
    ) {
      if (selectedImg.size > 5 * 1024 * 1024) {
        toast.warning(
          "File size exceeds 5MB limit. Please select a smaller file.",
        )
        e.target.value = null
      } else {
        setProfilePic(URL.createObjectURL(selectedImg))
        const convertedImg = await convertToWebP(selectedImg)
        e.target.value = null
        dispatch(changeProfilePicture({ profile_picture: convertedImg }))
      }
    } else {
      toast.warning("accepts .jpeg, .jpg, .png and .webp files only")
      e.target.value = null
    }
  }

  // const handleProfileClick = () => {
  //   document.getElementsByClassName("profile-pic-input")[0].click();
  // };

  useEffect(() => {
    formik.setValues({
      fname: profileDetail?.fname,
      lname: profileDetail?.lname,
      phone: profileDetail?.phone?.value.slice(-10),
      email: profileDetail?.email?.value,
      gender: profileDetail?.gender,
      dob: unixTimeToYYYYMMDD(profileDetail?.dob),
    })

    setProfilePic(
      profileDetail?.profilePicture
        ? profileDetail?.profilePicture
        : TempProfile,
    )
  }, [profileDetail])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  const handleCancel = () => {
    formik.resetForm()
    setActiveInputs(false)
    formik.setValues({
      fname: profileDetail?.fname,
      lname: profileDetail?.lname,
      phone: profileDetail?.phone?.value.slice(-10),
      email: profileDetail?.email?.value,
      gender: profileDetail?.gender,
      dob: unixTimeToYYYYMMDD(profileDetail?.dob),
    })
    setReset(true)
  }
  const { tableLoading: isProfileLoading } = useLoaderDelay({
    loadingState: profileLoading,
  })

  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        {showPreview && (
          <ImagePreviewBox
            customSetter={setShowPreview}
            imageChangeApi={changeProfilePicture}
            customState={showPreview}
            incomingImage={profilePic}
          />
        )}

        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}

          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          {/* 
        {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Profile</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                    {/* Profile / User Info */}
                  </div>
                </div>
                {/* <div className="mch2">
                  <div className="mch2-duration">
                    August 1,2020 - August 31,2020
                  </div>
                  <div
                    className="mch2-action"
                    onClick={() => setAction(!action)}
                  >
                    <span className="">Action</span>
                    <span className="material-symbols-rounded expand">
                      expand_more
                    </span>
                    {action && (
                      <div className="action-tooltip">
                        <span className="">Download</span>
                        <span className="">Print</span>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
              <div className='profile-header'>
                <div className='header-content'>
                  <div className='profile-details'>
                    <label
                      htmlFor='choose_photo'
                      className='updtIMG showCameraIcon'
                      onClick={() => setShowPreview(true)}
                    >
                      <span className='material-symbols-rounded updatelogo'>
                        add_a_photo
                      </span>
                      <img
                        src={
                          profilePic
                          // ? profilePic
                          // : require("../../../assets/images/user(1).webp")
                        }
                        alt='profile'
                        className='profile_user-pic'
                      />
                      {/* <input
                        id='choose_photo'
                        hidden={true}
                        type='file'
                        accept='.png,.jpeg,.jpg,.webp'
                        className='company-pic-input'
                        onChange={handleProfilePic}
                      /> */}
                    </label>
                    {/* <img
                      src={
                        profilePic
                          ? profilePic
                          : require("../../../assets/images/unsplash_jFAG_ixCrsM-_1_.webp")
                      }
                      alt="profile"
                      className="profile_user-pic"
                    /> */}
                    <div>
                      {/* <span className='profile-name'>
                        {profileDetail
                          ? `${profileDetail?.fname} ${profileDetail?.lname}`
                          : "User Info"}
                      </span> */}
                      <h2 className='profile-name'>
                        {profileDetail
                          ? `${profileDetail?.fname} ${profileDetail?.lname}`
                          : "User Info"}
                      </h2>
                      <span className='profile-email'>
                        {profileDetail
                          ? profileDetail?.email?.value
                          : "admin1@erp.com"}
                      </span>
                      {/* <span className='profile-address'>
                        <span className='material-symbols-outlined medium'>
                          location_on
                        </span>
                        {companyDetails.cityName},{" "}{companyDetails.stateName}.
                      </span> */}
                    </div>
                  </div>
                  {/* <div className="profile-pic-btn">
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.webp"
                      className="profile-pic-input"
                      onChange={handleProfilePic}
                    />
                    <button
                      className="change-image-btn"
                      onClick={handleProfileClick}
                    >
                      {" "}
                      Change Profile
                    </button>
                  </div> */}
                </div>
              </div>
              <div className='profile_form_box'>
                <div className='profile_personal_details'>
                  <div className='form-content'>
                    <form>
                      <div className='details-controls'>
                        <h2>Personal Information</h2>
                        {/* <div className='detail-btn'>
                      {activeInputs ? (
                        <button
                          type='submit'
                          onClick={formik.handleSubmit}
                          className='btn-primary'
                          disabled={profileLoading}
                          style={
                            profileLoading ? { cursor: "not-allowed" } : {}
                          }
                        >
                          {profileLoading ? (
                            <ClipLoader
                              loading={true}
                              size={13}
                              aria-label='Loading Spinner'
                              data-testid='loader'
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                      ) : (
                        <button
                          onClick={() => setActiveInputs(true)}
                          className='btn-secondary'
                        >
                          <span className='material-symbols-outlined small'>
                            border_color
                          </span>
                          Edit
                        </button>
                      )}
                    </div> */}
                        <EditFormButtons
                          loading={profileLoading}
                          activeInputs={activeInputs}
                          handleSubmit={formik.handleSubmit}
                          handleCancel={handleCancel}
                          handleInputs={setActiveInputs}
                        />
                      </div>
                      <div className='form-group form'>
                        <div
                          className={`input-field f-contents ${
                            formik.values.fname !== "" ||
                            focusedField === "fname"
                              ? "active"
                              : ""
                          }`}
                        >
                          <label htmlFor='fname' className='toggle-label'>
                            First Name
                          </label>
                          <input
                            type='text'
                            name='fname'
                            id='fname'
                            className='form-input'
                            value={formik.values.fname || ""}
                            onChange={formik.handleChange}
                            disabled={!activeInputs}
                            onInput={e => {
                              e.target.value = e.target.value
                                .replace(/[^A-Za-z ]/gi, "")
                                .replace(/(?:^|\s)\S/g, match =>
                                  match.toUpperCase(),
                                )
                            }}
                            maxLength={81}
                            onKeyDown={e => {
                              if (e.key === " ") {
                                e.preventDefault()
                              }
                            }}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField("fname")}
                          />
                          {formik.errors.fname && formik.touched.fname ? (
                            <span className='err'>{formik.errors.fname}</span>
                          ) : null}
                        </div>
                        <div
                          className={`input-field f-contents ${
                            formik.values.lname !== "" ||
                            focusedField === "lname"
                              ? "active"
                              : ""
                          }`}
                        >
                          <label htmlFor='lname' className='toggle-label'>
                            Last Name
                          </label>
                          <input
                            type='text'
                            name='lname'
                            id='lname'
                            maxLength={81}
                            className='form-input'
                            value={formik.values.lname || ""}
                            onChange={formik.handleChange}
                            onInput={e => {
                              e.target.value = e.target.value
                                .replace(/[^A-Za-z ]/gi, "")
                                .replace(/(?:^|\s)\S/g, match =>
                                  match.toUpperCase(),
                                )
                            }}
                            disabled={!activeInputs}
                            onKeyDown={e => {
                              if (e.key === " ") {
                                e.preventDefault()
                              }
                            }}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField("lname")}
                          />{" "}
                          {formik.errors.lname && formik.touched.lname ? (
                            <span className='err'>{formik.errors.lname}</span>
                          ) : null}
                        </div>
                        <div
                          className={`input-field f-contents ${
                            formik.values.phone !== "" ||
                            focusedField === "phone"
                              ? "active"
                              : ""
                          }`}
                        >
                          <label htmlFor='phone' className='toggle-label'>
                            Phone
                          </label>
                          <div
                            className={formik.values.phone ? "phone_box" : ""}
                          >
                            {
                              <span className='phone_code'>
                                {formik.values.phone ? "+91" : ""}
                              </span>
                            }
                            <input
                              type='text'
                              name='phone'
                              id='phone'
                              className='form-input'
                              value={formik.values.phone || ""}
                              onChange={formik.handleChange}
                              disabled={!activeInputs}
                              onInput={e => {
                                e.target.value = e.target.value
                                  .slice(0, 10)
                                  .replace(/\D/g, "")
                              }}
                              onKeyDown={e => {
                                if (e.key === " ") {
                                  e.preventDefault()
                                }
                              }}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("phone")}
                            ></input>
                          </div>{" "}
                          {formik.errors.phone && formik.touched.phone ? (
                            <span className='err'>{formik.errors.phone}</span>
                          ) : null}
                        </div>{" "}
                        <div
                          className={`input-field f-contents ${
                            formik.values.email !== "" ||
                            focusedField === "email"
                              ? "active"
                              : ""
                          }`}
                        >
                          <label htmlFor='email' className='toggle-label'>
                            Email
                          </label>
                          <input
                            type='email'
                            name='email'
                            id='email'
                            className='form-input'
                            value={formik.values.email || ""}
                            onChange={formik.handleChange}
                            disabled={true}
                            onKeyDown={e => {
                              if (e.key === " ") {
                                e.preventDefault()
                              }
                            }}
                            onBlur={e => {
                              setFocusedField("")
                              formik.handleBlur(e)
                            }}
                            onFocus={() => setFocusedField("email")}
                          ></input>
                          {formik.errors.email && formik.touched.email ? (
                            <span className='err'>{formik.errors.email}</span>
                          ) : null}
                        </div>
                        <div className='col-2'>
                          <div className='f-contents'>
                            <DropDownBox
                              options={[
                                { label: "Male", value: "male" },
                                { label: "Female", value: "female" },
                                { label: "Other", value: "other" },
                              ]}
                              // defaultValue="Select Gender"
                              dropDownTitle={"Gender"}
                              animateDropDownTitle
                              customSetter={formik.setFieldValue}
                              customFormikLabel={"gender"}
                              customDropBoxStyles={{
                                border: "1px solid #e6e6e6",
                                borderRadius: "0.5rem",
                                color: "rgba(102, 102, 102, 1)",
                              }}
                              customTextStyle={{
                                color: "#212229",
                                opacity: "0.7",
                              }}
                              disabled={activeInputs}
                              incomingValue={profileDetail.gender}
                            />
                            {formik.errors.gender && formik.touched.gender ? (
                              <span className='err'>
                                {formik.errors.gender}
                              </span>
                            ) : null}
                          </div>
                          <div
                            className={`input-field f-contents ${
                              formik.values.dob !== "" || focusedField === "dob"
                                ? "active"
                                : ""
                            }`}
                          >
                            {/* <label htmlFor="dob" className="toggle-label">
                          Date of Birth
                        </label> */}
                            <input
                              type='date'
                              name='dob'
                              id='dob'
                              className='form-input'
                              value={formik.values.dob}
                              onChange={formik.handleChange}
                              onBlur={e => {
                                setFocusedField("")
                                formik.handleBlur(e)
                              }}
                              onFocus={() => setFocusedField("dob")}
                              disabled={!activeInputs}
                              max='9999-12-31'
                            />{" "}
                            {formik.errors.dob && formik.touched.dob ? (
                              <span className='err'>{formik.errors.dob}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <div className="f-contents">
                        <div className="form-control">
                          <div className="input-fields"></div>
                        </div>
                      </div> */}

                      {/* <div className="f-contents">
                        <div className="form-control">
                          <div className="input-fields">
                           
                          </div>
                        </div>
                 
                      </div> */}

                      <div className='f-contents'>
                        {/* <div className="form-control">
                          <div className="input-fields"></div>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>

                <ProfilePasswordForm />
              </div>
            </div>
            <Footer />
          </div>
          <Loader isLoading={isProfileLoading} />
        </div>
      </div>
    </div>
  )
}

export default Profile

const ProfilePasswordForm = () => {
  const [activeInputs, setActiveInputs] = useState(false)
  const [focusedField, setFocusedField] = useState()
  const { profileLoading } = useSelector(store => store.profile)
  const [visibility, setVisibility] = useState({
    current: false,
    new: false,
    confirm: false,
  })
  const dispatch = useDispatch()
  const profileSchema = Yup.object({
    current_password: Yup.string()
      .required("Current password is required")
      .trim()
      .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/,
        "Password should contains at least 1 digit, 1 uppercase & 1 lowercase,1 special character,length between 8 to 20",
      ),
    password: Yup.string()
      .trim()
      .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/,
        "Password should contains at least 1 digit, 1 uppercase & 1 lowercase,1 special character,length between 8 to 20",
      )
      .required("Password is required"),
    retype_password: Yup.string()
      .oneOf([Yup.ref("password")], "Confirm Password does not match")
      .required("Confirm Password is required"),
  })

  const formik = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      retype_password: "",
    },
    validationSchema: profileSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        old_password: values.current_password,
        new_password: values.password,
        new_password_confirmation: values.retype_password,
      }

      const response = await dispatch(changePassword(data))
      if (response?.payload) {
        setActiveInputs(false)
        resetForm()
      }
    },
  })
  const handleVisibility = fieldName => {
    setVisibility({
      ...visibility,
      [fieldName]: !visibility[fieldName],
    })
  }
  const handleCancel = () => {
    setActiveInputs(false)
    formik.resetForm()
  }
  return (
    <div className='profile_personal_details'>
      <div className='details-controls'>
        <h2>Change Password</h2>
        {/* <div className='detail-btn'>
          {activeInputs ? (
            <button
              type='submit'
              onClick={formik.handleSubmit}
              className='btn-primary'
              disabled={profileLoading}
              style={profileLoading ? { cursor: "not-allowed" } : {}}
            >
              {profileLoading ? (
                <ClipLoader
                  loading={true}
                  size={13}
                  aria-label='Loading Spinner'
                  data-testid='loader'
                />
              ) : (
                "Save"
              )}
            </button>
          ) : (
            <button
              onClick={() => setActiveInputs(true)}
              className='btn-secondary'
            >
              <span className='material-symbols-outlined small'>
                border_color
              </span>
              Edit
            </button>
          )}
        </div> */}
        <EditFormButtons
          loading={profileLoading}
          activeInputs={activeInputs}
          handleSubmit={formik.handleSubmit}
          handleCancel={handleCancel}
          handleInputs={setActiveInputs}
        />
      </div>

      <div className='form-content'>
        <form className='form'>
          <div className='form-group form '>
            <div className='form-control '>
              <div
                className={`input-field f-contents ${
                  formik.values.current_password !== "" ||
                  focusedField === "current_password"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='company_name' className='toggle-label'>
                  Current Password
                </label>
                <input
                  type={visibility.current ? "text" : "password"}
                  name='current_password'
                  id='current_password'
                  className='form-input'
                  value={formik.values.current_password}
                  onChange={formik.handleChange}
                  disabled={!activeInputs}
                  maxLength={20}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  onPaste={e => {
                    e.preventDefault()
                  }}
                  onCopy={e => {
                    e.preventDefault()
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("current_password")}
                />
                <span
                  className='material-symbols-rounded medium visiblity'
                  onClick={() => {
                    if (activeInputs) handleVisibility("current")
                  }}
                >
                  {!visibility.current ? "visibility_off" : "visibility"}
                </span>
              </div>
              {formik.errors.current_password &&
              formik.touched.current_password ? (
                <span className='err'>{formik.errors.current_password}</span>
              ) : null}
            </div>
            <div className='form-control'>
              <div
                className={`input-field f-contents ${
                  formik.values.password !== "" || focusedField === "password"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='company_name' className='toggle-label'>
                  New Password
                </label>
                <input
                  type={visibility.new ? "text" : "password"}
                  name='password'
                  id='password'
                  className='form-input'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  disabled={!activeInputs}
                  maxLength={20}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  onPaste={e => {
                    e.preventDefault()
                  }}
                  onCopy={e => {
                    e.preventDefault()
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("password")}
                />{" "}
                <span
                  className='material-symbols-rounded medium visiblity'
                  onClick={() => {
                    if (activeInputs) handleVisibility("new")
                  }}
                >
                  {!visibility.new ? "visibility_off" : "visibility"}
                </span>
              </div>
              {formik.errors.password && formik.touched.password ? (
                <span className='err'>{formik.errors.password}</span>
              ) : null}
            </div>{" "}
            <div className='form-control'>
              <div
                className={`input-field f-contents ${
                  formik.values.retype_password !== "" ||
                  focusedField === "retype_password"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='company_name' className='toggle-label'>
                  Confirm Password
                </label>
                <input
                  type={visibility.confirm ? "text" : "password"}
                  name='retype_password'
                  id='retype_password'
                  className='form-input'
                  value={formik.values.retype_password}
                  onChange={formik.handleChange}
                  disabled={!activeInputs}
                  maxLength={20}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  onPaste={e => {
                    e.preventDefault()
                  }}
                  onCopy={e => {
                    e.preventDefault()
                  }}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("retype_password")}
                />{" "}
                <span
                  className='material-symbols-rounded medium visiblity'
                  onClick={() => {
                    if (activeInputs) handleVisibility("confirm")
                  }}
                >
                  {!visibility?.confirm ? "visibility_off" : "visibility"}
                </span>
              </div>{" "}
              {formik.errors.retype_password &&
              formik.touched.retype_password ? (
                <span className='err'>{formik.errors.retype_password}</span>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
