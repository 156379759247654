/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { ClipLoader } from "react-spinners"
import { forgotPassword } from "../redux/authApi"

const ForgotPassword = ({ setTitle }) => {
  const [focusedField, setFocusField] = useState()
  const year = new Date().getFullYear()

  useEffect(() => {
    setTitle("Forgot Password")
  }, [setTitle])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { authLoading } = useSelector(state => state.auth, shallowEqual)

  const initialValues = {
    email: "",
  }
  const ForgotPasswordSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Please enter email address")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please enter a valid email!",
      )
      .required("Email address should not be empty")
      .matches(
        "@poonamcoatings.com",
        "Email must contain @poonamcoatings.com domain",
      ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        email: values.email,
      }
      const response = await dispatch(forgotPassword(data))
      // await dispatch(forgotPassword(data));
      if (response?.payload?.status) {
        navigate("/login")
      }
    },
  })

  return (
    <div className='container'>
      <div className='app-container'>
        <div className='auth-container'>
          <div className='auth-logo'>
            <Link to='/'>
              <img
                src={require("../../../assets/icons/logo.svg").default}
                className='poonam-coatings-logo'
                alt='poonam_coatings'
              />
            </Link>
          </div>
          <main className='auth-main flex-center'>
            <div className='form-box'>
              <header className='auth-heading text-center'>
                <h2 className='title'>Forgot your password ?</h2>
                <p className='description'>
                  Please enter the email address associated with your account
                  and We will send you a link to reset the password.
                </p>
              </header>
              <form
                className='auth-form'
                onSubmit={formik.handleSubmit}
                noValidate='novalidate'
              >
                <div className='form-control'>
                  <div
                    className={`form-input-box ${
                      formik.values.email !== "" || focusedField === "email"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='email' className='toggle-label'>
                      Email
                    </label>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      className='form-input'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={e => {
                        setFocusField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusField("email")}
                       
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                  {formik.errors.email && formik.touched.email ? (
                    <span className='err'>{formik.errors.email}</span>
                  ) : null}
                </div>

                {authLoading ? (
                  <button
                    className='btn-tertiary'
                    style={{ cursor: "not-allowed" }}
                    disabled
                  >
                    <ClipLoader
                      loading={true}
                      size={20}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                    />
                  </button>
                ) : (
                  <button type='submit' className='btn-tertiary'>
                    Continue
                  </button>
                )}
              </form>

              <div className='auth-footer text-center'>
                Back to{" "}
                <Link to='/login' className='link'>
                  Login
                </Link>
              </div>
            </div>

            <nav className='auth-nav flex-center'>
              <a href='https://poonamcoatings.com/about-us/' className='nl'>
                About Us
              </a>
              <a href='https://poonamcoatings.com/contact-us/' className='nl'>
                Contact Us
              </a>
              <a href='https://poonamcoatings.com/services/' className='nl'>
                Services
              </a>
            </nav>

            <footer className='auth_copyright'>
              Copyright © {year}{" "}
              <a
                href='https://poonamcoatings.com/'
                target='blank'
                className='footer_link'
              >
                Poonam Coatings.
              </a>{" "}
              All Rights Reserved
            </footer>
          </main>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
