import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"
import { AddNumField, options } from "../../../helper/helper"
import { isUserLoadingToggle } from "./userSlice"

export const getUserList = createAsyncThunk(
  "user/get-user-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/user-list/?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}`,
      })

      if (response.status === 200) {
        if (data.listName === "userList") {
          return rejectWithValue(response.data.data)
        }

        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data,
            data?.page * data?.limit,
          )
          return fulfillWithValue(res)
        } else {
          const modPage = Math.max(data.page - 1, 0)
          const responseAgain = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/user/user-list/?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data.search}`,
          })
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit,
            )
            return fulfillWithValue(resAgain)
          } else {
            toast.error(responseAgain?.data?.message, options)
            return rejectWithValue()
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue()
      }
    } catch (err) {
      return rejectWithValue()
    }
  },
)

export const addUserAsync = createAsyncThunk(
  "user/add-user",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isUserLoadingToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/add-user`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isUserLoadingToggle(false))
        toast.success(response?.data?.message, options)
        return response?.data?.status
      } else {
        dispatch(isUserLoadingToggle(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isUserLoadingToggle(false))
      toast.error(err, options)
    }
  },
)
export const manageUserAccountAsync = createAsyncThunk(
  "user/manage-user-account",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isUserLoadingToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/manage-account/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isUserLoadingToggle(false))
        dispatch(
          getUserList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            filter: data.filter,
          }),
        )
        toast.success(response?.data?.message, options)
        return response?.data?.status
      } else {
        dispatch(isUserLoadingToggle(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isUserLoadingToggle(false))
      toast.error(err, options)
    }
  },
)
export const deleteUserAccountAsync = createAsyncThunk(
  "user/delete-user-account",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isUserLoadingToggle(true))
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/delete-account`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isUserLoadingToggle(false))
        dispatch(
          getUserList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            filter: data.filter,
          }),
        )
        // toast.success(response?.data?.message, options);
        return response?.data?.status
      } else {
        dispatch(isUserLoadingToggle(false))
        // toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isUserLoadingToggle(false))
      toast.error(err, options)
    }
  },
)
export const getSingleUserDetail = createAsyncThunk(
  "user/get-single-user-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/user-detail/${data?.id}`,
      })
      if (response.status === 200) {
        return fulfillWithValue(response?.data)
      } else {
        toast.error(response?.data?.message, options)
        return rejectWithValue()
      }
    } catch (err) {
      toast.error(err, options)
      return rejectWithValue()
    }
  },
)
export const updateSingleUserDetail = createAsyncThunk(
  "user/update-user",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isUserLoadingToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/update-detail/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isUserLoadingToggle(false))
        toast.success(response?.data?.message, options)

        return response?.data?.status
      } else {
        dispatch(isUserLoadingToggle(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isUserLoadingToggle(false))
      toast.error(err, options)
    }
  },
)

export const userActivateDeactivate = createAsyncThunk(
  "user/activate-deactivate",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isUserLoadingToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/deactivate-account/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isUserLoadingToggle(false))
        // toast.success(response?.data?.message, options)

        return response?.data?.status
      } else {
        dispatch(isUserLoadingToggle(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isUserLoadingToggle(false))
      toast.error(err, options)
    }
  },
)

export const changeUserProfilePicture = createAsyncThunk(
  "user/change-profile-picture",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isUserLoadingToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/change-profilePicture/${data.id}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data?.send,
      })

      if (response.status === 200) {
        dispatch(getSingleUserDetail(data))
        dispatch(isUserLoadingToggle(false))
        toast.success(response?.data?.message)
        return response?.data?.status
      } else {
        dispatch(isUserLoadingToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isUserLoadingToggle(false))
      toast.error(err)
    }
  },
)
