import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSalesCompanyAddress = createAsyncThunk(
    "sales/get-company-address",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {

        try {
            let response = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/address/get-address`,
                data: data.send,
            })

            if (response?.status === 200) {
                const a = { data: response?.data?.data, gst: data.gst, status: 200 }
                return a
            }
        } catch (error) {
            rejectWithValue()
        }
    },
)