import { useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import ReactQuill from "react-quill"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import * as Yup from "yup"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
import { numberWithCommas } from "../../../helper/helper"
// import SelectCompanyPopup from "./SelectCompanyPopup"
const tempImage = require("../../../assets/icons/logo.svg").default

const SalesPreview = ({ setTitle }) => {
  const [active, setActive] = useState("sales_details")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  const [isEventCard, setIsEventCard] = useState(false)
  const [popUpData, setPopupData] = useState({})
  const [isSelectCompany, setIsSelectCompany] = useState(false)
  const [showApproval, setShowApproval] = useState(false)
  // const { emailDetails } = useSelector((store) => store.email);
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { productDetails } = useSelector(state => state.products, shallowEqual)

  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const location = useLocation()
  const { key, data } = location?.state

  useEffect(() => {
    setTitle(key)
  }, [setTitle])

  const {
    supplier: supplierAddress,
    buyer: workAddress,
    delivery: warehouseAddress,
  } = data?.addressDetails

  const [focusedField, setFocusedField] = useState()
  const { purchaseLoading } = useSelector(store => store.purchase)
  const initialValues = {
    productName: "",
    skuNum: "233",
    brand: "",
    category: "",
    color: "",
    packSize: "",
    uom: "",
    productCode: "",
    batchNumber: "",
  }

  const singleComSchema = Yup.object({
    skuNum: Yup.string().required("SKU number is required"),
    productName: Yup.string()
      .trim()
      .min(2, "Paint name must be at least 2 characters long")
      .max(80, "Name must be less than 80 characters")
      .required("Paint name is required"),
    brand: Yup.string().required("Brand name is required"),
    category: Yup.string().required("Category name is required"),
    color: Yup.string(),
    // .required("Color name is required"),
    packSize: Yup.string().required("Pack size is required"),
    uom: Yup.string().required("UOM is required"),
    productCode: Yup.string().min(1).max(12),
    // .required("Product code is required"),
    batchNumber: Yup.string().min(1).max(17),
    // .required("Batch number is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        id,
        send: {
          brand_id: values?.brand,
          category_id: values?.category,
          color_id: values?.color,
          product_weight: values?.packSize,
          // product_weight: "5",
          uom_id: values?.uom,
          name: values?.productName,
          product_code: values?.productCode,
          batch_number: values?.batchNumber,
        },
        details_type: "update-basicDetail",
      }
    },
  })
  const deleteFunction = title => {
    setPopupData({
      data: {
        is_delete: true,
        product_ids: [id],
      },
      title,
    })
    setIsEventCard(true)
  }

  useEffect(() => {
    formik.setValues({
      skuNum: productDetails?.basic_details?.sku,
      productName: productDetails?.basic_details?.name,
      brand: productDetails?.basic_details?.brandId,
      color: productDetails?.basic_details?.colorId,
      category: productDetails?.basic_details?.categoryId,
      packSize: productDetails?.basic_details?.pack_size?.weight.$numberDecimal,
      uom: productDetails?.basic_details?.pack_size?.uomID,
      productCode: productDetails?.basic_details?.productCode,
      batchNumber: productDetails?.basic_details?.batchNumber,
    })

    setTableData({
      tHeading: setDetailsTitle({
        quotation: [
          "Product Name",
          "HSN Code",
          "Pack Quantity",
          "No. of Packs",
          "Total Quantity",
          "Total Amount",
        ],
        order: [
          "Product Name",
          "HSN Code",
          "No. of Packs",
          "Pack Quantity",
          "Total Quantity",
          "Total Amount",
        ],
        invoice: [
          "Product Name",
          "HSN Code",
          "Batch No.",
          "Pack Quantity",
          "No. of Packs",
          "Total Quantity",
          "Total Amount",
        ],
        sReturn: [
          "Product Name",
          "HSN Code",
          "Pack Quantity",
          "No. of Packs",
          "Return Qty.",
          "Total Return Qty.",
          "Total Amount",
        ],
        challan: ["Product Name", "HSN Code", "Batch No.", "Total Quantity"],
      }),
      tData: setDetailsTitle({
        quotation: [
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            noOfPack: "10",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            noOfPack: "10",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            noOfPack: "10",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
        ],
        order: [
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            noOfPack: "10",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            noOfPack: "10",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            noOfPack: "10",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
        ],
        invoice: [
          {
            productName: "Raw Material 657",
            hsn: "1121",
            pack: "19.95 Lt",
            batch: "B352  ",
            noOfPack: "10",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            batch: "B352  ",
            noOfPack: "10",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            batch: "B352  ",
            noOfPack: "10",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
        ],
        sReturn: [
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt po",
            noOfPack: "10",
            returnQuantity: "299.25 Lt trrt",
            quantity: "299.25 Lt yerrr",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            noOfPack: "10",
            returnQuantity: "299.25 Lt",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            pack: "19.95 Lt",
            noOfPack: "10",
            returnQuantity: "299.25 Lt",
            quantity: "299.25 Lt",
            amount: "5,500",
          },
        ],
        challan: [
          {
            productName: "Raw Material 1",
            hsn: "1121",
            noOfPack: "10",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            noOfPack: "10",
            amount: "5,500",
          },
          {
            productName: "Raw Material 1",
            hsn: "1121",
            noOfPack: "10",
            amount: "5,500",
          },
        ],
      }),
    })
  }, [productDetails])

  const [tableData, setTableData] = useState({ tHeading: [], tData: [] })

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  const [activeInputs, setActiveInputs] = useState(false)

  const [btnList, setBtnList] = useState([])

  useEffect(() => {
    if (key) {
      let newList =
        key === "Sales Quotation"
          ? [{ btnTitle: "Create Sales Order", btnAction: () => { } }]
          : key === "Sales Order"
            ? [
              { btnTitle: "Generate E-way Bill", btnAction: () => { } },
              { btnTitle: "Generate E-Invoice", btnAction: () => { } },
              { btnTitle: "Create Sales Return", btnAction: () => { } },
              {
                btnTitle: "Create Sales Proforma Invoice",
                btnAction: () => { },
              },
              { btnTitle: "Create Delivery Challan", btnAction: () => { } },
            ]
            : key === "Sales Proforma Invoice"
              ? [
                { btnTitle: "Generate E-way Bill", btnAction: () => { } },
                { btnTitle: "Generate E-Invoice", btnAction: () => { } },
                { btnTitle: "Create Sales Return", btnAction: () => { } },
              ]
              : key === "Delivery Challan"
                ? [{ btnTitle: "Create Purchase Return", btnAction: () => { } }]
                : []

      setBtnList(newList)
    }
  }, [])

  const setDetailsTitle = ({ invoice, quotation, order, sReturn, challan }) => {
    return key === "Sales Quotation"
      ? quotation
      : key === "Sales Order"
        ? order
        : key === "Delivery Challan"
          ? challan
          : key === "Sales Return"
            ? sReturn
            : key === "Sales Proforma Invoice"
              ? invoice
              : ""
  }
  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>{key}</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
                <div className='table-search'>
                  <div
                    className='search-container'
                    style={{ justifyContent: "flex-end" }}
                  >
                    {Object.values(privileges["purchase_management"]).includes(
                      "write",
                    ) && (
                        <>
                          {(key === "Sales Quotation" ||
                            key === "Sales Order") && (
                              <button className='btn-alpha   '>
                                <span className='material-symbols-outlined medium'>
                                  delete
                                </span>
                              </button>
                            )}
                          <button className='btn-alpha  '>
                            <span className='material-symbols-outlined medium'>
                              download
                            </span>
                          </button>
                          {key === "Sales Return" && (
                            <button className='btn-alpha  header-btn bg-none'>
                              Save
                            </button>
                          )}{" "}
                          {key !== "Sales Return" && (
                            <>
                              <button className='btn-alpha header-btn bg-none'>
                                Edit
                              </button>
                              <button className='btn-alpha  header-btn '>
                                {key === "Sales Proforma Invoice"
                                  ? "Send For Approval"
                                  : " Save and Send"}
                              </button>{" "}
                              {key === "Sales Proforma Invoice" && (
                                <button className='btn-alpha  header-btn '>
                                  Send
                                </button>
                              )}
                            </>
                          )}{" "}
                        </>
                      )}{" "}
                  </div>
                </div>
              </div>
              <div
                className='main-box-padding'
                style={{ borderRadius: ".4rem" }}
              >
                <div className='gray-border'>
                  <LabeAndValues
                    labels={{ first: "GST Number", second: "GST Number" }}
                    values={{ first: "24ERTGHN98948511", second: "AAW85841" }}
                  />
                </div>

                <div className='gray-border flex flex-direction-column gap-8'>
                  <div className='flex address-flex'>
                    <div className='modelEx_content singleAdd w-full'>
                      <div className='profile_personal_details'>
                        <div className='details-controls'>
                          <h2>
                            {key === "Sales Return"
                              ? "Goods Received By"
                              : key === "Delivery Challan"
                                ? "Challan From"
                                : key === "Sales Proforma Invoice"
                                  ? "Invoice To"
                                  : "Buyer Details"}
                          </h2>
                        </div>
                      </div>
                      <div className='location-collection flex'>
                        <div className='card' onClick={() => { }}>
                          <div className='col1'>
                            <h3 className='card-name'>{workAddress?.name}</h3>
                            <div className='card-address'>
                              <p>{`${workAddress?.address?.line_one}, `}</p>
                              <p>{`${workAddress?.address?.line_two}${workAddress?.address?.line_two ? "," : ""}`}</p>
                              <p>{`${workAddress?.address?.city} - ${workAddress?.address?.pin_code},`}</p>
                              <p>{`${workAddress?.address?.state} - ${workAddress?.address?.country}`}</p>
                              {/* <p
                                style={{ marginTop: "0.7rem" }}
                              >{`GSTIN - ${buyerGst}`}</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    <div className='dash-border'></div>
                    <div className='modelEx_content singleAdd w-full'>
                      <div className='profile_personal_details'>
                        <div className='details-controls'>
                          <h2>
                            {key === "Sales Return"
                              ? "Delivery Location"
                              : key === "Delivery Challan" ||
                                key === "Sales Order"
                                ? "Supplier Details"
                                : key === "Sales Proforma Invoice"
                                  ? "Invoice From"
                                  : "Supplier Details"}
                          </h2>
                        </div>
                      </div>
                      <div className='location-collection flex'>
                        <div className='card' onClick={() => { }}>
                          <div className='col1'>
                            <h3 className='card-name'>Poonam Coatings</h3>

                            <div className='card-address'>
                              <p>{`${supplierAddress?.address?.line_one}, `}</p>
                              <p>{`${supplierAddress?.address?.line_two}${supplierAddress?.address?.line_two ? "," : ""}`}</p>
                              <p>{`${supplierAddress?.city_detail?.name} - ${supplierAddress?.address?.pin_code},`}</p>
                              <p>{`${supplierAddress?.state_detail?.name} - ${supplierAddress?.country_detail?.name}`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ width: "100%", margin: 0 }} />
                  <div className='modelEx_content singleAdd w-full'>
                    <div className='profile_personal_details'>
                      <div className='details-controls'>
                        <h2>
                          {key === "Sales Return"
                            ? "Goods Sent By"
                            : key === "Delivery Challan"
                              ? "Shipped To"
                              : "Delivery Location"}
                        </h2>
                      </div>
                    </div>
                    <div className='location-collection flex'>
                      <div className='card' onClick={() => { }}>
                        <div className='col1'>
                          <h3 className='card-name'>Jotun</h3>
                          <div className='card-address'>
                            <p>{`${warehouseAddress?.address?.line_one}, `}</p>
                            <p>{`${warehouseAddress?.address?.line_two}${warehouseAddress?.address?.line_two ? "," : ""}`}</p>
                            <p>{`${warehouseAddress?.cityName} - ${warehouseAddress?.address?.pin_code},`}</p>
                            <p>{`${warehouseAddress?.stateName} - ${warehouseAddress?.countryName}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='gray-border'>
                  <h2 className='heading'>{key} Details</h2>
                  <div className='flex flex-direction-column gap-2'>
                    <LabeAndValues
                      labels={{
                        first: setDetailsTitle({
                          invoice: "Invoice Number",
                          quotation: "SQ Number",
                          order: "SO Number",
                          sReturn: "Sales Return Number",
                          challan: "Challan Number",
                        }),
                        second: setDetailsTitle({
                          quotation: "Document Date ",
                          order: "SO Date",
                          invoice: "Invoice Date",
                          sReturn: "Document Date ",
                          challan: "Challan Date ",
                        }),
                      }}
                      values={{
                        first: setDetailsTitle({
                          invoice: "Invoice Number",
                          quotation: "SQ Number",
                          order: "",
                          sReturn: "Sales Return Number",
                          challan: "Challan Number",
                        }),
                        second: setDetailsTitle({
                          quotation: "Document Date ",
                          order: data?.primaryDetails?.documentDate,
                          invoice: "Invoice Date",
                          sReturn: "Document Date ",
                          challan: "Challan Date ",
                        }),
                      }}
                    />{" "}
                    <LabeAndValues
                      labels={{
                        first: setDetailsTitle({
                          quotation: "Payment Term",
                          order: "Delivery Date ",
                          invoice: "Delivery Date ",
                          sReturn: "Return Delivery Date",
                          challan: "PO Number",
                        }),
                        second: setDetailsTitle({
                          quotation: "Transportation Cost",
                          order: "Payment Term ",
                          invoice: "Payment Date ",
                          sReturn: "Total Amount ",
                          challan: "PO Date",
                        }),
                      }}
                      values={{
                        first: setDetailsTitle({
                          quotation: "Payment Term",
                          order: data?.primaryDetails?.deliveryDate,
                          invoice: "Delivery Date ",
                          sReturn: "Return Delivery Date",
                          challan: "PO Number",
                        }),
                        second: setDetailsTitle({
                          quotation: "Transportation Cost",
                          order: data?.primaryDetails?.PaymentTerm,
                          invoice: "Payment Date ",
                          sReturn: "Total Amount ",
                          challan: "PO Date",
                        }),
                      }}
                    />{" "}
                    <LabeAndValues
                      labels={{
                        first: "No. of Items",
                        second: setDetailsTitle({
                          quotation: "",
                          order: "Total Amount",
                          invoice: "Total Amount",
                          sReturn: "",
                          challan: "",
                        }),
                      }}
                      values={{
                        first: setDetailsTitle({
                          quotation: "Payment Term",
                          order: "Delivery Date ",
                          invoice: "Delivery Date ",
                          sReturn: "Return Delivery Date",
                          challan: "PO Number",
                        }),
                        second: setDetailsTitle({
                          quotation: "Transportation Cost",
                          order: "Payment Term ",
                          invoice: "Payment Date ",
                          sReturn: "Total Amount ",
                          challan: "PO Date",
                        }),
                      }}
                    />{" "}
                    {(key === "Sales Order" ||
                      key === "Sales Proforma Invoice") && (
                        <LabeAndValues
                          labels={{
                            first: setDetailsTitle({
                              order: "Purchase Officer",
                              invoice: "Total Amount",
                            }),
                            second: setDetailsTitle({
                              order: "Transportation Cost",
                            }),
                          }}
                          values={{
                            first: setDetailsTitle({
                              quotation: "Payment Term",
                              order: data?.primaryDetails?.purchase_officer,
                              invoice: "Delivery Date ",
                              sReturn: "Return Delivery Date",
                              challan: "PO Number",
                            }),
                            second: setDetailsTitle({
                              quotation: "Transportation Cost",
                              order: data?.primaryDetails?.transportation_cost,
                              invoice: "Payment Date ",
                              sReturn: "Total Amount ",
                              challan: "PO Date",
                            }),
                          }}
                        />
                      )}
                  </div>
                </div>

                <>
                  <div
                    className='data-table-container'
                    style={{ padding: "0" }}
                  >
                    <div
                      className='data-table-box scrollbar-visible'
                      style={{
                        margin: 0,
                        border: "1px solid #BFBFBF",
                      }}
                    >
                      <PreviewTable
                        tHeading={tableData?.tHeading}
                        tData={tableData?.tData}
                        salesKey={key}
                        data={data}
                      />
                    </div>
                  </div>
                </>

                {key !== "Delivery Challan" && (
                  <>
                    <div className='tp-main'>
                      {(key === "Sales Quotation" || key === "Sales Order") && (
                        <>
                          <div className='tp-inner'>
                            <span>Subtotal :</span>
                            <hr />
                            <span>₹{" "}
                              {numberWithCommas(
                                Number(data?.grandTotals?.subtotal),
                              )}</span>
                          </div>
                          <div className='tp-inner'>
                            <span>Taxes :</span>
                            <hr />
                            <span>₹ {numberWithCommas(data?.grandTotals?.taxAmount)}</span>
                          </div>
                        </>
                      )}
                      <div className='tp-inner'>
                        <span>
                          {key === "Sales Return" ? "SR Amount" : "Grand Total"}
                          :
                        </span>
                        <hr />
                        <span>₹ {numberWithCommas(data?.grandTotals?.grandTotal)}</span>
                      </div>
                      {/* <div className='tp-inner'>
                        <span>Advance to pay :</span>
                        <input
                          type='text'
                          className='form-input'
                          placeholder='eg. 10000'
                        ></input>
                      </div> */}
                      <div
                        className='flex'
                        style={{ justifyContent: "flex-end" }}
                      >
                        <span>Sixteen Thousand Seven Hundred Fifty </span>
                      </div>
                    </div>
                  </>
                )}
                <form className=''>
                  <div className='form-group'>
                    <div className={`input-field ${"active"}`}>
                      <label htmlFor='about_us' className='toggle-label'>
                        Comment
                      </label>
                      <textarea
                        id='about_us'
                        name='about_us'
                        rows={4}
                        className={
                          !activeInputs
                            ? "form-input disabeldTextArea"
                            : "form-input"
                        }
                        disabled={true}
                        value={data?.additionalCharges?.comment}
                      />
                      {formik.touched.about_us && formik.errors.about_us ? (
                        <span className='err'>{formik.errors.about_us}</span>
                      ) : null}
                    </div>
                  </div>
                </form>
                <TermsCondition data={data} />
                <div className='logo-stamp flex'>
                  <div className='stamp-box'>
                    <img className='stamp-img' src={tempImage} alt='' />
                  </div>
                </div>
              </div>

              <div className='table-search'>
                <div
                  className='search-container'
                  style={{ justifyContent: "flex-end" }}
                >
                  {Object.values(privileges["sales_management"]).includes(
                    "write",
                  ) &&
                    // <>
                    //   <button className='btn-alpha header-btn  '>
                    //     Generate E-way Bill
                    //   </button>
                    //   <button className='btn-alpha header-btn  '>
                    //     Generate E-Invoice
                    //   </button>
                    //   <button className='btn-alpha header-btn  '>
                    //     Create Sales Proforma Invoice
                    //   </button>
                    //   <button className='btn-alpha header-btn  '>
                    //     Create Delivery Challan
                    //   </button>

                    //   {key === "Sales Order" ? (
                    //     <button className='btn-alpha header-btn  '>
                    //       Create Sales Return
                    //     </button>
                    //   ) : (
                    //     key === "Sales Return" && (
                    //       <button className='btn-alpha header-btn  '>
                    //         Create Credit Note
                    //       </button>
                    //     )
                    //   )}
                    // </>

                    btnList?.length > 0 &&
                    btnList?.map((item, index) => {
                      const { btnTitle, btnAction } = item
                      return (
                        <button
                          className='btn-alpha header-btn  '
                          onClick={btnAction}
                          key={index}
                        >
                          {btnTitle}
                        </button>
                      )
                    })}{" "}
                </div>
              </div>
            </div>
            <Footer />{" "}
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              //   api={deleteProducts}
              popUpData={popUpData}
              // reFetchApi={getProductsList}
              navigateTo='/products'
            // reData={{ search: "", sort:"desc", limit: 5, page: 0 }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SalesPreview

const LabeAndValues = ({ labels, values }) => {
  return (
    <div className='flex labeAndValue'>
      <div className='w-full'>
        <p className='remove-margin'>
          <b>{labels.first} : </b>
          {values.first}
        </p>
      </div>
      {labels?.second && (
        <>
          <div className='dash-border'></div>

          <div className='w-full'>
            <p className='remove-margin'>
              <b>{labels.second} : </b>
              {values.second}
            </p>
          </div>
        </>
      )}
    </div>
  )
}
const TermsCondition = ({data}) => {
  const formik = useFormik({
    initialValues: {
      terms: data?.additionalCharges?.terms?.terms,
    },

    onSubmit: async values => { },
  })

  const modules = {
    toolbar: [
      // [{ header: [2, 3, 4, true] }],
    ],
  }

  return (
    <div className='gray-border' style={{ padding: "0" }}>
      {" "}
      <div className='profile_personal_details'>
        <div
          className='col1 heading-tools details-controls'
          style={{ padding: "1.8rem 1.8rem 1rem 1.8rem" }}
        >
          <h2>Terms & Condition</h2>
        </div>
        <div className='ae-evIp flex-column-center'>
          <ReactQuill
            modules={modules}
            theme='snow'
            placeholder=''
            // onChange={handleDescChange}
            value={formik.values.terms}
            readOnly={true}
          />
        </div>
        {/* <FormButtons
        // loading={companyLoading}
        removeCancel
        btnContainerStyles={{
          justifyContent: "flex-end",
          padding: ".5rem",
        }}
      /> */}
      </div>
    </div>
  )
}

const PreviewTable = ({ tHeading = [], tData = [], salesKey, data }) => {
  const checkItIncludes = heading => {
    return tHeading?.includes(heading)
  }

  return (
    <table className='data-table fix-width-table-row preview-data-table'>
      <thead>
        <tr>
          <th>#</th>
          {tHeading?.map((item, index) => {
            return <th key={index}>{item}</th>
          })}
        </tr>
      </thead>

      <tbody>
        {data?.productDetails?.rows?.map((item, index) => {
          const {
            product_name,
            hsn,
            noOfPacks,
            packQuantity,
            totalQuantity,
            totalAmount,
            packSize
          } = item

          return (
            <tr key={index}>
              <td className='preview-td'>{index + 1}</td>
              {product_name && <td className='preview-td'>{product_name}</td>}
              {hsn && <td className='preview-td'>{hsn}</td>}
              {noOfPacks && <td className='preview-td'>{noOfPacks}</td>}
              {packQuantity && <td className='preview-td'>{packQuantity}</td>}
              {totalQuantity && <td className='preview-td'>{totalQuantity}</td>}
              {totalAmount && <td className='preview-td'>₹ {totalAmount}</td>}
            </tr>
          )
        })}
        {/* {salesKey !== "Sales Return" && (
          <tr>
            <td className='preview-td' colSpan={4}>
              Gross Total:
            </td>
            {salesKey !== "Delivery Challan" && (
              <td className='preview-td'>30</td>
            )}
            <td
              className='preview-td'
              style={{ borderRight: "1px solid #bfbfbf" }}
            >
              995 litre
            </td>
          </tr>
        )} */}
      </tbody>
    </table>
  )
}
