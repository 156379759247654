import { useEffect, useState } from "react"

const useLoaderDelay = ({ delay = 300, loadingState }) => {
  const [tableLoading, setTableLoading] = useState(false)
  const [tableCount, setTableCount] = useState(null)

  useEffect(() => {
    if (tableCount) {
      clearTimeout(tableCount)
    }
    const count = setTimeout(() => {
      setTableLoading(loadingState)
    }, delay)
    setTableCount(count)
    return () => clearTimeout(count)
  }, [loadingState])

  return { tableLoading }
}

export { useLoaderDelay }
