/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { useSelector, shallowEqual } from "react-redux"
import Maintenance from "../modules/common/Maintenance"
import { CommonRoutes } from "./role/CommonRoutes"
import { AuthRoutes } from "./role/AuthRoutes"
import { MainRoutes } from "./role/MainRoutes"

const Entry = () => {
  const [title, setTitle] = useState("Poonam Coatings")
  const [maintenanceStatus, setMaintenanceStatus] = useState(false)
  document.title = title

  const { maintenance, privileges, token, role, is_2fa, is_verify } =
    useSelector(state => state.auth, shallowEqual)

  // const [module, setModule] = useState([]);
  // const giveAssignedRoleModule = () => {
  //   switch (role) {
  //     case 1:
  //       return setModule(MainRoutes);
  //       break;
  //     case 2:
  //       return setModule(MainRoutes);
  //       break;
  //   }
  // };
  const module = role === 1 || role === 2 || role === 3 ? MainRoutes : []

  useEffect(() => {
    if (maintenance) {
      //   setMaintenanceStatus(maintenance?.status);
      setMaintenanceStatus(maintenance)
    }
  }, [maintenance])
  // useEffect(() => {
  //   giveAssignedRoleModule();
  // }, [role]);

  return (
    <>
      {maintenanceStatus ? (
        <Routes>
          <Route path='*' element={<Maintenance setTitle={setTitle} />} />
        </Routes>
      ) : token && is_2fa !== is_verify ? (
        <Routes>
          {/* <Route path="*" element={<Navigate to="/verify" />} /> */}
          {/* <Route path="/verify" element={<OTP setTitle={setTitle} />} /> */}
        </Routes>
      ) : (
        <Routes>
          {/* ----------------------------------COMMON ROUTES----------------------------------------- */}

          {CommonRoutes?.map((obj, index) => {
            return (
              <Route
                key={index}
                path={obj.path}
                element={<obj.component setTitle={setTitle} />}
              />
            )
          })}

          {/* ----------------------------------ROUTES ACCESSIBLE JUST FOR AUTH ----------------------------------------- */}

          {!token && <Route path='/' element={<Navigate to='/login' />} />}

          {AuthRoutes?.map((obj, index) => {
            return (
              <Route
                key={index}
                path={obj.path}
                element={
                  !token ? (
                    <obj.component setTitle={setTitle} />
                  ) : (
                    <Navigate to={"/"} />
                  )
                }
              />
            )
          })}

          {/* ----------------------------------Role Based Routing----------------------------------------- */}

          {token &&
            module?.map((obj, index) => {
              if (
                obj.position === "head" ||
                obj.position === "parent" ||
                obj.position === "child"
              ) {
                return (
                  <Route
                    key={index}
                    path={obj.path}
                    element={
                      token &&
                      is_2fa === is_verify &&
                      (obj.position === "head" ? (
                        <obj.component setTitle={setTitle} />
                      ) : privileges.hasOwnProperty(obj.privilegeTag) ? (
                        obj.position === "parent" ? (
                          Object.values(privileges[obj.privilegeTag]).includes(
                            "read",
                          ) ? (
                            <obj.component setTitle={setTitle} />
                          ) : (
                            <Navigate to='/' />
                          )
                        ) : Object.values(
                            privileges[obj.privilegeTag],
                            // ).includes("read" && "write") ? (
                          ).includes("read") ? (
                          <obj.component setTitle={setTitle} />
                        ) : (
                          <Navigate to='/' />
                        )
                      ) : (
                        <Navigate to='/' />
                      ))
                    }
                  />
                )
              }
            })}
        </Routes>
      )}
    </>
  )
}

export default Entry
