import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { useFormik } from "formik"
import * as Yup from "yup"

import { useState, useEffect } from "react"
import {
  getOptionsList,
  getOptionsListForTable,
} from "../../products/redux/ProductsApi"
import { addMoreOptions } from "../redux/ProductCatalogueApi"
import DropDownBox from "../../../components/DropDownBox"
import { capitalizeInput, filterNumbersRegex } from "../../../helper/helper"

const AddProductCatForm = ({ customState, customSetter }) => {
  const [addStyle, setAddStyle] = useState(true)
  const [focusedField, setFocusedField] = useState()
  // const { catalogueLoading } = useSelector(store => store.department)
  const dispatch = useDispatch()

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  const formik = useFormik({
    initialValues: {
      category: "",
      brandId: "",
      margin: "",
    },
    validationSchema: Yup.object({
      category: Yup.string()
        .trim()
        .min(3, "Product category must be at least 3 characters long")
        .max(30, "Product category must be less than 30 characters")
        .required("Product category is required"),
      margin: Yup.string().trim().required("Margin is required"),
      brandId: Yup.string().trim().required("Brand is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        slug: "add-category",
        send: {
          category_name: values.category,
          brand_id: values.brandId,
          margin: values.margin,
        },
      }
      const response = await dispatch(addMoreOptions(data))
      if (response.payload) {
        dispatch(
          getOptionsListForTable({
            listName: "category-list",
            page: 0,
            limit: "5",
            search: "",
            sort: "desc",
          }),
        )
        handleClick()
      }
    },
  })
  function chatBoxVisibility() {
    if (customState.add) {
      const styleTimer = setTimeout(() => {
        customSetter({ ...customState, add: false })
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  const [brandList, setBrandList] = useState([])

  const apiCall = async () => {
    {
      const response = await dispatch(
        getOptionsList({
          listName: "brand-list",
          search: "",
          sort: "desc",
          limit: "0",
          page: "0",
        }),
      )

      const options = response?.payload?.map(item => {
        return { label: item.name, value: item._id }
      })
      setBrandList(options)
    }
  }

  useEffect(() => {
    if (brandList.length === 0) apiCall()
  }, [])
  return (
    <div
      id='delete-card-container'
      className={`${
        addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation"
      }`}
    >
      <div
        className={
          "event-card" +
          (addStyle
            ? " show_delete-component-animation"
            : " hide_delete-component-animation")
        }
      >
        <h2>Add Product Category</h2>

        <div className='modelEx_content'>
          <form
            className='editbox-form flex-center'
            onSubmit={formik.handleSubmit}
          >
            <div className='form-group'>
              <div
                className='input-field'
                // onMouseEnter={() => apiCall("brand-list", "brandList")}
              >
                <DropDownBox
                  options={brandList}
                  customSetter={formik.setFieldValue}
                  dropDownTitle='Brand'
                  animateDropDownTitle
                  customFormikLabel={"brandId"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                  showSearchBar
                />
                {/* <SearchDropDown
                      label='Brand'
                      name='brand'
                      api={getOptionsList}
                      listName={"brand-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                {formik.touched.brand && formik.errors.brand ? (
                  <span className='err'>{formik.errors.brand}</span>
                ) : null}
              </div>
            </div>
            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.margin !== "" || focusedField === "margin"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='margin' className='toggle-label'>
                  Margin Percentage (%)
                </label>
                <input
                  id='margin'
                  name='margin'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.margin}
                  onInput={filterNumbersRegex}
                  maxLength={2}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("margin")}
                />
                {formik.touched.margin && formik.errors.margin ? (
                  <span className='err'>{formik.errors.margin}</span>
                ) : null}
              </div>
            </div>
            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.category !== "" || focusedField === "category"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='category' className='toggle-label'>
                  Category Name
                </label>
                <input
                  id='category'
                  name='category'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  // onInput={capitalizeInput}
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z-. \d]/gi, "")
                      .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                  }}
                  maxLength={30}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("category")}
                />
                {formik.touched.category && formik.errors.category ? (
                  <span className='err'>{formik.errors.category}</span>
                ) : null}
              </div>
            </div>
            {/* <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.subCategory !== "" ||
                  focusedField === "subCategory"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='subCategory' className='toggle-label'>
                  Sub-category
                </label>
                <input
                  id='subCategory'
                  name='subCategory'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.subCategory}
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z ]/gi, "")
                      .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                  }}
                  maxLength={30}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("subCategory")}
                    
                />
                {formik.touched.subCategory && formik.errors.subCategory ? (
                  <span className='err'>{formik.errors.subCategory}</span>
                ) : null}
              </div>
            </div>

            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.subChildCategory !== "" ||
                  focusedField === "subChildCategory"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='subChildCategory' className='toggle-label'>
                  Sub-child-category
                </label>
                <input
                  id='subChildCategory'
                  name='subChildCategory'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z ]/gi, "")
                      .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                  }}
                  maxLength={30}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("subChildCategory")}
                    
                />
                {formik.touched.subChildCategory &&
                formik.errors.subChildCategory ? (
                  <span className='err'>{formik.errors.subChildCategory}</span>
                ) : null}
              </div>
            </div>

            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.description !== "" ||
                  focusedField === "description"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='description' className='toggle-label'>
                  Description
                </label>
                <textarea
                  id='description'
                  name='description'
                  rows={4}
                  className={"form-input"}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  maxLength={151}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("description")}
                    
                />
                {formik.touched.description && formik.errors.description ? (
                  <span className='err mt-2'>{formik.errors.description}</span>
                ) : null}
              </div>
            </div> */}
            <div className='addPopup-btn-box'>
              <button
                type='button'
                className='btn-secondary'
                onClick={handleClick}
              >
                Abort
              </button>
              <button
                className='btn-primary'
                type='submit'
                // style={
                //   catalogueLoading
                //     ? {
                //         cursor: "not-allowed",
                //         // background: "gray",
                //       }
                //     : {}
                // }
                // disabled={catalogueLoading}
              >
                {/* {catalogueLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "SUBMIT"
                )} */}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddProductCatForm
