import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { useFormik } from "formik"
import * as Yup from "yup"

import { useState, useEffect } from "react"
import { addMoreOptions } from "../redux/ProductCatalogueApi"
import { getOptionsListForTable } from "../../products/redux/ProductsApi"
import DropDownBox from "../../../components/DropDownBox"

const AddUOMForm = ({ customState, customSetter }) => {
  const [addStyle, setAddStyle] = useState(true)
  const [focusedField, setFocusedField] = useState()
  // const { catalogueLoading } = useSelector(store => store.cataloue)
  const dispatch = useDispatch()

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  const formik = useFormik({
    initialValues: {
      uom: "",
    },
    validationSchema: Yup.object({
      uom: Yup.string()
        .trim()
        .max(30, "Paint uom must be less than 30 characters")
        .required("Please enter uom "),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        slug: "add-uom",
        send: {
          uom_type: values.uom,
        },
      }
      const response = await dispatch(addMoreOptions(data))
      if (response.payload) {
        dispatch(
          getOptionsListForTable({
            listName: "uom-list",
            page: 0,
            limit: 5,
            search: "",
            sort: "desc",
          }),
        )
        handleClick()
      }
    },
  })

  function chatBoxVisibility() {
    if (customState.add) {
      const styleTimer = setTimeout(() => {
        customSetter({ ...customState, add: false })
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  return (
    <div
      id='delete-card-container'
      className={`${
        addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation"
      }`}
    >
      <div
        className={
          "event-card" +
          (addStyle
            ? " show_delete-component-animation"
            : " hide_delete-component-animation")
        }
      >
        <h2>Add UOM</h2>

        <div className='modelEx_content'>
          <form
            className='editbox-form flex-center'
            onSubmit={formik.handleSubmit}
          >
            <div className='form-group'>
              {/* <div
                className={`input-field ${
                  formik.values.uom !== "" || focusedField === "uom"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='uom' className='toggle-label'>
                Unit Of Measurement
                </label>
                <input
                  id='uom'
                  name='uom'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.uom}
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z ]/gi, "")
                      .replace(/(?:^|\s)\S/g, match => match.toLowerCase())
                  }}
                  maxLength={30}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("uom")}
                    
                />
                {formik.touched.uom && formik.errors.uom ? (
                  <span className='err mt-2'>{formik.errors.uom}</span>
                ) : null}
              </div> */}
              <div className='input-field'>
                <DropDownBox
                  options={[
                    { label: "kg", value: "kg" },
                    { label: "litre", value: "litre" },
                    { label: "Number", value: "Number" },
                    // { label: "mg", value: "mg" },
                    // { label: "g", value: "g" },
                    // { label: "ml", value: "ml" },
                    // { label: "pack", value: "pack" },
                  ]}
                  customSetter={formik.setFieldValue}
                  dropDownTitle='UOM'
                  animateDropDownTitle
                  customFormikLabel={"uom"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                />
                {/* <SearchDropDown
                      label='Brand'
                      name='brand'
                      api={getOptionsList}
                      listName={"brand-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                {formik.touched.uom && formik.errors.uom ? (
                  <span className='err'>{formik.errors.uom}</span>
                ) : null}
              </div>
            </div>

            {/* <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.description !== "" || focusedField === "description"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='description' className='toggle-label'>
                  Description
                </label>
                <textarea
                  id='description'
                  name='description'
                  rows={4}
                  className={"form-input"}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  maxLength={151}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("description")}
                    
                />
                {formik.touched.description && formik.errors.description ? (
                  <span className='err mt-2'>{formik.errors.description}</span>
                ) : null}
              </div>
            </div> */}
            <div className='addPopup-btn-box'>
              <button
                type='button'
                className='btn-secondary'
                onClick={handleClick}
              >
                Abort
              </button>
              <button
                className='btn-primary'
                type='submit'
                // style={
                //   catalogueLoading
                //     ? {
                //         cursor: "not-allowed",
                //         // background: "gray",
                //       }
                //     : {}
                // }
                // disabled={catalogueLoading}
              >
                {/* {catalogueLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : ( */}
                Submit
                {/* )} */}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddUOMForm
