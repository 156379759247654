import React, { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import DataNotFound from "../../../components/DatanotFound"
import { setSupplierAddress, setWarehouseAddress } from "../redux/PurchaseSlice"

const AddressDetails = ({
  setIsSelectCompany,
  formData,
  setFormData,
  setPopup,
}) => {
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { purchaseLoading, supplierAddress, warehouseAddress } = useSelector(
    store => store.purchase,
    shallowEqual,
  )
  
  const { companyAddress, companyDetails } = useSelector(
    store => store.company,
    shallowEqual,
  )

  const dispatch = useDispatch()
  const [workAddress, setWorkAddress] = useState()
  const [activeInputs, setActiveInputs] = useState(false)
  useEffect(() => {
    if (supplierAddress) {
      dispatch(setSupplierAddress([]))
    }
    const workAddress = companyAddress.find(item => item.type === "work")
    setWorkAddress(workAddress)
    if (companyAddress.length === 0) {
      dispatch(setWarehouseAddress(workAddress))
    }
  }, [])

  useEffect(() => {
    if (!formData?.addressDetails) {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))

        const AddressDetails = {
          supplierAddress: supplierAddress?._id,
          soldBy: supplierAddress?.companyId,
          orderBy: companyDetails?._id,
          shippingAddress: warehouseAddress?.addressId,
        }

        // const buyerAdd = {
        //   orderBy: buyer,
        // }

        newList.addressDetails = {
          supplier: supplierAddress,
          buyer: workAddress,
          delivery: warehouseAddress,
          AddressDetails,
        }
        return newList
      })
    }
  }, [formData?.addressDetails, supplierAddress, workAddress, warehouseAddress])
  
  return (
    <div className='main-box-padding'>
      <div className='modelEx_content singleAdd w-full'>
        <div className='profile_personal_details'>
          <div className='details-controls'>
            <h2>Supplier Details</h2>
            {Object.values(privileges["purchase_management"]).includes(
              "write",
            ) && (
              <div className='detail-btn'>
                {activeInputs ? (
                  <>
                    <button
                      type='submit'
                      //   onClick={handleCancel}
                      className='btn-secondary'
                      disabled={purchaseLoading}
                      style={purchaseLoading ? { cursor: "not-allowed" } : {}}
                    >
                      {purchaseLoading ? (
                        <ClipLoader
                          loading={true}
                          size={13}
                          aria-label='Loading Spinner'
                          data-testid='loader'
                        />
                      ) : (
                        "Cancel"
                      )}
                    </button>{" "}
                    <button
                      type='submit'
                      //   onClick={formik.handleSubmit}
                      className='btn-primary'
                      disabled={purchaseLoading}
                      style={purchaseLoading ? { cursor: "not-allowed" } : {}}
                    >
                      {purchaseLoading ? (
                        <ClipLoader
                          loading={true}
                          size={13}
                          aria-label='Loading Spinner'
                          data-testid='loader'
                        />
                      ) : (
                        "Save"
                      )}
                    </button>{" "}
                  </>
                ) : (
                  <button
                    onClick={() => {
                      setIsSelectCompany({
                        key: "supplier",
                        value: true,
                        showDeliveryAddressBtn: true,
                      })
                      // setActiveInputs(true)
                      setPopup(2)
                    }}
                    className='btn-secondary'
                  >
                    <span className='material-symbols-outlined small'>
                      border_color
                    </span>
                    Select
                  </button>
                )}
              </div>
            )}{" "}
          </div>
        </div>
        <div
          className={`location-collection ${
            Object.keys(supplierAddress).length > 0 ? " flex" : ""
          }`}
        >
          {Object.keys(supplierAddress).length > 0 ? (
            <div className='card' onClick={() => {}}>
              <div className='col1'>
                <h3 className='card-name'>{supplierAddress?.name}</h3>
                <div className='card-address'>
                  <p>{`${supplierAddress?.address?.line_one}, `}</p>
                  <p>{`${supplierAddress?.address?.line_two}${supplierAddress?.address?.line_two ? "," : ""}`}</p>
                  <p>{`${supplierAddress?.address?.city} - ${supplierAddress?.address?.pin_code},`}</p>
                  <p>{`${supplierAddress?.address?.state} - ${supplierAddress?.address?.country}`}</p>
                  <p
                    style={{ marginTop: "0.7rem" }}
                  >{`GSTIN - ${supplierAddress?.gst}`}</p>
                </div>
              </div>
            </div>
          ) : (
            <DataNotFound title='No Supplier Selected' />
          )}
        </div>
      </div>
      <hr style={{ width: "100%", margin: 0 }} />
      <div className='flex ps-add'>
        <div className='modelEx_content singleAdd w-full'>
          <div className='profile_personal_details'>
            <div className='details-controls'>
              <h2>Buyer Details</h2>
            </div>
          </div>
          <div className='location-collection flex'>
            <div className='card' onClick={() => {}}>
              <div className='col1'>
                <h3 className='card-name'>Poonam Coatings</h3>

                <div className='card-address'>
                  <p>{`${workAddress?.address?.line_one}, `}</p>
                  <p>{`${workAddress?.address?.line_two}${workAddress?.address?.line_two ? "," : ""}`}</p>
                  <p>{`${workAddress?.city_detail?.name} - ${workAddress?.address?.pin_code},`}</p>
                  <p>{`${workAddress?.state_detail?.name} - ${workAddress?.country_detail?.name}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className='dash-border'></div>
        <div className='modelEx_content singleAdd w-full'>
          <div className='profile_personal_details'>
            <div className='details-controls'>
              <h2>Delivery Location</h2>
              {Object.values(privileges["purchase_management"]).includes(
                "write",
              ) && (
                <div className='detail-btn'>
                  {activeInputs ? (
                    <>
                      <button
                        type='submit'
                        //   onClick={handleCancel}
                        className='btn-secondary'
                        disabled={purchaseLoading}
                        style={purchaseLoading ? { cursor: "not-allowed" } : {}}
                      >
                        {purchaseLoading ? (
                          <ClipLoader
                            loading={true}
                            size={13}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                          />
                        ) : (
                          "Cancel"
                        )}
                      </button>{" "}
                      <button
                        type='submit'
                        //   onClick={formik.handleSubmit}
                        className='btn-primary'
                        disabled={purchaseLoading}
                        style={purchaseLoading ? { cursor: "not-allowed" } : {}}
                      >
                        {purchaseLoading ? (
                          <ClipLoader
                            loading={true}
                            size={13}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                          />
                        ) : (
                          "Save"
                        )}
                      </button>{" "}
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setIsSelectCompany({
                          key: "delivery",
                          value: true,
                          showDeliveryAddressBtn: false,
                          showMyAddress: true,
                        }) // setActiveInputs(true)
                        setPopup(2)
                      }}
                      className='btn-secondary'
                    >
                      <span className='material-symbols-outlined small'>
                        border_color
                      </span>
                      Edit
                    </button>
                  )}
                </div>
              )}{" "}
            </div>
          </div>
          <div
            className='location-collection flex'
            style={
              warehouseAddress && Object.keys(warehouseAddress).length === 0
                ? { justifyContent: "center" }
                : {}
            }
          >
            {warehouseAddress && Object.keys(warehouseAddress).length > 0 ? (
              <div className='card' onClick={() => {}}>
                <div className='col1'>
                  <h3 className='card-name'>Poonam Coatings</h3>
                  <div className='card-address'>
                    <p>{`${warehouseAddress?.address?.line_one}, `}</p>
                    <p>{`${warehouseAddress?.address?.line_two}${warehouseAddress?.address?.line_two ? "," : ""}`}</p>
                    <p>{`${warehouseAddress?.city_detail?.name ? warehouseAddress?.city_detail?.name : warehouseAddress?.cityName} - ${warehouseAddress?.address?.pin_code},`}</p>
                    <p>{`${warehouseAddress?.state_detail?.name ? warehouseAddress?.state_detail?.name : warehouseAddress?.stateName} - ${warehouseAddress?.country_detail?.name ? warehouseAddress?.country_detail?.name : warehouseAddress?.countryName}`}</p>
                  </div>
                </div>
              </div>
            ) : (
              <DataNotFound title='No Address Selected' />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressDetails
