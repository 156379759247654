/* eslint-disable no-useless-escape */
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { addCompanyAddress } from "../redux/bNsApi"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getLocalityDetail } from "../../auth/redux/authApi"
import { ClipLoader } from "react-spinners"

const AddLocations = ({ popup, setPopup, type }) => {
  const { addressLoading, companyLoading } = useSelector(store => store.bNs)
  const [checkbox, setCheckbox] = useState(false)

  const [focusedField, setFocusedField] = useState()
  const [addStyle, setAddStyle] = useState(true)
  // const [activeInputs, setActiveInputs] = useState(false);

  const { id } = useParams()
  const dispatch = useDispatch()

  const locationSchema = Yup.object({
    //   title: Yup.string()
    //   .trim()
    //   .min(3, "Title must be at least 3 characters long")
    //   .max(30, "Title must be less than 80 characters")
    // .matches(/^[A-Za-z]+$/, "Please enter a valid name")
    // .required("Title is required"),
    username: Yup.string()
      .min(3, "Full Name must be at least 3 characters long")
      .max(80, "Full Name must be less than 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Name is required"),
    // gst_number: Yup.string()
    //   .matches(
    //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    //     "Not matched with the valid schema, ensure that the GST number is in uppercase"
    //   )
    //   .required("Please enter valid GST number"),
    address_line_one: Yup.string()
      .trim()
      .min(3, "Address must be at least 3 characters long")
      .max(150, "Address must be less than 150 characters")
      .required("Address is required"),
    address_line_two: Yup.string()
      .trim()
      .min(3, "Address must be at least 3 characters long")
      .max(150, "Address must be less than 150 characters"),
    // .required("Address is required"),
    city: Yup.string().required("Please enter valid city name"),
    state: Yup.string().required("Please enter valid state name"),
    country: Yup.string().required("Please enter valid country name"),
    pinCode: Yup.string()
      .min(6, "Enter valid pincode please!")
      .matches(/^[1-9][0-9]{5}$/, "Enter a valid pincode")
      .required("Pincode is required"),
    email: Yup.string()
      .trim()
      .email("Please provide valid email")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please!",
      )
      .test("fakeMail", "Please provide valid email", function (value) {
        if (
          value.includes("@yopmail.") ||
          value.includes("@mywrld.") ||
          value.includes("@nicoric.") ||
          value.includes("@huleos.") ||
          value.includes("@guerrillamail.") ||
          value.includes("@maildrop.")
        ) {
          return false
        } else {
          return true
        }
      })
      .required("Email address should not be empty"),
    contact_num: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
      .required("Phone number is required"),
  })

  const formik = useFormik({
    initialValues: {
      // title: "",
      username: "",
      // gst_number: "",
      address_line_one: "",
      address_line_two: "",
      city: "",
      state: "",
      country: "",
      pinCode: "",
      email: "",
      contact_num: "",
    },
    validationSchema: locationSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        send: {
          name: values.username,
          company_id: popup.address,
          email: values.email,
          phone: values.contact_num,
          address_type: type,
          address_line_one: values.address_line_one,
          address_line_two: values.address_line_two,
          city: values.city,
          state: values.state,
          country: values.country,
          pin_code: values.pinCode,
          checked: checkbox,
        },
        slug: id,
      }

      const response = await dispatch(addCompanyAddress(data))
      if (response.payload) {
        handleClick()
        // setPopup(0)
        resetForm()
      }

      // if (type === "delivery") {
      //   delivery_locations.push(values);
      // }
      // if (type === "billing") {
      //   billing_locations.push(values);
      // }
      // SetData(data, ...values);
    },
  })

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setPopup(0)
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (popup.val === 1) {
      const styleTimer = setTimeout(() => {
        setPopup(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  const hitPincodeApi = async value => {
    const sendData = {
      pin_code: value,
    }
    const response = await dispatch(getLocalityDetail(sendData))
    if (response?.payload) {
      formik.setValues({
        ...formik.values,
        pinCode: value,
        city: response?.payload?.data?.data?.city_name,
        state: response?.payload?.data?.data?.state_name,
        country: response?.payload?.data?.data?.country_name,
      })
    }
  }

  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>{type} Locations</h2>
          <button className='exit-btn' onClick={() => handleClick()}>
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>

        <div className='modelEx_content singleAdd'>
          <form className='scrollForm' onSubmit={formik.handleSubmit}>
            <div className='form-content'>
              <h3 className='title'>Add Details*</h3>
              <div className='form-group form'>
                <div
                  className={`input-field ${
                    formik.values.username !== "" || focusedField === "username"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='username' className='toggle-label'>
                    Full Name
                  </label>
                  <input
                    id='username'
                    name='username'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    onInput={e => {
                      e.target.value = e.target.value
                        .replace(/[^A-Za-z ]/gi, "")
                        .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                    }}
                    maxLength={81}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("username")}
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <span className='err'>{formik.errors.username}</span>
                  ) : null}
                </div>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.email !== "" || focusedField === "email"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='email' className='toggle-label'>
                      Email
                    </label>
                    <input
                      id='email'
                      name='email'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      // maxLength={80}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("email")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <span className='err'>{formik.errors.email}</span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.contact_num !== "" ||
                      focusedField === "contact_num"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='contact_num' className='toggle-label'>
                      Contact Number
                    </label>
                    <div
                      className={formik.values.contact_num ? "phone_box" : ""}
                    >
                      {
                        <span className='phone_code'>
                          {formik.values.contact_num ? "+91" : ""}
                        </span>
                      }{" "}
                      <input
                        id='contact_num'
                        name='contact_num'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.contact_num}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 10)
                            .replace(/\D/g, "")
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("contact_num")}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                      />
                    </div>{" "}
                    {formik.touched.contact_num && formik.errors.contact_num ? (
                      <span className='err'>{formik.errors.contact_num}</span>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-2 gstn"> */}
                {/* <div
                  className={`input-field ${
                    formik.values.gst_number !== "" || focusedField === "gstNum"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor="gst_number" className="toggle-label">
                    GST Number
                  </label>
                  <input
                    id="gst_number"
                    name="gst_number"
                    type="text"
                    className="form-input"
                    onChange={formik.handleChange}
                    value={formik.values.gst_number}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(0, 15);
                    }}
                    onBlur={(e) => {
                      setFocusedField("");
                      formik.handleBlur(e);
                    }}
                    onFocus={() => setFocusedField("gstNum")}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {formik.touched.gst_number && formik.errors.gst_number ? (
                    <span className="err">{formik.errors.gst_number}</span>
                  ) : null}
                </div> */}
                {/* <button
                  type="button"
                  disabled={!gstn}
                  className={gstn ? "fetch_details enabled" : "fetch_details"}
                >
                  Fetch Details
                </button> */}
                {/* </div> */}
                {/* {activeInputs && (
                  <div className={!gstn ? "gstnValidate" : "gstnValidate true"}>
                    <span className="material-symbols-rounded small">
                      {!gstn ? "lightbulb" : "check_circle"}
                    </span>{" "}
                    {!gstn
                      ? "Verify the GST number to capture all the details automatically."
                      : "Unable to fetch details from GST portal. Please enter company details manually."}
                  </div>
                )}  */}
                <div
                  className={`input-field ${
                    formik.values.address_line_one !== "" ||
                    focusedField === "address1"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='address-line-one' className='toggle-label'>
                    Location Address 1
                  </label>
                  <input
                    id='address-line-one'
                    name='address_line_one'
                    type='text'
                    className='form-input'
                    maxLength={151}
                    onChange={formik.handleChange}
                    value={formik.values.address_line_one}
                    onInput={e => {
                      e.target.value = e.target.value
                        // .replace(/[^A-Za-z ]/gi, "")
                        .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                    }}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("address1")}
                  />
                  {formik.touched.address_line_one &&
                  formik.errors.address_line_one ? (
                    <span className='err'>
                      {formik.errors.address_line_one}
                    </span>
                  ) : null}
                </div>
                <div
                  className={`input-field ${
                    formik.values.address_line_two !== "" ||
                    focusedField === "address2"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='address-line-one' className='toggle-label'>
                    Location Address 2
                  </label>
                  <input
                    id='address_line_two'
                    name='address_line_two'
                    type='text'
                    className='form-input'
                    value={formik.values.address_line_two}
                    onChange={formik.handleChange}
                    onInput={e => {
                      e.target.value = e.target.value
                        // .replace(/[^A-Za-z ]/gi, "")
                        .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                    }}
                    maxLength={151}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("address2")}
                  />
                  {formik.touched.address_line_two &&
                  formik.errors.address_line_two ? (
                    <span className='err'>
                      {formik.errors.address_line_two}
                    </span>
                  ) : null}
                </div>
                {/* <div className="form-group contact-info"> */}
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.pinCode !== "" || focusedField === "pinCode"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='pinCode' className='toggle-label'>
                      Pincode
                    </label>
                    <div className='pinCode_wrapper'>
                      <input
                        id='pinCode'
                        name='pinCode'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          formik.setFieldValue("pinCode", e.target.value)
                          if (e.target.value.length > 5) {
                            hitPincodeApi(e.target.value)
                          }
                        }}
                        value={formik.values.pinCode}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 6)
                            .replace(/\D/g, "")
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("pinCode")}
                        maxLength={6}
                      />
                      {addressLoading ? (
                        <span className='pinCode_spinner'>
                          <ClipLoader
                            loading={true}
                            size={15}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                            color='white'
                          />
                        </span>
                      ) : null}
                    </div>

                    {formik.touched.pinCode && formik.errors.pinCode ? (
                      <span className='err'>{formik.errors.pinCode}</span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.city !== "" || focusedField === "city"
                        ? "active"
                        : ""
                    }`}
                  >
                    {" "}
                    <label htmlFor='city' className='toggle-label'>
                      City
                    </label>
                    <input
                      id='city'
                      name='city'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      onFocus={() => setFocusedField("city")}
                      disabled
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <span className='err'>{formik.errors.city}</span>
                    ) : null}
                  </div>
                </div>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.state !== "" || focusedField === "state"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='state' className='toggle-label'>
                      State
                    </label>
                    <input
                      id='state'
                      name='state'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("state")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      disabled
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <span className='err'>{formik.errors.state}</span>
                    ) : null}
                  </div>
                  <div
                    className={`input-field ${
                      formik.values.country !== "" || focusedField === "country"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='country' className='toggle-label'>
                      Country
                    </label>
                    <input
                      id='country'
                      name='country'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.country}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("country")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      disabled
                    />
                    {formik.touched.country && formik.errors.country ? (
                      <span className='err'>{formik.errors.country}</span>
                    ) : null}
                  </div>
                </div>{" "}
                <div className={`input-field checkbox-permission-input-field`}>
                  <span>
                    <input
                      name='checkbox'
                      id='checkbox'
                      type='checkbox'
                      className='checkbox-animated'
                      onChange={() => setCheckbox(!checkbox)}
                      checked={checkbox}
                    />
                    <label htmlFor={"checkbox"}>
                      <span className='filter-check-background'></span>
                    </label>
                  </span>{" "}
                  {type === "shipping" ? (
                    <span className='text-style'>
                      Is Billing address same as the Shipping address?
                    </span>
                  ) : (
                    <span className='text-style'>
                      Is Shipping address same as the Billing address?
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className='footer flex-center'>
              {/* <button type="submit" className="btn-primary">
                Submit
              </button> */}
              <button
                className='btn-primary'
                type='submit'
                style={
                  companyLoading || addressLoading
                    ? {
                        cursor: "not-allowed",
                        // background: "gray",
                      }
                    : {}
                }
                disabled={companyLoading || addressLoading}
              >
                {companyLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                    color='white' 
                  />
                ) : (
                  "SUBMIT"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddLocations
