import React from "react"
import { useNavigate } from "react-router-dom"
import {
  numberWithCommas,
  unixTimeToReadableFormat,
} from "../../../helper/helper"
import { useDispatch } from "react-redux"
import { getPurchaseOrderDetails } from "../../purchase/redux/PurchaseApi"

const OrderTable = ({
  listContent,
  listHeader,
  filterTable,
  activePo,
  setIsSalesPopUp,
  setIsPurchasePopUp,
}) => {
  const dispatch = useDispatch()

  async function ProductDetailsApi(orderNumber) {
    const response = await dispatch(
      getPurchaseOrderDetails({
        orderNumber,
        invoiceType:
          activePo === 1
            ? "purchase order invoice"
            : "purchase return order invoice",
      }),
    )
    activePo === 1 || activePo === 2
      ? setIsPurchasePopUp(response?.payload?.data)
      : setIsPurchasePopUp(false)
  }
  return (
    <div>
      {" "}
      <div className='data-table-box scrollbar-visible pur-data'>
        <table className='data-table '>
          <thead>
            <tr>
              {listHeader?.map((li, index) => (
                <th className='' key={index}>
                  {li?.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {listContent?.map((item, index) => {
              const {
                orderNumber,
                companyName,
                status,
                totalItems,
                createdAt,
                grandTotal,
                userName,
              } = item
              return (
                <tr
                  key={index}
                  onClick={() => {
                    ProductDetailsApi(orderNumber)
                  }}
                  className='navig-table-row'
                  style={{ cursor: "pointer" }}
                >
                  <>
                    <td style={{ maxWidth: "fit-content" }}>
                      {orderNumber}{" "}
                      <span className='navIcon'>
                        <i className='fa-regular fa-arrow-up-right-from-square'></i>
                      </span>
                    </td>
                    <td>-</td>
                    <td>{companyName}</td>
                    <td className='tb_data padding-1   status-column'>
                      {status}
                    </td>
                    <td>{totalItems}</td>
                    <td>-</td>
                    <td>{unixTimeToReadableFormat(createdAt)}</td>
                    <td>{userName}</td>
                    <td>-</td>
                    <td>₹ {numberWithCommas(grandTotal)}</td>
                    {/* <td
                      key={itemIndex}
                      //   className='tb_data padding-07'
                      className={`tb_data padding-1
                            ${
                              listHeader[itemIndex].key === "delivery_Status"
                                ? "status-column"
                                : ""
                            }
                    
                    `}
                    >
                      <span>{item}</span>
                      {(listHeader[itemIndex].key === "invoice_number" ||
                        listHeader[itemIndex].key === "sales_number" ||
                        listHeader[itemIndex].key === "sales_return_number" ||
                        listHeader[itemIndex].key === "purchase_return") && (
                        <span className='navIcon'>
                          <i className='fa-regular fa-arrow-up-right-from-square'></i>
                        </span>
                      )}
                    </td> */}
                  </>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OrderTable
