import { useFormik } from "formik"
import React, { useState } from "react"
import * as Yup from "yup"
import DropDownBox from "../../../components/DropDownBox"

const ApprovalPopup = ({ setIsStatuspopup }) => {
  const [focusedField, setFocusedField] = useState()
  const initialValues = {
    reason: "",
    status: "",
  }

  const singleComSchema = Yup.object({
    reason: Yup.string().required("Reason is required"),
    status: Yup.string().required("Status is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      
      setIsStatuspopup(false)
    },
  })
  return (
    <div className='scp-main'>
      <div className='scp-pu'>
        <i
          className='fa-sharp fa-regular fa-circle-xmark closeIcon'
          onClick={() => setIsStatuspopup(false)}
        ></i>
        <div className='scp-header'>Approval Status</div>

        <form className='product-detail-form' onSubmit={formik.handleSubmit}>
          <div className='form-content'>
            <div className='form-group form'>
              <div className='col-2' style={{ gridTemplateColumns: "1fr" }}>
                <div className='input-field' style={{ marginTop: "1rem" }}>
                  <DropDownBox
                    options={[
                      { label: "Approve", value: "approve" },
                      { label: "Reject", value: "reject" },
                    ]}
                    dropDownTitle='Approval Status'
                    animateDropDownTitle
                    customSetter={formik.setFieldValue}
                    customFormikLabel={"status"}
                    customDropBoxStyles={{
                      border: "1px solid #d7cece",
                      borderRadius: "0.5rem",
                      color: "#333",
                    }}
                    customTextStyle={{ color: "#212229", opacity: "0.7" }}
                  />
                  {formik.touched.status && formik.errors.status ? (
                    <span className='err'>{formik.errors.status}</span>
                  ) : null}
                </div>
                <div
                  className={`input-field 
                    ${
                      formik.values.title !== "" || focusedField === "title"
                        ? "active"
                        : ""
                    }`}
                  style={{ marginTop: "1rem" }}
                >
                  <label htmlFor='reason' className='toggle-label'>
                    Reason
                  </label>
                  <textarea
                    id='reason'
                    name='reason'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.reason}
                    maxLength={150}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("reason")}
                    onKeyDown={e => {
                      if (e.key === " ") {
                        e.preventDefault()
                      }
                    }}
                  />
                  {formik.touched.reason && formik.errors.reason ? (
                    <span className='err'>{formik.errors.reason}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <button
            type='submit'
            className='add-scp'
            // onClick={() => {
            //   setIsStatuspopup(false)
            // }}
            style={{ width: "20%", float: "right" }}
          >
            <span>Save</span>
          </button>
        </form>
      </div>
    </div>
  )
}

export default ApprovalPopup
