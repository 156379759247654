import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"
import { options } from "../../../helper/helper"

export const updatePurchaseOrderStatus = createAsyncThunk(
  "order/update-purchase-order-status",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      // dispatch(isUserLoadingToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/order/purchase/update-purchase-order-status`,
        data,
      })
      if (response.status === 200) {
        // dispatch(isUserLoadingToggle(false))
        toast.success(response?.data?.message)

        return response?.data?.status
      } else {
        // dispatch(isUserLoadingToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      // dispatch(isUserLoadingToggle(false))
      toast.error(err, options)
    }
  },
)
