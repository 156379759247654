import { createSlice } from "@reduxjs/toolkit"
import {
  getBankDetails,
  getCompanyDetailData,
  getSelfCompanyAddress,
} from "./companyApi"

const companySlice = createSlice({
  name: "company",
  initialState: {
    bankDetails: [],
    companyDetails: {},
    companyAddress: [],
    warehousesList: [],
    companyLoading: false,
  },

  reducers: {
    isCompanyToggle: (state, { payload }) => {
      state.companyLoading = payload
    },
    setWarehouseList: (state, { payload }) => {
      state.warehousesList = payload
    },
    setCompanyDetail: (state, action) => {
      state.companyDetails = action.payload
    },
    setBankDetail: (state, action) => {
      state.bankDetails = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(getCompanyDetailData.pending, state => {
      state.companyLoading = true
    })
    builder.addCase(getCompanyDetailData.fulfilled, (state, { payload }) => {
      state.companyLoading = false
      if (payload?.data.length > 0) {
        state.companyDetails = payload?.data[0]
      }
    })
    builder.addCase(getCompanyDetailData.rejected, state => {
      state.companyLoading = false
    })

    builder.addCase(getSelfCompanyAddress.fulfilled, (state, { payload }) => {
      state.companyAddress = payload?.data?.addressList
      const newList = payload?.data?.addressList.map((item, index) => {
        return {
          value: item?._id,
          label: item?.slug,
        }
      })
      state.warehousesList = newList
    })

    builder.addCase(getBankDetails.pending, (state, { payload }) => {
      state.companyLoading = true
    })
    builder.addCase(getBankDetails.fulfilled, (state, { payload }) => {
      state.companyLoading = false
      state.bankDetails = payload?.data
    })
    builder.addCase(getBankDetails.rejected, (state, { payload }) => {
      state.companyLoading = false
      state.bankDetails = []
    })
  },
})

export const {
  isCompanyToggle,
  setWarehouseList,
  setCompanyDetail,
  setBankDetail,
} = companySlice.actions
export default companySlice.reducer
