import React from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const PurchaseTable = ({ listContent, listHeader, filterTable, activePo }) => {
  const navigate = useNavigate()
  return (
    <div>
      {" "}
      <div className='data-table-box scrollbar-visible pur-data'>
        <table className='data-table '>
          <thead>
            <tr>
              {listHeader?.map((li, index) => (
                <th className='' key={index}>
                  {li?.label}
                </th>
              ))}
              {activePo === 2 && <th>Action</th>}
            </tr>
          </thead>

          <tbody>
            {listContent?.map((item, index) => {
              const {
                documentNumber,
                companyName,
                status,
                orderNumber,
              } = item

              return (
                <tr
                  key={index}
                  onClick={() => {
                    activePo === 1
                      ? navigate(`/purchase/purchase-order`, {
                          state: { orderNumber },
                        })
                      : navigate(`/purchase/purchase-return`, {
                          state: { orderNumber },
                        })
                  }}
                  className='navig-table-row'
                  style={{ cursor: "pointer" }}
                >
                  <td
                    style={{ maxWidth: "fit-content" }}
                    onClick={e => {
                      if (activePo === 1) {
                        e.stopPropagation()
                        navigator.clipboard.writeText(orderNumber)
                        toast.success("Order Number is copied")
                      }
                    }}
                    title={activePo === 1 ? "Click to copy" : null}
                  >
                    {orderNumber}{" "}
                    <span className='navIcon'>
                      <i className='fa-regular fa-arrow-up-right-from-square'></i>
                    </span>
                  </td>
                  <td>{documentNumber}</td>
                  <td>-</td>
                  <td>{companyName}</td>
                  <td>{status}</td>

                  {/* <td
                    key={itemIndex}
                    //   className='tb_data padding-07'
                    className={`tb_data padding-1 ${
                      listHeader[Rowindex].key === "status" ||
                      listHeader[Rowindex].key === "creation_status" ||
                      listHeader[Rowindex].key === "good_status"
                        ? "status-column"
                        : ""
                    }`}
                  >
                    <span>{item}</span>
                    {(listHeader[Rowindex].key === "po_number" ||
                      listHeader[Rowindex].key === "document_number" ||
                      listHeader[Rowindex].key === "order_number" ||
                      listHeader[Rowindex].key === "purchase_return") && (
                      <span className='navIcon'>
                        <i className='fa-regular fa-arrow-up-right-from-square'></i>
                      </span>
                    )}
                  </td> */}

                  <td>{}</td>

                  {activePo === 2 && (
                    <td>
                      <i className='fa-regular fa-pen'></i>
                    </td>
                  )}
                  {/* <td className='tb_data padding-07'>{item}</td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PurchaseTable
