import { useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"
import Breadcrumb from "../../../components/BreadCrumbs"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
import ConformationPopup from "../../../components/ConformationPopup"
import SelectCompanyPopup from "../../purchase/components/SelectCompanyPopup"
import AddSingleCom from "../../buyers&suppliers/components/AddSingleCom"
import SalesAddressDetails from "../components/SalesAddressDetails"
import SalesPrimaryDetails from "../components/SalesPrimaryDetails"
import SalesProducts from "../components/SalesProducts"
import SalesAdditionalCharges from "../components/SalesAdditionalCharges"
import SalesPriceDetails from "../components/SalesPriceDetails"
import { getCompanyAddress } from "../../purchase/redux/PurchaseApi"
import { getSalesCompanyAddress } from "../redux/salesApi"
import SelectSalesCompanyPopup from "../components/SelectSalesCompanyPopup"
import { createOrderFormData } from "../../../helper/helper"

const SalesDetails = ({ setTitle }) => {
  const [active, setActive] = useState("sales_details")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()

  const [isEventCard, setIsEventCard] = useState(false)
  const [popUpData, setPopupData] = useState({})
  const [popup, setPopup] = useState(0)
  const navigate = useNavigate()
  const [isSelectCompany, setIsSelectCompany] = useState({
    key: "",
    value: false,
  })

  const location = useLocation()
  // const { emailDetails } = useSelector((store) => store.email);
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { productDetails } = useSelector(state => state.products, shallowEqual)

  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const salesKey =
    location?.pathname === "/sales/sales-order"
      ? "Sales Order"
      : location?.pathname === "/sales/sales-return"
        ? "Sales Return"
        : location?.pathname === "/sales/sales-proforma-invoice"
          ? "Sales Proforma Invoice"
          : location?.pathname === "/sales/delivery-challan"
            ? "Delivery Challan"
            : "Sales Quotation"

  useEffect(() => {
    setTitle(salesKey)
  }, [setTitle])

  const { purchaseLoading } = useSelector(store => store.purchase)

  const [uomList, setUomList] = useState([])
  const [grandTotals, setGrandTotals] = useState({
    taxAmount: 0,
    subtotal: 0,
    grandTotal: 0,
    advanceToPay: 0,
    reservedSubtotal: 0,
  })
  const [allSalesFormDataCollection, setAllSalesFormDataCollection] = useState({
    primaryDetails: true,
    productDetails: true,
    additionalCharges: true,
    subTotal: true,
    addressDetails: true,
  })

  const checkLength = obj => {
    if (typeof obj === "object") {
      return Object.keys(obj).length > 0
    } else {
      // toast.error()
      return false
    }
  }

  useEffect(() => {
    if (
      checkLength(allSalesFormDataCollection?.primaryDetails) &&
      checkLength(allSalesFormDataCollection?.addressDetails) &&
      checkLength(allSalesFormDataCollection?.addressDetails?.buyer) &&
      checkLength(allSalesFormDataCollection?.addressDetails?.delivery) &&
      checkLength(allSalesFormDataCollection?.addressDetails?.supplier) &&
      checkLength(allSalesFormDataCollection?.productDetails)
      // checkLength(allSalesFormDataCollection?.additionalCharges) &&
    ) {
      if (allSalesFormDataCollection?.navigate) {
        navigate(`${location?.pathname}/preview`, {
          state: {
            key: "Sales Order",
            data: { ...allSalesFormDataCollection, grandTotals },
          },
        })
      } else {
        handleSaveAndSend({ status: "draft" })
      }
    }
  }, [allSalesFormDataCollection])

  const handleSaveAndSend = async ({ status }) => {
    const formData = await createOrderFormData(
      {
        ...allSalesFormDataCollection,
        grandTotals,
      },
      status,
    )

    // const response = await dispatch(createOrder(formData))
  }

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        {isSelectCompany?.value && (
          <SelectSalesCompanyPopup
            setIsSelectCompany={setIsSelectCompany}
            isSelectCompany={isSelectCompany}
            setPopup={setPopup}
            popup={popup}
            api={getSalesCompanyAddress}
          />
        )}
        {popup === 1 && <AddSingleCom popup={popup} setPopup={setPopup} />}
        <div className='home-container'>
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>{salesKey}</div>
                  <div className='mch-path'>
                    <Breadcrumb id title={salesKey} />
                  </div>
                </div>
              </div>
              <SalesAddressDetails
                setIsSelectCompany={setIsSelectCompany}
                formData={allSalesFormDataCollection}
                setFormData={setAllSalesFormDataCollection}
                setPopup={setPopup}
              />
              <SalesPrimaryDetails
                id={id}
                PrimaryDetails={productDetails?.paint_formula}
                uomList={uomList}
                quantity={productDetails?.QuantityToProduce}
                formData={allSalesFormDataCollection}
                setFormData={setAllSalesFormDataCollection}
              />
              <SalesProducts
                id={id}
                priceDetails={productDetails?.price_details}
                formData={allSalesFormDataCollection}
                setFormData={setAllSalesFormDataCollection}
                setGrandTotals={setGrandTotals}
                grandTotals={grandTotals}
              />
              <SalesAdditionalCharges
                setGrandTotals={setGrandTotals}
                grandTotals={grandTotals}
                formData={allSalesFormDataCollection}
                setFormData={setAllSalesFormDataCollection}
              />
              <SalesPriceDetails
                grandTotals={grandTotals}
                setGrandTotals={setGrandTotals}
              />

              <div className='table-search'>
                <div
                  className='search-container'
                  style={{ justifyContent: "flex-end" }}
                >
                  {Object.values(privileges["purchase_management"]).includes(
                    "write",
                  ) && (
                      <>
                        <button className='btn-secondary header-btn'>
                          Cancel
                        </button>
                        <button className='btn-alpha header-btn bg-none'
                          onClick={() => {
                            setAllSalesFormDataCollection({
                              primaryDetails: false,
                              productDetails: false,
                              additionalCharges: false,
                              subTotal: false,
                              addressDetails: false,
                              navigate: false,
                            })
                          }}
                        >
                          Save Draft
                        </button>
                        <button className='btn-alpha header-btn'
                          onClick={() =>
                            setAllSalesFormDataCollection({
                              primaryDetails: false,
                              productDetails: false,
                              additionalCharges: false,
                              subTotal: false,
                              addressDetails: false,
                              navigate: true
                            })
                          }
                        >
                          Preview
                        </button>
                        {/* <Link
                          style={{ color: "white" }}
                          to={`${location?.pathname}/preview`}
                          state={{
                            key: salesKey,
                            // data: allSalesFormDataCollection,
                          }}
                        >
                        </Link> */}
                      </>
                    )}{" "}
                </div>
              </div>
            </div>
            <Footer />{" "}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesDetails
