import { createSlice } from "@reduxjs/toolkit"

const OrderSlice = createSlice({
  name: "order",
  initialState: {
    orderLoading: false,
    orderList: [],
    metaData: [],
  },
  reducers: {
    isOrderToggle: (state, { payload }) => {
      state.orderLoading = payload
    },
  },
})

export const { isOrderToggle } = OrderSlice?.actions
export default OrderSlice.reducer