/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { toast } from "react-toastify"
import * as Yup from "yup"
import AddressForm from "../../../components/AddressForm"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import Loader from "../../../components/Loader"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
import { convertToWebP } from "../../../helper/helper"
import { getLocalityDetail } from "../../auth/redux/authApi"
import {
  addCompanyAddress,
  deleteCompanyAddress,
  updateCompanyAddress,
  updatePrimaryAddress,
} from "../../buyers&suppliers/redux/bNsApi"
import {
  addBankDetails,
  changeSelfCompanylogo,
  deleteBankDetail,
  getBankDetails,
  getCompanyDetailData,
  getSelfCompanyAddress,
  updateCompanyDetailsData,
} from "../redux/companyApi"
import ImagePreviewBox from "../../../components/ImagePreviewBox"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
import BankForm from "../components/bankForm"
import DataNotFound from "../../../components/DatanotFound"
const tempImage = require("../../../assets/icons/logo.svg").default
// import logo from "../../../assets/icons/logo.svg"

const Company = ({ setTitle }) => {
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [active, setActive] = useState("company")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [activeInputs, setActiveInputs] = useState(false)
  const [focusedField, setFocusedField] = useState()
  const [companyLogo, setCompanyLogo] = useState(null)
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [showEditAddressForm, setShowEditAddressForm] = useState(false)
  const [isEventCard, setIsEventCard] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [isEventCardTwo, setIsEventCardTwo] = useState(false)
  const [showBankForm, setShowBankForm] = useState(false)
  const [imageInfo, setImageInfo] = useState()
  const [deleteBankDetails, setDeleteBankDetails] = useState(false)
  // const [action, setAction] = useState(false);
  // const [gstn, setGstn] = useState(false);
  let pageIndex = 0
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  const dispatch = useDispatch()
  const { companyDetails, companyLoading, companyAddress, bankDetails } =
    useSelector(store => store.company)

  const { addressLoading } = useSelector(store => store.bNs)
  const [popupData, setPopupData] = useState()

  // const handleCompanyLogo = async e => {
  //   const selectedImg = e.target.files[0]

  //   if (
  //     selectedImg.type === "image/jpeg" ||
  //     selectedImg.type === "image/webp" ||
  //     selectedImg.type === "image/png" ||
  //     selectedImg.type === "image/jpg"
  //   ) {
  //     if (selectedImg.size > 5 * 1024 * 1024) {
  //       toast.warning(
  //         "File size exceeds 5MB limit. Please select a smaller file.",
  //       )
  //       e.target.value = null
  //     } else {
  //       setCompanyLogo(URL.createObjectURL(selectedImg))
  //       const convertedImg = await convertToWebP(selectedImg)
  //       setImageInfo(convertedImg)
  //       // const updateLogo = dispatch(
  //       //   changeSelfCompanylogo({
  //       //     send: {
  //       //       company_logo: convertedImg,
  //       //     },
  //       //   }),
  //       // )
  //       e.target.value = null
  //     }
  //   } else {
  //     toast.warning("accepts only .jpeg, .jpg, .png and .webp files only")
  //     e.target.value = null
  //   }
  // }
  const deleteFunction = (address_id, title) => {
    setPopupData({
      data: {
        id: address_id,
        send: { company_id: companyDetails._id, is_delete: true },
      },
      title: title,
    })
    setIsEventCard(true)
  }

  useEffect(() => {
    if (imageInfo) {
      const data = {
        send: {
          company_logo: imageInfo,
        },
      }

      const response = dispatch(changeSelfCompanylogo(data))
    }
  }, [imageInfo])

  const companyPageSchema = Yup.object({
    company_name: Yup.string()
      .trim()
      .min(3, "Company name must be at least 3 characters long")
      .max(80, "Company Name must be less than 80 characters.")
      .required("Company name is required"),
    industry: Yup.string()
      .trim()
      .min(3, "Industry must be at least 3 characters long")
      .max(80, "Industry name must be less than 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid industry")
      .required("Industry name is required"),
    founding_year: Yup.string()
      .required("Please enter Year")
      .test(
        "invalid year one",
        "Founding year must be grater than 1900",
        value => {
          if (value < 1900) {
            return false
          } else {
            return true
          }
        },
      )
      .test(
        "invalid year two",
        "Founding year must be less than current year",
        value => {
          if (value > new Date().getFullYear()) {
            return false
          } else {
            return true
          }
        },
      ),
    gst_number: Yup.string()
      .min(15, "GST number is less than 15")
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Not matched with the valid schema, ensure that the GST number is in uppercase",
      )
      .required("Please enter valid GST number"),
    address_line_one: Yup.string()
      .trim()
      .min(3, "Address must be at least 3 characters long")
      .max(150, "Address must be less than 150 characters")
      .required("Address is required"),
    address_line_two: Yup.string()
      .trim()
      .min(3, "Address must be at least 3 characters long")
      .max(150, "Address must be less than 150 characters"),
    // .required("Address is required"),
    city: Yup.string().required("Please enter valid city name"),
    state: Yup.string().required("Please enter valid state name"),
    country: Yup.string().required("Please enter valid country name"),
    pinCode: Yup.string()
      .min(6, "Enter valid pincode please!")
      .matches(/^[1-9][0-9]{5}$/, "Enter a valid pincode")
      .required("Pincode is required"),
    email: Yup.string()
      .trim()
      .email("Enter a valid email, please")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please",
      )
      .required("Email address should not be empty")
      .matches("@poonamcoatings.com", "email must contain @poonamcoatings.com"),
    contact_num: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
      .required("Phone number is required"),
    about_us: Yup.string().trim().max(150, "words must be less than 150 words"),
  })

  const formik = useFormik({
    initialValues: {
      company_name: "",
      industry: "",
      founding_year: "",
      gst_number: "",
      address_line_one: "",
      address_line_two: "",
      city: "",
      state: "",
      country: "",
      pinCode: "",
      email: "",
      contact_num: "",
      about_us: "",
    },
    validationSchema: companyPageSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        pin_code: values.pinCode,
        email: values.email,
        phone: `+91${values.contact_num}`,
        gstNumber: values.gst_number,
        industry: values.industry,
        year: values.founding_year,
        about: values.about_us,
        name: values.company_name,
        line_one: values.address_line_one,
        line_two: values.address_line_two,
      }
      const response = await dispatch(updateCompanyDetailsData(data))

      if (response.payload) {
        setActiveInputs(false)
      }
    },
  })

  useEffect(() => {
    setTitle("Company Details")
    dispatch(getCompanyDetailData())
    dispatch(getSelfCompanyAddress())
    dispatch(getBankDetails({ page: 0, limit: 0, search: "", sort: "desc" }))
  }, [setTitle, dispatch])

  useEffect(() => {
    if (companyDetails && Object.keys(companyDetails)?.length > 0) {
      formik.setValues({
        company_name: companyDetails?.name,
        industry: companyDetails?.industry,
        founding_year: companyDetails?.foundingYear,
        gst_number: companyDetails?.gst?.value,
        address_line_one: companyDetails?.line_one,
        address_line_two: companyDetails?.line_two,
        city: companyDetails?.cityName,
        state: companyDetails?.stateName,
        country: companyDetails?.countryName
          ? companyDetails?.countryName
          : "India",
        pinCode: companyDetails?.pin_code,
        email: companyDetails?.companyEmail?.value,
        contact_num: companyDetails?.companyPhone.slice(-10),
        about_us: companyDetails?.about,
      })

      setCompanyLogo(companyDetails?.logo)
    }
  }, [companyDetails])

  // const year = new Date().getFullYear();
  // const years = Array.from(new Array(10), (val, index) => ({
  //   label: `${year - index}`,
  //   value: `${year - index}`,
  // }));

  const hitPincodeApi = async value => {
    const sendData = {
      pin_code: value,
    }

    const response = await dispatch(getLocalityDetail(sendData))

    if (response?.payload) {
      formik.setValues({
        ...formik.values,
        pinCode: value,
        city: response?.payload?.data?.data?.city_name,
        state: response?.payload?.data?.data?.state_name,
        country: response?.payload?.data?.data?.country_name,
      })
    }
  }
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  const { tableLoading: isCompanyLoading } = useLoaderDelay({
    loadingState: companyLoading,
  })

  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        {showAddressForm && (
          <AddressForm
            customSetter={setShowAddressForm}
            customState={showAddressForm}
            api={addCompanyAddress}
            type='Add'
            companyData={popupData}
          />
        )}
        {showEditAddressForm && (
          <AddressForm
            customSetter={setShowEditAddressForm}
            customState={showEditAddressForm}
            api={updateCompanyAddress}
            type='Edit'
            companyData={popupData}
            popUpSetter={setIsEventCardTwo}
          />
        )}
        {showBankForm && (
          <BankForm
            customSetter={setShowBankForm}
            customState={showBankForm}
            api={addBankDetails}
            type={showBankForm?.type ? showBankForm?.type : "Add"}
            companyData={companyDetails?._id}
          />
        )}

        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
                setMbsidebar={setMbsidebar}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Company Details</div>
                  <div className='mch-path'>
                    <Breadcrumb
                    // id={companyDetails?._id}
                    // title={companyDetails?.name}
                    />
                    {/* Company / Poonam Coatings */}
                  </div>
                </div>
              </div>
              <div className='company-header'>
                <div className='header-content'>
                  <div className='company-details'>
                    <label
                      htmlFor='choose_photo'
                      className='updtIMG showCameraIcon'
                      onClick={() => {
                        if (Object.keys(companyDetails)?.length > 0) {
                          setShowPreview(true)
                        }
                      }}
                    >
                      <span className='material-symbols-rounded updatelogo'>
                        add_a_photo
                      </span>
                      <img
                        src={companyLogo ? companyLogo : tempImage}
                        alt='company-logo'
                        className='company-logo-edit'
                      />
                      {/* <input
                        id='choose_photo'
                        hidden={true}
                        type='file'
                        accept='.png,.jpeg,.jpg,.webp'
                        className='company-pic-input'
                        onChange={handleCompanyLogo}
                      /> */}
                    </label>
                    <div>
                      <h2 className='company-name'>
                        {companyDetails?.name
                          ? companyDetails?.name
                          : "Poonam Coatings"}
                      </h2>
                      <p className='company-address'>
                        <span className='material-symbols-outlined pro-loc'>
                          location_on
                        </span>
                        {companyDetails?.cityName && companyDetails?.stateName
                          ? `${companyDetails?.cityName}, ${companyDetails?.stateName}`
                          : "Ahmedabad, Gujrat"}
                      </p>
                    </div>
                  </div>

                  {/* <label htmlFor="choose_photo" className="change-image-btn">
                    Change Logo
                    <input
                      id="choose_photo"
                      hidden={true}
                      type="file"
                      accept=".png,.jpeg,.jpg,.webp"
                      className="company-pic-input"
                      onChange={handleCompanyLogo}
                    />
                  </label> */}
                </div>
              </div>
              {/* ____ Form_____ */}
              <form className='company_details_form'>
                <div className='details_control'>
                  <h3>Company Details</h3>
                  <div className='detail-btn'>
                    {activeInputs ? (
                      <button
                        type='submit'
                        onClick={formik.handleSubmit}
                        className='btn-primary'
                        disabled={companyLoading}
                        style={companyLoading ? { cursor: "not-allowed" } : {}}
                      >
                        {companyLoading ? (
                          <ClipLoader
                            loading={true}
                            size={15}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                            color='white'
                          />
                        ) : (
                          "Save"
                        )}
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='btn-secondary'
                        onClick={e => {
                          e.preventDefault()
                          setActiveInputs(true)
                        }}
                      >
                        <span className='material-symbols-outlined small'>
                          border_color
                        </span>
                        Edit
                      </button>
                    )}
                  </div>
                </div>
                <div className='form-group'>
                  <div
                    className={`input-field ${
                      formik.values.company_name !== "" ||
                      focusedField === "company"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='company_name' className='toggle-label'>
                      Name of Company
                    </label>
                    <input
                      id='company_name'
                      name='company_name'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      disabled={!activeInputs}
                      value={formik.values.company_name || ""}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /(?:^|\s)\S/g,
                          match => match.toUpperCase(),
                        )
                      }}
                      maxLength={81}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("company")}
                      // onPaste={(e) => {
                      //   e.preventDefault();
                      // }}
                      // onCopy={(e) => {
                      //   e.preventDefault();
                      // }}
                    />
                    {formik.touched.company_name &&
                    formik.errors.company_name ? (
                      <span className='err'>{formik.errors.company_name}</span>
                    ) : null}
                  </div>
                  <div
                    className={`input-field ${
                      formik.values.email !== "" || focusedField === "email"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='email' className='toggle-label'>
                      Official Email Address
                    </label>
                    <input
                      id='email'
                      name='email'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.email || ""}
                      disabled={!activeInputs}
                      // maxLength={81}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("email")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <span className='err'>{formik.errors.email}</span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.contact_num !== "" ||
                      focusedField === "contactNum"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='contact_num' className='toggle-label'>
                      Contact Number
                    </label>
                    <div
                      className={formik.values.contact_num ? "phone_box" : ""}
                    >
                      {
                        <span className='phone_code'>
                          {formik.values.contact_num ? "+91" : ""}
                        </span>
                      }
                      <input
                        id='contact_num'
                        name='contact_num'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.contact_num || ""}
                        disabled={!activeInputs}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 10)
                            .replace(/\D/g, "")
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("contactNum")}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                      />
                    </div>{" "}
                    {formik.touched.contact_num && formik.errors.contact_num ? (
                      <span className='err'>{formik.errors.contact_num}</span>
                    ) : null}
                  </div>
                  <div className='col-2'>
                    <div
                      className={`input-field ${
                        formik.values.industry !== "" ||
                        focusedField === "industry"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='industry' className='toggle-label'>
                        Industry
                      </label>
                      <input
                        id='industry'
                        name='industry'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.industry || ""}
                        disabled={!activeInputs}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^A-Za-z ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        maxLength={81}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("industry")}
                        // onPaste={(e) => {
                        //   e.preventDefault();
                        // }}
                        // onCopy={(e) => {
                        //   e.preventDefault();
                        // }}
                      />
                      {formik.touched.industry && formik.errors.industry ? (
                        <span className='err'>{formik.errors.industry}</span>
                      ) : null}
                    </div>

                    <div
                      className={`input-field ${
                        formik.values.founding_year !== "" ||
                        focusedField === "founding_year"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='founding_year' className='toggle-label'>
                        Founding Year
                      </label>
                      <input
                        id='founding_year'
                        name='founding_year'
                        type='text'
                        className='form-input'
                        disabled={!activeInputs}
                        onChange={formik.handleChange}
                        value={formik.values.founding_year || ""}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 4)
                            .replace(/\D/g, "")
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("founding_year")}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                        max='4'
                      />
                      {formik.touched.founding_year &&
                      formik.errors.founding_year ? (
                        <span className='err'>
                          {formik.errors.founding_year}
                        </span>
                      ) : null}
                    </div>

                    {/* <DropDownBox
                      options={years}
                      defaultValue="Founding Year"
                      customSetter={formik.setFieldValue}
                      customLabel="Founding Year"
                      disabled={activeInputs}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                    />
                    {formik.touched.founding_year &&
                    formik.errors.founding_year ? (
                      <span className="err">{formik.errors.founding_year}</span>
                    ) : null} */}
                  </div>
                  <div
                    className={`input-field ${
                      formik.values.about_us !== "" ||
                      focusedField === "aboutUs"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='about_us' className='toggle-label'>
                      About Us
                    </label>
                    <textarea
                      id='about_us'
                      name='about_us'
                      rows={4}
                      className={
                        !activeInputs
                          ? "form-input disabeldTextArea"
                          : "form-input"
                      }
                      onChange={formik.handleChange}
                      disabled={!activeInputs}
                      value={formik.values.about_us || ""}
                      maxLength={151}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("aboutUs")}
                    />
                    {formik.touched.about_us && formik.errors.about_us ? (
                      <span className='err'>{formik.errors.about_us}</span>
                    ) : null}
                  </div>

                  <div>
                    {/* <div className="col-2 gstn"> */}
                    <div
                      className={`input-field ${
                        formik.values.gst_number !== "" ||
                        focusedField === "gstNum"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='gst_number' className='toggle-label'>
                        GST Number
                      </label>
                      <input
                        id='gst_number'
                        name='gst_number'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.gst_number || ""}
                        disabled={!activeInputs}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 15)
                            .toUpperCase()
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("gstNum")}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                      />
                      {formik.touched.gst_number && formik.errors.gst_number ? (
                        <span className='err'>{formik.errors.gst_number}</span>
                      ) : null}
                    </div>
                    {/* <button
                        type="button"
                        disabled={!gstn}
                        className={
                          gstn ? "fetch_details enabled" : "fetch_details"
                        }
                      >
                        Fetch Details
                      </button> */}
                    {/* </div> */}

                    {/* {activeInputs && (
                      <div
                        className={!gstn ? "gstnValidate" : "gstnValidate true"}
                      >
                        <span className="material-symbols-rounded small">
                          {!gstn ? "lightbulb" : "error"}
                        </span>{" "}
                        <span>
                          {!gstn
                            ? "Verify the GST number to capture all the details automatically."
                            : "Unable to fetch details from GST portal. Please enter company details manually."}
                        </span>
                      </div>
                    )} */}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.address_line_one !== "" ||
                      focusedField === "address1"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='address-line-one' className='toggle-label'>
                      Address Line 1
                    </label>
                    <input
                      id='address-line-one'
                      name='address_line_one'
                      type='text'
                      className='form-input'
                      maxLength={151}
                      onChange={formik.handleChange}
                      value={formik.values.address_line_one || ""}
                      disabled={!activeInputs}
                      // onInput={e => {
                      //   e.target.value = e.target.value
                      //     .replace(/[^A-Za-z ]/gi, "")
                      //     .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      // }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("address1")}
                      // onPaste={(e) => {
                      //   e.preventDefault();
                      // }}
                      onCopy={e => {
                        e.preventDefault()
                      }}
                    />
                    {formik.touched.address_line_one &&
                    formik.errors.address_line_one ? (
                      <span className='err'>
                        {formik.errors.address_line_one}
                      </span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.address_line_two !== "" ||
                      focusedField === "address2"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='address-line-one' className='toggle-label'>
                      Address Line 2
                    </label>
                    <input
                      id='address_line_two'
                      name='address_line_two'
                      type='text'
                      className='form-input'
                      disabled={!activeInputs}
                      value={formik.values.address_line_two || ""}
                      onChange={formik.handleChange}
                      // onInput={e => {
                      //   e.target.value = e.target.value
                      //     .replace(/[^A-Za-z ]/gi, "")
                      //     .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      // }}
                      // maxLength={151}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("address2")}
                      // onPaste={(e) => {
                      //   e.preventDefault();
                      // }}
                      onCopy={e => {
                        e.preventDefault()
                      }}
                      maxLength={151}
                    />
                    {formik.touched.address_line_two &&
                    formik.errors.address_line_two ? (
                      <span className='err'>
                        {formik.errors.address_line_two}
                      </span>
                    ) : null}
                  </div>

                  <div className='col-2'>
                    <div
                      className={`input-field ${
                        formik.values.pinCode !== "" ||
                        focusedField === "pinCode"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='pinCode' className='toggle-label'>
                        Pincode
                      </label>
                      <div className='pinCode_wrapper'>
                        <input
                          id='pinCode'
                          name='pinCode'
                          type='text'
                          className='form-input'
                          onChange={formik.handleChange}
                          value={formik.values.pinCode || ""}
                          disabled={!activeInputs}
                          onInput={e => {
                            e.target.value = e.target.value
                              .slice(0, 6)
                              .replace(/\D/g, "")
                            if (e.target.value?.length === 6) {
                              hitPincodeApi(e.target.value)
                            } else {
                              formik.setFieldValue("city", "")
                              formik.setFieldValue("state", "")
                              formik.setFieldValue("country", "")
                            }
                          }}
                          onBlur={e => {
                            setFocusedField("")
                            formik.handleBlur(e)
                          }}
                          onFocus={() => setFocusedField("pinCode")}
                          // onPaste={(e) => {
                          //   e.preventDefault();
                          // }}
                          onCopy={e => {
                            e.preventDefault()
                          }}
                          maxLength={6}
                        />
                        {addressLoading ? (
                          <span className='pinCode_spinner'>
                            <ClipLoader
                              loading={true}
                              size={15}
                              aria-label='Loading Spinner'
                              data-testid='loader'
                            />
                          </span>
                        ) : null}
                      </div>
                      {formik.touched.pinCode && formik.errors.pinCode ? (
                        <span className='err'>{formik.errors.pinCode}</span>
                      ) : null}
                    </div>

                    <div
                      className={`input-field ${
                        formik.values.city !== "" || focusedField === "city"
                          ? "active"
                          : ""
                      }`}
                    >
                      {" "}
                      <label htmlFor='city' className='toggle-label'>
                        City
                      </label>
                      <input
                        id='city'
                        name='city'
                        type='text'
                        className='form-input'
                        // disabled={!activeInputs}
                        disabled
                        onChange={formik.handleChange}
                        value={formik.values.city || ""}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^A-Za-z ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        // maxLength={20}
                        onFocus={() => setFocusedField("city")}
                      />
                      {formik.touched.city && formik.errors.city ? (
                        <span className='err'>{formik.errors.city}</span>
                      ) : null}
                    </div>
                  </div>

                  <div className='col-2'>
                    <div
                      className={`input-field ${
                        formik.values.state !== "" || focusedField === "state"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='state' className='toggle-label'>
                        State
                      </label>
                      <input
                        id='state'
                        name='state'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.state || ""}
                        // disabled={!activeInputs}
                        disabled
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^A-Za-z ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        // maxLength={20}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("state")}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                      />
                      {formik.touched.state && formik.errors.state ? (
                        <span className='err'>{formik.errors.state}</span>
                      ) : null}
                    </div>

                    <div
                      className={`input-field ${
                        formik.values.country !== "" ||
                        focusedField === "country"
                          ? "active"
                          : ""
                      }`}
                    >
                      <label htmlFor='country' className='toggle-label'>
                        Country
                      </label>
                      <input
                        id='country'
                        name='country'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.country || ""}
                        // disabled={!activeInputs}
                        disabled
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^A-Za-z ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        // maxLength={20}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("country")}
                      />
                      {formik.touched.country && formik.errors.country ? (
                        <span className='err'>{formik.errors.country}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
              {Object.keys(companyDetails)?.length > 0 && (
                <div className='bns-company-details'>
                  <div className='company_details_form'>
                    <div className='details_control'>
                      <h3>Bank Details</h3>
                      <button
                        className='btn-secondary'
                        onClick={() => {
                          setShowBankForm(true)
                          setPopupData({ companyDetails })
                        }}
                      >
                        <span className='material-symbols-outlined small'>
                          add
                        </span>
                        <span>ADD</span>
                      </button>
                    </div>
                    {bankDetails?.length > 0 ? (
                      <div className='card-container'>
                        {bankDetails?.map((item, i) => {
                          const { type } = item
                          if (
                            // item.type === "warehouse" ||
                            // item.constraint === "primary"
                            true
                          ) {
                            pageIndex = pageIndex + 1
                            return (
                              <div
                                className='card'
                                key={i}
                                onClick={() => {
                                  setShowBankForm({ ...item, type: "Edit" })
                                }}
                              >
                                {/* {type === "work" && (
                                <div className='primary-ribbon'>
                                  <span>Primary</span>
                                </div>
                              )} */}
                                <div className='col1'>
                                  <h4 className='card-title'>
                                    <span className='material-symbols-outlined'>
                                      account_balance
                                    </span>{" "}
                                    {item.bank_name.toUpperCase()}
                                  </h4>{" "}
                                  {/* <h3 className='card-name'>{item.name}</h3> */}
                                  <div className='card-address'>
                                    <p>
                                      <b> Account No:</b>{" "}
                                      {` ${item.account_no?.value}, `}
                                    </p>{" "}
                                    <p>
                                      <b> Account Type:</b>{" "}
                                      <span className='capitalize'>{` ${item?.account_type}, `}</span>
                                    </p>
                                    <p>
                                      <b> Account Holder:</b>{" "}
                                      {` ${item.account_name}`}
                                    </p>
                                    <p>
                                      <b>IFSC Code:</b>
                                      {` ${item.ifsc}`}
                                    </p>
                                    <p>
                                      <b>Branch:</b>
                                      {` ${item.branch_name}`}
                                    </p>
                                    <p>
                                      <b>UPI:</b>
                                      {` ${item?.UPI}`}
                                    </p>
                                  </div>
                                </div>
                                <div className='col2'>
                                  <button
                                    className='icon-btn delete hover-close'
                                    onClick={e => {
                                      e.stopPropagation()
                                      setDeleteBankDetails({
                                        data: {
                                          id: item._id,
                                          send: {
                                            is_delete: true,
                                          },
                                        },
                                        title: `${item.bank_name.toUpperCase()} Account`,
                                      })
                                    }}
                                  >
                                    <span className='material-symbols-outlined medium'>
                                      close
                                    </span>
                                  </button>
                                </div>
                              </div>
                            )
                          }
                          return null
                        })}
                      </div>
                    ) : (
                      <DataNotFound />
                    )}
                  </div>
                </div>
              )}{" "}
              {Object.keys(companyDetails)?.length > 0 && (
                <div className='bns-company-details'>
                  <div className='company_details_form'>
                    <div className='details_control'>
                      <h3>Warehouse Address</h3>
                      <button
                        className='btn-secondary'
                        onClick={() => {
                          setShowAddressForm(!showAddressForm)
                          setPopupData({ companyDetails })
                        }}
                      >
                        <span className='material-symbols-outlined small'>
                          add
                        </span>
                        <span>ADD</span>
                      </button>
                    </div>

                    <div className='card-container'>
                      {companyAddress?.map((item, i) => {
                        const { type, constraint } = item
                        if (
                          // item.type === "warehouse" ||
                          // item.constraint === "primary"
                          true
                        ) {
                          pageIndex = pageIndex + 1
                          return (
                            <div
                              className='card'
                              key={i}
                              onClick={() => {
                                setShowEditAddressForm(true)
                                setPopupData({
                                  companyDetails,
                                  companyAddress: item,
                                })
                              }}
                            >
                              {constraint === "primary" && (
                                <div className='primary-ribbon'>
                                  <span>Primary</span>
                                </div>
                              )}
                              <div className='col1'>
                                <h4 className='card-title'>
                                  <span className='material-symbols-outlined'>
                                    {item?.type === "work"
                                      ? "work"
                                      : "warehouse"}
                                  </span>{" "}
                                  {item.type}
                                </h4>{" "}
                                <h3 className='card-name'>{item.name}</h3>
                                <div className='card-address'>
                                  <p>{`${item.address.line_one}, `}</p>
                                  <p>{`${item.address.line_two}${item.address.line_two ? "," : ""}`}</p>
                                  <p>{`${item.city_detail.name} - ${item.address.pin_code},`}</p>
                                  <p>{`${item.state_detail.name} - ${item.country_detail.name}`}</p>
                                </div>
                              </div>
                              <div className='col2'>
                                <button
                                  className='icon-btn delete hover-close'
                                  onClick={e => {
                                    e.stopPropagation()
                                    deleteFunction(item._id, "Address Details")
                                  }}
                                >
                                  <span className='material-symbols-outlined medium'>
                                    close
                                  </span>
                                </button>
                              </div>
                            </div>
                          )
                        }
                        return null
                      })}
                    </div>
                  </div>
                </div>
              )}{" "}
            </div>

            <Footer />
          </div>
          <Loader isLoading={isCompanyLoading} />
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteCompanyAddress}
              popUpData={popupData}
              reFetchApi={getSelfCompanyAddress}
            />
          )}
          {deleteBankDetails && (
            <ConformationPopup
              customState={deleteBankDetails}
              customSetter={setDeleteBankDetails}
              mainTitle=''
              subTitle=''
              api={deleteBankDetail}
              popUpData={deleteBankDetails}
            />
          )}
          {isEventCardTwo && (
            <ConformationPopup
              customState={isEventCardTwo}
              customSetter={setIsEventCardTwo}
              mainTitle='Are you sure you want to make this address your Primary address?'
              subTitle='This action will change your primary address'
              api={updatePrimaryAddress}
              popUpData={isEventCardTwo}
              reData={""}
              reFetchApi={getSelfCompanyAddress}
              popupType='warehouse'
              toastMessage='Primary address changed'
            />
          )}{" "}
          {showPreview && (
            <ImagePreviewBox
              customSetter={setShowPreview}
              imageChangeApi={changeSelfCompanylogo}
              customState={showPreview}
              slug={"company_logo"}
              incomingImage={companyLogo ? companyLogo : tempImage}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default Company
