import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { setPageDetails } from "../modules/auth/redux/authSlice"

const MinimizedSidebar = ({
  active,
  setActive,
  initialRenderAnimation,
  api = false,
  apiData = {
    page: "0",
    limit: "5",
    sort: "desc",
    filter: "",
    search: "",
  },
}) => {
  const [dashboard, setDashboard] = useState(false),
    [buyers, setBuyers] = useState(false),
    [calendar, setCalendar] = useState(false),
    [settings, setSettings] = useState(false),
    [inventory, setInventory] = useState(false),
    [sales, setSales] = useState(false),
    [order, setOrder] = useState(false),
    [purchase, setPurchase] = useState(false),
    [approval, setApproval] = useState(false),
    [payment, setPayment] = useState(false),
    [openList, setOpenList] = useState(null)

  const location = useLocation()
  const navigate = useNavigate()
  const { companyDetails } = useSelector(store => store.company)
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (active === "dashboard") {
      setDashboard(true)
    }
    if (active === "buyers") {
      setBuyers(true)
    }
    if (active === "inventoryList" || active === "stock-movement") {
      setInventory(true)
    }
    if (active === "sales_quotation" || active === "sales_details") {
      setSales(true)
    }
    if (active === "order_sales" || active === "order_purchase") {
      setOrder(true)
    }
    if (active === "stockAlert" || active === "purchaseDetails") {
      setPurchase(true)
    }
    if (active === "approvals") {
      setApproval(true)
    }
    if (active === "payment") {
      setPayment(true)
    }
    if (
      active === "profile" ||
      active === "company" ||
      active === "user" ||
      active === "email" ||
      active === "catalogue"
    ) {
      setSettings(true)
    }
    if (active === "calendar") {
      setCalendar(true)
    }
  }, [active])

  const handleListClick = navTabs => {
    // Close the previously open list
    if (openList && openList !== navTabs && openList.setState) {
      openList.setState(false)
    }
    // Toggle the state of the clicked list if it has a setState function
    if (navTabs.setState && typeof navTabs.setState === "function") {
      navTabs.setState(!navTabs.state)
    }
    // Update the currently open list
    setOpenList(navTabs)
    setActive(navTabs.nav_title)

    if (navTabs.goto_link) {
      navigate(navTabs.goto_link)
    }
  }

  const linkColor = navTabs => {
    if (navTabs.goto_link === location.pathname) {
      return { color: "#1F98D5" }
    }
  }

  const navList = [
    {
      nav_title: "Dashboard",
      nav_icon: "dashboard",
      goto_link: "/",
      privilege: "head",
    },
    {
      nav_title: "Buyers & Suppliers",
      nav_icon: "contacts",
      goto_link: "/buyers-and-suppliers",
      privilege: "buyers_and_suppliers_management",
    },
    {
      nav_title: "Calendar",
      nav_icon: "calendar_month",
      goto_link: "/calendar",
      privilege: "calendar_management",
    },
    {
      nav_title: "Sales & Purchase",
      nav_icon: "store",
      goto_link: "",
      privilege: "sales_purchase_management",
    },
    {
      nav_title: "Product Management",
      nav_icon: "  Inventory_2",
      goto_link: "/products",
      privilege: "production_management",
    },
    {
      nav_title: "Inventory",
      nav_icon: "inventory",
      goto_link: "/inventory",
      privilege: "inventory_management",
    },
    // {
    //   nav_title: "Inventory",
    //   nav_icon: "inventory",
    //   goto_link: "",
    //   privilege: "inventory_management",
    //   state: inventory,
    //   setState: setInventory,
    //   content: [
    //     {
    //       matchName: "inventoryList",
    //       title: "Inventory",
    //       goto_link: "/inventory",
    //       privilege: "inventory_management",
    //     },
    //     {
    //       matchName: "stock-movement",
    //       title: "Stock Management",
    //       goto_link: "/stock-movement",
    //       privilege: "inventory_management",
    //     },
    //   ],
    // },
    {
      nav_title: "Sales",
      nav_icon: "store",
      goto_link: "",
      privilege: "sales_management",
      state: sales,
      setState: setSales,
      content: [
        {
          matchName: "sales_quotation",
          title: "Sales Quotation",
          goto_link: "/sales-quotation",
          privilege: "sales_management",
        },
        {
          matchName: "sales_details",
          title: "Sales Details",
          goto_link: "/sales",
          privilege: "sales_management",
        },
      ],
    },
    {
      nav_title: "Purchase",
      nav_icon: "account_balance_wallet",
      goto_link: "",
      privilege: "purchase_management",
      state: purchase,
      setState: setPurchase,
      content: [
        {
          matchName: "stockAlert",
          title: "Stock Alert",
          goto_link: "/stock-alert",
          privilege: "purchase_management",
        },
        {
          matchName: "purchaseDetails",
          title: "Purchase Details",
          goto_link: "/purchase",
          privilege: "purchase_management",
        },
      ],
    },
    {
      nav_title: "Order Management",
      nav_icon: "package_2",
      goto_link: "",
      privilege: "order_management",
      state: order,
      setState: setOrder,
      content: [
        {
          matchName: "order_sales",
          title: "Sales Order",
          goto_link: "/order/sales",
          privilege: "purchase_management",
        },
        {
          matchName: "order_purchase",
          title: "Purchase Order",
          goto_link: "/order/purchase",
          privilege: "purchase_management",
        },
      ],
    },
    {
      nav_title: "Approval",
      nav_icon: "  order_approve",
      goto_link: "/approvals",
      privilege: "production_management",
    },

    {
      nav_title: "Payments",
      nav_icon: "payments",
      goto_link: "/payment",
      privilege: "payment_management",
    },
    // {
    //   nav_title: "Reports & Intelligence",
    //   nav_icon: "monitoring",
    //   goto_link: "",
    //   privilege: "reports_intelligence_management",
    // },
    // {
    //   nav_title: "Resource Planning",
    //   nav_icon: "deployed_code_account",
    //   goto_link: "",
    //   privilege: "resource_planning_management",
    // },
    {
      nav_title: "Account",
      nav_icon: "checkbook",
      goto_link: "",
      privilege: "account_tally_management",
    },
    // {
    //   nav_title: "Departments",
    //   nav_icon: "groups",
    //   goto_link: "/departments",
    //   privilege: "department_management",
    // },
    {
      nav_title: "Settings",
      nav_icon: "settings",
      state: settings,
      setState: setSettings,
      privilege: "head",
      content: [
        {
          matchName: "profile",
          title: "Profile",
          goto_link: "/profile",
          privilege: "head",
        },
        {
          matchName: "company",
          title: "Company",
          // goto_link: `/${
          //   companyDetails?.name
          //     ? companyDetails?.name.replaceAll(" ", "-").toLowerCase()
          //     : "poonam-coatings"
          // }`,
          goto_link: "/company",
          privilege: "company_management",
        },
        {
          matchName: "user",
          title: "User Management",
          goto_link: "/user",
          privilege: "user_management",
        },
        {
          matchName: "email",
          title: "Email Management",
          goto_link: "/email",
          privilege: "email_management",
        },
        {
          matchName: "department",
          title: "Department Management",
          goto_link: "/departments",
          privilege: "department_management",
        },
        {
          matchName: "catalogue",
          title: "Product Catalogue",
          goto_link: "/product-catalogue",
          privilege: "production_management",
        },
      ],
    },
  ]
  const resetMetaData = () => {
    if (api && pageDetails?.page > 0) {
      dispatch(api(apiData))
    }
    if (Object.keys(pageDetails).length > 0) {
      dispatch(setPageDetails({}))
    }
  }
  return (
    <div
      className={`sidebar-main mini ${
        MinimizedSidebar ? " mini_sidebar-out-ani" : " "
      } `}
    >
      {/* ____ Heading _______ */}
      <div className={`sidebar-header mini `} onClick={() => navigate("/")}>
        <img
          className={initialRenderAnimation ? "mini_sidebar-in-ani" : ""}
          src={require("../assets/images/logo192.png")}
          alt=''
          srcSet=''
        />
      </div>

      {/* Navigation List */}
      <ul className='min-sidebar-menu'>
        {navList.map((navTabs, i) => {
          const { privilege } = navTabs

          {
            if (
              (privileges.hasOwnProperty(privilege) &&
                Object?.values(privileges[privilege])?.includes("read")) ||
              privilege === "head"
            ) {
              return (
                <li
                  key={i}
                  className={`${
                    navTabs.state === true
                      ? "nav-button active mini_sidebar-in-ani"
                      : "nav-button " +
                        (initialRenderAnimation ? " mini_sidebar-in-ani" : "")
                  } `}
                  onClick={() => {
                    handleListClick(navTabs)
                    resetMetaData()
                  }}
                >
                  <div
                    className={navTabs.state === true ? "sc-c active" : "sc-c"}
                  >
                    <span
                      className={`material-symbols-rounded `}
                      style={linkColor(navTabs)}
                    >
                      {navTabs.nav_icon}
                    </span>
                  </div>

                  <div
                    className={`${
                      navTabs.state === true ? "accordion active" : "accordion"
                    } `}
                  >
                    <h4
                      className={
                        navTabs.state === true
                          ? "nav-link-title active"
                          : "nav-link-title"
                      }
                    >
                      {navTabs.nav_title}

                      {navTabs.content ? (
                        <span
                          className={
                            navTabs.state
                              ? "material-symbols-outlined arrow-down small-icon"
                              : "material-symbols-outlined small-icon"
                          }
                        >
                          navigate_next
                        </span>
                      ) : null}
                    </h4>

                    {/* ____ Nested List ______  */}
                    {navTabs.state === true && (
                      <ul className='nested-list'>
                        {navTabs.content
                          ? navTabs.content.map((list, i) => {
                              const { privilege } = list

                              if (
                                (privileges.hasOwnProperty(privilege) &&
                                  Object?.values(
                                    privileges[privilege],
                                  )?.includes("read")) ||
                                privilege === "head"
                              ) {
                                return (
                                  <li
                                    key={i}
                                    className='nested-list-link'
                                    onClick={() => {
                                      setActive(list.title)
                                    }}
                                  >
                                    <Link
                                      className={
                                        active === list.matchName
                                          ? "active-link"
                                          : ""
                                      }
                                      to={list.goto_link}
                                    >
                                      {list.title}
                                    </Link>
                                  </li>
                                )
                              }
                            })
                          : null}
                      </ul>
                    )}
                  </div>
                </li>
              )
            }
          }
        })}
      </ul>
    </div>
  )
}

export default MinimizedSidebar
