import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import Skelaton from "../../../components/Skelaton"
import * as Yup from "yup"
import FormButtons from "../../../components/SubmitBtns"
import { toast } from "react-toastify"
import ReactQuill from "react-quill"
import { formatTimestamp } from "../../../helper/helper"
import { ClipLoader } from "react-spinners"

const SalesAdditionalCharges = ({
  formData,
  setFormData,
  setGrandTotals,
  grandTotals,
}) => {
  const [addStyle, setAddStyle] = useState(true)
  const [activeBar, setActiveBar] = useState("extraCharge")
  const [toggleState, setToggleState] = useState(false)
  const [comments, setComments] = useState("")
  const [signature, setSignature] = useState()
  const [terms, setTerms] = useState("")

  // const { privileges } = useSelector(state => state.auth, shallowEqual)

  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      rows: [
        {
          description: "",
          total: "",
        },
      ],
    },
    validationSchema: Yup.object().shape({
      rows: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required("Description is required"),
          total: Yup.string().required("Total is required"),
        }),
      ),
    }),
    onSubmit: async values => { },
  })

  const formDataSetter = () => {
    setFormData(oldList => {
      let newList = JSON.parse(JSON.stringify(oldList))
      newList.additionalCharges = {
        extraCharges: formik.values,
        comment: comments,
        signature: signature,
        terms: terms
      }

      return newList
    })
  }

  useEffect(() => {
    if (!formData?.additionalCharges) {
      formDataSetter()
    }
  }, [formData?.additionalCharges, comments, formik.values, signature])
  
  useEffect(() => {
    let grandSubtotal = 0
    formik.values.rows.forEach(item => {
      const { total } = item
      grandSubtotal += Number(total)
    })

    grandSubtotal =
      Number(grandSubtotal) > 0
        ? Number(grandSubtotal) + Number(grandTotals?.reservedSubtotal)
        : grandTotals?.reservedSubtotal
    setGrandTotals(oldValues => {
      let newValues = JSON.parse(JSON.stringify(oldValues))

      newValues.subtotal = grandSubtotal
      newValues.grandTotal = newValues?.subtotal + newValues?.taxAmount
      return newValues
    })
  }, [toggleState, formik.values.rows])

  return (
    <>
      <div
        className='main-box-padding data-table-container'
        style={{ padding: "1.8rem", borderRadius: "1.5rem" }}
      >
        <div className='modelEx_content singleAdd'>
          <div className='add-navDiv'>
            <button
              className={
                "product-cat-btn2" +
                (activeBar === "extraCharge" ? " active" : "")
              }
              onClick={() => setActiveBar("extraCharge")}
            >
              Extra Charges
            </button>
            <button
              className={
                "product-cat-btn2" + (activeBar === "comment" ? " active" : "")
              }
              onClick={() => setActiveBar("comment")}
            >
              Comments
            </button>
            <button
              className={
                "product-cat-btn2" +
                (activeBar === "signature" ? " active" : "")
              }
              onClick={() => setActiveBar("signature")}
            >
              Attach Signature
            </button>
            <button
              className={
                "product-cat-btn2" + (activeBar === "t&c" ? " active" : "")
              }
              onClick={() => setActiveBar("t&c")}
            >
              Terms & Condition
            </button>
          </div>
          {activeBar === "extraCharge" && (
            <AdditionalCharge privileges={privileges}
              formik={formik}
              setToggleState={setToggleState}
              toggleState={toggleState}
            />
          )}
          {activeBar === "comment" && <Comments setComments={setComments} />}
          {activeBar === "signature" && <AttachSignature setSignature={setSignature} />}
          {activeBar === "t&c" && <TermsCondition setTerms={setTerms} />}
        </div>
      </div>
    </>
  )
}

export default SalesAdditionalCharges

const AdditionalCharge = ({
  privileges,
  formik,
  setToggleState,
  toggleState,
}) => {

  const addRow = () => {
    const newRow = {
      description: "",
      total: "",
    }
    formik.setFieldValue("rows", [...formik.values.rows, newRow])
  }

  const deleteRow = index => {
    const rows = [...formik.values.rows]
    rows.splice(index, 1)
    formik.setFieldValue("rows", rows)
  }

  return (
    <form className='profile_personal_details' onSubmit={formik.handleSubmit}>
      {false ? (
        <Skelaton col={4} row={5} />
      ) : true ? (
        <>
          <div className='modelEx_content'>
            {/* <div className='data-table-container'> */}
            <div >
              <div
                className='td_table_container'
              // style={{paddingBottom:"6rem"}}
              >
                <table className='td_table extra-ps'>
                  <thead>
                    <tr>
                      <th colSpan={5}>Extra Charges Description</th>
                      <th>Total</th>

                      <td className='stick-rh' />
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.rows.map((row, index) => (
                      <tr key={index}>
                        <td colSpan={5}>
                          <div className='input-field'>
                            <input
                              type='text'
                              name={`rows[${index}].description`}
                              onChange={formik.handleChange}
                              value={row.description}
                              style={{ cursor: "not-allowed" }}
                              className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.description && formik?.touched?.rows[index]?.description ? "error" : ""}`}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='input-field'>
                            <input
                              type='text'
                              name={`rows[${index}].total`}
                              onChange={e => {
                                formik.handleChange(e)
                                setToggleState(!toggleState)
                              }}
                              className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.total && formik?.touched?.rows[index]?.total ? "error" : ""}`}
                              value={row.total}
                            />
                          </div>
                        </td>

                        <td className='stick-rh'>
                          <button
                            type='button'
                            className='icon-btn delete'
                            onClick={() => deleteRow(index)}
                            disabled={
                              formik.values.rows.length === 1 ? true : false
                            }
                          >
                            <span className='material-symbols-outlined medium'>
                              delete
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className='error-msg'>
                  {formik.errors.rows ? "Please fill the data first!" : ""}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='Nodata-txt'>
          <img
            src={require("../../../assets/images/nodata.svg").default}
            alt=''
            srcSet=''
          />
          No Data Found
        </div>
      )}

      <div className='table-search'>
        <div
          className='search-container'
          style={{ justifyContent: "flex-end", paddingTop: "1rem" }}
        >
          {" "}
          <div className='create-btn-container'>
            {Object.values(privileges["sales_management"]).includes(
              "write",
            ) && (
                <button className='btn-secondary header-btn  ' onClick={addRow}>
                  Add Extra Charges
                </button>
              )}
          </div>
          <button className='btn-primary' type='submit'>
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}

const AttachSignature = ({ setSignature }) => {
  const [selectedFileDoc, setSelectedFileDoc] = useState(null)

  const handleChange = e => {
    const selectedFile = e.currentTarget.files[0]
    if (selectedFile) {
      const docType = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/",
      ]
      // if (docType.includes(selectedFile.type)) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        toast.warning(
          "File size exceeds 5MB limit. Please select a smaller file.",
        )
        e.target.value = null
      } else {
        setSelectedFileDoc(selectedFile)
        formik.setFieldValue("signature", e.currentTarget.files[0])
        e.target.value = null
      }
    }
  }

  function handleDelete() {
    formik.setFieldValue("signature", null)
    setSelectedFileDoc()
  }

  const formik = useFormik({
    initialValues: {
      signature: "",
    },
    validationSchema: Yup.object().shape({
      signature: Yup.string().required("Please select a product"),
    }),
    onSubmit: async values => {
      setSignature(values)
    },
  })

  return (
    <div className='profile_personal_details'>
      {/* <div className='col1 heading-tools details-controls'>
        <h2>Attach Signature</h2>
      </div> */}

      <div className='modelEx_content'>
        <form
          className='multipleComForm flex-center pt-1 form-group'
          onSubmit={formik.handleSubmit}
        >
          <div className='file_input flex-center file_name-box sl'>
            {selectedFileDoc ? (
              <div className=''>
                <u>File</u>: {selectedFileDoc.name}
                <span
                  className='material-symbols-outlined medium'
                  onClick={handleDelete}
                >
                  cancel
                </span>
              </div>
            ) : (
              <>
                <div className='txt attach flex-center'>
                  <i className='fa-duotone fa-solid fa-folder-arrow-up'></i>
                  <span>Upload Signature</span>
                </div>
                <input
                  type='file'
                  className='excel_input'
                  id='excel_input'
                  name='file'
                  accept='.jpg,.jpeg,.png , application/pdf'
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          {formik.errors.signature && formik.touched.signature && (
            <span className='err'> {formik.errors.signature} </span>
          )}

          <FormButtons
            // loading={companyLoading}
            removeCancel
            btnContainerStyles={{
              justifyContent: "flex-end",
              padding: ".5rem",
            }}
          />
        </form>
      </div>
    </div>
  )
}

const TermsCondition = ({ setTerms }) => {
  const formik = useFormik({
    initialValues: {
      terms: ` <ul>
          <li>GST extra 18%</li>
          <li>Transportation extra as at actual.</li>
          <li>Delivery within 10 days after confimation.</li>
          <li>Paymnet Terms : 60 Days</li>
          <li>
            Goods once sold will not be taken back. Prices valid till 31 July
            2024.&nbsp;
          </li>
          <li>
            Technical service will charge extra as per manufacturer
            Standard.&nbsp;
          </li>
          <li>
            If there are any changes in material prices by Jotun and Taxes by
            the Govt. at the time of order will be applicable.
          </li>
        </ul>`,
    },

    onSubmit: async values => {
      setTerms(values)
    },
  })

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
      // [{ header: [2, 3, 4, true] }],
    ],
  }

  return (
    <form className='profile_personal_details' onSubmit={formik.handleSubmit}>
      {/* <div className='col1 heading-tools details-controls'>
      <h2>Attach Signature</h2>
    </div> */}
      <div className='ae-evIp flex-column-center'>
        <ReactQuill
          modules={modules}
          theme='snow'
          placeholder=''
          // onChange={handleDescChange}
          value={formik.values.terms}
        />
      </div>
      <div
        className='flex-space-between'
        style={{ justifyContent: "flex-end", paddingTop: "1rem" }}
      >
        <button className='btn-primary' type='submit'>
          Submit
        </button>
      </div>
    </form>
  )
}

const Comments = ({ setComments }) => {
  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const initialValues = {
    comment: "",
  }
  const commentSchema = Yup.object({
    comment: Yup.string()
      .trim()
      .required("Comment field is required")
      .max(700),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: commentSchema,
    onSubmit: async (values, { resetForm }) => {
      setComments(values)
    },
  })

  return (
    <>
      <div className='modelEx_content singleAdd'>
        {Object.values(privileges["sales_management"]).includes("write") && (
          <form className='product-detail-form' onSubmit={formik.handleSubmit}>
            <div className='form-content'>
              <div className='form-group form'>
                <div>
                  <textarea
                    type='text'
                    name='comment'
                    className='commentInput cmS'
                    placeholder='Type Comment Here...'
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    maxLength={701}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        formik.handleSubmit()
                      }
                    }}
                  />
                  {formik.touched.comment && formik.errors.comment ? (
                    <span className='err'>{formik.errors.comment}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className='flex-space-between'
              style={{ justifyContent: "flex-end", paddingTop: "1rem" }}
            >
              <button className='btn-primary' type='submit'>
                Submit
              </button>
            </div>
          </form>
        )}{" "}
      </div>
    </>
  )
}
