import { createSlice } from "@reduxjs/toolkit"
import { getEmailDetail, getEmailList } from "./EmailApi"

const emailSlice = createSlice({
  name: "email",
  initialState: {
    emailLoading: false,
    emailDetails: [],
    emailList: [],
    metaData: {},
  },

  reducers: {
    isEmailToggle: (state, { payload }) => {
      state.emailLoading = payload
    },

    updateEmail: (state, { payload }) => {
      state.emailDetails = [
        {
          title: payload.title,
          subject: payload.subject,
        },
      ]
    },
  },
  extraReducers: builder => {
    builder.addCase(getEmailList.pending, (state, action) => {
      state.emailLoading = true
    })
    builder.addCase(getEmailList.fulfilled, (state, action) => {
      state.emailLoading = false
      state.emailList = action?.payload?.data
      state.metaData = action?.payload?.metadata[0]
    })
    builder.addCase(getEmailList.rejected, (state, action) => {
      state.emailLoading = false
    })
    builder.addCase(getEmailDetail.pending, (state, action) => {
      state.emailLoading = true
    })
    builder.addCase(getEmailDetail.fulfilled, (state, action) => {
      state.emailLoading = false
      state.emailDetails = action.payload.data
    })
    builder.addCase(getEmailDetail.rejected, (state, action) => {
      state.emailLoading = false
    })
  },
})

export const { isEmailToggle } = emailSlice.actions
export default emailSlice.reducer
