/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import Header from "../../../components/Header"
import Sidebar from "../../../components/Sidebar"
import Footer from "../../../components/Footer"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import { Link, useNavigate } from "react-router-dom"
import ConformationPopup from "../../../components/ConformationPopup"
import Breadcrumb from "../../../components/BreadCrumbs"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { unixTimeToReadableFormat } from "../../../helper/helper"
import Skelaton from "../../../components/Skelaton"
import { setPageDetails } from "../../auth/redux/authSlice"
import Pagination from "../../../components/Pagination"
import SearchBox from "../../../components/SearchBox"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
import { paymentList } from "../../../helper/dummy_data"
import DropDownBox from "../../../components/DropDownBox"
import PaymentModePopup from "../components/PaymentModePopup"

const Payment = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Payment Management")
  }, [setTitle])
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [active, setActive] = useState("payment")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  // const [mailList, setMailList] = useState(dummy_emails);
  const [paymentPopup, setPaymentPopup] = useState({ mode: "", statue: false })
  const [isEventCard, setIsEventCard] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [deleteAllPopup, setDeleteAllPopup] = useState(false)
  const [popUpData, setPopupData] = useState({})
  const [checkedCollection, setCheckedCollection] = useState([])
  const [data, setData] = useState(paymentList)
  const dispatch = useDispatch()
  const { emailLoading, emailList, metaData } = useSelector(
    store => store.email,
  )
  const [category, setCategory] = useState("")
  const [softBy, setSoftBy] = useState("")
  //
  //
  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const deleteFunction = (id, title) => {
    setPopupData({
      data: {
        id,
        page: 0,
        search: "",
        sort: "desc",
        limit: "5",
        send: {
          is_delete: true,
          slug: collectAllProducts,
        },
      },
      title,
    })
    setIsEventCard(true)
  }

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )
  // useEffect(() => {
  //   dispatch(getEmailList({ page: 0, limit: 5, sort:"desc", search: "" }));
  // }, []);

  // useEffect(() => {
  //   if (searchTimeout || check) {
  //     clearTimeout(searchTimeout)
  //   } else {
  //     dispatch(
  //       getEmailList({
  //         page:
  //           Object.keys(pageDetails).length > 0 &&
  //           pageDetails?.page !== undefined
  //             ? pageDetails?.page
  //             : "0",
  //         limit: records,
  //         search:
  //           Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
  //         sort: "desc",
  //       }),
  //     )
  //     setCheck(true)
  //     dispatch(setPageDetails({ page: metaData?.page, search }))
  //     return
  //   }

  //   const timeoutId = setTimeout(async () => {
  //     dispatch(getEmailList({ limit: records, page: 0, search, sort: "desc" }))
  //     setTableIndex(0)
  //     setBtnNo([1, 2, 3])
  //     if (selectAllProducts) {
  //       setSelectAllProducts(false)
  //       setCollectAllProducts([])
  //     }
  //   }, 1000)
  //   dispatch(setPageDetails({ page: metaData?.page, search }))
  //   setSearchTimeout(timeoutId)
  // }, [dispatch, records, search])

  // useEffect(() => {
  //   if (pageDetails?.btnNo) {
  //     setBtnNo(pageDetails?.btnNo)
  //   }

  //   dispatch(setPageDetails({ page: metaData?.page, search }))
  // }, [metaData])

  const [selectAllProducts, setSelectAllProducts] = useState(false)
  const [collectAllProducts, setCollectAllProducts] = useState([])

  // useEffect(() => {
  //   const updateListData = async () => {
  //     if (emailList?.length > 0) {
  //       const newList = JSON.parse(JSON.stringify(emailList))

  //       newList.forEach(item => {
  //         item["checked"] = false
  //       })
  //       setData(newList)
  //     } else {
  //       setData([])
  //     }
  //   }
  //   updateListData()

  //   if (checkedCollection.length > 0) {
  //     checkedCollection.map(item => {
  //       if (item.page === metaData.page) {
  //         setData(oldList => {
  //           let newList = JSON.parse(JSON.stringify(oldList))
  //           newList.map(data => {
  //             if (item.collection.includes(data._id)) {
  //               data.checked = true
  //             }
  //           })

  //           return newList
  //         })
  //       }
  //     })
  //   }
  // }, [emailList])

  // const [tableLoading, setTableLoading] = useState(false)
  // const [tableCount, setTableCount] = useState(null)

  // useEffect(() => {
  //   if (tableCount) {
  //     clearTimeout(tableCount)
  //   }
  //   const count = setTimeout(() => {
  //     // if (emailLoading) {
  //     //   setTableLoading(true);
  //     // } else {
  //     //   setTableLoading(false);
  //     // }
  //     setTableLoading(emailLoading)
  //   }, 1000)
  //   setTableCount(count)
  // }, [emailLoading])

  const { tableLoading } = useLoaderDelay({ loadingState: emailLoading })

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  //
  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {
            paymentPopup.statue === true && <PaymentModePopup paymentPopup={paymentPopup} setPaymentPopup={setPaymentPopup}/>
          }
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              // api={getEmailList}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              // api={getEmailList}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            // api={getEmailList}
            />
          )}
          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Payment Management</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
                {/* <div className="mch2">
                  <div className="mch2-duration">
                    August 1,2020 - August 31,2020
                  </div>
                  <div
                    className="mch2-action"
                    onClick={() => setAction(!action)}
                  >
                    <span className="">Action</span>
                    <span className="material-symbols-rounded expand">
                      expand_more
                    </span>
                    {action && (
                      <div className="action-tooltip">
                        <span className="">Download</span>
                        <span className="">Print</span>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
              <div className='data-table-container'>
                <div className='heading-tools'>
                  <h3>Payment Management</h3>
                  <div className='table-search'>
                    <div className='col1 search-container'>
                      <SearchBox
                        customSetter={setSearch}
                        customState={search}
                      />
                      <DropDownBox
                        options={[
                          { label: "Receivables", value: "Receivables" },
                          { label: "Payable", value: "Payable" },
                          { label: "Received", value: "Received" },
                          { label: "Paid", value: "Paid" },
                          {
                            label: "Overdue Receivables",
                            value: "Overdue Receivables",
                          },
                          {
                            label: "Overdue Payable",
                            value: "Overdue Payable",
                          },
                        ]}
                        customSetter={setCategory}
                        size='small'
                        animateDropDownTitle
                        dropDownTitle='Status'
                        customTextStyle={{
                          color: "#212229",
                          opacity: "0.7",
                        }}
                        resetButton={true}
                        incomingValue={category ? category : false}
                        customDropBoxStyles={{ height: "2.4rem" }}
                        animateDropDownTitleStyle={{
                          labelUp: { bottom: "25%" },
                          labelDown: { bottom: "2%" },
                        }}
                      />{" "}
                      <DropDownBox
                        options={[
                          { label: "Month", value: "Month" },
                          { label: "Today", value: "Today" },
                          { label: "Year", value: "Year" },
                        ]}
                        customSetter={setSoftBy}
                        size='small'
                        animateDropDownTitle
                        dropDownTitle='Soft By'
                        customTextStyle={{
                          color: "#212229",
                          opacity: "0.7",
                        }}
                        resetButton={true}
                        incomingValue={softBy ? softBy : false}
                        customDropBoxStyles={{ height: "2.4rem" }}
                        animateDropDownTitleStyle={{
                          labelUp: { bottom: "25%" },
                          labelDown: { bottom: "2%" },
                        }}
                      />
                      {/* {Object.values(privileges["email_management"]).includes(
                        "write",
                      ) && (
                        <button
                          className='btn-primary header-btn'
                          onClick={() => navigate("/email/add-email")}
                        >
                          Add
                        </button>
                      )} */}
                      {/* {Object.values(privileges["email_management"]).includes(
                        "delete",
                      ) && selectAllProducts ? (
                        <button
                          className='btn-secondary header-btn delete-primary-button'
                          onClick={() => {
                            deleteFunction("all products")
                          }}
                        >
                          <span className='material-symbols-outlined medium'>
                            delete
                          </span>{" "}
                          Delete{" "}
                          {collectAllProducts.length > 1
                            ? "All " + collectAllProducts.length
                            : ""}
                        </button>
                      ) : null} */}
                    </div>
                  </div>
                </div>

                <div className='money-cards'>
                  <div className='money-card'>
                    <div className='flex'>
                      <div className='icon'>
                        <i className='fa-solid fa-money-check-dollar-pen'></i>
                      </div>
                      <div className='money-values'>
                        <Link to={"/"}>
                          Total Payable{" "}
                          <i
                            className='fa-regular fa-chevron-right'
                            style={{ fontSize: ".8rem", fontWeight: "700" }}
                          ></i>
                        </Link>
                        <span>₹1,15,500 </span>
                      </div>
                    </div>
                  </div>
                  <div className='money-card'>
                    <div className='flex'>
                      <div className='icon'>
                        <i class='fa-solid fa-hand-holding-dollar'></i>
                      </div>
                      <div className='money-values'>
                        <Link to={"/"}>
                          Total Receivables{" "}
                          <i
                            className='fa-regular fa-chevron-right'
                            style={{ fontSize: ".8rem", fontWeight: "700" }}
                          ></i>
                        </Link>
                        <span>₹1,15,500 </span>
                      </div>
                    </div>
                  </div>
                  <div className='money-card'>
                    <div className='flex'>
                      <div className='icon'>
                        <i class='fa-regular fa-calendar-clock'></i>
                      </div>
                      <div className='money-values'>
                        <Link to={"/"}>
                          Overdue Payable
                          <i
                            className='fa-regular fa-chevron-right'
                            style={{ fontSize: ".8rem", fontWeight: "700" }}
                          ></i>
                        </Link>
                        <span>₹1,15,500 </span>
                      </div>
                    </div>
                  </div>
                  <div className='money-card'>
                    <div className='flex'>
                      <div className='icon'>
                        <i class='fa-regular fa-calendar-lines-pen'></i>
                      </div>
                      <div className='money-values'>
                        <Link to={"/"}>
                          Overdue Receivable
                          <i
                            className='fa-regular fa-chevron-right'
                            style={{ fontSize: ".8rem", fontWeight: "700" }}
                          ></i>
                        </Link>
                        <span>₹1,15,500 </span>
                      </div>
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton col={4} row={data?.length ? data?.length : 5} />
                ) : data?.length > 0 ? (
                  <>
                    <div className='data-table-box scrollbar-visible'>
                      <table className='data-table fix-width-table-row'>
                        <thead>
                          <tr>
                            {/* <th className='tb-head-name padding-07 '>
                              <div>
                                <span>
                                  <input
                                    name='checkbox'
                                    id='checkbox'
                                    type='checkbox'
                                    className='checkbox-animated'
                                    onChange={() =>
                                      setSelectAllProducts(!selectAllProducts)
                                    }
                                    checked={selectAllProducts}
                                  />
                                  <label htmlFor={"checkbox"}>
                                    <span className='filter-check-background'></span>
                                  </label>
                                </span>
                              </div>
                            </th> */}
                            <th>#</th>
                            <th>Order ID</th>
                            <th>Company name</th>
                            <th>Delivery Status</th>
                            <th>Payment Status</th>
                            <th>Payment date</th>
                            <th>Advance Amount</th>
                            <th>Amount Paid</th>
                            <th>Amount to pay</th>
                            <th>Grand Total</th>
                            <th>Action By</th>
                            <th>Action Date</th>
                            {Object.values(
                              privileges["email_management"],
                            ).includes("read") && <th>Action</th>}
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item, index) => {
                            const {
                              Order_ID,
                              Company_name,
                              Delivery_Status,
                              Payment_Status,
                              Payment_date,
                              Advance_Amount,
                              Amount_Paid,
                              Amount_to_pay,
                              Grand_Total,
                              Action_By,
                              Action_Date,
                              Payment_Mode
                            } = item

                            return (
                              <tr key={index} onClick={() => setPaymentPopup({mode: Payment_Mode, statue: true})}>
                                <td className='tb_data padding-07'>
                                  {index + 1}
                                </td>
                                <td>{Order_ID}</td>
                                <td>{Company_name}</td>
                                <td>{Delivery_Status}</td>
                                <td>{Payment_Status}</td>
                                <td>{Payment_date}</td>
                                <td>{Advance_Amount}</td>
                                <td>{Amount_Paid}</td>
                                <td>{Amount_to_pay}</td>
                                <td>{Grand_Total}</td>
                                <td>{Action_By}</td>
                                <td>{Action_Date}</td>
                                <td className='padding-07'>
                                  <div className='email-action-box'>
                                    {Object.values(
                                      privileges["email_management"],
                                    ).includes("read") && (
                                        <button
                                          className='icon-btn see'
                                        // onClick={() => {
                                        //   dispatch(
                                        //     setPageDetails({
                                        //       ...pageDetails,
                                        //       btnNo,
                                        //     }),
                                        //   )
                                        //   navigate(`/email/edit-email/${slug}`)
                                        // }}
                                        >
                                          <span className='material-symbols-outlined medium'>
                                            visibility
                                          </span>
                                        </button>
                                      )}

                                    {Object.values(
                                      privileges["email_management"],
                                    ).includes("delete") && (
                                        <button
                                          className='icon-btn delete'
                                        // onClick={() => {
                                        //   setIsEventCard(true)
                                        //   setPopupData({
                                        //     data: {
                                        //       slug,
                                        //       page: 0,
                                        //       search: "",
                                        //       sort: "desc",
                                        //       limit: "5",
                                        //       send: {
                                        //         is_delete: true,
                                        //         slug: [slug],
                                        //       },
                                        //     },
                                        //     title,
                                        //   })
                                        // }}
                                        >
                                          <span className='material-symbols-outlined medium'>
                                            Delete
                                          </span>
                                        </button>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                    {/* <Pagination
                      sliceName={"email"}
                      search={search}
                      filter={"desc"}
                      api={getEmailList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                      callbackFunction={() => {
                        setSelectAllProducts(false)
                        setCollectAllProducts([])
                      }}
                    /> */}
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
          {/* {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteEmail}
              popUpData={popUpData}
              customFunction={() => {
                setSelectAllProducts(false)
                setCollectAllProducts([])
              }}
              toastMessage={"Email removed successfully"}
            />
          )}{" "} */}
          {deleteAllPopup && (
            <ConformationPopup
              customState={deleteAllPopup}
              customSetter={setDeleteAllPopup}
            />
          )}{" "}
        </div>
      </div>
    </div>
  )
}

export default Payment
