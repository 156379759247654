import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import DropDownBox from "../../../components/DropDownBox"
import * as Yup from "yup"
import { useLocation } from "react-router-dom"
import {
  capitalizeInput,
  unixTimeToReadableFormat,
  unixTimeToYYYYMMDD,
} from "../../../helper/helper"

const PrimaryDetails = ({ formData, setFormData, purchaseApiDetails }) => {
  const [activeInputs, setActiveInputs] = useState(false)
  const dispatch = useDispatch()
  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const [focusedField, setFocusedField] = useState("")

  const { productsLoading } = useSelector(store => store.products)

  const location = useLocation()

  const orderSchema = Yup.object({
    orderDate: Yup.string().required("Please enter document number"),
    documentDate: Yup.string().required("Please enter document date"),
    customerId: Yup.string().required("Please enter customer ID"),
    paymentTerm: Yup.string().required("Please enter payment term"),
    PO_number: Yup.string().required("Purchase order number is required"),
  })

  const returnSchema = Yup.object({
    PO_date: Yup.string().required("Please enter PO date"),
    PO_number: Yup.string().required("Please enter PO number"),
    PR_number: Yup.string().required("Please enter PR number"),
    invoice_number: Yup.string().required("Please enter invoice number"),
    invoice_date: Yup.string().required("Please enter invoice date"),
    return_deliveryDate: Yup.string().required(
      "Please enter return delivery date",
    ),
  })

  const orderInitialValues = {
    orderDate: "",
    documentDate: "",
    customerId: "",
    paymentTerm: "",
    PO_number: "",
    sales_officer: "",
    additionalDetails: "",
  }

  const returnInitialValues = {
    PO_date: "",
    PO_number: "",
    PR_number: "",
    documentDate: "",
    invoice_number: "",
    invoice_date: "",
    return_deliveryDate: "",
    sales_officer: "",
  }

  // Determine the current path
  const isPurchaseOrderPath = location.pathname === "/purchase/purchase-order"

  // Set initial values and validation schema based on the path
  const initialValues = isPurchaseOrderPath
    ? orderInitialValues
    : returnInitialValues
  const validationSchema = isPurchaseOrderPath ? orderSchema : returnSchema

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Your form submission logic
      setActiveInputs(false)
      // const response = await dispatch(updateBasicDetails(values));
      // if (response.payload) {
      //   setActiveInputs(false);
      // }
    },
  })

  const checkValue = valueList => {
    const findValue = valueList.find(item => item === "")

    return findValue === "" ? false : true
  }

  useEffect(() => {
    if (!formData?.primaryDetails && checkValue(Object.values(formik.values))) {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))
        newList.primaryDetails = formik.values
        
        return newList
      })
    } else {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))
        newList.primaryDetails = true
        return newList
      })
    }
    
  }, [formData?.primaryDetails])

  const handleCancel = () => {
    // if (Object.keys(formik.errors).length === 0) {
    //   setActiveInputs(false)
    // }
    setActiveInputs(false)
  }

  return (
    <div className='main-box-padding'>
      <div className='modelEx_content singleAdd'>
        <div className='profile_personal_details'>
          <div className='details-controls'>
            <h2>Primary Document Details</h2>
            {Object.values(privileges["purchase_management"]).includes(
              "write",
            ) && (
              <div className='detail-btn'>
                {activeInputs ? (
                  <>
                    <button
                      type='button'
                      onClick={handleCancel}
                      className='btn-secondary'
                      disabled={productsLoading}
                      style={productsLoading ? { cursor: "not-allowed" } : {}}
                    >
                      {productsLoading ? (
                        <ClipLoader
                          loading={true}
                          size={15}
                          aria-label='Loading Spinner'
                          data-testid='loader'
                          color='white'
                        />
                      ) : (
                        "Cancel"
                      )}
                    </button>{" "}
                    <button
                      type='submit'
                      onClick={formik.handleSubmit}
                      className='btn-primary'
                      disabled={productsLoading}
                      style={productsLoading ? { cursor: "not-allowed" } : {}}
                    >
                      {productsLoading ? (
                        <ClipLoader
                          loading={true}
                          size={15}
                          aria-label='Loading Spinner'
                          data-testid='loader'
                          color='white'
                        />
                      ) : (
                        "Save"
                      )}
                    </button>{" "}
                  </>
                ) : (
                  <button
                    onClick={() => setActiveInputs(true)}
                    className='btn-secondary'
                  >
                    <span className='material-symbols-outlined small'>
                      border_color
                    </span>
                    Edit
                  </button>
                )}
              </div>
            )}{" "}
          </div>
        </div>
        {location?.pathname === "/purchase/purchase-order" ? (
          <PrimaryorderDetails
            activeInputs={activeInputs}
            focusedField={focusedField}
            setFocusedField={setFocusedField}
            formik={formik}
            purchaseApiDetails={
              purchaseApiDetails
                ? {
                    ...purchaseApiDetails?.primaryDocumentDetails,
                  }
                : false
            }
          />
        ) : (
          <PrimaryreturnDetails
            activeInputs={activeInputs}
            focusedField={focusedField}
            setFocusedField={setFocusedField}
            formik={formik}
          />
        )}
      </div>
    </div>
  )
}

export default PrimaryDetails

const PrimaryorderDetails = ({
  activeInputs,
  focusedField,
  setFocusedField,
  formik,
  purchaseApiDetails,
}) => {
  useEffect(() => {
    if (purchaseApiDetails) {
      formik.setValues({
        orderDate: unixTimeToYYYYMMDD(purchaseApiDetails?.orderDate),
        documentDate: unixTimeToYYYYMMDD(purchaseApiDetails?.documentDate),
        customerId: purchaseApiDetails?.customerId,
        paymentTerm: purchaseApiDetails?.paymentTerm,
        PO_number: purchaseApiDetails?.documentNumber,
        sales_officer: purchaseApiDetails?.contactPerson,
        additionalDetails: purchaseApiDetails?.additionalDetails,
      })
    }
  }, [purchaseApiDetails?.documentNumber])
  return (
    <>
      <form className='product-detail-form' onSubmit={formik.handleSubmit}>
        <div className='form-content'>
          <div className='form-group form'>
            <div className='col-2'>
              <div
                className={`input-field ${
                  formik.values.PO_number !== "" || focusedField === "PO_number"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='PO_number' className='toggle-label'>
                  PO Number
                </label>
                <input
                  id='PO_number'
                  name='PO_number'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.PO_number}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("PO_number")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.PO_number && formik.errors.PO_number ? (
                  <span className='err'>{formik.errors.PO_number}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${
                  formik.values.customerId !== "" ||
                  focusedField === "customerId"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='customerId' className='toggle-label'>
                  Customer ID
                </label>
                <input
                  id='customerId'
                  name='customerId'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.customerId}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("customerId")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.customerId && formik.errors.customerId ? (
                  <span className='err'>{formik.errors.customerId}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
                      }`}
              >
                <label htmlFor='orderDate' className='toggle-label'>
                  Order Date
                </label>
                <input
                  id='orderDate'
                  name='orderDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.orderDate}
                  onInput={capitalizeInput}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("orderDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.orderDate && formik.errors.orderDate ? (
                  <span className='err'>{formik.errors.orderDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
                      }`}
              >
                <label htmlFor='documentDate' className='toggle-label'>
                  Document Date
                </label>
                <input
                  id='documentDate'
                  name='documentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.documentDate}
                  onInput={capitalizeInput}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("documentDate")}
                  disabled={!activeInputs}
                />
                {formik.touched.documentDate && formik.errors.documentDate ? (
                  <span className='err'>{formik.errors.documentDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${
                  formik.values.paymentTerm !== "" ||
                  focusedField === "paymentTerm"
                    ? "active"
                    : ""
                }`}
              >
                <DropDownBox
                  options={[
                    { label: "Immediately", value: "Immediately" },
                    { label: "Net 30", value: "Net30" },
                    { label: "Net 60", value: "Net60" },
                    { label: "Net 90", value: "Net90" },
                  ]}
                  dropDownTitle='Payment Term'
                  animateDropDownTitle
                  customSetter={formik.setFieldValue}
                  customFormikLabel={"paymentTerm"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  disabled={activeInputs}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                  incomingValue={
                    purchaseApiDetails?.paymentTerm
                      ? purchaseApiDetails?.paymentTerm
                      : ""
                  }
                />
                {formik.touched.paymentTerm && formik.errors.paymentTerm ? (
                  <span className='err'>{formik.errors.paymentTerm}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${
                  formik.values.sales_officer !== "" ||
                  focusedField === "sales_officer"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='sales_officer' className='toggle-label'>
                  Sales Officer
                </label>
                <input
                  id='sales_officer'
                  name='sales_officer'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.sales_officer}
                  onInput={capitalizeInput}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("sales_officer")}
                  disabled={!activeInputs}
                />
                {formik.touched.sales_officer && formik.errors.sales_officer ? (
                  <span className='err'>{formik.errors.sales_officer}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${
                  formik.values.additionalDetails !== "" ||
                  focusedField === "additionalDetails"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='additionalDetails' className='toggle-label'>
                  Additional Details
                </label>
                <input
                  id='additionalDetails'
                  name='additionalDetails'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.additionalDetails}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("additionalDetails")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.additionalDetails &&
                formik.errors.additionalDetails ? (
                  <span className='err'>{formik.errors.additionalDetails}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const PrimaryreturnDetails = ({
  activeInputs,
  focusedField,
  setFocusedField,
  formik,
}) => {
  return (
    <>
      <form className='product-detail-form' onSubmit={formik.handleSubmit}>
        <div className='form-content'>
          <div className='form-group form'>
            <div className='col-2'>
              <div
                className={`input-field ${
                  formik.values.PR_number !== "" || focusedField === "PR_number"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='PR_number' className='toggle-label'>
                  Purchase return number
                </label>
                <input
                  id='PR_number'
                  name='PR_number'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.PR_number}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("PR_number")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.PR_number && formik.errors.PR_number ? (
                  <span className='err'>{formik.errors.PR_number}</span>
                ) : null}
              </div>
              <div className='input-field active'>
                <label htmlFor='documentDate' className='toggle-label'>
                  Document date
                </label>
                <input
                  id='documentDate'
                  name='documentDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.documentDate}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("documentDate")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.documentDate && formik.errors.documentDate ? (
                  <span className='err'>{formik.errors.documentDate}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${
                  formik.values.PO_number !== "" || focusedField === "PO_number"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='PO_number' className='toggle-label'>
                  PO Number
                </label>
                <input
                  id='PO_number'
                  name='PO_number'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.PO_number}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("PO_number")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.PO_number && formik.errors.PO_number ? (
                  <span className='err'>{formik.errors.PO_number}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
                      }`}
              >
                <label htmlFor='PO_date' className='toggle-label'>
                  PO Date
                </label>
                <input
                  id='PO_date'
                  name='PO_date'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.PO_date}
                  onInput={capitalizeInput}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("PO_date")}
                  disabled={!activeInputs}
                />
                {formik.touched.PO_date && formik.errors.PO_date ? (
                  <span className='err'>{formik.errors.PO_date}</span>
                ) : null}
              </div>
              <div
                className={`input-field ${
                  formik.values.invoice_number !== "" ||
                  focusedField === "invoice_number"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='invoice_number' className='toggle-label'>
                  Invoice number
                </label>
                <input
                  id='invoice_number'
                  name='invoice_number'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.invoice_number}
                  onInput={capitalizeInput}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("invoice_number")}
                  disabled={!activeInputs}
                />
                {formik.touched.invoice_number &&
                formik.errors.invoice_number ? (
                  <span className='err'>{formik.errors.invoice_number}</span>
                ) : null}
              </div>
              <div
                className={`input-field  active
                      }`}
              >
                <label htmlFor='invoice_date' className='toggle-label'>
                  Invoice Date
                </label>
                <input
                  id='invoice_date'
                  name='invoice_date'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.invoice_date}
                  onInput={capitalizeInput}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("invoice_date")}
                  disabled={!activeInputs}
                />
                {formik.touched.invoice_date && formik.errors.invoice_date ? (
                  <span className='err'>{formik.errors.invoice_date}</span>
                ) : null}
              </div>
              <div className={`input-field active`}>
                <label htmlFor='return_deliveryDate' className='toggle-label'>
                  Return Delivery Date
                </label>
                <input
                  id='return_deliveryDate'
                  name='return_deliveryDate'
                  type='date'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.return_deliveryDate}
                  // maxLength={80}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("return_deliveryDate")}
                  onKeyDown={e => {
                    if (e.key === " ") {
                      e.preventDefault()
                    }
                  }}
                  disabled={!activeInputs}
                />
                {formik.touched.return_deliveryDate &&
                formik.errors.return_deliveryDate ? (
                  <span className='err'>
                    {formik.errors.return_deliveryDate}
                  </span>
                ) : null}
              </div>
              <div
                className={`input-field ${
                  formik.values.sales_officer !== "" ||
                  focusedField === "sales_officer"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='sales_officer' className='toggle-label'>
                  Sales Officer
                </label>
                <input
                  id='sales_officer'
                  name='sales_officer'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.sales_officer}
                  onInput={capitalizeInput}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("sales_officer")}
                  disabled={!activeInputs}
                />
                {formik.touched.sales_officer && formik.errors.sales_officer ? (
                  <span className='err'>{formik.errors.sales_officer}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
