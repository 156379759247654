/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import Sidebar from "../../../components/Sidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import BrandTable from "../components/BrandTable"
import ProductCatTable from "../components/ProductCatTable"
import PaintTable from "../components/PaintTable"
import UomTable from "../components/UomTable"
import FinishTable from "../components/FinishTable"
import ColorTable from "../components/ColorTable"
import AddBrandForm from "../components/AddBrandForm"
import AddProductCatForm from "../components/AddProductCatForm"
import AddPaintForm from "../components/AddPaintForm"
import AddUOMForm from "../components/AddUOMForm"
import AddFinishForm from "../components/AddFinishForm"
import AddColorForm from "../components/AddColorForm"
import UpdateBrandForm from "../components/UpdateBrandForm"
import UpdateProductCatForm from "../components/UpdateProductCatForm"
import UpdatePaintForm from "../components/UpdatePaintForm"
import UpdateUOMForm from "../components/UpdateUOMForm"
import UpdateColorForm from "../components/UpdateColorForm"
import UpdateFinishForm from "../components/UpdateFinishForm"
import SubCategoryTable from "../components/SubCategoryTable"
import SubChildCategoryTable from "../components/SubChildCategoryTable"
import AddSubCategoryForm from "../components/AddSubCategoryForm"
import AddSubChildCategoryForm from "../components/AddSubChildCategoryForm.js"
import UpdateSubCatForm from "../components/UpdateSubCatForm .js"
import { deleteCatalogueOptions } from "../../products/redux/ProductsApi.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { setPageDetails } from "../../auth/redux/authSlice.js"
import UpdateSubChildCategoryForm from "../components/UpdateSubChildCatForm .js"

const ProductCatalogue = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Product Catalogue")
  }, [setTitle])

  const [active, setActive] = useState("catalogue")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [tab, setTab] = useState(0)
  const [isEventCard, setIsEventCard] = useState(false)
  const [popUpData, setPopupData] = useState({})
  const [formPopup, setFormPopup] = useState({
    add: false,
    edit: false,
  })
  const { pageDetails } = useSelector(state => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const deleteFunction = (id, title, type, listName) => {
    setPopupData({
      data: {
        id,
        page: 0,
        search: "",
        sort: "desc",
        limit: "5",
        deleteType: type,
        listName,
        send: { is_delete: true },
      },
      title,
    })
    setIsEventCard(true)
  }

  const resetMetaData = () => {
    if (Object.keys(pageDetails).length > 0) {
      dispatch(setPageDetails({}))
    }
  }
  const rendorList = params => {
    switch (params) {
      case 0:
        return (
          <BrandTable
            customState={formPopup}
            customSetter={setFormPopup}
            deleteFunction={deleteFunction}
          />
        )
      case 1:
        return (
          <ProductCatTable
            customState={formPopup}
            customSetter={setFormPopup}
            deleteFunction={deleteFunction}
          />
        )
      case 2:
        return (
          <PaintTable
            customState={formPopup}
            customSetter={setFormPopup}
            deleteFunction={deleteFunction}
          />
        )
      case 4:
        return (
          <UomTable
            customState={formPopup}
            customSetter={setFormPopup}
            deleteFunction={deleteFunction}
          />
        )
      case 3:
        return (
          <FinishTable
            customState={formPopup}
            customSetter={setFormPopup}
            deleteFunction={deleteFunction}
          />
        )
      case 5:
        return (
          <ColorTable
            customState={formPopup}
            customSetter={setFormPopup}
            deleteFunction={deleteFunction}
          />
        )
      case 6:
        return (
          <SubCategoryTable
            customState={formPopup}
            customSetter={setFormPopup}
            deleteFunction={deleteFunction}
          />
        )
      case 7:
        return (
          <SubChildCategoryTable
            customState={formPopup}
            customSetter={setFormPopup}
            deleteFunction={deleteFunction}
          />
        )
      default:
        return (
          <BrandTable
            customState={formPopup}
            customSetter={setFormPopup}
            deleteFunction={deleteFunction}
          />
        )
    }
  }
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
                <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          {/* 
        {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>

            <div className='main-content' style={{ gap: "1rem" }}>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Product Catalogue</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>

              <div className='tab-navigate'>
                <button
                  onClick={() => {
                    setTab(0)
                    resetMetaData()
                  }}
                  className={`product-cat-btn ${tab === 0 ? "active" : ""}`}
                >
                  Brand
                </button>
                <button
                  onClick={() => {
                    setTab(1)
                    resetMetaData()
                  }}
                  className={`product-cat-btn ${tab === 1 ? "active" : ""}`}
                >
                  Categories
                </button>{" "}
                <button
                  onClick={() => {
                    setTab(6)
                    resetMetaData()
                  }}
                  className={`product-cat-btn ${tab === 6 ? "active" : ""}`}
                >
                  Sub Categories
                </button>{" "}
                <button
                  onClick={() => {
                    setTab(7)
                    resetMetaData()
                  }}
                  className={`product-cat-btn ${tab === 7 ? "active" : ""}`}
                >
                  Sub Child Categories
                </button>
                <button
                  onClick={() => {
                    setTab(2)
                    resetMetaData()
                  }}
                  className={`product-cat-btn ${tab === 2 ? "active" : ""}`}
                >
                  Paint Type
                </button>
                <button
                  onClick={() => {
                    setTab(3)
                    resetMetaData()
                  }}
                  className={`product-cat-btn ${tab === 3 ? "active" : ""}`}
                >
                  Finish Type
                </button>
                <button
                  onClick={() => {
                    setTab(4)
                    resetMetaData()
                  }}
                  className={`product-cat-btn ${tab === 4 ? "active" : ""}`}
                >
                  UOM
                </button>
                <button
                  onClick={() => {
                    setTab(5)
                    resetMetaData()
                  }}
                  className={`product-cat-btn ${tab === 5 ? "active" : ""}`}
                >
                  Color
                </button>
              </div>

              {rendorList(tab)}
            </div>
            <Footer />
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteCatalogueOptions}
              popUpData={popUpData}
            />
          )}{" "}
          {/* <ConformationPopup
              customState={isEventCardTwo}
              customSetter={setIsEventCardTwo}
              mainTitle='Are you sure you want to make this address your Primary address?'
              subTitle='This action will change your primary address'
              api={updatePrimaryAddress}
              popUpData={isEventCardTwo}
              reData={""}
              reFetchApi={getSelfCompanyAddress}
              popupType='edit'
              toastMessage='Primary address changed'
            /> */}
          {formPopup.add === true && tab === 0 && (
            <AddBrandForm customState={formPopup} customSetter={setFormPopup} />
          )}
          {formPopup.add === true && tab === 1 && (
            <AddProductCatForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}
          {formPopup.add === true && tab === 2 && (
            <AddPaintForm customState={formPopup} customSetter={setFormPopup} />
          )}{" "}
          {formPopup.add === true && tab === 3 && (
            <AddFinishForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}{" "}
          {formPopup.add === true && tab === 4 && (
            <AddUOMForm customState={formPopup} customSetter={setFormPopup} />
          )}{" "}
          {formPopup.add === true && tab === 5 && (
            <AddColorForm customState={formPopup} customSetter={setFormPopup} />
          )}
          {formPopup.add === true && tab === 6 && (
            <AddSubCategoryForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}
          {formPopup.add === true && tab === 7 && (
            <AddSubChildCategoryForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}
          {formPopup.edit === true && tab === 0 && (
            <UpdateBrandForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}
          {formPopup.edit === true && tab === 1 && (
            <UpdateProductCatForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}{" "}
          {formPopup.edit === true && tab === 2 && (
            <UpdatePaintForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}{" "}
          {formPopup.edit === true && tab === 4 && (
            <UpdateUOMForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}
          {formPopup.edit === true && tab === 3 && (
            <UpdateFinishForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}
          {formPopup.edit === true && tab === 5 && (
            <UpdateColorForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}{" "}
          {formPopup.edit === true && tab === 6 && (
            <UpdateSubCatForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}{" "}
          {formPopup.edit === true && tab === 7 && (
            <UpdateSubChildCategoryForm
              customState={formPopup}
              customSetter={setFormPopup}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductCatalogue
