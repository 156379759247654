import React from "react"

const Footer = ({ activeFooter, MinimizeSidebar }) => {
  const year = new Date().getFullYear()

  return (
    <div
      className={`footer-main ${
        activeFooter && MinimizeSidebar
          ? "fixed-footer fixed-footer-for-minisidebar"
          : activeFooter
            ? "fixed-footer"
            : ""
      }`}
    >
      <div className='footer_links'>
        <a
          href='https://poonamcoatings.com/about-us/'
          target='blank'
          className='footer_link'
        >
          About us
        </a>
        <a
          href='https://poonamcoatings.com/contact-us/'
          target='blank'
          className='footer_link'
        >
          Contact us
        </a>
        <a
          href='https://poonamcoatings.com/services/'
          target='blank'
          className='footer_link'
        >
          Services
        </a>
      </div>

      <div className='footer_copyright'>
        Copyright © {year}{" "}
        <a
          href='https://poonamcoatings.com/'
          target='blank'
          className='footer_link'
        >
          Poonam Coatings ,
        </a>{" "}
        All Rights Reserved
      </div>
    </div>
  )
}

export default Footer
