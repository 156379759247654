import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { setPageDetails } from "../modules/auth/redux/authSlice"

const Sidebar = ({
  active,
  setActive,
  MinimizedSidebar,
  setMinimizedSidebar,
  initialRenderAnimation,
  setInitialRenderAnimation,
  sliceName,
  sliceReducer,
  api = false,
  apiData = {
    page: "0",
    limit: "5",
    sort: "desc",
    filter: "",
    search: "",
  },
}) => {
  const [navList, setNavList] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  // const { metaData } = useSelector((state) => state[sliceName], shallowEqual);

  // const [privileges , setprivileges] = useState(privileges)
  const { companyDetails } = useSelector(store => store.company)

  useEffect(() => {
    if (active === "inventoryList" || active === "stock-movement") {
      setNavList(1)
    } else if (
      active === "user" ||
      active === "profile" ||
      active === "email" ||
      active === "company" ||
      active === "catalogue" ||
      active === "department"
    ) {
      setNavList(2)
    } else if (active === "purchaseDetails" || active === "stockAlert") {
      setNavList(3)
    } else if (active === "sales_details" || active === "sales_quotation") {
      setNavList(5)
    } else if (active === "sales_order" || active === "purchase_order") {
      setNavList(6)
    }
  }, [active])

  const resetMetaData = () => {
    if (api && pageDetails?.page > 0) {
      dispatch(api(apiData))
    }
    if (Object.keys(pageDetails)?.length > 0) {
      dispatch(setPageDetails({}))
    }
  }

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizedSidebar(!MinimizedSidebar)
  }

  return (
    <div className='sidebar-main'>
      <div
        className='sidebar-header'
        onClick={() => {
          resetMetaData()
          navigate("/")
        }}
      >
        <img src={require("./../assets/icons/logo.svg").default} alt='logo' />
      </div>
      <div className='sidebar-content'>
        <div
          className={active === "dashboard" ? "sc-c active" : "sc-c"}
          onClick={() => {
            resetMetaData()
            setActive("dashboard")
            navigate("/")
          }}
        >
          <span
            className={
              "material-symbols-rounded sc-icon" +
              (initialRenderAnimation ? " slide_out-ani" : "")
            }
          >
            dashboard
          </span>
          <span className='scc-name'>Dashboard</span>
        </div>
        {privileges.hasOwnProperty("buyers_and_suppliers_management") &&
          Object?.values(
            privileges["buyers_and_suppliers_management"],
          )?.includes("read") && (
            <div
              className={active === "buyers" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                setActive("buyers")
                navigate("/buyers-and-suppliers")
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                contacts
              </span>
              <span className='scc-name'>Buyers & Suppliers</span>
            </div>
          )}
        {privileges.hasOwnProperty("calendar_management") &&
          Object?.values(privileges["calendar_management"])?.includes(
            "read",
          ) && (
            <div
              className={active === "calendar" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                setActive("calendar")
                navigate("/calendar")
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                calendar_month
              </span>
              <span className='scc-name'>Calendar </span>
            </div>
          )}
        {privileges.hasOwnProperty("production_management") &&
          Object?.values(privileges["production_management"])?.includes(
            "read",
          ) && (
            <div
              className={active === "product" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                navigate("/products")
                setActive("product")
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                Inventory_2
              </span>
              <span className='scc-name'>Products</span>
            </div>
          )}{" "}
        {privileges.hasOwnProperty("inventory_management") &&
          Object?.values(privileges["inventory_management"])?.includes(
            "read",
          ) && (
            <div
              className={active === "inventoryList" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                setActive("inventoryList")
                navigate("/inventory")
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                Inventory
              </span>
              <span className='scc-name'>Inventory</span>
            </div>
          )}{" "}
        {/* {privileges.hasOwnProperty("inventory_management") &&
          Object?.values(privileges["inventory_management"])?.includes(
            "read",
          ) && (
            <div
              className='sc-c extend'
              onClick={() => (navList === 1 ? setNavList(0) : setNavList(1))}
            >
              <div className='sc-c1'>
                <span
                  className={
                    "material-symbols-rounded sc-icon" +
                    (initialRenderAnimation ? " slide_out-ani" : "")
                  }
                >
                  inventory
                </span>
                <span className='scc-name'>Inventory</span>
              </div>

              <span className='material-symbols-rounded navigate'>
                {navList === 1 ? "expand_less" : "expand_more"}
              </span>
            </div>
          )}
        {navList === 1 && (
          <div className='app-main'>
            <div
              className={
                active === "inventoryList" ? "app-name active" : "app-name"
              }
              onClick={() => {
                resetMetaData()
                setActive("inventoryList")
                navigate("/inventory")
              }}
            >
              Inventory
            </div>
            <div
              className={
                active === "stock-movement" ? "app-name active" : "app-name"
              }
              onClick={() => {
                resetMetaData()
                setActive("stock-movement")
                navigate("/stock-movement")
              }}
            >
              Stock Management
            </div>
          </div>
        )}{" "} */}
        {privileges.hasOwnProperty("sales_management") &&
          Object?.values(privileges["sales_management"])?.includes("read") && (
            <div
              className='sc-c extend'
              onClick={() => (navList === 5 ? setNavList(0) : setNavList(5))}
            >
              <div className='sc-c1'>
                <span
                  className={
                    "material-symbols-rounded sc-icon" +
                    (initialRenderAnimation ? " slide_out-ani" : "")
                  }
                >
                  store
                </span>
                <span className='scc-name'>Sales</span>
              </div>

              <span className='material-symbols-rounded navigate'>
                {navList === 5 ? "expand_less" : "expand_more"}
              </span>
            </div>
          )}
        {navList === 5 && (
          <div className='app-main'>
            <div
              className={
                active === "sales_quotation" ? "app-name active" : "app-name"
              }
              onClick={() => {
                resetMetaData()
                setActive("sales_quotation")
                navigate("/sales-quotation")
              }}
            >
              Sales Quotation
            </div>
            <div
              className={
                active === "sales_details" ? "app-name active" : "app-name"
              }
              onClick={() => {
                resetMetaData()
                setActive("sales_details")
                navigate("/sales")
              }}
            >
              Sales Detail
            </div>
          </div>
        )}
        {privileges.hasOwnProperty("purchase_management") &&
          Object?.values(privileges["purchase_management"])?.includes(
            "read",
          ) && (
            <div
              className='sc-c extend'
              onClick={() => (navList === 3 ? setNavList(0) : setNavList(3))}
            >
              <div className='sc-c1'>
                <span
                  className={
                    "material-symbols-rounded sc-icon" +
                    (initialRenderAnimation ? " slide_out-ani" : "")
                  }
                >
                  account_balance_wallet
                </span>
                <span className='scc-name'>Purchase</span>
              </div>

              <span className='material-symbols-rounded navigate'>
                {navList === 3 ? "expand_less" : "expand_more"}
              </span>
            </div>
          )}
        {navList === 3 && (
          <div className='app-main'>
            <div
              className={
                active === "stockAlert" ? "app-name active" : "app-name"
              }
              onClick={() => {
                resetMetaData()
                setActive("stockAlert")
                navigate("/stock-alert")
              }}
            >
              Stock Alert
            </div>
            <div
              className={
                active === "purchaseDetails" ? "app-name active" : "app-name"
              }
              onClick={() => {
                resetMetaData()
                setActive("purchaseDetails")
                navigate("/purchase")
              }}
            >
              Purchase Detail
            </div>
          </div>
        )}
        {privileges.hasOwnProperty("order_management") &&
          Object?.values(privileges["order_management"])?.includes("read") && (
            <div
              className='sc-c extend'
              onClick={() => (navList === 6 ? setNavList(0) : setNavList(6))}
            >
              <div className='sc-c1'>
                <span
                  className={
                    "material-symbols-rounded sc-icon" +
                    (initialRenderAnimation ? " slide_out-ani" : "")
                  }
                >
                  package_2
                </span>
                <span className='scc-name'>Order Management</span>
              </div>

              <span className='material-symbols-rounded navigate'>
                {navList === 6 ? "expand_less" : "expand_more"}
              </span>
            </div>
          )}
        {navList === 6 && (
          <div className='app-main'>
            <div
              className={
                active === "sales_order" ? "app-name active" : "app-name"
              }
              onClick={() => {
                resetMetaData()
                setActive("sales_order")
                navigate("/order/sales")
              }}
            >
              Sales
            </div>
            <div
              className={
                active === "purchase_order" ? "app-name active" : "app-name"
              }
              onClick={() => {
                resetMetaData()
                setActive("purchase_order")
                navigate("/order/purchase")
              }}
            >
              Purchase
            </div>
          </div>
        )}
        {privileges.hasOwnProperty("production_management") &&
          Object?.values(privileges["production_management"])?.includes(
            "read",
          ) && (
            <div
              className={active === "approvals" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                navigate("/approvals")
                setActive("approvals")
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                order_approve
              </span>

              <span className='scc-name'>Approval</span>
            </div>
          )}
        {privileges.hasOwnProperty("payment_management") &&
          Object?.values(privileges["payment_management"])?.includes(
            "read",
          ) && (
            <div
              className={active === "payment" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                setActive("payment")
                navigate("/payment")
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                payments
              </span>
              <span className='scc-name'>Payments</span>
            </div>
          )}
        {/* {privileges.hasOwnProperty("reports_intelligence_management") &&
          Object?.values(
            privileges["reports_intelligence_management"],
          )?.includes("read") && (
            <div
              className={active === "reports" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                setActive("reports")
                // navigate("/");
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                monitoring
              </span>
              <span className='scc-name'>Reports & Intelligence</span>
            </div>
          )} */}
        {/* {privileges.hasOwnProperty("resource_planning_management") &&
          Object?.values(privileges["resource_planning_management"])?.includes(
            "read",
          ) && (
            <div
              className={active === "light" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                setActive("light")
                // navigate("/");
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                deployed_code_account
              </span>
              <span className='scc-name'>Resource Planning</span>
            </div>
          )} */}
        {privileges.hasOwnProperty("account_tally_management") &&
          Object?.values(privileges["account_tally_management"])?.includes(
            "read",
          ) && (
            <div
              className={active === "passbook" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                setActive("passbook")
                // navigate("/");
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                checkbook
              </span>
              <span className='scc-name'>Accounts</span>
            </div>
          )}
        {/* {privileges.hasOwnProperty("department_management") &&
          Object?.values(privileges["department_management"])?.includes(
            "read",
          ) && (
            <div
              className={active === "department" ? "sc-c active" : "sc-c"}
              onClick={() => {
                resetMetaData()
                setActive("department")
                navigate("/departments")
              }}
            >
              <span
                className={
                  "material-symbols-rounded sc-icon" +
                  (initialRenderAnimation ? " slide_out-ani" : "")
                }
              >
                groups
              </span>
              <span className='scc-name'>Departments</span>
            </div>
          )} */}
        <div
          className='sc-c extend'
          onClick={() => (navList === 2 ? setNavList(0) : setNavList(2))}
        >
          <div className='sc-c1'>
            <span
              className={
                "material-symbols-rounded sc-icon" +
                (initialRenderAnimation ? " slide_out-ani" : "")
              }
            >
              settings
            </span>
            <span className='scc-name'>Settings</span>
          </div>

          <span className='material-symbols-rounded navigate'>
            {navList === 2 ? "expand_less" : "expand_more"}
          </span>
        </div>
        {navList === 2 && (
          <div className='app-main'>
            <div
              className={active === "profile" ? "app-name active" : "app-name"}
              onClick={() => {
                resetMetaData()
                setActive("profile")
                navigate("/profile")
              }}
            >
              Profile
            </div>
            {privileges.hasOwnProperty("company_management") &&
              Object?.values(privileges["company_management"])?.includes(
                "read",
              ) && (
                <div
                  className={
                    active === "company" ? "app-name active" : "app-name"
                  }
                  onClick={() => {
                    resetMetaData()
                    setActive("company")
                    // navigate(
                    //   `/${
                    //     companyDetails?.name
                    //       ? companyDetails?.name
                    //           .replaceAll(" ", "-")
                    //           .toLowerCase()
                    //       : "poonam-coatings"
                    //   }`
                    // );
                    navigate("/company")
                  }}
                >
                  Company
                </div>
              )}
            {privileges.hasOwnProperty("user_management") &&
              Object?.values(privileges["user_management"])?.includes(
                "read",
              ) && (
                <div
                  className={active === "user" ? "app-name active" : "app-name"}
                  onClick={() => {
                    resetMetaData()
                    setActive("user")
                    navigate("/user")
                  }}
                >
                  User Management
                </div>
              )}
            {privileges.hasOwnProperty("email_management") &&
              Object?.values(privileges["email_management"])?.includes(
                "read",
              ) && (
                <div
                  className={
                    active === "email" ? "app-name active" : "app-name"
                  }
                  onClick={() => {
                    resetMetaData()
                    setActive("email")
                    navigate("/email")
                  }}
                >
                  Email Management
                </div>
              )}
            {privileges.hasOwnProperty("department_management") &&
              Object?.values(privileges["department_management"])?.includes(
                "read",
              ) && (
                <div
                  className={
                    active === "department" ? "app-name active" : "app-name"
                  }
                  onClick={() => {
                    resetMetaData()
                    setActive("department")
                    navigate("/departments")
                  }}
                >
                  Department Management
                </div>
              )}
            {privileges.hasOwnProperty("catalogue_management") &&
              Object?.values(privileges["catalogue_management"])?.includes(
                "read",
              ) && (
                <div
                  className={
                    active === "catalogue" ? "app-name active" : "app-name"
                  }
                  onClick={() => {
                    resetMetaData()
                    setActive("catalogue")
                    navigate("/product-catalogue")
                  }}
                >
                  Product Catalogue
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Sidebar
