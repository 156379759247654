import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { useFormik } from "formik"
import * as Yup from "yup"

import { useState, useEffect } from "react"
import {
  getOptionsList,
  getOptionsListForTable,
} from "../../products/redux/ProductsApi"
import { addMoreOptions, updateMoreOptions } from "../redux/ProductCatalogueApi"
import SearchDropDown from "../../../components/SearchDropDown"
import DropDownBox from "../../../components/DropDownBox"
import { capitalizeInput } from "../../../helper/helper"

const UpdateSubCatForm = ({ customState, customSetter }) => {
  const [addStyle, setAddStyle] = useState(true)
  const [focusedField, setFocusedField] = useState()
  // const { catalogueLoading } = useSelector(store => store.department)
  const dispatch = useDispatch()

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  const formik = useFormik({
    initialValues: {
      category: "",
      categoryId: "",
    },
    validationSchema: Yup.object({
      category: Yup.string()
        .trim()
        .min(3, "Product category must be at least 3 characters long")
        .max(30, "Product category must be less than 30 characters")
        .required("Please enter sub category "),
      categoryId: Yup.string()
        .trim()
        .min(3, "Product category must be at least 3 characters long")
        .max(30, "Product category must be less than 30 characters")
        .required("Please enter category "),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        slug: "update-subCategory",
        id: customState?.popupData?._id,
        send: {
          sub_category_name: values.category.trim(),
          category_id: values.categoryId,
        },
      }
      const response = await dispatch(updateMoreOptions(data))
      if (response.payload) {
        dispatch(
          getOptionsListForTable({
            listName: "subCategory-list",
            page: "0",
            limit: "5",
            search: "",
            sort: "desc",
          }),
        )
        handleClick()
      }
    },
  })

  function chatBoxVisibility() {
    if (customState.edit) {
      const styleTimer = setTimeout(() => {
        customSetter({ ...customState, edit: false })
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
    formik.setValues({
      category: customState?.popupData?.name,
      categoryId: customState?.popupData?.categoryId,
    })
  }, [])
  const [optionsList, setOptionsList] = useState({
    uomList: [],
    brandList: [],
    categoryList: [],
    subCategoryList: [],
    subChildCategoryList: [],
  })

  const apiCall = async (
    listName,
    optionsName,
    dynamicLabel = "name",
    dynamicValue = "_id",
    dynamicApiLabel,
    formikLabel,
  ) => {
    if (listName) {
      const response = await dispatch(
        getOptionsList({
          listName,
          search: "",
          sort: "desc",
          limit: "0",
          page: "0",
          send:
            formikLabel && dynamicApiLabel
              ? {
                  [dynamicApiLabel]: formik.values[formikLabel],
                }
              : undefined,
        }),
      )
      if (optionsName) {
        const options = response?.payload?.map(item => {
          return {
            label: item[dynamicLabel],
            value: item[dynamicValue],
          }
        })
        setOptionsList({ ...optionsList, [optionsName]: options })
      }
    }
  }
  useEffect(() => {
    if (optionsList?.categoryList?.length === 0)
      apiCall("category-list", "categoryList")
  }, [])

  return (
    <div
      id='delete-card-container'
      className={`${
        addStyle
          ? " simple-fade-in-animation"
          : " simple-fade-out-animation"
      }`}
    >
      <div
        className={
          "event-card" +
          (addStyle
            ? " show_delete-component-animation"
            : " hide_delete-component-animation")
        }
      >
        <h2>Update Sub Category</h2>

        <div className='modelEx_content'>
          <form
            className='editbox-form flex-center'
            onSubmit={formik.handleSubmit}
          >
            <div className='form-group'>
              <div className='input-field'>
                <DropDownBox
                  options={optionsList.categoryList}
                  customSetter={formik.setFieldValue}
                  customFormikLabel={"categoryId"}
                  customDropBoxStyles={{
                    border: "1px solid #d7cece",
                    borderRadius: "0.5rem",
                    color: "#333",
                  }}
                  customTextStyle={{ color: "#212229", opacity: "0.7" }}
                  dropDownTitle='Category'
                  animateDropDownTitle
                  showSearchBar
                  incomingValue={customState.popupData.categoryId}

                  // disabled={formik.values.brand ? true : false}
                  // loading={optionsLoading}
                />
                {/* <SearchDropDown
                      label='Category'
                      name='category'
                      api={getOptionsList}
                      listName={"category-list"}
                      customSetter={formik.setFieldValue}
                    /> */}
                {formik.touched.categoryId && formik.errors.categoryId ? (
                  <span className='err'>{formik.errors.categoryId}</span>
                ) : null}
              </div>
            </div>

            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.category !== "" || focusedField === "category"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='category' className='toggle-label'>
                  Sub Category Name
                </label>
                <input
                  id='category'
                  name='category'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z-. \d]/gi, "")
                      .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                  }}
                  maxLength={30}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("category")}
                />
                {formik.touched.category && formik.errors.category ? (
                  <span className='err'>{formik.errors.category}</span>
                ) : null}
              </div>
            </div>

            {/* <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.subCategory !== "" ||
                  focusedField === "subCategory"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='subCategory' className='toggle-label'>
                  Sub-category
                </label>
                <input
                  id='subCategory'
                  name='subCategory'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.subCategory}
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z ]/gi, "")
                      .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                  }}
                  maxLength={30}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("subCategory")}
                    
                />
                {formik.touched.subCategory && formik.errors.subCategory ? (
                  <span className='err'>{formik.errors.subCategory}</span>
                ) : null}
              </div>
            </div>

            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.subChildCategory !== "" ||
                  focusedField === "subChildCategory"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='subChildCategory' className='toggle-label'>
                  Sub-child-category
                </label>
                <input
                  id='subChildCategory'
                  name='subChildCategory'
                  type='text'
                  className='form-input'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z ]/gi, "")
                      .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                  }}
                  maxLength={30}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("subChildCategory")}
                    
                />
                {formik.touched.subChildCategory &&
                formik.errors.subChildCategory ? (
                  <span className='err'>{formik.errors.subChildCategory}</span>
                ) : null}
              </div>
            </div>

            <div className='form-group'>
              <div
                className={`input-field ${
                  formik.values.description !== "" ||
                  focusedField === "description"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor='description' className='toggle-label'>
                  Description
                </label>
                <textarea
                  id='description'
                  name='description'
                  rows={4}
                  className={"form-input"}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  maxLength={151}
                  onBlur={e => {
                    setFocusedField("")
                    formik.handleBlur(e)
                  }}
                  onFocus={() => setFocusedField("description")}
                    
                />
                {formik.touched.description && formik.errors.description ? (
                  <span className='err mt-2'>{formik.errors.description}</span>
                ) : null}
              </div>
            </div> */}
            <div className='addPopup-btn-box'>
              <button
                type='button'
                className='btn-secondary'
                onClick={handleClick}
              >
                Abort
              </button>
              <button
                className='btn-primary'
                type='submit'
                // style={
                //   catalogueLoading
                //     ? {
                //         cursor: "not-allowed",
                //         // background: "gray",
                //       }
                //     : {}
                // }
                // disabled={catalogueLoading}
              >
                {/* {catalogueLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "SUBMIT"
                )} */}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdateSubCatForm
