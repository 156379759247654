import React, { useEffect, useState } from "react"
import Skelaton from "../../../components/Skelaton"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useFormik } from "formik"
import { formatTimestamp } from "../../../helper/helper"
import FormButtons from "../../../components/SubmitBtns"
import { toast } from "react-toastify"

const AdditionalCharges = ({
  formData,
  setFormData,
  IspurchaseOrder,
  setGrandTotals,
  grandTotals,
  purchaseApiDetails,
}) => {
  const [addStyle, setAddStyle] = useState(true)
  // const { privileges } = useSelector(state => state.auth, shallowEqual)
  const [toggleState, setToggleState] = useState(false)

  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const [invoice, setInvoice] = useState()
  const [signature, setSignature] = useState()
  const [commentState, setCommentState] = useState()
  let totalExtraChargers = 0

  const dispatch = useDispatch()

  const [searchTimeout, setSearchTimeout] = useState(null)
  const callApi = async (value, index) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    const timeoutId = setTimeout(async () => {
      if (value?.length > 2) {
        // const response = await dispatch(
        //   getStockList({ search: value, send: { locationId: fromStore } }),
        // )
        // setHsnList(response?.payload?.data)
      }
    }, 1000)
    setSearchTimeout(timeoutId)
  }

  const formik = useFormik({
    initialValues: {
      rows: [
        {
          description: "",
          total: "",
        },
      ],
    },
    validationSchema: Yup.object().shape({
      rows: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required("Description is required"),
          total: Yup.string().required("Total is required"),
        }),
      ),
    }),
    onSubmit: async values => {},
  })

  const [search, setSearch] = useState("")
  const [activeNav, setActiveNav] = useState(IspurchaseOrder ? 0 : 2)
  const formDataSetter = () => {
    setFormData(oldList => {
      let newList = JSON.parse(JSON.stringify(oldList))
      newList.additionalCharges = {
        extraCharges: formik.values,
        comment: commentState,
        invoice,
        company_signature: signature,
      }

      return newList
    })
    // setGrandTotals(oldList => oldList?.subtotal)
  }

  useEffect(() => {
    if (!formData?.additionalCharges) {
      formDataSetter()
    }
  }, [formData?.additionalCharges, commentState, formik.values, signature])
  useEffect(() => {
    let grandSubtotal = 0
    formik.values.rows.forEach(item => {
      const { total } = item
      grandSubtotal += Number(total)
    })

    grandSubtotal =
      Number(grandSubtotal) > 0
        ? Number(grandSubtotal) + Number(grandTotals?.reservedSubtotal)
        : grandTotals?.reservedSubtotal
    setGrandTotals(oldValues => {
      let newValues = JSON.parse(JSON.stringify(oldValues))

      newValues.subtotal = grandSubtotal
      newValues.grandTotal = newValues?.subtotal + newValues?.taxes
      return newValues
    })
  }, [toggleState, formik.values.rows])

  useEffect(() => {
    // formik.setValues({
    //   rows:add
    // })
    setCommentState(
      purchaseApiDetails?.comment && purchaseApiDetails?.comment !== "undefined"
        ? purchaseApiDetails?.comment
        : "",
    )
    setSignature(purchaseApiDetails?.signature)
  }, [purchaseApiDetails?.comment, purchaseApiDetails?.signature])

  return (
    <>
      <div
        className='main-box-padding data-table-container'
        style={{ padding: "1.8rem", borderRadius: "1.5rem" }}
      >
        <div className='modelEx_content singleAdd'>
          <div className='add-navDiv'>
            <button
              className={
                "product-cat-btn2" +
                (activeNav === 0 || activeNav === 2 ? " active" : "")
              }
              onClick={() => {
                setActiveNav(IspurchaseOrder ? 0 : 2)
              }}
            >
              {IspurchaseOrder ? "Extra Charges" : "Invoice"}
            </button>
            <button
              className={
                "product-cat-btn2" + (activeNav === 1 ? " active" : "")
              }
              onClick={() => {
                setActiveNav(1)
              }}
            >
              Comment
            </button>{" "}
            <button
              className={
                "product-cat-btn2" + (activeNav === 3 ? " active" : "")
              }
              onClick={() => {
                setActiveNav(3)
              }}
            >
              Signature
            </button>{" "}
            {/* <button
              className={
                "product-cat-btn2" + (activeNav === 2 ? " active" : "")
              }
              onClick={() => {
                setActiveNav(2)
              }}
            >
              Invoice
            </button> */}
          </div>

          {activeNav === 0 && (
            <AdditionalCharge
              privileges={privileges}
              formik={formik}
              setToggleState={setToggleState}
              toggleState={toggleState}
              purchaseApiDetails={purchaseApiDetails.extraCharge}
            />
          )}
          {activeNav === 1 && (
            <Comments
              commentState={commentState}
              commentSetter={setCommentState}
            />
          )}

          {activeNav === 2 && <AttachDocument setInvoice={setInvoice} />}
          {activeNav === 3 && (
            <AttachSignature
              setSignature={setSignature}
              signature={signature}
            />
          )}
        </div>
      </div>
    </>
  )
}
const AdditionalCharge = ({
  privileges,
  formik,
  setToggleState,
  toggleState,
  purchaseApiDetails,
}) => {
  //
  const addRow = () => {
    const newRow = {
      description: "",
      total: "",
    }
    formik.setFieldValue("rows", [...formik.values.rows, newRow])
  }

  const deleteRow = index => {
    const rows = [...formik.values.rows]
    rows.splice(index, 1)
    formik.setFieldValue("rows", rows)
  }

  return (
    <div className='modelEx_content singleAdd'>
      <div className='profile_personal_details'>
        {false ? (
          <Skelaton col={4} row={5} />
        ) : true ? (
          <>
            <div className='modelEx_content'>
              {/* <div className='data-table-container'> */}
              <form onSubmit={formik.handleSubmit}>
                <div
                  className='td_table_container'
                  // style={{paddingBottom:"6rem"}}
                >
                  <table className='td_table extra-ps'>
                    <thead>
                      <tr>
                        <th colSpan={5}>Extra Charges Description</th>
                        <th>Total</th>

                        <td className='stick-rh' />
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.rows.map((row, index) => (
                        <tr key={index}>
                          <td colSpan={5}>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].description`}
                                onChange={formik.handleChange}
                                value={row.description}
                                style={{ cursor: "not-allowed" }}
                                // disabled
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.description && formik?.touched?.rows[index]?.description ? "error" : ""}`}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].total`}
                                onChange={e => {
                                  formik.handleChange(e)
                                  setToggleState(!toggleState)
                                }}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.quantity ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.total && formik?.touched?.rows[index]?.total ? "error" : ""}`}
                                value={row.total}
                              />
                            </div>
                          </td>

                          <td className='stick-rh'>
                            <button
                              type='button'
                              className='icon-btn delete'
                              onClick={() => deleteRow(index)}
                              disabled={
                                formik.values.rows.length === 1 ? true : false
                              }
                            >
                              <span className='material-symbols-outlined medium'>
                                delete
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='error-msg'>
                    {formik.errors.rows ? "Please fill the data first!" : ""}
                  </div>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div className='Nodata-txt'>
            <img
              src={require("../../../assets/images/nodata.svg").default}
              alt=''
              srcSet=''
            />
            No Data Found
          </div>
        )}
        <div className='table-search'>
          <div
            className='search-container'
            style={{ justifyContent: "flex-end", paddingTop: "1rem" }}
          >
            {" "}
            <div className='create-btn-container'>
              {Object.values(privileges["purchase_management"]).includes(
                "write",
              ) && (
                <button className='btn-secondary header-btn  ' onClick={addRow}>
                  Add Extra Charges
                </button>
              )}
            </div>
            {/* <button className='btn-primary' type='submit'>
              Submit
            </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}
const Comments = ({ commentState, commentSetter }) => {
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const { productsLoading } = useSelector(store => store.products)
  const [showSubmitButton, setShowSubmitButton] = useState(false)
  const initialValues = {
    commentBox: "",
  }
  const commentSchema = Yup.object({
    commentBox: Yup.string()
      .trim()
      .required("Comment field is required")
      .max(700),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: commentSchema,
    onSubmit: async (values, { resetForm }) => {
      // const data = {
      //   product_id: id,
      //   comment: values.commentBox.trim(),
      // }

      commentSetter(values.commentBox)
      setShowSubmitButton(false)
    },
  })

  useEffect(() => {
    if (commentState) {
      formik.setValues({
        commentBox: commentState,
      })
    }
  }, [])

  return (
    <>
      <div className='modelEx_content singleAdd'>
        {Object.values(privileges["sales_management"]).includes("write") && (
          <form className='product-detail-form' onSubmit={formik.handleSubmit}>
            <div className='form-content'>
              <div className='form-group form'>
                <div>
                  <textarea
                    type='text'
                    name='commentBox'
                    className='commentInput cmS'
                    placeholder='Type Comment Here...'
                    value={formik.values.commentBox}
                    onChange={e => {
                      formik.handleChange(e)
                      setShowSubmitButton(true)
                    }}
                    maxLength={701}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        formik.handleSubmit()
                      }
                    }}
                  />
                  {formik.touched.commentBox && formik.errors.commentBox ? (
                    <span className='err'>{formik.errors.commentBox}</span>
                  ) : null}
                </div>
              </div>
            </div>
            {showSubmitButton && (
              <div
                className='flex-space-between'
                style={{ justifyContent: "flex-end", paddingTop: "1rem" }}
              >
                <button className='btn-primary' type='submit'>
                  Submit
                </button>
              </div>
            )}
          </form>
        )}{" "}
      </div>
    </>
  )
}

const AttachDocument = ({ setInvoice }) => {
  const [selectedFileDoc, setSelectedFileDoc] = useState(null)

  const handleChange = e => {
    const selectedFile = e.currentTarget.files[0]
    if (selectedFile) {
      const docType = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/",
      ]
      // if (docType.includes(selectedFile.type)) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        toast.warning(
          "File size exceeds 5MB limit. Please select a smaller file.",
        )
        e.target.value = null
      } else {
        setSelectedFileDoc(selectedFile)
        setInvoice("attachedFile", e.currentTarget.files[0])
        e.target.value = null
      }
      // } else {
      //   toast.warning("Images and pdf files only")
      //   e.target.value = null
      // }
    }
  }

  function handleDelete() {
    formik.setFieldValue("attachedFile", null)
    setSelectedFileDoc()
  }

  const formik = useFormik({
    initialValues: {
      attachedFile: "",
    },
    validationSchema: Yup.object().shape({
      attachedFile: Yup.string().required("Please select a product"),
    }),
    onSubmit: async values => {
      setInvoice(values.attachedFile)
    },
  })

  return (
    <div className='profile_personal_details'>
      {/* <div className='col1 heading-tools details-controls'>
        <h2>Attach Signature</h2>
      </div> */}

      <div className='modelEx_content'>
        <form
          className='multipleComForm flex-center pt-1 form-group'
          onSubmit={formik.handleSubmit}
        >
          <div className='file_input flex-center file_name-box sl'>
            {selectedFileDoc ? (
              <div className=''>
                <u>File</u>: {selectedFileDoc.name}
                <span
                  className='material-symbols-outlined medium'
                  onClick={handleDelete}
                >
                  cancel
                </span>
              </div>
            ) : (
              <>
                <div className='txt attach flex-center'>
                  <i className='fa-duotone fa-solid fa-folder-arrow-up'></i>
                  <span>Upload Invoice</span>
                </div>
                <input
                  type='file'
                  className='excel_input'
                  id='excel_input'
                  name='file'
                  accept='.jpg,.jpeg,.png , application/pdf'
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          {formik.errors.attachedFile && formik.touched.attachedFile && (
            <span className='err'> {formik.errors.attachedFile} </span>
          )}

          <FormButtons
            // loading={companyLoading}
            removeCancel
            btnContainerStyles={{
              justifyContent: "flex-end",
              padding: ".5rem",
            }}
          />
        </form>
      </div>
    </div>
  )
}

const AttachSignature = ({ setSignature, signature }) => {
  const [selectedFileDoc, setSelectedFileDoc] = useState(null)
  const formik = useFormik({
    initialValues: {
      attachedFile: "",
    },
    validationSchema: Yup.object().shape({
      attachedFile: Yup.string().required("Please select a product"),
    }),
    onSubmit: async values => {
      setSignature(values)
    },
  })
  const handleChange = e => {
    const selectedFile = e.currentTarget.files[0]
    if (selectedFile) {
      const docType = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/",
      ]
      // if (docType.includes(selectedFile.type)) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        toast.warning(
          "File size exceeds 5MB limit. Please select a smaller file.",
        )
        e.target.value = null
      } else {
        setSelectedFileDoc(selectedFile.name)
        setSignature(e.currentTarget.files[0])
        e.target.value = null
      }
      // } else {
      //   toast.warning("Images and pdf files only")
      //   e.target.value = null
      // }
    }
  }

  function handleDelete() {
    formik.setFieldValue("attachedFile", null)
    setSelectedFileDoc()
  }

  useEffect(() => {
    if (signature) {
      setSelectedFileDoc(signature)
    }
  }, [])

  return (
    <div className='profile_personal_details'>
      {/* <div className='col1 heading-tools details-controls'>
        <h2>Attach Signature</h2>
      </div> */}

      <div className='modelEx_content'>
        <form
          className='multipleComForm flex-center pt-1 form-group'
          onSubmit={formik.handleSubmit}
        >
          <div className='file_input flex-center file_name-box sl'>
            {selectedFileDoc ? (
              <div className=''>
                <u>File</u>: {selectedFileDoc}
                <span
                  className='material-symbols-outlined medium'
                  onClick={handleDelete}
                >
                  cancel
                </span>
              </div>
            ) : (
              <>
                <div className='txt attach flex-center'>
                  <i className='fa-duotone fa-solid fa-folder-arrow-up'></i>
                  <span>Upload Signature</span>
                </div>
                <input
                  type='file'
                  className='excel_input'
                  id='excel_input'
                  name='file'
                  accept='.jpg,.jpeg,.png , application/pdf'
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          {formik.errors.attachedFile && formik.touched.attachedFile && (
            <span className='err'> {formik.errors.attachedFile} </span>
          )}

          {/* <FormButtons
            // loading={companyLoading}
            removeCancel
            btnContainerStyles={{
              justifyContent: "flex-end",
              padding: ".5rem",
            }}
          /> */}
        </form>
      </div>
    </div>
  )
}

export default AdditionalCharges
