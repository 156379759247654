import { createSlice } from "@reduxjs/toolkit"
import { getCompanyAddress, getCompanyDetail, getCompanyList } from "./bNsApi"

const bNsSlice = createSlice({
  name: "bNs",
  initialState: {
    addressLoading: false,
    companyLoading: false,
    companyList: [],
    metaData: [],
    companyDetail: [],
    companyAddress: [],
  },
  reducers: {
    isCompanyLoading: (state, { payload }) =>
      (state = {
        ...state,
        companyLoading: payload,
      }),
    isAddressLoading: (state, { payload }) =>
      (state = {
        ...state,
        addressLoading: payload,
      }),
  },
  extraReducers: builder => {
    // getCompanyList reducers-------------------------
    builder.addCase(getCompanyList.pending, (state, action) => {
      state.companyLoading = true
    })
    builder.addCase(getCompanyList.fulfilled, (state, action) => {
      state.companyLoading = false
      state.companyList = action.payload.data
      state.metaData = action.payload.metadata[0]
      
      
    })
    builder.addCase(getCompanyList.rejected, (state, action) => {
      state.companyLoading = false
      state.companyList = []
    })
    // getSingleCompanyDetail reducers-------------------------
    builder.addCase(getCompanyDetail.pending, (state, action) => {
      state.companyLoading = true
    })
    builder.addCase(getCompanyDetail.fulfilled, (state, action) => {
      // 
      state.companyLoading = false
      state.companyDetail = action.payload
    })
    builder.addCase(getCompanyDetail.rejected, (state, action) => {
      state.companyLoading = false
    })
    // get company address
    builder.addCase(getCompanyAddress.pending, state => {
      state.companyLoading = true
    })
    builder.addCase(getCompanyAddress.fulfilled, (state, { payload }) => {
      state.companyLoading = false
      state.companyAddress = payload.data
    })
    builder.addCase(getCompanyAddress.rejected, state => {
      state.companyLoading = false
    })
  },
})

export const { isCompanyLoading, isAddressLoading } = bNsSlice.actions
export default bNsSlice.reducer
