import React, { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import Skelaton from "../../../components/Skelaton"
import { optionsList } from "../../../helper/dummy_data"
import Pagination from "../../../components/Pagination"
import { getOptionsListForTable } from "../../products/redux/ProductsApi"
import { setPageDetails } from "../../auth/redux/authSlice"

const ProductCatTable = ({ deleteFunction, customState, customSetter }) => {
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const { optionsList, metaData } = useSelector(store => store.catalogue)

  const dispatch = useDispatch()
  const [tableIndex, setTableIndex] = useState(0)

  const [records, setRecords] = useState(5)
  const [sort, setFilter] = useState("desc")

  const [search, setSearch] = useState(
    pageDetails.search ? pageDetails.search : "",
  )
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      dispatch(
        getOptionsListForTable({
          page:
            Object.keys(pageDetails).length > 0 &&
            pageDetails.page !== undefined
              ? pageDetails?.page
              : 0,
          limit: records,
          search:
            Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
          sort: "desc",
          listName: "category-list",
        }),
      )
      setCheck(true)
      if (metaData?.length > 0) {
        dispatch(setPageDetails({ page: metaData?.page, search }))
      }
      return
    }
    const timeoutId = setTimeout(async () => {
      dispatch(
        getOptionsListForTable({
          page: 0,
          limit: records,
          search,
          sort,
          listName: "category-list",
        }),
      )
      setTableIndex(0)
      setBtnNo([1, 2, 3])
      // if (selectAllProducts) {
      //   setSelectAllProducts(false)
      //   setCollectAllProducts([])
      // }
    }, 1000)
    setSearchTimeout(timeoutId)
    dispatch(setPageDetails({ page: metaData?.page, search }))
  }, [search, dispatch, records, sort])

  useEffect(() => {
    dispatch(setPageDetails({ page: metaData?.page, search }))
  }, [metaData])

  return (
    <div className='data-table-container'>
      <div className='tab-header'>
        <h3>Products Categories</h3>
        {Object.values(privileges["catalogue_management"]).includes(
          "write",
        ) && (
          <button
            className='btn-primary'
            onClick={() => {
              customSetter({ ...customState, add: !customState.add })
            }}
          >
            ADD PRODUCT CATEGORY
          </button>
        )}
      </div>
      {false ? (
        <Skelaton col={4} row={7} />
      ) : optionsList?.length > 0 ? (
        <>
          <div className='data-table-box scrollbar-visible'>
            <table className='data-table fix-width-table-row'>
              <thead>
                <tr className='catalogue-list-headings'>
                  <th className='tb-head-name padding-07 '>#</th>
                  <th>Category Name</th>
                  <th>Brand Name</th>
                  <th>Margin</th>
                  {/* <th>Sub Category</th>
                  <th>Sub-Child Category</th>
                  <th>Description</th> */}
                  {Object.values(privileges["catalogue_management"]).includes(
                    "write",
                  ) && <th>Actions</th>}
                </tr>
              </thead>

              <tbody>
                {optionsList?.map((item, index) => {
                  return (
                    <tr className='catalogue-list-headings' key={index}>
                      <td className='tb_data padding-07'>{item.num}</td>
                      <td>{item.name}</td>
                      <td>{item.brandName}</td>
                      <td>{item.margin}%</td>
                      {/* <td>{item.subCategory}</td>
                      <td>{item.subChildCategory}</td>
                      <td>{item.description}</td> */}
                      <td>
                        <div className='email-action-box'>
                          {Object.values(
                            privileges["catalogue_management"],
                          ).includes("write") && (
                            <button
                              className='icon-btn edit'
                              onClick={() =>
                                customSetter({
                                  ...customState,
                                  edit: !customState.edit,
                                  popupData: item,
                                })
                              }
                            >
                              <span className='material-symbols-outlined medium'>
                                border_color
                              </span>
                            </button>
                          )}
                          {Object.values(
                            privileges["catalogue_management"],
                          ).includes("delete") && (
                            <button
                              className='icon-btn delete'
                              onClick={() =>
                                deleteFunction(
                                  item._id,
                                  item.name,
                                  "delete-category",
                                  "category-list",
                                )
                              }
                            >
                              <span className='material-symbols-outlined medium'>
                                Delete
                              </span>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <Pagination
            sliceName={"catalogue"}
            search={search}
            filter={"desc"}
            api={getOptionsListForTable}
            limit={5}
            tableIndex={tableIndex}
            setTableIndex={setTableIndex}
            btnNo={btnNo}
            setBtnNo={setBtnNo}
            listName={"category-list"}
          />
        </>
      ) : (
        <div className='Nodata-txt'>
          <img
            src={require("../../../assets/images/nodata.svg").default}
            alt=''
            srcSet=''
          />
          No Data Found
        </div>
      )}
    </div>
  )
}

export default ProductCatTable
