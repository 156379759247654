import React, { useEffect, useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import DataNotFound from "../../../components/DatanotFound"

const SalesAddressDetails = ({
  setIsSelectCompany,
  formData,
  setFormData,
  setPopup,
}) => {
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { buyerAddress, salesLoading, buyerGst, buyerWarehouseAddress } = useSelector(
    store => store.sales,
    shallowEqual,
  )

  const [activeInputs, setActiveInputs] = useState(false)

  const [workAddress, setWorkAddress] = useState([])
  const [sellerAddress, setSellerAddress] = useState([])
  const { companyDetails, companyAddress } = useSelector(store => store.company, shallowEqual)

  useEffect(() => {
    const wa = buyerAddress.filter(ele => ele.type === "work")
    setWorkAddress(wa)

    const supplierPlace = companyAddress?.filter((item) => item.type === "work")
    setSellerAddress(supplierPlace[0])
  }, [buyerAddress, companyAddress])

  useEffect(() => {
    if (!formData?.addressDetails) {
      setFormData(oldList => {
        let newList = JSON.parse(JSON.stringify(oldList))

        const AddressDetails = {
          supplierAddress: companyDetails?._id,
          soldBy: companyDetails?._id,
          orderBy: workAddress[0]?.companyId,
          shippingAddress: buyerWarehouseAddress?.addressId,
        }

        newList.addressDetails = {
          supplier: sellerAddress,
          buyer: workAddress[0],
          delivery: buyerWarehouseAddress,
          AddressDetails,
        }
        return newList
      })
    }
  }, [formData?.addressDetails, workAddress, buyerWarehouseAddress])
  

  return (
    <div className='main-box-padding'>
      <div className='modelEx_content singleAdd w-full'>
        <div className='flex ps-add'>
          <div
            className='modelEx_content singleAdd w-full'
            style={{ marginBottom: "1rem" }}
          >
            <div className='profile_personal_details'>
              <div className='details-controls'>
                <h2>Buyer Details</h2>
                {Object.values(privileges["purchase_management"]).includes(
                  "write",
                ) && (
                    <div className='detail-btn'>
                      {activeInputs ? (
                        <>
                          <button
                            type='submit'
                            //   onClick={handleCancel}
                            className='btn-secondary'
                            disabled={salesLoading}
                            style={salesLoading ? { cursor: "not-allowed" } : {}}
                          >
                            {salesLoading ? (
                              <ClipLoader
                                loading={true}
                                size={13}
                                aria-label='Loading Spinner'
                                data-testid='loader'
                              />
                            ) : (
                              "Cancel"
                            )}
                          </button>{" "}
                          <button
                            type='submit'
                            //   onClick={formik.handleSubmit}
                            className='btn-primary'
                            disabled={salesLoading}
                            style={salesLoading ? { cursor: "not-allowed" } : {}}
                          >
                            {salesLoading ? (
                              <ClipLoader
                                loading={true}
                                size={13}
                                aria-label='Loading Spinner'
                                data-testid='loader'
                              />
                            ) : (
                              "Save"
                            )}
                          </button>{" "}
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            { setIsSelectCompany({ key: "buyer", value: true, showDeliveryAddressBtn: true }); setPopup(2) } // setActiveInputs(true)
                          }}
                          className='btn-secondary'
                        >
                          <span className='material-symbols-outlined small'>
                            border_color
                          </span>
                          Edit
                        </button>
                      )}
                    </div>
                  )}{" "}
              </div>
            </div>
            <div className='location-collection flex'>
              <div className='card' onClick={() => { }}>
                {workAddress.length > 0 ? (
                  <div className='card' onClick={() => { }}>
                    <div className='col1'>
                      <h3 className='card-name'>{workAddress?.[0].name}</h3>
                      <div className='card-address'>
                        <p>{`${workAddress?.[0].address?.line_one}, `}</p>
                        <p>{`${workAddress?.[0].address?.line_two}${workAddress?.[0].address?.line_two ? "," : ""}`}</p>
                        <p>{`${workAddress?.[0].address?.city} - ${workAddress?.[0].address?.pin_code},`}</p>
                        <p>{`${workAddress?.[0].address?.state} - ${workAddress?.[0].address?.country}`}</p>
                        <p
                          style={{ marginTop: "0.7rem" }}
                        >{`GSTIN - ${buyerGst}`}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <DataNotFound title='No Buyer Selected' />
                )}
              </div>
            </div>
          </div>{" "}
          <div className='dash-border'></div>
          <div className='modelEx_content singleAdd w-full'>
            <div className='profile_personal_details'>
              <div className='details-controls'>
                <h2>Delivery Location</h2>
                {Object.values(privileges["purchase_management"]).includes(
                  "write",
                ) && (
                    <div className='detail-btn'>
                      {activeInputs ? (
                        <>
                          <button
                            type='submit'
                            //   onClick={handleCancel}
                            className='btn-secondary'
                            disabled={salesLoading}
                            style={salesLoading ? { cursor: "not-allowed" } : {}}
                          >
                            {salesLoading ? (
                              <ClipLoader
                                loading={true}
                                size={13}
                                aria-label='Loading Spinner'
                                data-testid='loader'
                              />
                            ) : (
                              "Cancel"
                            )}
                          </button>{" "}
                          <button
                            type='submit'
                            //   onClick={formik.handleSubmit}
                            className='btn-primary'
                            disabled={salesLoading}
                            style={salesLoading ? { cursor: "not-allowed" } : {}}
                          >
                            {salesLoading ? (
                              <ClipLoader
                                loading={true}
                                size={13}
                                aria-label='Loading Spinner'
                                data-testid='loader'
                              />
                            ) : (
                              "Save"
                            )}
                          </button>{" "}
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            setIsSelectCompany({ key: "delivery", value: true, showMyAddress: true }) // setActiveInputs(true)
                          }}
                          className='btn-secondary'
                        >
                          <span className='material-symbols-outlined small'>
                            border_color
                          </span>
                          Edit
                        </button>
                      )}
                    </div>
                  )}{" "}
              </div>
            </div>
            <div className='location-collection flex'>
              {buyerWarehouseAddress && Object.keys(buyerWarehouseAddress)?.length > 0 ? (
                <div className='card' onClick={() => { }}>
                  <div className='col1'>
                    <h3 className='card-name'>{workAddress?.[0]?.name}</h3>
                    <div className='card-address'>
                      <p>{buyerWarehouseAddress?.address?.line_one},</p>
                      <p>{buyerWarehouseAddress?.address?.line_two},</p>
                      <p>{buyerWarehouseAddress?.cityName} - {buyerWarehouseAddress?.address?.pin_code}</p>
                      <p>{buyerWarehouseAddress?.stateName} - {buyerWarehouseAddress?.countryName}</p>
                    </div>
                  </div>
                </div>
              ) : (<DataNotFound title='No Buyer Selected' />)}

            </div>
          </div>
        </div>
        <hr style={{ width: "100%" }} />
        <div className='profile_personal_details' style={{ marginTop: "1rem" }}>
          <div className='details-controls'>
            <h2>Supplier Details</h2>
          </div>
        </div>
        <div className='location-collection flex'>
          <div className='card' onClick={() => { }}>
            <div className='col1'>
              <h3 className='card-name'>{companyDetails?.name}</h3>

              <div className='card-address'>
                <p>{companyDetails?.line_one}</p>
                <p>{companyDetails?.line_two}</p>
                <p>{`${companyDetails?.cityName} - ${companyDetails?.pin_code},`}</p>
                <p>{`${companyDetails?.stateName} - ${companyDetails?.countryName}`}</p>
                <p style={{ marginTop: "0.7rem" }}>
                  <strong>Contact No - </strong>
                  {companyDetails?.companyPhone?.length > 10 ? companyDetails?.companyPhone?.slice(3, 13) : companyDetails?.companyPhone}
                </p>
                <p style={{ marginTop: "0.7rem" }}>
                  <strong>Email - </strong>
                  {companyDetails?.companyEmail?.value}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesAddressDetails
