import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import DropDownBox from "../../../components/DropDownBox"

const UpdateSalesPopup = ({ customSetter, customState, activePo }) => {
  const [addStyle, setAddStyle] = useState(true)
  // const [activeInputs, setActiveInputs] = useState(false);

  const dispatch = useDispatch()

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (customState) {
      const styleTimer = setTimeout(() => {
        customSetter(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")

    // if (type === "Edit") {

    //     formik.setValues({
    //       address_line_one: companyAddress?.address?.line_one,
    //       address_line_two: companyAddress?.address?.line_two,
    //       city: companyAddress?.city_detail?.name,
    //       state: companyAddress?.state_detail?.name,
    //       country: companyAddress?.country_detail?.name,
    //       pinCode: companyAddress?.address?.pin_code,
    //     })
    // }
  }, [])

  const initialFields = {
    rows: [
      {
        sku_num: "",
        product_name: "",
        product_code: "",
        hsn_code: "",
        batch_no: "",
        packSize: "",
        pack_quantity: "",
        pack_num: "",
        total_quantity: "",
        deliveryDate: "",
        total_amount: "",
      },
    ],
  }

  const salesOrderFields = {
    amount: "",
    tax: "",
    dispatch_qty: "",
    status: "",
  }

  const salesReturnFields = {
    deliverd_products: "",
    sales_returnQty: "",
  }

  const getInitialValues = () => {
    const specificFields = activePo === 3 ? salesOrderFields : salesReturnFields
    return {
      rows: [{ ...initialFields, ...specificFields }],
    }
  }

  const getValidationSchema = Yup.object().shape({
    rows: Yup.array().of(
      Yup.object().shape({
        sku_num: Yup.string().required("SKU is required"),
        product_name: Yup.string().required("Product Name is required"),
        product_code: Yup.string().required("Product code is required"),
        hsn_code: Yup.string().required("HSN code is required"),
        batch_no: Yup.string().required("This field is required"),
        pack_quantity: Yup.string().required("Pack Quantity is required"),
        total_quantity: Yup.string().required("Total Quantity is required"),
        packSize: Yup.string().required("Pack Size is required"),
        pack_num: Yup.string().required("no. of pack is required"),
        amount: Yup.string().required("Amount is required"),
        tax: Yup.string().required("Tax is required"),
        total_amount: Yup.string().required("Total amount is required"),
        deliveryDate: Yup.string().required("Delivery date is required"),
        dispatch_qty: Yup.string().required("Dispatched Quantity is required"),
        status: Yup.string().required("Status is required"),
      }),
    ),
  })

  const formik = useFormik({
    initialValues: getInitialValues(),
    // validationSchema: getValidationSchema,
    onSubmit: async values => {},
  })

  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>Update Delivery Status</h2>
          <button className='exit-btn' onClick={() => handleClick()}>
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>

        <div className='modelEx_content singleAdd'>
          <div className='modelEx_content'>
            <div className='overAll-st'>
              <div className='input-field active'>
                <span htmlFor='' className='toggle-label'>
                  Overall Status
                </span>
                <input
                  type='text'
                  className='form-input'
                  disabled={true}
                  defaultValue='Delivered'
                />
              </div>
            </div>

            <div className='data-table-container'>
              <form onSubmit={formik.handleSubmit}>
                <div
                  className='td_table_container'
                  // style={{paddingBottom:"6rem"}}
                >
                  <table className='td_table'>
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Product Name</th>
                        <th>Product Code</th>
                        <th>HSN Code</th>
                        <th>Batch No.</th>
                        <th>Pack Size</th>
                        <th>Pack Quantity</th>
                        <th>No. of packs</th>
                        <th>Total Quantity</th>
                        <th>Delivery Date</th>

                        {activePo === 3 && (
                          <>
                            {" "}
                            <th>Amount</th>
                            <th>Tax</th>
                          </>
                        )}

                        {activePo === 4 && (
                          <>
                            <th>Delivered Quantity</th>
                            <th>Sales Return Qty.</th>
                          </>
                        )}

                        <th>Total Amount</th>

                        {activePo === 3 && (
                          <>
                            <th>Dispatched Quantity</th>
                            <th>Delivery Status</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.rows.map((row, index) => (
                        <tr key={index}>
                          {" "}
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].sku_num`}
                                onChange={formik.handleChange}
                                value={row.sku_num}
                                style={{ cursor: "not-allowed" }}
                                // disabled
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.sku_num && formik?.touched?.rows[index]?.sku_num ? "error" : ""}`}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].product_name`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.quantity ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.product_name && formik?.touched?.rows[index]?.product_name ? "error" : ""}`}
                                value={row.product_name}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].product_code`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.product_code && formik?.touched?.rows[index]?.product_code ? "error" : ""}`}
                                value={row.product_code}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].hsn_code`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.hsn_code && formik?.touched?.rows[index]?.hsn_code ? "error" : ""}`}
                                value={row.hsn_code}
                                // disabled={
                                //   fromStore && toStore ? false : true
                                // }
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].batch_no`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.batch_no && formik?.touched?.rows[index]?.batch_no ? "error" : ""}`}
                                value={row.batch_no}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].packSize`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.packSize && formik?.touched?.rows[index]?.packSize ? "error" : ""}`}
                                value={row.packSize}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].pack_quantity`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.pack_quantity && formik?.touched?.rows[index]?.pack_quantity ? "error" : ""}`}
                                value={row.pack_quantity}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].pack_num`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.pack_num && formik?.touched?.rows[index]?.pack_num ? "error" : ""}`}
                                value={row.pack_num}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].total_quantity`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.total_quantity && formik?.touched?.rows[index]?.total_quantity ? "error" : ""}`}
                                value={row.total_quantity}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='input-field'>
                              <input
                                type='date'
                                name={`rows[${index}].deliveryDate`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.deliveryDate && formik?.touched?.rows[index]?.deliveryDate ? "error" : ""}`}
                                value={row.deliveryDate}
                              />
                            </div>
                          </td>
                          {activePo === 3 && (
                            <>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].amount`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.amount && formik?.touched?.rows[index]?.amount ? "error" : ""}`}
                                    value={row.amount}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].tax`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.tax && formik?.touched?.rows[index]?.tax ? "error" : ""}`}
                                    value={row.tax}
                                  />
                                </div>
                              </td>
                            </>
                          )}
                          {activePo === 4 && (
                            <>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='number'
                                    name={`rows[${index}].deliverd_products`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.deliverd_products && formik?.touched?.rows[index]?.deliverd_products ? "error" : ""}`}
                                    value={row.deliverd_products}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='number'
                                    name={`rows[${index}].sales_returnQty`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.sales_returnQty && formik?.touched?.rows[index]?.sales_returnQty ? "error" : ""}`}
                                    value={row.sales_returnQty}
                                  />
                                </div>
                              </td>
                            </>
                          )}
                          <td>
                            <div className='input-field'>
                              <input
                                type='text'
                                name={`rows[${index}].total_amount`}
                                onChange={formik.handleChange}
                                // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.total_amount && formik?.touched?.rows[index]?.total_amount ? "error" : ""}`}
                                value={row.total_amount}
                              />
                            </div>
                          </td>
                          {activePo === 3 && (
                            <>
                              <td>
                                <div className='input-field'>
                                  <input
                                    type='text'
                                    name={`rows[${index}].dispatch_qty`}
                                    onChange={formik.handleChange}
                                    // className={`td_input ${Object.hasOwn(formik.errors, "rows") && formik?.errors?.rows[index]?.unit ? "error" : ""}`}
                                    className={`td_input ${Object.hasOwn(formik.errors, "rows") && Object.hasOwn(formik.touched, "rows") && formik?.errors?.rows[index]?.dispatch_qty && formik?.touched?.rows[index]?.dispatch_qty ? "error" : ""}`}
                                    value={row.dispatch_qty}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  className='input-field'
                                  style={{ width: "13rem" }}
                                >
                                  <DropDownBox
                                    options={[
                                      { label: "Pending", value: "pending" },
                                      {
                                        label: "Dispatched",
                                        value: "dispatched",
                                      },
                                      {
                                        label: "Partially Dispatched",
                                        value: "partiallyDispatched",
                                      },

                                      {
                                        label: "Cancelled",
                                        value: "cancelled",
                                      },
                                    ]}
                                    defaultValue='Delivery Status'
                                    animateDropDownTitle
                                    customSetter={formik.setFieldValue}
                                    customFormikLabel={"status"}
                                    customDropBoxStyles={{
                                      border: "1px solid #d7cece",
                                      borderRadius: "0.5rem",
                                      color: "#333",
                                    }}
                                    customTextStyle={{
                                      color: "#212229",
                                      opacity: "0.7",
                                    }}
                                  />
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='error-msg'>
                    {formik.errors.rows ? "Please fill the data first!" : ""}
                  </div>
                </div>
                <button className='btn-secondary upPop'>Save</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateSalesPopup
