import React from "react"
import { useNavigate } from "react-router-dom"

const SalesTable = ({ listContent, listHeader, filterTable, activePo }) => {
  const navigate = useNavigate()
  return (
    <div>
      {" "}
      <div className='data-table-box scrollbar-visible pur-data'>
        <table className='data-table '>
          <thead>
            <tr>
              {listHeader?.map((li, index) => (
                <th className='' key={index}>
                  {li?.label}
                </th>
              ))}
              {/* {activePo === 2 && <th>Action</th>} */}
            </tr>
          </thead>

          <tbody>
            {listContent?.map((row, Rowindex) => {
              return (
                <tr
                  key={Rowindex}
                  onClick={() => {
                    navigate(`/sales/sales-order`)
                  }}
                  className='navig-table-row'
                  style={{ cursor: "pointer" }}
                >
                  {row.map((item, itemIndex) => (
                    <>
                      <td
                        key={itemIndex}
                        //   className='tb_data padding-07'
                        className={`tb_data padding-1 ${
                          listHeader[itemIndex].key === "delivery_status" ||
                          listHeader[itemIndex].key === "creation_status"
                            ? "status-column"
                            : ""
                        }`}
                      >
                        <span>{item}</span>
                        {(listHeader[itemIndex].key === "po_number" ||
                          listHeader[itemIndex].key === "invoice_number" ||
                          listHeader[itemIndex].key === "order_number" ||
                          listHeader[itemIndex].key === "so_number" ||
                          listHeader[itemIndex].key === "sales_return_no" ||
                          listHeader[itemIndex].key === "proforma_no" ||
                          listHeader[itemIndex].key === "schallan_no") && (
                          <span className='navIcon'>
                            <i className='fa-regular fa-arrow-up-right-from-square'></i>
                          </span>
                        )}
                      </td>
                    </>
                  ))}
                  {/* {activePo === 2 && (
                    <td>
                      <i className='fa-regular fa-pen'></i>
                    </td>
                  )} */}
                  {/* <td className='tb_data padding-07'>{item}</td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SalesTable
