/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
// import { deleteEmail, getEmailList } from "../redux/EmailApi";
import FilterBox from "../../../components/FilterBox"
import Skelaton from "../../../components/Skelaton"
import SearchBox from "../../../components/SearchBox"
// import { getInventoryList } from "../redux/InventoryApi"
import { setPageDetails } from "../../auth/redux/authSlice"
import { unixTimeToReadableFormat } from "../../../helper/helper"
import Pagination from "../../../components/Pagination"
import DropDownBox from "../../../components/DropDownBox"

const StockAlert = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Stock Alert")
  }, [setTitle])
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [active, setActive] = useState("stockAlert")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const [isEventCard, setIsEventCard] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const { inventoryList } = useSelector(state => state.inventory)
  const [states, setStates] = useState("")
  const [bidding, setBidding] = useState("")
  const [checkedCollection, setCheckedCollection] = useState([])
  const [filterTable, setFilterTable] = useState({
    stock_quantity: true,
    sold: true,
    created_at: true,
    minimum_stock_quantity: true,
  })
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const { inventoryLoading, metaData } = useSelector(store => store.inventory)
  const [searchShow, setSearchShow] = useState(false)

  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )

  //   useEffect(() => {
  //     if (searchTimeout || check) {
  //       clearTimeout(searchTimeout)
  //     } else {
  //       dispatch(
  //         getInventoryList({
  //           page: pageDetails?.page ? pageDetails?.page : 0,
  //           limit: records,
  //           search:
  //             Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
  //           sort: "desc",
  //         }),
  //       )
  //       setCheck(true)
  //       dispatch(setPageDetails({ page: metaData?.page, search }))
  //       return
  //     }

  //     const timeoutId = setTimeout(async () => {
  //       dispatch(
  //         getInventoryList({ limit: records, page: 0, search, sort: "desc" }),
  //       )
  //       setTableIndex(0)
  //       setBtnNo([1, 2, 3])
  //     }, 1000)
  //     dispatch(setPageDetails({ page: metaData?.page, search }))
  //     setSearchTimeout(timeoutId)
  //   }, [dispatch, records, search])

  //   useEffect(() => {
  //     dispatch(setPageDetails({ page: metaData?.page, search }))
  //   }, [metaData])

  const [tableLoading, setTableLoading] = useState(false)
  const [tableCount, setTableCount] = useState(null)
  // const [search, setSearch] = useState(
  //   pageDetails?.search ? pageDetails?.search : "",
  // )
  useEffect(() => {
    if (tableCount) {
      clearTimeout(tableCount)
    }
    const count = setTimeout(() => {
      setTableLoading(inventoryLoading)
    }, 1000)
    setTableCount(count)
  }, [inventoryLoading])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  const stockData = [
    {
      num: 1,
      id: "1",
      sku: "A12345",
      product_name: "Product111",
      refill_quantity: "5 ltr",
    },
    {
      num: 2,
      id: "2",
      sku: "A12345",
      product_name: "Product222",
      refill_quantity: "15 ltr",
    },
    {
      num: 3,
      id: "3",
      sku: "A12345",
      product_name: "Product333",
      refill_quantity: "55 ltr",
    },
  ]

  const [selectAllProducts, setSelectAllProducts] = useState(false)
  const [collectAllProducts, setCollectAllProducts] = useState([])
  

  useEffect(() => {
    const idArr = stockData?.map(item => {
      return item.id
    })
    if (selectAllProducts) {
      setCollectAllProducts(idArr)
    } else {
      setCollectAllProducts([])
    }
  }, [selectAllProducts])

  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        <div className='home-container'>
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Stock Alerts</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>
              <div className='data-table-container '>
                <div className='bns-header'>
                  <div className='col1 heading-tools'>
                    <h3>Stock Alerts Details</h3>
                    <div className='table-search'>
                      <div className='search-container'>
                        {" "}
                        {/* <SearchBox
                          customSetter={setSearch}
                          customState={search}
                        />
                       
                        <DropDownBox
                          options={[
                            { label: "Sent", value: "sent" },
                            { label: "Draft", value: "draft" },
                          ]}
                          customSetter={setStates}
                          size='small'
                          animateDropDownTitle
                          dropDownTitle='Creation Status'
                          customTextStyle={{
                            color: "#212229",
                            opacity: "0.7",
                          }}
                          resetButton={true}
                          //   incomingValue={category ? category : false}
                          customDropBoxStyles={{ height: "2.4rem" }}
                          animateDropDownTitleStyle={{
                            labelUp: { bottom: "25%" },
                            labelDown: { bottom: "2%" },
                          }}
                        />{" "}
                        <DropDownBox
                          options={[
                            { label: "All", value: "all" },
                            {
                              label: "Open For Bidding",
                              value: "open-for-bidding",
                            },
                            {
                              label: "Close For Bidding",
                              value: "close-for-bidding",
                            },
                          ]}
                          customSetter={setBidding}
                          size='small'
                          animateDropDownTitle
                          dropDownTitle='Bidding Status'
                          customTextStyle={{
                            color: "#212229",
                            opacity: "0.7",
                          }}
                          resetButton={true}
                          //   incomingValue={category ? category : false}
                          customDropBoxStyles={{ height: "2.4rem" }}
                          animateDropDownTitleStyle={{
                            labelUp: { bottom: "25%" },
                            labelDown: { bottom: "2%" },
                          }}
                        />{" "} */}
                        {collectAllProducts.length > 0 && (
                          <>
                            <button
                              className='btn-alpha bg-none header-btn  '
                            
                              onClick={() => {
                                setSelectAllProducts(false)
                              }}
                            >
                              Cancel
                            </button>
                            {Object.values(
                              privileges["purchase_management"],
                            ).includes("write") && (
                              <button
                                className='btn-alpha header-btn  '
                                onClick={() =>
                                  navigate("/purchase/purchase-order")
                                }
                              >
                                Create Purchase Order
                              </button>
                            )}{" "}
                          </>
                        )}
                      </div>

                      <div className='search-filter-flex'>
                        {/* {Object.values(
                        privileges["purchase_management"],
                      ).includes("delete") && selectAllProducts ? (
                        <button
                          className='btn-secondary header-btn delete-primary-button'
                          onClick={() => {
                            deleteFunction("all products")
                          }}
                        >
                          <span className='material-symbols-outlined medium'>
                            delete
                          </span>{" "}
                          Delete{" "}
                          {collectAllProducts.length > 1
                            ? "All " + collectAllProducts.length
                            : ""}
                        </button>
                      ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton col={6} row={stockData?.length} />
                ) : stockData?.length > 0 ? (
                  <>
                    <div className='data-table-box scrollbar-visible'>
                      <table className='data-table '>
                        <thead>
                          <tr>
                            <th className='tb-head-name padding-07 '>
                              <div>
                                <span>
                                  <input
                                    name='checkbox'
                                    id='checkbox'
                                    type='checkbox'
                                    className='checkbox-animated'
                                    onChange={() =>
                                      setSelectAllProducts(!selectAllProducts)
                                    }
                                    checked={selectAllProducts}
                                  />
                                  <label htmlFor={"checkbox"}>
                                    <span className='filter-check-background'></span>
                                  </label>
                                </span>
                              </div>
                            </th>
                            <th>SKU</th>
                            <th>Product Name</th>
                            <th>Refill Quantity</th>
                          </tr>
                        </thead>

                        <tbody>
                          {stockData?.map((item, index) => {
                            const {
                              product_name,
                              sku,
                              refill_quantity,
                              id,
                              num,
                            } = item

                            return (
                              <tr
                                key={index}
                                // onClick={() => {
                                //   navigate(`/products/66a22c7d372e0b8bc1a45130`)
                                // }}
                                className='navig-table-row'
                                style={{ cursor: "pointer" }}
                              >
                                <td className='tb_data'>
                                  <span onChange={e => e.stopPropagation()}>
                                    <input
                                      name={"checkbox" + index}
                                      id={"checkbox" + index}
                                      type='checkbox'
                                      className='checkbox-animated'
                                      onChange={e => {
                                        e.stopPropagation()
                                        setCollectAllProducts(oldList => {
                                          let newList = JSON.parse(
                                            JSON.stringify(oldList),
                                          )

                                          if (newList.includes(id)) {
                                            newList.splice(
                                              newList.indexOf(id),
                                              1,
                                            )
                                          } else {
                                            newList.unshift(id)
                                          }

                                          return newList
                                        })
                                      }}
                                      checked={collectAllProducts.includes(id)}
                                    />
                                    <label htmlFor={"checkbox" + index}>
                                      <span className='filter-check-background'></span>
                                    </label>
                                  </span>
                                </td>
                                <td className='tb_data padding-07'>
                                  {/* {num} */}
                                  {sku}
                                </td>
                                <td className='product_nav'>{product_name} </td>
                                {filterTable.stock_quantity ? (
                                  <td>
                                    {refill_quantity}

                                    {/* {Object.values(
                                      privileges["inventory_management"],
                                    ).includes("write") && (
                                      <button
                                        className='icon-btn edit'
                                        onClick={() => {}}
                                        style={{ marginLeft: "1rem" }}
                                      >
                                        <span className='material-symbols-outlined medium'>
                                          border_color
                                        </span>
                                      </button>
                                    )} */}
                                  </td>
                                ) : null}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                    {/* <Pagination
                      sliceName={"inventory"}
                      search={search}
                      filter={"desc"}
                      api={getInventoryList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                    /> */}
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StockAlert
