import { useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import * as Yup from "yup"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
import { createOrderFormData, numberWithCommas } from "../../../helper/helper"
import SelectCompanyPopup from "./SelectCompanyPopup"
import { createOrder, returnPurchaseOrder } from "../redux/PurchaseApi"
import { ClipLoader } from "react-spinners"
import EditFormButtons from "../../../components/EditFormBtns"
const tempImage = require("../../../assets/icons/logo.svg").default

const PreviewPurchase = ({ setTitle }) => {
  const [active, setActive] = useState("purchaseDetails")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  const [isEventCard, setIsEventCard] = useState(false)
  const [popUpData, setPopupData] = useState({})
  const [isSelectCompany, setIsSelectCompany] = useState(false)
  const [buttonsVisibilityState, setButtonsVisibilityState] = useState({
    download: { show: false, url: "" },
    delete: false,
  })
  // const { emailDetails } = useSelector((store) => store.email);
  const { privileges } = useSelector(state => state.auth, shallowEqual)
  const { productDetails } = useSelector(state => state.products, shallowEqual)

  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const location = useLocation()

  const { key, data } = location?.state

  const {
    supplier: supplierAddress,
    buyer: workAddress,
    delivery: warehouseAddress,
  } = data?.addressDetails
  useEffect(() => {
    setTitle(
      key === "purchaseOrder"
        ? "Purchase Order Details"
        : "Purchase Return Details",
    )
  }, [setTitle])

  const [focusedField, setFocusedField] = useState()
  const { purchaseLoading } = useSelector(store => store.purchase)
  console.log("purchaseLoading: ", purchaseLoading)

  //   const apiCall = async () => {
  //     {
  //       const response = await dispatch(
  //         getOptionsList({
  //           listName: "uom-list",
  //           search: "",
  //           sort: "desc",
  //           limit: 0,
  //           page: 0,
  //         }),
  //       )

  //       const options = response?.payload?.map(item => {
  //         return { label: item.uom_type, value: item._id }
  //       })
  //       setUomList(options)
  //     }
  //   }

  //   useEffect(() => {
  //     dispatch(getProductsDetails({ id }))
  //     if (uomList.length === 0) apiCall()
  //   }, [])

  // useEffect(() => {
  //   formik.setValues({
  //     skuNum: productDetails?.basic_details?.sku,
  //     productName: productDetails?.basic_details?.name,
  //     brand: productDetails?.basic_details?.brandId,
  //     color: productDetails?.basic_details?.colorId,
  //     category: productDetails?.basic_details?.categoryId,
  //     packSize: productDetails?.basic_details?.pack_size?.weight.$numberDecimal,
  //     uom: productDetails?.basic_details?.pack_size?.uomID,
  //     productCode: productDetails?.basic_details?.productCode,
  //     batchNumber: productDetails?.basic_details?.batchNumber,
  //   })
  // }, [productDetails])

  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  const [activeInputs, setActiveInputs] = useState(false)
  let totalPacks = 0
  let grandTotal = 0
  let extraChargesTotal =
    Number(data?.grandTotals?.subtotal) -
    Number(data?.grandTotals?.reservedSubtotal)
  let numberOfItems = data?.productDetails?.rows?.length

  const handleSaveAndSend = async () => {
    const formData = await createOrderFormData(data, "pending", key)
    let response
    if (key === "purchaseOrder") {
      response = await dispatch(createOrder(formData))
    } else {
      response = await dispatch(returnPurchaseOrder(formData))
    }

    if (response?.payload?.status === 200) {
      setButtonsVisibilityState(oldValue => {
        let newList = JSON.parse(JSON.stringify(oldValue))
        newList.download = {
          show: true,
          url: response?.payload?.data?.data?.pdfURL,
        }
        return newList
      })
    }
  }

  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        {isSelectCompany && (
          <SelectCompanyPopup setIsSelectCompany={setIsSelectCompany} />
        )}
        <div className='home-container'>
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}

          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>
                    {" "}
                    {key === "purchaseOrder"
                      ? "Purchase Order Details"
                      : "Purchase Return Details"}
                  </div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>

                <div className='table-search'>
                  <div
                    className='search-container'
                    style={{ justifyContent: "flex-end" }}
                  >
                    {Object.values(privileges["purchase_management"]).includes(
                      "write",
                    ) && (
                      <>
                        {buttonsVisibilityState?.delete && (
                          <button className='btn-alpha   '>
                            <span className='material-symbols-outlined medium'>
                              delete
                            </span>
                          </button>
                        )}{" "}
                        {buttonsVisibilityState?.download?.show && (
                          <a
                            href={buttonsVisibilityState?.download?.url}
                            download='Purchase Order PDF'
                            target='_blank'
                          >
                            <button className='btn-alpha  '>
                              <span className='material-symbols-outlined medium'>
                                download
                              </span>
                            </button>
                          </a>
                        )}{" "}
                        {!buttonsVisibilityState?.download?.show && (
                          <>
                            {!purchaseLoading && (
                              <button className='btn-alpha header-btn bg-none'>
                                Edit
                              </button>
                            )}
                            {purchaseLoading ? (
                              <button
                                className='btn-alpha  header-btn '
                                disabled
                              >
                                <ClipLoader
                                  loading={true}
                                  size={15}
                                  aria-label='Loading Spinner'
                                  data-testid='loader'
                                  color='white'
                                />
                              </button>
                            ) : (
                              <button
                                className='btn-alpha  header-btn '
                                onClick={() => {
                                  handleSaveAndSend()
                                }}
                              >
                                Save and Send
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}{" "}
                  </div>
                </div>
              </div>
              <div
                className='main-box-padding'
                style={{ borderRadius: ".4rem" }}
              >
                <div className='gray-border'>
                  <LabeAndValues
                    labels={{ first: "GST Number", second: "PAN Number" }}
                    values={{ first: "24ERTGHN98948511", second: "AAW85841" }}
                  />
                </div>

                <div className='gray-border flex flex-direction-column gap-8'>
                  <div className='modelEx_content singleAdd w-full'>
                    <div className='profile_personal_details'>
                      <div className='details-controls'>
                        <h2>
                          {key === "purchaseOrder"
                            ? "To,"
                            : "Goods Received By"}
                        </h2>
                      </div>
                    </div>
                    <div className='location-collection flex'>
                      <div className='card' onClick={() => {}}>
                        <div className='col1'>
                          <h3 className='card-name'>Jotun</h3>

                          <div className='card-address'>
                            <p>{`${supplierAddress?.address?.line_one}, `}</p>
                            <p>{`${supplierAddress?.address?.line_two}${supplierAddress?.address?.line_two ? "," : ""}`}</p>
                            <p>{`${supplierAddress?.address?.city} - ${supplierAddress?.address?.pin_code},`}</p>
                            <p>{`${supplierAddress?.address?.state} - ${supplierAddress?.address?.country}`}</p>
                            <p
                              style={{ marginTop: "0.7rem" }}
                            >{`GSTIN - ${supplierAddress?.gst}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ width: "100%", margin: 0 }} />
                  <div className='flex address-flex  ps-add'>
                    <div className='modelEx_content singleAdd w-full'>
                      <div className='profile_personal_details'>
                        <div className='details-controls'>
                          <h2>
                            {key === "purchaseOrder"
                              ? "Buyer Details"
                              : "Goods Sent By"}
                          </h2>
                        </div>
                      </div>
                      <div className='location-collection flex'>
                        <div className='card' onClick={() => {}}>
                          <div className='col1'>
                            <h3 className='card-name'>Poonam Coatings</h3>

                            <div className='card-address'>
                              <p>{`${workAddress?.address?.line_one}, `}</p>
                              <p>{`${workAddress?.address?.line_two}${workAddress?.address?.line_two ? "," : ""}`}</p>
                              <p>{`${workAddress?.city_detail?.name} - ${workAddress?.address?.pin_code},`}</p>
                              <p>{`${workAddress?.state_detail?.name} - ${workAddress?.country_detail?.name}`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    <div className='dash-border'></div>
                    <div className='modelEx_content singleAdd w-full'>
                      <div className='profile_personal_details'>
                        <div className='details-controls'>
                          <h2>Delivery Location</h2>
                        </div>
                      </div>
                      <div className='location-collection flex'>
                        <div className='card' onClick={() => {}}>
                          <div className='col1'>
                            <h3 className='card-name'>Poonam Coatings</h3>

                            <div className='card-address'>
                              <p>{`${warehouseAddress?.address?.line_one}, `}</p>
                              <p>{`${warehouseAddress?.address?.line_two}${warehouseAddress?.address?.line_two ? "," : ""}`}</p>
                              <p>{`${warehouseAddress?.city_detail?.name ? warehouseAddress?.city_detail?.name : warehouseAddress?.cityName} - ${warehouseAddress?.address?.pin_code},`}</p>
                              <p>{`${warehouseAddress?.state_detail?.name ? warehouseAddress?.state_detail?.name : warehouseAddress?.stateName} - ${warehouseAddress?.country_detail?.name ? warehouseAddress?.country_detail?.name : warehouseAddress?.countryName}`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='gray-border'>
                  <h2 className='heading'>
                    {key === "purchaseOrder"
                      ? "Purchase Order Details"
                      : "Purchase Return Details"}
                  </h2>
                  <div className='flex flex-direction-column gap-2'>
                    <LabeAndValues
                      labels={{
                        first:
                          key === "purchaseOrder"
                            ? "PO Number"
                            : "Purchase Return Number",
                        second:
                          key === "purchaseOrder"
                            ? "Payment Term"
                            : "Document Date",
                      }}
                      values={{
                        first:
                          key === "purchaseOrder"
                            ? data?.primaryDetails?.title
                            : data?.primaryDetails?.PR_number,
                        second:
                          key === "purchaseOrder"
                            ? data?.primaryDetails?.customerId
                            : data?.primaryDetails?.documentDate,
                      }}
                    />{" "}
                    <LabeAndValues
                      labels={{
                        first:
                          key === "purchaseOrder"
                            ? "Customer ID"
                            : "PO  Number",
                        second:
                          key === "purchaseOrder"
                            ? "Number of Items"
                            : "PO  Date",
                      }}
                      values={{
                        first:
                          key === "purchaseOrder"
                            ? data?.primaryDetails?.customerId
                            : data?.primaryDetails?.PO_number,
                        second:
                          key === "purchaseOrder"
                            ? numberOfItems
                            : data?.primaryDetails?.PO_date,
                      }}
                    />{" "}
                    <LabeAndValues
                      labels={{
                        first:
                          key === "purchaseOrder"
                            ? "Delivery Date"
                            : "Return Delivery Date",
                        second:
                          key === "purchaseOrder" ? "Selling Officer" : "",
                      }}
                      values={{
                        first: data?.primaryDetails?.documentDate,
                        second:
                          key === "purchaseOrder"
                            ? data?.primaryDetails?.sales_officer
                            : "",
                      }}
                    />{" "}
                  </div>
                </div>

                <>
                  <div
                    className='data-table-container'
                    style={{ padding: "0" }}
                  >
                    <div
                      className='data-table-box scrollbar-visible'
                      style={{
                        margin: 0,
                        border: "1px solid #BFBFBF",
                      }}
                    >
                      <table className='data-table fix-width-table-row preview-data-table'>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>HSN Code</th>
                            <th>Pack Quantity</th>
                            <th>No. of Packs</th>
                            {key === "purchaseReturn" ? (
                              <th>Return Qty.</th>
                            ) : null}
                            <th>
                              {key === "purchaseReturn"
                                ? "Total Return Quantity"
                                : " Quantity"}
                            </th>
                            <th>Total Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {/* {mailList[tableIndex]?.map((item, index) => { */}
                          {data?.productDetails?.rows?.map((item, index) => {
                            const {
                              product_name,
                              hsn_code,
                              packQuantity,
                              pack_num,
                              total_quantity,
                              totalAmount,
                              totalQuantity,
                              noOfPacks,
                              tax: taxValue,
                            } = item

                            totalPacks += Number(noOfPacks)
                            grandTotal += Number(totalAmount)

                            return (
                              <tr key={index}>
                                <td className='preview-td'>{index + 1}</td>
                                <td className='preview-td'>{product_name}</td>
                                <td className='preview-td'>{hsn_code}</td>
                                <td className='preview-td'>
                                  {numberWithCommas(packQuantity)}
                                </td>
                                <td className='preview-td'>
                                  {numberWithCommas(noOfPacks)}
                                </td>
                                <td className='preview-td'>
                                  {numberWithCommas(totalQuantity)}{" "}
                                  {item?.selectedProduct?.uom}
                                </td>
                                {key === "purchaseReturn" ? (
                                  <td className='preview-td'>
                                    {numberWithCommas(totalQuantity)}
                                  </td>
                                ) : null}
                                <td className='preview-td'>
                                  ₹ {numberWithCommas(totalAmount)}
                                </td>
                              </tr>
                            )
                          })}
                          {key === "purchaseOrder" ? (
                            <tr>
                              <td className='preview-td' colSpan={4}>
                                Gross Total:
                              </td>
                              <td className='preview-td'>
                                {numberWithCommas(totalPacks)}
                              </td>
                              <td
                                className='preview-td'
                                style={{ borderRight: "1px solid #bfbfbf" }}
                              >
                                -
                              </td>{" "}
                              <td className='preview-td'>
                                ₹ {numberWithCommas(grandTotal)}
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>

                {key === "purchaseOrder" ? (
                  <>
                    <div className='tp-main'>
                      <div className='tp-inner'>
                        <span>Subtotal :</span>
                        <hr />
                        <span>
                          ₹{" "}
                          {numberWithCommas(
                            Number(data?.grandTotals?.subtotal) -
                              extraChargesTotal,
                          )}
                        </span>
                      </div>
                      <div className='tp-inner'>
                        <span>Extra Charges :</span>
                        <hr />
                        <span>₹ {numberWithCommas(extraChargesTotal)}</span>
                      </div>
                      <div className='tp-inner'>
                        <span>Taxes :</span>
                        <hr />
                        <span>
                          ₹ {numberWithCommas(data?.grandTotals?.taxes)}
                        </span>
                      </div>
                      <div className='tp-inner'>
                        <span>Grand Total  :</span>
                        <hr />
                        <span>
                          ₹ {numberWithCommas(data?.grandTotals?.grandTotal)}
                        </span>
                      </div>
                      {/* <div className='tp-inner'>
                        <span>Advance to pay :</span>
                        <input
                          type='text'
                          className='form-input'
                          placeholder='eg. 10000'
                        ></input>
                      </div> */}

                      <div
                        className='flex'
                        style={{ justifyContent: "flex-end" }}
                      >
                        <span>Sixteen Thousand Seven Hundred Fifty </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='tp-main'>
                      <div className='tp-inner'>
                        <span>Total Return Amount: </span>
                        <hr />
                        <span>₹16,750</span>
                      </div>
                      <div
                        className='flex'
                        style={{ justifyContent: "flex-end" }}
                      >
                        <span>Sixteen Thousand Seven Hundred Fifty </span>
                      </div>
                    </div>
                  </>
                )}
                {data?.additionalCharges?.comment && (
                  <div className='form-group'>
                    <div className={`input-field ${"active"}`}>
                      <label htmlFor='about_us' className='toggle-label'>
                        Comment
                      </label>
                      <textarea
                        id='about_us'
                        name='about_us'
                        rows={4}
                        className={
                          !activeInputs
                            ? "form-input disabeldTextArea"
                            : "form-input"
                        }
                        disabled={true}
                        value={data?.additionalCharges?.comment}
                      />
                    </div>
                  </div>
                )}
                <div className='logo-stamp flex'>
                  <div className='stamp-box'>
                    <img
                      className='stamp-img'
                      src={
                        data?.additionalCharges?.company_signature
                          ? typeof data?.additionalCharges
                              ?.company_signature === "string"
                            ? data?.additionalCharges?.company_signature
                            : URL.createObjectURL(
                                data?.additionalCharges?.company_signature,
                              )
                          : tempImage
                      }
                      alt=''
                    />
                  </div>
                </div>
              </div>

              <div className='table-search'>
                <div
                  className='search-container'
                  style={{ justifyContent: "flex-end" }}
                >
                  {Object.values(privileges["purchase_management"]).includes(
                    "write",
                  ) &&
                    key === "purchaseReturn" && (
                      <>
                        <button className='btn-alpha header-btn  '>
                          {"Create Credit Note"}
                        </button>
                      </>
                    )}{" "}
                </div>
              </div>
            </div>
            <Footer />{" "}
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              //   api={deleteProducts}
              popUpData={popUpData}
              // reFetchApi={getProductsList}
              navigateTo='/products'
              // reData={{ search: "", sort:"desc", limit: 5, page: 0 }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PreviewPurchase

const LabeAndValues = ({ labels, values }) => {
  return (
    <div className='flex labeAndValue lb'>
      <div className='w-full'>
        <p className='remove-margin'>
          <b>{labels.first} : </b>
          {values.first}
        </p>
      </div>
      {labels?.second && (
        <>
          <div className='dash-border'></div>

          <div className='w-full'>
            <p className='remove-margin'>
              <b>{labels.second} : </b>
              {values.second}
            </p>
          </div>
        </>
      )}
    </div>
  )
}
