import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"
import { AddNumField } from "../../../helper/helper"
import { isStockProductLoading, isStockToggle } from "./stockManagementSlice"

export const getStockList = createAsyncThunk(
  "stock/get-stock-list",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/inventory/stockMovement/stock-detail?search=${data?.search}`,
        method: "POST",
        data: data.send,
      })

      if (response?.status === 200) {
        if (response?.data?.data.length > 0) {
          const res = await AddNumField(
            { data: response.data.data, metadata: response.data.metadata },
            data?.page * data.limit,
          )

          return fulfillWithValue(res)
        } else {
          const response = await axios({
            url: `${process.env.REACT_APP_AUTH_URL}/inventory/stockMovement/stock-detail?search=${data?.search}`,
            method: "POST",
          })

          if (response?.status === 200) {
            const res = await AddNumField(
              response?.data?.data[0],
              data?.page * data.limit,
            )

            return fulfillWithValue(res)
          } else {
            toast.error(response.data.message)
            return rejectWithValue()
          }
        }
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)
export const getStockHistoryList = createAsyncThunk(
  "stock/get-stock-history-list",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/inventory/stockMovement/stock-history?limit=${data.limit}&page=${data.page}&sort=${data.sort}&search=${data.search}`,
        method: "POST",
      })

      if (response?.status === 200) {
        if (response?.data?.data.length > 0) {
          const res = await AddNumField(
            { data: response.data.data, metadata: response.data.metadata },
            data?.page * data.limit,
          )

          return fulfillWithValue(res)
        } else {
          const response = await axios({
            url: `${process.env.REACT_APP_AUTH_URL}/inventory/stockMovement/stock-history?limit=${data.limit}&page=${data.page}&sort=${data.sort}&search=${data.search}`,
            method: "POST",
          })

          if (response?.status === 200) {
            const res = await AddNumField(
              response?.data?.data[0],
              data?.page * data.limit,
            )

            return fulfillWithValue(res)
          } else {
            toast.error(response.data.message)
            return rejectWithValue()
          }
        }
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)
export const stockMovement = createAsyncThunk(
  "stock/stock-movement",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isStockProductLoading(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/inventory/stockMovement/stock-transfer`,
        data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isStockProductLoading(false))
        toast.success(response?.data?.message)
        return response?.data?.status
      } else {
        dispatch(isStockProductLoading(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isStockProductLoading(false))
      toast.error(err)
    }
  },
)
export const getStockHistoryDetail = createAsyncThunk(
  "stock/get-stock-details",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/inventory/stockMovement/stock-transffer-detail/${data.id}`,
      })

      if (response.status === 200) {
        return fulfillWithValue(response)
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      return rejectWithValue()
    }
  },
)

export const downloadStockSheet = createAsyncThunk(
  "stock/download-stock-sheet",
  async (_data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_STOCK_SHEET}`,
      })
      if (response.status === 200) {
        toast.success(response.data.message)
        return fulfillWithValue()
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      toast.error(error)
    }
  },
)
