/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"

const PrivilegesPopup = ({
  customPrivi,
  setCustomPrivi,
  setPrivileges,
  privilege,
  // modUsed,
}) => {
  const Modules = [
    "user_management",
    "email_management",
    "buyers_and_suppliers_management",
    "calendar_management",
    "department_management",
    "sales_management",
    "purchase_management",
    "inventory_management",
    "payment_management",
    // "reports_intelligence_management",
    // "resource_planning_management",
    // "account_tally_management",
    "production_management",
    // "configuration_management",
    "catalogue_management",
    "order_management",
  ]
  const [addStyle, setAddStyle] = useState(true)
  const [modulePermissions, setModulePermissions] = useState(privilege)

  useEffect(() => {
    setPrivileges(modulePermissions)
  }, [modulePermissions])

  const updatePermissions = (moduleName, permissionType, value) => {
    switch (permissionType + value) {
      case "readfalse":
        return setModulePermissions(prevPermissions => {
          const updatedPermissions = { ...prevPermissions }
          delete updatedPermissions[moduleName]
          return updatedPermissions
        })
      // break;
      case "writefalse":
        return setModulePermissions(prevPermissions => {
          const updatedPermissions = { ...prevPermissions }
          if (updatedPermissions[moduleName]) {
            updatedPermissions[moduleName] = updatedPermissions[
              moduleName
            ].filter(perm => perm !== "write" && perm !== "delete")
            if (updatedPermissions[moduleName].length === 0) {
              delete updatedPermissions[moduleName]
            }
          }
          return updatedPermissions
        })
      // break;
      case "writetrue":
        return setModulePermissions(prevPermissions => {
          if (prevPermissions[moduleName]?.includes("read")) {
            return {
              ...prevPermissions,
              [moduleName]: [
                ...(prevPermissions[moduleName] || []),
                permissionType,
              ],
            }
          } else {
            return prevPermissions
          }
        })
      // break;
      case "deletetrue":
        return setModulePermissions(prevPermissions => {
          if (prevPermissions[moduleName]?.includes("read" && "write")) {
            return {
              ...prevPermissions,
              [moduleName]: [
                ...(prevPermissions[moduleName] || []),
                permissionType,
              ],
            }
          } else {
            return prevPermissions
          }
        })
      // break;
      default:
        return setModulePermissions(prevPermissions => {
          if (value) {
            // Add the permission to the module if the checkbox is checked
            return {
              ...prevPermissions,
              [moduleName]: [
                ...(prevPermissions[moduleName] || []),
                permissionType,
              ],
            }
          } else {
            // Remove the permission from the module if the checkbox is unchecked
            const updatedPermissions = { ...prevPermissions }
            if (updatedPermissions[moduleName]) {
              updatedPermissions[moduleName] = updatedPermissions[
                moduleName
              ].filter(permission => permission !== permissionType)
              if (updatedPermissions[moduleName].length === 0) {
                delete updatedPermissions[moduleName]
              }
            }
            return updatedPermissions
          }
        })
    }
  }

  const handleClick = () => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
  }

  function chatBoxVisibility() {
    if (customPrivi) {
      const styleTimer = setTimeout(() => {
        setCustomPrivi(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  })

  return (
    <div
      className={
        "mainPrivileges" +
        (addStyle
          ? " show_component-common-animation"
          : " hide_component-common-animation")
      }
    >
      <div className='L1'>
        <div className='L2'>
          <span className='closespan' onClick={() => handleClick()}>
            <span className='material-symbols-outlined'>close</span>
          </span>
          <div className='L3 scrollbar-visible'>
            <table className='prTable' cellSpacing={0}>
              <thead className='pr_Head'>
                <tr className='pr_TH_TR'>
                  <th className='pr_TH' style={{ padding: "1rem" }}>
                    Privileges
                  </th>
                  <th className='pr_TH' style={{ padding: "1.2rem 2.5rem" }}>
                    Read
                  </th>
                  <th className='pr_TH' style={{ padding: "1.2rem 2.5rem" }}>
                    Write
                  </th>
                  <th className='pr_TH' style={{ padding: "1.2rem 2.5rem" }}>
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody className='pr_TBody'>
                {Modules.map(obj => {
                  return (
                    <tr className='pr_Body_TR' key={obj}>
                      <td className='pr_Body_TD' style={{ padding: "1rem" }}>
                        {obj.replaceAll("_", " ")}
                      </td>
                      <td
                        className='pr_Body_TD'
                        style={{ padding: "1rem", lineHeight: "0" }}
                      >
                        {/* {modulePermissions[obj]?.includes("read")
                          ? "YES"
                          : "NO"} */}
                        <input
                          name='read'
                          type='checkbox'
                          checked={
                            modulePermissions[obj]?.includes("read")
                              ? true
                              : false
                          }
                          onChange={e =>
                            updatePermissions(obj, "read", e.target.checked)
                          }
                        />
                      </td>
                      <td
                        className='pr_Body_TD'
                        style={{ padding: "1rem", lineHeight: "0" }}
                      >
                        {/* {modulePermissions[obj]?.includes("write")
                          ? "YES"
                          : "NO"} */}
                        <input
                          name='write'
                          type='checkbox'
                          checked={
                            modulePermissions[obj]?.includes("write")
                              ? true
                              : false
                          }
                          onChange={e =>
                            updatePermissions(obj, "write", e.target.checked)
                          }
                        />
                      </td>
                      <td
                        className='pr_Body_TD'
                        style={{ padding: "1rem", lineHeight: "0" }}
                      >
                        {/* {modulePermissions[obj]?.includes("delete")
                          ? "YES"
                          : "NO"} */}
                        <input
                          name='delete'
                          type='checkbox'
                          checked={
                            modulePermissions[obj]?.includes("delete")
                              ? true
                              : false
                          }
                          onChange={e =>
                            updatePermissions(obj, "delete", e.target.checked)
                          }
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='privileges-submit'>
            <button onClick={() => handleClick()}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivilegesPopup
