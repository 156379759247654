import React, { useEffect, useRef, useState } from "react"

const SearchBox = ({ customSetter, customState }) => {
  const [searchShow, setSearchShow] = useState(false)

  useEffect(() => {
    if (customState && searchShow === false) {
      setSearchShow(true)
    }
  }, [])
  return (
    <div className={"search-box " + (searchShow ? "searchShow" : "")}>
      {searchShow && (
        <SearchBoxSearchInput
          customSetter={customSetter}
          customState={customState}
          setSearchShow={setSearchShow}
        />
      )}
      <span
        className='material-symbols-outlined '
        onClick={() => {
          if (!customState) {
            setSearchShow(!searchShow)
          }
        }}
      >
        search
      </span>{" "}
    </div>
  )
}

const SearchBoxSearchInput = ({ customSetter, customState, setSearchShow }) => {
  const searchRef = useRef()
  const [globalClick, setGlobalClick] = useState(false)
  useEffect(() => {
    const handleGlobalClick = event => {
      if (
        searchRef?.current &&
        !searchRef?.current?.contains(event.target) &&
        !searchRef.current.value
      ) {
        setSearchShow(false)
      }
    }
    document.addEventListener("click", handleGlobalClick)
    return () => {
      document.removeEventListener("click", handleGlobalClick)
    }
  }, [searchRef])

  useEffect(() => {
    setGlobalClick(true)
    document.getElementsByClassName("table-search-input")[0].focus()
  }, [])

  return (
    <input
      ref={globalClick ? searchRef : null}
      type='search'
      className='table-search-input '
      placeholder='Search here...'
      value={customState}
      onChange={e => customSetter(e.target.value)}
    />
  )
}

export default SearchBox
