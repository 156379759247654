import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { ClipLoader } from "react-spinners"

const SearchUser = ({
  api,
  handleChange,
  label,
  name,
  // search = "",
  customSetter,
  listName = "",
  dynamicLabel, //? you can provide dynamic destructing key for showing the options on the drop down
  dynamicValue, //? you can provide dynamic destructing key for gating specific value from object or you can provide "all" keyword to get whole object as value
  incomingValue,
  disabled,
  apiData = {},
  customFunctionOnClick,
  customValueForFunctionOnClick,
  maxLength = false,
  type = "",
  removeSpace = false,
  resetState = false,
  resetSetter,
  minimumSearchLetters = 0,
  showSize,
}) => {
  const [searchTimeout, setSearchTimeout] = useState(null)
  const dispatch = useDispatch()
  const [search, setSearch] = useState("")
  const [showResultBox, setShowResultBox] = useState(false)
  const [focusedField, setFocusedField] = useState()
  const [searchList, setSearchList] = useState([])
  const [loading, setLoading] = useState(false)
  const [labelHistory, setLabelHistory] = useState(incomingValue)
  const apiCall = async () => {
    if (search && search.length >= minimumSearchLetters && api) {
      // if (search && typeof api === "function") {
      setLoading(true)

      const response = await dispatch(
        api({
          search,
          listName,
          page: apiData?.page ? apiData?.page : "0",
          sort: apiData?.sort ? apiData?.sort : "desc",
          limit: apiData?.limit ? apiData?.limit : "0",
          send: apiData?.send ? apiData?.send : null,
        }),
      )
      if (response?.payload?.length > 0) {
        const options = response?.payload?.map(item => {
          return {
            labelTwo: item.fname + " " + item.lname + ` (${item.username})`,
            label: item.fname + " " + item.lname,
            value: dynamicValue
              ? dynamicValue.toLowerCase() === "all"
                ? item
                : item[dynamicValue]
              : item?._id,
          }
        })

        setSearchList(options)

        setShowResultBox(true)
      } else {
        setSearchList([])
        setShowResultBox(true)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    const timeoutId = setTimeout(async () => {
      if (incomingValue && !labelHistory) {
        if (search !== labelHistory) {
          apiCall()
        }
      } else {
        if (search !== labelHistory) {
          apiCall()
        }
      }
    }, 1000)

    setSearchTimeout(timeoutId)
    return () => clearTimeout(timeoutId)
  }, [search])

  useEffect(() => {
    if (incomingValue || resetState) {
      if (resetState === "clear") {
        setSearch("")
        resetSetter("")
        customSetter(name, "")
        return
      }

      if (typeof resetState !== "string") {
        setSearch(incomingValue?.trim())
      }

      // if (resetState) {
      //   resetSetter(false)
      // }
    }
  }, [incomingValue, resetState])
  const menuRef = useRef()
  useEffect(() => {
    const handleGlobalClick = event => {
      if (menuRef?.current && !menuRef?.current?.contains(event.target)) {
        setShowResultBox(false)
        setSearchList([])
        setFocusedField("")
      }
    }

    document.addEventListener("click", handleGlobalClick)
    return () => {
      document.removeEventListener("click", handleGlobalClick)
    }
  }, [menuRef])

  return (
    <div className='hsnBox' ref={focusedField ? menuRef : null}>
      <div
        className={`input-field ${
          search !== "" || focusedField === name ? "active" : ""
        }`}
      >
        <label htmlFor={name} className='toggle-label'>
          {label}
        </label>
        <input
          id={name}
          name={name}
          type='text'
          className='form-input'
          onChange={
            // handleChange
            e => {
              setSearch(e.target.value)
              if (!e.target.value.length) {
                customSetter(name, "")
              }
            }
          }
          value={search}
          onInput={e => {
            const value = e.target.value
            if (labelHistory === incomingValue) {
              setLabelHistory("")
            }
            if (value.length < 3) {
              setSearchList([])
              // customSetter(name, "")
            }
            if (typeof type === "string" && type.toLowerCase() === "number") {
              e.target.value = e.target.value.replace(/[^\d ]/gi, "")
            } else {
              e.target.value = e.target.value.replace(/(?:^|\s)\S/g, match =>
                match.toUpperCase(),
              )
            }

            setShowResultBox(true)
          }}
          onKeyDown={e => {
            if (removeSpace && e.key === " ") {
              e.preventDefault()
            }
          }}
          maxLength={maxLength ? maxLength : 81}
          // onBlur={e => {
          //   // formik.handleBlur(e)
          // }}
          onFocus={() => setFocusedField(name)}
          disabled={disabled}
          autoComplete='off'
        />
      </div>
      {/* {showResultBox && focusedField && ( */}
      {search?.length >= minimumSearchLetters &&
        showResultBox &&
        focusedField && (
          <DropDownBox
            search={search}
            searchList={searchList}
            customSetter={customSetter}
            setSearch={setSearch}
            setSearchList={setSearchList}
            loading={loading}
            name={name}
            setShowResultBox={setShowResultBox}
            showResultBox={showResultBox}
            setLabelHistory={setLabelHistory}
            setFocusedField={setFocusedField}
            customFunctionOnClick={customFunctionOnClick}
            customValueForFunctionOnClick={customValueForFunctionOnClick}
          />
        )}
    </div>
  )
}

const DropDownBox = ({
  search,
  searchList,
  customSetter,
  setSearch,
  setSearchList,
  loading,
  showResultBox,
  name,
  setShowResultBox,
  setFocusedField,
  setLabelHistory,
  customFunctionOnClick,
  customValueForFunctionOnClick,
}) => {
  return (
    <div className='hsnSelectBox'>
      {searchList?.length > 0 && !loading ? (
        searchList?.map(({ value, label, labelTwo }, index) => (
          <div
            // tabIndex={index + 1}
            key={index}
            className={
              "drop-down-item2" + (search === label ? " selectedDropBox" : "")
            }
            onClick={() => {
              if (customFunctionOnClick) {
                customFunctionOnClick(value, customValueForFunctionOnClick)
              } else {
                customSetter(name, value)
              }
              setSearch(label)
              setLabelHistory(label)
              setShowResultBox(false)
              setSearchList([])
              setFocusedField("")
            }}
          >
            <span>{labelTwo}</span>
          </div>
        ))
      ) : (
        <div className='drop-down-item2'>
          {loading ? (
            <ClipLoader
              loading={true}
              size={13}
              aria-label='Loading Spinner'
              data-testid='loader'
            />
          ) : (
            <span>No Data Found</span>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchUser
