import { createAsyncThunk } from "@reduxjs/toolkit"
import { isEmailToggle } from "./Emailslice"
import axios from "axios"
import { toast } from "react-toastify"
import { AddNumField } from "../../../helper/helper"

export const getEmailList = createAsyncThunk(
  "email/get-email-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/email/email-list/?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data.search}`,

        // url: `${process.env.REACT_APP_AUTH_URL}/admin/email/email-list/?page=${data.page}&limit=${data.limit}`,
      })

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data,
            data?.page * data?.limit,
          )
          return fulfillWithValue(res)
        } else {
          const modPage = Math.max(data.page - 1, 0)
          const responseAgain = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/email/email-list/?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data.search}`,
          })
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit,
            )
            return fulfillWithValue(resAgain)
          } else {
            toast.error(responseAgain?.data?.messages)
            return rejectWithValue()
          }
        }
      } else {
        // toast.error(response?.data?.message);
        return rejectWithValue()
      }
    } catch (err) {
      return rejectWithValue()
    }
  },
)

export const addEmail = createAsyncThunk(
  "email/add-email",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isEmailToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/email/add-email`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isEmailToggle(false))
        toast.success(response?.data?.message)
        return response?.data?.status
      } else {
        dispatch(isEmailToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isEmailToggle(false))
      toast.error(err)
    }
  },
)

export const deleteEmail = createAsyncThunk(
  "email/delete-email-templete",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isEmailToggle(true))
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/email/delete-email`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isEmailToggle(false))

        dispatch(
          getEmailList({
            page: data?.page,
            limit: data?.limit,
            sort: data?.sort,
            search: data?.search,
          }),
        )
        // toast.success(response?.data?.message);
        return response?.data?.status
      } else {
        dispatch(isEmailToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isEmailToggle(false))
      toast.error(err)
    }
  },
)
export const getEmailDetail = createAsyncThunk(
  "email/get-single-email-templete",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/email/email-detail/${data?.slug}`,
      })
      if (response.status === 200) {
        return fulfillWithValue(response?.data)
      } else {
        toast.error(response?.data?.message)
        return rejectWithValue()
      }
    } catch (err) {
      toast.error(err)
      return rejectWithValue()
    }
  },
)
export const updateSingleEmail = createAsyncThunk(
  "email/update-email",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isEmailToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/email/update-detail/${data?.slug}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isEmailToggle(false))
        toast.success(response?.data?.message)
        return response?.data?.status
      } else {
        dispatch(isEmailToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isEmailToggle(false))
      toast.error(err)
    }
  },
)
