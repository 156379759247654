import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"
import {
  AddNumField,
  // AddNumFieldCompany,
  options,
} from "../../../helper/helper"
import { getSelfCompanyAddress } from "../../company/redux/companyApi"
import { isCompanyLoading } from "./bNsSlice"

export const AddSingleCompany = createAsyncThunk(
  "company/addCompany",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCompanyLoading(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/add-company`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      })
      if (response.status === 200) {
        dispatch(isCompanyLoading(false))
        dispatch(
          getCompanyList({
            page: 0,
            limit: 5,
            search: "",
            sort: "desc",
            send: {
              categories: "",
              tags: "",
              city: "",
            },
          }),
        )
        toast.success(response?.data?.message, options)
        return response?.data?.status
      } else {
        dispatch(isCompanyLoading(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isCompanyLoading(false))
      toast.error(err, options)
    }
  },
)

export const addBulkCompaniesAsync = createAsyncThunk(
  "company/add-bulk",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isCompanyLoading(true))
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/company-upload-bulk`,
        headers: {
          "Content-Type": "multipart/form-data",
        },

        data: data,
      })

      if (response.status === 200) {
        dispatch(isCompanyLoading(false))
        dispatch(
          getCompanyList({
            page: 0,
            limit: 5,
            search: "",
            sort: "desc",
            send: {
              categories: "",
              tags: "",
              city: "",
            },
          }),
        )
        toast.success(response?.data?.message, options)
        return response?.data?.status
      } else {
        dispatch(isCompanyLoading(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isCompanyLoading(false))
      toast.error(err, options)
    }
  },
)

export const getCompanyList = createAsyncThunk(
  "company/get-comapny-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/companyList/?page=${data.page}&limit=${data.limit}&sort=${data.sort ? data.sort : "desc"}&search=${data.search}&filter=${data?.filter ? data.filter : ""}`,
        data: data.send,
      })

      if (response.status === 200) {
        if (response?.data?.message.length > 0) {
          const res = await AddNumField(
            response?.data?.message[0],
            data?.page * data?.limit,
          )

          return fulfillWithValue(res)
          // return fulfillWithValue(response?.data?.message[0]);
        } else {
          // const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/company/companyList/?page=${data.page}&limit=${data.limit}&sort=${data.sort}&search=${data.search}`,
          })
          if (responseAgain.status === 200) {
            const res = await AddNumField(
              response?.data?.message[0],
              data?.page * data?.limit,
            )

            return fulfillWithValue(res)
          } else {
            // toast.error(responseAgain?.data?.message, options);
            return rejectWithValue()
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue()
      }
    } catch (err) {
      return rejectWithValue()
    }
  },
)

export const deleteCompanyAsync = createAsyncThunk(
  "profile/delete-address-async",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCompanyLoading(true))
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/delete-company`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        dispatch(isCompanyLoading(false))
        // toast.success(response?.data?.message, options);
        dispatch(
          getCompanyList({
            page: 0,
            limit: 5,
            search: "",
            sort: "desc",
            send: {
              categories: "",
              tags: "",
              city: "",
            },
          }),
        )
        return response?.data?.status
      } else {
        dispatch(isCompanyLoading(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isCompanyLoading(false))
      toast.error(err, options)
    }
  },
)

export const getCompanyDetail = createAsyncThunk(
  "company/get-company-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/company-detail/${data}`,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        dispatch(getCompanyAddress(data))
        return fulfillWithValue(response?.data?.companyDetail[0])
      } else {
        toast.error(response?.data?.message, options)
        return rejectWithValue()
      }
    } catch (err) {
      toast.error(err, options)
      return rejectWithValue()
    }
  },
)

export const updateCompanyAsync = createAsyncThunk(
  "company/update-company-async",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCompanyLoading(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/update-detail/${data?.id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data?.send,
      })

      if (response.status === 200) {
        dispatch(getCompanyDetail(data.id))
        dispatch(isCompanyLoading(false))
        toast.success(response?.data?.message, options)
        return response?.data?.status
      } else {
        dispatch(isCompanyLoading(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isCompanyLoading(false))
      toast.error(err, options)
    }
  },
)

export const updatePrimaryAddress = createAsyncThunk(
  "company/update-primary-address",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCompanyLoading(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/address/change-primary-address`,
        headers: {
          "Content-Type": "application/json",
        },
        data,
      })

      if (response.status === 200) {
        // dispatch(getCompanyDetail(data?.data.id))
        dispatch(isCompanyLoading(false))
        // toast.success(response?.data?.message, options)
        return response?.data?.status
      } else {
        dispatch(isCompanyLoading(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isCompanyLoading(false))
      toast.error(err, options)
    }
  },
)

export const changeCompanylogo = createAsyncThunk(
  "company/change-profile-picture",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCompanyLoading(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/company/change-companyLogo/${data?.id}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data?.send,
      })

      if (response.status === 200) {
        dispatch(getCompanyDetail(data.id))
        dispatch(isCompanyLoading(false))
        toast.success(response?.data?.message, options)
        return response?.data?.status
      } else {
        dispatch(isCompanyLoading(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isCompanyLoading(false))
      toast.error(err, options)
    }
  },
)

export const getCompanyAddress = createAsyncThunk(
  "company/get-company-address",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/address/address-list/${data}`,
      })

      if (response.status === 200) {
        // toast.success(response.data.message);
        return fulfillWithValue(response.data)
      } else {
        toast.error(response.data.message)
        return rejectWithValue()
      }
    } catch (error) {
      toast.error(error)
      return rejectWithValue()
    }
  },
)

export const addCompanyAddress = createAsyncThunk(
  "company/add-company-address",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCompanyLoading(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/address/add-address`,
        data: data.send,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        toast.success(response.data.message)
        dispatch(isCompanyLoading(false))
        if (data.type === "self-address") {
          dispatch(getSelfCompanyAddress())
        } else {
          dispatch(getCompanyAddress(data.slug))
        }
        return response.status
      } else {
        dispatch(isCompanyLoading(false))
        toast.error(response.data.message)
      }
    } catch (error) {
      dispatch(isCompanyLoading(false))
      toast.error(error)
    }
  },
)

export const deleteCompanyAddress = createAsyncThunk(
  "address/delete-company-address",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCompanyLoading(true))
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/address/delete-address/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        dispatch(isCompanyLoading(false))
        // toast.success(response?.data?.message, options);
        // dispatch(getCompanyAddress(data.send.company_id))
        return response?.status
      } else {
        dispatch(isCompanyLoading(false))
        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isCompanyLoading(false))
      toast.error(err, options)
    }
  },
)

export const updateCompanyAddress = createAsyncThunk(
  "address/update-company-address",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCompanyLoading(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/address/update-detail/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isCompanyLoading(false))
        toast.success(response?.data?.message, options)
        if (data.type === "self-address") {
          dispatch(getSelfCompanyAddress())
        } else {
          dispatch(getCompanyAddress(data.send.company_id))
        }
        return response?.status
      } else {
        dispatch(isCompanyLoading(false))

        toast.error(response?.data?.message, options)
      }
    } catch (err) {
      dispatch(isCompanyLoading(false))
      toast.error(err, options)
    }
  },
)
