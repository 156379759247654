import { createAsyncThunk } from "@reduxjs/toolkit"
import { isDepartmentToggle } from "./DepartmentSlice"
import axios from "axios"
import { toast } from "react-toastify"
import { AddNumField } from "../../../helper/helper"

export const getAllDepartments = createAsyncThunk(
  "department/fetch-departments",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isDepartmentToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/department/fetch-departments`,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        return fulfillWithValue(response?.data)
      } else {
        toast.error(response?.data?.message)
        return rejectWithValue()
      }
    } catch (err) {
      toast.error(err)
      return rejectWithValue()
    }
  },
)

export const getDepartmentList = createAsyncThunk(
  "department/get-department-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/department/list-department?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data.search}`,
        // url: `${process.env.REACT_APP_AUTH_URL}/admin/email/email-list/?page=${data.page}&limit=${data.limit}`,
      })

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data[0],
            data?.page * data?.limit,
          )
          return fulfillWithValue(res)
        } else {
          const modPage = Math.max(data.page - 1, 0)
          const responseAgain = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/department/list-department/?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data.search}`,
          })
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              response?.data?.data[0],
              data?.page * data?.limit,
            )
            return fulfillWithValue(resAgain)
          } else {
            toast.error(responseAgain?.data?.messages)
            return rejectWithValue()
          }
        }
      } else {
        // toast.error(response?.data?.message);
        return rejectWithValue()
      }
    } catch (err) {
      return rejectWithValue()
    }
  },
)

export const addDepartment = createAsyncThunk(
  "department/add-department",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isDepartmentToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/department/add-department`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isDepartmentToggle(false))
        dispatch(
          getDepartmentList({ page: 0, limit: 5, search: "", sort: "desc" }),
        )
        toast.success(response?.data?.message)
        return response?.data?.status
      } else {
        dispatch(isDepartmentToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isDepartmentToggle(false))
      toast.error(err)
    }
  },
)

export const deleteDepartment = createAsyncThunk(
  "department/delete-department",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isDepartmentToggle(true))
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/department/delete-department`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isDepartmentToggle(false))

        dispatch(
          getDepartmentList({
            page: data?.page,
            limit: data?.limit,
            filter: data?.filter,
            search: data?.search,
          }),
        )
        // toast.success(response?.data?.message);
        return response?.data?.status
      } else {
        dispatch(isDepartmentToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isDepartmentToggle(false))
      toast.error(err)
    }
  },
)

export const updateSingleDepartment = createAsyncThunk(
  "department/update-department",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isDepartmentToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/department/update-department/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        dispatch(isDepartmentToggle(false))
        toast.success(response?.data?.message)
        dispatch(
          getDepartmentList({ page: 0, limit: 5, search: "", sort: "desc" }),
        )
        return response?.data?.status
      } else {
        dispatch(isDepartmentToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (err) {
      dispatch(isDepartmentToggle(false))
      toast.error(err)
    }
  },
)
