import { createSlice } from "@reduxjs/toolkit"
import { getProfileDetails } from "./profileApi"

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profileLoading: false,
    profileDetail: [],
  },
  reducers: {
    isProfileToggle: (state, action) => {
      state.profileLoading = action.payload
    },
    setProfileDetail: (state, action) => {
      state.profileDetail = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(getProfileDetails.pending, state => {
      state.profileLoading = true
    })
    builder.addCase(getProfileDetails.fulfilled, (state, { payload }) => {
      state.profileLoading = false
      state.profileDetail = payload.data
    })
    builder.addCase(getProfileDetails.rejected, state => {
      state.profileLoading = false
    })
  },
})

export const { isProfileToggle, setProfileDetail } = profileSlice.actions
export default profileSlice.reducer
