import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { ClipLoader } from "react-spinners"
import { resetPassword } from "../redux/authApi"

const ResetPassword = ({ setTitle }) => {
  const [visibility, setVisibility] = useState(false)
  const [visibility2, setVisibility2] = useState(false)
  const [focusedField, setFocusedField] = useState()
  const year = new Date().getFullYear()

  useEffect(() => {
    setTitle("Reset Password")
  }, [setTitle])

  const { authLoading } = useSelector(state => state.auth, shallowEqual)
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setTitle("Reset Password")
    // verifyToken();
  }, [setTitle])

  // const verifyToken = async () => {
  //   const verifyTokenResponse = await dispatch(
  //     verifyTokenAsync({ verify_token: id })
  //   );
  //   if (!verifyTokenResponse?.payload) {
  //     navigate("*");
  //   }
  // };

  const initialValues = {
    password: "",
    confirm_password: "",
  }

  const ResetPasswordSchema = Yup.object({
    password: Yup.string()
      .trim()
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/,
        "Password should contains at least 1 digit, 1 uppercase & 1 lowercase,1 special character,length between 8 to 20",
      )
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords does not match")
      .required("Confirm Password is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        verify_token: id,
        password: formik.values.password,
        password_confirmation: formik.values.confirm_password,
      }
      const response = await dispatch(resetPassword(data))
      if (response.payload) {
        formik.resetForm()
        navigate("/login")
      }
    },
  })

  return (
    <div className='container'>
      <div className='app-container'>
        <div className='auth-container'>
          <div className='auth-logo'>
            <Link to='/'>
              <img
                src={require("../../../assets/icons/logo.svg").default}
                className='poonam-coatings-logo'
                alt='poonam_coatings'
              />
            </Link>
          </div>
          <main className='auth-main flex-center'>
            <div className='form-box'>
              <header className='auth-heading text-center'>
                <h2 className='title'>Create a New Password</h2>
              </header>

              <form
                className='auth-form'
                onSubmit={formik.handleSubmit}
                noValidate='novalidate'
              >
                <div className='form-control'>
                  <div
                    className={`form-input-box ${
                      formik.values.password !== "" ||
                      focusedField === "password"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='password' className='toggle-label'>
                      New Password
                    </label>
                    <input
                      type={visibility ? "text" : "password"}
                      id='password'
                      name='password'
                      className='form-input'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("password")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                       
                      maxLength={20}
                    />
                    <span
                      className='material-symbols-rounded medium visiblity'
                      onClick={() => setVisibility(!visibility)}
                    >
                      {!visibility ? "visibility_off" : "visibility"}
                    </span>
                  </div>
                  {formik.errors.password && formik.touched.password ? (
                    <span className='err'>{formik.errors.password}</span>
                  ) : null}
                </div>
                <div className='form-control'>
                  <div
                    className={`form-input-box ${
                      formik.values.confirm_password !== "" ||
                      focusedField === "confirm_password"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='confirm_password' className='toggle-label'>
                      Confirm Password
                    </label>
                    <input
                      type={visibility2 ? "text" : "password"}
                      id='confirm_password'
                      name='confirm_password'
                      className='form-input'
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("confirm_password")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                       
                      maxLength={20}
                    />
                    <span
                      className='material-symbols-rounded medium visiblity'
                      onClick={() => setVisibility2(!visibility2)}
                    >
                      {!visibility2 ? "visibility_off" : "visibility"}
                    </span>
                  </div>
                  {formik.errors.confirm_password &&
                  formik.touched.confirm_password ? (
                    <span className='err'>
                      {formik.errors.confirm_password}
                    </span>
                  ) : null}
                </div>

                {authLoading ? (
                  <button
                    className='btn-tertiary'
                    style={{ cursor: "not-allowed" }}
                    disabled
                  >
                    <ClipLoader
                      loading={true}
                      size={20}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                    />
                  </button>
                ) : (
                  <button type='submit' className='btn-tertiary'>
                    SUBMIT
                  </button>
                )}
              </form>

              <div className='auth-footer text-center'>
                Back to{" "}
                <Link to='/login' className='link'>
                  Login
                </Link>
              </div>
            </div>

            <nav className='auth-nav flex-center'>
              <a
                href='https://poonamcoatings.com/about-us/'
                className='nl'
                target='_blank'
                rel='noreferrer'
              >
                About Us
              </a>
              <a
                href='https://poonamcoatings.com/contact-us/'
                className='nl'
                target='_blank'
                rel='noreferrer'
              >
                Contact Us
              </a>
              <a
                href='https://poonamcoatings.com/services/'
                className='nl'
                target='_blank'
                rel='noreferrer'
              >
                Services
              </a>
            </nav>

            <footer className='auth_copyright'>
              Copyright © {year}{" "}
              <a
                href='https://poonamcoatings.com/'
                target='blank'
                rel='noreferrer'
                className='footer_link'
              >
                Poonam Coatings.
              </a>{" "}
              All Rights Reserved
            </footer>
          </main>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
