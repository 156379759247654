import React from "react"
import { ClipLoader } from "react-spinners"

const FormButtons = ({
  submitBtnText = "Submit",
  cancelBtnText = "Abort",
  loading = false,
  abortHandleClick,
  disabled = false,
  btnContainerStyles = {},
  submitBtnStyle = {},
  cancelBtnStyle = {},
  removeCancel = false,
}) => {
  return (
    <div className='flex-space-between' style={btnContainerStyles}>
      {!removeCancel && (
        <button
          type='button'
          className='btn-secondary'
          onClick={abortHandleClick}
          style={cancelBtnStyle}
        >
          {cancelBtnText}
        </button>
      )}
      {/* <button type="submit" className="btn-primary">
    Save
  </button>{" "} */}
      <button
        className='btn-primary'
        type='submit'
        // style={
        //   loading
        //     ? {
        //         cursor: "not-allowed",
        //         color: "transparent",
        //         position: "relative",
        //         transition: "none",
        //       }
        //     : {}
        // }
        style={
          loading
            ? {
                ...submitBtnStyle,
                cursor: "not-allowed",
                color: "transparent",
                position: "relative",
                transition: "none",
              }
            : { ...submitBtnStyle }
        }
        disabled={disabled ? disabled : loading}
      >
        {/* {departmentLoading ? (
          <ClipLoader
            loading={true}
            size={13}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        ) : (
          submitBtnText
        )} */}
        {submitBtnText}
        {loading && (
          <span
            style={{
              position: "absolute",
              left: "0",
              right: "0",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <ClipLoader
              loading={true}
              size={15}
              aria-label='Loading Spinner'
              data-testid='loader'
              color='white'
            />
          </span>
        )}
      </button>
    </div>
  )
}

export default FormButtons
