import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit"
import axios from "axios"
import { AddNumField, options } from "../../../helper/helper"
import { toast } from "react-toastify"
import { isPurchaseToggle } from "./PurchaseSlice"

export const searchCompany = createAsyncThunk(
  "purchase/search-company",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isPurchaseToggle(true))
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/common/search-companyName`,
        data: data.send,
      })

      if (response.status === 200) {
        // if (response?.data?.message.length > 0) {
        //   const res = await AddNumField(
        //     response?.data?.message[0],
        //     data?.page * data?.limit,
        //   )
        dispatch(isPurchaseToggle(false))
        return response?.data
        // }
      }
    } catch (err) {
      dispatch(isPurchaseToggle(false))
      return rejectWithValue()
    }
  },
)

export const getCompanyAddress = createAsyncThunk(
  "purchase/get-company-address",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      let response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/address/get-address`,
        data: data.send,
      })
      if (response?.status === 200) {
        const res = {
          ...response?.data?.data[0],
          status: 200,
          ...data?.send,
        }
        return res
      }
    } catch (error) {
      rejectWithValue()
    }
  },
)

export const getSelfWarehouseAddress = createAsyncThunk(
  "purchase/get-self-warehouse-address",
  async function (data, { fulfillWithValue, rejectWithValue, dispatch }) {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/common/companyWarehouseAddresses`,
        data,
      })
      if (response?.status === 200) {
        return response
      }
    } catch (error) {
      rejectWithValue()
    }
  },
)

export const getProductDetail = createAsyncThunk(
  "purchase/get-product-detail",
  async function (data, { rejectWithValue, fulfillWithValue, dispatch }) {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/common/productList`,
        data,
      })

      if (response?.status === 200) {
        return response
      }
    } catch (error) {
      rejectWithValue()
    }
  },
)

export const createOrder = createAsyncThunk(
  "purchase/create-order",
  async function (data, { rejectWithValue, dispatch }) {
    dispatch(isPurchaseToggle(true))
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/order/purchase/create-purchase-order`,
        data,
      })
      if (response.status === 200) {
        dispatch(isPurchaseToggle(false))
        toast.success(response?.data?.message)
        return response
      } else {
        dispatch(isPurchaseToggle(false))
        toast.error(response?.data?.message)
      }
    } catch (error) {
      rejectWithValue()
    }
  },
)

export const returnPurchaseOrder = createAsyncThunk(
  "purchase/return-purchase-order",
  async function (data, { fulfillWithValue, rejectWithValue, dispatch }) {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/order/purchase/create-purchase-return-order`,
        data,
      })
    } catch (error) {}
  },
)

export const getPurchaseOrderDetails = createAsyncThunk(
  "purchase/get-purchase-order-details",
  async function (data, { fulfillWithValue, rejectWithValue, dispatch }) {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/order/purchase/get-purchase-order-details`,
        data,
      })

      if (response.status === 200) {
        return fulfillWithValue(response?.data)
      } else {
        toast.error(response?.data?.message, options)
        return rejectWithValue()
      }
    } catch (err) {
      toast.error(err, options)
      return rejectWithValue()
    }
  },
)

export const getSalesAndOrderList = createAsyncThunk(
  "common/get-purchase-sales-lists",
  async function (data, { rejectWithValue, fulfillWithValue, dispatch }) {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/common/order-list?limit=${data?.limit}&page=${data?.page}&sort=${data?.sort}&search=${data?.search}&filter=""`,
        data: data?.send,
      })

      if (response.status === 200) {
        if (response?.data?.data.length > 0) {
          const res = await AddNumField(
            response?.data?.data[0],
            data?.page * data?.limit,
          )

          return fulfillWithValue(res)
          // return fulfillWithValue(response?.data?.message[0]);
        } else {
          // const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/common/order-list?limit=${data?.limit}&page=${data?.page}&sort=${data?.sort}&search=${data?.search}`,
            data: data?.send,
          })
          if (responseAgain.status === 200) {
            const res = await AddNumField(
              response?.data?.data[0],
              data?.page * data?.limit,
            )

            return fulfillWithValue(res)
          } else {
            // toast.error(responseAgain?.data?.message, options);
            return rejectWithValue()
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue()
      }
    } catch (error) {}
  },
)
