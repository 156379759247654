/* eslint-disable no-useless-escape */
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ClipLoader } from "react-spinners"
import { getLocalityDetail } from "../modules/auth/redux/authApi"

const AddressForm = ({
  type,
  api,
  companyData,
  customSetter,
  customState,
  popUpSetter,
}) => {
  const [localityAddress, setLocalityAddress] = useState({})
  const { addressLoading, companyLoading } = useSelector(store => store.bNs)
  const [activeInputs, setActiveInputs] = useState(false)
  const { companyDetails, companyAddress } = companyData

  const [focusedField, setFocusedField] = useState()
  const [addStyle, setAddStyle] = useState(true)
  // const [activeInputs, setActiveInputs] = useState(false);

  const dispatch = useDispatch()

  const locationSchema = Yup.object({
    address_line_one: Yup.string()
      .trim()
      .min(3, "Address must be at least 3 characters long")
      .max(150, "Address must be less than 150 characters")
      .required("Address is required"),
    address_line_two: Yup.string()
      .trim()
      .min(3, "Address must be at least 3 characters long")
      .max(150, "Address must be less than 150 characters"),
    // .required("Address is required"),
    city: Yup.string().required("Please enter valid city name"),
    state: Yup.string().required("Please enter valid state name"),
    country: Yup.string().required("Please enter valid country name"),
    pinCode: Yup.string()
      .min(6, "Enter valid pincode please!")
      .matches(/^[1-9][0-9]{5}$/, "Enter a valid pincode")
      .required("Pincode is required"),
  })

  const formik = useFormik({
    initialValues: {
      address_line_one: "",
      address_line_two: "",
      city: "",
      state: "",
      country: "",
      pinCode: "",
    },
    validationSchema: locationSchema,
    onSubmit: async (values, { resetForm }) => {
      let data = {}
      if (type === "Edit") {
        data = {
          send: {
            name: companyDetails?.name,
            company_id: companyDetails?._id,
            email: companyDetails?.companyEmail?.value,
            phone: companyDetails?.companyPhone,
            address_type: "warehouse",
            address_line_one: values?.address_line_one,
            address_line_two: values?.address_line_two,
            cityId: localityAddress.cityId,
            stateId: localityAddress.stateId,
            countryId: localityAddress.countryId,
            pinCode: values?.pinCode,
          },
          id: companyData?.companyAddress?._id
            ? companyData?.companyAddress?._id
            : "",
          type: "self-address",
        }
      } else {
        data = {
          send: {
            name: companyDetails?.name,
            company_id: companyDetails?._id,
            email: companyDetails?.companyEmail?.value,
            phone: companyDetails?.companyPhone,
            address_type: "warehouse",
            address_line_one: values?.address_line_one,
            address_line_two: values?.address_line_two,
            city: values?.city,
            state: values?.state,
            country: values?.country,
            pin_code: values?.pinCode,
            checked: false,
          },
          id: companyData?.companyAddress?._id
            ? companyData?.companyAddress?._id
            : "",
          type: "self-address",
        }
      }
      const response = await dispatch(api(data))
      if (response.payload) {
        handleClick()
      }
      //   const response = await dispatch(addCompanyAddress(data))
      //   if (response.payload) {
      //     customSetter(0)
      //     resetForm()
      //   }

      // if (type === "delivery") {
      //   delivery_locations.push(values);
      // }
      // if (type === "billing") {
      //   billing_locations.push(values);
      // }
      // SetData(data, ...values);
    },
  })

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (customState) {
      const styleTimer = setTimeout(() => {
        customSetter(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")

    if (type === "Edit") {
      const { companyAddress } = companyData

      formik.setValues({
        address_line_one: companyAddress?.address?.line_one,
        address_line_two: companyAddress?.address?.line_two,
        city: companyAddress?.city_detail?.name,
        state: companyAddress?.state_detail?.name,
        country: companyAddress?.country_detail?.name,
        pinCode: companyAddress?.address?.pin_code,
      })

      setLocalityAddress({
        cityId: companyAddress?.city_detail?._id,
        stateId: companyAddress?.state_detail?._id,
        countryId: companyAddress?.country_detail?._id,
      })
    }
  }, [])

  const hitPincodeApi = async value => {
    const sendData = {
      pin_code: value,
    }
    const response = await dispatch(getLocalityDetail(sendData))
    if (response?.payload) {
      formik.setValues({
        ...formik.values,
        pinCode: value,
        city: response?.payload?.data?.data?.city_name,
        state: response?.payload?.data?.data?.state_name,
        country: response?.payload?.data?.data?.country_name,
      })

      setLocalityAddress({
        cityId: response?.payload?.data?.data?.city_id,
        stateId: response?.payload?.data?.data?.state_id,
        countryId: response?.payload?.data?.data?.country_id,
      })
    }
  }

  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>
            {type === "Edit" ? (activeInputs && type ? type : "") : type}{" "}
            Warehouse Address
          </h2>
          <button className='exit-btn' onClick={() => handleClick()}>
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>

        <div className='modelEx_content singleAdd'>
          <form className='scrollForm' onSubmit={formik.handleSubmit}>
            <div className='form-content form-for-Address'>
              <div className='update-form-activation'>
                <h3 className='title'>
                  {type === "Edit" ? (activeInputs && type ? type : "") : type}{" "}
                  Details*
                </h3>
                {type === "Edit" && (
                  <div
                    className='detail-btn update-form-activation'
                    style={{ gap: "1rem" }}
                  >
                    {companyData?.companyAddress?.constraint ===
                      "secondary" && (
                      <div
                        className={
                          companyData?.companyAddress?.type !== "work"
                            ? "divide-border-right"
                            : ""
                        }
                      >
                        <button
                          type='button'
                          onClick={e => {
                            e.preventDefault()
                            popUpSetter({
                              data: {
                                address_id: companyData?.companyAddress?._id,
                                company_id: companyData?.companyDetails?._id,
                                address_type: companyData?.companyAddress?.type,
                              },
                            })

                            // setPopupData({
                            //   data: { id: address_id, send: { company_id: id, is_delete: true } },
                            //   title: title,
                            // })
                            handleClick()
                          }}
                          className='btn-secondary changes-primary'
                        >
                          <i className='fa-sharp fa-solid fa-thumbtack'></i>{" "}
                          {/* <i className="fa-regular fa-map-location-dot"></i>{" "} */}
                          SET AS PRIMARY
                        </button>
                      </div>
                    )}
                    {activeInputs ? (
                      <button
                        type='button'
                        onClick={e => {
                          e.preventDefault()
                          setActiveInputs(false)
                        }}
                        className='btn-secondary'
                        disabled={companyLoading}
                        style={companyLoading ? { cursor: "not-allowed" } : {}}
                      >
                        {companyLoading ? (
                          <ClipLoader
                            loading={true}
                            size={15}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                            color='white'
                          />
                        ) : (
                          "Abort"
                        )}
                      </button>
                    ) : (
                      companyData?.companyAddress?.type !== "work" && (
                        <button
                          type='button'
                          onClick={e => {
                            e.preventDefault()
                            setActiveInputs(true)
                          }}
                          className='btn-primary'
                        >
                          <span className='material-symbols-outlined small'>
                            border_color
                          </span>{" "}
                          EDIT
                        </button>
                      )
                    )}
                  </div>
                )}
              </div>
              <div className='form-group form'>
                <div
                  className={`input-field ${
                    formik.values.address_line_one !== "" ||
                    focusedField === "address1"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='address-line-one' className='toggle-label'>
                    Address Line 1{" "}
                  </label>
                  <input
                    id='address-line-one'
                    name='address_line_one'
                    type='text'
                    className='form-input'
                    maxLength={151}
                    onChange={formik.handleChange}
                    value={formik.values.address_line_one}
                    // onInput={(e) => {
                    // e.target.value = e.target.value;
                    // .replace(/[^A-Za-z ]/gi, "")
                    // .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                    // }}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("address1")}
                    disabled={type === "Edit" ? !activeInputs : false}
                  />
                  {formik.touched.address_line_one &&
                  formik.errors.address_line_one ? (
                    <span className='err'>
                      {formik.errors.address_line_one}
                    </span>
                  ) : null}
                </div>
                <div
                  className={`input-field ${
                    formik.values.address_line_two !== "" ||
                    focusedField === "address2"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='address-line-one' className='toggle-label'>
                    Address Line 2
                  </label>
                  <input
                    id='address_line_two'
                    name='address_line_two'
                    type='text'
                    className='form-input'
                    value={formik.values.address_line_two}
                    onChange={formik.handleChange}
                    // onInput={(e) => {
                    //   e.target.value = e.target.value;
                    // .replace(/[^A-Za-z ]/gi, "")
                    // .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                    // }}
                    maxLength={151}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("address2")}
                    disabled={type === "Edit" ? !activeInputs : false}
                  />
                  {formik.touched.address_line_two &&
                  formik.errors.address_line_two ? (
                    <span className='err'>
                      {formik.errors.address_line_two}
                    </span>
                  ) : null}
                </div>
                {/* <div className="form-group contact-info"> */}
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.pinCode !== "" || focusedField === "pinCode"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='pinCode' className='toggle-label'>
                      Pincode
                    </label>
                    <div className='pinCode_wrapper'>
                      <input
                        id='pinCode'
                        name='pinCode'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          formik.setFieldValue("pinCode", e.target.value)
                          if (e.target.value.length > 5) {
                            hitPincodeApi(e.target.value)
                          }
                        }}
                        value={formik.values.pinCode}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 6)
                            .replace(/\D/g, "")
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("pinCode")}
                        maxLength={6}
                        disabled={type === "Edit" ? !activeInputs : false}
                      />
                      {addressLoading ? (
                        <span className='pinCode_spinner'>
                          <ClipLoader
                            loading={true}
                            size={15}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                            color='white'
                          />
                        </span>
                      ) : null}
                    </div>

                    {formik.touched.pinCode && formik.errors.pinCode ? (
                      <span className='err'>{formik.errors.pinCode}</span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.city !== "" || focusedField === "city"
                        ? "active"
                        : ""
                    }`}
                  >
                    {" "}
                    <label htmlFor='city' className='toggle-label'>
                      City
                    </label>
                    <input
                      id='city'
                      name='city'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      onFocus={() => setFocusedField("city")}
                      disabled
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <span className='err'>{formik.errors.city}</span>
                    ) : null}
                  </div>
                </div>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.state !== "" || focusedField === "state"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='state' className='toggle-label'>
                      State
                    </label>
                    <input
                      id='state'
                      name='state'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("state")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      disabled
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <span className='err'>{formik.errors.state}</span>
                    ) : null}
                  </div>
                  <div
                    className={`input-field ${
                      formik.values.country !== "" || focusedField === "country"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='country' className='toggle-label'>
                      Country
                    </label>
                    <input
                      id='country'
                      name='country'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.country}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("country")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      disabled
                    />
                    {formik.touched.country && formik.errors.country ? (
                      <span className='err'>{formik.errors.country}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {(activeInputs || type !== "Edit") && (
              <div className='footer flex-center'>
                {/* <button type="submit" className="btn-primary">
                Submit
              </button> */}
                <button
                  className='btn-primary'
                  type='submit'
                  style={
                    companyLoading || addressLoading
                      ? {
                          cursor: "not-allowed",
                          // background: "gray",
                        }
                      : {}
                  }
                  disabled={companyLoading || addressLoading}
                >
                  {companyLoading ? (
                    <ClipLoader
                      loading={true}
                      size={15}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                      color='white'
                    />
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddressForm
