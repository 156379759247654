/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import Sidebar from "../../../components/Sidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import Breadcrumb from "../../../components/BreadCrumbs"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import Skelaton from "../../../components/Skelaton"
import { useNavigate, useParams } from "react-router-dom"
import { STOCK_MOVEMENT_DETAILS } from "../../../helper/dummy_data"
import { getStockHistoryDetail } from "../redux/stockManagementApi"
import { formatTimestamp } from "../../../helper/helper"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
// import Loader from "../../../components/Loader";

const StockDetails = ({ setTitle }) => {
  const { id } = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    setTitle("Stock Management")
  }, [setTitle])

  useEffect(() => {
    dispatch(getStockHistoryDetail({ id }))
  }, [])

  const { privileges } = useSelector(state => state.auth, shallowEqual)

  const [active, setActive] = useState("stock-movement")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const { singleStockHistoryDetail, stockLoading } = useSelector(
    store => store.stock,
  )

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  const { tableLoading } = useLoaderDelay({ loadingState: stockLoading })
  return (
    <div className='container' style={heightAuto}>
      <div className='app-container'>
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          {/* 
        {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Stock Management</div>
                  <div className='mch-path'>
                    <Breadcrumb id title={"Stock Transfer Details"} />

                    {/* Profile / User Info */}
                  </div>
                </div>
                {/* {Object.values(privileges["inventory_management"]).includes(
                  "write",
                ) && (
                  <button
                    className='btn-primary self-center'
                    onClick={() => setShowStockTransfer(!showStockTransfer)}
                  >
                    Stock Transfer
                  </button>
                )} */}
              </div>

              <div className='data-table-container'>
                <div className='stock-details-basic'>
                  <h2 className='title'>Document Number - A7300</h2>
                  <p>
                    <b>Created By:</b> {singleStockHistoryDetail?.userFname}{" "}
                    {singleStockHistoryDetail?.userLname}{" "}
                  </p>

                  <p>
                    <b>From Store:</b>{" "}
                    {singleStockHistoryDetail?.products?.length > 0
                      ? singleStockHistoryDetail.products[0].from_store_slug
                      : ""}{" "}
                  </p>
                  <p>
                    <b>Inventory Change Date:</b>{" "}
                    {formatTimestamp(singleStockHistoryDetail.createdAt)}
                  </p>
                  <p>
                    <b>To Location:</b>{" "}
                    {singleStockHistoryDetail?.products?.length > 0
                      ? singleStockHistoryDetail.products[0].to_store_slug
                      : ""}
                  </p>
                  {/* 
                  <p>
                    <b>Scheduled Date:</b> 03/09/2024
                  </p> */}

                  {/* <p>
                    <b>Date:</b> 03/09/2024
                  </p> */}
                </div>

                {tableLoading ? (
                  <Skelaton
                    col={4}
                    row={singleStockHistoryDetail?.products?.length}
                  />
                ) : singleStockHistoryDetail?.products?.length > 0 ? (
                  <>
                    <div className='data-table-box  scrollbar-visible'>
                      <table className='data-table stock-table'>
                        <thead>
                          <tr>
                            <th>SKU</th>
                            <th>Product Name</th>
                            <th>From Store</th>
                            <th>To location</th>
                            <th>Transfer Quantity</th>
                            <th>UOM</th>
                            <th>Price/Unit</th>
                            {/* {Object.values(
                              privileges["inventory_management"],
                            ).includes("write") && <th>Action</th>} */}
                          </tr>
                        </thead>

                        <tbody>
                          {singleStockHistoryDetail?.products?.map(
                            (item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.sku}</td>
                                  <td>
                                    <div
                                    // style={{
                                    //   display: "flex",
                                    //   alignItems: "center",
                                    //   justifyContent: "space-between",
                                    //   gap: ".4rem",
                                    // }}
                                    >
                                      <span>{item.name}</span>
                                    </div>
                                  </td>

                                  <td>{item?.from_store_slug}</td>
                                  <td>{item?.to_store_slug}</td>
                                  <td>
                                    {item.transfferedItems}{" "}
                                    {/* <span className='status gray'>
                                    </span> */}
                                  </td>
                                  <td>
                                    {item.uom}
                                    {/* <span className='status gray'></span> */}
                                  </td>
                                  <td>
                                    <span className='status gray'>
                                      {"₹"} {item.unitPrice}
                                    </span>
                                  </td>
                                </tr>
                              )
                            },
                          )}
                        </tbody>
                      </table>
                    </div>

                    {/* <Pagination
                      sliceName={"email"}
                      search={search}
                      filter={"desc"}
                      api={getEmailList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                    /> */}
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StockDetails
