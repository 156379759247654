/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
// import { deleteEmail, getEmailList } from "../redux/EmailApi";
// import { getSalesAndOrderList } from "../redux/InventoryApi"
import { setPageDetails } from "../../auth/redux/authSlice"
import { unixTimeToReadableFormat } from "../../../helper/helper"
import Pagination from "../../../components/Pagination"
import PurchaseTable from "../components/PurchaseTable"
import SearchBox from "../../../components/SearchBox"
import FilterBox from "../../../components/FilterBox"
import Skelaton from "../../../components/Skelaton"
import PurchaseReturnNumberPopup from "../components/PurchaseReturnNumberPopup"
import { getSalesAndOrderList } from "../redux/PurchaseApi"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"

const Purchase = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Purchase Management")
  }, [setTitle])
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [active, setActive] = useState("purchaseDetails")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [isEventCard, setIsEventCard] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const { inventoryList } = useSelector(state => state.inventory)
  const { commonPageList, purchaseLoading } = useSelector(function (state) {
    return state.purchase
  })

  const [filterTable, setFilterTable] = useState({
    stock_quantity: true,
    sold: true,
    created_at: true,
    minimum_stock_quantity: true,
  })
  const { inventoryLoading, metaData } = useSelector(store => store.inventory)
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const [searchShow, setSearchShow] = useState(false)
  const [activePo, setActivePo] = useState(1)

  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )

  const [showTable, setShowTable] = useState("order")

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      dispatch(
        getSalesAndOrderList({
          page: pageDetails?.page ? pageDetails?.page : 0,
          limit: records,
          search:
            Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
          sort: "desc",
          send: {
            requestType: activePo === 1 ? "purchase" : "purchase return",
          },
        }),
      )
      setCheck(true)
      dispatch(setPageDetails({ page: metaData?.page, search }))
      return
    }

    const timeoutId = setTimeout(async () => {
      dispatch(
        getSalesAndOrderList({
          limit: records,
          page: 0,
          search,
          sort: "desc",
          send: {
            requestType: activePo === 1 ? "purchase" : "purchase return",
          },
        }),
      )
      setTableIndex(0)
      setBtnNo([1, 2, 3])
    }, 1000)
    dispatch(setPageDetails({ page: metaData?.page, search }))
    setSearchTimeout(timeoutId)
  }, [dispatch, records, search])

  useEffect(() => {
    dispatch(
      getSalesAndOrderList({
        limit: records,
        page: 0,
        search,
        sort: "desc",
        send: {
          requestType: activePo === 1 ? "purchase" : "purchase return",
        },
      }),
    )
  }, [activePo])

  useEffect(() => {
    dispatch(setPageDetails({ page: metaData?.page, search }))
  }, [metaData])

  const { tableLoading } = useLoaderDelay({ loadingState: purchaseLoading })

  // Data for Purchase Orders

  const Orderheaders = [
    { key: "order_number", label: "Order Number" },
    { key: "po_number", label: "PO Number" },
    { key: "purchase_return", label: "Purchase Return" },
    { key: "company", label: "Company" },
    { key: "good_status", label: "Status" },
  ]

  const Returnheaders = [
    { key: "document_number", label: "Order Number" },
    { key: "po_number", label: "PO Number" },
    { key: "company", label: "Company Name" },
    { key: "creation_date", label: "Creation Date" },
    { key: "item_qty", label: "Number of items" },
    { key: "return_qty", label: "Purchase return qty" },
    { key: "status", label: "Status" },
  ]

  const headerToDisplay = activePo === 1 ? Orderheaders : Returnheaders
  const [showOrderNumber, setShowOrderNumber] = useState(false)

  // useEffect(() => {
  //   dispatch(
  //     getSalesAndOrderList({
  //       send: { requestType: activePo === 1 ? "purchase" : "purchase return" },
  //       sort: "desc",
  //       search: "",
  //       limit: "5",
  //       page: "0",
  //     }),
  //   )
  // }, [activePo])
  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        <div className='home-container'>
          {" "}
          {showOrderNumber && (
            <PurchaseReturnNumberPopup
              customSetter={setShowOrderNumber}
              customState={showOrderNumber}
            />
          )}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Purchase Details</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>
              <div className='data-table-container pur-data'>
                <div className='col1 heading-tools'>
                  <div className='p-change'>
                    <span
                      className={activePo === 1 ? "active" : ""}
                      onClick={() => setActivePo(1)}
                    >
                      Purchase Order
                    </span>
                    <span
                      className={activePo === 2 ? "active" : ""}
                      onClick={() => setActivePo(2)}
                    >
                      Purchase Return
                    </span>
                  </div>
                  <div className='table-search pur'>
                    <div className='search-container'>
                      <div className='search-filter-flex'>
                        <SearchBox
                          customSetter={setSearch}
                          customState={search}
                        />
                        <FilterBox
                          customState={filterTable}
                          customSetter={setFilterTable}
                          customPosition={{ right: "0", left: "auto" }}
                        />
                        {Object.values(
                          privileges["purchase_management"],
                        ).includes("write") && (
                          <button
                            className='btn-alpha header-btn  '
                            onClick={() => {
                              activePo === 1
                                ? navigate("/purchase/purchase-order")
                                : setShowOrderNumber(true)
                            }}
                          >
                            {activePo === 1
                              ? "Create Purchase Order"
                              : "Create Purchase Return"}
                          </button>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton col={6} row={inventoryList?.length} />
                ) : commonPageList?.length > 0 ? (
                  <>
                    <PurchaseTable
                      filterTable={filterTable}
                      listContent={commonPageList}
                      listHeader={headerToDisplay}
                      activePo={activePo}
                    />

                    <Pagination
                      sliceName={"purchase"}
                      search={search}
                      filter={"desc"}
                      api={getSalesAndOrderList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                      send={{
                        requestType:
                          activePo === 1 ? "purchase" : "purchase return",
                      }}
                    />
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Purchase
