import React from "react"
import { ClipLoader } from "react-spinners"

const EditFormButtons = ({
  handleSubmit,
  handleCancel,
  loading,
  submitBtnText = "Save",
  cancelBtnText = "Cancel",
  activeInputs = false,
  handleInputs,
}) => {
  return (
    <div className='detail-btn'>
      {activeInputs ? (
        <>
          {!loading && (
            <button
              type='button'
              onClick={handleCancel}
              className='btn-secondary'
              disabled={loading}
              style={loading ? { cursor: "not-allowed" } : {}}
            >
              {cancelBtnText}
            </button>
          )}{" "}
          <button
            type='submit'
            onClick={handleSubmit}
            className='btn-primary'
            disabled={loading}
            style={loading ? { cursor: "not-allowed" } : {}}
          >
            {loading ? (
              <ClipLoader
                loading={true}
                size={15}
                aria-label='Loading Spinner'
                data-testid='loader'
                color='white'
              />
            ) : (
              submitBtnText
            )}
          </button>{" "}
        </>
      ) : (
        <button
          onClick={() => handleInputs(true)}
          className='btn-secondary'
          type='button'
        >
          <span className='material-symbols-outlined small'>border_color</span>
          Edit
        </button>
      )}
    </div>
  )
}

export default EditFormButtons
