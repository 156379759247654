/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import ConformationPopup from "../../../components/ConformationPopup"
import DataNotFound from "../../../components/DatanotFound"
import DropDownBox from "../../../components/DropDownBox"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Pagination from "../../../components/Pagination"
import SearchBox from "../../../components/SearchBox"
import Sidebar from "../../../components/Sidebar"
import Skelaton from "../../../components/Skelaton"
import { useLoaderDelay } from "../../../customHooks/LoaderDelay"
import {
  handleAnimation,
  unixTimeToReadableFormat,
} from "../../../helper/helper"
import { setPageDetails } from "../../auth/redux/authSlice"
import AddMultipleCom from "../components/AddMultipleCom"
import AddSingleCom from "../components/AddSingleCom"
import { deleteCompanyAsync, getCompanyList } from "../redux/bNsApi"

const Buyers_Suppliers = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Buyers and Suppliers")
  }, [setTitle])

  const dispatch = useDispatch()
  const { companyLoading, companyList, metaData } = useSelector(
    state => state.bNs,
    shallowEqual,
  )

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )
  const [category, setCategory] = useState(
    pageDetails?.category ? pageDetails?.category : "",
  )
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )
  const [city, setCity] = useState(pageDetails?.city ? pageDetails?.city : "")

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout)
    } else {
      const data = {
        page:
          Object.keys(pageDetails).length > 0 && pageDetails.page !== undefined
            ? pageDetails?.page
            : 0,
        limit: records,
        search: Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
        filter:
          Object.keys(pageDetails).length > 0 ? pageDetails?.category : "",
        send: {
          categories:
            Object.keys(pageDetails).length > 0 ? pageDetails?.category : "",
          city: Object.keys(pageDetails).length > 0 ? pageDetails?.city : "",
        },
      }
      dispatch(getCompanyList(data))
      setCheck(true)
      return
    }
    const timeoutId = setTimeout(async () => {
      dispatch(
        getCompanyList({
          page: 0,
          limit: records,
          sort: "desc",
          filter: category,
          search,
          send: {
            categories: category,
            city,
          },
        }),
      )
      setBtnNo([1, 2, 3])
      setTableIndex(0)
      if (selectAllProducts) {
        setSelectAllProducts(false)
        setCollectAllProducts([])
      }
    }, 1000)

    setSearchTimeout(timeoutId)
    dispatch(
      setPageDetails({
        page: metaData?.page,
        search,
        city,
        category,
      }),
    )
  }, [records, category, search, city])
  const [active, setActive] = useState("buyers")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [tableIndex, setTableIndex] = useState(0)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const [popup, setPopup] = useState(0)
  const [isEventCard, setIsEventCard] = useState(false)
  const [popUpData, setPopupData] = useState({})
  const [deleteAllPopup, setDeleteAllPopup] = useState(false)
  const [selectAllProducts, setSelectAllProducts] = useState(false)
  const [collectAllProducts, setCollectAllProducts] = useState([])

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null
  const [data, setData] = useState(null)

  const deleteFunction = (id, title) => {
    setPopupData({
      data: {
        id,
        page: 0,
        search: "",
        sort: "desc",
        limit: "5",
        send: {
          is_delete: true,
          company_ids: selectAllProducts ? collectAllProducts : [id],
        },
      },
      title,
    })
    setIsEventCard(true)
  }

  useEffect(() => {
    setData(companyList)
  }, [companyList])

  const { tableLoading } = useLoaderDelay({ loadingState: companyLoading })

  useEffect(() => {
    const idArr = companyList?.map(item => {
      return item._id
    })
    if (selectAllProducts) {
      setCollectAllProducts(idArr)
    } else {
      setCollectAllProducts([])
    }
  }, [selectAllProducts])
  // useEffect(() => {
  //   if (tableCount) {
  //     clearTimeout(tableCount)
  //   }
  //   const count = setTimeout(() => {
  //     // if (companyLoading) {
  //     //   setTableLoading(true);
  //     // } else {
  //     //   setTableLoading(false);
  //     // }
  //     setTableLoading(companyLoading)
  //   }, 300)
  //   setTableCount(count)

  //   return () => clearTimeout(count)
  // }, [companyLoading])

  useEffect(() => {
    if (selectAllProducts && collectAllProducts.length === 0) {
      setSelectAllProducts(false)
      setCollectAllProducts([])
      return
    }
  }, [collectAllProducts])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  useEffect(() => {
    if (pageDetails?.btnNo) {
      setBtnNo(pageDetails?.btnNo)
    }

    dispatch(
      setPageDetails({
        page: metaData?.page,
        search,
        city,
        category,
      }),
    )
  }, [metaData, city])

  return (
    <div
      className='container'
      style={!isEventCard && !popup ? heightAuto : null}
    >
      <div className='app-container'>
        {popup === 1 && <AddSingleCom popup={popup} setPopup={setPopup} />}
        {popup === 2 && <AddMultipleCom popup={popup} setPopup={setPopup} />}
        <div className='home-container'>
          {/* <ScrollTop containerRef={containerRef} /> */}
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
                api={getCompanyList}
                apiData={{
                  page: "0",
                  limit: "5",
                  sort: "desc",
                  filter: "",
                  search: "",
                  send: {
                    categories: "",
                    city: "",
                  },
                }}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
                api={getCompanyList}
                apiData={{
                  page: "0",
                  limit: "5",
                  sort: "desc",
                  filter: "",
                  search: "",
                  send: {
                    categories: "",
                    city: "",
                  },
                }}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              active={active}
              setActive={setActive}
              MbSidebar={MbSidebar}
              setMbsidebar={setMbsidebar}
              api={getCompanyList}
              apiData={{
                page: "0",
                limit: "5",
                sort: "desc",
                filter: "",
                search: "",
                send: {
                  categories: "",
                  city: "",
                },
              }}
            />
          )}
          {/* {showChat && (
          <Chatbox showChat={showChat} setShowChat={setShowChat} />
        )} */}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                setInitialRenderAnimation={setInitialRenderAnimation}
                initialRenderAnimation={initialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Buyers & Suppliers</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>
              <div className='data-table-container'>
                <div className='bns-header'>
                  <div className='col1'>
                    <h3>Buyers & Suppliers</h3>
                    <div className='search-container'>
                      <SearchBox
                        customSetter={setSearch}
                        customState={search}
                      />
                      <DropDownBox
                        options={[
                          { label: "Buyer", value: "Buyer" },
                          { label: "Supplier", value: "Supplier" },
                          { label: "Both", value: "Both" },
                        ]}
                        customSetter={setCategory}
                        size='small'
                        animateDropDownTitle
                        dropDownTitle='Category'
                        customTextStyle={{
                          color: "#212229",
                          opacity: "0.7",
                        }}
                        resetButton={true}
                        incomingValue={category ? category : false}
                        customDropBoxStyles={{ height: "2.4rem" }}
                        animateDropDownTitleStyle={{
                          labelUp: { bottom: "25%" },
                          labelDown: { bottom: "2%" },
                        }}
                      />
                      {/* <DropDownBox
                          options={[
                            { label: "Ahmedabad", value: "ahmedabad" },
                            { label: "Bhavnagar", value: "bhavnagar" },
                            { label: "Rajkot", value: "rajkot" },
                            { label: "Central Delhi", value: "central" },
                          ]}
                          dropDownTitle='City'
                          animateDropDownTitle
                          customSetter={setCity}
                          customTextStyle={{
                            color: "#212229",
                            opacity: "0.7",
                          }}
                          size='small'
                          resetButton={true}
                          showSearchBar
                          incomingValue={city ? city : false}
                          customDropBoxStyles={{ height: "2.4rem" }}
                          animateDropDownTitleStyle={{
                            labelUp: { bottom: "50%" },
                            labelDown:{bottom:"30%"}

                          }}
                        /> */}
                      {/* <Tags /> */}
                      <div className='mch2 flex-center'>
                        {/* <button
                    className="btn-secondary mch2-btn"
                    onClick={() => setPopup(!popup)}
                  >
                    <span className="">Add Buyers/Suppliers</span>
                  </button>
                  <button
                    className="btn-primary mch2-btn flex-center"
                    onClick={() => setPopup(!popup)}
                  >
                    <span className="">Bulk Upload</span>
                    <span className="material-symbols-rounded small">
                      description
                    </span>
                  </button> */}
                        {Object.values(
                          privileges["buyers_and_suppliers_management"],
                        ).includes("write") && (
                          <PopupSelector customSetter={setPopup} />
                        )}
                      </div>{" "}
                      {Object.values(
                        privileges["buyers_and_suppliers_management"],
                      ).includes("delete") && selectAllProducts ? (
                        <button
                          className='btn-secondary header-btn delete-primary-button'
                          onClick={() => {
                            deleteFunction("all products")
                          }}
                        >
                          <span className='material-symbols-outlined medium'>
                            delete
                          </span>{" "}
                          Delete{" "}
                          {collectAllProducts.length > 1
                            ? "All " + collectAllProducts.length
                            : ""}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton col={7} row={data?.length} />
                ) : (
                  <>
                    {data?.length > 0 ? (
                      <>
                        {" "}
                        <div className='data-table-box scrollbar-visible'>
                          <table className='data-table fix-width-table-row'>
                            <thead>
                              <tr>
                                <th className='tb-head-name padding-07 '>
                                  <div>
                                    <span>
                                      <input
                                        name='checkbox'
                                        id='checkbox'
                                        type='checkbox'
                                        className='checkbox-animated'
                                        onChange={() =>
                                          setSelectAllProducts(
                                            !selectAllProducts,
                                          )
                                        }
                                        checked={selectAllProducts}
                                      />
                                      <label htmlFor={"checkbox"}>
                                        <span className='filter-check-background'></span>
                                      </label>
                                    </span>
                                  </div>
                                </th>
                                <th>Company</th>
                                <th>Category</th>
                                <th>City</th>
                                {/* <th>Tags</th> */}
                                <th>Date</th>
                                {Object?.values(
                                  privileges["buyers_and_suppliers_management"],
                                )?.includes("read") && <th>Actions</th>}
                              </tr>
                            </thead>

                            <tbody>
                              {data?.map((item, index) => {
                                const {
                                  _id,
                                  name,
                                  cityName,
                                  createdAt,
                                  num,
                                  buyerAndSupplier,
                                } = item

                                return (
                                  <tr key={index}>
                                    <td className='tb_data padding-07'>
                                      {selectAllProducts ? (
                                        <span
                                          onChange={
                                            e => e.stopPropagation()
                                            // setSelectAllProducts(
                                            //   !selectAllProducts,
                                            // )
                                          }
                                        >
                                          <input
                                            name={"checkbox" + index}
                                            id={"checkbox" + index}
                                            type='checkbox'
                                            className='checkbox-animated'
                                            onChange={e => {
                                              e.stopPropagation()
                                              setCollectAllProducts(oldList => {
                                                let newList = JSON.parse(
                                                  JSON.stringify(oldList),
                                                )

                                                if (newList.includes(_id)) {
                                                  newList.splice(
                                                    newList.indexOf(_id),
                                                    1,
                                                  )
                                                } else {
                                                  newList.unshift(_id)
                                                }

                                                return newList
                                              })
                                            }}
                                            checked={collectAllProducts.includes(
                                              _id,
                                            )}
                                          />
                                          <label htmlFor={"checkbox" + index}>
                                            <span className='filter-check-background'></span>
                                          </label>
                                        </span>
                                      ) : (
                                        num + "."
                                      )}
                                    </td>
                                    {/* <td className='tb_data padding-07'>
                                      {num}
                                      {"."}
                                    </td> */}
                                    <td className='company_details'>
                                      {name} <br />
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {buyerAndSupplier}
                                    </td>
                                    <td>{cityName}</td>
                                    {/* <td className="tags_heading">
                              <span className="tags">{tags}</span>
                            </td> */}
                                    <td>
                                      {unixTimeToReadableFormat(createdAt)}
                                    </td>
                                    <td className='padding-07'>
                                      <div className='email-action-box'>
                                        {Object?.values(
                                          privileges[
                                            "buyers_and_suppliers_management"
                                          ],
                                        )?.includes("read") && (
                                          <button
                                            className='icon-btn see'
                                            onClick={() => {
                                              dispatch(
                                                setPageDetails({
                                                  ...pageDetails,
                                                  btnNo,
                                                }),
                                              )
                                              navigate(
                                                `/buyers-and-suppliers/${_id}`,
                                              )
                                            }}
                                          >
                                            <span className='material-symbols-outlined medium'>
                                              visibility
                                            </span>
                                          </button>
                                        )}{" "}
                                        {Object.values(
                                          privileges[
                                            "buyers_and_suppliers_management"
                                          ],
                                        ).includes("delete") && (
                                          <button
                                            className='icon-btn delete'
                                            onClick={() =>
                                              deleteFunction(_id, name)
                                            }
                                          >
                                            <span className='material-symbols-outlined medium'>
                                              Delete
                                            </span>
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="pagination-container">
                          <div className="pagination-box flex-center">
                            <button
                              className="pagBTN flex-center"
                              onClick={() => {
                                if (tableIndex > 0) {
                                  dispatch(
                                    getCompanyList({
                                      page: tableIndex - 1,
                                      limit: 5,
                                      sort:"desc",
                                      search,
                                      send: {
                                        categories: category,
                                        tags: "",
                                        city,
                                      },
                                    })
                                  );
                                }
                                handleChange(tableIndex);
                              }}
                            >
                              <span className="material-symbols-outlined small">
                                west
                              </span>
                            </button>
                            {new Array(metaData?.totalPages).fill(undefined)
                              .length <= 3 ? (
                              new Array(metaData?.totalPages)
                                .fill(undefined)
                                ?.map((item, index) => {
                                  return (
                                    <button
                                      key={index}
                                      className={
                                        index === tableIndex
                                          ? "pagBTN active"
                                          : "pagBTN"
                                      }
                                      onClick={() => {
                                        setTableIndex(index);
                                        dispatch(
                                          getCompanyList({
                                            page: index,
                                            limit: 5,
                                            sort:"desc",
                                            search,
                                            send: {
                                              categories: category,
                                              tags: "",
                                              city,
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                })
                            ) : new Array(metaData?.totalPages).fill(undefined)
                                .length > 3 &&
                              tableIndex + 1 <
                                new Array(metaData?.totalPages).fill(undefined)
                                  .length -
                                  1 ? (
                              <>
                                {btnNo?.map((item, i) => {
                                  return (
                                    <button
                                      key={i}
                                      className={
                                        item === tableIndex + 1
                                          ? "pagBTN active"
                                          : "pagBTN"
                                      }
                                      onClick={() => {
                                        handleChange(item);
                                        dispatch(
                                          getCompanyList({
                                            page: item - 1,
                                            limit: 5,
                                            sort:"desc",
                                            search,
                                            send: {
                                              categories: category,
                                              tags: "",
                                              city,
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      {item}
                                    </button>
                                  );
                                })}{" "}
                                <span className="page-separation">...</span>
                                <button
                                  className={
                                    tableIndex ===
                                    new Array(metaData?.totalPages).fill(
                                      undefined
                                    ).length -
                                      1
                                      ? "pagBTN active"
                                      : "pagBTN"
                                  }
                                  onClick={handleLastBtn}
                                >
                                  {
                                    new Array(metaData?.totalPages).fill(
                                      undefined
                                    ).length
                                  }
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className={
                                    tableIndex === 0
                                      ? "pagBTN active"
                                      : "pagBTN"
                                  }
                                  onClick={handleFirstBtn}
                                >
                                  1
                                </button>{" "}
                                <span className="page-separation">...</span>
                                {[
                                  new Array(metaData?.totalPages).fill(
                                    undefined
                                  ).length - 2,
                                  new Array(metaData?.totalPages).fill(
                                    undefined
                                  ).length - 1,
                                  new Array(metaData?.totalPages).fill(
                                    undefined
                                  ).length,
                                ]?.map((item, i) => {
                                  return (
                                    <button
                                      key={i}
                                      className={
                                        item === tableIndex + 1
                                          ? "pagBTN active"
                                          : "pagBTN tow"
                                      }
                                      onClick={() => {
                                        handleChange(item);
                                        dispatch(
                                          getCompanyList({
                                            page: item - 1,
                                            limit: 5,
                                            sort:"desc",
                                            search,
                                            send: {
                                              categories: category,
                                              tags: "",
                                              city,
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      {item}
                                    </button>
                                  );
                                })}{" "}
                              </>
                            )}
                            <button
                              className="pagBTN  flex-center"
                              onClick={() => {
                                if (
                                  tableIndex <
                                  new Array(metaData?.totalPages).fill(
                                    undefined
                                  ).length -
                                    1
                                ) {
                                  dispatch(
                                    getCompanyList({
                                      page: tableIndex + 1,
                                      limit: 5,
                                      sort:"desc",
                                      search,
                                      send: {
                                        categories: category,
                                        tags: "",
                                        city,
                                      },
                                    })
                                  );
                                  handleChange(tableIndex + 2);
                                }
                                // handleChange(tableIndex + 2);
                              }}
                            >
                              <span className="material-symbols-outlined small">
                                east
                              </span>
                            </button>
                          </div>
                        </div> */}
                        <Pagination
                          sliceName={"bNs"}
                          search={search}
                          filter={category}
                          api={getCompanyList}
                          limit={5}
                          tableIndex={tableIndex}
                          setTableIndex={setTableIndex}
                          btnNo={btnNo}
                          setBtnNo={setBtnNo}
                          send={{ categories: category, city }}
                          callbackFunction={() => {
                            setSelectAllProducts(false)
                            setCollectAllProducts([])
                          }}
                        />
                      </>
                    ) : (
                      <DataNotFound />
                    )}{" "}
                  </>
                )}
              </div>
            </div>
            <Footer />
          </div>
          {isEventCard && (
            <ConformationPopup
              customState={isEventCard}
              customSetter={setIsEventCard}
              mainTitle=''
              subTitle=''
              api={deleteCompanyAsync}
              popUpData={popUpData}
              customFunction={() => {
                setSelectAllProducts(false)
                setCollectAllProducts([])
                // dispatch(
                //   setPageDetails({
                //     ...pageDetails,
                //     btnNo: [1, 2, 3],
                //   }),
                // )
              }}
              toastMessage={"Company removed successfully"}
            />
          )}{" "}
          {deleteAllPopup && (
            <ConformationPopup
              customState={deleteAllPopup}
              customSetter={setDeleteAllPopup}
            />
          )}{" "}
        </div>
      </div>
    </div>
  )
}

export default Buyers_Suppliers

const PopupSelector = ({ customSetter }) => {
  const [action, setAction] = useState(false)
  const [addStyle, setAddStyle] = useState(false)

  return (
    <div
      className='mch2-action shakeAnimation'
      onClick={() => {
        handleAnimation(setAddStyle, addStyle, setAction, action)
      }}
    >
      <span className=''>Add Buyers/Suppliers</span>
      <span className='material-symbols-rounded medium'>expand_more</span>
      {action && (
        <PopupOptions
          addStyle={addStyle}
          customSetter={customSetter}
          setAction={setAction}
          action={action}
          setAddStyle={setAddStyle}
        />
      )}
    </div>
  )
}

const PopupOptions = ({
  addStyle,
  customSetter,
  setAddStyle,
  setAction,
  action,
}) => {
  const comDopRef = useRef()
  const [globalClick, setGlobalClick] = useState(false)

  useEffect(() => {
    const handleGlobalClick = event => {
      if (comDopRef?.current && !comDopRef?.current?.contains(event.target)) {
        handleAnimation(setAddStyle, addStyle, setAction, action)
      }
    }
    // document
    //   .querySelector(".magic-icon-search")
    //   .addEventListener("click", (e) => {
    //     e.target.classList.toggle("close");
    //   });
    document.addEventListener("click", handleGlobalClick)
    return () => {
      document.removeEventListener("click", handleGlobalClick)
    }
  }, [comDopRef])
  useEffect(() => {
    if (action) {
      setGlobalClick(true)
    }
  }, [])
  return (
    <div
      className={
        "action-tooltip w-full" +
        (addStyle
          ? " show_component-common-animation"
          : " hide_component-common-animation ")
      }
      ref={action && globalClick ? comDopRef : null}
    >
      <span className='' onClick={() => customSetter(1)}>
        Add Single Company
      </span>
      <span className='' onClick={() => customSetter(2)}>
        Add Multiple Company
      </span>
    </div>
  )
}
