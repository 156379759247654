import { createSlice } from "@reduxjs/toolkit"
import { getSalesCompanyAddress } from "./salesApi"

const salesSlice = createSlice({
    name: "sales",
    initialState: {
        salesLoading: false,
        buyerAddress: [],
        buyerGst: "",
        buyerWarehouseAddress: {},
    },
    reducers: {
        setBuyerWarehouseAddress: (state, { payload }) => {
            state.buyerWarehouseAddress = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getSalesCompanyAddress.pending, (state, { payload }) => {
                state.salesLoading = true
            })
            .addCase(getSalesCompanyAddress.fulfilled, (state, { payload }) => {

                state.salesLoading = false
                state.buyerAddress = payload.data
                state.buyerGst = payload.gst
            })
            .addCase(getSalesCompanyAddress.rejected, state => {
                state.salesLoading = false
            })
    }
})

export const { salesLoading, setBuyerWarehouseAddress } = salesSlice.actions
export default salesSlice.reducer