import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/BreadCrumbs"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import MinimizedSidebar from "../../../components/MinimizedSidebar"
import MobileSidebar from "../../../components/MobileSidebar"
import Sidebar from "../../../components/Sidebar"
import FilterBox from "../../../components/FilterBox"
import SearchBox from "../../../components/SearchBox"
import Skelaton from "../../../components/Skelaton"
import ApprovalPopup from "../components/approvalPopup"
import ApprovalForm from "../components/ApprovalForm"

const Approval = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Approvals")
  }, [setTitle])

  const { privileges, pageDetails } = useSelector(
    state => state.auth,
    shallowEqual,
  )

  const [active, setActive] = useState("approvals")
  const [MinimizeSidebar, setMinimizeSidebar] = useState(false)
  const [MbSidebar, setMbsidebar] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [btnNo, setBtnNo] = useState([1, 2, 3])
  const [isEventCard, setIsEventCard] = useState(false)
  const [initialRenderAnimation, setInitialRenderAnimation] = useState(false)
  const [approvalPopupForm, setApprovalPopupForm] = useState({ data: {}, status: false })

  const { inventoryList } = useSelector(state => state.inventory)

  const [checkedCollection, setCheckedCollection] = useState([])
  const [filterTable, setFilterTable] = useState({
    company_name: true,
    approval_status: true,
    creation_status: true,
    created_by: true,
    create_date: true,
    user_assigned: true,
    action_by: true,
    delivery_date: true,
    total: true,
  })
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const { inventoryLoading, metaData } = useSelector(store => store.inventory)
  const [searchShow, setSearchShow] = useState(false)
  const [activePo, setActivePo] = useState(1)

  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0,
  )

  const navigate = useNavigate()
  const containerRef = useRef(null)
  const heightAuto = MinimizeSidebar ? { height: "auto" } : null

  const records = 5
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [check, setCheck] = useState(false)
  const [search, setSearch] = useState(
    pageDetails?.search ? pageDetails?.search : "",
  )

  //   useEffect(() => {
  //     if (searchTimeout || check) {
  //       clearTimeout(searchTimeout)
  //     } else {
  //       dispatch(
  //         getInventoryList({
  //           page: pageDetails?.page ? pageDetails?.page : 0,
  //           limit: records,
  //           search:
  //             Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
  //           sort: "desc",
  //         }),
  //       )
  //       setCheck(true)
  //       dispatch(setPageDetails({ page: metaData?.page, search }))
  //       return
  //     }

  //     const timeoutId = setTimeout(async () => {
  //       dispatch(
  //         getInventoryList({ limit: records, page: 0, search, sort: "desc" }),
  //       )
  //       setTableIndex(0)
  //       setBtnNo([1, 2, 3])
  //     }, 1000)
  //     dispatch(setPageDetails({ page: metaData?.page, search }))
  //     setSearchTimeout(timeoutId)
  //   }, [dispatch, records, search])

  //   useEffect(() => {
  //     dispatch(setPageDetails({ page: metaData?.page, search }))
  //   }, [metaData])

  const [tableLoading, setTableLoading] = useState(false)
  const [tableCount, setTableCount] = useState(null)
  const [isStatuspopup, setIsStatuspopup] = useState(false)

  useEffect(() => {
    if (tableCount) {
      clearTimeout(tableCount)
    }
    const count = setTimeout(() => {
      setTableLoading(inventoryLoading)
    }, 1000)
    setTableCount(count)
  }, [inventoryLoading])
  const handleNavToggle = () => {
    if (!initialRenderAnimation) {
      setInitialRenderAnimation(true)
    }
    setMinimizeSidebar(!MinimizeSidebar)
  }

  // Data for Purchase Orders
  const approvalsData = [
    {
      doc_number: "O-007845",
      company_name: "Bmo Paint",
      approval_status: "Rejected",
      created_by: "Allen J. Baier",
      create_date: "13/03/24, 12:00 AM",
      user_assigned: "John Jacob",
      action_by: "John Jacob",
      delivery_date: "13/03/24",
      total: "15,500",
      approval_note: "Lorem ipsum dolor sit amet consectetur. Ipsum orci dictum cursus sit nunc praesent. Leo mi lacus lectus nunc mauris a. Posuere proin ultricies quis rutrum mollis urna. Cursus leo posuere in proin scelerisque elit ornare erat est."
    },
    {
      doc_number: "O-007845",
      company_name: "Bmo Paint",
      approval_status: "Approved",
      created_by: "Allen J. Baier",
      create_date: "13/03/24, 12:00 AM",
      user_assigned: "John Jacob",
      action_by: "John Jacob",
      delivery_date: "13/03/24",
      total: "15,500",
      approval_note: "Lorem ipsu mas ldknas dasd asd asd asd asd adasdaasd t. Leo mi lacus lectus nunc mauris a. Posuere proin ultricies quis rutrum mollis urna. Cursus leo posuere in proin scelerisque elit ornare erat est."
    },
  ]

  return (
    <div className='container' style={!isEventCard ? heightAuto : null}>
      <div className='app-container'>
        {isStatuspopup && <ApprovalPopup setIsStatuspopup={setIsStatuspopup} />}
        <div className='home-container'>
          {
            approvalPopupForm.status === true && <ApprovalForm approvalPopupForm={approvalPopupForm} setApprovalPopupForm={setApprovalPopupForm} />
          }
          {!MinimizeSidebar ? (
            <div
              className={
                "sidebar-container" +
                (initialRenderAnimation ? " slide_in-ani" : "")
              }
            >
              <Sidebar
                active={active}
                setActive={setActive}
                setMinimizeSidebar={setMinimizeSidebar}
                setMbsidebar={setMbsidebar}
                MinimizeSidebar={MinimizeSidebar}
                initialRenderAnimation={initialRenderAnimation}
              />

              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_back_ios
                </span>
              </div>
            </div>
          ) : (
            <div className={"minisidebar-container"}>
              <MinimizedSidebar
                active={active}
                setActive={setActive}
                initialRenderAnimation={initialRenderAnimation}
              />
              <div
                className='sidebar-expender'
                onClick={() => {
                  handleNavToggle()
                  setMinimizeSidebar(!MinimizeSidebar)
                }}
              >
                <span className='material-symbols-outlined expender-icon'>
                  arrow_forward_ios
                </span>
              </div>
            </div>
          )}
          {MbSidebar && (
            <MobileSidebar
              setMbsidebar={setMbsidebar}
              MbSidebar={MbSidebar}
              active={active}
              setActive={setActive}
            />
          )}
          <div
            className={
              MinimizeSidebar ? "main-container expand" : "main-container"
            }
            ref={containerRef}
          >
            <div className='main-header'>
              <Header
                MinimizeSidebar={MinimizeSidebar}
                setMinimizeSidebar={setMinimizeSidebar}
                showChat={showChat}
                setShowChat={setShowChat}
                setMbsidebar={setMbsidebar}
                initialRenderAnimation={initialRenderAnimation}
                setInitialRenderAnimation={setInitialRenderAnimation}
              />
            </div>
            <div className='main-content'>
              <div className='mc-header'>
                <div className='mch1'>
                  <div className='mch1-title'>Approvals</div>
                  <div className='mch-path'>
                    <Breadcrumb />
                  </div>
                </div>
              </div>
              <div className='data-table-container '>
                <div className='col1 heading-tools'>
                  <h3>Approval List</h3>
                  <div className='table-search'>
                    <div className='search-container'>
                      <div className='search-filter-flex'>
                        <SearchBox
                          customSetter={setSearch}
                          customState={search}
                        />

                        <FilterBox
                          customState={filterTable}
                          customSetter={setFilterTable}
                          customPosition={{ right: "0", left: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {tableLoading ? (
                  <Skelaton col={6} row={approvalsData?.length} />
                ) : approvalsData?.length > 0 ? (
                  <>
                    <div className='data-table-box scrollbar-visible'>
                      <table className='data-table '>
                        <thead>
                          <tr>
                            <th className='tb-head-name'>#</th>
                            <th>Document Number</th>
                            {filterTable.company_name ? (
                              <th>Company Name</th>
                            ) : null}
                            {filterTable.approval_status ? (
                              <th>Approval Status</th>
                            ) : null}
                            {filterTable?.created_by ? (
                              <th>Created By</th>
                            ) : null}

                            {filterTable.create_date ? (
                              <th>Creation Date</th>
                            ) : null}
                            <th>User Assigned</th>
                            <th>Action By</th>
                            <th>Delivery Date</th>
                            <th>Total Amount</th>
                            {Object.values(
                              privileges["inventory_management"],
                            ).includes("write") && <th>View Note</th>}
                            {Object.values(
                              privileges["inventory_management"],
                            ).includes("write") && <th>Action Tab</th>}
                          </tr>
                        </thead>

                        <tbody>
                          {approvalsData?.map((item, index) => {
                            const {
                              doc_number,
                              po_number,
                              company_name,
                              approval_status,
                              created_by,
                              create_date,
                              user_assigned,
                              action_by,
                              delivery_date,
                              total,
                            } = item
                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  //   navigate(`/inventory/${_id}`)
                                }}
                                className='navig-table-row'
                                style={{ cursor: "pointer" }}
                              >
                                <td className='tb_data padding-07'>
                                  {/* {num} */}
                                  {index + 1}
                                  {"."}
                                </td>
                                <td className='product_nav'>
                                  {doc_number}{" "}
                                  <span className='navIcon' onClick={() =>
                                    navigate("/approvals/approval-details")
                                  }>
                                    <i className='fa-regular fa-arrow-up-right-from-square'></i>
                                  </span>{" "}
                                </td>

                                {filterTable.company_name ? (
                                  <td>{company_name}</td>
                                ) : null}
                                {filterTable.approval_status ? (
                                  <td className='tb_data status-column'>
                                    <span>{approval_status}</span>
                                  </td>
                                ) : null}

                                {filterTable.created_by ? (
                                  <td>{created_by}</td>
                                ) : null}
                                {filterTable.create_date ? (
                                  <td>{create_date}</td>
                                ) : null}
                                {filterTable.user_assigned ? (
                                  <td>{user_assigned}</td>
                                ) : null}
                                {filterTable.action_by ? (
                                  <td>{action_by}</td>
                                ) : null}
                                {filterTable.delivery_date ? (
                                  <td>{delivery_date}</td>
                                ) : null}
                                {filterTable.total ? <td>{total}</td> : null}

                                {Object.values(
                                  privileges["production_management"],
                                ).includes("write") && (
                                    <td>
                                      <button
                                        className='icon-btn  see'
                                      >
                                        <span className='material-symbols-outlined medium'>
                                          visibility
                                        </span>
                                      </button>
                                    </td>
                                  )}
                                {Object.values(
                                  privileges["production_management"],
                                ).includes("write") && (
                                    <td>
                                      <button
                                        className='icon-btn '
                                        onClick={() => setApprovalPopupForm({ data: item, status: true })}
                                      >
                                        <span className='material-symbols-outlined medium'>
                                          edit
                                        </span>
                                      </button>
                                    </td>
                                  )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                    {/* <Pagination
                      sliceName={"inventory"}
                      search={search}
                      filter={"desc"}
                      api={getInventoryList}
                      limit={5}
                      tableIndex={tableIndex}
                      setTableIndex={setTableIndex}
                      btnNo={btnNo}
                      setBtnNo={setBtnNo}
                    /> */}
                  </>
                ) : (
                  <div className='Nodata-txt'>
                    <img
                      src={require("../../../assets/images/nodata.svg").default}
                      alt=''
                      srcSet=''
                    />
                    No Data Found
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Approval
