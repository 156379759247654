import { combineReducers } from "@reduxjs/toolkit"
import authSlice from "../modules/auth/redux/authSlice"
import bNsSlice from "../modules/buyers&suppliers/redux/bNsSlice"
import Catalogue from "../modules/catalogue/redux/ProductCatalogueSlice"
import companySlice from "../modules/company/redux/companySlice"
import dashboardSlice from "../modules/dashboard/redux/dashboardSlice"
import DepartmentSlice from "../modules/department/redux/DepartmentSlice"
import emailSlice from "../modules/email/redux/Emailslice"
import InventorySlice from "../modules/inventory/redux/InventorySlice"
import stockManagementSlice from "../modules/inventory/redux/stockManagementSlice"
import OrderSlice from "../modules/order/redux/OrderSlice"
import ProductsSlice from "../modules/products/redux/ProductsSlice"
import profileSlice from "../modules/profile/redux/profileSlice"
import PurchaseSlice from "../modules/purchase/redux/PurchaseSlice"
import userSlice from "../modules/user/redux/userSlice"
import paymentSlice from "../modules/payments/redux/paymentSlice"
import salesSlice from "../modules/sales/redux/salesSlice"

const rootReducer = combineReducers({
  auth: authSlice,
  profile: profileSlice,
  bNs: bNsSlice,
  company: companySlice,
  email: emailSlice,
  user: userSlice,
  dashboard: dashboardSlice,
  department: DepartmentSlice,
  products: ProductsSlice,
  inventory: InventorySlice,
  catalogue: Catalogue,
  stock: stockManagementSlice,
  purchase: PurchaseSlice,
  order: OrderSlice,
  payment: paymentSlice,
  sales: salesSlice
})

export default rootReducer
