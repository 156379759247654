import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react"
import { ClipLoader } from "react-spinners"
import { useFormik } from "formik"
import * as Yup from "yup"
import { updateMoreOptions } from "../redux/ProductCatalogueApi"
import { getOptionsListForTable } from "../../products/redux/ProductsApi"
import { capitalizeInput } from "../../../helper/helper"

const UpdateColorForm = ({ customState, customSetter }) => {
  // const { catalogueLoading } = useSelector(store => store.catalogue)
  const [addStyle, setAddStyle] = useState(true)
  const [focusedField, setFocusedField] = useState()
  const dispatch = useDispatch()

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      colorCode: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(3, "Color name must be at least 3 characters long")
        .max(30, "Color name must be less than 30 characters")
        .required("Please enter Color name "),
      colorCode: Yup.string()
        .trim()
        .max(30, "Color code must be less than 10 characters.")
        .required("Please enter Color code "),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        slug: "update-color",
        id: customState?.popupData?._id,
        send: {
          name: values.name.trim(),
          code: values.colorCode.trim(),
        },
      }
      const response = await dispatch(updateMoreOptions(data))
      if (response.payload) {
        dispatch(
          getOptionsListForTable({
            listName: "color-list",
            page: 0,
            limit: 5,
            search: "",
            sort: "desc",
          }),
        )
        handleClick()
      }
    },
  })
  function chatBoxVisibility() {
    if (customState.edit) {
      const styleTimer = setTimeout(() => {
        customSetter({ ...customState, edit: false })
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
      }, 200)
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
    formik.setValues({
      name: customState?.popupData?.name,
      colorCode: customState?.popupData?.code,
    })
  }, [])

  return (
    <div
      id='delete-card-container'
      className={`${
        addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation"
      }`}
    >
      <div
        className={
          "event-card" +
          (addStyle
            ? " show_delete-component-animation"
            : " hide_delete-component-animation")
        }
      >
        <h2>Update Color</h2>

        <div className='modelEx_content'>
          <div className='modelEx_content'>
            <form
              className='editbox-form flex-center'
              onSubmit={formik.handleSubmit}
            >
              <div className='form-group'>
                <div
                  className={`input-field ${
                    formik.values.name !== "" || focusedField === "name"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='name' className='toggle-label'>
                    Color Name
                  </label>
                  <input
                    id='name'
                    name='name'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    onInput={capitalizeInput}
                    maxLength={30}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <span className='err'>{formik.errors.name}</span>
                  ) : null}
                </div>
              </div>

              <div className='form-group'>
                <div
                  className={`input-field ${
                    formik.values.colorCode !== "" ||
                    focusedField === "colorCode"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='colorCode' className='toggle-label'>
                    Color Code
                  </label>
                  <input
                    id='colorCode'
                    name='colorCode'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.colorCode}
                    // onInput={e => {
                    //   e.target.value = e.target.value
                    //     .replace(/[^A-Za-z ]/gi, "")
                    //     .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                    // }}
                    maxLength={30}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("colorCode")}
                  />
                  {formik.touched.colorCode && formik.errors.colorCode ? (
                    <span className='err'>{formik.errors.colorCode}</span>
                  ) : null}
                </div>
              </div>

              <div className='addPopup-btn-box'>
                <button
                  type='button'
                  className='btn-secondary'
                  onClick={handleClick}
                >
                  Abort
                </button>
                <button
                  className='btn-primary'
                  type='submit'
                  // style={
                  //   catalogueLoading
                  //     ? {
                  //         cursor: "not-allowed",
                  //         // background: "gray",
                  //       }
                  //     : {}
                  // }
                  // disabled={catalogueLoading}
                >
                  {/* {catalogueLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "SUBMIT"
                )} */}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateColorForm
