/* eslint-disable no-useless-escape */
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import DropDownBox from "../../../components/DropDownBox"
import { useDispatch, useSelector } from "react-redux"
import { AddSingleCompany } from "../redux/bNsApi"
import { getLocalityDetail } from "../../auth/redux/authApi"
import { ClipLoader } from "react-spinners"
import FormButtons from "../../../components/SubmitBtns"
import SearchDropDown from "../../../components/SearchDropDown"
import {
  convertRupeesToNumber,
  numberWithCommas,
  priceValidator,
  removePhoneNoCountryCode,
} from "../../../helper/helper"
import { getUserList } from "../../user/redux/userApi"
import SearchBox from "../../../components/SearchBox"
import SearchUser from "./SearchUser"

const AddSingleCom = ({ popup, setPopup }) => {
  const dispatch = useDispatch()

  // const [activeInputs, setActiveInputs] = useState(false);
  // const [gstn, setGstn] = useState(false);
  const [addStyle, setAddStyle] = useState(true)
  const [focusedField, setFocusedField] = useState()
  const [checkbox, setCheckbox] = useState(false)
  const { companyLoading } = useSelector(store => store.bNs)
  const initialValues = {
    name: "",
    email: "",
    mobile_no: "",
    company_name: "",
    company_ref_id: "",
    company_email: "",
    company_num: "",
    gst_number: "",
    company_role: "",
    address_line_one: "",
    address_line_two: "",
    pinCode: "",
    city: "",
    state: "",
    handlerName: "",
    handlerPhone: "",
    handlerEmail: "",
    creditLimit: "",
    payDate: "",
    // gst_type: "",
    // tags: "",
  }

  // const gstnREGEX =
  //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

  // function gstInput(e) {
  //   const num = (e.target.value = e.target.value.slice(0, 15));
  //   setGstn(e.target.value.length === 15 ? true : false);
  //   return num;
  // }
  const singleComSchema = Yup.object({
    name: Yup.string()
      .trim()
      .min(3, "Full Name must be at least 3 characters long")
      .max(80, "Full Name must be less than 80 characters")
      // .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Name is required"),
    email: Yup.string()
      .trim()
      .email("Please provide valid email")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please!",
      )
      .test("fakeMail", "Please provide valid email", function (value) {
        if (
          value.includes("@yopmail.") ||
          value.includes("@mywrld.") ||
          value.includes("@nicoric.") ||
          value.includes("@huleos.") ||
          value.includes("@guerrillamail.") ||
          value.includes("@maildrop.")
        ) {
          return false
        } else {
          return true
        }
      })
      .required("Email address should not be empty"),
    mobile_no: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
      .required("Phone number is required"),
    handlerName: Yup.string()
      .trim()
      .min(3, "Full Name must be at least 3 characters long")
      .max(80, "Full Name must be less than 80 characters"),
    // .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
    // .required("Name is required"),
    handlerEmail: Yup.string()
      .trim()
      .email("Please provide valid email")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please!",
      )
      .matches(
        "@poonamcoatings.com",
        "Email must contain @poonamcoatings.com domain",
      ),
    // .required("Email address should not be empty"),
    handlerPhone: Yup.string().matches(
      /^[6-9]\d{9}$/,
      "Please enter a valid phone number",
    ),
    // .required("Phone number is required"),
    company_name: Yup.string()
      .trim()
      .min(3, "Company name must be at least 3 characters long")
      .max(80, "Name must be less than 80 characters")
      .required("Company name is required"),
    company_role: Yup.string().trim().required("Company Role is required"),
    company_ref_id: Yup.string()
      .trim()
      .max(15, "Reference ID must be less than 15 characters"),
    // .matches(/[A-Z]/, "Reference ID must be in uppercase")
    // .required("Reference ID name is required"),
    company_email: Yup.string()
      .email("Please provide valid email")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please!",
      )
      .required("Email address should not be empty")
      .test("fakeMail", "Please provide valid email", function (value) {
        if (
          value.includes("@yopmail.") ||
          value.includes("@mywrld.") ||
          value.includes("@nicoric.") ||
          value.includes("@huleos.") ||
          value.includes("@guerrillamail.") ||
          value.includes("@maildrop.")
        ) {
          return false
        } else {
          return true
        }
      }),
    company_num: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
      .required("Phone number is required"),
    // gst_type: Yup.string()
    //   .trim()
    //   .matches(/^[A-Za-z]+$/, "Please enter a valid name")
    //   .required("GST Type is required"),
    gst_number: Yup.string()
      .min(15, "GST number is less than 15")
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Not matched with the valid schema, ensure that the GST number is in uppercase",
      )
      .required("Please enter valid GST number"),
    address_line_one: Yup.string()
      .trim()
      .min(3, "Address must be at least 3 characters long")
      .max(150, "Address must be less than 150 characters")
      .required("Address is required"),
    address_line_two: Yup.string()
      .trim()
      .min(3, "Address must be at least 3 characters long")
      .max(150, "Address must be less than 150 characters"),
    // .required("Address is required"),
    pinCode: Yup.string()
      .min(6, "Enter valid pincode please!")
      .matches(/^[1-9][0-9]{5}$/, "Enter a valid pincode")
      .required("Pincode is required"),
    city: Yup.string().required("Please enter valid city name"),
    state: Yup.string().required("Please enter valid state name"),
    // tags: Yup.string().required("Please enter valid"),
    creditLimit: Yup.string().matches(/[\d]/),
    // .required("Cost price is required")
    payDate: Yup.string(),
    // .required("Pay Date is required").matches(/[\d]/),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: singleComSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        contactPersonName: values.name.trim(),
        name: values.company_name,
        contact_email: values.email,
        contact_phone: `+91${values.mobile_no}`,
        phone: `+91${values.company_num}`,
        reference_id: values.company_ref_id,
        email: values.company_email,
        gstNumber: values.gst_number,
        buyerAndSupplier: values.company_role,
        address_line_one: values.address_line_one,
        address_line_two: values.address_line_two,
        city: values.city,
        state: values.state,
        pin_code: values.pinCode,
        checked: checkbox,
        creditLimit: convertRupeesToNumber(values.creditLimit),
        validity: values.payDate,
        salesPersonId: values.handlerName,
        salesPersonEmail: values.handlerEmail,
        salesPersonPhone: values.handlerPhone,
      }

      const response = await dispatch(AddSingleCompany(data))

      if (response?.payload) {
        resetForm()
        handleClick()
      }
    },
  })

  const hitPincodeApi = async value => {
    const sendData = {
      pin_code: value,
    }

    const response = await dispatch(getLocalityDetail(sendData))

    if (response?.payload) {
      formik.setValues({
        ...formik.values,
        pinCode: value,
        city: response?.payload?.data?.data?.city_name,
        state: response?.payload?.data?.data?.state_name,
      })
    }
  }

  const handleClick = e => {
    setAddStyle(!addStyle)
    chatBoxVisibility()
    // setShowChat(false);
  }

  function chatBoxVisibility() {
    if (popup === 1) {
      const styleTimer = setTimeout(() => {
        setPopup(false)
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container")
        clearTimeout(styleTimer)
        clearTimeout(styleTimer)
      }, 200)
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container")
  }, [])

  return (
    <div
      className={
        "mainExelPop" +
        (addStyle ? " simple-fade-in-animation" : " simple-fade-out-animation")
      }
    >
      <div
        className={
          "modelExel" +
          (addStyle
            ? " show_component-common-animation"
            : " hide_component-common-animation")
        }
      >
        <div className='modelEx_header'>
          <h2>Add new Company</h2>
          <button className='exit-btn' onClick={() => handleClick()}>
            <span className='material-symbols-outlined medium'>close</span>
          </button>
        </div>

        <div className='modelEx_content singleAdd'>
          <form className='scrollForm' onSubmit={formik.handleSubmit}>
            <div className='form-content'>
              <h3 className='title'>Contact Person Info*</h3>
              <div className='form-group contact-info'>
                <div
                  className={`input-field ${
                    formik.values.name !== "" || focusedField === "first_name"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='name' className='toggle-label'>
                    Full Name
                  </label>
                  <input
                    type='text'
                    name='name'
                    id='name'
                    className='form-input'
                    maxLength={81}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onInput={e => {
                      e.target.value = e.target.value
                        .replace(/[^A-Za-z ]/gi, "")
                        .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                    }}
                    // onPaste={(e) => {
                    //   e.preventDefault();
                    // }}
                    // onCopy={(e) => {
                    //   e.preventDefault();
                    // }}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("first_name")}
                  />
                  {formik.errors.name && formik.touched.name ? (
                    <span className='err'>{formik.errors.name}</span>
                  ) : null}
                </div>
                <div
                  className={`input-field ${
                    formik.values.mobile_no !== "" || focusedField === "mobile"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='mobile_no' className='toggle-label'>
                    Phone
                  </label>
                  <div className={formik.values.mobile_no ? "phone_box" : ""}>
                    {
                      <span className='phone_code'>
                        {formik.values.mobile_no ? "+91" : ""}
                      </span>
                    }
                    <input
                      type='text'
                      name='mobile_no'
                      id='mobile_no'
                      className='form-input'
                      value={formik.values.mobile_no}
                      onChange={formik.handleChange}
                      onInput={e => {
                        e.target.value = e.target.value
                          .slice(0, 10)
                          .replace(/\D/g, "")
                      }}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("mobile")}
                    />
                  </div>{" "}
                  {formik.errors.mobile_no && formik.touched.mobile_no ? (
                    <span className='err'>{formik.errors.mobile_no}</span>
                  ) : null}
                </div>
                <div
                  className={`input-field ${
                    formik.values.email !== "" || focusedField === "email"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='email' className='toggle-label'>
                    Email
                  </label>
                  <input
                    type='email'
                    name='email'
                    id='email'
                    className='form-input'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onKeyDown={e => {
                      if (e.key === " ") {
                        e.preventDefault()
                      }
                    }}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("email")}
                  ></input>
                  {formik.errors.email && formik.touched.email ? (
                    <span className='err'>{formik.errors.email}</span>
                  ) : null}
                </div>
              </div>

              <h3 className='title'>Company Details*</h3>
              <div className='form-group form'>
                <div
                  className={`input-field ${
                    formik.values.company_name !== "" ||
                    focusedField === "company_name"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='company_name' className='toggle-label'>
                    Name of Company
                  </label>
                  <input
                    id='company_name'
                    name='company_name'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.company_name}
                    onInput={e => {
                      e.target.value = e.target.value.replace(
                        /(?:^|\s)\S/g,
                        match => match.toUpperCase(),
                      )
                    }}
                    maxLength={81}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("company_name")}
                  />
                  {formik.touched.company_name && formik.errors.company_name ? (
                    <span className='err'>{formik.errors.company_name}</span>
                  ) : null}
                </div>
                <div
                  className={`input-field ${
                    formik.values.company_ref_id !== "" ||
                    focusedField === "company_ref_id"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='company_ref_id' className='toggle-label'>
                    Customer ID
                  </label>
                  <input
                    id='company_ref_id'
                    name='company_ref_id'
                    type='text'
                    className='form-input'
                    onChange={formik.handleChange}
                    value={formik.values.company_ref_id}
                    // onInput={(e) => {
                    //   e.target.value = e.target.value.replace(
                    //     /(?:^|\s)\S/g,
                    //     (match) => match.toUpperCase()
                    //   );
                    // }}
                    maxLength={15}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("company_ref_id")}
                    onKeyDown={e => {
                      if (e.key === " ") {
                        e.preventDefault()
                      }
                    }}
                  />
                  {formik.touched.company_ref_id &&
                  formik.errors.company_ref_id ? (
                    <span className='err'>{formik.errors.company_ref_id}</span>
                  ) : null}
                </div>
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.company_email !== "" ||
                      focusedField === "company_email"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='email' className='toggle-label'>
                      Official Email Address
                    </label>
                    <input
                      id='company_email'
                      name='company_email'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.company_email}
                      // maxLength={80}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("company_email")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                    />
                    {formik.touched.company_email &&
                    formik.errors.company_email ? (
                      <span className='err'>{formik.errors.company_email}</span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.company_num !== "" ||
                      focusedField === "company_num"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='company_num' className='toggle-label'>
                      Company Contact No.
                    </label>
                    <div
                      className={formik.values.company_num ? "phone_box" : ""}
                    >
                      {
                        <span className='phone_code'>
                          {formik.values.company_num ? "+91" : ""}
                        </span>
                      }{" "}
                      <input
                        id='company_num'
                        name='company_num'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.company_num}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 10)
                            .replace(/\D/g, "")
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("company_num")}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                      />
                    </div>{" "}
                    {formik.touched.company_num && formik.errors.company_num ? (
                      <span className='err'>{formik.errors.company_num}</span>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-2"> */}
                {/* <div
                  className={`input-field ${
                    formik.values.gst_type !== "" || focusedField === "gst_type"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor="email" className="toggle-label">
                    GST Type
                  </label>
                  <input
                    id="gst_type"
                    name="gst_type"
                    type="text"
                    className="form-input"
                    onChange={formik.handleChange}
                    value={formik.values.gst_type}
                    maxLength={40}
                    onBlur={(e) => {
                      setFocusedField("");
                      formik.handleBlur(e);
                    }}
                    onFocus={() => setFocusedField("gst_type")}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div> */}
                {/* <div className="input-field">
                  <DropDownBox
                    options={[
                      { label: "Regular", value: "regular" },
                      { label: "Composition", value: "composition" },
                    ]}
                    defaultValue="GST Scheme"
                    customSetter={formik.setFieldValue}
                    customLabel="gst_type"
                    customDropBoxStyles={{
                      border: "1px solid #d7cece",
                      borderRadius: "0.5rem",
                      color: "#333",
                    }}
                    customTextStyle={{ color: "#212229", opacity: "0.7" }}
                  />
                  {formik.touched.gst_type && formik.errors.gst_type ? (
                    <span className="err">{formik.errors.gst_type}</span>
                  ) : null}
                </div> */}
                {/* <div> */}
                {/* <div className="col-2 gstn"> */}
                <div className='col-2'>
                  <div
                    className={`input-field ${
                      formik.values.gst_number !== "" ||
                      focusedField === "gstNum"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='gst_number' className='toggle-label'>
                      GST Number
                    </label>
                    <input
                      id='gst_number'
                      name='gst_number'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.gst_number}
                      onInput={e => {
                        e.target.value = e.target.value
                          .slice(0, 15)
                          .toUpperCase()
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("gstNum")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                    />
                    {formik.touched.gst_number && formik.errors.gst_number ? (
                      <span className='err'>{formik.errors.gst_number}</span>
                    ) : null}
                  </div>{" "}
                  <div className='input-field'>
                    <DropDownBox
                      options={[
                        { label: "Buyer", value: "Buyer" },
                        { label: "Supplier", value: "Supplier" },
                        { label: "Both", value: "Both" },
                      ]}
                      dropDownTitle='Company Type'
                      animateDropDownTitle
                      customSetter={formik.setFieldValue}
                      customFormikLabel={"company_role"}
                      customDropBoxStyles={{
                        border: "1px solid #d7cece",
                        borderRadius: "0.5rem",
                        color: "#333",
                      }}
                      customTextStyle={{ color: "#212229", opacity: "0.7" }}
                    />
                    {formik.touched.company_role &&
                    formik.errors.company_role ? (
                      <span className='err'>{formik.errors.company_role}</span>
                    ) : null}
                  </div>
                </div>
                {/* <button
                      type="button"
                      disabled={!gstn}
                      className={
                        gstn ? "fetch_details enabled" : "fetch_details"
                      }
                    >
                      Fetch Details
                    </button> */}
                {/* </div> */}
                {/* {activeInputs && (
                    <div
                      className={!gstn ? "gstnValidate" : "gstnValidate true"}
                    >
                      <span className="material-symbols-rounded small">
                        {!gstn ? "lightbulb" : "check_circle"}
                      </span>{" "}
                      {!gstn
                        ? "Verify the GST number to capture all the details automatically."
                        : "Unable to fetch details from GST portal. Please enter company details manually."}
                    </div>
                  )} */}
                {/* </div> */}
                {/* </div> */}
                {/* <div
                className={`input-field ${
                  formik.values.company_role !== "" ||
                  focusedField === "company_role"
                    ? "active"
                    : ""
                }`}
              >
                <label htmlFor="company_role" className="toggle-label">
                  Company Role
                </label>
                <input
                  id="company_role"
                  name="company_role"
                  type="text"
                  className="form-input"
                  maxLength={80}
                  onChange={formik.handleChange}
                  value={formik.values.company_role}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z ]/gi, "")
                      // .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                  }}
                  onBlur={(e) => {
                    setFocusedField("");
                    formik.handleBlur(e);
                  }}
                  onFocus={() => setFocusedField("company_role")}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                  }}
                />
              </div> */}
                <div className='col-2'>
                  {/* <div
                    className={`input-field ${
                      formik.values.creditLimit !== "" ||
                      focusedField === "creditLimit"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='creditLimit' className='toggle-label'>
                      Credit Limit
                    </label>
                    <div
                      className={formik.values.creditLimit ? "phone_box" : ""}
                    >
                      {
                        <span className='phone_code'>
                          {formik.values.creditLimit ? "₹" : ""}
                        </span>
                      }{" "}
                      <input
                        id='creditLimit'
                        name='creditLimit'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.creditLimit}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^\d ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        maxLength={12}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("creditLimit")}
                      />
                    </div>

                    {formik.touched.creditLimit && formik.errors.creditLimit ? (
                      <span className='err'>{formik.errors.creditLimit}</span>
                    ) : null}
                  </div>{" "} */}
                  <div
                    className={`input-field ${
                      formik.values.creditLimit !== "" ||
                      focusedField === "creditLimit"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='creditLimit' className='toggle-label'>
                      Credit Limit
                    </label>
                    <div
                      className={formik.values.creditLimit ? "phone_box" : ""}
                    >
                      {
                        <span className='phone_code'>
                          {formik.values.creditLimit ? "₹" : ""}
                        </span>
                      }{" "}
                      <input
                        id='creditLimit'
                        name='creditLimit'
                        type='text'
                        className='form-input'
                        onChange={e => {
                          formik.setFieldValue(
                            "creditLimit",
                            numberWithCommas(e.target.value),
                          )
                        }}
                        value={formik.values.creditLimit}
                        onInput={e => {
                          e.target.value = priceValidator(e.target.value)
                        }}
                        maxLength={12}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("creditLimit")}
                      />
                    </div>

                    {formik.touched.creditLimit && formik.errors.creditLimit ? (
                      <span className='err'>{formik.errors.creditLimit}</span>
                    ) : null}
                  </div>{" "}
                  <div
                    className={`input-field ${
                      formik.values.payDate !== "" || focusedField === "payDate"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='payDate' className='toggle-label'>
                      Pay Duration In days
                    </label>
                    <div
                      className={
                        formik.values.payDate ? "margin_percentage_box" : ""
                      }
                    >
                      {
                        <span className='m_percentage'>
                          {formik.values.payDate
                            ? Number(formik.values.payDate) > 1
                              ? "Days"
                              : "Day"
                            : ""}
                        </span>
                      }{" "}
                      <input
                        id='payDate'
                        name='payDate'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.payDate}
                        onInput={e => {
                          e.target.value = e.target.value
                            .replace(/[^\d ]/gi, "")
                            .replace(/(?:^|\s)\S/g, match =>
                              match.toUpperCase(),
                            )
                        }}
                        maxLength={3}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("payDate")}
                      />
                    </div>

                    {formik.touched.payDate && formik.errors.payDate ? (
                      <span className='err'>{formik.errors.payDate}</span>
                    ) : null}
                  </div>{" "}
                </div>
                <div
                  className={`input-field ${
                    formik.values.address_line_one !== "" ||
                    focusedField === "address1"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='address-line-one' className='toggle-label'>
                    Address Location 1
                  </label>
                  <input
                    id='address-line-one'
                    name='address_line_one'
                    type='text'
                    className='form-input'
                    maxLength={151}
                    onChange={formik.handleChange}
                    value={formik.values.address_line_one}
                    // onInput={(e) => {
                    // e.target.value = e.target.value;
                    // .replace(/[^A-Za-z ]/gi, "")
                    // .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                    // }}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("address1")}
                  />
                  {formik.touched.address_line_one &&
                  formik.errors.address_line_one ? (
                    <span className='err'>
                      {formik.errors.address_line_one}
                    </span>
                  ) : null}
                </div>
                <div
                  className={`input-field ${
                    formik.values.address_line_two !== "" ||
                    focusedField === "address2"
                      ? "active"
                      : ""
                  }`}
                >
                  <label htmlFor='address-line-one' className='toggle-label'>
                    Address Location 2
                  </label>
                  <input
                    id='address_line_two'
                    name='address_line_two'
                    type='text'
                    className='form-input'
                    value={formik.values.address_line_two}
                    onChange={formik.handleChange}
                    // onInput={(e) => {
                    //   e.target.value = e.target.value;
                    // .replace(/[^A-Za-z ]/gi, "")
                    // .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                    // }}
                    maxLength={151}
                    onBlur={e => {
                      setFocusedField("")
                      formik.handleBlur(e)
                    }}
                    onFocus={() => setFocusedField("address2")}
                  />
                  {formik.touched.address_line_two &&
                  formik.errors.address_line_two ? (
                    <span className='err'>
                      {formik.errors.address_line_two}
                    </span>
                  ) : null}
                </div>
                <div className='form-group contact-info'>
                  <div
                    className={`input-field ${
                      formik.values.pinCode !== "" || focusedField === "pinCode"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='pinCode' className='toggle-label'>
                      Pincode
                    </label>
                    <div className='pinCode_wrapper'>
                      <input
                        id='pinCode'
                        name='pinCode'
                        type='text'
                        className='form-input'
                        onChange={formik.handleChange}
                        value={formik.values.pinCode}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 6)
                            .replace(/\D/g, "")
                          if (e.target.value?.length === 6) {
                            hitPincodeApi(e.target.value)
                          } else {
                            formik.setFieldValue("city", "")
                            formik.setFieldValue("state", "")
                          }
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("pinCode")}
                        maxLength={6}
                      />
                      {companyLoading ? (
                        <span className='pinCode_spinner'>
                          <ClipLoader
                            loading={true}
                            size={15}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                            color='white'
                          />
                        </span>
                      ) : null}
                    </div>

                    {formik.touched.pinCode && formik.errors.pinCode ? (
                      <span className='err'>{formik.errors.pinCode}</span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.city !== "" || focusedField === "city"
                        ? "active"
                        : ""
                    }`}
                  >
                    {" "}
                    <label htmlFor='city' className='toggle-label'>
                      City
                    </label>
                    <input
                      id='city'
                      name='city'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      // maxLength={20}
                      onFocus={() => setFocusedField("city")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      disabled
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <span className='err'>{formik.errors.city}</span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.state !== "" || focusedField === "state"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='state' className='toggle-label'>
                      State
                    </label>
                    <input
                      id='state'
                      name='state'
                      type='text'
                      className='form-input'
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      // maxLength={20}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("state")}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      disabled
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <span className='err'>{formik.errors.state}</span>
                    ) : null}
                  </div>
                </div>{" "}
                <h3 className='title'>Sales Person Info*</h3>
                <div className='form-group contact-info'>
                  {/* <div
                    className={`input-field ${
                      formik.values.handlerName !== "" ||
                      focusedField === "handlerName"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='handlerName' className='toggle-label'>
                      Full Name
                    </label>
                    <input
                      type='text'
                      name='handlerName'
                      id='handlerName'
                      className='form-input'
                      maxLength={81}
                      value={formik.values.handlerName}
                      onChange={formik.handleChange}
                      onInput={e => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, match => match.toUpperCase())
                      }}
                      // onPaste={(e) => {
                      //   e.preventDefault();
                      // }}
                      // onCopy={(e) => {
                      //   e.preventDefault();
                      // }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("handlerName")}
                    />
                    {formik.errors.handlerName && formik.touched.handlerName ? (
                      <span className='err'>{formik.errors.handlerName}</span>
                    ) : null}
                  </div> */}
                  <div className='input-field'>
                    <SearchUser
                      label={"Full Name"}
                      name='handlerName'
                      customSetter={formik.setFieldValue}
                      api={getUserList}
                      // listName={"painttype-list"}
                      dynamicLabel={"fname"}
                      dynamicValue={"all"}
                      listName='userList'
                      customFunctionOnClick={value => {
                        formik.setFieldValue(
                          "handlerPhone",
                          removePhoneNoCountryCode(value.phone.value),
                        )
                        formik.setFieldValue("handlerEmail", value.email.value)
                        formik.setFieldValue("handlerName", value._id)
                      }}
                    />{" "}
                    {formik.touched.handlerName && formik.errors.handlerName ? (
                      <span className='err'>{formik.errors.handlerName}</span>
                    ) : null}
                  </div>

                  <div
                    className={`input-field ${
                      formik.values.handlerPhone !== "" ||
                      focusedField === "mobile"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='handlerPhone' className='toggle-label'>
                      Phone
                    </label>
                    <div
                      className={formik.values.handlerPhone ? "phone_box" : ""}
                    >
                      {
                        <span className='phone_code'>
                          {formik.values.handlerPhone ? "+91" : ""}
                        </span>
                      }
                      <input
                        type='text'
                        name='handlerPhone'
                        id='handlerPhone'
                        className='form-input'
                        value={formik.values.handlerPhone}
                        onChange={formik.handleChange}
                        onInput={e => {
                          e.target.value = e.target.value
                            .slice(0, 10)
                            .replace(/\D/g, "")
                        }}
                        onKeyDown={e => {
                          if (e.key === " ") {
                            e.preventDefault()
                          }
                        }}
                        onBlur={e => {
                          setFocusedField("")
                          formik.handleBlur(e)
                        }}
                        onFocus={() => setFocusedField("mobile")}
                        disabled
                      />
                    </div>{" "}
                    {formik.errors.handlerPhone &&
                    formik.touched.handlerPhone ? (
                      <span className='err'>{formik.errors.handlerPhone}</span>
                    ) : null}
                  </div>
                  <div
                    className={`input-field ${
                      formik.values.handlerEmail !== "" ||
                      focusedField === "handlerEmail"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor='handlerEmail' className='toggle-label'>
                      Email
                    </label>
                    <input
                      type='handlerEmail'
                      name='handlerEmail'
                      id='handlerEmail'
                      className='form-input'
                      value={formik.values.handlerEmail}
                      onChange={formik.handleChange}
                      onKeyDown={e => {
                        if (e.key === " ") {
                          e.preventDefault()
                        }
                      }}
                      onBlur={e => {
                        setFocusedField("")
                        formik.handleBlur(e)
                      }}
                      onFocus={() => setFocusedField("handlerEmail")}
                      disabled
                    ></input>
                    {formik.errors.handlerEmail &&
                    formik.touched.handlerEmail ? (
                      <span className='err'>{formik.errors.handlerEmail}</span>
                    ) : null}
                  </div>
                </div>
                <div className={`input-field checkbox-permission-input-field`}>
                  <span>
                    <input
                      name='checkbox'
                      id='checkbox'
                      type='checkbox'
                      className='checkbox-animated'
                      onChange={() => setCheckbox(!checkbox)}
                      checked={checkbox}
                    />
                    <label htmlFor={"checkbox"}>
                      <span className='filter-check-background'></span>
                    </label>
                  </span>{" "}
                  <span className='text-style'>
                    Is Shipping address same as the Billing address?
                  </span>
                </div>
                {/* <div>
                <h3 className="title">Additional Details*</h3>
                <div className="col-2">
                  <div
                    className={`input-field ${
                      formik.values.company_ref_id !== "" ||
                      focusedField === "company_ref_id"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor="name" className="toggle-label">
                      Company Reference ID
                    </label>
                    <input
                      type="text"
                      name="company_ref_id"
                      id="company_ref_id"
                      className="form-input"
                      value={formik.values.company_ref_id}
                      onChange={formik.handleChange}
                      // onInput={(e) => {
                      // e.target.value = e.target.value
                      // .replace(/[^A-Za-z ]/gi, "")
                      // .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                      // }}
                      maxLength={30}
                      // onPaste={(e) => {
                      //   e.preventDefault();
                      // }}
                      // onCopy={(e) => {
                      //   e.preventDefault();
                      // }}
                      onBlur={(e) => {
                        setFocusedField("");
                        formik.handleBlur(e);
                      }}
                      onFocus={() => setFocusedField("company_ref_id")}
                    />
                    {formik.errors.company_ref_id &&
                    formik.touched.company_ref_id ? (
                      <span className="err">
                        {formik.errors.company_ref_id}
                      </span>
                    ) : null}
                  </div>
                  <div
                    className={`input-field ${
                      formik.values.tags !== "" || focusedField === "tags"
                        ? "active"
                        : ""
                    }`}
                  >
                    <label htmlFor="tags" className="toggle-label">
                      Tags
                    </label>
                    <input
                      type="text"
                      name="tags"
                      id="tags"
                      className="form-input"
                      value={formik.values.tags}
                      onChange={formik.handleChange}
                      onInput={(e) => {
                        e.target.value = e.target.value;
                      }}
                      // onKeyDown={(e) => {
                      //   if (e.key === " ") {
                      //     e.preventDefault();
                      //   }
                      // }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onBlur={(e) => {
                        setFocusedField("");
                        formik.handleBlur(e);
                      }}
                      onFocus={() => setFocusedField("tags")}
                    />
                    {formik.errors.tags && formik.touched.tags ? (
                      <span className="err">{formik.errors.tags}</span>
                    ) : null}
                  </div>
                </div>
              </div> */}
              </div>
            </div>
            <div className='footer flex-center'>
              {/* <button
                type='submit'
                disabled={companyLoading}
                className='btn-primary'
                // disabled={profileLoading}
                style={companyLoading ? { cursor: "not-allowed" } : {}}
              >
                {companyLoading ? (
                  <ClipLoader
                    loading={true}
                    size={13}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  "Save"
                )}
              </button> */}

              <FormButtons
                loading={companyLoading}
                removeCancel
                btnContainerStyles={{ justifyContent: "center" }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddSingleCom
// {/* <div>
// {/* <div className="col-2 gstn"> */}
// <div
//   className={`input-field ${
//     formik.values.gst_number !== "" ||
//     focusedField === "gstNum"
//       ? "active"
//       : ""
//   }`}
// >
//   <label htmlFor="gst_number" className="toggle-label">
//     GST Number
//   </label>
//   <input
//     id="gst_number"
//     name="gst_number"
//     type="text"
//     className="form-input"
//     onChange={formik.handleChange}
//     value={formik.values.gst_number}
//     onInput={(e) => gstInput(e)}
//     onBlur={(e) => {
//       setFocusedField("");
//       formik.handleBlur(e);
//     }}
//     onFocus={() => setFocusedField("gstNum")}
//     onPaste={(e) => {
//       e.preventDefault();
//     }}
//     onCopy={(e) => {
//       e.preventDefault();
//     }}
//     onKeyDown={(e) => {
//       if (e.key === " ") {
//         e.preventDefault();
//       }
//     }}
//   />
//   {formik.touched.gst_number && formik.errors.gst_number ? (
//     <span className="err">{formik.errors.gst_number}</span>
//   ) : null}
// </div>
// {/* <button
//     type="button"
//     disabled={!gstn}
//     className={
//       gstn ? "fetch_details enabled" : "fetch_details"
//     }
//   >
//     Fetch Details
//   </button> */}
// {/* </div> */}

// {activeInputs && (
//   <div
//     className={!gstn ? "gstnValidate" : "gstnValidate true"}
//   >
//     <span className="material-symbols-rounded small">
//       {!gstn ? "lightbulb" : "check_circle"}
//     </span>{" "}
//     {!gstn
//       ? "Verify the GST number to capture all the details automatically."
//       : "Unable to fetch details from GST portal. Please enter company details manually."}
//   </div>
// )}
// </div> */}
